import React, { useState } from "react";
import FileManager from "../../../components/file_manager/file_manager";
import withModal from "../../../hoc/withModal";
import { deleteAsset, getFileTarget } from "../../../services/api/file_upload";
import i18n from "../../../i18n";
import { ALLOWED_MIMES } from "../../../components/file_manager/constants";
import { connectWithStore } from "../../../services/redux";
import UserFieldWrapper from "./field_wrapper";

function UserFileField({
  files,
  user,
  onAttachment,
  removeAttachment,
  currentUser,
  label,
  field,
  changeUserProp,
}) {
  const [filesModalVisible, setFilesModalVisible] = useState(false);

  const openFilesModal = () => {
    setFilesModalVisible(true);
  };

  const closeFilesModal = () => {
    setFilesModalVisible(false);
  };

  const onFileAttach = (attachedFiles) => {
    if (onAttachment) {
      onAttachment(attachedFiles);
    } else {
      const fileIds = attachedFiles.map((fileInfo) => fileInfo.assetId);
      let currentFiles = files ? files.concat(fileIds) : fileIds;
      changeUserProp(field.value, currentFiles);
    }
  };

  const onFileRemove = async (index) => {
    if (removeAttachment) {
      removeAttachment(index);
    } else {
      if (files && files[index]) {
        const assetId = files[index];

        const result = await deleteAsset(assetId);

        if (result && result.success) {
          files.splice(index, 1);
          changeUserProp(field.value, files);
        }
      }
    }
  };

  const renderFilesModal = () => {
    const FilesModal = withModal(FileManager);
    return (
      <FilesModal
        title={i18n.t("default:_FILE_LIST")}
        visible={filesModalVisible}
        onClose={() => closeFilesModal()}
        files={
          files
            ? files.map((f) => (typeof f === "object" ? f.ID || f.assetId : f))
            : []
        }
        viewOnly={false}
        onAttachment={(attached) => onFileAttach(attached)}
        removeAttachment={(index) => onFileRemove(index)}
        allowedFiles={ALLOWED_MIMES}
        uploadAsPrivate={true}
        targetUser={currentUser ? currentUser._id : null}
      />
    );
  };

  return (
    <div style={{ marginTop: label ? 10 : 0 }}>
      {renderFilesModal()}
      <UserFieldWrapper
        onFieldClick={() => openFilesModal()}
        label={label ? label : field ? field.name : ""}
        withPointer={true}
      >
        <div className="files">
          {files &&
            files.length > 0 &&
            files.map((file, i) => {
              return (
                <img
                  className="file-image"
                  key={i}
                  alt={file.displayName}
                  src={getFileTarget(
                    file.thumbnail || file.ID || file.assetId || file,
                    "100x100"
                  )}
                />
              );
            })}
        </div>
      </UserFieldWrapper>
    </div>
  );
}

export default connectWithStore(UserFileField, ["user"]);
