export const STYLE_BUTTONS = {
  BOLD: {
    label: "Bold",
    style: "BOLD",
  },
  ITALIC: {
    label: "Italic",
    style: "ITALIC",
  },
  UNDERLINE: {
    label: "Underline",
    style: "UNDERLINE",
  },
};

export const BLOCK_BUTTONS = {
  H1: {
    label: "H1",
    style: "header-one",
  },
  H2: {
    label: "H2",
    style: "header-two",
  },
  H3: {
    label: "H3",
    style: "header-three",
  },
  H4: {
    label: "H4",
    style: "header-four",
  },
  UL: {
    label: "UL",
    style: "unordered-list-item",
  },
  OL: {
    label: "OL",
    style: "ordered-list-item",
  },
};

export const STYLE_TYPES = {
  RICH: "rich",
  BLOCK: "block",
};
