import React, { Component } from "react";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";

export const FIELDS = {
  ALL: "all",
};

class ProjectValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: this.props.question.settings.field || null,
    };
    const { settings } = props;
    const { projectFields } = settings;

    this.options = [
      {
        value: null,
        label: i18n.t("default:_CHOOSE_FIELD"),
      },
      {
        value: FIELDS.ALL,
        label: i18n.t("default:_ALL"),
      },
      ...(projectFields
        ? projectFields.map((field) => {
            return {
              label: field.label,
              value: field.value,
            };
          })
        : []),
    ];
  }

  static getDerivedStateFromProps(props) {
    return {
      field: props.question.settings.field || null,
    };
  }

  changeField(value) {
    if (!value) return;
    this.setState(
      {
        field: value,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange({ field: value });
        }
      }
    );
  }

  render() {
    return (
      <div>
        <select
          className="form-control"
          value={this.state.field}
          onChange={(e) => this.changeField(e.target.value)}
        >
          {this.options.map((option) => {
            return <option value={option.value}>{option.label}</option>;
          })}
        </select>
      </div>
    );
  }
}

export default connectWithStore(ProjectValue, ["settings"]);
