import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connectWithStore } from "../../../services/redux";
import { ROLE } from "../../../services/roles";
import { getFileTarget } from "../../../services/api/file_upload";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: "100%",
  },
  icon: {
    fontSize: 30,
  },
  imgIcon: {
    width: 40,
    height: 45,
    objectFit: "contain",
  },
  label: {
    display: "inline-block",
    width: "100%",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
}));

function FieldPreview({
  label,
  icon,
  children,
  defaultIcon,
  onClick,
  user,
  accessRules,
  noLabel,
  considerReadAccess,
}) {
  const classes = useStyles();
  const canEditField = useCallback(() => {
    const { profile } = user;
    const { role } = profile;
    if (
      user.profile.role === ROLE.BUSINESS_ADMIN ||
      user.profile.role === ROLE.SYSTEM_ADMIN
    ) {
      return true;
    }
    if (considerReadAccess) {
      return accessRules && accessRules[role] && accessRules[role].read
        ? accessRules[role].read
        : false;
    }
    return accessRules && accessRules[role] && accessRules[role].update
      ? accessRules[role].update
      : false;
  }, [accessRules, user, considerReadAccess]);

  return (
    <Grid
      container
      onClick={() => (onClick && canEditField() ? onClick() : null)}
    >
      {!noLabel && (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.gridItem}
        >
          <Grid item xs={1} md={1} sm={1} lg={1}>
            {icon && (
              <img
                className={classes.imgIcon}
                src={getFileTarget(icon, "100x100")}
                alt={label}
              />
            )}
            {!icon && <i className={`mdi ${defaultIcon} ${classes.icon}`} />}
          </Grid>
          <Grid
            item
            md={11}
            sm={11}
            lg={11}
            xs={11}
            className={classes.paddingLeft}
          >
            {label && (
              <span data-tip={label} className={classes.label}>
                {label}
              </span>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container>{children}</Grid>
    </Grid>
  );
}

export default connectWithStore(FieldPreview, ["user"]);
