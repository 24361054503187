import React, { Component } from "react";
import "./search_field.scss";
import { connectWithStore } from "../../services/redux";
import SearchButton from "../search_button/search_button";

class SearchFiled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVal: this.props.value ? this.props.value : null,
      //timeout = null
    };
  }

  onChange(val) {
    this.setState({ currentVal: val });
    if (val === "") {
      this.props.onChange(val);
    }
    /* if (this.props.onChange) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.props.onChange(val);
      }, 1000);
    } */
  }

  handleSearch() {
    this.props.onChange(this.state.currentVal);
  }

  handleKeyPressed(e) {
    if (e.key === "Enter" && this.state.currentVal) {
      this.props.onChange(this.state.currentVal);
    }
  }

  render() {
    return (
      <div className="SearchField row">
        {/* <div className="SearchField_icon col col-md-1">
          <i className="mdi mdi-magnify"></i>
        </div> */}
        <div
          className={
            this.props.squeeze
              ? "SearchField_text col col-md-10"
              : "SearchField_text col col-md-11"
          }
          style={{ display: "flex" }}
        >
          <div
            className={
              this.state.currentVal && this.state.currentVal.length > 0
                ? "SearchBar active"
                : "SearchBar"
            }
          >
            <input
              type="text"
              defaultValue={this.props.value}
              placeholder={this.props.placeholder}
              onChange={(ev) => this.onChange(ev.target.value)}
              onKeyPress={this.handleKeyPressed.bind(this)}
              style={{
                color:
                  this.props.settings.branding &&
                  this.props.settings.branding.primary &&
                  this.props.settings.branding.primary.color
                    ? this.props.settings.branding.primary.color.hex
                    : "#0083ca",
              }}
            />
          </div>
          {this.state.currentVal && this.state.currentVal.length > 0 && (
            <div
              style={{ height: "44px", borderBottom: "1px solid #f7f7f7" }}
              onClick={() => this.handleSearch()}
            >
              <SearchButton
                primary={
                  this.props.settings.branding &&
                  this.props.settings.branding.primary &&
                  this.props.settings.branding.primary.color &&
                  this.props.settings.branding.primary.color.hex
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connectWithStore(SearchFiled, ["settings"]);
