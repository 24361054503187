import React, { Component } from "react";
import i18n from "../../i18n";
import Modal from "react-modal";
import "./info_modal.scss";

export default class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };
  }

  close() {
    this.setState(
      {
        show: false,
      },
      () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    );
  }

  render() {
    const { show } = this.state;
    return (
      <Modal
        className="InfoModal"
        ariaHideApp={false}
        isOpen={show}
        onRequestClose={() => this.close()}
        style={{
          content: {
            width: "100%",
            maxWidth: 320,
          },
        }}
      >
        <div className="center">
          <p>{this.props.text}</p>
        </div>
        <div className="col-md-12 text-center">
          <button
            type="button"
            onClick={() => this.close()}
            className="btn btn-primary btn-block btn-lg"
          >
            {i18n.t("default:_CLOSE")}
          </button>
        </div>
      </Modal>
    );
  }
}
