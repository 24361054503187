//Here its the main render that will render everything elseimport React, { Component } from 'react';
import React, { Component } from "react";
import Text from "./types/text";
import Date from "./types/date";
import Bool from "./types/bool";
import List from "./types/list";
import Image from "./types/image";
import Length from "./types/length";
import ProjectList from "./types/project_list";
import SystemList from "./types/system_list";
import SamuraiMessage from "./types/message";
import Summary from "./types/summary";
import ProjectValue from "./types/project_value";
import i18n from "../../i18n";
import { QuestionType, getFileIcon } from "../../services/question";
import { submitSurvey } from "../../services/api/answer";
import Modal from "react-modal";
import "./questions.scss";
import FileManager from "../file_manager/file_manager";
import SignatureCanvas from "react-signature-canvas";
import { connectWithStore } from "../../services/redux";
import { getJobType } from "../../services/api/job_type";
import MediaQuery from "react-responsive";
import { isMobileDevice } from "../../services/mobile";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { getFileTarget } from "../../services/api/file_upload";
import StepWizard from "react-step-wizard";
import Step from "./steps/step";
import ProjectTable from "./types/table";
import ToggleService from "../../services/toggle";
import BPEditor from "../blueprints/editor/editor";
import store from "../../redux/store";
import { updateElements } from "../../services/api/blueprints";
import { BlueprintEditor, Questions } from "../../redux/actions";
import EmailSection from "./partials/email_section";
import { hideLoading } from "react-redux-loading-bar";
import { ALLOWED_MIMES } from "../file_manager/constants";
import QuestionHeader from "./partials/question_header";

const styles = {
  root: {
    marginTop: "10px",
    marginRight: "10px",
    backgroundColor: "#7aed9f",
  },
};

class QuestionRenderer extends Component {
  constructor(props) {
    super(props);
    this.currentCmp = null;
    const existingAnswers = {};
    this.props.questions
      .filter((question) => question && question.type !== QuestionType.STEP)
      .forEach((question) => {
        if (question.answer) {
          existingAnswers[question.ID] = {
            ...question.answer,
          };
        }
      });
    this.state = {
      signatureApplied: false,
      showFileModal: false,
      showCommentSection: false,
      showSignatureSection: false,
      showSendEmailSection: false,
      //activeQuestion: 0,
      isMandatory: false,
      answers: existingAnswers,
      loading: false,
      job: this.props.job,
      jobType: this.props.jobType,
      lastQuestionId: null,
      pathActivated: false,
      attachmentUploadInProgress: false,
      primary:
        this.props.settings.branding &&
          this.props.settings.branding.primary &&
          this.props.settings.branding.primary.color &&
          this.props.settings.branding.primary.color.hex
          ? this.props.settings.branding.primary.color.hex
          : null,
      emptyDate: false,
      withSteps: false,
      fileIsRequired: false,
    };
    this.sigCanvas = null;
  }

  handleChange = (data, type) => {
    if (type === "empty date") {
      this.setState({ emptyDate: true });
    } else if (type === "full date") {
      this.setState({ emptyDate: false });
    }
    if (type === "length") {
      setTimeout(() => this.updateValue({ value: data }), 100);
    } else {
      this.updateValue({ value: data });
    }
    if (
      data !== 0 &&
      !data &&
      data !== false &&
      this.currentCmp &&
      this.currentCmp.settings.isMandatory
    ) {
      this.setState({ isMandatory: true });
    } else {
      this.setState({ isMandatory: false });
    }
  };

  checkForFiles = (question) => {
    const { answers } = this.state;
    const filesLength =
      answers[question] && answers[question].files
        ? answers[question].files.length
        : 0;
    return filesLength > 0;
  };

  async checkStepsToggle() {
    const response = await ToggleService._isToggleActive(
      "steps",
      this.props.settings.toggles
    );
    this.setState({ withSteps: response });
  }

  async componentDidMount() {
    const { activeQuestion } = this.props.jobQuestions;
    this.checkStepsToggle();
    let { jobType } = this.state;
    const currentQuestion = this.props.questions[activeQuestion];
    if (jobType && typeof jobType === "string") {
      const response = await getJobType({
        id: jobType,
      });

      if (response) {
        jobType = response;

        this.setState({
          jobType,
        });
      }
    }
    if (
      currentQuestion &&
      currentQuestion.settings &&
      currentQuestion.settings.allowAddingFile &&
      currentQuestion.settings.fileIsRequired
    ) {
      const hasFiles = this.checkForFiles(currentQuestion);
      this.setState({
        fileIsRequired: !hasFiles,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevActiveQuestion = prevProps.jobQuestions.activeQuestion;
    const currentActiveQuestion = this.props.jobQuestions.activeQuestion;
    if (prevActiveQuestion !== currentActiveQuestion) {
      const currentQuestion = this.props.questions[currentActiveQuestion];
      if (
        currentQuestion &&
        currentQuestion.settings &&
        currentQuestion.settings.allowAddingFile &&
        currentQuestion.settings.fileIsRequired
      ) {
        const hasFiles = this.checkForFiles(currentQuestion);
        this.setState({
          fileIsRequired: !hasFiles,
        });
      } else {
        this.setState({
          fileIsRequired: false,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { withSteps } = this.state;
    if (withSteps && this.props.questions.length > 1 && this.sigCanvas) {
      if (nextState.signatureApplied) {
        return false;
      }
      return JSON.stringify(nextState) !== JSON.stringify(this.state);
    }
    const { filePreviewVisible } = this.props.blueprintEditor;
    if (filePreviewVisible) {
      return false;
    }
    return true;
  }

  handleChangeSettings = (value, name, item) => {
    const { activeQuestion } = this.props.jobQuestions;
    const filteredQuestions = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    );
    if (name === "emailChips") {
      const { answers } = this.state;
      this.currentCmp = filteredQuestions[activeQuestion];
      if (!this.state.answers[this.currentCmp.ID]) {
        answers[this.currentCmp.ID] = {};
      }
      const answer = answers[this.currentCmp.ID];
      const selectedEmails = answer.emails ? answer.emails : "";
      const newEmail = value;
      let allMails;
      if (selectedEmails) {
        allMails = selectedEmails + ", " + newEmail;
      } else {
        allMails = newEmail;
      }
      const newValue = allMails;
      this.updateValue({ emails: newValue }, item);
      return;
    }
    this.updateValue({ [name]: value }, item);
  };

  handleValid = (valid) => {
    this.setState({ isMandatory: valid });
  };

  updateValue(params, item) {
    const { activeQuestion } = this.props.jobQuestions;
    const filteredQuestions = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    );
    if (filteredQuestions.length === 0) {
      return;
    }
    const { answers } = this.state;
    if (item && activeQuestion !== filteredQuestions.indexOf(item)) {
      if (!this.state.answers[item.ID]) {
        answers[item.ID] = {};
      }
      const stepAnswer = answers[item.ID];
      Object.assign(stepAnswer, params);
      this.setState({ answers });
      return;
    }
    if (params.value && typeof params.value === "object") {
      if (
        "from" in params.value &&
        "to" in params.value &&
        "date" in params.value
      ) {
        if (
          params.value.from === null &&
          params.value.to === null &&
          params.value.date === null
        ) {
          params.value = null;
        }
      }
    }
    this.currentCmp = filteredQuestions[activeQuestion];
    if (!this.state.answers[this.currentCmp.ID]) {
      answers[this.currentCmp.ID] = {};
    }
    const answer = answers[this.currentCmp.ID];
    Object.assign(answer, params);
    this.setState({ answers });
    this.props.dispatch(Questions.setAnswers(answers));
  }

  async nextQuestion() {
    const { activeQuestion } = this.props.jobQuestions;
    const filteredQuestions = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    );
    this.currentCmp = filteredQuestions[activeQuestion];
    let pathOrder = false;
    await this.answerCurrentOnly(
      Object.assign({}, filteredQuestions[activeQuestion])
    );

    //check if there are remaining step questions before set bp open to true
    const remainingQuestions = filteredQuestions.slice(activeQuestion + 1);
    if (remainingQuestions.length > 0) {
      this.props.dispatch(BlueprintEditor.setIsBpEditorOpen(true));
    }

    if (this.currentCmp.settings.pathOrder) {
      this.currentCmp.settings.path.forEach((path) => {
        if (
          this.state.answers[this.currentCmp.ID] &&
          ((path.value === "any" &&
            this.state.answers[this.currentCmp.ID].value) ||
            this.state.answers[this.currentCmp.ID].value === path.value ||
            (path.value === "" &&
              (!this.state.answers[this.currentCmp.ID] ||
                this.state.answers[this.currentCmp.ID].value === "" ||
                this.state.answers[this.currentCmp.ID].value === null)))
        ) {
          const filter = filteredQuestions.filter(
            (i) => i.ID === parseInt(path.toQuestion)
          );
          if (filter.length > 0) {
            const index = filteredQuestions.indexOf(filter[0]);
            this.props.dispatch(Questions.setActiveQuestion(index));
            this.setState({
              //activeQuestion: index,
              lastQuestionId: activeQuestion,
              pathActivated: true,
            });
            pathOrder = true;
          }
        }
      });
    }

    if (activeQuestion + 2 <= filteredQuestions.length && !pathOrder) {
      this.props.dispatch(Questions.setActiveQuestion(activeQuestion + 1));
      this.setState({
        //activeQuestion: this.state.activeQuestion + 1,
        lastQuestionId: activeQuestion + 1,
        pathActivated: false,
      });
    }
  }

  backQuestion() {
    const { activeQuestion } = this.props.jobQuestions;
    if (this.state.pathActivated) {
      this.props.dispatch(
        Questions.setActiveQuestion(this.state.lastQuestionId)
      );
      this.setState({
        //activeQuestion: this.state.lastQuestionId,
        pathActivated: false,
      });
    } else if (activeQuestion !== 0) {
      this.props.dispatch(Questions.setActiveQuestion(activeQuestion - 1));
      //this.setState({ activeQuestion: this.state.activeQuestion - 1 });
    }

    if (
      !this.props.questions.filter(
        (question) => question && question.type !== QuestionType.STEP
      )[activeQuestion].settings.isMandatory
    ) {
      this.setState({ isMandatory: false }, () => this.forceUpdate());
    }
  }

  checkAndUpdateSignature(item) {
    if (this.sigCanvas && !this.sigCanvas.isEmpty()) {
      this.handleChangeSettings(
        this.sigCanvas.toDataURL("image/png"),
        "signature"
      );
    }
  }

  async answerAllQuestions(questions, fromSteps) {
    //await this.handleBlueprintSave();
    const { answers } = this.state;

    const defaultAnswer = {
      value: "",
    };
    const data = [];

    for (let question of questions) {
      const id = question.ID;
      if (
        answers[id] ||
        question.type === QuestionType.SUMMARY ||
        question.type === QuestionType.PROJECT_VALUE ||
        (question.type === QuestionType.LENGTH &&
          answers[id] &&
          answers[id].value === 0)
      ) {
        const answer = answers[id] ? answers[id] : defaultAnswer;
        answer.files =
          answer && answer.files && answer.files.length > 0
            ? answer.files.map((file) => {
              if (isNaN(file) && typeof file === "object" && file.assetId) {
                return file.assetId;
              }

              return file;
            })
            : [];
        const mutableQuestion = Object.assign({}, question);
        delete mutableQuestion.answer;
        data.push({
          answer: answer,
          question: mutableQuestion,
        });
      } else {
        continue;
      }
    }

    if (data.length > 0) {
      const jobId = this.props.job ? this.props.job._id : this.props.job_id;
      const response = await submitSurvey({
        job: jobId,
        data: data,
        location: this.state.jobType.settings.answerLocation
          ? this.props.userLocation.coords
          : null,
      });

      if (fromSteps) {
        for (let question of questions) {
          const id = question.ID;
          if (
            answers[id] ||
            question.type === QuestionType.SUMMARY ||
            question.type === QuestionType.PROJECT_VALUE ||
            (question.type === QuestionType.LENGTH &&
              answers[id] &&
              answers[id].value === 0)
          ) {
            const responseAnswer = answers[id];

            if (
              responseAnswer &&
              question.settings.pathOrder &&
              (!question.answer ||
                (question.answer && !question.answer.value)) &&
              response.result
            ) {
              const responseAnswerArr = response.result.filter(
                (a) =>
                  a.question.ID &&
                  typeof a.question.ID === "string" &&
                  Number(a.question.ID.split("_")[0]) === question.ID
              );

              const responseAnswerId =
                responseAnswerArr.length > 0 && responseAnswerArr[0].ID;

              responseAnswer.ID = responseAnswerId;
              responseAnswer.files =
                responseAnswer &&
                  responseAnswer.files &&
                  responseAnswer.files.length > 0
                  ? responseAnswer.files.map((file) => {
                    if (
                      isNaN(file) &&
                      typeof file === "object" &&
                      file.assetId
                    ) {
                      return file.assetId;
                    }

                    return file;
                  })
                  : [];

              question.answer = responseAnswer;
            }
          }
        }
      }

      if (this.props.update) {
        this.props.update(response.result);
      }

      if (response && fromSteps) {
        response.questions = questions;
      }
      return response;
    }

    return null;
  }

  async answerCurrentOnly(question) {
    const newElements = await this.handleBlueprintSave();
    const { answers } = this.state;
    const defaultAnswer = {
      value: "",
    };
    const data = [];
    const id = question.ID;
    if (
      answers[id] ||
      question.type === QuestionType.SUMMARY ||
      question.type === QuestionType.PROJECT_VALUE ||
      (question.type === QuestionType.LENGTH && answers[id].value === 0)
    ) {
      const answer = answers[id] ? answers[id] : defaultAnswer;
      answer.files =
        answer && answer.files && answer.files.length > 0
          ? answer.files.map((file) => {
            if (isNaN(file) && typeof file === "object" && file.assetId) {
              return file.assetId;
            }

            return file;
          })
          : [];
      const mutableQuestion = Object.assign({}, question);
      if (question.type === QuestionType.BLUEPRINT) {
        if (newElements && newElements.length > 0) {
          answer.elements = newElements;
        } else {
          delete answer.elements;
        }
        const bpResponse = [];
        bpResponse.push(answer);
        /* this.props.update(bpResponse);
        return; */
      }
      if (JSON.stringify(mutableQuestion.answer) === JSON.stringify(answer)) {
        return;
      }

      delete mutableQuestion.answer;
      data.push({
        answer: answer,
        question: mutableQuestion,
      });
    }
    if (data.length > 0) {
      const jobId = this.props.job ? this.props.job._id : this.props.job_id;
      const response = await submitSurvey({
        job: jobId,
        data: data,
        location: this.state.jobType.settings.answerLocation
          ? this.props.userLocation.coords
          : null,
      });
      if (
        question.type === QuestionType.BLUEPRINT &&
        newElements &&
        newElements.length > 0 &&
        response.result
      ) {
        if (response.result[0]) {
          response.result[0].elements = newElements;
        }
      }
      if (this.props.update) {
        this.props.update(response.result);
      }
      return response;
    }
    return null;
  }

  async handleBlueprintSave() {
    const { activeQuestion } = this.props.jobQuestions;
    const q = this.props.questions[activeQuestion];
    if (q.type === QuestionType.BLUEPRINT) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const dataElements = [];
      const previewElements = [];
      blueprintEditor.elements
        .filter((el) => (el.job ? el.job.ID === this.state.job.ID : true))
        .filter((el) => (el.question ? el.question === q._id : true))
        .forEach((el) => {
          const elId = el.initialId ? el.initialId : el.id;
          const target = blueprintEditor.app.find("#" + elId);
          if (target.length > 0) {
            const dataEl = {
              attrs: target.toJSON()[0].attrs,
              className: target.toJSON()[0].attrs.name,
            };
            const groupId = dataEl.attrs.groupId;
            let groupShape = null;
            if (groupId) {
              groupShape = blueprintEditor.app.find("#" + groupId);
            }
            previewElements.push({
              elementId: el.elementId,
              canvasData: JSON.stringify(dataEl),
              visible: el.visible,
            });
            dataElements.push({
              elementId: el.elementId,
              canvasData: groupShape
                ? groupShape.toJSON()[0]
                : target.toJSON()[0],
              visible: el.visible,
            });
          }
        });
      await updateElements({
        question: q._id,
        job: this.state.job._id,
        elements: dataElements,
        file: blueprintEditor.file,
        measurements: blueprintEditor.measurements,
      });
      this.props.dispatch(BlueprintEditor.reset());
      return previewElements;
    }
  }

  async submitQuestions() {
    const { activeQuestion } = this.props.jobQuestions;
    this.setState({ loading: true });
    const response = await this.answerCurrentOnly(
      Object.assign(
        {},
        this.props.questions.filter(
          (question) => question && question.type !== QuestionType.STEP
        )[activeQuestion]
      )
    );
    this.props.dispatch(Questions.setActiveQuestion(0));
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.dispatch(hideLoading());
        this.props.done(response ? response.result : []);
      }
    );
  }

  async saveQuestion() {
    const { activeQuestion } = this.props.jobQuestions;
    this.setState({ loading: true });
    await this.answerCurrentOnly(
      Object.assign(
        {},
        this.props.questions.filter(
          (question) => question && question.type !== QuestionType.STEP
        )[activeQuestion]
      )
    );
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.dispatch(hideLoading());
      }
    );
    return Promise.resolve();
  }

  async submitStepQuestions(questions) {
    const response = await this.answerAllQuestions(questions);
    this.props.dispatch(Questions.setActiveQuestion(0));
    this.setState(
      {
        loading: false,
      },
      () => {
        this.props.done(response ? response.result : []);
      }
    );
  }

  renderQuestionComponent(question, filteredQuestions) {
    const { activeQuestion } = this.props.jobQuestions;
    const { primary } = this.state;
    switch (question.type) {
      case QuestionType.DATE_TIME:
        return (
          <Date
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
            settings={this.props.settings}
          />
        );
      case QuestionType.BOOL:
        return (
          <Bool
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.LIST:
        return (
          <List
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.TEXT:
        return (
          <Text
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            activeQuestion={
              this.props.questions.filter(
                (question) => question && question.type !== QuestionType.STEP
              )[activeQuestion]
            }
            answers={this.state.answers}
            allAnswers={this.state.job && this.state.job.answers}
          />
        );
      case QuestionType.IMAGE:
        return (
          <Image
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.LENGTH:
        return (
          <Length
            handleChange={(val) => this.handleChange(val, "length")}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.MESSAGE:
        return (
          <SamuraiMessage
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.PROJECT_LIST:
        return (
          <ProjectList
            primary={primary}
            project={
              this.state.job
                ? this.state.job.project
                : {
                  materials: [],
                  customers: [],
                  factories: [],
                }
            }
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.PROJECT_TABLE:
        return (
          <ProjectTable
            project={this.state.job && this.state.job.project}
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.SYSTEM_LIST:
        return (
          <SystemList
            primary={primary}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.SUMMARY:
        return (
          <Summary
            job={this.props.job}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.PROJECT_VALUE:
        return (
          <ProjectValue
            primary={primary}
            jobId={this.state.job ? this.state.job.ID : null}
            project={this.state.job ? this.state.job.project : {}}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
          />
        );
      case QuestionType.BLUEPRINT:
        return (
          <BPEditor
            onClose={() => {
              this.props.dispatch(BlueprintEditor.setFile(null))
            }}
            primary={primary}
            job={this.state.job ? this.state.job : {}}
            handleChange={this.handleChange}
            handleValid={this.handleValid}
            question={question}
            answers={this.state.answers}
            filteredQuestions={filteredQuestions}
            submitQuestion={this.submitQuestions.bind(this)}
            nextQuestion={this.nextQuestion.bind(this)}
            saveQuestion={this.saveQuestion.bind(this)}
          //allQuestions={this.props.questions}
          />
        );
      default:
        return null;
    }
  }

  onAttachment(files) {
    const { activeQuestion } = this.props.jobQuestions;
    const { answers } = this.state;
    this.currentCmp = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    )[activeQuestion];

    if (!this.state.answers[this.currentCmp.ID]) {
      answers[this.currentCmp.ID] = {};
    }
    if (!answers[this.currentCmp.ID].files) {
      answers[this.currentCmp.ID].files = [];
    }
    answers[this.currentCmp.ID].files = answers[
      this.currentCmp.ID
    ].files.concat(files.map((f) => f.assetId));

    const hasFiles = this.checkForFiles(this.currentCmp.ID);
    this.setState(
      {
        answers: answers,
        loading: false,
        attachmentUploadInProgress: false,
        fileIsRequired: !hasFiles,
      },
      () => {
        this.answerCurrentOnly(
          this.props.questions.filter(
            (question) => question && question.type !== QuestionType.STEP
          )[activeQuestion]
        );
      }
    );
  }

  removeAttachment(index) {
    const { activeQuestion } = this.props.jobQuestions;
    const { answers } = this.state;
    this.currentCmp = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    )[activeQuestion];
    if (!answers[this.currentCmp.ID].files) {
      answers[this.currentCmp.ID].files = [];
    }
    if (answers[this.currentCmp.ID].files[index]) {
      answers[this.currentCmp.ID].files.splice(index, 1);
      const hasFiles = this.checkForFiles(this.currentCmp.ID);
      this.setState(
        {
          answers: answers,
          fileIsRequired: !hasFiles,
        },
        async () => {
          const question = this.props.questions.filter(
            (question) => question && question.type !== QuestionType.STEP
          )[activeQuestion];
          const defaultAnswer = {
            value: null,
          };
          const id = question.ID;
          const answer = answers[id] ? answers[id] : defaultAnswer;
          const mutableQuestion = Object.assign({}, question);
          const jobId = this.props.job ? this.props.job._id : this.props.job_id;
          const data = [
            {
              answer: answer,
              question: mutableQuestion,
            },
          ];
          const response = await submitSurvey({
            job: jobId,
            data: data,
            location: this.state.jobType.settings.answerLocation
              ? this.props.userLocation.coords
              : null,
          });
          if (this.props.update) {
            this.props.update(response.result);
          }
        }
      );
    }
  }

  getFileExtension(filename) {
    return filename.split(".").pop();
  }

  getIcon(extension) {
    if (extension) {
      return getFileIcon(extension);
    }
    return "";
  }

  toggleComment() {
    this.setState({
      showCommentSection: this.state.showCommentSection ? false : true,
    });
  }

  handleAnswerClick(chipComment, item) {
    const currentValue =
      this.state.answers[item.ID] && this.state.answers[item.ID]["comment"]
        ? this.state.answers[item.ID]["comment"]
        : "";
    const updatedValue = currentValue.concat(chipComment);
    this.updateValue({ comment: updatedValue });
  }

  renderCommentSection(item, fromSteps, showSection) {
    const { classes } = this.props;
    const options = item.settings.responseOptions;
    const { showCommentSection } = this.state;
    const shouldShowSection = fromSteps ? showSection : showCommentSection;
    return (
      <div
        className="commentSection no-background"
        style={shouldShowSection ? { paddingTop: 15 } : {}}
      >
        <div
          className={
            shouldShowSection ? "commentTextarea visible" : "commentTextarea"
          }
        >
          <textarea
            dir="auto"
            className="form-control commentTextarea-form"
            value={
              this.state.answers[item.ID] &&
              this.state.answers[item.ID]["comment"]
            }
            onChange={(e) => {
              this.handleChangeSettings(e.target.value, "comment");
            }}
          />
          <div className="responseOptions">
            {options &&
              options.map((option, i) => (
                <Chip
                  key={i}
                  className={classes.root}
                  label={option}
                  onClick={() => this.handleAnswerClick(option, item)}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  toggleSignature() {
    this.setState({
      showSignatureSection: this.state.showSignatureSection ? false : true,
    });
  }

  renderSignatureSection(item, fromSteps, toggleSection, showSection) {
    const { showSignatureSection } = this.state;
    const shouldShowSignatureSection = fromSteps
      ? showSection
      : showSignatureSection;
    return (
      <div className="commentSection emailSignatureCommentSection">
        <div
          className="commentToolbar"
          onClick={() => (fromSteps ? toggleSection() : this.toggleSignature())}
        >
          <div
            className={
              this.state.answers[item.ID] &&
                this.state.answers[item.ID]["signature"]
                ? "commentSectionCol left hasComment pointer"
                : "commentSectionCol left pointer"
            }
          >
            <i className="icon-edit_2" />
            <span>{i18n.t("default:_ADD_SIGNATURE")}</span>
          </div>
          {!shouldShowSignatureSection && (
            <div className="commentSectionCol right">
              <i className="mdi mdi-chevron-up" />
            </div>
          )}
          {shouldShowSignatureSection && (
            <div className="commentSectionCol right">
              <i className="mdi mdi-chevron-down" />
            </div>
          )}
        </div>
        <div
          className={
            shouldShowSignatureSection
              ? "commentTextarea visible"
              : "commentTextarea"
          }
          style={isMobileDevice() ? { width: '90vw', height: '71vh' } : {}}
        >
          <SignatureCanvas
            ref={(ref) => {
              this.sigCanvas = ref;
              if (
                !this.state.signatureApplied &&
                ref &&
                this.state.answers[item.ID] &&
                this.state.answers[item.ID]["signature"]
              ) {
                this.sigCanvas.fromDataURL(
                  this.state.answers[item.ID]["signature"],
                  {
                    width: 200,
                    height: 100,
                  }
                );
                this.setState({
                  signatureApplied: true,
                });
              }
            }}
            onEnd={() => this.checkAndUpdateSignature(item)}
            penColor="black"
            canvasProps={{
              width: isMobileDevice() ? '300px' : "200px",
              height: isMobileDevice() ? '400px' : "100px",
              className: "sigCanvas",
            }}
          />
          <i
            style={{ color: "red", fontSize: 50, marginLeft: 10 }}
            className="mdi mdi-delete pointer "
            onClick={() => {
              if (this.sigCanvas) {
                this.sigCanvas.clear();
                this.handleChangeSettings("", "signature");
              }
            }}
          />
        </div>
      </div>
    );
  }

  toggleSendEmail() {
    this.setState({
      showSendEmailSection: this.state.showSendEmailSection ? false : true,
    });
  }

  goBack() {
    this.props.dispatch(BlueprintEditor.reset());
    this.props.dispatch(Questions.setActiveQuestion(0));
    this.props.close();
  }

  renderFileOverview(item) {
    const { answers } = this.state;
    const length =
      answers[item.ID] && answers[item.ID].files
        ? answers[item.ID].files.length
        : 0;
    return (
      <div className="pointer" onClick={() => this.openFileModal()}>
        {/*{i18n.t('default:_FILES')} <span className='preview'>{renderIcon(icon)}</span> */}
        <p>
          <i
            className={
              length > 0
                ? "mdi mdi-folder-multiple-image fileIcon"
                : "mdi mdi-image-plus fileIcon"
            }
          />
        </p>
      </div>
    );
  }

  renderFileModal(item) {
    const { activeQuestion } = this.props.jobQuestions;
    const filteredQuestions = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    );
    if (activeQuestion !== filteredQuestions.indexOf(item)) return;
    const { answers } = this.state;
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showFileModal}
        onRequestClose={() => this.handleClose()}
        style={{
          content: {
            padding: 0,
            width: 489,
            height: 500,
          },
        }}
      >
        <div className="Modal">
          <div className="Modal-header">
            <i
              className="mdi mdi-chevron-left chevron-left"
              onClick={() => this.handleClose()}
            />
            <h5>{i18n.t("default:_FILE_LIST")}</h5>
          </div>

          <div className="Modal-content NoPadding">
            <FileManager
              onAttachment={(files) => this.onAttachment(files)}
              removeAttachment={(index) => this.removeAttachment(index)}
              files={answers[item.ID] ? answers[item.ID].files : []}
              allowedFiles={ALLOWED_MIMES}
              onBefereS3Upload={() => this.beforeAttachmentUpload()}
            />
          </div>
        </div>
      </Modal>
    );
  }

  beforeAttachmentUpload() {
    this.setState({
      attachmentUploadInProgress: true,
    });
  }

  openFileModal() {
    this.setState({
      showFileModal: true,
    });
  }

  handleClose() {
    this.setState({
      showFileModal: false,
    });
  }

  renderTabToolbar(item, fromSteps, toggleCommentFromStep) {
    const { answers } = this.state;
    const isIssue = answers[item.ID] ? answers[item.ID]["isIssue"] : false;
    const hasComment =
      answers[item.ID] && this.state.answers[item.ID]["comment"] ? true : false;
    const filesLength =
      answers[item.ID] && answers[item.ID].files
        ? answers[item.ID].files.filter((f) => f !== null).length
        : 0;
    const hasFiles = filesLength > 0;
    return (
      <>
        <div className="answer-action-toolbar">
          {item.settings.allowComment && (
            <div
              style={{
                borderTop: hasComment
                  ? "3px solid #7AED9F"
                  : "3px solid #B0BAC9",
              }}
              className="action-toolbar-tab"
              onClick={() =>
                fromSteps ? toggleCommentFromStep() : this.toggleComment()
              }
            >
              <i
                className={
                  hasComment ? "mdi mdi-comment" : "mdi mdi-comment-outline"
                }
              />
            </div>
          )}
          {item.settings.allowAddingFile && (
            <div
              style={{
                borderTop: hasFiles ? "3px solid #269ce0" : "3px solid #B0BAC9",
              }}
              className="action-toolbar-tab"
              onClick={() => this.openFileModal()}
            >
              <i
                className={
                  hasFiles ? "mdi mdi-image-multiple" : "mdi mdi-image-filter"
                }
              />
            </div>
          )}
          {item.settings.allowTaggingIssue && (
            <div
              style={{
                borderTop: isIssue
                  ? `3px solid ${this.props.settings.branding &&
                    this.props.settings.branding["review"]
                    ? this.props.settings.branding["review"].color.hex
                    : "#E010B2"
                  }`
                  : "3px solid #B0BAC9",
              }}
              className="action-toolbar-tab"
              onClick={() =>
                this.handleChangeSettings(
                  isIssue ? false : true,
                  "isIssue",
                  item
                )
              }
            >
              <i
                style={
                  isIssue
                    ? {
                      color:
                        this.props.settings.branding &&
                          this.props.settings.branding["review"]
                          ? this.props.settings.branding["review"].color.hex
                          : "#E010B2",
                    }
                    : {}
                }
                className={
                  isIssue
                    ? "mdi mdi-lightbulb-on"
                    : "mdi mdi-lightbulb-on-outline"
                }
              />
            </div>
          )}
        </div>
        {item.settings.allowAddingFile &&
          item.settings.fileIsRequired &&
          !hasFiles && (
            <div className="file-is-required">{"File is required!"}</div>
          )}
      </>
    );
  }

  hideCommentIfPresent(e) {
    if (
      this.state.showCommentSection &&
      !e.target.classList.contains("commentSection") &&
      !e.target.classList.contains("commentTextarea") &&
      !e.target.classList.contains("commentTextarea-form") &&
      !e.target.classList.contains("responseOptions") &&
      !e.target.classList.contains("MuiChip-label")
    ) {
      this.setState({
        showCommentSection: false,
      });
    }
  }

  getStepTitles() {
    return this.props.questions
      .filter((question) => question && question.type === QuestionType.STEP)
      .map((step) => {
        let reworkedStep = {};
        reworkedStep.title = step.title;
        reworkedStep.icon = step.settings.stepIcon;
        return reworkedStep;
      });
  }

  render() {
    const { activeQuestion } = this.props.jobQuestions;
    const { job, jobType, withSteps, fileIsRequired } = this.state;
    const filteredQuestions = this.props.questions.filter(
      (question) => question && question.type !== QuestionType.STEP
    );

    const { file } = this.props.blueprintEditor;

    const steps = this.props.questions
      .filter((question) => question && question.type === QuestionType.STEP)
      .map((step, i) => {
        return (
          <Step
            key={i}
            ind={i}
            step={step}
            steps={this.getStepTitles()}
            allQuestions={this.props.questions}
            questions={filteredQuestions}
            job={job}
            jobType={jobType}
            goBack={this.goBack.bind(this)}
            renderSignatureSection={this.renderSignatureSection.bind(this)}
            renderTabToolbar={this.renderTabToolbar.bind(this)}
            renderCommentSection={this.renderCommentSection.bind(this)}
            answers={this.state.answers}
            showCommentSection={this.state.showCommentSection}
            renderQuestionComponent={this.renderQuestionComponent.bind(this)}
            renderFileModal={this.renderFileModal.bind(this)}
            submitStepQuestions={this.submitStepQuestions.bind(this)}
            answerAllQuestions={this.answerAllQuestions.bind(this)}
            handleChangeSettings={this.handleChangeSettings.bind(this)}
            fromQuestionnaire={this.props.fromQuestionnaire}
          />
        );
      });

    return (
      <React.Fragment>
        {withSteps &&
          filteredQuestions.length > 1 &&
          steps.length !== 0 &&
          this.props.questions[0].type === QuestionType.STEP ? (
          <StepWizard isLazyMount={true}>{steps}</StepWizard>
        ) : (
          filteredQuestions.map(
            (item, i) =>
              activeQuestion === i && (
                <div
                  className="question"
                  key={i}
                  onClick={(e) => this.hideCommentIfPresent(e)}
                >
                  {(item.type !== QuestionType.BLUEPRINT || !file) && (
                    <QuestionHeader
                      jobType={jobType}
                      hideBackButton={
                        this.props.fromPreview || this.props.fromQuestionnaire
                      }
                      goBack={this.goBack.bind(this)}
                      job={job}
                    />
                  )}
                  <div
                    style={{
                      backgroundImage: isMobileDevice()
                        ? `url(${item.settings.withBackground &&
                        getFileTarget(item.settings.backgroundMobile)
                        })`
                        : `url(${item.settings.withBackground &&
                        getFileTarget(item.settings.backgroundDesktop)
                        })`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      color: item.settings.darkMode ? "white" : null,
                      width: "100%",
                      height: item.type === QuestionType.BLUEPRINT && "100%",
                      paddingBottom: item.type === QuestionType.BLUEPRINT && 0,
                    }}
                    className={
                      item.settings.managementNote
                        ? "question-container managementNote"
                        : "question-container"
                    }
                  >
                    <div className="question-toolbar-sticky">
                      {(item.settings.allowAddingFile ||
                        item.settings.allowComment ||
                        item.settings.allowTaggingIssue) &&
                        this.renderTabToolbar(item)}
                      {item.settings.allowComment &&
                        this.renderCommentSection(item)}
                    </div>

                    {item.type !== QuestionType.SUMMARY &&
                      item.type !== QuestionType.BLUEPRINT && (
                        <div
                          className={"question-title".concat(
                            this.state.answers[item.ID] &&
                              this.state.answers[item.ID].isIssue
                              ? " issueQuestion active"
                              : item.settings.allowTaggingIssue
                                ? " issueQuestion"
                                : ""
                          )}
                        >
                          <h5
                            className={
                              this.state.showCommentSection
                                ? "question-header-commentOpen"
                                : ""
                            }
                            dir="auto"
                            style={{
                              color: item.settings.darkMode ? "white" : null,
                            }}
                          >
                            {this.state.answers[item.ID] &&
                              this.state.answers[item.ID].isIssue && (
                                <React.Fragment>
                                  <MediaQuery minWidth={768}>
                                    <i
                                      style={{
                                        color:
                                          this.props.settings.branding &&
                                            this.props.settings.branding["review"]
                                            ? this.props.settings.branding[
                                              "review"
                                            ].color.hex
                                            : "#E010B2",
                                        paddingRight: "30px",
                                      }}
                                      className={"mdi mdi-lightbulb-on"}
                                    />
                                  </MediaQuery>
                                  <MediaQuery maxWidth={767}>
                                    <div>
                                      <i
                                        style={{
                                          color:
                                            this.props.settings.branding &&
                                              this.props.settings.branding[
                                              "review"
                                              ]
                                              ? this.props.settings.branding[
                                                "review"
                                              ].color.hex
                                              : "#E010B2",
                                          fontSize: "30px",
                                        }}
                                        className={"mdi mdi-lightbulb-on"}
                                      />
                                    </div>
                                  </MediaQuery>
                                </React.Fragment>
                              )}
                            {item.title}
                          </h5>
                          <p
                            style={{
                              color: item.settings.darkMode ? "white" : null,
                            }}
                            dir="auto"
                          >
                            {item.description}
                          </p>
                        </div>
                      )}

                    {this.renderQuestionComponent(item, filteredQuestions)}
                    <div className="question-toolbar-sticky toolbar-bottom">
                      {item.settings.signature &&
                        this.renderSignatureSection(item)}
                      {item.settings.emails && (
                        <EmailSection
                          item={item}
                          job={this.state.job}
                          answers={this.state.answers}
                          toggleSection={this.toggleSendEmail.bind(this)}
                          showSendEmailSection={this.state.showSendEmailSection}
                          handleChangeSettings={this.handleChangeSettings.bind(
                            this
                          )}
                        />
                      )}
                    </div>

                    {item.settings.allowAddingFile &&
                      this.renderFileModal(item)}

                    {!this.props.fromPreview &&
                      item.type !== QuestionType.BLUEPRINT && (
                        <div className="question-actions">
                          {activeQuestion + 1 === filteredQuestions.length && (
                            <div>
                              {/* .filter(
                          (question) => question.type !== QuestionType.STEP
                        ) */}
                              {filteredQuestions.length > 1 && (
                                <div>
                                  <button
                                    disabled={activeQuestion === 0}
                                    onClick={() => this.backQuestion()}
                                    className="btn btn-rounded btn-primary btn-back"
                                  >
                                    {i18n.t("default:_BACK")}
                                  </button>
                                </div>
                              )}
                              <div>
                                <button
                                  disabled={
                                    this.state.attachmentUploadInProgress ||
                                    this.state.isMandatory ||
                                    (this.state.isMandatory &&
                                      this.state.emptyDate) ||
                                    fileIsRequired
                                  }
                                  onClick={() => this.submitQuestions()}
                                  className="btn btn-rounded btn-primary"
                                >
                                  {i18n.t("default:_SUBMIT")}
                                </button>
                              </div>
                            </div>
                          )}

                          {activeQuestion + 1 !== filteredQuestions.length && (
                            <div>
                              {filteredQuestions.length > 1 && (
                                <div>
                                  <button
                                    disabled={
                                      this.state.attachmentUploadInProgress ||
                                      activeQuestion === 0
                                    }
                                    onClick={() => this.backQuestion()}
                                    className="btn btn-rounded btn-primary btn-back"
                                  >
                                    {i18n.t("default:_BACK")}
                                  </button>
                                </div>
                              )}
                              <div>
                                <button
                                  disabled={
                                    this.state.attachmentUploadInProgress ||
                                    this.state.isMandatory ||
                                    (this.state.isMandatory &&
                                      this.state.emptyDate) ||
                                    fileIsRequired
                                  }
                                  onClick={() => this.nextQuestion()}
                                  className="btn btn-rounded btn-primary"
                                >
                                  {i18n.t("default:_NEXT")}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              )
          )
        )}
      </React.Fragment>
    );
  }
}

export default connectWithStore(withStyles(styles)(QuestionRenderer), [
  "settings",
  "userLocation",
  "apps",
  "jobQuestions",
  "userAutocomplete",
  "blueprintEditor",
]);
