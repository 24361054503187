import Http from "../http";

export const s3Upload = async (
  params,
  targetSize = null,
  isPrivate = false,
  targetUser = null
) => {
  let path = `/v2/asset/upload`;
  let queryStringApplied = false;
  if (targetSize) {
    path += `?target=${targetSize}`;
    queryStringApplied = true;
  }

  if (isPrivate) {
    path += `${queryStringApplied ? "&" : "?"}private_asset=true`;
    queryStringApplied = true;
  }

  if (targetUser) {
    path += `${queryStringApplied ? "&" : "?"}target_user=${targetUser}`;
    queryStringApplied = true;
  }
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const pdfResize = async (params) => {
  let path = `/v2/asset/pdf-resize/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getPdfPages = async (params) => {
  const { query } = params;
  let path = `/v2/asset/pdf-images?${query}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

const NON_EXISTING_ASSETS = [];
const EXISTING_ASSETS = [];
export const assetExists = (assetUrl) => {
  if (NON_EXISTING_ASSETS.indexOf(assetUrl) !== -1) {
    return false;
  }

  if (EXISTING_ASSETS.indexOf(assetUrl) !== -1) {
    return true;
  }

  try {
    const http = new XMLHttpRequest();

    http.open("HEAD", assetUrl, false);
    http.send();

    return http.status !== 404;
  } catch (e) {
    NON_EXISTING_ASSETS.push(assetUrl);
  }
};

export const getAssetKey = (assetUrl) => {
  let origin = window.location.origin;

  if(origin.startsWith('http://') || origin.startsWith('https://')) {
    origin = origin.split('://')[1];
  }

  let originParts = origin.split('.');

  if(originParts[originParts.length - 1] === 'localhost') {
    originParts = [null].concat(originParts);
  }

  const assetId = assetUrl.split('/').pop();

  let keyString = '';

  if(originParts.length >= 2) {
    const host = originParts[0];
    const key = `host:${host};assetId:${assetId}`;

    keyString += `${assetUrl.indexOf('?') !== -1 ? "&" : "?"}key=${Buffer.from(key).toString('base64')}`;
  }

  return assetUrl + keyString;
}

export const getFileTarget = (assetId, target, preview = false) => {
  if (!assetId) {
    return "";
  }
  let questionAdded = false;

  let url = "/v1/s3/download/" + assetId;

  if (typeof assetId === "object" && assetId.Location) {
    return assetId.Location;
  }

  if (
    assetId &&
    (assetId.toString().startsWith("http") ||
      assetId.toString().startsWith("/static"))
  ) {
    if (assetId.indexOf("-lg") !== -1) {
      return assetId.replace("-lg", "-" + target);
    }
    return assetId;
  }
  if (target) {
    url += "?size=" + target;
    questionAdded = true;
  }

  if (preview) {
    url += `${questionAdded ? "&" : "?"}preview=true`;
    questionAdded = true;
  }

  let origin = window.location.origin;

  if(origin.startsWith('http://') || origin.startsWith('https://')) {
    origin = origin.split('://')[1];
  }

  let originParts = origin.split('.');

  if(originParts[originParts.length - 1] === 'localhost' || (originParts.length > 2 && originParts[originParts.length - 1] === 'localhost')) {
    originParts = [null].concat(originParts);
  }

  if(originParts.length >= 2) {
    const host = originParts[0];
    const key = `host:${host};assetId:${assetId}`;

    url += `${questionAdded ? "&" : "?"}key=${Buffer.from(key).toString('base64')}`;
  }

  return url;
};

export const getFileInfo = async (assetId) => {
  if (typeof assetId === "object") {
    return new Promise((resolve) => resolve(assetId));
  }
  const path = `/v2/asset/info/${assetId}`;

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return null;
  }
};

export const s3Delete = async (params) => {
  const path = `/v1/s3/delete`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const s3DownloadLink = (params) => {
  const path = `/v1/s3/download`;
  return `${path}?file=${params.file}`;
};

export const deleteAsset = async (assetId) => {
  const path = `/v1/s3/delete/${assetId}`;

  try {
    const response = await Http.sharedInstance.withAuth().delete(path);

    return response.data;
  } catch (e) {
    return { success: false };
  }
};
