import React, { useCallback, useEffect, useState } from "react";
import Editor from "draft-js-plugins-editor";
import { convertFromRaw, EditorState, ContentState, CompositeDecorator } from "draft-js";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import i18n from "../../i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
  },
  seeMore: {
    marginTop: "20px",
    color: "#DDE1E7",
    textDecoration: "underline",
  },
}));

// Regular expression for phone numbers
const phoneRegex = /05\d{2}-?\d{7}/g;

// Function to create a "Call" button
const PhoneNumber = (props) => {
  const phoneNumber = props.decoratedText;
  const cleanNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  return (
    <span>
      {props.children}{" "}
      <Button className="callButton" onClick={(e) => {  
        window.location.href = `tel:${cleanNumber}`
      }}>Call</Button>
    </span>
  );
};

// Decorator to find phone numbers and wrap them with the PhoneNumber component
const findPhoneNumbers = (contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = phoneRegex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

// Create a decorator using the findPhoneNumbers function
const phoneNumberDecorator = new CompositeDecorator([
  {
    strategy: findPhoneNumbers,
    component: PhoneNumber,
  },
]);


export const PhoneNumberWithCallButton = ({ value }) => {
  // Function to process the value and replace phone numbers with JSX
  const renderWithPhoneNumbers = (text) => {
    if (!text) return text;

    // Split the text based on phone numbers
    const parts = text.split(phoneRegex);
    const matches = text.match(phoneRegex);

    // If no matches, return the original text
    if (!matches) {
      return <span>{text}</span>;
    }

    // Rebuild the text with "Call" buttons next to the phone numbers
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {matches[index] && (
          <Button className="callButton" variant="link" onClick={() => window.location.href = `tel:${matches[index].replace(/\D/g, '')}`}>
            {matches[index]}
          </Button>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <p dir="auto" className="dialog-tooltip-target">
        {renderWithPhoneNumbers(value)}
      </p>
    </div>
  );
};

function RichTextRenderer({ value, fromSlider }) {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty(phoneNumberDecorator));
  const [multiRowData, setMultiRowData] = useState(false);
  const [fontSize, setFontSize] = useState("inherit");

  const getContentFromValue = useCallback(() => {
    if (value) {
      let val = value;
      if (!isNaN(val)) {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(val.toString())));
        return;
      }

      if (!val.blocks) val.blocks = [];
      if (!val.entityMap) val.entityMap = {};

      if (val.blocks.length >= 3) setFontSize("14px");
      else setFontSize("inherit");
      if (fromSlider) setFontSize("12px");

      const regex = /\B\$question\w+?\s/gm;

      if (val.blocks.length > 3) {
        let previewVal = { ...val };
        let previewBlocks = [previewVal.blocks[0], previewVal.blocks[1], previewVal.blocks[2]];
        previewVal.blocks = previewBlocks;
        setMultiRowData(true);

        const previewContent = convertFromRaw(previewVal);
        const previewValue = previewContent.getPlainText().replaceAll(regex, "");
        const filteredPreviewContent = ContentState.createFromText(previewValue);
        const newPreviewState = EditorState.createWithContent(filteredPreviewContent, phoneNumberDecorator);
        setEditorState(newPreviewState);
      } else {
        setMultiRowData(false);
        const content = convertFromRaw(val);
        const contentValue = content.getPlainText().replaceAll(regex, "");
        const filteredContent = ContentState.createFromText(contentValue);
        const newState = EditorState.createWithContent(filteredContent, phoneNumberDecorator);
        setEditorState(newState);
      }
    }
  }, [value, fromSlider]);

  useEffect(() => {
    getContentFromValue();
  }, [getContentFromValue]);

  const onChange = () => null;

  return (
    <React.Fragment>
      <Grid className={classes.root} style={{ fontSize: fontSize }}>
        <Editor
          id="questionField"
          editorState={editorState}
          readOnly={true}
          onChange={onChange}
        />
      </Grid>
      {multiRowData && (
        <Grid className={classes.seeMore}>{i18n.t("default:_SEE_MORE")}</Grid>
      )}
    </React.Fragment>
  );
}

export default RichTextRenderer;
