export default {
  palette: {
    primary: {
      main: "#0083c9",
    },
  },
  typography: {
    h6: {
      color: "#b0bac9",
      fontSize: "12px",
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "1.13px",
    },
    fontFamily: "Rubik, sans-serif",
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: "9999 !important",
      },
    },
    MuiPopover: {
      root: {
        zIndex: "99999 !important",
      },
    },
  },
};
