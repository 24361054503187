import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../../../i18n";
import GestureIcon from "@material-ui/icons/Gesture";
import { SHAPE } from "../constants";
import { getRgbaColor } from "../utils";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SpaceBarIcon from "@material-ui/icons/SpaceBar";

const useStyles = makeStyles((theme) => ({
  shapeMenuItem: {
    alignItems: "center",
  },
  iconColor: {
    color: ({ displayColor }) => (displayColor ? displayColor : "blue"),
  },
  width: {
    color: "#d0d0e1",
    paddingLeft: 3,
  },
  circleFilled: {
    height: "20px",
    width: "20px",
    background: ({ displayColor }) => (displayColor ? displayColor : "blue"),
    border: ({ displayColor }) =>
      displayColor ? `1px solid ${displayColor}` : "1px solid red",
    borderRadius: "50%",
    flexBasis: "unset",
    //cursor: "pointer",
  },
  rectangleFilled: {
    height: "20px",
    background: ({ displayColor }) => (displayColor ? displayColor : "blue"),
    //cursor: "pointer",
  },
  rectangleOutline: {
    height: "20px",
    border: ({ displayColor }) =>
      displayColor ? `1px solid ${displayColor}` : "1px solid red",
    //cursor: "pointer",
  },
  circleOutline: {
    height: "20px",
    width: "20px",
    border: ({ displayColor }) =>
      displayColor ? `1px solid ${displayColor}` : "1px solid red",
    borderRadius: "50%",
    flexBasis: "unset",
    //cursor: "pointer",
  },
  lamp: {
    color: ({ buttonColor }) => (buttonColor ? buttonColor.hex : "#D0021B"),
  },
  thikLine: {
    height: "4px",
    background: ({ displayColor }) => (displayColor ? displayColor : "blue"),
    //cursor: "pointer",
  },
  thinLine: {
    height: "1px",
    background: ({ displayColor }) => (displayColor ? displayColor : "blue"),
    //cursor: "pointer",
  },
  label: {
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: 5,
  },
  polygonIcon: {
    transform: "rotate(-90deg)",
    fontSize: "2rem",
  },
}));

function ElementContainer({
  type,
  buttonColor,
  label,
  width,
  quantity,
  clickable,
  renderDimensionsPanel,
  renderElementDetails,
}) {
  const displayColor = getRgbaColor(buttonColor);
  const classes = useStyles({ displayColor });

  const getClassName = (type) => {
    switch (type) {
      case SHAPE.RECT_FILLED:
        return classes.rectangleFilled;
      case SHAPE.RECT_OUTLINED:
        return classes.rectangleOutline;
      case SHAPE.CIRCLE_FILLED:
        return classes.circleFilled;
      case SHAPE.CIRCLE_OUTLINED:
        return classes.circleOutline;
      case SHAPE.THICK_LINE:
        return classes.thikLine;
      case SHAPE.THIN_LINE:
        return classes.thinLine;
      case SHAPE.LAMP:
        return classes.lamp;
      default:
        return null;
    }
  };

  const getLabel = (type) => {
    switch (type) {
      case SHAPE.RECT_FILLED:
        return i18n.t("default:_RECT_FILLED");
      case SHAPE.RECT_OUTLINED:
        return i18n.t("default:_RECT_OUTLINED");
      case SHAPE.CIRCLE_FILLED:
        return i18n.t("default:_CIRCLE_FILLED");
      case SHAPE.CIRCLE_OUTLINED:
        return i18n.t("default:_CIRCLE_OUTLINED");
      case SHAPE.THICK_LINE:
        return i18n.t("default:_THICK_LINE");
      case SHAPE.THIN_LINE:
        return i18n.t("default:_THIN_LINE");
      case SHAPE.DOODLE:
        return i18n.t("default:_DOODLE");
      case SHAPE.LAMP:
        return i18n.t("default:_LAMP");
      case SHAPE.POLYGON_FILLED:
        return i18n.t("default:_POLYGON_FILLED");
      case SHAPE.POLYGON_OUTLINED:
        return i18n.t("default:_POLYGON_OUTLINED");
      case SHAPE.POLYGON_OPEN:
        return i18n.t("default:_POLYGON_OPEN");
      default:
        return null;
    }
  };

  return (
    <Grid container className={classes.shapeMenuItem}>
      <Grid
        item
        xs={7}
        className={classes.label}
        onClick={() => clickable && renderElementDetails()}
      >
        <Typography>{label ? label : getLabel(type)}</Typography>
      </Grid>
      <Grid container item xs={5} justify="center" alignItems="center">
        {type === SHAPE.DOODLE && (
          <Grid
            container
            justify="center"
            item
            xs={5}
            className={classes.iconColor}
          >
            <GestureIcon />
          </Grid>
        )}
        {type === SHAPE.POLYGON_OPEN && (
          <Grid
            container
            justify="center"
            item
            xs={5}
            className={classes.iconColor}
          >
            <SpaceBarIcon fontSize="large" />
          </Grid>
        )}
        {type === SHAPE.POLYGON_OUTLINED && (
          <Grid
            container
            justify="center"
            item
            xs={5}
            className={classes.iconColor}
          >
            <BookmarkBorderIcon className={classes.polygonIcon} />
          </Grid>
        )}
        {type === SHAPE.POLYGON_FILLED && (
          <Grid
            container
            justify="center"
            item
            xs={5}
            className={classes.iconColor}
          >
            <BookmarkIcon className={classes.polygonIcon} />
          </Grid>
        )}
        {type === SHAPE.LAMP && (
          <Grid container justifyContent="center" item xs={5} className={classes.lamp}>
            <WbIncandescentIcon />
          </Grid>
        )}
        {[
          SHAPE.LAMP,
          SHAPE.DOODLE,
          SHAPE.POLYGON_FILLED,
          SHAPE.POLYGON_OUTLINED,
          SHAPE.POLYGON_OPEN,
        ].indexOf(type) === -1 && (
          <Grid item xs={5} className={getClassName(type)} />
        )}
        {quantity && (
          <Typography className={classes.width}>{` (${quantity})`}</Typography>
        )}
        {!quantity &&
          width &&
          type !== SHAPE.POLYGON_FILLED &&
          type !== SHAPE.POLYGON_OUTLINED &&
          type !== SHAPE.POLYGON_OPEN && (
            <Typography
              className={classes.width}
              style={{ cursor: /* width.includes("x") && */ "pointer" }}
              onClick={() =>
                clickable &&
                /* width.includes("x") &&  */ renderDimensionsPanel()
              }
            >
              {width}
            </Typography>
          )}
      </Grid>
    </Grid>
  );
}

export default ElementContainer;
