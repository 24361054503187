import React from "react";
import { connectWithStore } from "../../services/redux";
import JobEditor from "../../pages/jobs/components/editor_v2";

function JobEditorPanel({
  panelStyles,
  onClose,
  onDelete,
  rightPanelJob,
  dispatch,
  fromBlueprints,
}) {
  return (
    <div
      className="GridViewWithRightPanel"
      style={panelStyles ? panelStyles : {}}
    >
      <div
        className="col-md-4 RightPanel"
        style={{
          top: fromBlueprints ? 0 : 65,
          height: fromBlueprints ? "100%" : "calc(100% - 65px)",
          zIndex: fromBlueprints ? 10000 : 10,
        }}
      >
        <div className="RightPanelWrapper">
          <div className="RightPanelContent">
            <i
              className="mdi mdi-chevron-left close desktopOnly"
              onClick={() => onClose()}
            />
            <JobEditor
              openQuestion={null}
              resetOpenQuestion={() => false}
              dispatch={dispatch}
              jobs={[rightPanelJob]}
              refresh={() => null}
              onDeselect={() => null}
              onUpdate={(job) => console.log(job)}
              onDelete={() => onDelete()}
              initial={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connectWithStore(JobEditorPanel, []);
