import React from "react";
import { getFileTarget } from "./api/file_upload";

export const mapJobForAutoselect = ({ _id, ID, jobType }) => {
  return {
    value: _id,
    label: "#" + ID,
    avatar: !jobType.icon ? (
      <i className="mdi mdi-briefcase-outline circle" />
    ) : (
      <img src={getFileTarget(jobType.icon, "100x100")} alt={jobType.title} />
    ),
  };
};
