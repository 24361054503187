import {
  USER_AUTOCOMPLETE_WITH_AVAILABILITY,
  USER_AUTOCOMPLETE_SET_AVAILABILITIES,
  USER_AUTOCOMPLETE_RESET,
  USER_AUTOCOMPLETE_SET_JOB_DATE,
  USER_AUTOCOMPLETE_SET_TAB,
  USER_AUTOCOMPLETE_SET_USERS,
  USER_AUTOCOMPLETE_SET_ASSIGNED_USERS,
  USER_AUTOCOMPLETE_RESET_KEEPING_USERS,
} from "../constants";

export const USER_AUTOCOMPLETE_TAB = {
  SKILLS: 0,
  AVAILABILITY: 1,
};

const initialState = {
  availabilities: [],
  jobDate: null,
  withAvailabilities: false,
  tab: USER_AUTOCOMPLETE_TAB.AVAILABILITY,
  users: [],
  assignedUsers: [],
};

const userAutocomplete = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case USER_AUTOCOMPLETE_WITH_AVAILABILITY:
      return Object.assign({}, state, {
        withAvailabilities: true,
      });
    case USER_AUTOCOMPLETE_SET_AVAILABILITIES:
      return Object.assign({}, state, {
        availabilities: data,
      });
    case USER_AUTOCOMPLETE_SET_JOB_DATE:
      return Object.assign({}, state, {
        jobDate: data,
      });
    case USER_AUTOCOMPLETE_RESET:
      return Object.assign({}, state, initialState);
    case USER_AUTOCOMPLETE_RESET_KEEPING_USERS:
      const newState = { ...initialState };
      newState.users = state.users;
      return Object.assign({}, state, newState);
    case USER_AUTOCOMPLETE_SET_TAB:
      return Object.assign({}, state, {
        tab: data,
      });
    case USER_AUTOCOMPLETE_SET_USERS:
      return Object.assign({}, state, {
        users: data,
      });
    case USER_AUTOCOMPLETE_SET_ASSIGNED_USERS:
      return Object.assign({}, state, {
        assignedUsers: data,
      });
    default:
      return state;
  }
};

export default userAutocomplete;
