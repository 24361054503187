import React, { useCallback, useState, useEffect } from "react";
import FieldPreview from "./field_preview";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AutoComplete from "../../../components/autoselect/v2autoselect";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import withModal from "../../../hoc/withModal";
import {
  getListSuggestions,
  createListSuggestion,
  updateSignleProjectField,
  deleteListSuggestion,
} from "../../../services/api/projects";
import { Button, Grid } from "@material-ui/core";
import Switch from "../../../components/switch/switch";
import { CurrentProject } from "../../../redux/actions";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import { canUserEdit } from "../../../services/access";
import CSVParser from '../../../components/dropzone/csv_parser'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  listRoot: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
    maxWidth: "100%",
  },
  labelSpan: {
    paddingLeft: "20px",
    overflowWrap: "break-word",
  },
  roleSpan: {
    textAlign: "right",
  },
  labelSpanSelected: {
    color: "#408FF8",
    overflowWrap: "break-word",
  },
  roleSpanSelected: {
    textAlign: "right",
    color: "#408FF8",
  },
  userAvatar: {
    background: "black",
    fontSize: "26px",
    width: "36px",
    height: "36px",
  },
  userAvatarSelected: {
    border: "6px solid #fff",
    width: "46px !important",
    height: "46px !important",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
  chip: {
    width: "100%",
    marginRight: "5px",
    marginBottom: "5px",
    color: "inherit",
  },
}));

function OptionRow({ option, selected, canDelete, onDelete }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Grid item xs={1}>
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon />}
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected.includes(option)}
          />
        </Grid>
        <Grid item xs={11}>
          <ListItemText className={classes.labelSpan} primary={option.label} />
          {canDelete && (
            <Button
              variant="contained"
              delete="1"
              color="secondary"
              onClick={() => onDelete(option.value)}
            >
              <DeleteForeverIcon delete="1" />
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function SelectedRow({ selected, index, item, onClick }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} key={index} button onClick={onClick}>
      <Checkbox
        icon={<RadioButtonUncheckedOutlinedIcon />}
        checkedIcon={<CheckCircleIcon className={classes.checkedUserIcon} />}
        color="primary"
        style={{ marginRight: 8 }}
        checked={selected.includes(item)}
      />
      <ListItemText
        className={classes.labelSpanSelected}
        primary={item.label}
      />
    </ListItem>
  );
}

function ListAutocomplete({
  id,
  selected,
  onChange,
  onDeselect,
  project,
  isPrivate,
  accessRules,
  user,
  onCsvImport
}) {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [disableDelete, setDisableDelete] = useState(true);

  const getOptionLabel = useCallback(({ label }) => {
    return label;
  }, []);

  const suggestionTransformer = ({ _id, value }) => {
    return {
      label: value,
      value: _id,
    };
  };

  const getSuggestions = useCallback(async () => {
    const { data, success } = await getListSuggestions({
      group: id,
      project: project._id,
      isPrivate: isPrivate || false,
    });
    if (success) setSuggestions(data.map(suggestionTransformer));
  }, [id, project._id, isPrivate]);

  const createNewSuggestion = useCallback(
    async (value) => {
      await createListSuggestion({
        value,
        group: id,
        project: project._id,
        isPrivate: isPrivate || false,
      });
      await getSuggestions();
    },
    [getSuggestions, id, project._id, isPrivate]
  );

  const onDelete = useCallback(
    async (id) => {
      await deleteListSuggestion({
        id,
      });
      await getSuggestions();
    },
    [getSuggestions]
  );

  const filterSuggestion = (suggestion) => {
    return selected.filter((s) => s.value === suggestion.value).length === 0;
  };

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  const options = suggestions.filter(filterSuggestion);

  return (
    <React.Fragment>
      {canUserEdit(user, accessRules) && <CSVParser onChange={(data) => onCsvImport(data.map((row) => ({
        label: row,
        value: row
      })))} />}
      <AutoComplete
        ComponentAfter={() => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {canUserEdit(user, accessRules) && (
                <Switch
                  className="float-switch"
                  label={i18n.t("default:_DISABLE_DELETE")}
                  onChange={() => {
                    setDisableDelete(disableDelete ? false : true);
                  }}
                  checked={disableDelete}
                  labelLeft={false}
                />
              )}
            </div>
          );
        }}
        withoutSearch={!canUserEdit(user, accessRules)}
        list={options}
        selected={selected}
        onChange={(option) => onChange(option)}
        renderOption={(option, index) => (
          <OptionRow
            index={index}
            selected={selected}
            option={option}
            canDelete={!disableDelete}
            onDelete={(id) => onDelete(id)}
          />
        )}
        getOptionLabel={getOptionLabel}
        noOptionsDisplay="none"
        text={i18n.t("default:_TYPE_TEXT_SEARCH")}
        canCreateNew={true}
        createNew={(value) => createNewSuggestion(value)}
        withSearchButton={true}
      />
      {selected && selected.length > 0 ? (
        <List className={classes.root}>
          {selected.map((row, index) => {
            return (
              <SelectedRow
                key={index}
                item={row}
                selected={selected}
                onClick={() =>
                  canUserEdit(user, accessRules) && onDeselect(row)
                }
              />
            );
          })}
        </List>
      ) : null}
    </React.Fragment>
  );
}

const ListAutocompleteModal = withModal(ListAutocomplete);

function ListModal({
  visible,
  onClose,
  id,
  title,
  project,
  dispatch,
  isPrivate,
  accessRules,
  user,
  onUpdate,
}) {
  const [selected, setSelected] = useState([]);

  const updateSelected = useCallback(async () => {
    if (project[id]) {
      setSelected(project[id]);
    }
  }, [id, project]);

  useEffect(() => {
    updateSelected();
  }, [updateSelected]);

  const change = useCallback(
    (option) => {
      canUserEdit(user, accessRules) && setSelected(selected.concat([option]));
    },
    [selected, user, accessRules]
  );

  const deselect = useCallback(
    (option) => {
      const temp = [...selected];
      const filter = temp.filter((o) => o.value === option.value);
      if (filter.length > 0) {
        const index = temp.indexOf(filter[0]);
        if (index !== -1) {
          temp.splice(index, 1);
          setSelected(temp);
        }
      }
    },
    [selected]
  );

  const onCsvImport = useCallback((importData) => {
    setSelected([
      ...selected,
      ...importData
    ])
  }, [selected])

  const saveProject = async () => {
    await updateSignleProjectField({
      id: project._id,
      field: {
        id,
        value: selected,
      },
    });
    dispatch(
      CurrentProject.updateProject({
        [id]: selected,
      })
    );
    const updatedProject = { ...project };
    updatedProject[id] = selected;
    if (onUpdate) {
      onUpdate(updatedProject);
    }
    onClose();
  };
  const FooterComponent = function FooterComponent() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{
          padding: 10,
          paddingRight: 20,
        }}
      >
        <Button
          onClick={() => saveProject()}
          variant="contained"
          color="primary"
        >
          {i18n.t("default:_SELECT")}
        </Button>
      </Grid>
    );
  };
  return (
    <ListAutocompleteModal
      visible={visible}
      onClose={() => onClose()}
      title={title}
      id={id}
      onCsvImport={onCsvImport}
      FooterComponent={canUserEdit(user, accessRules) && FooterComponent}
      selected={selected}
      onChange={change.bind(this)}
      onDeselect={deselect.bind(this)}
      project={project}
      isPrivate={isPrivate}
      accessRules={accessRules}
      user={user}
    />
  );
}

const ListModalConnected = connectWithStore(ListModal, []);

function ProjectList({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  isPrivate,
  user,
  onUpdate,
}) {
  const formatPreview = useCallback(
    (value) => {
      if (editable) return [];
      if (value && value.length > 0) {
        const newValue = value.slice(0, 4);
        const remaining = value.length - 4;
        if (remaining > 0) {
          newValue.push({
            label: `${remaining} ${i18n.t("default:_MORE")}`,
            value: -1,
          });
        }
        return newValue;
      }
      return value;
    },
    [editable]
  );

  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const { project } = currentProject;

  const [finalValue, setFinalValue] = useState(
    formatPreview(
      currentProject.project && currentProject.project[id]
        ? currentProject.project[id]
        : []
    )
  );

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  useEffect(() => {
    if (editable) return;
    setFinalValue(
      formatPreview(
        currentProject.project[id] ? currentProject.project[id] : []
      )
    );
  }, [
    id,
    project,
    editable,
    setFinalValue,
    currentProject.project,
    formatPreview,
  ]);
  return (
    <div>
      {!editable && (
        <ListModalConnected
          onClose={onClose}
          id={id}
          visible={modalVisible}
          title={label}
          isPrivate={isPrivate}
          project={project}
          accessRules={accessRules}
          user={user}
          onUpdate={onUpdate}
        />
      )}
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.LIST]}
        onClick={() => (!editable ? setModalVisible(true) : null)}
        accessRules={accessRules}
      >
        {!editable && (
          <List className={classes.listRoot}>
            {finalValue.length > 0 &&
              finalValue.map(({ label, value }, index) => {
                return (
                  <ListItem className={classes.listItemRoot} key={index}>
                    <Chip
                      onClick={() => setModalVisible(true)}
                      className={classes.chip}
                      label={label}
                      variant="outlined"
                    />
                  </ListItem>
                );
              })}
          </List>
        )}
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(ProjectList, ["currentProject", "user"]);
