import { JOB_ROW_SET_ANSWERS_MODAL } from "../constants";

const initialState = {
  showAnswerModalFor: "",
};

const jobRow = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case JOB_ROW_SET_ANSWERS_MODAL:
      return Object.assign({}, state, {
        showAnswerModalFor: data,
      });
    default:
      return state;
  }
};

export default jobRow;
