import List from "./list";
import { SystemListType } from "../../../services/question";
import {
  getJobTypeList,
  //getProjectList,
  getUsersList,
} from "../../../services/api/answer";
import {
  getAllProjectList,
  getListSuggestions,
} from "../../../services/api/projects";
const avatar = require("../../../assets/img/project-placeholder.png");

export const getChilds = async (question, filters, project) => {
  if (question && question.settings) {
    let result;
    let params = {
      filters: filters,
    };
    let response;
    switch (question.settings.systemListType) {
      case SystemListType.PROJECTS:
        const projectsResponse = await getAllProjectList();
        const filterIds = filters.map((filter) => filter.id);
        const filteredProjects = projectsResponse.docs
          ? projectsResponse.docs.filter((project) =>
              filterIds.includes(project._id)
            )
          : [];
        //if no filter is applied render all projects as options
        response =
          filteredProjects.length > 0
            ? filteredProjects
            : projectsResponse.docs;
        result = response.map((project) => {
          return {
            value: project.name,
            avatar: project.avatar,
          };
        });
        break;
      case SystemListType.USERS:
        response = await getUsersList(params);
        const filterParams = filters ? filters.map((filter) => filter.id) : [];
        //filter response by user role
        result = response
          .filter((user) =>
            filterParams.length > 0
              ? filterParams.indexOf(user.role) !== -1
              : user
          )
          .map((user) => {
            return {
              value: user.name
                ? `${user.name.first} ${user.name.last}`
                : user.email,
              avatar: user.avatar,
            };
          });
        break;
      case SystemListType.JOB_TYPES:
        response = await getJobTypeList(params);
        result = response.map((jobType) => {
          return {
            value: jobType.title,
            avatar: jobType.icon ? jobType.icon : null,
          };
        });
        break;
      default:
        response = await getListSuggestions({
          group: question.settings.systemListType,
        });
        result =
          response.success && response.data && response.data.length > 0
            ? response.data.map((item) => item.value)
            : [];
        break;
    }
    return result;
  }
};

export default class SystemList extends List {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      childs: [],
      ID: props.question.ID || null,
      filters: props.question.settings.filter,
    };
  }
  getDefaultAvatar() {
    return avatar;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.question.ID !== state.ID) {
      return {
        ID: nextProps.question.ID,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevProps.question.ID !== this.props.question.ID ||
      JSON.stringify(prevProps.question.settings.filter) !==
        JSON.stringify(this.state.filters)
    ) {
      this.setState(
        {
          filters: this.props.question.settings.filter,
        },
        () => this.getList()
      );
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getList();
  }

  async getList() {
    const result = await getChilds(this.props.question, this.state.filters);
    this.setState({
      childs: result,
    });
  }

  getChilds() {
    return this.state.childs;
  }
}
