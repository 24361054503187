import { ROLE } from "./roles";

export const canEdit = (user) => {
  return ["inspector"].indexOf(user.profile.role) === -1;
};

export const canEditProject = (user, settings) => {
    return canEdit(user) || settings.security.allowInspectorManageProjects === true;
}

export const canUserEdit = (user, accessRules) => {
  if (user.profile.role === ROLE.INSPECTOR) {
    return accessRules && accessRules.inspector.update;
  } else if (user.profile.role === ROLE.PROJECT_MANAGER) {
    return accessRules && accessRules.project_manager.update;
  } else if (user.profile.role === ROLE.CLIENT) {
    return accessRules && accessRules.client.update;
  } else {
    return true;
  }
};

export const canUserRead = (user, accessRules) => {
  if (user.profile.role === ROLE.INSPECTOR) {
    return accessRules && accessRules.inspector && accessRules.inspector.read;
  } else if (user.profile.role === ROLE.PROJECT_MANAGER) {
    return (
      accessRules &&
      accessRules.project_manager &&
      accessRules.project_manager.read
    );
  } else if (user.profile.role === ROLE.CLIENT) {
    return accessRules && accessRules.client && accessRules.client.read;
  } else {
    return true;
  }
};

export const canSetBp = (user, accessRules) => {
  if (user.profile.role === ROLE.INSPECTOR) {
    return accessRules && accessRules.inspector && accessRules.inspector.set;
  } else if (user.profile.role === ROLE.PROJECT_MANAGER) {
    return (
      accessRules &&
      accessRules.project_manager &&
      accessRules.project_manager.set
    );
  } else if (user.profile.role === ROLE.CLIENT) {
    return accessRules && accessRules.client && accessRules.client.set;
  } else {
    return true;
  }
};

export const canEraseJob = (settings, user) => {
  const { allowErase } = settings.security;
  const { role } = user.profile;
  const condition =
    allowErase === false &&
    [ROLE.INSPECTOR, ROLE.PROJECT_MANAGER].includes(role);
  return !condition;
};

export const canCloneJob = (settings, user) => {
  const { allowInspectorDuplicate } = settings.security;
  const { role } = user.profile;
  return !(allowInspectorDuplicate === false && role === ROLE.INSPECTOR);
};

export const shouldDisplayUserField = (
  settings,
  loggedUser,
  currentUser,
  field
) => {
  const { userFields } = settings;
  if (loggedUser.profile.role === ROLE.SYSTEM_ADMIN) {
    return true;
  }
  if (
    loggedUser.profile.id === currentUser.id &&
    userFields &&
    userFields[field] &&
    userFields[field].user
  ) {
    return true;
  }
  if (
    userFields &&
    userFields[field] &&
    userFields[field][loggedUser.profile.role]
  ) {
    return true;
  }
  return false;
};
