import { PROJECTS_LIST, PROJECTS_SHOW_ACTION_BUTTONS } from "../constants";

const initialState = {
  projectsList: [],
  showActionButtons: null,
};

const projects = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case PROJECTS_LIST:
      return Object.assign({}, state, {
        projectsList: data,
      });
    case PROJECTS_SHOW_ACTION_BUTTONS:
      return Object.assign({}, state, {
        showActionButtons: data,
      });
    default:
      return state;
  }
};

export default projects;
