import React from "react";
import { GoogleLogin } from '@react-oauth/google';
// const GoogleLogin = () => {
//   return <></>
// }

export default function GoogleButton({
  handleSocialLogin,
  handleSocialLoginFailure,
  label,
  loading,
  disabled,
}) {
  return (
    <GoogleLogin
      theme="filled_black"
      shape="pill"
      onSuccess={handleSocialLogin}
      onError={handleSocialLoginFailure}
      disabled={disabled}
    />
  );
}
