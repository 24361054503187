import i18n from "../i18n";
export const ROLE = {
  SYSTEM_ADMIN: "sys_admin",
  BUSINESS_ADMIN: "business_admin",
  PROJECT_MANAGER: "project_manager",
  INSPECTOR: "inspector",
  CLIENT: "client",
};

export const canManageRole = (userRole, targetRole) => {
  const roles = Object.values(ROLE);

  return (
    roles.indexOf(userRole) !== -1 &&
    roles.indexOf(targetRole) !== -1 &&
    roles.indexOf(userRole) <= roles.indexOf(targetRole)
  );
};

export const getRolesWithLabels = (withClient) => {
  return [
    {
      label: i18n.t("default:_SYS_ADMIN"),
      value: ROLE.SYSTEM_ADMIN,
    },
    {
      label: i18n.t("default:_BUSINESS_ADMIN"),
      value: ROLE.BUSINESS_ADMIN,
    },
    {
      label: i18n.t("default:_PROJECT_MANAGER"),
      value: ROLE.PROJECT_MANAGER,
    },
    {
      label: i18n.t("default:_INSPECTOR"),
      value: ROLE.INSPECTOR,
    },
    {
      label: i18n.t("default:_CLIENT"),
      value: ROLE.CLIENT,
    },
  ];
};
