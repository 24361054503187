export const WIZARD_JOB_SELECT_PROJECT = "WIZARD_SELECT_PROJECT";
export const WIZARD_JOB_SELECT_PREV_JOB = "WIZARD_SELECT_PREV_JOB";
export const WIZARD_JOB_SELECT_NEXT_JOB = "WIZARD_SELECT_NEXT_JOB";
export const WIZARD_JOB_SELECT_JOB_TYPE = "WIZARD_SELECT_JOB_TYPE";
export const WIZARD_JOB_SELECT_START_DATE = "WIZARD_SELECT_START_DATE";
export const WIZARD_JOB_ANSWER_MAIN_QUESTIONS =
  "WIZARD_JOB_ANSWER_MAIN_QUESTIONS";
export const WIZARD_JOB_ASSIGN_USERS = "WIZARD_JOB_ASSIGN_USERS";
export const WIZARD_JOB_SET_ID = "WIZARD_JOB_SET_ID";
export const WIZARD_JOB_SET_SKILLS = "WIZARD_JOB_SET_SKILLS";
export const WIZARD_JOB_SET_MAIN_QUESTIONS = "WIZARD_JOB_SET_MAIN_QUESTIONS";
export const WIZARD_JOB_RESET = "WIZARD_JOB_RESET";
export const WIZARD_JOB_SET_LINK_ID = "WIZARD_JOB_SET_LINK_ID";

export const WIZARD_PROJECT_SET_NAME = "WIZARD_PROJECT_SET_NAME";
export const WIZARD_PROJECT_SET_ID = "WIZARD_PROJECT_SET_ID";
export const WIZARD_PROJECT_SET_SKILL_FIELDS =
  "WIZARD_PROJECT_SET_SKILL_FIELDS";
export const WIZARD_PROJECT_SET_CURRENT_INDEX =
  "WIZARD_PROJECT_SET_CURRENT_INDEX";
export const WIZARD_PROJECT_RESET = "WIZARD_PROJECT_RESET";

export const WIZARD_USER_SET_EMAIL = "WIZARD_USER_SET_EMAIL";
export const WIZARD_USER_SELECT_ROLE = "WIZARD_USER_SELECT_ROLE";
export const WIZARD_USER_SELECT_COMPANIES = "WIZARD_USER_SELECT_COMPANIES";
export const WIZARD_USER_SELECT_PROJECTS = "WIZARD_USER_SELECT_PROJECTS";

export const WIZARD_USER_RESET = "WIZARD_USER_RESET";

export const WIZARD_USER_SET_ID = "WIZARD_USER_SET_ID";

export const WIZARD_SET_TYPE = "WIZARD_SET_TYPE";
export const WIZARD_SET_TITLE = "WIZARD_SET_TITLE";

export const FIELD_EDITOR_SET_VISIBLE = "FIELD_EDITOR_SET_VISIBLE";
export const FIELD_EDITOR_ADD_FIELD = "FIELD_EDITOR_ADD_FIELD";
export const FIELD_EDITOR_REMOVE_FIELD = "FIELD_EDITOR_REMOVE_FIELD";
export const FIELD_EDITOR_SET_FIELD = "FIELD_EDITOR_SET_FIELD";
export const SET_FIELD_LIST = "SET_FIELD_LIST";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const UPDATE_FIELD = "UPDATE_FIELD";

export const CURRENT_PROJECT_SET_PROJECT = "CURRENT_PROJECT_SET_PROJECT";
export const CURRENT_PROJECT_RESET_PROJECT = "CURRENT_PROJECT_RESET_PROJECT";
export const CURRENT_PROJECT_UPDATE_PROJECT = "CURRENT_PROJECT_UPDATE_PROJECT";

export const USER_AUTOCOMPLETE_WITH_AVAILABILITY =
  "USER_AUTOCOMPLETE_WITH_AVAILABILITY";
export const USER_AUTOCOMPLETE_SET_AVAILABILITIES =
  "USER_AUTOCOMPLETE_SET_AVAILABILITIES";
export const USER_AUTOCOMPLETE_RESET = "USER_AUTOCOMPLETE_RESET";
export const USER_AUTOCOMPLETE_RESET_KEEPING_USERS =
  "USER_AUTOCOMPLETE_RESET_KEEPING_USERS";
export const USER_AUTOCOMPLETE_SET_JOB_DATE = "USER_AUTOCOMPLETE_SET_JOB_DATE";
export const USER_AUTOCOMPLETE_SET_TAB = "USER_AUTOCOMPLETE_SET_TAB";
export const USER_AUTOCOMPLETE_SET_USERS = "USER_AUTOCOMPLETE_SET_USERS";
export const USER_AUTOCOMPLETE_SET_ASSIGNED_USERS =
  "USER_AUTOCOMPLETE_SET_ASSIGNED_USERS";

export const BLUEPRINT_EDITOR_REMOVE_ELEMENT =
  "BLUEPRINT_EDITOR_REMOVE_ELEMENT";
export const BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY =
  "BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY";
export const BLUEPRINT_EDITOR_RESET = "BLUEPRINT_EDITOR_RESET";
export const BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA =
  "BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA";
export const BLUEPRINT_EDITOR_SET_ELEMENTS = "BLUEPRINT_EDITOR_SET_ELEMENTS";
export const BLUEPRINT_EDITOR_ADD_ELEMENT = "BLUEPRINT_EDITOR_ADD_ELEMENT";
export const BLUEPRINT_EDITOR_UPDATE_ELEMENT =
  "BLUEPRINT_EDITOR_UPDATE_ELEMENT";
export const BLUEPRINT_EDITOR_OPEN_FILE = "BLUEPRINT_EDITOR_OPEN_FILE";
export const BLUEPRINT_EDITOR_SET_LAYER = "BLUEPRINT_EDITOR_SET_LAYER";
export const BLUEPRINT_EDITOR_UPDATE_LAYER = "BLUEPRINT_EDITOR_UPDATE_LAYER";
export const BLUEPRINT_EDITOR_CHANGE_TOOL = "BLUEPRINT_EDITOR_CHANGE_TOOL";
export const BLUEPRINT_EDITOR_CHANGE_MODE = "BLUEPRINT_EDITOR_CHANGE_MODE";
export const BLUEPRINT_EDITOR_SET_TOOLS = "BLUEPRINT_EDITOR_SET_TOOLS";
export const BLUEPRINT_EDITOR_OPEN_LAYER_PANEL =
  "BLUEPRINT_EDITOR_OPEN_LAYER_PANEL";
export const BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL =
  "BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL";
export const BLUEPRINT_EDITOR_SET_KONVA_APP = "BLUEPRINT_EDITOR_SET_KONVA_APP";
export const BLUEPRINT_EDITOR_UPDATE_HISTORY =
  "BLUEPRINT_EDITOR_UPDATE_HISTORY";
export const BLUEPRINT_EDITOR_UNDO = "BLUEPRINT_EDITOR_UNDO";
export const BLUEPRINT_EDITOR_REDO = "BLUEPRINT_EDITOR_REDO";
export const BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT =
  "BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT";
export const BLUEPRINT_EDITOR_SET_JOBS = "BLUEPRINT_EDITOR_SET_JOBS";
export const BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL =
  "BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL";
export const BLUEPRINT_EDITOR_SET_SHOW_FILTERS =
  "BLUEPRINT_EDITOR_SET_SHOW_FILTERS";
export const BLUEPRINT_EDITOR_SET_QUESTION_OPEN =
  "BLUEPRINT_EDITOR_SET_QUESTION_OPEN";
export const BLUEPRINT_EDITOR_SET_EXPORT_IMAGE =
  "BLUEPRINT_EDITOR_SET_EXPORT_IMAGE";
export const BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT =
  "BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT";
export const BLUEPRINT_EDITOR_SET_FILENAME = "BLUEPRINT_EDITOR_SET_FILENAME";
export const BLUEPRINT_EDITOR_SET_FILE_OPTIONS =
  "BLUEPRINT_EDITOR_SET_FILE_OPTIONS";
export const BLUEPRINT_EDITOR_SET_FILE_ID = "BLUEPRINT_EDITOR_SET_FILE_ID";
export const BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER =
  "BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER";
export const BLUEPRINT_EDITOR_SET_IMAGE_ROTATION =
  "BLUEPRINT_EDITOR_SET_IMAGE_ROTATION";
export const BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE =
  "BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE";
export const BLUEPRINT_EDITOR_SET_FILE_PAGE = "BLUEPRINT_EDITOR_SET_FILE_PAGE";
export const BLUEPRINT_EDITOR_SET_MEASUREMENTS =
  "BLUEPRINT_EDITOR_SET_MEASUREMENTS";
export const BLUEPRINT_EDITOR_SET_IS_MEASURING =
  "BLUEPRINT_EDITOR_SET_IS_MEASURING";
export const BLUEPRINT_EDITOR_RESET_MEASUREMENTS =
  "BLUEPRINT_EDITOR_RESET_MEASUREMENTS";
export const BLUEPRINT_EDITOR_SET_FLOATING_INPUT =
  "BLUEPRINT_EDITOR_SET_FLOATING_INPUT";
export const BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED =
  "BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED";
export const BLUEPRINT_EDITOR_SET_PDF_INFO = "BLUEPRINT_EDITOR_SET_PDF_INFO";
export const BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE =
  "BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE";
export const BLUEPRINT_EDITOR_SET_PDF_RESIZING =
  "BLUEPRINT_EDITOR_SET_PDF_RESIZING";
export const BLUEPRINT_EDITOR_SHOW_EDIT_MODAL =
  "BLUEPRINT_EDITOR_SHOW_EDIT_MODAL";
export const BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED =
  "BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED";
export const BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN =
  "BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN";
export const BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT =
  "BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT";
export const BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT =
  "BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT";
export const BLUEPRINT_EDITOR_SELECT_ELEMENT =
  "BLUEPRINT_EDITOR_SELECT_ELEMENT";
export const BLUEPRINT_EDITOR_SELECT_LINE = "BLUEPRINT_EDITOR_SELECT_LINE";
export const BLUEPRINT_EDITOR_DESELECT_ELEMENT =
  "BLUEPRINT_EDITOR_DESELECT_ELEMENT";
export const BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB =
  "BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB";
export const BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES =
  "BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES";
export const BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE =
  "BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE";
export const BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL =
  "BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL";
export const BLUEPRINT_EDITOR_SET_CROP_SIZE = "BLUEPRINT_EDITOR_SET_CROP_SIZE";
export const BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP =
  "BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP";
export const BLUEPRINT_EDITOR_CANCEL_CROP = "BLUEPRINT_EDITOR_CANCEL_CROP";

export const SHIFT_VIEW_SET_USERS = "SHIFT_VIEW_SET_USERS";
export const SHIFT_VIEW_SET_JOBS = "SHIFT_VIEW_SET_JOBS";
export const SHIFT_VIEW_SET_RANGE = "SHIFT_VIEW_SET_RANGE";
export const SHIFT_VIEW_SET_USER_PANEL_MODE = "SHIFT_VIEW_SET_USER_PANEL_MODE";
export const SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED =
  "SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED";
export const SHIFT_VIEW_SET_SHOW_STATUSES = "SHIFT_VIEW_SET_SHOW_STATUSES";
export const SHIFT_VIEW_SET_SHOW_JOB_TYPES = "SHIFT_VIEW_SET_SHOW_JOB_TYPES";
export const SHIFT_VIEW_SET_SEARCH_PHRASE = "SHIFT_VIEW_SET_SEARCH_PHRASE";

export const SHIFT_VIEW_SET_TEMP_ASSIGNED = "SHIFT_VIEW_SET_TEMP_ASSIGNED";
export const SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES =
  "SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES";
export const SHIFT_VIEW_SET_DROPZONES = "SHIFT_VIEW_SET_DROPZONES";
export const SHIFT_VIEW_SET_DRAGGER = "SHIFT_VIEW_SET_DRAGGER";
export const SHIFT_VIEW_SET_RIGHT_PANEL_USER =
  "SHIFT_VIEW_SET_RIGHT_PANEL_USER";
export const SHIFT_VIEW_SET_RIGHT_PANEL_JOB = "SHIFT_VIEW_SET_RIGHT_PANEL_JOB";
export const SHIFT_VIEW_SET_OUTSIDE_CONFIRM = "SHIFT_VIEW_SET_OUTSIDE_CONFIRM";
export const SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB =
  "SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB";
export const SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP =
  "SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP";
export const SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES =
  "SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES";
export const SHIFT_VIEW_SET_IS_ASSIGN = "SHIFT_VIEW_SET_IS_ASSIGN";
export const SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS =
  "SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS";
export const SHIFT_VIEW_SET_SELECTED_USER = "SHIFT_VIEW_SET_SELECTED_USER";
export const SHIFT_VIEW_SET_DRAGGED = "SHIFT_VIEW_SET_DRAGGED";
export const SHIFT_VIEW_SET_USER_HOVER = "SHIFT_VIEW_SET_USER_HOVER";
export const SHIFT_VIEW_SET_DROPPABLE_CACHE = "SHIFT_VIEW_SET_DROPPABLE_CACHE";
export const SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES =
  "SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES";
export const SHIFT_VIEW_SET_USERS_LOADING = "SHIFT_VIEW_SET_USERS_LOADING";
export const SHIFT_VIEW_SET_JOBS_LOADING = "SHIFT_VIEW_SET_JOBS_LOADING";
export const SHIFT_VIEW_SET_MINI_VIEW = "SHIFT_VIEW_SET_MINI_VIEW";
export const SHIFT_VIEW_SET_SHARE_MODAL = "SHIFT_VIEW_SET_SHARE_MODAL";
export const SHIFT_VIEW_SET_SHARE_LINK = "SHIFT_VIEW_SET_SHARE_LINK";
export const SHIFT_VIEW_LOAD_DATA = "SHIFT_VIEW_LOAD_DATA";
export const SHIFT_VIEW_SET_ONE_DAY_VIEW = "SHIFT_VIEW_SET_ONE_DAY_VIEW";

export const SET_STEPS = "SET_STEPS";
export const ADD_STEP = "ADD_STEP";
export const SET_STEPS_MODAL_OPEN = "SET_STEPS_MODAL_OPEN";
export const ADD_STEP_QUESTIONS = "ADD_STEP_QUESTIONS";
export const SET_BUTTON_PRESSED = "SET_BUTTON_PRESSED";
export const SET_LAST_ACTIVE_QUESTION = "SET_LAST_ACTIVE_QUESTION";
export const SET_PREVIOUS_STEP = "SET_PREVIOUS_STEP";
export const SET_STEP_PATH_ACTIVATED = "SET_STEP_PATH_ACTIVATED";

export const USER_LOCATION_SET_LOCATION = "USER_LOCATION_SET_LOCATION";

export const CURRENT_WIDGET_SET_WIDGET = "CURRENT_WIDGET_SET_WIDGET";
export const CURRENT_WIDGET_RESET_WIDGET = "CURRENT_WIDGET_RESET_WIDGET";
export const CURRENT_WIDGET_UPDATE_WIDGET = "CURRENT_WIDGET_UPDATE_WIDGET";
export const CURRENT_WIDGET_SET_MOBILE_TAB = "CURRENT_WIDGET_SET_MOBILE_TAB";

export const INSTANCE_WIZARD_DEV_PROCEDURE = "INSTANCE_WIZARD_DEV_PROCEDURE";
export const INSTANCE_WIZARD_EMAIL = "INSTANCE_WIZARD_EMAIL";
export const INSTANCE_WIZARD_PHONE = "INSTANCE_WIZARD_PHONE";
export const INSTANCE_WIZARD_USER_NAME = "INSTANCE_WIZARD_USER_NAME";
export const INSTANCE_WIZARD_PASSWORD = "INSTANCE_WIZARD_PASSWORD";
export const INSTANCE_WIZARD_DB = "INSTANCE_WIZARD_DB";
export const INSTANCE_WIZARD_INSTANCE_NAME = "INSTANCE_WIZARD_INSTANCE_NAME";
export const INSTANCE_WIZARD_INCOGNITO = "INSTANCE_WIZARD_INCOGNITO";
export const INSTANCE_WIZARD_ADD_YOURSELF = "INSTANCE_WIZARD_WITH_YOURSELF";
export const INSTANCE_WIZARD_TOKEN = "INSTANCE_WIZARD_TOKEN";
export const INSTANCE_WIZARD_ENABLE_SSO = "INSTANCE_WIZARD_ENABLE_SSO";

export const TABLE_SET_TYPE = "TABLE_SET_TYPE";
export const TABLE_SET_ROWS = "TABLE_SET_ROWS";
export const TABLE_SET_SELECTED_ROWS = "TABLE_SET_SELECTED_ROWS";
export const TABLE_RIGHT_PANEL_OPEN = "TABLE_RIGHT_PANEL_OPEN";
export const TABLE_SET_TOTAL_PAGES = "TABLE_SET_TOTAL_PAGES";
export const TABLE_SET_TOTAL = "TABLE_SET_TOTAL";
export const TABLE_SET_CURRENT_PAGE = "TABLE_SET_CURRENT_PAGE";
export const TABLE_SET_PAGE_SIZE = "TABLE_SET_PAGE_SIZE";
export const TABLE_SET_LOADING = "TABLE_SET_LOADING";
export const TABLE_SET_SEARCH = "TABLE_SET_SEARCH";
export const TABLE_SET_ACTIVE_FILTERS = "TABLE_SET_ACTIVE_FILTERS";
export const TABLE_SET_HAS_DEFAULT_FILTERS = "TABLE_SET_HAS_DEFAULT_FILTERS";
export const TABLE_SET_WITH_RIGHT_PANEL = "TABLE_SET_WITH_RIGHT_PANEL";
export const TABLE_SET_RIGHT_PANEL_WITH_QUESTION =
  "TABLE_SET_RIGHT_PANEL_WITH_QUESTION";
export const TABLE_INIT = "TABLE_INIT";
export const TABLE_SET_HAS_ACTIVE_FILTER = "TABLE_SET_HAS_ACTIVE_FILTER";
export const TABLE_SET_SHOW_FILTERS_MODAL = "TABLE_SET_SHOW_FILTERS_MODAL";
export const GRID_SET_TITLE = "GRID_SET_TITLE";
export const TABLE_SET_TEMP_FILTER_SELECTION =
  "TABLE_SET_TEMP_FILTER_SELECTION";
export const TABLE_SET_SHOW_USERS_MODAL = "TABLE_SET_SHOW_USERS_MODAL";
export const TABLE_SET_SHOW_REPORTS_BUILDER = "TABLE_SET_SHOW_REPORTS_BUILDER";
export const TABLE_SET_SHOW_WARNING_MODAL = "TABLE_SET_SHOW_WARNING_MODAL";
export const TABLE_SET_SHOW_ACTION_BUTTONS = "TABLE_SET_SHOW_ACTION_BUTTONS";
export const TABLE_SET_SELECT_MODE = "TABLE_SET_SELECT_MODE";

export const FILTER_VIEWS_SET_NAME = "FILTER_VIEWS_SET_NAME";
export const FILTER_VIEWS_SET_SAVED_FILTERS = "FILTER_VIEWS_SET_SAVED_FILTERS";
export const FILTER_VIEWS_SET_CHECKED = "FILTER_VIEWS_SET_CHECKED";

export const FILTERS_SET_INSPECTOR = "FILTERS_SET_INSPECTOR";
export const FILTERS_SET_FILTER_ME = "FILTERS_SET_FILTER_ME";
export const FILTERS_SET_DATE = "FILTERS_SET_DATE";
export const FILTERS_SET_STATUS = "FILTERS_SET_STATUS";
export const FILTERS_SET_JOB_TYPE = "FILTERS_SET_JOB_TYPE";
export const FILTERS_SET_QUESTIONS = "FILTERS_SET_QUESTIONS";
export const FILTERS_SET_ANSWERS = "FILTERS_SET_ANSWERS";
export const FILTERS_SET_MY_PROJECTS = "FILTERS_SET_MY_PROJECTS";
export const FILTERS_SET_MY_PROJECT_MANAGER = "FILTERS_SET_MY_PROJECT_MANAGER";
export const FILTERS_SET_PROJECT = "FILTERS_SET_PROJECT";
export const FILTERS_SET_PROJECT_MANAGERS = "FILTERS_SET_PROJECT_MANAGERS";
export const FILTERS_SET_SKILLS = "FILTERS_SET_SKILLS";
export const FILTER_PROJECT_FIELDS = "FILTER_PROJECT_FIELDS";
export const FILTERS_SET_SORT = "FILTERS_SET_SORT";
export const FILTERS_SET_SHOW_PROJECTS_MODAL =
  "FILTERS_SET_SHOW_PROJECTS_MODAL";
export const FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL =
    "FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL";
export const FILTERS_SET_SHOW_SKILLS_MODAL = "FILTERS_SET_SHOW_SKILLS_MODAL";

export const APPS_SET_ENABLED = "APPS_SET_ENABLED";

export const PROJECTS_LIST = "PROJECTS_LIST";
export const PROJECTS_SHOW_ACTION_BUTTONS = "PROJECTS_SHOW_ACTION_BUTTONS";

export const MULTI_STEP_FILTER_SET_SHOW = "MULTI_STEP_FILTER_SET_SHOW";
export const MULTI_STEP_FILTER_SET_ACTIVE_STEP =
  "MULTI_STEP_FILTER_SET_ACTIVE_STEP";
export const MULTI_STEP_SET_ANSWERS = "MULTI_STEP_SET_ANSWERS";
export const MULTI_STEP_SET_QUESTIONS_LIST = "MULTI_STEP_SET_QUESTIONS_LIST";
export const MULTI_STEP_SET_PROJECTS = "MULTI_STEP_SET_PROJECTS";

export const AUTOMATION_WIZARD_SET_TOPIC = "AUTOMATION_WIZARD_SET_TOPIC";
export const AUTOMATION_WIZARD_SET_TOPICS = "AUTOMATION_WIZARD_SET_TOPICS";
export const AUTOMATION_WIZARD_SET_EVENT = "AUTOMATION_WIZARD_SET_EVENT";
export const AUTOMATION_WIZARD_SET_INITIAL_EVENT =
  "AUTOMATION_WIZARD_SET_INITIAL_EVENT";
export const AUTOMATION_WIZARD_SET_ID = "AUTOMATION_WIZARD_SET_ID";
export const AUTOMATION_WIZARD_SET_CONDITION_OPTIONS = "CONDITION_OPTIONS";
export const AUTOMATION_WIZARD_SET_CONDITIONS = "AUTOMATION_WIZARD_CONDITIONS";
export const AUTOMATION_WIZARD_SET_CONDITIONS_CONFIG =
  "AUTOMATION_WIZARD_CONDITIONS_CONFIG";
export const AUTOMATION_WIZARD_SET_REQUIRED_CONDITIONS =
  "AUTOMATION_WIZARD_REQUIRED_CONDITIONS";
export const AUTOMATION_WIZARD_SET_LAST_STEP =
  "AUTOMATION_WIZARD_SET_LAST_STEP";
export const AUTOMATION_WIZARD_SET_ACTION = "AUTOMATION_WIZARD_SET_ACTION";
export const AUTOMATION_WIZARD_SET_ACTION_CHANGED =
  "AUTOMATION_WIZARD_SET_ACTION_CHANGED";
export const AUTOMATION_WIZARD_SET_ACTIONS = "AUTOMATION_WIZARD_SET_ACTIONS";
export const AUTOMATION_WIZARD_SET_ACTIONS_CONFIG =
  "AUTOMATION_WIZARD_SET_ACTIONS_CONFIG";
export const AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO =
  "AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO";
export const AUTOMATION_WIZARD_SET_NAME = "AUTOMATION_WIZARD_SET_NAME";
export const AUTOMATION_WIZARD_SET_CONNECTED_DATA =
  "AUTOMATION_WIZARD_SET_CONNECTED_DATA";
export const AUTOMATION_WIZARD_SET_RECEIVERS =
  "AUTOMATION_WIZARD_SET_RECEIVERS";
export const AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS =
  "AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS";
export const AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS =
  "AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS";
export const AUTOMATION_WIZARD_SET_ACTION_QUESTIONS =
  "AUTOMATION_WIZARD_SET_ACTION_QUESTIONS";
export const AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST =
  "AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST";
export const AUTOMATION_WIZARD_SET_TEST_URL = "AUTOMATION_WIZARD_SET_TEST_URL";
export const AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED =
  "AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED";
export const AUTOMATION_WIZARD_RESET = "AUTOMATION_WIZARD_RESET";

export const FILTERS_BP_SET_USER = "FILTERS_BP_SET_USER";
export const FILTERS_BP_SET_DATE = "FILTERS_BP_SET_DATE";
export const FILTERS_BP_SET_JOB_TYPE = "FILTERS_BP_SET_JOB_TYPE";
export const FILTERS_BP_SET_ELEMENT = "FILTERS_BP_SET_ELEMENT";
export const FILTERS_BP_SET_STATUS = "FILTERS_BP_SET_STATUS";
export const FILTERS_BP_SET_JOB_ID = "FILTERS_BP_SET_JOB_ID";
export const FILTERS_BP_RESET = "FILTERS_BP_RESET";
export const FILTERS_BP_SET_FILTERS_CHANGED = "FILTERS_BP_SET_FILTERS_CHANGED";

export const QUESTIONS_ACTIVE_QUESTION = "QUESTIONS_ACTIVE_QUESTION";
export const QUESTIONS_TOGGLE_MAIL_SECTION = "QUESTIONS_TOGGLE_MAIL_SECTION";
export const QUESTIONS_SET_ANSWERS = "QUESTIONS_SET_ANSWERS";
export const QUESTIONS_SET_PUBLIC_START_NODE_ID =
  "QUESTIONS_SET_PUBLIC_START_NODE_ID";

export const SET_WIDGET_CODE = "SET_WIDGET_CODE";

export const JOB_ROW_SET_ANSWERS_MODAL = "JOB_ROW_SET_ANSWERS_MODAL";

export const SSO_SET_SETTINGS = "SSO_SET_SETTINGS";

export const FILTERS_SET_PROJECT_FIELDS_DATA = 'FILTERS_SET_PROJECT_FIELDS_DATA'