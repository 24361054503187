import { getAppSettings } from "../../services/api/settings";
import store from "../../redux/store";
import { Apps, Settings } from "../../redux/actions";
import { batch } from "react-redux";

const initialState = {
  blueprintSettings: {},
  branding: null,
  companyName: null,
  settings: {},
  menu: [],
  host: null,
  timezone: null,
  security: {
    allowInspectorDuplicate: false,
    allowErase: false,
    allowChangeStatus: false,
    allowInspectorAssign: false,
    allowInspectorManageProjects: false,
  },
  secondaryLogoUrl: null,
  allowedStatuses: {},
  statusOrder: {},
  toggles: [],
  projectFields: [],
  shiftViewByDay: false,
  defaultJobSort: null,
  defaultFilterViews: null,
  hasChange: false,
  statusTranslations: {},
  logoUrl: null,
  emailSenderName: "",
  notifications: [],
  allDay: [],
  googleDriveCode: "",
  customTranslations: [],
  userFields: null,
  customStatuses: [],
};

const settings = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case "SET_HAS_CHANGE":
      return Object.assign({}, state, {
        hasChange: action.data,
      });
    case "SET_SHIFT_VIEW_BY_DAY":
      return Object.assign({}, state, {
        shiftViewByDay: action.data,
      });
    case "SET_BLUEPRINTS":
      return Object.assign({}, state, {
        blueprintSettings: action.data,
      });
    case "SET_EMAIL_SENDER_NAME":
      return Object.assign({}, state, {
        emailSenderName: action.data,
      });
    case "SET_NOTIFICATIONS":
      return Object.assign({}, state, {
        notifications: action.data,
      });
    case "SET_GOOGLE_DRIVE_CODE":
      return Object.assign({}, state, {
        googleDriveCode: action.data,
      });
    case "SET_SETTINGS":
      state.settings = action.data;

      if (action.data && action.data.default_language) {
        const currentLang = localStorage.getItem("lang");

        if (
          !currentLang ||
          currentLang.toString() !== action.data.default_language.toString()
        ) {
          localStorage.setItem("lang", action.data.default_language);

          if (window.location.pathname.indexOf("settings") === -1) {
            window.location.reload();
          }
        }
      }
      return Object.assign({}, state);
    case "SET_BRANDING":
      state.branding = action.data;
      return Object.assign({}, state);
    case "SET_MENU":
      state.menu = action.data;
      return Object.assign({}, state);
    case "SET_CUSTOM_STATUSES":
      state.customStatuses = action.data;
      return Object.assign({}, state);
    case "SET_HOST":
      state.host = action.data;
      return state;
    case "SET_STATUS_TRANSLATIONS":
      state.statusTranslations = action.data;
      return state;
    case "SET_TRANSLATIONS":
      state.customTranslations = action.data;

      if (action.data) {
        localStorage.setItem("customTranslations", JSON.stringify(action.data));
      }
      return Object.assign({}, state);
    case "SET_SECURITY":
      state.security = action.data;
      return Object.assign({}, state);
    case "SET_TIMEZONE":
      state.timezone = action.data;
      return Object.assign({}, state);
    case "SET_COMPANY_NAME":
        state.companyName = action.data;
        return Object.assign({}, state);
    case "SET_PRIMARY_LOGO":
      state.logoUrl = action.data;
      return Object.assign({}, state);
    case "SET_SECONDARY_LOGO":
      state.secondaryLogoUrl = action.data;
      return Object.assign({}, state);
    case "SET_ALL_DAY":
      state.allDay = action.data;
      return Object.assign({}, state);
    case "SET_DEFAULT_JOB_SORT":
      state.defaultJobSort = action.data;
      return Object.assign({}, state);
    case "SET_DEFAULT_FILTER_VIEWS":
      state.defaultFilterViews = action.data;
      return Object.assign({}, state);
    case "SET_ALLOWED_STATUSES":
      state.allowedStatuses = action.data;
      return Object.assign({}, state);
    case "SET_TOGGLES":
      state.toggles = action.data;
      return Object.assign({}, state);
    case "SET_PROJECT_FIELDS":
      const projectFields = action.data;
      return Object.assign({}, state, {
        projectFields,
      });
    case "SET_STATUS_ORDER":
      return Object.assign({}, state, {
        statusOrder: action.data
      });
    case "SET_USER_FIELDS":
      const userFields = action.data;
      return Object.assign({}, state, {
        userFields,
      });
    case "LOAD_SETTINGS":
      try {
        getAppSettings().then((response) => {
          batch(() => {
            if (response && response.branding) {
              store.dispatch(Settings.setBranding(response.branding));
            }

            if (response && response.companyName) {
                store.dispatch(Settings.setCompanyName(response.companyName));
            }

            if (response && response.blueprintSettings) {
              store.dispatch(
                Settings.setBlueprintSettings(response.blueprintSettings)
              );
            }

            if (response && response.menu) {
              store.dispatch(Settings.setMenu(response.menu));
            }

            if (response && response.emailSenderName) {
              store.dispatch(
                Settings.setEmailSenderName(response.emailSenderName)
              );
            }

            if (response && response.notifications) {
              store.dispatch(Settings.setNotifications(response.notifications));
            }

            if (response && response.googleDriveCode) {
              store.dispatch(
                Settings.setGoogleDriveCode(response.googleDriveCode)
              );
            }

            if (response && response.settings) {
              const systemSettings = { ...response.settings };
              if (response.logo) {
                Object.assign(systemSettings, {
                  logo: response.logo,
                });
              }
              store.dispatch(Settings.setSettings(systemSettings));
            }

            if (response && response.customTranslations) {
              store.dispatch(
                Settings.setTranslations(response.customTranslations)
              );
            }

            if (response && response.customStatuses) {
              store.dispatch(
                Settings.setCustomStatuses(response.customStatuses)
              );
            }

            if (response && response.defaultJobSort) {
              store.dispatch(
                Settings.setDefaultJobSort(response.defaultJobSort)
              );
            }

            if (response && response.defaultFilterViews) {
              store.dispatch(
                Settings.setDefaultFilterViews(response.defaultFilterViews)
              );
            }

            if (response && response.host) {
              store.dispatch(Settings.setHost(response.host));
            }

            if (response && response.statusTranslations) {
              store.dispatch(
                Settings.setStatusTranslations(response.statusTranslations)
              );
            }

            if (response && response.timezone) {
              store.dispatch(Settings.setTimezone(response.timezone));
            }

            if (response && response.security) {
              store.dispatch(Settings.setSecurity(response.security));
            }

            if (response && response.logo) {
              store.dispatch(Settings.setPrimaryLogo(response.logo));
            }

            if (response && response.secondaryLogoUrl) {
              store.dispatch(
                Settings.setSecondaryLogo(response.secondaryLogoUrl)
              );
            }

            if (response && response.allowedStatuses) {
              store.dispatch(
                Settings.setAllowedStatuses(response.allowedStatuses)
              );
            }
            if (response && response.allDay) {
              store.dispatch(Settings.setAllDay(response.allDay));
            }
            if (response && response.userFields) {
              store.dispatch(Settings.setUserFields(response.userFields));
            }
            if (response && response.toggles) {
              store.dispatch(Settings.setToggles(response.toggles));
              store.dispatch(
                Apps.set(
                  response.toggles.map((t) => (t.isActive ? t.toggleId : null))
                )
              );
            }
            if (response && response.projectFields) {
              store.dispatch(Settings.setProjectFields(response.projectFields));
            }
            if (response && response.byDay) {
              store.dispatch(Settings.setShiftViewByDay(response.byDay));
            }
            if(response && response.statusOrder){
              store.dispatch(Settings.setStatusOrder(response.statusOrder));
            }
          });
        });
      } catch (e) {
        if (window.location.pathname !== "/error") {
          window.location = "error";
        }
      }
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default settings;
