import React, { useState, useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import stringScore from "string-score";
import i18n from "../../i18n";
import { Button, Grid as ResponsiveGrid } from "@material-ui/core";
import { isMobileDevice } from "../../services/mobile";
import SearchButton from "../search_button/search_button";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "blue",
    },
  },
  popper: {
    zIndex: "999999 !important",
  },
  noOptions: ({ noOptionsDisplay }) => ({
    display: noOptionsDisplay,
  }),
  clearIndicatorDirty: {
    display: "none",
  },
  inputContainer: {
    marginTop: "30px",
    position: "relative",
    marginBottom: 30,
  },
  searchInput: {
    width: "100%",
  },
  searchIconLeft: {
    width: "0%",
    position: "absolute",
    left: "12px",
    top: "5px",
    fontSize: "20px",
  },
  searchIconRight: {
    //width: "0%",
    position: "absolute",
    right: "65px",
    top: "5px",
    fontSize: "20px",
    cursor: "pointer",
    zIndex: "9999",
    /* color: theme.palette.primary.main,
    "&:hover": {
      color: "grey",
    }, */
  },
  labelRoot: {
    marginLeft: ({ withSearchButton }) => (withSearchButton ? 0 : 40),
    "&$labelFocused": {
      marginLeft: "0",
    },
    "&$labelShrink": {
      marginLeft: "0",
    },
  },
  labelFocused: {},
  labelShrink: {},
}));
function AutoComplete({
  list,
  onChange,
  renderOption,
  getOptionLabel,
  noOptionsDisplay,
  text,
  canCreateNew,
  createNew,
  ComponentAfter,
  withSearchButton,
  autocompleteWidth,
  withoutSearch,
  onSearchClick,
  searchCallback,
}) {
  const classes = useStyles({ noOptionsDisplay, withSearchButton });
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [blockCreateNew, setBlockCreateNew] = useState(
    canCreateNew ? !canCreateNew : true
  );

  const onCreateNew = useCallback(async () => {
    await createNew(inputValue);
    setInputValue("");
    withSearchButton && setBlockCreateNew(false);
  }, [inputValue, createNew, withSearchButton]);

  const listFilter = (item) => {
    if (inputValue.length === 0) return true;
    if (searchCallback) {
      searchCallback(item, (transformed) => {
        if (transformed && transformed.length > 0) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      const iterable = Object.keys(item);
      for (let i = 0; i < iterable.length; i++) {
        const value = item[iterable[i]];
        if (value && typeof value === "string") {
          const score = stringScore(value, inputValue, 0.5);
          if (score > 0.3) return true;
        }
      }
    }
    return false;
  };

  const getInitialOptions = useCallback(() => {
    setFilteredOptions(list);
    setBlockCreateNew(false);
  }, [list]);

  const getFilteredOptions = () => {
    setFilteredOptions(list.filter(listFilter));
    setBlockCreateNew(false);
  };

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      getFilteredOptions();
    }
  };

  const noExactMatch = () => {
    if (list.filter((item) => item.label === inputValue).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    withSearchButton && getInitialOptions();
  }, [withSearchButton, getInitialOptions]);

  return (
    <>
      <Autocomplete
        id="autocomplete"
        onChange={(event, newValue, reason) => {
          const attr = event.target.getAttribute("delete");
          const shouldReturn = parseInt(attr) === 1;
          if (shouldReturn) return;
          onChange(newValue, reason);
          setInputValue("");
        }}
        disabled={withoutSearch}
        clearOnBlur={true}
        options={withSearchButton ? filteredOptions : list.filter(listFilter)}
        getOptionLabel={(option) => getOptionLabel(option)}
        classes={{
          popper: classes.popper,
          noOptions: classes.noOptions,
          clearIndicatorDirty: classes.clearIndicatorDirty,
        }}
        style={{
          height: 50,
          width: autocompleteWidth && autocompleteWidth,
        }}
        renderOption={(option) => renderOption(option)}
        renderInput={(params) => (
          <div>
            <Grid container="true" className={classes.inputContainer}>
              {!withSearchButton && (
                <Grid id="icon" item="true" className={classes.searchIconLeft}>
                  <i className="icon-search" />
                </Grid>
              )}
              <Grid item="true" className={classes.searchInput}>
                <TextField
                  {...params}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused,
                      shrink: classes.labelShrink,
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      ...params.style,
                      paddingLeft: withSearchButton ? 10 : 50,
                    },
                    value: inputValue,
                    onChange: (event) => {
                      if (withSearchButton && event.target.value.length === 0) {
                        setFilteredOptions(list);
                      }
                      setInputValue(event.target.value);
                    },
                    onKeyPress: handleKeyPressed,
                  }}
                  label={isMobileDevice() ? i18n.t("default:_TYPE_HERE") : text}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              {withSearchButton && inputValue.length > 0 && (
                <Grid
                  id="icon"
                  item="true"
                  className={classes.searchIconRight}
                  onClick={() =>
                    onSearchClick
                      ? onSearchClick(inputValue)
                      : getFilteredOptions()
                  }
                >
                  {/* <i className="icon-search" /> */}
                  <SearchButton primary={theme.palette.primary.main} />
                </Grid>
              )}
            </Grid>
          </div>
        )}
      />
      {canCreateNew &&
        !blockCreateNew &&
        /* list.filter(listFilter).length === 0  */
        noExactMatch() &&
        inputValue.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => onCreateNew()}>
              {i18n.t("default:_NO_SUGGESTIONS")}
            </Button>
          </div>
        )}
      {ComponentAfter && (
        <ResponsiveGrid
          container
          style={{
            height: 50,
            marginBottom: 20,
          }}
        >
          <ComponentAfter />
        </ResponsiveGrid>
      )}
    </>
  );
}
export default AutoComplete;
