import { SET_WIDGET_CODE } from "../constants";

const initialState = {
  widgetCode: "",
};

const popupWidget = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case SET_WIDGET_CODE:
      return Object.assign({}, state, {
        widgetCode: data,
      });
    default:
      return state;
  }
};

export default popupWidget;
