import i18n from "../i18n";

export const JOB_STATUS = {
  OPEN: "open",
  REJECTED: "rejected",
  ASSIGNED: "assigned",
  CONFIRMED: "confirmed",
  REPORTED: "reported",
  REVIEW: "review",
  DONE: "done",
};

export const JOB_STATUS_LABELS = {
  OPEN: "_OPEN",
  REJECTED: "_REJECTED",
  ASSIGNED: "_ASSIGNED",
  CONFIRMED: "_CONFIRMED",
  REPORTED: "_REPORTED",
  REVIEW: "_REVIEW",
  DONE: "_DONE",
};

export const getDefaultStatusProps = (status) => {
  let color;
  let label;
  switch (status) {
    case JOB_STATUS.OPEN:
      color = "grey";
      label = i18n.t("default:_OPEN");
      break;
    case JOB_STATUS.REJECTED:
      color = "red";
      label = i18n.t("default:_REJECTED");
      break;
    case JOB_STATUS.ASSIGNED:
      color = "blue";
      label = i18n.t("default:_ASSIGNED");
      break;
    case JOB_STATUS.REPORTED:
      color = "green";
      label = i18n.t("default:_REPORTED");
      break;
    case JOB_STATUS.REVIEW:
      color = "pink";
      label = i18n.t("default:_REVIEW");
      break;
    case JOB_STATUS.DONE:
      color = "cyan";
      label = i18n.t("default:_DONE");
      break;
    case JOB_STATUS.CONFIRMED:
      color = "orange";
      label = i18n.t("default:_CONFIRMED");
      break;
    default:
      color = "cyan";
      label = "-";
  }

  return { color, label };
};

export const setStatusCustomTranslation = (status, settings) => {
  if (settings.statusTranslations && settings.statusTranslations[status]) {
    return settings.statusTranslations[status];
  }

  return getDefaultStatusProps(status.toLowerCase()).label;
};

export const getStatusProps = (status, settings) => {
  let color,
    label = null;

  if (
    settings &&
    (settings.branding || (settings && settings.customStatuses))
  ) {
    if (settings.branding && settings.branding.hasOwnProperty(status)) {
      color = settings.branding[status].color.hex;

      return {
        color,
        label: setStatusCustomTranslation(status.toUpperCase(), settings),
      };
    }

    if (
      settings &&
      settings.customStatuses &&
      settings.customStatuses.length > 0
    ) {
      const targetStatus = settings.customStatuses.filter(
        (item) => item.name === status
      );

      if (targetStatus && targetStatus.length === 1) {
        color = targetStatus[0].color.hex;
        label = status;

        return { color, label };
      }
    }
  }

  return getDefaultStatusProps(status);
};

export const getStatusList = (settings) => {
  const result = Object.values(JOB_STATUS).map((status) => {
    const { label } = getStatusProps(status, settings);
    return {
      label,
      value: status,
    };
  });
  if (
    settings &&
    settings.customStatuses &&
    settings.customStatuses.length > 0
  ) {
    for (const status of settings.customStatuses) {
      result.push({
        value: status.name,
        label: status.name,
      });
    }
  }
  return result;
};
