import { isMobileDevice } from "react-select/lib/utils";
import {
  BLUEPRINT_EDITOR_CHANGE_MODE,
  BLUEPRINT_EDITOR_CHANGE_TOOL,
  BLUEPRINT_EDITOR_SET_LAYER,
  BLUEPRINT_EDITOR_UPDATE_LAYER,
  BLUEPRINT_EDITOR_SET_TOOLS,
  BLUEPRINT_EDITOR_OPEN_LAYER_PANEL,
  BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL,
  BLUEPRINT_EDITOR_SET_KONVA_APP,
  BLUEPRINT_EDITOR_UPDATE_HISTORY,
  BLUEPRINT_EDITOR_UNDO,
  BLUEPRINT_EDITOR_REDO,
  BLUEPRINT_EDITOR_OPEN_FILE,
  BLUEPRINT_EDITOR_ADD_ELEMENT,
  BLUEPRINT_EDITOR_UPDATE_ELEMENT,
  BLUEPRINT_EDITOR_REMOVE_ELEMENT,
  BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL,
  BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT,
  BLUEPRINT_EDITOR_SET_ELEMENTS,
  BLUEPRINT_EDITOR_SET_JOBS,
  BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA,
  BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY,
  BLUEPRINT_EDITOR_SET_SHOW_FILTERS,
  BLUEPRINT_EDITOR_RESET,
  BLUEPRINT_EDITOR_SET_QUESTION_OPEN,
  BLUEPRINT_EDITOR_SET_EXPORT_IMAGE,
  BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT,
  BLUEPRINT_EDITOR_SET_FILENAME,
  BLUEPRINT_EDITOR_SET_FILE_OPTIONS,
  BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER,
  BLUEPRINT_EDITOR_SET_IMAGE_ROTATION,
  BLUEPRINT_EDITOR_SET_FILE_PAGE,
  BLUEPRINT_EDITOR_SET_MEASUREMENTS,
  BLUEPRINT_EDITOR_RESET_MEASUREMENTS,
  BLUEPRINT_EDITOR_SET_FLOATING_INPUT,
  BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED,
  BLUEPRINT_EDITOR_SET_PDF_INFO,
  BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE,
  BLUEPRINT_EDITOR_SET_FILE_ID,
  BLUEPRINT_EDITOR_SET_PDF_RESIZING,
  BLUEPRINT_EDITOR_SHOW_EDIT_MODAL,
  BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE,
  BLUEPRINT_EDITOR_SET_IS_MEASURING,
  BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED,
  BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN,
  BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT,
  BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT,
  BLUEPRINT_EDITOR_SELECT_ELEMENT,
  BLUEPRINT_EDITOR_SELECT_LINE,
  BLUEPRINT_EDITOR_DESELECT_ELEMENT,
  BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB,
  BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES,
  BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE,
  BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL,
  BLUEPRINT_EDITOR_SET_CROP_SIZE,
  BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP,
  BLUEPRINT_EDITOR_CANCEL_CROP,
} from "../constants";

const initialState = {
  layer: null,
  previousLayer: null,
  tool: null,
  mode: "VIEW",
  toolbar: {},
  layerPanelOpen: false,
  app: null,
  history: [],
  lastHistoryIndex: 0,
  file: null,
  fileId: null,
  fileName: "",
  fileOptions: [],
  elements: [],
  image: null,
  elementsInfoPanelOpen: false,
  selectedElement: null,
  jobs: [],
  infoPanelData: null,
  showFilters: false,
  questionOpen: false,
  dimensionsPanel: false,
  imageRotation: 0,
  imageGrayscale: false,
  filePage: 1,
  measurements: null,
  isMeasuring: false,
  measurementsChecked: false,
  isPdfResizing: false,
  floatingInput: {
    display: "none",
    top: 0,
    left: 0,
    type: "height",
  },
  showHeightInput: false,
  showWidthInput: false,
  pdfInfo: {
    totalPages: 1,
    imgSource: "",
    fileBlob: null,
    pages: null,
    size: null,
  },
  filePreviewVisible: false,
  showEditBpModal: false,
  measureIntroDisplayed: false,
  isBpEditorOpen: false,
  rightPanelJob: null,
  openPolygonSides: 1,
  pdfExportModalVisible: false,
  croppedDataUrl: "",
  cropSize: null,
  activeCropStep: 1,
};

const blueprintEditor = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    default:
      return state;
    case BLUEPRINT_EDITOR_SET_EXPORT_IMAGE:
      return Object.assign({}, state, {
        image: data,
      });
    case BLUEPRINT_EDITOR_SET_PDF_RESIZING:
      return Object.assign({}, state, {
        isPdfResizing: data,
      });
    case BLUEPRINT_EDITOR_SET_IS_MEASURING:
      return Object.assign({}, state, {
        isMeasuring: data,
      });
    case BLUEPRINT_EDITOR_SET_MEASUREMENTS:
      return Object.assign({}, state, {
        measurements: {
          ...state.measurements,
          ...data,
        },
      });
    case BLUEPRINT_EDITOR_RESET_MEASUREMENTS:
      return Object.assign({}, state, {
        measurements: null
      });
    case BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED:
      return Object.assign({}, state, {
        measurementsChecked: data,
      });
    case BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA:
      return Object.assign({}, state, {
        infoPanelData: data,
      });
    case BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE:
      return Object.assign({}, state, {
        filePreviewVisible: data,
      });
    case BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY:
      let indexVisibility = -1;
      state.elements.forEach((e, idx) => {
        if (indexVisibility !== -1) return;
        if (e.id === data.id) {
          indexVisibility = idx;
        }
      });
      if (indexVisibility !== -1) {
        const next = [...state.elements];
        next[indexVisibility].visible = next[indexVisibility].visible
          ? false
          : true;
        const shape = state.app.find("#" + next[indexVisibility].id)[0];
        if (shape) {
          if (!next[indexVisibility].visible) {
            shape.hide();
          } else {
            shape.show();
          }
          state.app.batchDraw();
        }
        return Object.assign({}, state, {
          elements: next,
        });
      }
      return state;
    case BLUEPRINT_EDITOR_UPDATE_ELEMENT:
      let indexUpdate = -1;
      state.elements.forEach((e, idx) => {
        if (indexUpdate !== -1) return;
        if (e.id === data.id) {
          indexUpdate = idx;
        }
      });
      if (indexUpdate !== -1) {
        const next = [...state.elements];
        next[indexUpdate] = {...next[indexUpdate], ...data};
        return Object.assign({}, state, {
          elements: next,
        });
      }
      return state;
    case BLUEPRINT_EDITOR_ADD_ELEMENT:
      const next = [...state.elements];
      next.push({ ...data, visible: true });
      return Object.assign({}, state, {
        elements: next,
      });
    case BLUEPRINT_EDITOR_SET_ELEMENTS:
      return Object.assign({}, state, {
        elements: data,
      });
    case BLUEPRINT_EDITOR_REMOVE_ELEMENT:
      const newElements = [...state.elements];
      const target = newElements.filter((e) => e.id === data);
      if (target.length > 0) {
        newElements.splice(newElements.indexOf(target[0]), 1);
      }
      return Object.assign({}, state, {
        elements: newElements,
      });
    case BLUEPRINT_EDITOR_OPEN_FILE:
      return Object.assign({}, state, {
        file: data,
      });
    case BLUEPRINT_EDITOR_UNDO:
      if (state.app) {
        const prevIndex = state.lastHistoryIndex - 1;
        if (prevIndex >= 0) {
          return Object.assign({}, state, {
            lastHistoryIndex: prevIndex,
          });
        }
      }
      return state;
    case BLUEPRINT_EDITOR_REDO:
      if (state.app) {
        const nextIndex = state.lastHistoryIndex + 1;
        if (nextIndex <= state.history.length - 1) {
          /* state.app.clear();
          const newStage = Konva.Node.create(
            state.history[nextIndex],
            ".konvajs-content"
          ); */
          return Object.assign({}, state, {
            lastHistoryIndex: nextIndex,
            //app: newStage,
          });
        }
      }
      return state;
    case BLUEPRINT_EDITOR_SET_TOOLS:
      return Object.assign({}, state, {
        toolbar: data,
        tool: !state.tool ? data[0] : state.tool,
      });
    case BLUEPRINT_EDITOR_SET_KONVA_APP:
      return Object.assign({}, state, {
        app: data,
      });
    case BLUEPRINT_EDITOR_UPDATE_LAYER:
      const lUpdate = [...state.layers];
      const currentIdx = state.layer;
      lUpdate[currentIdx] = data;
      return Object.assign({}, state, {
        layers: lUpdate,
      });
    case BLUEPRINT_EDITOR_SET_LAYER:
      return Object.assign({}, state, {
        layer: data,
      });
    case BLUEPRINT_EDITOR_CHANGE_MODE:
      return Object.assign({}, state, {
        mode: data,
      });
    case BLUEPRINT_EDITOR_CHANGE_TOOL:
      return Object.assign({}, state, {
        tool: data,
      });
    case BLUEPRINT_EDITOR_OPEN_LAYER_PANEL:
      return Object.assign({}, state, {
        layerPanelOpen: true,
      });
    case BLUEPRINT_EDITOR_UPDATE_HISTORY:
      //all current elements on the stage
      const stageChildrenArr =
        state.app &&
        state.app.children &&
        state.app.children[0] &&
        state.app.children[0].children
          ? Array.from(state.app.children[0].children)
          : [];
      const remadeChildrenArr = stageChildrenArr
        //filter out Image and Transformer elements
        .filter(
          (row) =>
            row.constructor.name !== "Image" &&
            row.constructor.name !== "Transformer"
        )
        //filter out elements outside elements arr
        .filter((row) => {
          const elementId = state.elements.filter(
            (el) => row.attrs.id === el.id
          );
          if (elementId.length > 0 && elementId[0].elementId) {
            return row;
          } else {
            return null;
          }
        })
        //map elements to have name and attrs like
        .map((row) => {
          const newAttr = { ...row.attrs };
          const name = row.attrs.name ? row.attrs.name : row.constructor.name;
          const elementId = state.elements.filter(
            (el) => row.attrs.id === el.id
          );
          return {
            attrs: newAttr,
            name,
            elementId:
              elementId.length > 0 && elementId[0].elementId
                ? elementId[0].elementId
                : null,
          };
        });

      const newHistory = [...state.history];
      //before push - check if there is change!
      const lastHistory = newHistory[state.lastHistoryIndex]
        ? newHistory[state.lastHistoryIndex]
        : [];
      let elementChanged = false;
      //check if element was added or removed
      if (lastHistory.length !== remadeChildrenArr.length) {
        elementChanged = true;
      } else {
        elementChanged = false;
        //check if attrs of element were changed
        const newHistoryAttrs = remadeChildrenArr.map((row) => {
          const attrs = { ...row.attrs };
          return attrs;
        });
        const lastHistoryAttrs = lastHistory.map((row) => {
          const attrs = { ...row.attrs };
          return attrs;
        });
        newHistoryAttrs.forEach((row) => {
          const filteredHistoryAttrs = lastHistoryAttrs.filter(
            (lastRow) => JSON.stringify(lastRow) === JSON.stringify(row)
          );
          if (filteredHistoryAttrs.length === 0) {
            elementChanged = true;
          }
        });
      }

      if (elementChanged) {
        //check if element was added when lastHistoryIndex is not the last index in history arr
        //then cut history after lastHistoryIndex
        if (
          lastHistory.length < remadeChildrenArr.length &&
          state.lastHistoryIndex < newHistory.length - 1
        ) {
          const resetHistory = newHistory.slice(0, state.lastHistoryIndex + 1);
          resetHistory.push([...remadeChildrenArr]);
          return Object.assign({}, state, {
            history: resetHistory,
            lastHistoryIndex: resetHistory.length - 1,
          });
        } else {
          newHistory.push([...remadeChildrenArr]);
          return Object.assign({}, state, {
            history: newHistory,
            lastHistoryIndex: newHistory.length - 1,
          });
        }
      } else {
        if (
          lastHistory.length === 0 &&
          remadeChildrenArr.length === 0 &&
          newHistory.length === 0
        ) {
          newHistory.push([]);
          return Object.assign({}, state, {
            history: newHistory,
            lastHistoryIndex: 0,
          });
        }
        return state;
      }
    case BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL:
      return Object.assign({}, state, {
        layerPanelOpen: false,
      });
    case BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL:
      return Object.assign({}, state, {
        elementsInfoPanelOpen: data,
      });
    case BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT:
      return Object.assign({}, state, {
        selectedElement: data,
      });
    case BLUEPRINT_EDITOR_SET_JOBS:
      return Object.assign({}, state, {
        jobs: data,
      });
    case BLUEPRINT_EDITOR_SET_SHOW_FILTERS:
      return Object.assign({}, state, {
        showFilters: data,
      });
    case BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT:
      return Object.assign({}, state, {
        showHeightInput: data,
      });
    case BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT:
      return Object.assign({}, state, {
        showWidthInput: data,
      });
    case BLUEPRINT_EDITOR_RESET:
      return Object.assign({}, state, initialState);
    case BLUEPRINT_EDITOR_SET_QUESTION_OPEN:
      return Object.assign({}, state, {
        questionOpen: data,
      });
    case BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT:
      return Object.assign({}, state, {
        dimensionsPanel: data,
      });
    case BLUEPRINT_EDITOR_SET_FILENAME:
      return Object.assign({}, state, {
        fileName: data,
      });
    case BLUEPRINT_EDITOR_SET_FILE_OPTIONS:
      return Object.assign({}, state, {
        fileOptions: data,
      });
    case BLUEPRINT_EDITOR_SET_FILE_ID:
      return Object.assign({}, state, {
        fileId: data,
      });
    case BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER:
      return Object.assign({}, state, {
        previousLayer: data,
      });
    case BLUEPRINT_EDITOR_SET_IMAGE_ROTATION:
      return Object.assign({}, state, {
        imageRotation: data,
      });
    case BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE:
      return Object.assign({}, state, {
        imageGrayscale: data,
      });
    case BLUEPRINT_EDITOR_SET_FILE_PAGE:
      return Object.assign({}, state, {
        filePage: data,
      });
    case BLUEPRINT_EDITOR_SET_FLOATING_INPUT:
      return Object.assign({}, state, {
        floatingInput: {
          ...state.floatingInput,
          ...data,
        },
      });
    case BLUEPRINT_EDITOR_SET_PDF_INFO:
      return Object.assign({}, state, {
        pdfInfo: {
          ...state.pdfInfo,
          ...data,
        },
      });
    case BLUEPRINT_EDITOR_SHOW_EDIT_MODAL:
      return Object.assign({}, state, {
        showEditBpModal: data,
      });
    case BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED:
      return Object.assign({}, state, {
        measureIntroDisplayed: data,
      });
    case BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN:
      return Object.assign({}, state, {
        isBpEditorOpen: data,
      });
    case BLUEPRINT_EDITOR_SELECT_ELEMENT:
      return Object.assign({}, state, {
        selectedElement: data,
        layerPanelOpen: isMobileDevice() ? false : true,
        elementsInfoPanelOpen: false,
        infoPanelData: null,
        dimensionsPanel: data.attrs.id,
      });
    case BLUEPRINT_EDITOR_SELECT_LINE:
      return Object.assign({}, state, {
        selectedElement: data,
        layerPanelOpen: isMobileDevice() ? false : true,
        elementsInfoPanelOpen: data.attrs.id,
        dimensionsPanel: false,
      });
    case BLUEPRINT_EDITOR_DESELECT_ELEMENT:
      return Object.assign({}, state, {
        selectedElement: null,
        layerPanelOpen: false,
        elementsInfoPanelOpen: false,
        dimensionsPanel: false,
      });
    case BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB:
      return Object.assign({}, state, {
        rightPanelJob: data,
      });
    case BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES:
      return Object.assign({}, state, {
        openPolygonSides: data,
      });
    case BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE:
      return Object.assign({}, state, {
        pdfExportModalVisible: data,
      });
    case BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL:
      return Object.assign({}, state, {
        croppedDataUrl: data,
      });
    case BLUEPRINT_EDITOR_SET_CROP_SIZE:
      return Object.assign({}, state, {
        cropSize: data,
      });
    case BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP:
      return Object.assign({}, state, {
        activeCropStep: data,
      });
    case BLUEPRINT_EDITOR_CANCEL_CROP:
      return Object.assign({}, state, {
        activeCropStep: 1,
        cropSize: null,
        croppedDataUrl: "",
        pdfExportModalVisible: false,
      });
  }
};

export default blueprintEditor;
