import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DetailsRoundedIcon from "@material-ui/icons/DetailsRounded";
import ChangeHistoryRoundedIcon from "@material-ui/icons/ChangeHistoryRounded";

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: "pointer",
    color: "#D3D3D3",
  },
  inputContainer: {
    maxWidth: 70,
    marginLeft: 10,
    fontSize: "1.4rem",
  },
}));

function MeasureAdjuster({
  onDecrement,
  onIncrement,
  onValueChange,
  value,
  name,
  step,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        className={classes.button}
        xs={2}
        onClick={() => onDecrement()}
      >
        <DetailsRoundedIcon fontSize="large" color="inherit" />
      </Grid>
      <Grid item xs={8} className={classes.inputContainer}>
        <input
          style={{ width: "100%" }}
          value={value}
          onChange={(event) => onValueChange(event.target.value)}
          type="number"
          name={name}
          className="measureInputHeightElement"
          max={9999999999999999999}
          min={1}
          step={step}
        />
      </Grid>
      <Grid
        item
        className={classes.button}
        xs={2}
        onClick={() => onIncrement()}
      >
        <ChangeHistoryRoundedIcon fontSize="large" color="inherit" />
      </Grid>
    </Grid>
  );
}

export default MeasureAdjuster;
