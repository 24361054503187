import React from "react";
import ContentLoader from "react-content-loader";

export function LoadingGrid() {
  return (
    <ContentLoader style={{ width: "100%", height: 89, marginBottom: 0 }}>
      <rect x="0" y="0" width="100%" height="75" />
    </ContentLoader>
  );
}
