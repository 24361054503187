import Http from "../http";

export const listQuestionsWithFilters = async (params) => {
  const path = `/v2/question`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const listQuestionProjects = async (params) => {
  const path = `/v2/question/projects`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const listQuestions = async (params) => {
  const path = `/v2/jobtype/${params.id}/questions`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const getQuestionAnswers = async (idQuestion, idJob) => {
  const path = `/v1/questions/${idQuestion}/answers?id_job=${idJob}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const listGlobalQuestions = async (jobTypes) => {
  const path = `/v2/question`;
  const params = {
    only_global: true,
    jobType: jobTypes,
  };
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const createQuestion = async (params) => {
  const path = `/v2/question`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      title: params.title,
      description: params.description,
      is_global: params.isGlobal,
      settings: params.settings,
      jobType: params.jobType,
      type: params.type,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const reorderQuestions = async (params) => {
  const path = `/v2/jobtype/${params.id}/reorder-questions`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, {
      questions: params.questions,
    });

    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const updateQuestion = async (params) => {
  const path = `/v2/question/${params.ID}`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, {
      title: params.title,
      description: params.description,
      is_global: params.isGlobal,
      settings: params.settings,
      job_type: params.jobType,
      type: params.type,
      sequence: params.sequence,
      root: params.root ? params.root : null,
      disabled: params.disabled,
      withBackground: params.withBackground,
      backgroundDesktop: params.backgroundDesktop,
      backgroundMobile: params.backgroundMobile,
      darkMode: params.darkMode,
      isClientVisible: params.isClientVisible,
      fileRequired: params.fileRequired,
      stepIcon: params.stepIcon,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const deleteQuestion = async (params) => {
  const path = `/v2/question/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};
