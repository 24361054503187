import React, { Component } from "react";
import Sidebar from "./partials/sidebar/sidebar";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastr";
import { withRouter } from "react-router-dom";

class AdminLayoutMock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalFunc: null,
    };
    this.headerRef = null;
  }

  render() {
    const path = this.props.location && this.props.location.pathname;
    if (
      path &&
      (path.includes("answer") ||
        path.includes("create_installation") ||
        path.includes("instance_reminder"))
    ) {
      return null;
    }
    return (
      <div className="adminLayout">
        <Helmet>
          <link rel="apple-touch-icon" href={"/logo.default.png"} />
        </Helmet>
        <ToastContainer
          ref={(ref) => (window.toast = ref)}
          className="toast-top-center"
        />
        <Sidebar
          location={{
            pathname: "/",
          }}
          toggleModal={this.state.showModalFunc}
        />
        <div className="admin-main">
          <div
            className="row container-fluid"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <div className={"col-md-12  d-sm-block wrapper"}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminLayoutMock);
