import React, { useEffect } from "react";
import { connectWithStore } from "../../services/redux";
import i18n from "../../i18n";
import { withRouter } from "react-router-dom";
import { STEP_CREATE_NEW } from "./steps";
import WizardButtons from "../create_wizard/wizard_buttons";
import { Wizard } from "../../redux/actions";

function FinalStep({ history, goToStep, dispatch, isActive, jobTypeId }) {
  const goToJobType = () => {
    history.push(`/job_type/editor/${jobTypeId}`);
    history.go();
  };

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(null));
  }, [dispatch, isActive]);
  return (
    <div className="FinalStep ModalBody">
      <div
        className="final-step"
        style={{
          flex: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <i className="mdi mdi-rocket h1" style={{ fontSize: "20rem" }} />
        <h5>
          {i18n.t("default:_JOB_TYPE_FROM_TEMPLATE", {
            jobTypeWord: i18n.t("default:_JOB_TYPE").toLowerCase(),
          })}
        </h5>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_MORE"),
            onClick: () => goToStep(STEP_CREATE_NEW),
            variant: "white",
          },
          {
            label: i18n.t("default:_GO_TO", {
              type: i18n.t("default:_JOB_TYPES"),
            }),
            onClick: () => {
              goToJobType();
            },
            variant: "black",
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(withRouter(FinalStep), ["wizard"]);
