import React, { useCallback, useState, useEffect } from "react";
import FieldPreview from "./field_preview";
import MapWithButtons from "../../../components/google_map/map_with_buttons";
import i18n from "../../../i18n";
import InfoModal from "../../../components/info_modal/info_modal";
import { locationFromCoords } from "../../../services/location";
import { connectWithStore } from "../../../services/redux";
import { CurrentProject } from "../../../redux/actions";
import withModal from "../../../hoc/withModal";
import { updateSignleProjectField } from "../../../services/api/projects";
import { PROJECT_FIELD_TYPE, ICON } from "./constants";
import { canUserEdit } from "../../../services/access";

const MapButtonsModal = withModal(MapWithButtons);

function LocationModal({
  visible,
  dispatch,
  onClose,
  copyLink,
  project,
  id,
  accessRules,
  user,
}) {
  const onLocationChanged = useCallback(
    async (coords) => {
      if (!coords.address) {
        const { formatted_address } = await locationFromCoords(
          coords.lat,
          coords.lng
        );
        coords.address = formatted_address;
      }
      const newLoc = {
        lat: coords.lat,
        long: coords.lng,
        address: coords.address,
      };
      try {
        await updateSignleProjectField({
          id: project._id,
          field: {
            id,
            value: newLoc,
          },
        });
        dispatch(
          CurrentProject.updateProject({
            [id]: newLoc,
          })
        );
        //upate field data
      } catch (e) {
        console.log("Failed to update project data", e);
      }
    },
    [dispatch, id, project._id]
  );

  return (
    <MapButtonsModal
      visible={visible}
      onClose={() => onClose()}
      project={project}
      onLocationChanged={onLocationChanged.bind(this)}
      copyLink={copyLink.bind(this)}
      title={i18n.t("default:_ADDRESS")}
      accessAllowed={canUserEdit(user, accessRules)}
    />
  );
}

const LocationModalConnected = connectWithStore(LocationModal, []);

let textAreaRef = null;

function Address({
  label,
  icon,
  editable,
  accessRules,
  id,
  currentProject,
  user,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [copyAlertVisible, setCopyAlertVisible] = useState(false);
  const [copyBuffer, setCopyBuffer] = useState("");
  const { project } = currentProject;
  const copyLink = useCallback((link) => {
    setCopyBuffer(link);
  }, []);
  useEffect(() => {
    if (!copyBuffer || copyBuffer.length === 0) return;
    if (textAreaRef) {
      textAreaRef.select();
    }
    document.execCommand("copy");
    setTimeout(() => {
      setCopyAlertVisible(true);
    }, 500);
  }, [copyBuffer]);
  return (
    <div>
      <InfoModal
        show={copyAlertVisible}
        text={i18n.t("default:_LINK_COPIED")}
        onClose={() => setCopyAlertVisible(false)}
      />
      {!editable && (
        <LocationModalConnected
          onClose={() => setModalVisible(false)}
          id={id}
          project={{
            name: project.name,
            _id: project._id,
            location: project[id] ? project[id] : null,
          }}
          copyLink={copyLink.bind(this)}
          visible={modalVisible}
          textAreaRef={textAreaRef}
          accessRules={accessRules}
          user={user}
        />
      )}
      <FieldPreview
        onClick={() => (!editable ? setModalVisible(true) : null)}
        label={
          !editable && project[id] && project[id].address
            ? project[id].address
            : label
        }
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.ADDRESS]}
        accessRules={accessRules}
        considerReadAccess={true}
      />
      <textarea
        ref={(ref) => {
          textAreaRef = ref;
        }}
        style={{
          width: "0px",
          height: "0px",
          opacity: 0,
          visibility: "hidden",
        }}
        value={copyBuffer}
        readOnly
      />
    </div>
  );
}

export default connectWithStore(Address, ["currentProject", "user"]);
