import React, { Component } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getFileTarget } from "../../../services/api/file_upload";
// import Spacer from '../../spacer/spacer';
//import i18n from "../../../i18n";

export default class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.getActiveByValue(),
      hoveredItem: null,
    };
  }

  getActiveByValue() {
    const { childs } = this.props.question.settings;
    if (!this.props.question.answer) {
      return null;
    }
    const { value } = this.props.question.answer;
    if (childs && childs.length > 0 && value && value !== "") {
      const filtered = childs.filter((item) => item.value === value);
      if (filtered.length > 0) {
        return filtered[0];
      }
    }
    return null;
  }

  componentDidMount() {
    let cmpData = this.props.answers[this.props.question.ID];

    if (cmpData) {
      this.setState({ activeItem: this.getActiveByValue() });
    }

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
  }

  activeItem(value) {
    this.setState({ hoveredItem: null });
    this.setState({ activeItem: value });
    this.props.handleChange(value.value);
  }

  isChecked(item) {
    return JSON.stringify(item) === JSON.stringify(this.state.activeItem);
  }

  isHovered(item) {
    return item.value === this.state.hoveredItem;
  }

  setHoveredItem(item) {
    this.setState({ hoveredItem: item.value });
  }

  render() {
    const { settings } = this.props.question;
    return (
      <div
        className={
          settings.thumbs
            ? "question-content imageComponent thumbs"
            : "question-content imageComponent"
        }
      >
        <div className="form-row">
          {/* {this.props.question.settings.childs && 
                    this.props.question.settings.childs.length > 0 && 
                        <div className='col-md-12 text-left'>
                            <h5 className='heading-styled'>
                                <i className='mdi mdi-check' />
                                {i18n.t('default:_PLEASE_CHOOSE_AN_IMAGE')}
                            </h5>    
                        </div>
                    } */}
          <div className="form-group">
            {this.props.question.settings.childs &&
              this.props.question.settings.childs.length > 0 &&
              this.props.question.settings.childs.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={
                      settings.thumbs
                        ? "col-md-3 imgChoice"
                        : "col-md-6 imgChoice"
                    }
                  >
                    <label
                      className={
                        this.isChecked(item)
                          ? "image-box selected"
                          : "image-box"
                      }
                      onClick={() => this.activeItem(item)}
                      onMouseEnter={() => this.setHoveredItem(item)}
                      onMouseLeave={() => this.setState({ hoveredItem: null })}
                    >
                      <input
                        defaultChecked={this.isChecked(item)}
                        name="image-box"
                        type="checkbox"
                        value={item.value}
                      />
                      <span className="image-box-content">
                        <img
                          src={getFileTarget(item.file, "100x100")}
                          alt={item.caption}
                        />
                        {this.isChecked(item) && (
                          <span className="imageSelected">
                            <CheckCircleIcon
                              style={{
                                fontSize: "50px",
                                color: this.props.primary
                                  ? this.props.primary
                                  : "#fff",
                              }}
                            />
                          </span>
                        )}
                        {this.isHovered(
                          item
                        ) /* && !this.isChecked(item) */ && (
                          <span className="imageSelected">
                            <CheckCircleIcon style={{ fontSize: "50px" }} />
                          </span>
                        )}
                      </span>
                    </label>
                    <span
                      style={{
                        color: this.props.question.settings.darkMode
                          ? "white"
                          : null,
                      }}
                      dir="auto"
                      className="imgCmpCaption heading-styled"
                    >
                      {item.caption}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
