import {
  FILTERS_BP_SET_USER,
  FILTERS_BP_SET_DATE,
  FILTERS_BP_SET_JOB_TYPE,
  FILTERS_BP_SET_ELEMENT,
  FILTERS_BP_RESET,
  FILTERS_BP_SET_STATUS,
  FILTERS_BP_SET_JOB_ID,
  FILTERS_BP_SET_FILTERS_CHANGED,
} from "../constants";

const initialState = {
  filterUser: [],
  filterDate: [],
  filterJobType: [],
  filterElement: [],
  filterStatus: [],
  filterJobId: [],
  filtersChanged: false,
};

const bluePrintsFilters = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case FILTERS_BP_RESET:
      return Object.assign({}, initialState);
    case FILTERS_BP_SET_USER:
      return Object.assign({}, state, {
        filterUser: data,
      });
    case FILTERS_BP_SET_DATE:
      return Object.assign({}, state, {
        filterDate: data,
      });
    case FILTERS_BP_SET_JOB_TYPE:
      return Object.assign({}, state, {
        filterJobType: data,
      });
    case FILTERS_BP_SET_ELEMENT:
      return Object.assign({}, state, {
        filterElement: data,
      });
    case FILTERS_BP_SET_STATUS:
      return Object.assign({}, state, {
        filterStatus: data,
      });
    case FILTERS_BP_SET_JOB_ID:
      return Object.assign({}, state, {
        filterJobId: data,
      });
    case FILTERS_BP_SET_FILTERS_CHANGED:
      return Object.assign({}, state, {
        filtersChanged: data,
      });
    default:
      return state;
  }
};

export default bluePrintsFilters;
