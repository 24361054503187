import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connectWithStore } from "../../../../services/redux";
import SimpleAutocomplete from "../../../../components/autoselect/simple_autocomplete";
import i18n from "../../../../i18n";
import Avatar from "@material-ui/core/Avatar";
import { getStatusList, getStatusProps } from "../../../../services/job_status";

const useStyles = makeStyles((theme) => ({
  root: {
    //marginTop: 6,
    width: ({ fullParentWidth }) => (fullParentWidth ? "100%" : "92%"),
    justifyContent: ({ centeredInput }) =>
      centeredInput ? "center" : "inherit",
  },
}));

function StatusSelect({
  selectedStatuses,
  settings,
  onStatusChange,
  multiple,
  inputWidth,
  centeredInput,
  fullParentWidth,
  allowClear,
}) {
  const classes = useStyles({ fullParentWidth, centeredInput });
  const statusList = getStatusList(settings);

  const selected = selectedStatuses
    ? statusList.filter((st) => selectedStatuses.includes(st.value))
    : [];

  const singleSelection = selectedStatuses
    ? statusList.filter((st) => selectedStatuses.includes(st.value))[0]
    : "";

  const renderAlternativeAvatar = (option) => {
    const props = getStatusProps(option.value, settings);
    return (
      <Avatar
        alt="status avatar"
        style={{ color: props.color, background: props.color }}
      />
    );
  };

  return (
    <Grid container item className={classes.root}>
      <SimpleAutocomplete
        options={statusList}
        onValueChange={onStatusChange}
        multiple={multiple}
        value={multiple ? selected : singleSelection}
        width={inputWidth ? inputWidth : "95%"}
        label={i18n.t("default:_JOB_STATUS")}
        renderAlternativeAvatar={renderAlternativeAvatar}
        disableClearable={allowClear ? false : true}
      />
    </Grid>
  );
}

export default connectWithStore(StatusSelect, ["settings"]);
