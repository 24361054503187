import React from "react";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import DateService from "../../services/date";
import SKILL_COLORS from "../../services/skill_colors";

const useStyles = makeStyles((theme) => ({
  skillChip: ({
    withMargin,
    withAvatar,
    skillColor,
    backgroundColor,
    sidePanel,
  }) => ({
    marginRight: "20px",
    marginBottom: withMargin ? "10px" : "0px",
    height: withAvatar && !sidePanel ? "40px" : "30px",
    border: skillColor ? `2px solid ${skillColor}` : `none`,
    borderRadius: "5px",
    cursor: "pointer",
  }),
  skillAvatar: {
    border: "3px solid #C7DF94",
    background: "#FDBD84",
    marginLeft: "14px !important",
    marginRight: "4px !important",
  },
  skillIconAuto: {
    fontSize: "20px",
    lineHeight: "20px",
  },
  avatar: {
    width: "30px !important",
    height: "30px !important",
  },
  clickable: (backgroundColor, skillColor) => ({
    "&:focus": {
      backgroundColor:
        backgroundColor && backgroundColor !== null
          ? backgroundColor
          : skillColor
          ? skillColor
          : SKILL_COLORS.WHITE,
    },
    "&:active": {
      backgroundColor:
        backgroundColor && backgroundColor !== null
          ? backgroundColor
          : skillColor
          ? skillColor
          : SKILL_COLORS.WHITE,
    },
    "&:hover": {
      backgroundColor:
        backgroundColor && backgroundColor !== null
          ? backgroundColor
          : skillColor
          ? skillColor
          : SKILL_COLORS.WHITE,
    },
  }),
  date: {
    color: SKILL_COLORS.GREY,
    fontSize: "10px",
    textAlign: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function SkillChip({
  handleClick,
  withAvatar,
  skillAvatar,
  name,
  backgroundColor,
  skillColor,
  withMargin,
  date,
  sidePanel,
}) {
  const classes = useStyles({
    skillColor,
    withAvatar,
    withMargin,
    backgroundColor,
    sidePanel,
  });
  const label =
    date && date !== null ? (
      <Grid>
        <Grid>{name}</Grid>
        <Grid className={classes.date}>
          {DateService.getFormattedDay(date)}
        </Grid>
      </Grid>
    ) : (
      name
    );
  return (
    <Chip
      classes={{
        avatar: !sidePanel && classes.avatar,
        clickable: classes.clickable,
        outlined: classes.clickable,
        label: classes.label,
      }}
      style={{
        background:
          backgroundColor && backgroundColor !== null
            ? backgroundColor
            : skillColor
            ? skillColor
            : SKILL_COLORS.WHITE,
        color: skillColor
          ? backgroundColor === SKILL_COLORS.WHITE
            ? SKILL_COLORS.BLACK
            : skillColor !== SKILL_COLORS.GREY
            ? skillColor === SKILL_COLORS.DARK_GREY
              ? SKILL_COLORS.WHITE
              : SKILL_COLORS.BLACK
            : SKILL_COLORS.GREY
          : SKILL_COLORS.GREY,
      }}
      className={classes.skillChip}
      variant="outlined"
      size="medium"
      avatar={
        withAvatar ? (
          skillAvatar ? (
            <Avatar
              className={classes.skillAvatar}
              alt="skill icon"
              src={skillAvatar}
            />
          ) : (
            <Avatar className={classes.skillAvatar}>
              <Grid
                container
                justify="center"
                alignContent="center"
                className={`mdi mdi-shield-check ${classes.skillIconAuto}`}
              />
            </Avatar>
          )
        ) : null
      }
      label={label}
      onClick={handleClick ? () => handleClick() : null}
    />
  );
}

export default SkillChip;
