import React, { useCallback, useEffect, useState } from "react";
import { PROJECT_FIELD_TYPE } from "../../../pages/projects/fields/constants";
import { getFileInfo } from "../../../services/api/file_upload";
import { connectWithStore } from "../../../services/redux";
//import ListWizard from "../../create_wizard/list_wizard";
import { BlueprintEditor } from "../../../redux/actions";
import List from "../../question/types/list";
import { useTheme } from "@material-ui/core/styles";
import { batch } from "react-redux";
import CancelIcon from "@material-ui/icons/Cancel";
import { Grid, IconButton, AppBar } from "@material-ui/core";
import { getMarkings } from "../../../services/api/blueprints";
//import { makeStyles } from "@material-ui/styles";

function SelectBlueprint({
  project,
  settings,
  dispatch,
  onChange,
  blueprintEditor,
  question,
  answers,
  submitQuestion,
  nextQuestion,
  filteredQuestions,
  jobQuestions,
  job
}) {
  const [childs, setChilds] = useState(null);
  const [markings, setMarkings] = useState(null);
  const { activeQuestion } = jobQuestions;
  const theme = useTheme();

  const getList = useCallback(() => {
    const projectFields = settings.projectFields;
    let result = [];
    const bpFields = projectFields.filter(
      (f) => f.type === PROJECT_FIELD_TYPE.BLUEPRINT_LIST
    );
    if (bpFields.length > 0) {
      bpFields.forEach((f) => {
        const bpList = project && project[f.id] ? project[f.id] : [];
        result = result.concat(bpList.filter((bp) => bp.measurements));
      });
    }
    dispatch(BlueprintEditor.setFileOptions(result));
    if (answers && answers[question.ID] && answers[question.ID].value) {
      const answerValue = answers[question.ID].value;
      result.forEach((file) => {
        if (answerValue.includes(file.id)) {
          dispatch(BlueprintEditor.setFileId(file.id));
          dispatch(
            BlueprintEditor.setImageRotation(
              file.rotation ? file.rotation : 0
            )
          );
          dispatch(
            BlueprintEditor.setImageGrayscale(
              file.grayscale ? file.grayscale : false
            )
          );
          dispatch(
            BlueprintEditor.setMeasurements(
              file.measurements ? file.measurements : null
            )
          );
        }
      });
    }
    setChilds(result);
    // eslint-disable-next-line
  }, [project, settings.projectFields, blueprintEditor, activeQuestion]);


  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (childs && childs.length > 0 && !markings) {
      const data = {
        job: job,
        question: question._id,
        files: childs.map((child) => child.location.split('?')[0])
      }
      getMarkings(data).then((res) => {
        const tmp = {};
        res.forEach((mark) => {
          tmp[mark.file] = mark.elements.length
        })
        setMarkings(tmp);
      })
    }
  }, [childs, job, question, markings]);

  const changeBlueprint = async (selected) => {
    if (!selected) return;
    const result = await getFileInfo(selected.id);
    if (result.extension === "pdf" || result.extension === ".pdf") {
      batch(() => {
        dispatch(BlueprintEditor.reset())
        dispatch(BlueprintEditor.setFile(result.Location));
        dispatch(BlueprintEditor.setFileId(result.assetId));
        dispatch(
          BlueprintEditor.setImageRotation(
            selected.rotation ? selected.rotation : 0
          )
        );
        dispatch(
          BlueprintEditor.setImageGrayscale(
            selected.grayscale ? selected.grayscale : false
          )
        );
        dispatch(
          BlueprintEditor.setMeasurements(
            selected.measurements ? selected.measurements : null
          )
        );

        const currentFile = blueprintEditor.fileOptions.filter(
          (f) => f.id === result.assetId
        )[0];
        if (currentFile) {
          dispatch(BlueprintEditor.setFileName(currentFile.value));
          if (onChange) {
            onChange(result.Location);
          }
        }
      });
    }

  };

  const onCloseClick = () => {
    activeQuestion + 1 !== filteredQuestions.length
      ? nextQuestion()
      : submitQuestion();
  };

  if (!childs) {
    return null;
  }

  return (
    <React.Fragment>
      {childs && childs.length > 0 ? (
        <List
          handleValid={() => null}
          handleChange={changeBlueprint}
          answers={{}}
          question={{
            settings: {
              childs: childs,
              markings
            },
          }}
          primary={theme.palette.primary.main}
        />
      ) : (
        <Grid container>
          <AppBar position="static">
            <Grid container alignItems="center" justify="flex-start">
              <IconButton onClick={() => onCloseClick()}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </AppBar>
          <Grid container item justify="center" style={{ marginTop: 30 }}>
            There are no available blueprints for this answer!
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default connectWithStore(SelectBlueprint, [
  "blueprintEditor",
  "jobQuestions",
]);
