import {
  WIZARD_USER_RESET,
  WIZARD_USER_SET_EMAIL,
  WIZARD_USER_SELECT_ROLE,
  WIZARD_USER_SELECT_COMPANIES,
  WIZARD_USER_SELECT_PROJECTS,
  WIZARD_USER_SET_ID,
} from "../constants";
import { getRolesWithLabels } from "../../services/roles";

const ROLES = getRolesWithLabels();

const initialState = {
  email: "",
  role: ROLES[3],
  projects: [],
  companies: [],
};

const wizardUser = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case WIZARD_USER_SET_EMAIL:
    case WIZARD_USER_SELECT_ROLE:
    case WIZARD_USER_SELECT_COMPANIES:
    case WIZARD_USER_SELECT_PROJECTS:
      return Object.assign({}, state, data);
    case WIZARD_USER_RESET:
      return Object.assign({}, state, initialState);
    case WIZARD_USER_SET_ID:
      return Object.assign({}, state, data);
    default:
      return state;
  }
};

export default wizardUser;
