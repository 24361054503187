import React, { useCallback } from "react";
import withModal from "../../../hoc/withModal";
import i18n from "../../../i18n";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import { Button, Grid } from "@material-ui/core";
import StepWizard from "react-step-wizard";
import { connectWithStore } from "../../../services/redux";
import { MultiStepFilterReducer, GridTable } from "../../../redux/actions";
import { isMobileDevice } from "../../../services/mobile";

function MultiStepValue({ value }) {
  return <span>{value ? value : i18n.t("default:_NO_VALUE_SELECTED")}</span>;
}

function StepNav({
  currentStepValue,
  totalSteps,
  onDone,
  setActiveStep,
  activeStep,
  goToStep,
  fromFiltersModal,
}) {
  const onClickNext = useCallback(() => {
    setActiveStep(activeStep + 1);
    goToStep(activeStep + 1);
  }, [goToStep, setActiveStep, activeStep]);
  const onClickBack = useCallback(() => {
    setActiveStep(activeStep - 1);
    goToStep(activeStep - 1);
  }, [goToStep, setActiveStep, activeStep]);
  return (
    <Grid
      container
      style={{
        width: "100%",
        alignContent: "center",
        justifyContent: "flex-end",
        position: "absolute",
        bottom: 0,
        right: "15px",
        zIndex: "9999",
        background: "white",
        height: "50px",
      }}
    >
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        style={{
          justifyContent: "space-around",
          alignItems: "flex-end",
          width: isMobileDevice() ? "75%" : "45%",
          alignSelf: "flex-end",
        }}
      >
        <Button
          onClick={() => onClickBack()}
          disabled={activeStep === 1}
          color="secondary"
        >
          {i18n.t("default:_BACK")}
        </Button>
        {activeStep !== totalSteps && (
          <Button
            onClick={() => onClickNext()}
            disabled={
              (!currentStepValue && activeStep !== 1) ||
              (currentStepValue &&
                currentStepValue.length === 0 &&
                activeStep !== 1)
            }
            variant="contained"
            color="primary"
          >
            {i18n.t("default:_NEXT")}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => onDone()}
          //disabled={fromFiltersModal}
        >
          {i18n.t("default:_DONE")}
        </Button>
      </Grid>
    </Grid>
  );
}

function Steps({
  steps,
  activeStep,
  onClose,
  setActiveStep,
  fromFiltersModal,
}) {
  return (
    <Grid container alignItems="center" direction="column">
      <Grid item xs={12} md={12} lg={12} sm={12} style={{ width: "100%" }}>
        <Stepper alternativeLabel nonLinear activeStep={activeStep - 1}>
          {steps.map(({ title }, index) => {
            return (
              <Step key={"s_" + index}>
                <StepButton disabled={true}>{title}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <StepWizard
          nav={
            <StepNav
              setActiveStep={setActiveStep}
              currentStepValue={steps[activeStep - 1].value}
              onDone={() => onClose()}
              activeStep={activeStep}
              fromFiltersModal={fromFiltersModal}
            />
          }
          initialStep={activeStep}
          className="WizardStepMultiStagePipeline"
        >
          {steps.map(({ RenderStep }, index) => {
            return <div key={index}>{RenderStep}</div>;
          })}
        </StepWizard>
      </Grid>
    </Grid>
  );
}

const StepsModal = withModal(Steps);

function MultiStepFilter({
  value,
  steps,
  multiStepFilter,
  dispatch,
  gridTable,
  fromFiltersModal,
}) {
  const { showModal, activeStep, multiStepAnswers } = multiStepFilter;
  const { tempFilterSelection } = gridTable;
  const setActiveStep = (step) => {
    dispatch(MultiStepFilterReducer.setActiveStep(step));
  };

  const onDoneClick = () => {
    const updatedFilters = { ...tempFilterSelection };
    updatedFilters.filterAnswers = { ...multiStepAnswers };
    dispatch(GridTable.setTempFilterSelection(updatedFilters));
    dispatch(MultiStepFilterReducer.setShowModal(false));
    dispatch(MultiStepFilterReducer.setMultistepAnswers({}));
  };

  return (
    <div>
      <StepsModal
        setActiveStep={setActiveStep}
        steps={steps}
        activeStep={activeStep}
        visible={showModal}
        onClose={() => onDoneClick()}
        fromFiltersModal={fromFiltersModal}
      />
      <div onClick={() => dispatch(MultiStepFilterReducer.setShowModal(true))}>
        <MultiStepValue value={value} />
      </div>
    </div>
  );
}

export default connectWithStore(MultiStepFilter, [
  "multiStepFilter",
  "gridTable",
]);
