import React, { Component, Suspense, lazy } from "react";
import ReactTable from "react-table";
import AsyncSelect from "react-select/lib/Async";
import UserSelectModal from "../../layout/partials/modals/user_select_modal";
import AutoSelect from "../../components/autoselect/autoselect";
import CustomTablePagination from "../../components/custom_pagination/custom_pagination";
import ReportBuilder from "../reports/report_builder";
import Modal from "react-modal";
import { isMobileDevice } from "../../services/mobile";
import i18n from "../../i18n";
import "./../../../node_modules/flatpickr/dist/themes/material_blue.css";
import "react-table/react-table.css";
import "./grid_list.scss";
import Select from "react-select";
import SelectSearch from "react-select-search";
import SearchField from "../../components/search_field/search_field";
import { TopNavMenu, GridTable } from "../../redux/actions";
import Confirm from "../../components/confirm/confirm";
import { connectWithStore } from "../../services/redux";
import moment from "moment";
import { LoadingGrid } from "./loader";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MultiStepFilter from "./filters/multi_step";
import ButtonBlock from "../button_block/button_block";
import withModal from "../../hoc/withModal";
import CustomProjectFilters from "../grid_table/filters/custom_project_filters";
import { getAllFieldsValues } from "../../services/api/projects";

const Flatpickr = lazy(() => import("react-flatpickr"));

const useStyles = (theme) => ({
  addIconText: {
    fontSize: "12px",
    marginLeft: 0,
  },
  addButton: {
    background: "#0083C9",
    height: "65px",
    width: "65px",
    "&:hover": {
      background: "#e9eeff",
    },
  },
  label: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    "&:hover": {
      color: "#0083C9 !important",
    },
  },
});

const DATE_VALUES = {
  today: "today",
  tomorrow: "tomorrow",
  last_week: "last_week",
  this_week: "this_week",
  last_month: "last_month",
  this_month: "this_month",
  next_month: "next_month",
  next_week: "next_week",
};

class GridList extends Component {
  showSearchText;

  constructor(props) {
    super(props);
    this.state = {
      activeFilter: false,
      activeSort: false,
      showReportBuilder: false,
      showUserModal: false,
      userSelected: [],
      showFiltersModal: props.showFilters ? props.showFilters : false,
      activeCheckbox: {},
      showWarningModal: false,
      hovered: {},
    };
  }

  async componentDidMount() {
    this.props.dispatch(
      TopNavMenu.setSearchHandler(() => this.toggleFiltersModel())
    );
      if (Object.keys(this.props.gridTable.projectFieldsData).length === 0) {
        getAllFieldsValues().then((res) => this.props.dispatch(GridTable.setProjectFieldsData(res.data)));
      }
  }

  onChange(value) {
    //this.onGridSwitchChange(true);
    if (this.props.onSearchChange) {
      this.props.onSearchChange(value);
      this.setState({
        showFiltersModal: false,
      });
    }
  }

  toogleFilter() {
    this.setState(
      {
        activeFilter: !this.state.activeFilter,
        showFiltersModal: !this.state.showFiltersModal,
      },
      () => {
        if (!this.state.showFiltersModal) {
          this.props.onFilterClose();
        } else {
          this.props.onFilterOpen();
        }
      }
    );
  }

  toggleSort() {
    this.setState({ activeSort: !this.state.activeSort });
  }

  toggleUserModal() {
    this.setState({ showUserModal: !this.state.showUserModal });
  }

  closeUserModal() {
    this.setState({ showUserModal: false });
  }

  onClearFilters() {
    if (this.props.onClearFilters) {
      this.props.onClearFilters();
      var checkedInputs = document.getElementsByTagName("input");
      for (var input of checkedInputs) {
        if (input.type === "radio" || input.type === "checkbox") {
          input.checked = false;
        }
      }
      this.toogleFilter();
    }

    this.setState({
      activeCheckbox: {},
      userSelected: [],
      flatPickerOptions: null,
      showDatePicker: false,
    });
  }

  onGridSwitchChange(ev) {
    this.showSearchText = ev;
    if (this.props.onSwitchChange) {
      this.props.onSwitchChange(ev);
    }
  }

  onCheckboxChange(name, value, isRadio = false) {
    const activeCheckbox = this.state.activeCheckbox || {};
    if (!activeCheckbox[name]) {
      activeCheckbox[name] = {
        [value]: true,
      };
    } else {
      activeCheckbox[name][value] = !activeCheckbox[name][value];
    }

    if (isRadio) {
      for (const item in activeCheckbox[name]) {
        if (
          activeCheckbox[name].hasOwnProperty(item) &&
          activeCheckbox[name][item] &&
          activeCheckbox[name][item] === true &&
          item !== value
        ) {
          if (!isNaN(value) && Number(item) === value) {
            activeCheckbox[name][item] = true;
          } else {
            activeCheckbox[name][item] = false;
          }
        }
      }
    }

    this.setState({
      activeCheckbox,
    });
  }

  isCheckboxChecked(filter, option) {
    let condition =
      this.state.activeCheckbox &&
      Object.values(this.state.activeCheckbox).length > 0 &&
      this.state.activeCheckbox[filter.name] &&
      this.state.activeCheckbox[filter.name][option.value];
    if (!condition && filter.value) {
      return filter.value.indexOf(option.value) !== -1;
    }
    return condition;
  }

  isFilterRadioChecked(option, filter) {
    if (!filter.value && filter.value !== 0) {
      return false;
    }
    return (
      this.state.activeCheckbox &&
      Object.values(this.state.activeCheckbox).length > 0 &&
      this.state.activeCheckbox[filter.name] &&
      this.state.activeCheckbox[filter.name][option.value]
    );
  }

  getOptionColor(type, option, filter) {
    if (!option.color) {
      return {};
    }

    const style = {};

    if (type === "radio") {
      if (!this.isFilterRadioChecked(option, filter)) {
        style.border = `1px solid ${option.color}`;
      } else {
        style.backgroundColor = option.color;
      }
    } else {
      style.border = `1px solid ${option.color}`;
      if (
        this.state.hovered[option.value] ||
        this.isCheckboxChecked(filter, option)
      ) {
        style.backgroundColor = option.color;
      }
    }

    return style;
  }

  renderAutoselectModal(filter) {
    const AutoselectComponent = withModal(AutoSelect);

    return (
      <AutoselectComponent
        title={filter.modalTitle}
        visible={filter.modalShow}
        onClose={() => filter.onClose()}
        requestUrl={filter.requestUrl}
        response={filter.response}
        selectedSuggestions={filter.selectedSuggestions}
        valueFromID={filter.valueFromID}
        onChange={(e) => filter.onChange(e)}
        disableAdd={true}
        FooterComponent={() => (
          <ButtonBlock
            backgroundColor={"white"}
            borderTop={"1px solid #269ce0"}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => filter.onClose()}
              style={{ margin: 20 }}
            >
              {i18n.t("default:_SELECT")}
            </Button>
          </ButtonBlock>
        )}
      />
    );
  }

  renderFilterOptions(type, option, filter) {
    return (
      <div
        className="FiltersModal-filters-item-option"
        key={option ? option.value : filter.name}
      >
        {type === "radio" && (
          <div className="custom-control custom-radio">
            <input
              defaultChecked={this.isFilterRadioChecked(option, filter)}
              type="radio"
              id={option.label.toLowerCase()}
              value={option.value}
              name={filter.name}
              onChange={(e) => {
                this.onCheckboxChange(filter.name, option.value, true);
                filter.onChange(e.target.value);
              }}
              className="custom-control-input"
            />
            <label
              className="custom-control-label"
              style={this.getOptionColor(type, option, filter)}
              htmlFor={option.label.toLowerCase()}
            >
              {filter.name === "sort" && <span>{i18n.t("default:_SORT")}</span>}
              {filter.name === "section" && (
                <span>{i18n.t("default:_SECTION")}</span>
              )}
              {option.label}
            </label>
          </div>
        )}
        {type === "checkbox" && (
          <div
            className="custom-control custom-checkbox"
            onMouseOver={() =>
              this.setState({
                hovered: {
                  ...this.state.hovered,
                  [option.value]: true,
                },
              })
            }
            onMouseLeave={() =>
              this.setState({
                hovered: {
                  ...this.state.hovered,
                  [option.value]: false,
                },
              })
            }
          >
            <input
              type="checkbox"
              checked={
                filter &&
                filter.value &&
                filter.value.indexOf(option.value) !== -1
              }
              id={option.label.toLowerCase()}
              value={option.value}
              name={filter.name}
              onChange={(e) => {
                //	this.onCheckboxChange(filter.name, option.value);
                filter.onChange(e.target.value);
              }}
              className="custom-control-input"
            />
            <label
              className="custom-control-label"
              htmlFor={option.label.toLowerCase()}
              style={this.getOptionColor(type, option, filter)}
            >
              {option.label}
            </label>
          </div>
        )}
        {type === "text" && (
          <div className="form-group">
            <input
              type="text"
              id={filter.name}
              name={filter.name}
              onChange={(e) => filter.onChange(e.target.value)}
              className="form-control"
            />
          </div>
        )}
        {type === "selectAuto" && (
          <div className="form-group">
            <AsyncSelect
              cacheOptions
              isMulti={filter.isMulti}
              value={filter.value}
              onChange={(selected) => filter.onChange(selected)}
              loadOptions={(query) => filter.loadOptions(query, filter.role)}
            />
            <a onClick={() => filter.onChange({ value: null })} href="# ">
              {i18n.t("default:_RESET")}[X]
            </a>
          </div>
        )}
        {type === "autoSelect" && (
          <div>
            {this.renderAutoselectModal(filter)}
            {filter.selectedSuggestions.length > 0 &&
              filter.selectedSuggestions
                .filter(
                  (sugg) =>
                    typeof sugg !== "undefined" &&
                    typeof sugg.label !== "undefined" &&
                    typeof sugg.value !== "undefined"
                )
                .map((selected) => (
                  <span key={selected.value}>{selected.label}</span>
                ))}
          </div>
        )}
        {type === "userSelect" && (
          <div>
            <UserSelectModal
              show={this.state.showUserModal}
              withRole={filter.role ? filter.role : null}
              excludeRoles={filter.excludeRoles}
              close={() => this.closeUserModal()}
              selected={filter.selected ? filter.selected : []}
              onChange={(selected) => {
                filter.onChange(selected);
                this.setState({ userSelected: selected });
                this.closeUserModal();
              }}
            />

            {filter.selected.map((selected, index) => (
              <span key={index}>{selected.label}</span>
            ))}
          </div>
        )}
        {type === "select" && (
          <div className="FiltersModal-filters-item-option-select">
            <Select
              className="react-select"
              options={filter.options}
              onChange={(e) => filter.onChange(e.value)}
              value={filter.value}
            />
          </div>
        )}

        {type === "search-select" && (
          <div>
            <SelectSearch
              value={filter.value}
              options={filter.options.map((option) => {
                return { value: option.value, name: option.label };
              })}
              placeholder={filter.name}
              onChange={(target) => {
                filter.onChange(target.value);
              }}
            />
          </div>
        )}

        {type === "date" && (
          <div>
            {option.value === "date_picker" && (
              <div className="custom-date">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    checked={option && option.initialValue ? true : false}
                    id={option.value}
                    value={[
                      moment(
                        option && option.initialValue
                          ? option.initialValue.from
                          : new Date()
                      ).toDate(),
                      moment(
                        option && option.initialValue
                          ? option.initialValue.to
                          : new Date()
                      ).toDate(),
                    ]}
                    name={filter.name}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        this.setState({
                          flatPickerOptions: null,
                        });
                      }
                      this.setState({
                        showDatePicker: e.target.checked,
                      });

                      //this.onCheckboxChange(filter.name, option.value);
                      if (option.initialValue) {
                        filter.onChange(option.initialValue);
                      }
                    }}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={option.value}
                  >
                    {option.label}
                  </label>
                </div>
                {(this.state.showDatePicker || option.initialValue) && (
                  <Suspense fallback={"loading calendar"}>
                    <Flatpickr
                      onChange={(value) => {
                        this.setState({
                          flatPickerOptions: value,
                        });
                        if (Array.isArray(value) && value.length > 1)
                          filter.onChange(value);
                      }}
                      options={{
                        ...filter.optionsDate,
                        multiple: true,
                        defaultDate: option.initialValue
                          ? [
                              moment(option.initialValue.from).toDate(),
                              moment(option.initialValue.to).toDate(),
                            ]
                          : null,
                      }}
                    />
                  </Suspense>
                )}
              </div>
            )}
            {option.value !== "date_picker" && (
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  checked={
                    filter.value && filter.value.indexOf(option.value) !== -1
                  }
                  id={option.value}
                  value={DATE_VALUES[option.value]}
                  name={filter.name}
                  onChange={(e) => {
                    this.setState({
                      showDatePicker: false,
                    });

                    //this.onCheckboxChange(filter.name, option.value);

                    filter.onChange(e.target.value, e);
                  }}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor={option.value}>
                  {option.label}
                </label>
              </div>
            )}
          </div>
        )}

        {type === "multiStep" && <MultiStepFilter {...filter} />}
      </div>
    );
  }

  toggleReportBuilder() {
    if (this.state.showReportBuilder) {
      this.setState({
        showWarningModal: true,
      });
    } else {
      this.setState({
        showReportBuilder: !this.state.showReportBuilder,
      });
    }
  }

  getType() {
    return this.props.getType();
  }

  onWarningConfirm() {
    this.setState({
      showWarningModal: false,
      showReportBuilder: false,
    });
  }

  onWarningCancel() {
    this.setState({
      showWarningModal: false,
    });
  }

  renderReportModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showReportBuilder}
        onRequestClose={() => this.toggleReportBuilder()}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
          },
        }}
      >
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_DATA_WILL_BE_LOST")}
          show={this.state.showWarningModal}
          onConfirm={() => this.onWarningConfirm()}
          onClose={() => this.onWarningCancel()}
        />
        <div className="modal-mobile-header">
          <div>
            <div
              onClick={() => this.toggleReportBuilder()}
              style={{ fontSize: 22 }}
              className="close-modal-mobile"
            >
              <i className="mdi mdi-chevron-left" />
            </div>
          </div>
        </div>
        <ReportBuilder
          filters={this.props.reportsFilter}
          changeFilter={() => this.reportChangeFilter()}
          defaultSelectedType={this.getType()}
        />
      </Modal>
    );
  }

  toggleFiltersModel() {
    this.setState(
      {
        showFiltersModal: !this.state.showFiltersModal,
      },
      () => {
        if (!this.state.showFiltersModal) {
          this.props.dispatch(GridTable.setTempFilterSelection({}));
        }
      }
    );
  }

  renderFiltersModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showFiltersModal}
        onRequestClose={() => this.toggleFiltersModel()}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
          },
        }}
      >
        <div className="modal-mobile-header">
          <div>
            <div
              onClick={() => this.toggleFiltersModel()}
              style={{ fontSize: 22 }}
              className="close-modal-mobile"
            >
              <i className="mdi mdi-chevron-left" />
            </div>
          </div>
        </div>
        <div className="FiltersModal row">
          <div className="FiltersModal-filters col col-md-12">
            <div className="FiltersModal-filters-dropdown">
              <div className="row">
                <div
                  className={
                    !this.props.showFiltersOnly
                      ? "col col-12 col-md-8"
                      : "col col-12 col-md-12"
                  }
                >
                  <ul className="FiltersModal-filters-list">
                    <div
                      style={{ marginLeft: 5 }}
                      className={
                        /* this.props.searchValue &&
                        this.props.searchValue.length > 0
                          ? "col col-12 col-md-3 SearchBar active d-sm-block d-md-none"
                          :  */ "col col-12 col-md-3 d-sm-block d-md-none"
                      }
                    >
                      <SearchField
                        placeholder="Search..."
                        value={this.props.searchValue}
                        onChange={(newVal) => this.onChange(newVal)}
                        classNames="test-class"
                      />
                    </div>
                    {this.props.filters &&
                      this.props.filters.length > 0 &&
                      this.props.filters.map((filter, i) => (
                        <li className="FiltersModal-filters-item" key={i}>
                          {filter.type === "userSelect" ? (
                            <div className="FiltersModal-filters-item-title">
                              <i className={filter.icon} />
                              <span
                                onClick={() => this.toggleUserModal()}
                                className="pointer"
                              >
                                {filter.name}
                              </span>
                              <span className="pull-right">
                                {filter.rightAddon && filter.rightAddon()}
                              </span>
                            </div>
                          ) : (
                            <div className="FiltersModal-filters-item-title">
                              <i className={filter.icon} />
                              <span onClick={filter.onShow} className="pointer">
                                {filter.name}
                              </span>
                              <span className="pull-right">
                                {filter.rightAddon && filter.rightAddon()}
                              </span>
                            </div>
                          )}

                          {filter.options &&
                            filter.type !== "select" &&
                            filter.type !== "search-select" &&
                            filter.options.map((option) =>
                              this.renderFilterOptions(
                                filter.type,
                                option,
                                filter
                              )
                            )}
                          {(filter.type === "search-select" ||
                            filter.type === "select") &&
                            this.renderFilterOptions(filter.type, null, filter)}
                          {!filter.options &&
                            filter.type &&
                            this.renderFilterOptions(filter.type, null, filter)}
                        </li>
                      ))}
                  </ul>
                </div>
                {!this.props.showFiltersOnly && (
                  <div className="col col-md-4">
                    <div className="FiltersModal-sort">
                      <ul className="text-left">
                        {this.props.sort && this.props.sort.options.length > 0 && (
                          <div
                            className="filter-sort"
                            style={{ marginBottom: 200 }}
                          >
                            <span className="filter-sort-title">
                              {i18n.t("default:_SORT")}
                            </span>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              options={this.props.sort.options}
                              onChange={(e) => this.props.sort.onChange(e)}
                            />
                          </div>
                        )}
                        <CustomProjectFilters />
                        {this.props.section &&
                          this.props.section.options.length > 0 && (
                            <div className="filter-sort">
                              <span className="filter-sort-title">
                                {i18n.t("default:_SECTION")}
                              </span>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                options={this.props.section.options}
                                onChange={(e) => this.props.section.onChange(e)}
                              />
                            </div>
                          )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="FiltersModal-filters-action"
                style={{ paddingTop: 50 }}
              >
                {!this.props.showFiltersOnly && (
                  <div style={{ float: "left" }}>
                    {this.props.hasDefaultFilters && (
                      <button
                        className="btn btn-outline"
                        onClick={() => this.props.deleteSavedFilters()}
                      >
                        {i18n.t("default:_CLEAR_DEFAULT")}
                      </button>
                    )}
                    <button
                      className="btn btn-secondary"
                      onClick={() => this.props.saveFilters()}
                    >
                      {i18n.t("default:_SAVE_AS_DEFAULT")}
                    </button>
                  </div>
                )}
                <button
                  className="btn btn-outline"
                  onClick={() => this.onClearFilters()}
                >
                  {i18n.t("default:_CLEAR_ALL")}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => this.toogleFilter()}
                >
                  {i18n.t("default:_SELECT")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  reportChangeFilter() {
    this.setState(
      {
        showReportBuilder: true,
      },
      () => this.toogleFilter()
    );
  }

  renderLoader() {
    const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
      <div>
        {items.map((i) => {
          return <LoadingGrid key={i} />;
        })}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="grid-list">
        {this.renderReportModal()}
        {this.renderFiltersModal()}
        {isMobileDevice() && (
          <div className="row ml-0">
            {this.props.renderActiveFiltersMobile &&
              this.props.renderActiveFiltersMobile(
                this.toggleFiltersModel.bind(this)
              )}
          </div>
        )}
        {!this.props.hideFilters && !this.props.disableFiltering && (
          <div className="filters-wrapper px-1 pb-3 pb-sm-0 px-sm-0 d-none d-md-block">
            <div className="row">
              {this.props.withSearch && (
                <div
                  style={{ marginLeft: 5 }}
                  className={
                    /* this.props.searchValue && this.props.searchValue.length > 0
                      ? "col col-12 col-md-4 SearchBar active"
                      :  */ "col col-12 col-md-4"
                  }
                >
                  <SearchField
                    placeholder="Search..."
                    value={this.props.searchValue}
                    onChange={(newVal) => this.onChange(newVal)}
                    classNames="test-class"
                    squeeze={this.props.squeezeSearch}
                  />
                </div>
              )}
              {this.props.withSearchSpace && (
                <div
                  style={{ marginLeft: 5 }}
                  className={
                    /* this.props.searchValue && this.props.searchValue.length > 0
                      ? "col col-12 col-md-4 SearchBar active"
                      :  */ "col col-12 col-md-4"
                  }
                ></div>
              )}
              <div
                className={`col col-12 ${
                  this.props.withSkills ? "col-md-4" : "col-md-6"
                }`}
              >
                <div className="col col-12 col-md-12 text-center text-sm-center p-3 p-sm-0">
                  {this.props.renderActiveFilters &&
                    this.props.renderActiveFilters()}
                </div>
              </div>
              <div
                className={`FiltersGroup col ${
                  this.props.withSkills ? "col-md-4" : "col-md-2"
                } row order-2 pt-3 pt-sm-0`}
                style={{ paddingRight: 45 }}
              >
                {this.props.withSkills && (
                  <div className="col col-md-3" style={{ minWidth: "80px" }}>
                    <Button
                      className={classes.addButton}
                      classes={{ label: classes.label }}
                      onClick={() => this.props.createSkill()}
                    >
                      <AddIcon />
                      {/* <i className="mdi mdi-plus-circle" style={{ fontSize: "28px" }} /> */}
                      <Typography className={classes.addIconText}>
                        {i18n.t("default:_CREATE_A_SKILL")}
                      </Typography>
                    </Button>
                  </div>
                )}
                {this.props.filters.length > 0 && (
                  <div
                    className={`col ${
                      this.props.withSkills ? "col-md-4" : "col-md-6"
                    }`}
                  >
                    <div className={"filters"}>
                      <div
                        className={
                          this.props.activeFilterLength > 0
                            ? "filter-action-title active"
                            : "filter-action-title"
                        }
                        onClick={() => this.toogleFilter()}
                      >
                        <i className="mdi mdi-filter-variant" />
                        <span>{i18n.t("default:_FILTER")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.withReports && (
                  <div
                    className={`col ${
                      this.props.withSkills ? "col-md-4" : "col-md-6"
                    }`}
                  >
                    <div
                      className="filter-action-title"
                      onClick={() => this.toggleReportBuilder()}
                    >
                      <i className="icon-line-chart" />
                      <span>{i18n.t("default:_REPORT")}</span>
                    </div>
                  </div>
                )}
                {this.props.withDownload && this.props.activeFilterLength > 0 && (
                  <div className="col col-md-6">
                    <div
                      className="filter-action-title"
                      onClick={() => this.props.downloadAll()}
                    >
                      <i className="mdi mdi-download" />
                      <span>{i18n.t("default:_DOWNLOAD")}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="grid-table">
          {this.props.data.length > 0 && (
            <div
              className={
                this.state.selectedAll
                  ? "selectAll active d-sm-none d-md-flex"
                  : "selectAll d-sm-none d-md-flex"
              }
              style={{ justifyContent: "space-between" }}
            >
              {this.props.withSelectAll ? (
                <div
                  className="grid-table-checkbox"
                  style={{
                    width: 100,
                    alignItems: "flex-start",
                    paddingLeft: 20,
                    justifyContent: "flex-start",
                    marginBottom: 10,
                  }}
                >
                  <label className="custom-checkbox" style={{}}>
                    <input type="checkbox" defaultChecked={false} />
                    <i
                      onClick={() =>
                        this.props.selected.length !== this.props.data.length
                          ? this.props.selectAll()
                          : null
                      }
                      className={
                        this.props.selected.length !== this.props.data.length
                          ? "mdi mdi-checkbox-marked-circle"
                          : "mdi mdi-checkbox-marked-circle active"
                      }
                      data-tip={i18n.t("default:_SELECT_ALL")}
                    />
                    {this.props.selected.length > 0 && (
                      <i
                        onClick={() => this.props.deselectAll()}
                        className="mdi mdi-minus-circle"
                        data-tip={i18n.t("default:_REMOVE_ALL")}
                      />
                    )}
                  </label>
                </div>
              ) : (
                <div></div>
              )}

              {this.props.renderResults && this.props.renderResults()}
            </div>
          )}
          {this.props.loading && this.renderLoader()}
          {!this.props.loading && (
            <ReactTable
              previousText={<i className="circle mdi mdi-chevron-left" />}
              nextText={<i className="circle mdi mdi-chevron-right" />}
              loadingText={i18n.t("default:_LOADING")}
              noDataText={
                this.props.noDataText ? "" : i18n.t("default:_NO_DATA")
              }
              pageText=""
              ofText={i18n.t("default:_OF")}
              rowsText={i18n.t("default:_ROWS")}
              PaginationComponent={CustomTablePagination}
              pageSizeOptions={this.props.pageSizeOptions}
              showPageJump={false}
              showPagination={
                this.props.data.length > 0 && this.props.withPagination
                  ? true
                  : false
              }
              showPageSizeOptions={this.props.showPageSizeOptions}
              pageSize={this.props.pageSize}
              loading={this.props.loading}
              page={this.props.page}
              pages={this.props.pages}
              manual
              data={this.props.data}
              columns={this.props.columns}
              minRows={0}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) =>
                    this.props.onRowClick(rowInfo.original[column.id], e),
                };
              }}
              onPageChange={(pageIndex) => this.props.onPageChange(pageIndex)}
              onPageSizeChange={(pageSize, pageIndex) =>
                this.props.onPageSizeChange(pageSize, pageIndex)
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(connectWithStore(GridList, ["settings", "gridTable"]));
