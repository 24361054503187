import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxHeight: 50,
    minWidth: 420,
    borderRadius: 10,
    backgroundColor: "#F6F8FA",
    padding: 10,
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 68,
    alignItems: "center",
    boxShadow:
      "0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12)",
    position: "relative",
    cursor: "pointer",
    marginLeft: '10px'
  },
  icon: {
    cursor: "pointer",
  },
  items: {
    position: "absolute",
    right: 40,
    cursor: "pointer",
    "& path": {
      color: "red",
    },
  },
}));

function ReportTemplateItem({ name, onSelect, onDelete, canDelete }) {
  const [showItems, setShownItems] = useState(false);
  const classes = useStyles();

  const onSelectCb = (event) => {
    if (["path", "svg"].indexOf(event.target.tagName) !== -1) {
      return;
    }

    onSelect();
  };
  return (
    <div className={classes.root} onClick={(event) => onSelectCb(event)}>
      {name}
      {canDelete && (
        <MoreVertIcon
          onClick={() => setShownItems(!showItems)}
          className={classes.icon}
        />
      )}
      {showItems && (
        <DeleteForeverIcon
          className={classes.items}
          onClick={() => onDelete()}
        />
      )}
    </div>
  );
}

export default ReportTemplateItem;
