import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import TransitionRight from "../../components/transitions/transition_right";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { isMobileDevice } from "../../services/mobile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: "unset",
  },
  snackBar: {
    left: isMobileDevice() ? "10%" : "78%",
    right: isMobileDevice() && "unset",
    bottom: "74px",
  },
  snackBarMessage: {
    padding: 0,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ToastMessage({ showAlert, handleAlertClose, message }) {
  const classes = useStyles();

  const getAlertMessage = () => {
    return (
      <Alert onClose={handleAlertClose} severity={"info"}>
        {message}
      </Alert>
    );
  };

  return (
    <Snackbar
      open={showAlert}
      className={classes.snackBar}
      autoHideDuration={3000}
      onClose={handleAlertClose}
      TransitionComponent={TransitionRight}
      message={getAlertMessage()}
      ContentProps={{
        classes: {
          root: classes.root,
          message: classes.snackBarMessage,
        },
      }}
    ></Snackbar>
  );
}

export default ToastMessage;
