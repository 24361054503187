import React, { useState, useCallback, useEffect } from "react";
import AutoComplete from "./v2autoselect";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import i18n from "../../i18n";
import { getFileTarget } from "../../services/api/file_upload";
import { getJobsList } from "../../services/api/jobs";

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  labelSpan: {
    paddingLeft: "20px",
    overflowWrap: "break-word",
    paddingTop: '10px',
  },
  labelSpanSelected: {
    color: "#408FF8",
    paddingLeft: "20px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

function PreviousJobAutoselect({
  unclickable,
  autocompleteWidth,
  withoutSelected,
  onJobChange,
  selectedSuggestions,
}) {
  const classes = useStyles();
  const [activities, setActivities] = useState([]);
  const [selected, setSelected] = useState([]);

  const checkSelectedJobs = useCallback(() => {
    if (selectedSuggestions) {
      setSelected(selectedSuggestions);
    } else {
      setSelected([]);
    }
  }, [selectedSuggestions]);

  useEffect(() => {
    checkSelectedJobs();
  }, [checkSelectedJobs]);

  const suggestionTransformer = (job) => {
    const { _id, ID, jobType, project, startDate } = job;
    return {
      label: `#${ID}`,
      value: _id,
      icon: jobType.icon,
      projectName: project.name,
      startDate,
    };
  };

  const getActivities = useCallback(async (query) => {
    //get jobs here
    const params = {
      search: query,
      page_size: 100,
    };
    const response = await getJobsList(params, "v2");
    if (response.docs) {
      setActivities(response.docs.map(suggestionTransformer));
    }
  }, []);

  const getOptionLabel = useCallback(({ label }) => {
    return label;
  }, []);

  function OptionRow({ option, selected }) {
    console.log('here 1', option);
    return (
      <React.Fragment>
        <Grid container justifyContent="space-between" alignItems="center">
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon />}
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected.includes(option)}
          />
          {option.icon ? (
            <Avatar
              alt="Activity type icon"
              src={getFileTarget(option.icon, "100x100")}
            />
          ) : (
            <Avatar>
              <i className="mdi mdi-briefcase-outline"></i>
            </Avatar>
          )}
          <ListItemText className={classes.labelSpan} primary={option.projectName} secondary={moment(option.startDate).format(
              "DD/MM/YY, HH:mm"
          )} />
          <ListItemText className={classes.labelSpan} primary={option.label} />
        </Grid>
      </React.Fragment>
    );
  }

  function SelectedRow({ selected, index, item, onClick }) {
    return (
      <ListItem className={classes.listItem} key={index} button>
        <Checkbox
          onClick={onClick}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<CheckCircleIcon className={classes.checkedUserIcon} />}
          style={{ marginRight: 8 }}
          checked={selected.includes(item)}
        />
        {item.icon ? (
          <Avatar
            alt="Activity type icon"
            src={getFileTarget(item.icon, "100x100")}
          />
        ) : (
          <Avatar>
            <i className="mdi mdi-briefcase-outline"></i>
          </Avatar>
        )}
        <ListItemText
          className={classes.labelSpanSelected}
          primary={item.projectName}
          secondary={moment(item.startDate).format(
              "DD/MM/YY, HH:mm"
          )}
        />
        <ListItemText className={classes.labelSpanSelected} primary={item.label} />
      </ListItem>
    );
  }

  const onChange = (activity) => {
    const currentlySelected = [...selected];
    currentlySelected.push(activity);
    setSelected(currentlySelected);
    if (withoutSelected) {
      //clear available options, so it can allow custom delete
      setActivities([]);
    }
    if (onJobChange) {
      onJobChange(currentlySelected);
    }
  };

  const onDeselect = (activity) => {
    if (unclickable) return;
    const currentlySelected = [...selected];
    currentlySelected.splice(currentlySelected.indexOf(activity), 1);
    setSelected(currentlySelected);
    setActivities([]);
  };

  const filterSuggestion = (suggestion) => {
    return selected.filter((s) => s.value === suggestion.value).length === 0;
  };

  const options =
    activities.length > 0 ? activities.filter(filterSuggestion) : [];
  console.log('here 1', options);
  return (
    <>
      <AutoComplete
        list={options}
        selected={selected}
        onChange={(option) => onChange(option)}
        onSearchClick={getActivities}
        renderOption={(option, index) => (
          <OptionRow index={index} selected={selected} option={option} />
        )}
        getOptionLabel={getOptionLabel}
        noOptionsDisplay="none"
        text={i18n.t("default:_TYPE_TEXT_SEARCH")}
        autocompleteWidth={autocompleteWidth}
        withSearchButton={true}
      />
      {selected.length > 0 && !withoutSelected ? (
        <List className={classes.root}>
          {selected.map((row, index) => {
            return (
              <SelectedRow
                key={index}
                item={row}
                selected={selected}
                onClick={() => onDeselect(row)}
              />
            );
          })}
        </List>
      ) : null}
    </>
  );
}

export default PreviousJobAutoselect;
