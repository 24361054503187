import React, { useCallback, useState, useEffect } from "react";
import AnswerHistory from "./answer_history";
import AnswerValue from "./answer_value";
import i18n from "../../i18n";
import { renderIcon } from "../../pages/jobs/components/editor_v2";
import ReactTooltip from "react-tooltip";
import { ROLE } from "../../services/roles";
import { connectWithStore } from "../../services/redux";
import { getTypeIcon, QuestionType } from "../../services/question";
import { PROJECT_FIELD_TYPE } from "../../pages/projects/fields/constants";
import { getFileInfo } from "../../services/api/file_upload";
import { BlueprintEditor } from "../../redux/actions";

function QuestionSummary({
  index,
  question,
  currentAnswer,
  answers,
  cb,
  onOpenFilesModal = null,
  /* currentUserLang = "en", */
  fileInfo,
  user,
  settings,
  project,
  dispatch,
  blueprintEditor,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [fileName, setFileName] = useState("");

  const userAnswers = [];
  for (let idx in answers) {
    const historyAnswer = answers[idx];
    if (!historyAnswer.user || !historyAnswer.value) continue;
    userAnswers.push({
      value: historyAnswer.value,
      avatar:
        historyAnswer.user && historyAnswer.user.imgUrl
          ? historyAnswer.user.imgUrl
          : null,
      name:
        historyAnswer.user.name &&
        (historyAnswer.user.name.first || historyAnswer.user.name.last)
          ? historyAnswer.user.name.first + " " + historyAnswer.user.name.last
          : historyAnswer.user.email,
      email: historyAnswer.user.email,
      date: historyAnswer.date ? historyAnswer.date : null,
      location: historyAnswer.location ? historyAnswer.location : null,
    });
  }
  //Reverse from oldest to newset
  userAnswers.reverse();

  let defaultClass =
    currentAnswer && currentAnswer.isIssue
      ? "JobPanelQuestion issued pointer"
      : "JobPanelQuestion pointer";
  if (question.settings.managementNote) {
    defaultClass += " managementNote";
  }

  const getIconColor = (type) => {
    switch (type) {
      case QuestionType.DATE_TIME:
        return "#FFD700";
      case QuestionType.BOOL:
        return "#C71585";
      case QuestionType.LIST:
        return "#FF4500";
      case QuestionType.PROJECT_LIST:
        return "#F0E68C";
      case QuestionType.SYSTEM_LIST:
        return "#8A2BE2";
      case QuestionType.TEXT:
        return "#5F9EA0";
      case QuestionType.IMAGE:
        return "#EE82EE";
      case QuestionType.LENGTH:
        return "#00BFFF";
      case QuestionType.MESSAGE:
        return "#00FF7F";
      case QuestionType.PROJECT_VALUE:
        return "#2F4F4F";
      case QuestionType.SUMMARY:
        return "#228B22";
      case QuestionType.PROJECT_TABLE:
        return "#808000";
      case QuestionType.BLUEPRINT:
        return "#800000";
      default:
        return "#E9967A";
    }
  };

  const showHistoryBlock = (e) => {
    if (showHistory) {
      setShowHistory(false);
    } else {
      setShowHistory(true);
    }
    e.stopPropagation();
  };

  const getFileName = useCallback(async () => {
    const projectFields = settings.projectFields;
    let result = [];
    const bpFields = projectFields.filter(
      (f) => f.type === PROJECT_FIELD_TYPE.BLUEPRINT_LIST
    );
    if (bpFields.length > 0) {
      bpFields.forEach((f) => {
        const bpList = project && project[f.id] ? project[f.id] : [];
        result = result.concat(bpList);
      });
    }
    const updatedResult = await Promise.all(
      result.map(async (row) => {
        const updatedRow = { ...row };
        if (!updatedRow.location) {
          const fileInfo = await getFileInfo(row.id);
          updatedRow.location = fileInfo && fileInfo.Location;
        }
        return updatedRow;
      })
    );
    if (updatedResult.length === 1) {
      const fileName = updatedResult[0].value;
      setFileName(fileName);
    } else if (updatedResult.length > 1) {
      const bpvalue = currentAnswer ? currentAnswer.value : "";
      const selected = updatedResult.filter((bp) => {
        return bp.location === bpvalue;
      });
      if (selected.length > 0) {
        setFileName(selected[0].value);
      }
    }
  }, [project, settings.projectFields, currentAnswer]);

  useEffect(() => {
    getFileName();
  }, [project, getFileName]);

  const onSummaryClick = (e) => {
    const isCallButton = e.target.parentElement.classList.contains('callButton');
    if(isCallButton){
      return;
    }
    const { rightPanelJob } = blueprintEditor;
    if (question.type === QuestionType.BLUEPRINT && !rightPanelJob) {
      dispatch(BlueprintEditor.setFileName(fileName));
      dispatch(BlueprintEditor.setIsBpEditorOpen(true));
    }
    return cb ? cb(index, false, e) : null;
  };

  return (
    <div
      key={index}
      className={defaultClass}
      onClick={(e) => onSummaryClick(e)}
    >
      <div className="JobPanelQuestion-row">
        <div className="JobPanelQuestion-icon">
          <i
            style={{ color: getIconColor(question.type) }}
            className={`mdi ${getTypeIcon(question.type)} material-icons`}
          />
          {/* <span className="material-icons">storage</span> */}
        </div>
        <div className="JobPanelQuestion-title">
          <span dir="auto">{question.title}</span>
          {question.type === QuestionType.BLUEPRINT && fileName && (
            <span>{` / ${fileName}`}</span>
          )}
        </div>
      </div>
      <div className="JobPanelQuestion-row">
        <div className="JobPanelQuestion-answer">
          <h6 dir="auto">
            {<AnswerValue currentAnswer={currentAnswer} question={question} project={project} settings={settings} />}
            {/* {currentAnswer && preformatedValue} */}
            {currentAnswer === 0 && i18n.t("default:_NO_ANSWER")}
          </h6>
          {currentAnswer && currentAnswer.signature && (
            <div className="signature">
              <img alt={"signature"} src={currentAnswer.signature} />
            </div>
          )}
          <div
            className="JobPanelQuestion-footer"
            style={{ justifyContent: "space-between" }}
          >
            <ReactTooltip effect="solid" />
            {currentAnswer &&
              currentAnswer.user &&
              user.profile.role !== ROLE.CLIENT && (
                <div
                  dir="auto"
                  data-tip={
                    currentAnswer.user.name ? currentAnswer.user.email : ""
                  }
                  className="CurrentUserInfo"
                >
                  <span>
                    {currentAnswer.user.name
                      ? `${currentAnswer.user.name.first} ${currentAnswer.user.name.last}`
                      : currentAnswer.user.email}
                  </span>
                  {currentAnswer.history && currentAnswer.history.length > 0 && (
                    <span
                      onClick={(e) => showHistoryBlock(e)}
                      className="history"
                    >
                      {`(${i18n.t("default:_HISTORY")})`}
                    </span>
                  )}
                </div>
              )}
            <div className="CommentAndFiles">
              {question.settings.allowComment &&
                currentAnswer &&
                currentAnswer.comment &&
                currentAnswer.comment.length > 0 && (
                  <div
                    data-tip={currentAnswer.comment}
                    className="JobPanelQuestion-comments"
                  >
                    <i className="mdi mdi-comment-outline" />
                  </div>
                )}
              {currentAnswer &&
                currentAnswer.location &&
                user.profile.role === ROLE.SYSTEM_ADMIN && (
                  <div
                    data-tip={i18n.t(
                      "default:_APPROXIMATE_LOCATION_CLICK_TO_SEE"
                    )}
                    className="JobPanelQuestion-location"
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.com/maps/search/?api=1&query=${currentAnswer.location.lat},${currentAnswer.location.long}`}
                    >
                      <i className="mdi mdi-map-marker" />
                    </a>
                  </div>
                )}
              {question.settings.allowAddingFile &&
                currentAnswer &&
                currentAnswer.files && (
                  <div
                    onClick={() => {
                      if (onOpenFilesModal) {
                        onOpenFilesModal(currentAnswer.files);
                      }
                    }}
                    className="JobPanelQuestion-files"
                  >
                    {currentAnswer &&
                      currentAnswer.files.length > 0 &&
                      renderIcon(currentAnswer.files, fileInfo)}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {showHistory && (
        <div className="JobPanelQuestion-row">
          <div className="JobPanelQuestion-history">
            <h6 dir="auto">
              {currentAnswer && (
                <AnswerHistory
                  currentAnswer={currentAnswer}
                  answers={userAnswers}
                  currentUserLang="en"
                  user={user}
                />
              )}
            </h6>
          </div>
        </div>
      )}
    </div>
  );
}

export default connectWithStore(QuestionSummary, [
  "user",
  "settings",
  "blueprintEditor",
]);
