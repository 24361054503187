import { APPS_SET_ENABLED } from "../constants";

const initalState = { enabled: [] };

const apps = (state = Object.assign({}, initalState), action) => {
  switch (action.type) {
    case APPS_SET_ENABLED:
      return Object.assign({}, state, {
        enabled: action.data.filter((r) => r !== null),
      });
    default:
      return state;
  }
};

export default apps;
