const initialState = { bp_list_visible: undefined };

const utils = (state = Object.assign({}, initialState), action) => {
    switch (action.type) {
        case 'BP_LIST_VISIBLE':
            state.bp_list_visible = action.data;
            return Object.assign({}, state);
        default:
            return Object.assign({}, state);;
    }
};

export default utils;