export const PEN_TOOL = "PEN_TOOL";
export const RECT_TOOL = "RECT_TOOL";
export const CIRCLE_TOOL = "CIRCLE_TOOL";
export const LINE_TOOL = "LINE_TOOL";
export const LAMP_TOOL = "LAMP_TOOL";
export const POLYGON_TOOL = "POLYGON_TOOL";
export const POLYGON_OPEN = "POLYGON_OPEN";

export const MOVE_TOOL = "MOVE_TOOL";
export const MEASURE_TOOL_VERTICAL = "MEASURE_TOOL_VERTICAL";
export const MEASURE_TOOL_HORIZONTAL = "MEASURE_TOOL_HORIZONTAL";
export const SCISSORS_TOOL = "SCISSORS_TOOL";

export const SHAPE = {
  THIN_LINE: 0,
  THICK_LINE: 1,
  RECT_OUTLINED: 2,
  RECT_FILLED: 3,
  CIRCLE_OUTLINED: 4,
  CIRCLE_FILLED: 5,
  DOODLE: 6,
  LAMP: 7,
  POLYGON_OUTLINED: 8,
  POLYGON_FILLED: 9,
  POLYGON_OPEN: 10,
};

export const CONSTRUCTOR_NAME = {
  RECT: "Rect",
  LINE: "Line",
  CIRCLE: "Circle",
  ARROW: "Arrow",
  IMAGE: "Image",
  LAMP: "Lamp",
  LABEL: "Label",
  POLYGON: "Polygon",
  DOODLE: "Doodle",
};

export const BLUEPRINT_ID = "blueprint";

export const MEASURE_TYPE = {
  HEIGHT: "height",
  WIDTH: "width",
};
