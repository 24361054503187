import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import MuiSwitch from "../../switch/mui_switch";
import { makeStyles, Grid } from "@material-ui/core";
import { connectWithStore } from "../../../services/redux";
import { BlueprintEditor } from "../../../redux/actions";
import { getLineWidth } from "./utils";
import { SHAPE } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "10%",
    marginBottom: 10,
  },
  toggleLabel: {
    fontSize: "0.8em",
  },
  labelPlacementStart: {
    margin: 0,
  },
}));

function HeightAsWidthToggle({ element, type, blueprintEditor, dispatch }) {
  const classes = useStyles();
  const shape = blueprintEditor.app.find("#" + element.id)[0];
  const attrs = shape ? shape.attrs : null;
  const { layer, elements } = blueprintEditor;
  const [widthAsHeight, setWidthAsHeight] = useState(false);

  useEffect(() => {
    if (attrs && attrs.widthAsHeight) {
      setWidthAsHeight(true);
    } else {
      setWidthAsHeight(false);
    }
  }, [attrs]);

  const toggleWidthAsHeight = (value) => {
    if (!shape) return;
    shape.setAttr("widthAsHeight", value);
    const newElements = [...elements];
    const elementIds = newElements.map((element) => element.id);
    const elementIndex = elementIds.indexOf(shape.attrs.id);
    if (value) {
      if (type === SHAPE.THICK_LINE || type === SHAPE.THIN_LINE) {
        const shapeWidth = getLineWidth(shape);
        shape.setAttr("strokeHeight", shapeWidth);
      } else {
        //set it same as stroke width
        const strokeWidth = shape.strokeWidth();
        shape.setAttr("strokeHeight", strokeWidth);
      }
      newElements[elementIndex].widthAsHeight = true;
    } else {
      newElements[elementIndex].widthAsHeight = false;
    }
    dispatch(BlueprintEditor.setElements(newElements));
    setWidthAsHeight(value);
    layer.batchDraw();
  };

  return (
    <Grid container className={classes.root}>
      <MuiSwitch
        label={i18n.t("default:_USE_WIDTH_AS_HEIGHT")}
        onToggle={(e) => toggleWidthAsHeight(e.target.checked)}
        checked={widthAsHeight}
        labelStyles={classes.toggleLabel}
        labelPlacement="start"
        labelPlacementStartStyles={classes.labelPlacementStart}
      />
    </Grid>
  );
}

export default connectWithStore(HeightAsWidthToggle, ["blueprintEditor"]);
