import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import Button from "@material-ui/core/Button";
import SelectUserCompanies from "../create_wizard/create_user/select_user_companies";
import SelectUserProjects from "../create_wizard/create_user/select_user_projects";
import { connectWithStore } from "../../services/redux";
import { updateUser } from "../../services/api/user";
import { WizardUser } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "500px",
  },
  source: {
    height: "150px",
    width: "100px",
    //border: "3px solid #DBDBDB",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: "#DBDBDB",
    },
  },
  buttonContainer: {
    position: "absolute",
    right: "20px",
    bottom: "10px",
    zIndex: "9999",
  },
  button: {
    marginLeft: "20px",
  },
}));

function StepNav({
  activeStep,
  setActiveStep,
  goToStep,
  totalSteps,
  wizardUser,
  user,
  onClose,
  onUpdate,
}) {
  const classes = useStyles();

  const onNextClick = () => {
    setActiveStep(activeStep + 1);
    goToStep(activeStep + 1);
  };

  const onBackClick = () => {
    setActiveStep(activeStep - 1);
    goToStep(activeStep - 1);
  };

  const onDoneClick = async () => {
    user.companies = wizardUser.companies;
    user.client_projects = wizardUser.projects;
    if (onUpdate) {
      onUpdate(user);
    }
    const updatedUser = { ...user };
    updatedUser.client_projects = wizardUser.projects.map((p) => p._id);
    await updateUser(updatedUser);
    onClose();
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="flex-end"
      direction="row"
      className={classes.buttonContainer}
    >
      <Button
        disabled={activeStep === 1}
        color="secondary"
        onClick={() => onBackClick()}
      >
        {i18n.t("default:_BACK")}
      </Button>
      {activeStep !== totalSteps && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => onNextClick()}
        >
          {i18n.t("default:_NEXT")}
        </Button>
      )}
      {activeStep === totalSteps && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => onDoneClick()}
        >
          {i18n.t("default:_DONE")}
        </Button>
      )}
    </Grid>
  );
}

function ClientWizard({ wizardUser, user, onClose, onUpdate, dispatch }) {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    dispatch(
      WizardUser.setCompanies({
        companies: user.companies,
        projects: user.client_projects,
      })
    );
  }, [dispatch, user]);
  return (
    <React.Fragment>
      <StepWizard
        className="stepWizard"
        isLazyMount={true}
        nav={
          <StepNav
            wizardUser={wizardUser}
            user={user}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            onClose={onClose}
            onUpdate={onUpdate}
          />
        }
      >
        <SelectUserCompanies fromUserEditor={true} />
        <SelectUserProjects
          selectedProjects={user.client_projects}
          fromUserEditor={true}
        />
      </StepWizard>
    </React.Fragment>
  );
}

export default connectWithStore(ClientWizard, ["wizardUser"]);
