import React, { Component, Fragment } from "react";
import UserSelectModal from "../../layout/partials/modals/user_select_modal";
import i18n from "../../i18n";
import "./circle_user_list.scss";
import { UserAutocomplete } from "../../redux/actions";
import { connectWithStore } from "../../services/redux";
import { getFileTarget } from "../../services/api/file_upload";
import ReactTooltip from "react-tooltip";
import { mapUser } from "../../services/user";

class CircleUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selected: CircleUserList.formatUsersFromProps(this.props),
    };
    this.selfRef = null;
  }

  static formatUsersFromProps(props) {
    return props.users
      ? props.users
          .filter((user) => typeof user !== "undefined" && user !== null)
          .map((user) => mapUser(user))
      : [];
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.users) !==
      JSON.stringify(this.props.users) /* &&
      this.props.users.length > 0 */
    ) {
      this.setState(
        { selected: CircleUserList.formatUsersFromProps(this.props) },
        () => {
          if (!this.props.fromFiles) {
            ReactTooltip.rebuild();
          }
          this.forceUpdate();
        }
      );
    }
  }

  componentWillReceiveProps(prevProps) {
    if (
      JSON.stringify(prevProps.users) !== JSON.stringify(this.props.users) &&
      this.props.users &&
      this.props.users.length > 0
    ) {
      this.setState({
        selected: CircleUserList.formatUsersFromProps(this.props),
      });
    }
  }

  renderModal() {
    const { showModal, selected } = this.state;
    return (
      <div>
        {showModal && (
          <UserSelectModal
            includeMe={this.props.includeMe}
            triggerImmediateChange={this.props.triggerImmediateChange}
            listItemColorFn={
              this.props.listItemColorFn ? this.props.listItemColorFn : null
            }
            searchUser={this.props.searchUser ? this.props.searchUser : null}
            disableNonTemp={this.props.disableNonTemp}
            show={showModal}
            skills={this.props.skills ? this.props.skills : []}
            excludeRoles={this.props.excludeRoles}
            withRole={this.props.withRole ? this.props.withRole : null}
            close={() => this.closeModal()}
            selected={selected}
            onChange={(selected, forceClose) => {
              this.onChange(selected, forceClose);
            }}
            predefinedUsersList={this.props.predefinedUsersList}
            additionalFilter={this.props.additionalFilter}
          />
        )}
      </div>
    );
  }

  openModal() {
    this.setState(
      {
        showModal: true,
      },
      () => {
        if (this.props.job) {
          this.props.dispatch(
            UserAutocomplete.setJobDate(this.props.job.startDate)
          );
        }
      }
    );
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  closeModal() {
    this.setState(
      {
        showModal: false,
      },
      () => {
        if (this.props.job) {
          this.props.dispatch(UserAutocomplete.setJobDate(null));
        }
      }
    );
  }

  onChange(selected, forceClose) {
    this.setState({
      selected: selected,
    });
    if (this.props.onChange) {
      this.props.onChange(selected);
    }

    if (!this.props.changeWithoutClose || forceClose) {
      this.closeModal();
    }
  }

  containsTarget(target) {
    if (this.selfRef && this.selfRef.contains(target)) {
      return true;
    }
    return false;
  }

  usersToShow() {
    return this.props.usersToShow ? this.props.usersToShow : 3;
  }

  render() {
    return (
      <div
        ref={(ref) => (this.selfRef = ref)}
        className="CircleUserList pointer"
      >
        {this.props.showAddButton ||
          typeof this.props.showAddButton === undefined}
        {this.renderModal()}
        <ul className="list-users" onClick={this.props.toggleModal}>
          {this.state.selected &&
            this.state.selected.length > 0 &&
            this.state.selected
              .filter((u, index) => index < this.usersToShow())
              .filter((option) => typeof option !== "undefined")
              .map((user, i) => {
                return (
                  <li key={i}>
                    <div
                      className="text-center"
                      data-place="bottom"
                      data-tip={
                        user && user.name && (user.name.first || user.name.last)
                          ? user.name.first + " " + user.name.last
                          : user && user.email
                      }
                    >
                      {user && !user.avatar && (
                        <Fragment>
                          <i
                            className={
                              user && user.inTraining
                                ? `circle mdi mdi-account trainee`
                                : `circle mdi mdi-account`
                            }
                          />
                          {user && user.greenMark && (
                            <i className="mdi mdi-check-circle" />
                          )}
                        </Fragment>
                      )}

                      {user && user.avatar && (
                        <Fragment>
                          <img
                            className={
                              user.inTraining ? "trainee circle " : "circle"
                            }
                            src={getFileTarget(user.avatar, "100x100")}
                            alt={
                              user.name
                                ? user.name.first + " " + user.name.last
                                : user.email
                            }
                          />
                          {user.greenMark && (
                            <i className="mdi mdi-check-circle" />
                          )}
                        </Fragment>
                      )}
                    </div>
                  </li>
                );
              })}
          {(this.props.showAddButton ||
            typeof this.props.showAddButton === undefined) && (
            <li className="add-user">
              <div
                className="text-center"
                data-place="bottom"
                data-tip={`${i18n.t("default:_ASSIGN_USERS", {
                  users: i18n.t("default:_USERS"),
                })}`}
                onClick={() => (!this.props.noModal ? this.openModal() : null)}
              >
                <i className="circle mdi mdi-plus" />
              </div>
            </li>
          )}
          {this.state.selected &&
            this.state.selected.length > this.usersToShow() && (
              <li>
                <div
                  className="text-center"
                  data-place="bottom"
                  data-tip={`${
                    this.state.selected.length - this.usersToShow()
                  } ${i18n.t("default:_MORE")}`}
                >
                  <i
                    className="circle numeric"
                    data-count={this.state.selected.length - this.usersToShow()}
                  ></i>
                </div>
              </li>
            )}
        </ul>
      </div>
    );
  }
}
export default connectWithStore(CircleUserList, []);
