import Http from "../http";

export const getJobsList = async (params, version = "v1") => {
  const path = `/${version}/jobs`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getCurrentJob = async (params, version = "v1") => {
  const path = `/${version}/jobs/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createJob = async (params) => {
  const path = `/v2/jobs`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const cloneJob = async (params) => {
  const path = `/v1/jobs/${params.id}/clone`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const removePrevious = async (idJob, targetJob) => {
  const path = `/v1/jobs/${idJob}/remove_prev/${targetJob}`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path);

    return response.data;
  } catch (e) {
    return {};
  }
};

export const addPrevious = async (idJob, targetJob) => {
  const path = `/v1/jobs/${idJob}/add_prev/${targetJob}`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path);

    return response.data;
  } catch (e) {
    return {};
  }
};

export const toggleAttendance = async (jobId, userId) => {
  const path = `/v1/jobs/${jobId}/attendance/${userId}`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path);
    return response.data;
  } catch (e) {
    console.log("Error", e);
    return {};
  }
};

export const deleteJobs = async (params) => {
  const path = `/v1/jobs/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteMultipleJobs = async (params) => {
  const path = `/v1/jobs/delete_multiple`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path, {
      data: {
        id: params["ids"],
      },
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateJob = async (params) => {
  const path = `/v1/jobs/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const assignMultiple = async (params) => {
  const path = `/v1/jobs/assign_multiple`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const assignSingle = async (params) => {
  const path = `/v1/jobs/${params.id}/assign`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, {
      users: params.users,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const feedsJobs = async (params) => {
  const path = `/v2/jobs/${params.id}/feeds?page=${params.page}&page_size=${params.page_size}`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
    };
  }
};

export const visit = async (params) => {
  const path = `/v1/jobs/${params.id}/visit`;
  try {
    const response = await Http.sharedInstance.getClient().put(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: [],
    };
  }
};

export const view = async (id) => {
  const path = `/v1/jobs/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return null;
  }
};

export const viewJob = async (id) => {
  const path = `/v1/jobs/view/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return null;
  }
};

export const jobsDashboard = async () => {
  const path = `/v1/jobs/dashboard`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
    };
  }
};

export const recentJobsDashboard = async () => {
  const path = `/v1/jobs/dashboard/recent`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response ? e.response.data : null,
    };
  }
};

export const statusCounters = async () => {
  const path = `/v1/jobs/dashboard/counters`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
    };
  }
};

export const assignManyToMany = async (assignmentArray) => {
  const path = `/v1/jobs/assign_many`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      jobs: assignmentArray,
    });

    return response.data;
  } catch (e) {
    return {
      success: false,
      data: null,
    };
  }
};

// export const exportJob = async (id) => {
//   const path = `/v1/jobs/${id}/export`;
//   try {
//     const response = await Http.sharedInstance.withAuth().get(path);
//
//     return response.data;
//   } catch (e) {
//     return {
//       success: false,
//       data: null,
//     };
//   }
// };

export const exportJob = async (id) => {
  let jobID = id;
  const combined = [];
  if (Array.isArray(jobID)) {
    jobID = jobID[0];
    // remove the first element and combine the rest
    combined.push(...id.slice(1));
  }
  let path = `/v2/jobs/${jobID}/export`;

  if (combined.length > 0) {
    path += `?combined=${combined.join(",")}`;
  }
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      responseType: "blob",
    });

    const headers = response.headers;
    const filename = headers["content-disposition"].split("filename=")[1];

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = filename;
    link.href = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    link.click();
    return null;
  } catch (e) {
    const blob = e.response.data;
    alert(await blob.text());
    return {
      success: false,
      data: null,
    };
  }
};
