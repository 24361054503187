import React from "react";
import FieldPreview from "./field_preview";
import { connectWithStore } from "../../../services/redux";
import JobBadge from "../../../components/job_badge/job_badge";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PROJECT_FIELD_TYPE, ICON } from "./constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 5,
    cursor: "normal",
  },
}));

export function StatusList({
  noLabel,
  label,
  icon,
  id,
  currentProject,
  editable,
}) {
  const { project } = currentProject;
  const statuses =
    !editable && project && project[id] ? Object.keys(project[id]) : [];
  const counters = !editable ? project[id] : [];
  const classes = useStyles();
  let pathName = window.location.pathname;
  let applyLink =
    pathName === "/projects" || pathName.indexOf("/project/view") !== -1;

  pathName = project && project._id ? `/project/view/${project._id}` : `/project/view/`;

  return (
    <FieldPreview
      noLabel={noLabel}
      label={label}
      icon={icon}
      defaultIcon={ICON[PROJECT_FIELD_TYPE.STATUS_LIST]}
    >
      <Grid
        container
        justify={noLabel ? "center" : "flex-start"}
        alignItems={noLabel ? "center" : "flex-start"}
        spacing={2}
        className={classes.container}
      >
        {!editable &&
          statuses.length > 0 &&
          statuses.map((status, index) => (
            <Grid key={index} item>
              <JobBadge
                noLabel={true}
                linkTo={applyLink ? pathName + "?filterStatus=" + status : null}
                count={counters[status]}
                status={status}
              />{" "}
            </Grid>
          ))}
      </Grid>
    </FieldPreview>
  );
}

export default connectWithStore(StatusList, ["currentProject"]);
