import moment from "moment";
import { MODE_DATES_BY_USER } from "../../pages/shift_view/constants";
import DateService from "../../services/date";
import { isMobileDevice } from "../../services/mobile";
import {
  SHIFT_VIEW_SET_USERS,
  SHIFT_VIEW_SET_JOBS,
  SHIFT_VIEW_SET_RANGE,
  SHIFT_VIEW_SET_USER_PANEL_MODE,
  SHIFT_VIEW_SET_SHOW_STATUSES,
  SHIFT_VIEW_SET_SHOW_JOB_TYPES,
  SHIFT_VIEW_SET_SEARCH_PHRASE,
  SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED,
  SHIFT_VIEW_SET_TEMP_ASSIGNED,
  SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES,
  SHIFT_VIEW_SET_DROPZONES,
  SHIFT_VIEW_SET_DRAGGER,
  SHIFT_VIEW_SET_RIGHT_PANEL_USER,
  SHIFT_VIEW_SET_RIGHT_PANEL_JOB,
  SHIFT_VIEW_SET_OUTSIDE_CONFIRM,
  SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP,
  SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES,
  SHIFT_VIEW_SET_IS_ASSIGN,
  SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS,
  SHIFT_VIEW_SET_SELECTED_USER,
  SHIFT_VIEW_SET_DRAGGED,
  SHIFT_VIEW_SET_USER_HOVER,
  SHIFT_VIEW_SET_DROPPABLE_CACHE,
  SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES,
  SHIFT_VIEW_SET_USERS_LOADING,
  SHIFT_VIEW_SET_JOBS_LOADING,
  SHIFT_VIEW_SET_MINI_VIEW,
  SHIFT_VIEW_LOAD_DATA,
  SHIFT_VIEW_SET_SHARE_LINK,
  SHIFT_VIEW_SET_SHARE_MODAL,
  SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB,
  SHIFT_VIEW_SET_ONE_DAY_VIEW,
} from "../constants";

const { from, to } = DateService.parseDateExpression("today");

const initialState = {
  users: [],
  jobs: [],
  from: isMobileDevice() ? from.toDate() : moment().startOf("month").toDate(),
  to: isMobileDevice() ? to.toDate() : moment().endOf("week").toDate(),
  userPanelMode: MODE_DATES_BY_USER,
  showStatuses: [],
  showJobTypes: [],
  showStatusesTransformed: [],
  showJobTypesTransformed: [],
  hideAssigned: false,
  searchPhrase: "",
  tempAssigned: [],
  tempAssignedDates: [],
  dropzones: [],
  draggerId: null,
  rightPanelUser: null,
  rightPanelJob: null,
  outsideAssignConfirm: false,
  outsideAssignConfirmJob: null,
  toBeAssignedTemp: null,
  toBeAssignedTempDates: null,
  isAssigning: false,
  assignmentProgress: null,
  selectedUser: null,
  dragged: null,
  userHover: null,
  dropableCache: {},
  jobsLoading: true,
  usersLoading: true,
  isMiniView: true,
  shareModal: false,
  shareLink: null,
  oneDayView: false,
};

function shiftView(state = Object.assign({}, initialState), action) {
  const { type, data } = action;
  switch (type) {
    case SHIFT_VIEW_SET_JOBS:
      return Object.assign({}, state, {
        jobs: data,
      });
    case SHIFT_VIEW_SET_USERS:
      return Object.assign({}, state, {
        users: data,
      });
    case SHIFT_VIEW_SET_RANGE:
      const { from, to } = data;
      return Object.assign({}, state, {
        from: from,
        to: to,
      });
    case SHIFT_VIEW_SET_USER_PANEL_MODE:
      return Object.assign({}, state, {
        userPanelMode: data,
      });
    case SHIFT_VIEW_SET_SHOW_JOB_TYPES:
      return Object.assign({}, state, {
        showJobTypes: data,
        showJobTypesTransformed: data.map((o) => o.value),
      });
    case SHIFT_VIEW_SET_SHOW_STATUSES:
      return Object.assign({}, state, {
        showStatuses: data,
        showStatusesTransformed: data.map((o) => o.value),
      });
    case SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED:
      return Object.assign({}, state, {
        hideAssigned: state.hideAssigned ? false : true,
      });
    case SHIFT_VIEW_SET_SEARCH_PHRASE:
      return Object.assign({}, state, {
        searchPhrase: data,
      });
    case SHIFT_VIEW_SET_TEMP_ASSIGNED:
      return Object.assign({}, state, {
        tempAssigned: data,
      });
    case SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES:
      return Object.assign({}, state, {
        tempAssignedDates: data,
      });
    case SHIFT_VIEW_SET_DROPZONES:
      return Object.assign({}, state, {
        dropzones: data,
      });
    case SHIFT_VIEW_SET_DRAGGER:
      return Object.assign({}, state, {
        draggerId: data,
      });
    case SHIFT_VIEW_SET_RIGHT_PANEL_USER:
      return Object.assign({}, state, {
        rightPanelUser: data,
      });
    case SHIFT_VIEW_SET_RIGHT_PANEL_JOB:
      return Object.assign({}, state, {
        rightPanelJob: data,
      });
    case SHIFT_VIEW_SET_OUTSIDE_CONFIRM:
      return Object.assign({}, state, {
        outsideAssignConfirm: data,
      });
    case SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB:
      return Object.assign({}, state, {
        outsideAssignConfirmJob: data,
      });
    case SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP:
      return Object.assign({}, state, {
        toBeAssignedTemp: data,
      });
    case SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES:
      return Object.assign({}, state, {
        toBeAssignedTempDates: data,
      });
    case SHIFT_VIEW_SET_IS_ASSIGN:
      return Object.assign({}, state, {
        isAssigning: data,
      });
    case SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS:
      return Object.assign({}, state, {
        SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS: data,
      });
    case SHIFT_VIEW_SET_SELECTED_USER:
      return Object.assign({}, state, {
        selectedUser: data,
      });
    case SHIFT_VIEW_SET_DRAGGED:
      return Object.assign({}, state, {
        dragged: data,
      });
    case SHIFT_VIEW_SET_USER_HOVER:
      return Object.assign({}, state, {
        userHover: data,
      });
    case SHIFT_VIEW_SET_DROPPABLE_CACHE:
      return Object.assign({}, state, {
        dropableCache: data,
      });
    case SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES:
      return Object.assign({}, state, {
        userHover: data.userHover,
        dropzones: data.dropzones,
        draggerId: data.dragger,
      });
    case SHIFT_VIEW_SET_USERS_LOADING:
      return Object.assign({}, state, {
        usersLoading: data,
      });
    case SHIFT_VIEW_SET_JOBS_LOADING:
      return Object.assign({}, state, {
        jobsLoading: data,
      });
    case SHIFT_VIEW_SET_MINI_VIEW:
      if (state.isMiniView !== data) {
        return Object.assign({}, state, {
          isMiniView: data,
        });
      }
      return state;
    case SHIFT_VIEW_LOAD_DATA:
      return Object.assign({}, state, {
        ...data,
      });
    case SHIFT_VIEW_SET_SHARE_LINK:
      return Object.assign({}, state, {
        shareLink: data,
      });
    case SHIFT_VIEW_SET_SHARE_MODAL:
      return Object.assign({}, state, {
        shareModal: data,
      });
    case SHIFT_VIEW_SET_ONE_DAY_VIEW:
      return Object.assign({}, state, {
        oneDayView: data,
      });
    default:
      return state;
  }
}

export default shiftView;
