import React, { useState, useCallback, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AutoComplete from "./v2autoselect";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import i18n from "../../i18n";
import { connectWithStore } from "../../services/redux";
import ToggleService from "../../services/toggle";
import SkillChip from "../skills/skill_chip";
import SKILL_COLORS from "../../services/skill_colors";
import { intersect } from "../../services/helpers";
import { ROLE } from "../../services/roles";
import SkillToolip from "../tooltip/skill_tooltip";
import { USER_AUTOCOMPLETE_TAB } from "../../redux/reducers/user_autocomplete";
import HtmlTooltip from "../tooltip/html_tooltip";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import { Typography } from "@material-ui/core";
import DateService from "../../services/date";
import { isMobileDevice } from "../../services/mobile";
import { getFileTarget } from "../../services/api/file_upload";

const ROLE_LABEL = {
  [ROLE.SYSTEM_ADMIN]: i18n.t("default:_SYS_ADMIN"),
  [ROLE.PROJECT_MANAGER]: i18n.t("default:_PROJECT_MANAGER"),
  [ROLE.INSPECTOR]: i18n.t("default:_INSPECTOR"),
  [ROLE.BUSINESS_ADMIN]: i18n.t("default:_BUSINESS_ADMIN"),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  labelSpan: {
    paddingLeft: "20px",
    overflowWrap: "break-word",
  },
  roleSpan: {
    textAlign: "right",
  },
  roleSpanSecondary: {
    textAlign: "right",
  },
  labelSpanSelected: {
    color: "#408FF8",
    overflowWrap: "break-word",
  },
  roleSpanSelected: {
    textAlign: "right",
    color: "#408FF8",
  },
  userAvatar: {
    background: "black",
    fontSize: "26px",
    width: "36px",
    height: "36px",
  },
  userAvatarSelected: {
    border: "6px solid #fff",
    width: "46px !important",
    height: "46px !important",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

function UserAutoComplete({
  withRole,
  includeMe,
  excludeRoles,
  onUsersChange,
  listItemColorFn,
  disableNonTemp,
  selectedSuggestions,
  maxSelection,
  searchCallback,
  skills,
  settings,
  userAutocomplete,
  additionalFilter,
  user,
  autocompleteWidth,
  predefinedText,
  withoutRole,
  withoutSelected,
  predefinedUsersList,
}) {
  const classes = useStyles();
  const [maxSelected] = useState(maxSelection ? maxSelection : null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [skillsEnabled, setSkillsEnabled] = useState(false);

  const [skillFilter, setSkillFilter] = useState([]);
  const { users } = userAutocomplete;

  const searchByUser = useCallback(async () => {
    let filteredUsers = predefinedUsersList
      ? [...predefinedUsersList]
      : [...users];

    if (withRole) {
      filteredUsers = filteredUsers.filter(
        (checkedUser) => checkedUser.role === withRole
      );
    }

    if (!includeMe) {
      filteredUsers = filteredUsers.filter(
        (checkedUser) => checkedUser.value !== user.profile.id
      );
    }

    if (excludeRoles) {
      filteredUsers = filteredUsers.filter(
        (checkedUser) => excludeRoles.indexOf(checkedUser.role) === -1
      );
    }

    setUsersList(filteredUsers);
  }, [
    excludeRoles,
    includeMe,
    withRole,
    user.profile.id,
    users,
    predefinedUsersList,
  ]);

  const handleSkillFilter = useCallback(
    (skillId) => {
      const tmp = [...skillFilter];
      const idx = tmp.indexOf(skillId);
      if (idx === -1) {
        tmp.push(skillId);
      } else {
        tmp.splice(idx, 1);
      }
      setSkillFilter(tmp);
    },
    [skillFilter, setSkillFilter]
  );

  const isInSkillFilter = useCallback(
    (id) => {
      return skillFilter.indexOf(id) !== -1;
    },
    [skillFilter]
  );

  const asyncCheckSkillsEnabled = useCallback(async () => {
    const isSkillToggleEnabled = await ToggleService._isToggleActive(
      "skills",
      settings.toggles
    );
    setSkillsEnabled(isSkillToggleEnabled);
  }, [settings.toggles]);

  const checkSelectedUsers = useCallback(() => {
    if (selectedSuggestions) {
      setSelectedUsers(selectedSuggestions);
    }
  }, [selectedSuggestions]);

  useEffect(() => {
    checkSelectedUsers();
  }, [checkSelectedUsers]);

  useEffect(() => {
    searchByUser();
  }, [searchByUser]);

  useEffect(() => {
    asyncCheckSkillsEnabled();
  }, [asyncCheckSkillsEnabled]);

  const hasSkill = (user) => {
    const skillIds = user.skills.map((skill) => skill._id);
    const intersection = intersect(skillIds, skillFilter);
    return intersection.length > 0;
  };

  const sortMatches = (prevUser, nextUser) => {
    const skillIdsPrevUser = prevUser.skills.map((skill) => skill._id);
    const skillIdsNextUser = nextUser.skills.map((skill) => skill._id);
    const intersectionPrevUser = intersect(skillIdsPrevUser, skillFilter);
    const intersectionNextUser = intersect(skillIdsNextUser, skillFilter);
    if (intersectionPrevUser.length > intersectionNextUser.length) return -1;
    if (intersectionPrevUser.length < intersectionNextUser.length) return 1;
    return 0;
  };

  const filterUsers = () => {
    const arrID = selectedUsers
      ? [...selectedUsers].map((user) => user.value)
      : [];
    let filtered = usersList
      .filter((user) => !arrID.includes(user.value))
      .filter((user) =>
        skillsEnabled && skillFilter.length > 0 ? hasSkill(user) : true
      );
    if (
      skillsEnabled &&
      !(userAutocomplete.tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY)
    ) {
      filtered = filtered.sort(sortMatches);
    }
    if (additionalFilter) {
      let additionallyFiltered = filtered.filter(additionalFilter);
      return additionallyFiltered;
    }
    return filtered;
  };

  const changeSelectedUsers = (option, reason) => {
    if (reason === "select-option") {
      const newSelectedUsers = [...selectedUsers];
      if (listItemColorFn) {
        option.isTempAssigned = true;
      }
      newSelectedUsers.push(option);

      if (onUsersChange) {
        onUsersChange(newSelectedUsers);
      }
      setSelectedUsers(newSelectedUsers);
    }
  };

  const getOptionLabel = (option) => {
    return option.label;
  };

  const excludeUserFromSelected = ({ value }) => {
    const filtered = selectedUsers.filter(
      (item) => typeof item.value !== "undefined" && item.value !== value
    );
    if (onUsersChange) {
      onUsersChange(filtered);
    }
    setSelectedUsers(filtered);
  };

  const renderOption = (option) => {
    return (
      <React.Fragment>
        <Checkbox
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<CheckCircleOutlineOutlinedIcon />}
          color="primary"
          style={{ marginRight: 8 }}
          checked={selectedUsers.includes(option)}
        />
        <Avatar className={classes.userAvatar}>
          {option.avatar ? (
            <img
              src={getFileTarget(option.avatar, "100x100")}
              alt="avatar"
              className={option.inTraining ? "circle trainee" : "circle"}
            />
          ) : (
            <i
              className={
                option.inTraining
                  ? "circle mdi mdi-account trainee"
                  : "circle mdi mdi-account"
              }
            />
          )}
        </Avatar>
        <ListItemText
          className={classes.labelSpan}
          primary={option.label}
          secondary={option.subLabel}
        />
        {!isMobileDevice() && !withoutRole && (
          <ListItemText
            className={
              !skillsEnabled ? classes.roleSpan : classes.roleSpanSecondary
            }
            primary={ROLE_LABEL[option.role]}
          />
        )}
        {skillsEnabled &&
          !(userAutocomplete.tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY) && (
            <div className={classes.roleSpan}>
              <SkillToolip skills={option.skills} />
            </div>
          )}
        {userAutocomplete.tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY &&
          userAutocomplete.withAvailabilities && (
            <div className={classes.roleSpan}>
              <HtmlTooltip title={renderAvailabilities(option)}>
                <QueryBuilderIcon />
              </HtmlTooltip>
            </div>
          )}
      </React.Fragment>
    );
  };

  const renderAvailabilities = (user) => {
    const id = user.value;
    const query = userAutocomplete.availabilities.filter(
      (schedule) => schedule._id === id
    );
    const dates = query.length > 0 ? query[0].displayDates : [];
    return (
      <div>
        <ul style={{ listStyleType: "none" }}>
          {dates &&
            dates.length > 0 &&
            dates.map((date, index) => {
              return (
                <li key={index}>
                  <span className="date">
                    {moment(date.date).format("DD/MM/YYYY")}
                  </span>
                  <span className="time">
                    &nbsp; {moment(date.from).format("HH:mm")} -{" "}
                    {moment(date.to).format("HH:mm")}
                  </span>
                </li>
              );
            })}
          {dates && dates.length === 0 && (
            <li>{i18n.t("default:_NOT_AVAILABLE")}</li>
          )}
        </ul>
      </div>
    );
  };

  const options = filterUsers();
  return (
    <React.Fragment>
      {userAutocomplete.withAvailabilities &&
        userAutocomplete.tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY &&
        userAutocomplete.jobDate && (
          <Typography>
            {i18n.t("default:_JOB_TIME")}:{" "}
            {DateService.getFormattedDate(userAutocomplete.jobDate)}
          </Typography>
        )}
      {skillsEnabled &&
        !(userAutocomplete.tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY) &&
        skills &&
        skills.length > 0 && (
          <div>
            {skills.map((skill) => (
              <SkillChip
                backgroundColor={
                  isInSkillFilter(skill._id)
                    ? SKILL_COLORS.GREEN
                    : SKILL_COLORS.WHITE
                }
                withAvatar={true}
                skillColor={SKILL_COLORS.GREEN}
                withMargin={true}
                key={skill._id}
                name={skill.name}
                skillAvatar={skill.avatar}
                handleClick={() => handleSkillFilter(skill._id)}
              />
            ))}
          </div>
        )}
      {(maxSelected === null ||
        (selectedUsers && maxSelected > selectedUsers.length)) &&
        !disableNonTemp && (
          <AutoComplete
            list={options}
            selected={selectedUsers}
            onChange={(option, reason) => changeSelectedUsers(option, reason)}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            noOptionsDisplay="none"
            text={
              predefinedText
                ? predefinedText
                : i18n.t("default:_TYPE_TO_SEARCH_USER")
            }
            autocompleteWidth={autocompleteWidth}
            searchCallback={searchCallback}
          />
        )}
      {selectedUsers && selectedUsers.length > 0 && !withoutSelected ? (
        <List className={classes.root}>
          {selectedUsers
            .reduce((unique, current) => {
              if (
                !unique.some(
                  (obj) =>
                    obj.value === current.value && obj.value === current.value
                )
              ) {
                unique.push(current);
              }
              return unique;
            }, [])
            .map((user, index) => {
              const userLabel = user.label
                ? user.label
                : user.name.first + " " + user.name.last;
              return (
                <ListItem
                  className={classes.listItem}
                  key={index}
                  button
                  style={
                    listItemColorFn
                      ? {
                          background: listItemColorFn(user),
                        }
                      : {}
                  }
                >
                  <Checkbox
                    icon={<RadioButtonUncheckedOutlinedIcon />}
                    checkedIcon={
                      <CheckCircleIcon className={classes.checkedUserIcon} />
                    }
                    color="primary"
                    style={{ marginRight: 8 }}
                    checked={selectedUsers.includes(user)}
                    onChange={() =>
                      !disableNonTemp ? excludeUserFromSelected(user) : null
                    }
                  />
                  <ListItemAvatar>
                    <Avatar
                      className={`${classes.userAvatar} ${classes.userAvatarSelected}`}
                    >
                      {user.avatar ? (
                        <img
                          src={getFileTarget(user.avatar, "100x100")}
                          alt="avatar"
                          className={
                            user.inTraining ? "circle trainee" : "circle"
                          }
                        />
                      ) : (
                        <i
                          className={
                            user.inTraining
                              ? "circle mdi mdi-account trainee"
                              : "circle mdi mdi-account"
                          }
                        />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.labelSpanSelected}
                    primary={userLabel}
                  />
                  {!isMobileDevice() && (
                    <ListItemText
                      className={classes.roleSpanSelected}
                      primary={user.role}
                    />
                  )}
                </ListItem>
              );
            })}
        </List>
      ) : null}
    </React.Fragment>
  );
}

export default connectWithStore(UserAutoComplete, [
  "settings",
  "userAutocomplete",
  "user",
]);
