import React, { useCallback, useEffect, useRef } from "react";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { MOVE_TOOL, LINE_TOOL } from "../constants";
import store from "../../../../redux/store";
import { BlueprintEditor } from "../../../../redux/actions";
import {
  addNewElementToJob,
  convertMeterToPixels,
  getRelativePointerPosition,
  canUserEditElement,
  canProceedEditingElement,
} from "../utils";
import { v1 as uuid } from "uuid";
import i18n from "../../../../i18n";
import { isMobileDevice } from "../../../../services/mobile";

const ELEMENT_NAME = "Line";

function LineTool({ blueprintEditor, dispatch, job, question }) {
  const { app } = blueprintEditor;
  let transformer = useRef();

  const comparePosition = useCallback(
    (pos) => {
      let nearestLine;
      const children =
        app && app.children && app.children[0] && app.children[0].children
          ? Array.from(app.children[0].children)
          : [];
      const lines = children.filter(
        (child) => child.attrs.name === ELEMENT_NAME
      );
      //compare position with lines positions
      lines.forEach((line) => {
        const x1 = line.attrs.x
          ? line.attrs.points[0] + line.attrs.x
          : line.attrs.points[0];
        const x2 = line.attrs.x
          ? line.attrs.points[2] + line.attrs.x
          : line.attrs.points[2];
        const y1 = line.attrs.y
          ? line.attrs.points[1] + line.attrs.y
          : line.attrs.points[1];
        if (pos.x >= x1 && pos.x <= x2 && pos.y > y1 - 5 && pos.y < y1 + 5) {
          nearestLine = line;
        }
      });
      //return line if is near
      return nearestLine;
    },
    [app]
  );

  useEffect(() => {
    if (app) {
      app.on("mousedown touchstart", (e) => {
        const state = store.getState();
        const { blueprintEditor } = state;
        const { tool } = blueprintEditor;
        if (tool && tool.type !== LINE_TOOL) {
          return;
        }
        if (e.target !== app && e.target.id() !== "blueprint") {
          return;
        }
      });

      app.on("click tap", function (e) {
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool, layer, mode } = blueprintEditor;
        const { profile } = user;

        if (canProceedEditingElement(mode, tool)) {
          dispatch(
            BlueprintEditor.updateHistory(tool.element && tool.element.id)
          );
        } else {
          return;
        }
        if (
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          transformer.current.nodes([]);
          layer.batchDraw();
          dispatch(BlueprintEditor.setSelectedElement(null));
          dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
          dispatch(BlueprintEditor.setDimensionsPanel(false));
          //return;
        }

        if (tool && tool.type !== MOVE_TOOL && tool.type !== LINE_TOOL) {
          return;
        }
        // if click on empty area - remove all selections
        if (
          (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name.includes("Layer"))) &&
          tool.type === LINE_TOOL
        ) {
          const pos = getRelativePointerPosition(app);
          const id = uuid();
          const canvasData = {
            points: [
              pos.x,
              pos.y,
              pos.x + convertMeterToPixels(tool.width) / 2,
              pos.y,
              pos.x + convertMeterToPixels(tool.width) / 2,
              pos.y,
            ],
            stroke: tool.color,
            strokeWidth: tool.height * 2 || 2,
            name: ELEMENT_NAME,
            draggable: true,
            id,
          };
          const line = new Konva.Line(canvasData);
          layer.add(line);
          dispatch(BlueprintEditor.setSelectedElement(line));
          transformer.current = new Konva.Transformer({
            nodes: [line],
            padding: isMobileDevice() ? 15 : 1,
          });
          layer.add(transformer.current);
          layer.batchDraw();
          const updatedJobs = addNewElementToJob(
            canvasData,
            ELEMENT_NAME,
            tool,
            id,
            blueprintEditor
          );
          dispatch(BlueprintEditor.setJobs(updatedJobs));
          dispatch(
            BlueprintEditor.addElement({
              id,
              elementId: tool.element.id,
              user: profile,
              job,
            })
          );
          line.on("dragstart", () => {
            if (
              blueprintEditor.mode !== "EDIT" ||
              !(
                transformer &&
                transformer.current &&
                transformer.current._nodes &&
                transformer.current._nodes.length > 0
              )
            ) {
              line.stopDrag();
            } else {
              dispatch(BlueprintEditor.setSelectedElement(line));
            }
          });
          line.on("transformend", () => {
            dispatch(
              BlueprintEditor.updateElement({
                id: line.getAttr("id"),
                elementId: tool.element.id,
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
          });
          line.on("dragend", () => {
            dispatch(
              BlueprintEditor.updateElement({
                id: line.getAttr("id"),
                elementId: tool.element.id,
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            //dispatch(BlueprintEditor.setSelectedElement(null));
          });
          dispatch(
            BlueprintEditor.changeTool({
              type: MOVE_TOOL,
              color: "#000",
              name: i18n.t("default:_MOVE_TOOL"),
            })
          );
          return;
        }
        if (
          e.target &&
          e.target.attrs &&
          e.target.attrs.name ===
            ELEMENT_NAME /* &&
          transformer.current */
        ) {
          const selectedElementArr = blueprintEditor.elements.filter(
            (el) => el.id === e.target.attrs.id
          );
          const elementJob =
            selectedElementArr.length > 0 ? selectedElementArr[0].job : null;
          if (!canUserEditElement(elementJob, job, question)) {
            return;
          }
          dispatch(BlueprintEditor.selectElement(e.target));
          if (!transformer.current) {
            transformer.current = new Konva.Transformer({
              nodes: [e.target],
              padding: isMobileDevice() ? 15 : 1,
            });
            layer.add(transformer.current);
          } else {
            transformer.current.nodes([e.target]);
          }
          layer.batchDraw();
          return;
        } else {
          const pos = getRelativePointerPosition(app);
          const nearestLine = comparePosition(pos);
          if (nearestLine) {
            if (!isMobileDevice()) {
              dispatch(BlueprintEditor.setSelectedElement(nearestLine));
              dispatch(BlueprintEditor.openLayerPanel());
              dispatch(
                BlueprintEditor.setElementsInfoPanelOpen(nearestLine.attrs.id)
              );
              dispatch(BlueprintEditor.setDimensionsPanel(false));
            }
            if (!transformer.current) {
              transformer.current = new Konva.Transformer({
                nodes: [nearestLine],
                padding: isMobileDevice() ? 15 : 1,
              });
              layer.add(transformer.current);
            } else {
              transformer.current.nodes([nearestLine]);
            }
            //transformer.current.nodes([nearestLine]);
            layer.batchDraw();
            return;
          }
        }
        if (
          transformer &&
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          dispatch(BlueprintEditor.deselectElement());
          transformer.current.nodes([]);
          layer.batchDraw();
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [app, dispatch, comparePosition]);
  return <div />;
}

export default connectWithStore(LineTool, ["blueprintEditor"]);
