import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import i18n from "../../i18n";
import { ROLE } from "../../services/roles";
import StepWizard from "react-step-wizard";
import SelectTemplate from "./select_template";
import FinalStep from "./final_step";

import "../create_wizard/wizard.scss";

import { STEP_SELECT_TEMPLATE } from "./steps";
import { connectWithStore } from "../../services/redux";
import { Wizard } from "../../redux/actions";
import { withRouter } from "react-router-dom";

const NewJobType = connectWithStore(
  withRouter(function ({
    userRole,
    goToStep,
    dispatch,
    isActive,
    jobOnly,
    history,
    toggleModal,
  }) {
    const onCreateFromBlank = () => {
      toggleModal("jobTypeModalOpen");
      history.push("/job_type/new");
    };

    useEffect(() => {
      if (isActive)
        dispatch(Wizard.setTitle(i18n.t("default:_CREATE_JOB_TYPE")));
    }, [dispatch, isActive]);
    return (
      <div
        className="row fullHeight"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "nowrap",
        }}
      >
        <div className="WhichEntity">
          {userRole !== ROLE.INSPECTOR && !jobOnly && (
            <div className="wizardCol">
              <div
                onClick={() => onCreateFromBlank()}
                className="option-box-grid"
              >
                <div>
                  <i className="mdi h1 d-block mdi-book" />
                  <span className="h5">
                    {i18n.t("default:_CREATE_FROM_BLANK")}
                  </span>
                </div>
              </div>
            </div>
          )}
          {userRole !== ROLE.INSPECTOR && !jobOnly && (
            <div className="wizardCol">
              <div
                onClick={() => goToStep(STEP_SELECT_TEMPLATE)}
                className="option-box-grid"
              >
                <div>
                  <i className="mdi h1  d-block mdi-book-multiple" />
                  <span className="h5">
                    {i18n.t("default:_CREATE_FROM_TEMPLATE")}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }),
  []
);

function CreateNewJobType(props) {
  const { modalIsOpen, dismiss, history, settings, toggleModal } = props;
  const [jobTypeId, setJobTypeId] = useState(null);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={() => {
        return null;
      }}
      style={{
        content: {
          padding: 10,
        },
      }}
      contentLabel={i18n.t("default:_NEW_TYPE")}
    >
      <div className="modal-mobile-header pointer">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            onClick={() => {
              history.go();
              dismiss();
            }}
            style={{
              background: "rgba(204, 204, 204, 0.5)",
              position: "absolute",
              fontSize: "35px",
              width: "40px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              left: "30px",
              top: "20px",
              zIndex: "9999",
              opacity: "1",
            }}
            className="close-modal-mobile"
          >
            <i
              className="mdi mdi-chevron-left"
              style={{ color: "#fff !important" }}
            />
          </div>
          {props.wizard.title && (
            <div style={{ paddingTop: 70, textAlign: "center" }}>
              <span
                className="wizard-heading"
                style={{
                  color:
                    settings.branding &&
                    settings.branding.primary &&
                    settings.branding.primary.color
                      ? settings.branding.primary.color.hex
                      : "#0083ca",
                }}
              >
                {props.wizard.title}
              </span>
            </div>
          )}
        </div>
      </div>
      <StepWizard className="CreateNewJobType-Wizard" isLazyMount={true}>
        <NewJobType toggleModal={toggleModal} />
        <SelectTemplate setJobTypeId={setJobTypeId} />
        <FinalStep jobTypeId={jobTypeId} />
      </StepWizard>
    </Modal>
  );
}

export default connectWithStore(withRouter(CreateNewJobType), [
  "wizard",
  "settings",
  "user",
]);
