import React, { Component, Suspense } from "react";
import { DateType } from "../../../services/question";
import "./../../../../node_modules/flatpickr/dist/themes/material_blue.css";
import i18n from "../../../i18n";
import moment from "moment";
import DateService from "../../../services/date";
import { connectWithStore } from "../../../services/redux";
import { ROLE } from "../../../services/roles";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Confirm from "../../confirm/confirm";
import MuiDateTimePicker from "../../date_picker/date_time_picker";
import { DatePicker, TimePicker } from "@material-ui/pickers";

const styles = {
  chip: {
    marginBottom: "10px",
    marginRight: "10px",
    background: "white",
    "&:focus": {
      background: "white",
    },
  },
};

class DateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      from: null,
      to: null,
      allDay: false,
      selectedDay: null,
      filterDate: null,
      punchStart: null,
      punchEnd: null,
      punchDuration: 0,
      punchClockOn: false,
      deleteConfirm: false,
    };
    this.dateOptions = {};
  }

  componentDidMount() {
    let cmpData = this.props.answers[this.props.question.ID];

    if (cmpData && cmpData.value) {
      this.setState(Object.assign(this.state, cmpData.value));
    }

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const { punchStart, punchEnd, punchClockOn } = this.state;
    const today = moment();
    const secondsTillEnd = moment(punchEnd).diff(today, "seconds");

    if (punchClockOn) {
      if (punchEnd && secondsTillEnd < 0) {
        return;
      }
      this.setState({
        punchDuration: today.diff(moment(punchStart), "seconds"),
      });
    }
  }

  handleDateTimePickerChange = (value, type) => {
    if (type) {
      this.handleChangeRange(value, type);
    } else {
      this.handleChange(value);
    }
  };

  shouldBlockInspector() {
    return (
      this.props.question.settings.isBlockInspector &&
      this.props.user.profile.role === ROLE.INSPECTOR
    );
  }

  renderDateTimePicker = (defaultDate, type) => {
    const { question } = this.props;
    const clockDisabled = type && this.shouldBlockInspector();
    switch (question.settings.type) {
      case DateType.DATE:
        const momentDay = DateService.formattedDayToMoment(defaultDate);
        return (
          <DatePicker
            format={DateService.SYSTEM_DAY_MONTH_YEAR_FORMAT}
            value={type ? defaultDate : momentDay}
            onChange={(value) => this.handleDateTimePickerChange(value, type)}
            animateYearScrolling
            clearable={true}
            inputVariant={"outlined"}
            disabled={clockDisabled}
          />
        );
      case DateType.DATE_TIME:
        if (question.settings && question.settings.isRange) {
          const momentTime = DateService.formattedTimeToMoment(defaultDate);
          return (
            <TimePicker
              clearable={true}
              ampm={false}
              value={type ? defaultDate : momentTime}
              onChange={(value) => this.handleDateTimePickerChange(value, type)}
              inputVariant={"outlined"}
              disabled={clockDisabled}
            />
          );
        }
        const momentDate = DateService.formattedDateToMoment(defaultDate);
        return (
          <MuiDateTimePicker
            defaultValue={type ? defaultDate : momentDate}
            onClose={(value) => this.handleDateTimePickerChange(value, type)}
            clearable={true}
            disabled={clockDisabled}
          />
        );
      case DateType.DATE_PLUS_TIME:
        const momentDatePlusTime = DateService.formattedDateToMoment(
          defaultDate
        );
        return (
          <MuiDateTimePicker
            defaultValue={type ? defaultDate : momentDatePlusTime}
            onClose={(value) => this.handleDateTimePickerChange(value, type)}
            clearable={true}
            disabled={clockDisabled}
          />
        );
      case DateType.TIME:
        const momentTime = DateService.formattedTimeToMoment(defaultDate);
        return (
          <TimePicker
            clearable={true}
            ampm={false}
            value={type ? defaultDate : momentTime}
            onChange={(value) => this.handleDateTimePickerChange(value, type)}
            inputVariant={"outlined"}
            disabled={clockDisabled}
          />
        );
      default:
        return (
          <MuiDateTimePicker
            defaultValue={defaultDate ? defaultDate : null}
            onClose={(value) => this.handleDateTimePickerChange(value, type)}
            clearable={true}
            disabled={clockDisabled}
          />
        );
    }
  };

  renderNoRangeDateTimePicker = (answers, valueFrom = null) => {
    let defaultDate =
      this.props.answers[this.props.question.ID] &&
      this.props.answers[this.props.question.ID]["value"];
    //make component rerender if there is change in answers value
    if (
      answers &&
      answers[this.props.question.ID] &&
      answers[this.props.question.ID]["value"]
    ) {
      defaultDate =
        answers[this.props.question.ID] &&
        answers[this.props.question.ID]["value"];
    }

    if (valueFrom && defaultDate && defaultDate[valueFrom]) {
      defaultDate = defaultDate[valueFrom];
    }
    return this.renderDateTimePicker(defaultDate);
  };

  getTimeStart(day) {
    if (this.props.settings && this.props.settings.allDay) {
      const targetDay = this.props.settings.allDay.filter(
        (s) => s._id.toString() === day.value.toString()
      );

      if (targetDay && targetDay.length === 1) {
        return targetDay[0].dateTimeStart;
      }
    }

    return "00:00";
  }

  getTimeEnd(day) {
    if (this.props.settings && this.props.settings.allDay) {
      const targetDay = this.props.settings.allDay.filter(
        (s) => s._id.toString() === day.value.toString()
      );

      if (targetDay && targetDay.length === 1) {
        return targetDay[0].dateTimeEnd;
      }
    }

    return "23:59";
  }

  triggerAllDay(day) {
    const oldFrom = moment(this.state.from || this.state.date);
    const oldTo = moment(this.state.to || this.state.date);
    const startHour = this.getTimeStart(day);
    const endHour = this.getTimeEnd(day);

    const startParts = startHour.split(":");
    oldFrom.hours(startParts[0]);
    oldFrom.minutes(startParts[1]);
    const endParts = endHour.split(":");
    oldTo.hours(endParts[0]);
    oldTo.minutes(endParts[1]);

    this.setState(
      {
        allDay: this.state.selectedDay !== day.value,
        selectedDay: this.state.selectedDay === day.value ? null : day.value,
        from: oldFrom.toDate(),
        to: oldTo.toDate(),
      },
      () => {
        if (this.state.date && this.state.from && this.state.to) {
          this.props.handleChange(this.state, "full date");
        } else if (
          this.state.date === null &&
          this.state.from === null &&
          this.state.to === null
        ) {
          this.props.handleChange(this.state, "empty date");
        }
      }
    );
  }

  handleChange(date) {
    let value = null;
    switch (this.props.question.settings.type) {
      case DateType.DATE:
        if (date) {
          value = DateService.getFormattedDay(date);
        }
        break;
      case DateType.TIME:
        if (date) {
          value = DateService.getFormattedTime(date);
        }
        break;
      default:
        if (date) {
          value = DateService.getFormattedDate(date);
        }
    }
    if (this.props.fromMultiStepFilter) {
      if (value === this.state.filterDate) {
        return;
      } else {
        this.setState({ filterDate: value });
      }
    }
    this.props.handleChange(value, "date");
  }

  checkForFromData() {
    if (!this.state.from) {
      this.setState({ from: new Date() });
    }
  }

  checkForToData() {
    if (!this.state.to) {
      this.setState({ to: new Date() });
    }
  }

  handleChangeRange(value, type) {
    let newState = {
      [type]: value ? moment(value /* [0] */).toDate() : null,
    };
    if (newState.from && !this.state.date) {
      newState.date = newState.from;
    }
    if (newState.to && this.state.from) {
      if (
        typeof this.state.from !== "string" &&
        this.state.from.getHours() > newState.to.getHours()
      ) {
        newState.to.setHours(this.state.from.getHours() + 1);
      }
    }
    this.setState(newState, () => {
      if (this.state.from && this.state.to) {
        this.props.handleChange(this.state, "full date");
      } else if (this.state.from === null && this.state.to === null) {
        this.props.handleChange(this.state, "full date");
      } else if (this.state.from === null || this.state.to === null) {
        this.props.handleChange(this.state, "empty date");
      }
    });
  }

  toggleTimer() {
    const { punchClockOn } = this.state;
    if (punchClockOn) {
      this.setState(
        {
          punchClockOn: false,
          punchEnd: moment().toDate(),
          to: moment().toDate(),
        },
        () => {
          this.props.handleChange(this.state, "full date");
        }
      );
    } else {
      this.setState(
        {
          punchClockOn: true,
          punchStart: moment().toDate(),
          from: moment().toDate(),
          punchEnd: null,
          to: null,
          date: moment().toDate(),
        },
        () => {
          this.props.handleChange(this.state, "full date");
        }
      );
    }
  }

  resetTimer() {
    this.setState(
      {
        punchClockOn: false,
        punchDuration: 0,
        punchStart: null,
        punchEnd: null,
        from: null,
        to: null,
        date: null,
        deleteConfirm: false,
      },
      () => {
        this.props.handleChange(this.state, "full date");
      }
    );
  }

  handleChangePunch(value, type, reportType) {
    this.setState(
      {
        [type]: value ? moment(value).toDate() : null,
        [reportType]: value ? moment(value).toDate() : null,
      },
      () => {
        this.props.handleChange(this.state, "full date");
      }
    );
  }

  confirmDelete() {
    this.setState({
      deleteConfirm: true,
    });
  }

  closeConfirm() {
    this.setState({
      deleteConfirm: false,
    });
  }

  render() {
    const { classes, primary, question, jobQuestions } = this.props;
    const { answers } = jobQuestions;
    const {
      punchStart,
      punchEnd,
      punchClockOn,
      punchDuration,
      deleteConfirm,
      date,
      from,
      to,
    } = this.state;

    return (
      <div className="question-content">
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_CONFIRM_RESET_TIMER")}
          show={deleteConfirm}
          onConfirm={() => this.resetTimer()}
          onClose={() => this.closeConfirm()}
        />
        <div className="form-row">
          <div className="form-group col-md-12">
            <Suspense fallback={"loading calendar"}>
              {!question.settings.isRange && (
                <>{this.renderNoRangeDateTimePicker(answers)}</>
              )}
              {question.settings.isRange && !question.settings.isPunchClock && (
                <>
                  <div className="RangeDatePicker">
                    {question.settings.type === DateType.DATE_TIME && (
                      <div className="row">
                        <div className=" text-center">
                          <p className="text-center">
                            {i18n.t("default:_DATE")}:
                          </p>
                          <DatePicker
                            disabled={this.shouldBlockInspector()}
                            format={DateService.SYSTEM_DAY_MONTH_YEAR_FORMAT}
                            value={date}
                            onChange={(value) =>
                              this.handleChangeRange(value, "date")
                            }
                            animateYearScrolling
                            clearable={true}
                            inputVariant={"outlined"}
                          />
                        </div>
                      </div>
                    )}
                    {!this.state.allDay && (
                      <>
                        <div className="row">
                          <div
                            className="text-center"
                            onClick={() => this.checkForFromData()}
                          >
                            {" "}
                            <p className="text-center">
                              {i18n.t("default:_FROM")}:
                            </p>
                            {this.renderDateTimePicker(from, "from")}
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="text-center"
                            onClick={() => this.checkForToData()}
                          >
                            <p className="text-center">
                              {i18n.t("default:_TO")}:
                            </p>
                            {this.renderDateTimePicker(to, "to")}
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.allDay && this.state.selectedDay && (
                      <div className="row pt-5">
                        <div className="col col-12">
                          <p className="text-center">
                            {
                              this.props.question.settings.allDays.filter(
                                (d) => d.value === this.state.selectedDay
                              )[0].label
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {question &&
                    question.settings &&
                    question.settings.allDays &&
                    question.settings.type !== DateType.DATE && (
                      <div className="row row-all-day">
                        <div className="all-day-wrapper">
                          {this.props.question.settings.allDays.map(
                            (day, index) => (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  disabled={!this.state.date}
                                  className={classes.chip}
                                  style={{
                                    background:
                                      this.state.selectedDay &&
                                      primary &&
                                      this.state.selectedDay === day.value &&
                                      primary,
                                    border: `1px solid ${primary}`,
                                    color:
                                      this.state.selectedDay &&
                                      primary &&
                                      this.state.selectedDay === day.value &&
                                      "white",
                                  }}
                                  label={day.label}
                                  onClick={() => this.triggerAllDay(day)}
                                />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    )}
                </>
              )}
              {question.settings.isRange && question.settings.isPunchClock && (
                <>
                  <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.toggleTimer()}
                    >
                      {punchClockOn ? "Stop" : "Start"}
                    </Button>
                    {(punchStart || punchEnd) && (
                      <Button
                        style={{ marginLeft: "10px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => this.confirmDelete()}
                      >
                        {"Reset"}
                      </Button>
                    )}
                  </Grid>
                  {punchStart && (
                    <>
                      <Grid className="punch-counter">
                        {new Date(punchDuration * 1000)
                          .toISOString()
                          .substr(11, 8)}
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className="punch-input"
                      >
                        <p className="text-center mr-1">
                          {i18n.t("default:_START")}:
                        </p>
                        <MuiDateTimePicker
                          defaultValue={punchStart}
                          onClose={(value) =>
                            this.handleChangePunch(value, "punchStart", "from")
                          }
                          clearable={true}
                        />
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className="punch-input"
                      >
                        <p className="text-center mr-1">
                          {i18n.t("default:_FINISH")}:
                        </p>
                        <MuiDateTimePicker
                          defaultValue={punchEnd}
                          onClose={(value) =>
                            this.handleChangePunch(value, "punchEnd", "to")
                          }
                          clearable={true}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Suspense>
          </div>
        </div>
      </div>
    );
  }
}

export default connectWithStore(withStyles(styles)(DateForm), [
  "user",
  "jobQuestions",
]);
