export const getDisplayName = (user) => {
  if (user.name && user.name.first && user.name.last) {
    return `${user.name.first} ${user.name.last}`;
  }
  if (user.name && user.name.first && !user.name.last) {
    return `${user.name.first}`;
  }
  if (user.name && !user.name.first && !user.name.last) {
    return `${user.email}`;
  }
  if (!user.name) {
    return `${user.email}`;
  }
};

export const mapUser = (user) => {
  const hasName = user.name && user.name.first && user.name.last;
  if (hasName) {
    return {
      value: user._id,
      label: user.name.first + " " + user.name.last,
      subLabel: user.email,
      avatar: user.imgUrl,
      role: user.role,
      inTraining: user.inTraining,
      ...user,
    };
  } else {
    return {
      value: user._id,
      label: user.email,
      avatar: user.imgUrl,
      role: user.role,
      inTraining: user.inTraining,
      ...user,
    };
  }
};
