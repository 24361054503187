import React, { Component } from "react";
import MediaQuery from "react-responsive";
import GridList from "../../components/grid_list/grid_list";
//import GridTable from "../../components/grid_table/grid_table";
import "./grid_view.scss";
import { NotificationPanel, TopNavMenu } from "../../redux/actions";
import store from "../../redux/store";
import { Helmet } from "react-helmet";
import i18n from "../../i18n";
import {
  updateClientState,
  getClientState,
} from "../../services/api/client_state";
import { getWidget } from "../../services/api/widgets";
import PaginationInfo from "./pagination_info";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class GridView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      totalPages: -1,
      total: 0,
      currentPage: 0,
      loading: true,
      search: null,
      pageSize: 20,
      activeFilters: false,
      rightPanelOpen: false,
      selectedRows: [],
      allowOpenRightPanel: true,
      hideFilters: true,
      showMenu: {},
      hasDefaultFilters: false,
      withFilters: props.withFilters ? props.withFilters : false,
      selectionMode: false,
    };
    this.tableRef = null;
  }

  componentDidCatch() {
    this.setState({
      rows: [],
    });
  }

  showThreeDotMenu(identifier) {
    this.setState({
      showMenu: {
        ...this.state.showMenu,
        [identifier]: true,
      },
    });
  }

  hideThreeDotMenu(identifier) {
    this.setState({
      showMenu: {
        ...this.state.showMenu,
        [identifier]: false,
      },
    });
  }

  getThreeDotMenuClass(identifier) {
    return this.state.showMenu[identifier] ? " show" : "";
  }

  toggleThreeDotMenu(identifier) {
    if (this.state.showMenu[identifier]) {
      this.hideThreeDotMenu(identifier);
    } else {
      this.showThreeDotMenu(identifier);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeFilters !== this.state.activeFilters) {
      if (this.state.activeFilters) {
        this.props.dispatch(TopNavMenu.setActiveSearch(true));
      } else {
        this.props.dispatch(TopNavMenu.setActiveSearch(false));
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TopNavMenu.setActiveSearch(false));
  }

  async componentDidMount() {
    //check if table is opened from dashboard widget
    if (
      this.props.location &&
      this.props.location.search &&
      this.props.location.search.includes("ref") &&
      this.props.location.search.includes("widgetId")
    ) {
      //get widget filters here and pass it to rebuildFiltersFromDatabase
      const id = this.props.location.search.split("&")[1].split("=")[1];
      const params = {
        id,
      };
      const response = await getWidget(params);
      if (response.success) {
        const widgetFilters = response.data.filters
          ? response.data.filters
          : [];
        return this.setState(
          {
            hasDefaultFilters: true,
          },
          async () =>
            await this.rebuildFiltersFromDatabase(widgetFilters, "widget")
        );
      }
    }
    if (
      !this.props.noDefaultFilter ||
      (this.props.match && !this.props.match.params.id)
    ) {
      //check for ref here
      if (this.props.withPresetFilters) {
        this.setState(
          {
            hasDefaultFilters: true,
          },
          async () =>
            await this.rebuildFiltersFromDatabase(this.props.withPresetFilters)
        );
      }
      //eslint-disable-next-line
      const { state } = await getClientState({
        screen: this.getType(),
      });
      if (
        !this.props.freezeRows &&
        state &&
        Object.keys(state).length > 0 &&
        this.props.match &&
        !this.props.match.params.id
      ) {
        this.setState(
          {
            hasDefaultFilters: true,
          },
          async () => await this.rebuildFiltersFromDatabase(state)
        );
      } else {
        await this.getRows();
      }
    } else {
      this.setState(
        {
          hasDefaultFilters: false,
        },
        () => {
          this.getRows();
        }
      );
    }

    store.subscribe((res) => {
      const state = store.getState();

      if (state.notificationPanel && state.notificationPanel.visible === true) {
        this.setState({
          rightPanelOpen: false,
        });
      }
    });
  }

  async getRows() {
    throw new Error(
      "GridView derived classes should implement async getRows method!"
    );
  }

  columns() {
    throw new Error("GridView derived classes should implement column method!");
  }

  renderRow(props) {
    throw new Error(
      "GridView derived classes should implement renderRow method!"
    );
  }

  filters(props) {
    throw new Error(
      "GridView derived classes should implement filters method!"
    );
  }

  sort() {
    throw new Error("GridView derived classes should implement sort method!");
  }

  section() {}

  renderRightPanel() {
    throw new Error(
      "GridView derived classes should implement renderRightPanel method!"
    );
  }

  deselectAll() {
    this.setState({
      selectedRows: [],
      rightPanelOpen: false,
    });
  }

  onRowClick(data, event, singleSelect) {
    if (event && event.target.closest(".custom-click")) {
      return;
    }
    if (!this.withRightPanel()) {
      return;
    }
    const { selectedRows } = this.state;
    const index = selectedRows.indexOf(data);

    if (singleSelect) {
      if (index === -1) {
        selectedRows.splice(0, 1, data);
      } else {
        selectedRows.splice(index, 1);
      }
    } else {
      if (index === -1) {
        if (
          event.target.classList.contains("checkmark") ||
          event.target.classList.contains("custom-checkbox") ||
          event.target.classList.contains("grid-table-checkbox")
        ) {
          selectedRows.push(data);
        } else {
          selectedRows[0] = data;
        }
      } else {
        selectedRows.splice(index, 1);
      }
    }

    if (selectedRows.length > 0) {
      if (
        this.props.notificationPanel &&
        this.props.notificationPanel.visible
      ) {
        this.props.dispatch(NotificationPanel.hide());
      }
    }
    this.setState({
      selectedRows,
      rightPanelOpen:
        selectedRows.length > 0 && this.state.allowOpenRightPanel
          ? true
          : false,
      selectionMode:
        event.target.classList.contains("checkmark") ||
        event.target.classList.contains("custom-checkbox") ||
        event.target.classList.contains("grid-table-checkbox"),
    });
  }

  resetSelection() {
    this.setState({
      selectedRows: [],
      rightPanelOpen: false,
    });
  }

  changePage(page) {
    if (this.tableRef) {
      window.scrollTo(0, this.tableRef.offsetTop);
    }
    const { totalPages } = this.state;
    let currentPage = page;
    if (currentPage < 0) {
      currentPage = 0;
    }
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    this.setState(
      {
        currentPage,
        loading: true,
      },
      async () => {
        this.resetSelection();
        await this.getRows();
      }
    );
  }

  changeSize(size, page) {
    this.setState(
      {
        pageSize: size,
      },
      () => this.changePage(page)
    );
  }

  search(keywords) {
    this.setState(
      {
        search: keywords,
        loading: true,
        activeFilters: keywords.length > 0,
      },
      async () => {
        this.resetSelection();
        await this.getRows();
      }
    );
  }

  clearFilters() {
    if (this.onClear) {
      this.onClear();
    }
    this.setState(
      {
        activeFilters: false,
        search: null,
        loading: true,
      },
      async () => {
        this.resetSelection();
        await this.getRows();
      }
    );
  }

  toggleFilters(val) {
    this.setState(
      {
        activeFilters: val,
        loading: true,
      },
      async () => {
        this.resetSelection();
        await this.getRows();
      }
    );
  }

  getType() {
    throw new Error(
      "Classes derived from grid view should implement getType() method"
    );
  }

  getFilters() {
    throw new Error(
      "Classes derived from grid view should implement getFilters() method"
    );
  }

  rebuildFiltersFromDatabase(databaseFilters) {
    throw new Error(
      "Classes derived from grid view should implement rebuildFiltersFromDatabase() method"
    );
  }

  selectAll() {
    let newSelectedRows = this.state.rows.map(
      (value) => value[Object.keys(value)[0]]
    );
    if (this.state.selectedRows.length === this.state.rows.length) {
      newSelectedRows = [];
    }
    this.setState({
      selectedRows: newSelectedRows,
      rightPanelOpen: newSelectedRows.length > 0 ? true : false,
    });
  }

  getActiveFiltersPreview() {
    throw new Error(
      "Classes derived from grid view should implement getActiveFiltersPreview() method"
    );
  }

  renderActiveFilters() {
    const list = this.getActiveFiltersPreview();
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          paddingTop: 9,
          marginBottom: 5,
        }}
      >
        {list.map((pill, index) => {
          return (
            <div
              className="searchPill"
              key={index}
              style={pill.customCss ? pill.customCss : {}}
            >
              {pill.renderIcon && (
                <div className="pillIcon">{pill.renderIcon()}</div>
              )}
              <div className="pillLabel">
                <span
                  style={{
                    maxHeight: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    wordBreak: "break-word",
                    ...(pill.spanCss ? pill.spanCss : {}),
                  }}
                >
                  {pill.label}
                </span>
              </div>
              <div className="pillRemove" onClick={() => pill.onRemove()}>
                <i className="mdi mdi-close" />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderActiveFiltersMobile(filterModal) {
    const list = this.getActiveFiltersPreview();
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          paddingTop: 9,
          marginBottom: 22,
          width: "100%",
        }}
      >
        {list.length > 0 && (
          <Accordion style={{ width: "95%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{i18n.t("default:_FILTERS")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {list.map((pill, index) => {
                if (pill.renderWithLabel) {
                  return (
                    <Avatar
                      key={index}
                      style={{
                        backgroundColor:
                          pill.customCss && pill.customCss.background
                            ? pill.customCss.background
                            : "black",
                        color: "white",
                        margin: 5,
                        fontSize: "0.9rem",
                        width: "auto",
                        minWidth: 40,
                        padding: 3,
                      }}
                      onClick={() => filterModal()}
                    >
                      {pill.customDate ? "Custom" : pill.label}
                    </Avatar>
                  );
                }
                return (
                  <Avatar
                    key={index}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      margin: 5,
                    }}
                    onClick={() => filterModal()}
                  >
                    {pill.renderIcon && pill.renderIcon()}
                  </Avatar>
                );
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    );
  }

  async saveFilters() {
    //get filters from temporary filters
    this.setState(
      {
        ...this.props.gridTable.tempFilterSelection,
        hasDefaultFilters: true,
      },
      async () => {
        const filters = this.getFilters();
        await updateClientState({
          screen: this.getType(),
          state: {
            ...filters,
          },
        });
      }
    );
  }

  async deleteSavedFilters() {
    await updateClientState({
      screen: this.getType(),
      state: null,
    });
    this.setState(
      {
        hasDefaultFilters: false,
      },
      () => this.clearFilters()
    );
  }

  withRightPanel() {
    return true;
  }

  withReports() {
    if (this.props.withoutReports) {
      return false;
    } else {
      return true;
    }
  }

  withDownload() {
    return false;
  }

  pageSizeOptions() {
    return [5, 10, 20, 25, 50, 100];
  }

  withSelectAll() {
    return true;
  }

  withSearch() {
    if (this.props.withoutSearch) {
      return false;
    } else {
      return true;
    }
  }

  withSearchSpace() {
    if (this.props.withSearchSpace) {
      return true;
    } else {
      return false;
    }
  }

  withPagination() {
    return true;
  }

  withMobilePagination() {
    return false;
  }

  onFilterClose() {
    this.setState(
      {
        ...this.props.gridTable.tempFilterSelection,
        activeFilters: true,
        loading: true,
      },
      async () => {
        if (this.props.filterChangeCallback) {
          this.props.filterChangeCallback(this.getFilters());
        }
        await this.getRows();
      }
    );
  }

  onFilterOpen() {
    return true;
  }

  noDataText() {
    if (this.props.noDataText) {
      return true;
    } else {
      return false;
    }
  }

  renderGrid() {
    const { rightPanelOpen } = this.state;
    return (
      <div className="GridViewWithRightPanel">
        <Helmet>
          <title>Samurai</title>
          <style>{`.grid-table-actions i{ color: ${
            this.props.settings.branding &&
            this.props.settings.branding.primary &&
            this.props.settings.branding.primary.color
              ? this.props.settings.branding.primary.color.hex
              : "#2e384d"
          }; }`}</style>
        </Helmet>
        <MediaQuery minWidth={768}>
          <div className="row">
            <div className={rightPanelOpen ? "col-md-8" : "col-md-12"}>
              <div className="DataGrid">
                <GridList
                  disableFiltering={this.props.disableFiltering}
                  onFilterClose={() => this.onFilterClose()}
                  onFilterOpen={() => this.onFilterOpen()}
                  showFiltersOnly={
                    this.props.freezeRows ? this.props.freezeRows : false
                  }
                  withPagination={this.withPagination()}
                  withSearch={this.withSearch()}
                  withSearchSpace={this.withSearchSpace()}
                  noDataText={this.noDataText()}
                  withSelectAll={this.withSelectAll()}
                  withReports={this.withReports()}
                  withDownload={this.withDownload()}
                  withSkills={this.withSkills ? this.withSkills() : false}
                  pageSizeOptions={this.pageSizeOptions()}
                  activeFilterLength={this.getActiveFiltersPreview().length}
                  renderActiveFilters={this.renderActiveFilters.bind(this)}
                  dispatch={this.props.dispatch}
                  selectAll={() => this.selectAll()}
                  deselectAll={() => this.deselectAll()}
                  createSkill={() => this.createSkill()}
                  downloadAll={() => this.downloadAll()}
                  reportsFilter={this.getFilters()}
                  getType={this.getType}
                  searchValue={this.state.search}
                  activeFilters={this.state.activeFilters}
                  ref={(ref) => (this.tableRef = ref)}
                  pageSize={this.state.pageSize}
                  loading={this.state.loading}
                  page={this.state.currentPage}
                  pages={this.state.totalPages}
                  data={this.state.rows}
                  columns={this.columns()}
                  filters={this.filters()}
                  showFilters={this.props.withFilters}
                  sort={this.sort()}
                  section={this.section() ? this.section() : null}
                  onRowClick={(data, event) => this.onRowClick(data, event)}
                  onSwitchChange={(val) => this.toggleFilters(val)}
                  onClearFilters={() => this.clearFilters()}
                  onPageChange={(page) => this.changePage(page)}
                  onPageSizeChange={(size, page) => this.changeSize(size, page)}
                  onSearchChange={(keywords) => this.search(keywords)}
                  saveFilters={() => this.saveFilters()}
                  deleteSavedFilters={() => this.deleteSavedFilters()}
                  hasDefaultFilters={this.state.hasDefaultFilters}
                  squeezeSearch={this.state.rightPanelOpen}
                  selected={this.state.selectedRows}
                  renderResults={() => (
                    <PaginationInfo
                      total={this.state.total}
                      perPage={this.state.pageSize}
                      page={this.state.currentPage}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={
                rightPanelOpen
                  ? "col-md-4 RightPanel"
                  : "col-md-4 RightPanel hidden"
              }
            >
              <div className="RightPanelWrapper">
                <div className="RightPanelContent">
                  {rightPanelOpen && this.renderRightPanel()}
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className="DataGrid">
            <GridList
              renderActiveFiltersMobile={this.renderActiveFiltersMobile.bind(
                this
              )}
              withPagination={this.withMobilePagination()}
              showPageSizeOptions={false}
              disableFiltering={this.props.disableFiltering}
              onFilterClose={() => this.onFilterClose()}
              onFilterOpen={() => this.onFilterOpen()}
              showFiltersOnly={
                this.props.freezeRows ? this.props.freezeRows : false
              }
              dispatch={this.props.dispatch}
              reportsFilter={this.getFilters()}
              getType={this.getType}
              searchValue={this.state.search}
              noDataText={this.noDataText()}
              selected={this.state.selectedRows}
              activeFilters={this.state.activeFilters}
              ref={(ref) => (this.tableRef = ref)}
              pageSize={this.state.pageSize}
              loading={this.state.loading}
              page={this.state.currentPage}
              pages={this.state.totalPages}
              data={this.state.rows}
              showFilters={this.props.withFilters}
              columns={this.columns()}
              filters={this.filters()}
              sort={this.sort()}
              onRowClick={(data, event) => this.onRowClick(data, event)}
              onSwitchChange={(val) => this.toggleFilters(val)}
              onClearFilters={() => this.clearFilters()}
              onPageChange={(page) => this.changePage(page)}
              onPageSizeChange={(size, page) => this.changeSize(size, page)}
              onSearchChange={(keywords) => this.search(keywords)}
              hideFilters={this.state.hideFilters}
              saveFilters={() => this.saveFilters()}
              deleteSavedFilters={() => this.deleteSavedFilters()}
              hasDefaultFilters={this.state.hasDefaultFilters}
              renderResults={() => (
                <PaginationInfo
                  total={this.state.totalPages}
                  perPage={this.state.pageSize}
                  page={this.state.currentPage}
                />
              )}
            />
          </div>
          {rightPanelOpen && (
            <div className="RightPanel">
              <div className="RightPanelWrapper">
                <div className="RightPanelContent">
                  <i
                    className="mdi mdi-chevron-left close"
                    onClick={() => this.deselectAll()}
                  />
                  {this.renderRightPanel()}
                </div>
              </div>
            </div>
          )}
        </MediaQuery>
      </div>
    );
  }
}
