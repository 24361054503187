import React, { Component } from "react";
import "./switch.scss";

export default class Switch extends Component {
  render() {
    return (
      <label
        className={
          this.props.class ? this.props.class + " cstm-switch" : "cstm-switch"
        }
      >
        <input
          disabled={this.props.disabled}
          checked={this.props.checked}
          onChange={(ev) => this.props.onChange(ev.target.checked)}
          type="checkbox"
          className="cstm-switch-input"
        />
        {this.props.label && this.props.labelLeft && (
          <span
            className="cstm-switch-description"
            style={{
              ...(this.props.uppercaseLabel
                ? { textTransform: "uppercase" }
                : {}),
            }}
          >
            {this.props.label}
          </span>
        )}
        <span className="cstm-switch-indicator " />
        {this.props.label && !this.props.labelLeft && (
          <span
            className="cstm-switch-description"
            style={{
              ...(this.props.uppercaseLabel
                ? { textTransform: "uppercase" }
                : {}),
            }}
          >
            {this.props.label}
          </span>
        )}
      </label>
    );
  }
}
