import React, { Component } from "react";
import i18n from "../../../i18n";
import { FIELDS } from "../form/project_value";
import MapWithButtons from "../../google_map/map_with_buttons";
import { withRouter } from "react-router-dom";
import InfoModal from "../../info_modal/info_modal";
import FileManager from "../../file_manager/file_manager";
import {
  PROJECT_FIELD_TYPE,
  ICON,
} from "../../../pages/projects/fields/constants";
import { connectWithStore } from "../../../services/redux";
import PrettoSlider from "../../../components/slider/slider";
import SkillChip from "../../../components/skills/skill_chip";
import SKILL_COLORS from "../../../services/skill_colors";
import { getSkill } from "../../../services/api/skills";

const marks = Array.from(Array(5).keys()).map((value) => {
  return {
    label: value + 1,
    value: value + 1,
  };
});

class ProjectValue extends Component {
  state = {
    showCopyAlert: false,
    shouldCopyLink: "",
    projectSkills: [],
  };

  async componentDidMount() {
    const { project } = this.props;
    const { settings } = this.props;
    const { projectFields } = settings;
    const skillField =
      projectFields && projectFields[PROJECT_FIELD_TYPE.SKILLS];
    const skillFieldId = skillField && skillField.id;
    const currentSkills = [];
    if (project && skillFieldId && project[skillFieldId]) {
      for (let skill of project[skillFieldId]) {
        const response = await getSkill(skill);
        if (response.success) {
          currentSkills.push(response.data);
        }
      }
    }
    this.setState({
      projectSkills: currentSkills,
    });
  }

  renderValue() {
    const { settings } = this.props.question;
    const targetField = settings.field;
    const { projectFields } = this.props.settings;
    if (projectFields && projectFields.length > 0) {
      const filter = projectFields.filter(
        (field) => field.label === targetField
      );
      const target = filter.length > 0 ? filter[0] : { type: targetField };
      switch (target.type) {
        case PROJECT_FIELD_TYPE.ADDRESS:
          return this.renderLocation(target);
        case PROJECT_FIELD_TYPE.LIST:
          return this.renderList(target);
        case PROJECT_FIELD_TYPE.FILE_LIST:
          return this.renderFiles(target);
        case PROJECT_FIELD_TYPE.TEXT:
          return this.renderText(target);
        case PROJECT_FIELD_TYPE.RANKING:
          return this.renderRanking(target);
        case PROJECT_FIELD_TYPE.SKILLS:
          return this.renderSkills(target);
        case FIELDS.ALL:
          return this.renderAll();
        default:
          break;
      }
    }
  }

  copyLink(link) {
    this.setState(
      {
        shouldCopyLink: link,
      },
      () => {
        this.textAreaRef.select();
        document.execCommand("copy");
        this.setState({
          showCopyAlert: true,
        });
      }
    );
  }

  renderLocation({ id, label, icon }, index) {
    const { project } = this.props;
    const { showCopyAlert, shouldCopyLink } = this.state;
    return (
      <div className="ProjectValue-Section ProjectValue-Location" key={index}>
        {showCopyAlert && (
          <InfoModal
            show={true}
            text={i18n.t("default:_LINK_COPIED")}
            onClose={() => this.setState({ showCopyAlert: false })}
          />
        )}
        <textarea
          ref={(ref) => (this.textAreaRef = ref)}
          style={{
            width: 0,
            height: 0,
            opacity: 0,
          }}
          value={shouldCopyLink}
          readOnly
        />
        {this.props.project[id] && (
          <MapWithButtons
            customChangeHandler={() =>
              this.props.history.push(`/project/view/${project._id}/location`)
            }
            project={{
              ...this.props.project,
              location: this.props.project[id],
            }}
            customShareLink={
              window.location.href
                .replace("#", "")
                .replace(`/${this.props.jobId}`, "") +
              `/${this.props.jobId}/location`
            }
            copyLink={(link) => this.copyLink(link)}
          />
        )}
        {!this.props.project.location && (
          <div>
            <div className="ProjectValue-Heading">
              {icon && <img src={icon} alt={label} />}
              {!icon && (
                <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.FILE_LIST]}`} />
              )}
              <span dir="auto">{label}</span>
            </div>
            <ul>
              <li>{i18n.t("default:_NO_LOCATION")}</li>
            </ul>
          </div>
        )}
      </div>
    );
  }

  renderList({ id, label, icon }, index) {
    const value =
      this.props.project && this.props.project[id]
        ? this.props.project[id].map((c) => c.label)
        : ["List item"];
    return (
      <div className="ProjectValue-Section ProjectValue-List" key={index}>
        <div dir="auto" className="ProjectValue-Heading">
          {icon && <img src={icon} alt={label} />}
          {!icon && <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.LIST]}`} />}
          <span dir="auto">{label}</span>
        </div>
        <ul>
          {value.length > 0 &&
            value.map((customer, index) => {
              return (
                <li dir="auto" key={index}>
                  {customer}
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  renderFiles({ id, label, icon }, index) {
    const files =
      this.props.project && this.props.project[id]
        ? this.props.project[id]
        : [];
    return (
      <div className="ProjectValue-Section" key={index}>
        <div dir="auto" className="ProjectValue-Heading">
          {icon && <img src={icon} alt={label} />}
          {!icon && (
            <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.FILE_LIST]}`} />
          )}
          <span dir="auto">{label}</span>
        </div>

        <FileManager files={files} viewOnly={true} showDownloadButton={true} />
      </div>
    );
  }

  renderText({ id, label, icon }, index) {
    const value =
      this.props.project && this.props.project[id]
        ? this.props.project[id]
        : [];
    return (
      <div className="ProjectValue-Section" key={index}>
        <div dir="auto" className="ProjectValue-Heading">
          {icon && <img src={icon} alt={label} />}
          {!icon && <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.TEXT]}`} />}
          <span dir="auto">{label}</span>
        </div>
        <span>{value}</span>
      </div>
    );
  }

  renderRanking({ id, label, icon }, index) {
    const leadingColor = this.props.primary;
    const value =
      this.props.project && this.props.project[id]
        ? this.props.project[id]
        : [];
    return (
      <div className="ProjectValue-Section" key={index}>
        <div dir="auto" className="ProjectValue-Heading">
          {icon && <img src={icon} alt={label} />}
          {!icon && <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.RANKING]}`} />}
          <span dir="auto">{label}</span>
        </div>
        <div>
          <PrettoSlider
            style={{
              color: leadingColor && leadingColor,
              width: "200px",
              marginTop: "50px",
            }}
            value={value || 1}
            step={1}
            marks={marks}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={marks[0].value}
            max={marks[marks.length - 1].value}
          />
        </div>
      </div>
    );
  }

  renderSkills({ id, label, icon }, index) {
    const { projectSkills } = this.state;
    return (
      <div className="ProjectValue-Section" key={index}>
        <div dir="auto" className="ProjectValue-Heading">
          {icon && <img src={icon} alt={label} />}
          {!icon && <i className={`mdi ${ICON[PROJECT_FIELD_TYPE.RANKING]}`} />}
          <span dir="auto">{label}</span>
        </div>
        {projectSkills.length > 0 ? (
          <div style={{ width: "70%" }}>
            {projectSkills.map((skill, index) => {
              return (
                <SkillChip
                  withMargin
                  backgroundColor={SKILL_COLORS.WHITE}
                  withAvatar
                  sidePanel={true}
                  skillAvatar={skill.avatar}
                  key={index}
                  name={skill.name}
                  skillColor={SKILL_COLORS.GREEN}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  renderAll() {
    const { settings } = this.props;
    const { projectFields } = settings;

    return (
      <div className="ProjectValue-All">
        {projectFields &&
          projectFields.length > 0 &&
          projectFields.map((field, index) => {
            switch (field.type) {
              case PROJECT_FIELD_TYPE.ADDRESS:
                return this.renderLocation(field, index);
              case PROJECT_FIELD_TYPE.LIST:
                return this.renderList(field, index);
              case PROJECT_FIELD_TYPE.TEXT:
                return this.renderText(field, index);
              case PROJECT_FIELD_TYPE.FILE_LIST:
                return this.renderFiles(field, index);
              case PROJECT_FIELD_TYPE.RANKING:
                return this.renderRanking(field, index);
              case PROJECT_FIELD_TYPE.SKILLS:
                return this.renderSkills(field, index);
              default:
                return null;
            }
          })}
      </div>
    );
  }

  render() {
    return (
      <div className="ProjectValue" dir="auto">
        {this.renderValue()}
      </div>
    );
  }
}

export default connectWithStore(withRouter(ProjectValue), ["settings"]);
