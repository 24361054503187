import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import { isMobileDevice } from "../../../services/mobile";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Avatar from "@material-ui/core/Avatar";
import { getFileTarget } from "../../../services/api/file_upload";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  iconRoot: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: isMobileDevice() ? 30 : 50,
    height: isMobileDevice() ? 30 : 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepper: {
    padding: 0,
    width: "80%",
    flexDirection: ({ rightToLeft }) => (rightToLeft ? "row-reverse" : "row"),
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function ColorlibStepIcon(props) {
  const classes = useStyles();
  const { active, completed, stepIcon } = props;

  if (stepIcon) {
    return (
      <Avatar
        alt="Step icon"
        src={getFileTarget(stepIcon, "100x100")}
        style={{
          width: isMobileDevice() ? 30 : 50,
          height: isMobileDevice() ? 30 : 50,
        }}
      />
    );
  }
  return (
    <div
      className={clsx(classes.iconRoot, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {<VideoLabelIcon />}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function HorizontalLinearStepper({
  activeStep,
  steps,
  jobType,
}) {
  //set right to left from activy type settings of current job
  const rightToLeft =
    jobType && jobType.settings && jobType.settings.stepperRTL;
  const classes = useStyles({ rightToLeft });

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel
                {...labelProps}
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{ stepIcon: step.icon }}
              >
                {!isMobileDevice() && step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
