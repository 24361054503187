/**
 * Different question types constants so we dont hardcode them in code.
 * @type {Object}
 */
export const QuestionType = {
  DATE_TIME: 0,
  BOOL: 1,
  LIST: 2,
  PROJECT_LIST: 3,
  SYSTEM_LIST: 4,
  TEXT: 5,
  IMAGE: 6,
  LENGTH: 7,
  MESSAGE: 8,
  PROJECT_VALUE: 9,
  SUMMARY: 10,
  STEP: 11,
  PROJECT_TABLE: 12,
  BLUEPRINT: 13,
};

export const QaSummaryType = {
  PREVIOUS_JOB: "previous_job",
  NEXT_JOB: "next_job",
};

export const DateType = {
  DATE: 0,
  TIME: 1,
  DATE_TIME: 2,
  DATE_PLUS_TIME: 3,
};

export const InputType = {
  TEXT: 0,
  EMAIL: 1,
  NUMBER: 2,
};

export const ProjectListType = {
  MATERIALS: 0,
  CONTACTS: 1,
  FACTORIES: 2,
  CUSTOMERS: 3,
};

export const SystemListType = {
  PROJECTS: 0,
  JOB_TYPES: 1,
  USERS: 2,
  FACTORIES: 3,
  MATERIALS: 4,
  CUSTOMERS: 5,
};

export const getLabelFromType = (type) => {
  const keys = Object.keys(QuestionType);
  const key = keys[type];
  return key;
};

export const getFileIcon = (extension) => {
  const ICONS = {
    png: "mdi mdi-image",
    jpg: "mdi mdi-image",
    jpeg: "mdi mdi-image",
    xls: "mdi mdi-file-table",
    xlsx: "mdi mdi-file-table",
    pdf: "mdi mdi-file-pdf",
    doc: "mdi mdi-file-word",
    docx: "mdi mdi-file-word",
    mov: "mdi mdi-video",
    mp4: "mdi mdi-video",
    "3gp": "mdi mdi-video",
  };
  const result = ICONS[extension && extension.toLowerCase()];
  if (result) {
    return result;
  }
  return "";
};

export const getTypeIcon = (type) => {
  const icons = [
    "mdi-calendar-clock",
    "mdi-thumb-up",
    "mdi-clipboard-pulse",
    "mdi-factory",
    "mdi-buffer",
    "mdi-lead-pencil",
    "mdi-camera-outline",
    "mdi-map-marker-distance",
    "mdi-message-text",
    "mdi-file-document",
    "mdi-information",
    "mdi-label-outline",
    "mdi-table",
    "mdi-file-pdf",
  ];
  return icons[type] ? icons[type] : "";
};

export const getRawAnswerValue = (answerValue) => {
  if (!answerValue) {
    return "";
  }
  let convertedValue = answerValue;
  if (typeof answerValue === "object") {
    convertedValue = "";
  }

  if (answerValue.blocks) {
    const rawText = answerValue.blocks.map((block) => block.text).join(" ");
    convertedValue = rawText;
  }
  return convertedValue;
};

export const getFilterAnswerValue = (answerValue) => {
  if (!answerValue) {
    return "";
  }
  let convertedValue = answerValue;
  if (answerValue.blocks) {
    const rawText = answerValue.blocks.map((block) => block.text).join(" ");
    convertedValue = rawText;
  }
  return convertedValue;
};
