import React, { Component } from "react";
import { getFileTarget } from "../../../services/api/file_upload";
import DecoratedTextReader from "../../draft_editor/decorated_text_reader";

export default class SamuraiMessage extends Component {
  render() {
    const image = this.props.question.settings.image;

    return (
      <div className="MessageComponent">
        <div className="MessageComponent-Image">
          {image && (
            <div>
              <img alt={"message"} src={getFileTarget(image, "500x500")} />
            </div>
          )}
        </div>
        <div dir="auto" className="MessageComponent-Text">
          <DecoratedTextReader text={this.props.question.settings.message} />
        </div>
      </div>
    );
  }
}
