import { IconButton, makeStyles, Typography, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { BlueprintEditor } from "../../../redux/actions";
import { connectWithStore } from "../../../services/redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkIcon from '@material-ui/icons/Work';
import i18n from "../../../i18n";
import DateService from "../../../services/date";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Avatar from "@material-ui/core/Avatar";
import { getFileTarget } from "../../../services/api/file_upload";
import { useTheme } from "@material-ui/core/styles";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import { removeTransformers, canUserEditElement, removeShape } from "./utils";
import Confirm from "../../confirm/confirm";
import { deleteElement } from "../../../services/api/blueprints";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import { getCurrentJob } from "../../../services/api/jobs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 5,
    marginLeft: 30,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    paddingLeft: "5px",
    overflow: "auto",
  },
  contentSpan: {
    paddingLeft: "10px",
  },
  footer: {
    borderBottom: "1px solid #F0F0F0",
    paddingBottom: 5,
  },
  footerIconContainer: {
    display: "flex",
    flexDirection: "column",
  },
  footerIcon: {
    width: "1rem",
    height: "1rem",
  },
  footerIconText: {
    fontSize: "0.9rem",
  },
  previewContainer: {
    margin: 20,
  },
  elementInfoIcon: {
    width: 18,
    height: 18,
    marginLeft: 3,
  },
}));

//const AVATAR = require("../../../assets/img/project-placeholder.png");

function ElementsInfoPanel({
  blueprintEditor,
  dispatch,
  currentJob,
  question,
}) {
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const theme = useTheme();
  const {
    //elementsInfoPanelOpen,
    infoPanelData,
    selectedElement,
    mode,
  } = blueprintEditor;
  const classes = useStyles();
  //const blueprintElements = settings.blueprintSettings.elements;

  const getDateCreated = () => {
    if (infoPanelData) {
      return DateService.getFormattedDate(infoPanelData.element.created_at);
    } else if (selectedElement) {
      const filteredElements = blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      );
      if (filteredElements && filteredElements.length > 0) {
        const created_at = filteredElements[0].created_at;
        return DateService.getFormattedDate(created_at);
      }
    }
  };

  const getUserName = () => {
    let user;
    if (infoPanelData) {
      user = infoPanelData.element.user;
    } else if (selectedElement) {
      const filteredElements = blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      );
      if (filteredElements && filteredElements.length > 0) {
        user = filteredElements[0].user;
      }
    }
    return user && user.name
      ? `${user.name.first} ${user.name.last}`
      : user
      ? user.email
      : "";
  };

  const getJobTypeTitle = () => {
    if (infoPanelData) {
      return infoPanelData.blueprint.job.jobType.title;
    }
    if (selectedElement) {
      const elementJobArr = blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      );
      const elementJob = elementJobArr.length > 0 ? elementJobArr[0].job : null;
      return elementJob && elementJob.jobType
        ? elementJob.jobType.title
        : "unknown";
    }
  };

  const getJobTypeIcon = () => {
    let icon;
    if (infoPanelData) {
      icon = infoPanelData.blueprint.job.jobType.icon;
    }
    if (selectedElement && currentJob) {
      icon = currentJob.jobType ? currentJob.jobType.icon : null;
    }
    if (icon) {
      return (
        <Avatar
          className={classes.elementInfoIcon}
          alt="Activity type icon"
          src={getFileTarget(icon, "100x100")}
        />
      );
    }
    return (
      <i
        style={{ color: theme.palette.primary.main, fontSize: "1.1rem" }}
        className="icon-case_v2"
      ></i>
    );
  };

  /* const getProjectIcon = () => {
    let icon;
    if (infoPanelData) {
      icon = infoPanelData.blueprint.job.jobType.avatar;
    }
    if (selectedElement && currentJob) {
      icon = currentJob.jobType ? currentJob.jobType.avatar : null;
    }
    return (
      <Avatar
        className={classes.elementInfoIcon}
        alt="Activity type icon"
        src={icon ? getFileTarget(icon, "100x100") : AVATAR}
      />
    );
  }; */

  /* const getProjectName = () => {
    if (infoPanelData) {
      return infoPanelData.blueprint.job.project.name;
    }
    if (selectedElement) {
      const elementJobArr = blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      );
      const elementJob = elementJobArr.length > 0 ? elementJobArr[0].job : null;
      return elementJob ? elementJob.project.name : "unknown";
    }
  }; */

  const getJobProp = (prop) => {
    if (infoPanelData) {
      return infoPanelData.blueprint.job[prop];
    }
    if (selectedElement) {
      const elementJobArr = blueprintEditor.elements.filter(
        (el) => el.id === selectedElement.attrs.id
      );
      const elementJob = elementJobArr.length > 0 ? elementJobArr[0].job : null;
      return elementJob ? elementJob[prop] : "unknown";
    }
  };

  const isElementVisible = () => {
    if (infoPanelData) {
      return infoPanelData.element.visible;
    }
    if (selectedElement) {
      return selectedElement.attrs.visible;
    }
  };

  const removeElement = async () => {
    setRemoveConfirm(false);
    dispatch(
      BlueprintEditor.removeElement(
        infoPanelData ? infoPanelData.element.id : selectedElement.attrs.id
      )
    );
    const elId = infoPanelData
      ? infoPanelData.element.id
      : selectedElement.attrs.id;
    const shape = blueprintEditor.app.find("#" + elId)[0];
    if (shape) {
      removeShape(shape);
    }
    removeTransformers();
    dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
    dispatch(BlueprintEditor.setInfoPanelData(null));
    // remove element if it comes from other job
    if (
      infoPanelData &&
      infoPanelData.blueprint.job.ID !== infoPanelData.currentJob.ID
    ) {
      await deleteElement(elId);
    }
  };

  const closeConfirm = () => {
    setRemoveConfirm(false);
  };

  const onVisibilityClick = () => {
    dispatch(
      BlueprintEditor.toggleElementVisibility({
        id: infoPanelData ? infoPanelData.element.id : selectedElement.attrs.id,
      })
    );
  };

  const openJob = async (id) => {
    try {
      const currentJob = await getCurrentJob({ id }, "v2");
      dispatch(BlueprintEditor.setRightPanelJob(currentJob));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid className={classes.root}>
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_CONFIRM_DELETE_ELEMENT")}
        show={removeConfirm}
        onConfirm={() => removeElement()}
        onClose={() => closeConfirm()}
      />
      <div className={classes.wrapper}>
        {(infoPanelData || selectedElement) && (
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            className={classes.content}
          >
            <div>
              <CalendarTodayIcon color="primary" />
              <span className={classes.contentSpan}>{getDateCreated()}</span>
            </div>
            <div>
              <PermIdentityIcon color="primary" />
              <span className={classes.contentSpan}>{getUserName()}</span>
            </div>
            <Grid container>
              {getJobTypeIcon()}
              <span className={classes.contentSpan}>{getJobTypeTitle()}</span>
            </Grid>
            {/* <Grid container>
              {getProjectIcon()}
              <span className={classes.contentSpan}>{getProjectName()}</span>
            </Grid> */}
            <div>
              <WorkOutlineIcon color="primary" />
              <span className={classes.contentSpan}>{getJobProp("ID")}</span>
            </div>
            <div>
              <CheckCircleOutlineOutlined color="primary" />
              <span className={classes.contentSpan}>
                {getJobProp("status")}
              </span>
            </div>
          </Grid>
        )}
      </div>
      {canUserEditElement(
        infoPanelData && infoPanelData.blueprint && infoPanelData.blueprint.job,
        currentJob,
        question
      ) && (
        <Grid container justifyContent="space-around" className={classes.footer}>
          <Grid item className={classes.footerIconContainer}>
            <IconButton
              onClick={() => onVisibilityClick()}
            >
              {isElementVisible() ? (
                <VisibilityOffIcon className={classes.footerIcon} />
              ) : (
                <VisibilityIcon className={classes.footerIcon} />
              )}
            </IconButton>
            <Typography align="center" className={classes.footerIconText}>
              {isElementVisible()
                ? i18n.t("default:_HIDE")
                : i18n.t("default:_SHOW")}
            </Typography>
          </Grid>
          <Grid item className={classes.footerIconContainer}>
            <IconButton
              disabled={mode !== "EDIT"}
              color={mode !== "EDIT" ? "disabled" : "secondary"}
              onClick={() => setRemoveConfirm(true)}
            >
              <DeleteIcon className={classes.footerIcon} />
            </IconButton>
            <Typography
              align="center"
              className={classes.footerIconText}
              color={mode !== "EDIT" ? "primary" : "secondary"}
            >
              {i18n.t("default:_REMOVE")}
            </Typography>
          </Grid>
          <Grid item className={classes.footerIconContainer}>
            <IconButton
              onClick={() => {
                openJob(
                  infoPanelData
                    ? infoPanelData.blueprint.job._id
                    : blueprintEditor.jobs[0].job._id
                );
              }}
            >
              <WorkIcon className={classes.footerIcon} />
            </IconButton>
            <Typography className={classes.footerIconText} align="center">
              {i18n.t("default:_VIEW_JOB")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default connectWithStore(ElementsInfoPanel, [
  "blueprintEditor",
  "settings",
]);
