/****
 * IMPORTANT NOTE: When creating new reducers use constants and put them in constants.js, do not attempt to change existing one's
 * to not cause bugs in existing codebase unless required by someone to do so
 */
import {
  WIZARD_JOB_SELECT_PROJECT,
  WIZARD_JOB_SELECT_JOB_TYPE,
  WIZARD_JOB_SELECT_START_DATE,
  WIZARD_JOB_ASSIGN_USERS,
  WIZARD_JOB_ANSWER_MAIN_QUESTIONS,
  WIZARD_JOB_RESET,
  WIZARD_JOB_SET_ID,
  WIZARD_JOB_SET_SKILLS,
  WIZARD_JOB_SET_MAIN_QUESTIONS,
  WIZARD_SET_TYPE,
  WIZARD_SET_TITLE,
  WIZARD_JOB_SELECT_PREV_JOB,
  WIZARD_JOB_SELECT_NEXT_JOB,
  WIZARD_PROJECT_SET_NAME,
  WIZARD_PROJECT_SET_SKILL_FIELDS,
  WIZARD_PROJECT_SET_CURRENT_INDEX,
  WIZARD_PROJECT_RESET,
  WIZARD_USER_SET_EMAIL,
  WIZARD_USER_SELECT_ROLE,
  WIZARD_USER_RESET,
  FIELD_EDITOR_SET_VISIBLE,
  FIELD_EDITOR_SET_FIELD,
  SET_FIELD_LIST,
  UPDATE_FIELDS,
  FIELD_EDITOR_ADD_FIELD,
  FIELD_EDITOR_REMOVE_FIELD,
  UPDATE_FIELD,
  CURRENT_PROJECT_SET_PROJECT,
  CURRENT_PROJECT_UPDATE_PROJECT,
  CURRENT_PROJECT_RESET_PROJECT,
  SET_STEPS,
  ADD_STEP_QUESTIONS,
  SET_BUTTON_PRESSED,
  SET_LAST_ACTIVE_QUESTION,
  USER_AUTOCOMPLETE_WITH_AVAILABILITY,
  USER_AUTOCOMPLETE_SET_AVAILABILITIES,
  USER_AUTOCOMPLETE_RESET,
  USER_AUTOCOMPLETE_SET_JOB_DATE,
  USER_AUTOCOMPLETE_SET_TAB,
  BLUEPRINT_EDITOR_SET_TOOLS,
  BLUEPRINT_EDITOR_UPDATE_LAYER,
  BLUEPRINT_EDITOR_CHANGE_TOOL,
  BLUEPRINT_EDITOR_CHANGE_MODE,
  BLUEPRINT_EDITOR_SET_LAYER,
  BLUEPRINT_EDITOR_OPEN_LAYER_PANEL,
  BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL,
  BLUEPRINT_EDITOR_SET_KONVA_APP,
  BLUEPRINT_EDITOR_UPDATE_HISTORY,
  BLUEPRINT_EDITOR_UNDO,
  BLUEPRINT_EDITOR_REDO,
  SHIFT_VIEW_SET_USERS,
  SHIFT_VIEW_SET_JOBS,
  SHIFT_VIEW_SET_RANGE,
  SHIFT_VIEW_SET_USER_PANEL_MODE,
  SHIFT_VIEW_SET_SHOW_STATUSES,
  SHIFT_VIEW_SET_SHOW_JOB_TYPES,
  SHIFT_VIEW_SET_SEARCH_PHRASE,
  SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED,
  SHIFT_VIEW_SET_TEMP_ASSIGNED,
  SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES,
  SHIFT_VIEW_SET_DROPZONES,
  SHIFT_VIEW_SET_DRAGGER,
  SHIFT_VIEW_SET_RIGHT_PANEL_USER,
  SHIFT_VIEW_SET_RIGHT_PANEL_JOB,
  SHIFT_VIEW_SET_OUTSIDE_CONFIRM,
  SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP,
  SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES,
  SHIFT_VIEW_SET_IS_ASSIGN,
  SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS,
  SHIFT_VIEW_SET_SELECTED_USER,
  SHIFT_VIEW_SET_DRAGGED,
  SHIFT_VIEW_SET_USER_HOVER,
  SHIFT_VIEW_SET_DROPPABLE_CACHE,
  SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES,
  SHIFT_VIEW_SET_USERS_LOADING,
  SHIFT_VIEW_SET_JOBS_LOADING,
  SHIFT_VIEW_SET_MINI_VIEW,
  SHIFT_VIEW_SET_SHARE_MODAL,
  SHIFT_VIEW_SET_SHARE_LINK,
  SHIFT_VIEW_LOAD_DATA,
  USER_LOCATION_SET_LOCATION,
  WIZARD_USER_SELECT_COMPANIES,
  WIZARD_USER_SELECT_PROJECTS,
  CURRENT_WIDGET_SET_WIDGET,
  CURRENT_WIDGET_RESET_WIDGET,
  CURRENT_WIDGET_UPDATE_WIDGET,
  CURRENT_WIDGET_SET_MOBILE_TAB,
  INSTANCE_WIZARD_DEV_PROCEDURE,
  INSTANCE_WIZARD_EMAIL,
  INSTANCE_WIZARD_DB,
  INSTANCE_WIZARD_INCOGNITO,
  INSTANCE_WIZARD_ADD_YOURSELF,
  TABLE_SET_SELECTED_ROWS,
  TABLE_SET_ROWS,
  TABLE_RIGHT_PANEL_OPEN,
  TABLE_SET_TOTAL_PAGES,
  TABLE_SET_TOTAL,
  TABLE_SET_CURRENT_PAGE,
  TABLE_SET_PAGE_SIZE,
  TABLE_SET_LOADING,
  TABLE_SET_SEARCH,
  TABLE_SET_ACTIVE_FILTERS,
  TABLE_SET_HAS_DEFAULT_FILTERS,
  FILTERS_SET_INSPECTOR,
  FILTERS_SET_FILTER_ME,
  FILTERS_SET_DATE,
  FILTERS_SET_STATUS,
  FILTERS_SET_JOB_TYPE,
  FILTERS_SET_QUESTIONS,
  FILTERS_SET_ANSWERS,
  FILTERS_SET_MY_PROJECTS,
  FILTERS_SET_PROJECT,
  FILTERS_SET_SKILLS,
  FILTERS_SET_SORT,
  FILTERS_SET_PROJECT_FIELDS_DATA,
  APPS_SET_ENABLED,
  TABLE_SET_RIGHT_PANEL_WITH_QUESTION,
  TABLE_SET_WITH_RIGHT_PANEL,
  TABLE_SET_TYPE,
  TABLE_INIT,
  TABLE_SET_HAS_ACTIVE_FILTER,
  TABLE_SET_SHOW_FILTERS_MODAL,
  TABLE_SET_SHOW_USERS_MODAL,
  PROJECTS_LIST,
  FILTERS_SET_SHOW_PROJECTS_MODAL,
  FILTERS_SET_SHOW_SKILLS_MODAL,
  MULTI_STEP_FILTER_SET_SHOW,
  MULTI_STEP_FILTER_SET_ACTIVE_STEP,
  AUTOMATION_WIZARD_SET_TOPIC,
  AUTOMATION_WIZARD_SET_EVENT,
  AUTOMATION_WIZARD_SET_ID,
  AUTOMATION_WIZARD_SET_CONDITIONS,
  AUTOMATION_WIZARD_SET_CONDITIONS_CONFIG,
  USER_AUTOCOMPLETE_SET_USERS,
  BLUEPRINT_EDITOR_OPEN_FILE,
  BLUEPRINT_EDITOR_ADD_ELEMENT,
  BLUEPRINT_EDITOR_REMOVE_ELEMENT,
  BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL,
  BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT,
  BLUEPRINT_EDITOR_UPDATE_ELEMENT,
  BLUEPRINT_EDITOR_SET_ELEMENTS,
  BLUEPRINT_EDITOR_SET_JOBS,
  BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA,
  BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY,
  BLUEPRINT_EDITOR_RESET,
  AUTOMATION_WIZARD_SET_LAST_STEP,
  AUTOMATION_WIZARD_SET_ACTION,
  AUTOMATION_WIZARD_SET_ACTIONS_CONFIG,
  AUTOMATION_WIZARD_SET_NAME,
  AUTOMATION_WIZARD_SET_CONDITION_OPTIONS,
  FILTERS_BP_SET_USER,
  FILTERS_BP_SET_DATE,
  FILTERS_BP_SET_JOB_TYPE,
  FILTERS_BP_SET_ELEMENT,
  FILTERS_BP_RESET,
  BLUEPRINT_EDITOR_SET_SHOW_FILTERS,
  BLUEPRINT_EDITOR_SET_QUESTION_OPEN,
  AUTOMATION_WIZARD_SET_INITIAL_EVENT,
  ADD_STEP,
  SET_STEPS_MODAL_OPEN,
  QUESTIONS_ACTIVE_QUESTION,
  SET_WIDGET_CODE,
  AUTOMATION_WIZARD_SET_CONNECTED_DATA,
  AUTOMATION_WIZARD_SET_RECEIVERS,
  AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS,
  TABLE_SET_TEMP_FILTER_SELECTION,
  USER_AUTOCOMPLETE_SET_ASSIGNED_USERS,
  TABLE_SET_SHOW_REPORTS_BUILDER,
  TABLE_SET_SHOW_WARNING_MODAL,
  USER_AUTOCOMPLETE_RESET_KEEPING_USERS,
  AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO,
  QUESTIONS_TOGGLE_MAIL_SECTION,
  QUESTIONS_SET_ANSWERS,
  AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS,
  AUTOMATION_WIZARD_SET_ACTION_QUESTIONS,
  AUTOMATION_WIZARD_SET_TOPICS,
  JOB_ROW_SET_ANSWERS_MODAL,
  BLUEPRINT_EDITOR_SET_EXPORT_IMAGE,
  BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT,
  BLUEPRINT_EDITOR_SET_FILENAME,
  BLUEPRINT_EDITOR_SET_FILE_OPTIONS,
  BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER,
  BLUEPRINT_EDITOR_SET_IMAGE_ROTATION,
  BLUEPRINT_EDITOR_SET_FILE_PAGE,
  TABLE_SET_SHOW_ACTION_BUTTONS,
  BLUEPRINT_EDITOR_SET_MEASUREMENTS,
  BLUEPRINT_EDITOR_RESET_MEASUREMENTS,
  BLUEPRINT_EDITOR_SET_FLOATING_INPUT,
  BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED,
  BLUEPRINT_EDITOR_SET_PDF_INFO,
  BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE,
  SET_PREVIOUS_STEP,
  SET_STEP_PATH_ACTIVATED,
  BLUEPRINT_EDITOR_SET_FILE_ID,
  BLUEPRINT_EDITOR_SET_PDF_RESIZING,
  BLUEPRINT_EDITOR_SHOW_EDIT_MODAL,
  BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE,
  BLUEPRINT_EDITOR_SET_IS_MEASURING,
  BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED,
  WIZARD_PROJECT_SET_ID,
  SSO_SET_SETTINGS,
  BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN,
  BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT,
  BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT,
  MULTI_STEP_SET_ANSWERS,
  FILTERS_BP_SET_STATUS,
  FILTERS_BP_SET_JOB_ID,
  FILTERS_BP_SET_FILTERS_CHANGED,
  BLUEPRINT_EDITOR_SELECT_ELEMENT,
  BLUEPRINT_EDITOR_SELECT_LINE,
  BLUEPRINT_EDITOR_DESELECT_ELEMENT,
  AUTOMATION_WIZARD_SET_REQUIRED_CONDITIONS,
  AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST,
  MULTI_STEP_SET_QUESTIONS_LIST,
  MULTI_STEP_SET_PROJECTS,
  BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB,
  PROJECTS_SHOW_ACTION_BUTTONS,
  FILTER_VIEWS_SET_NAME,
  FILTER_VIEWS_SET_SAVED_FILTERS,
  FILTER_VIEWS_SET_CHECKED,
  SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB,
  BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES,
  WIZARD_JOB_SET_LINK_ID,
  BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE,
  BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL,
  BLUEPRINT_EDITOR_SET_CROP_SIZE,
  BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP,
  BLUEPRINT_EDITOR_CANCEL_CROP,
  INSTANCE_WIZARD_PHONE,
  INSTANCE_WIZARD_PASSWORD,
  INSTANCE_WIZARD_USER_NAME,
  INSTANCE_WIZARD_INSTANCE_NAME,
  AUTOMATION_WIZARD_SET_TEST_URL,
  INSTANCE_WIZARD_TOKEN,
  AUTOMATION_WIZARD_SET_ACTIONS,
  AUTOMATION_WIZARD_SET_ACTION_CHANGED,
  AUTOMATION_WIZARD_RESET,
  AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED,
  INSTANCE_WIZARD_ENABLE_SSO,
  QUESTIONS_SET_PUBLIC_START_NODE_ID,
  SHIFT_VIEW_SET_ONE_DAY_VIEW,
  WIZARD_USER_SET_ID,
  TABLE_SET_SELECT_MODE,
  FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL,
  FILTERS_SET_MY_PROJECT_MANAGER,
  FILTERS_SET_PROJECT_MANAGERS, GRID_SET_TITLE,
  FILTER_PROJECT_FIELDS,
} from "../constants";
import { USER_AUTOCOMPLETE_TAB } from "../reducers/user_autocomplete";

export const User = {
  setProfileData: (data) => Object.assign({ type: "SET_PROFILE", data }),
  setImpersonateProfileData: (data) =>
    Object.assign({ type: "SET_IMPERSONATE_PROFILE", data }),
  updateProfileData: (data) => Object.assign({ type: "UPDATE_PROFILE", data }),
  setAccessToken: (data) => Object.assign({ type: "SET_ACCESS_TOKEN", data }),
  refreshToken: (data) => Object.assign({ type: "REFRESH_ACCESS_TOKEN", data }),
  logout: (data) => Object.assign({ type: "LOGOUT", data }),
};

export const MyProfile = {
  setNotifications: (data) =>
    Object.assign({ type: "SET_NOTIFICATIONS", data }),
};

export const JobType = {
  list: (data) => Object.assign({ type: "LIST_JOB_TYPE", data }),
  listFromFilter: (data) =>
    Object.assign({ type: "LIST_JOB_TYPE_FROM_FILTER", data }),
  add: (data) => Object.assign({ type: "ADD_JOB_TYPE", data }),
  delete: (data) => Object.assign({ type: "DELETE_JOB_TYPE", data }),
  update: (data) => Object.assign({ type: "UPDATE_JOB_TYPE", data }),
};

export const PageTitle = {
  setPageTitle: (data) => Object.assign({ type: "SET_PAGE_TITLE", data }),
};

export const TopNavMenu = {
  setButtons: (data) => Object.assign({ type: "SET_BUTTONS", data }),
  setActiveMenu: (data) => Object.assign({ type: "SET_ACTIVE_MENU", data }),
  setSearchHandler: (data) =>
    Object.assign({ type: "SET_SEARCH_HANDLER", data }),
  setActiveSearch: (data) => Object.assign({ type: "SET_ACTIVE_SEARCH", data }),
  openWizard: (data) => Object.assign({ type: "OPEN_WIZARD", data }),
  openJobWizard: (data) => Object.assign({ type: "OPEN_JOB_WIZARD", data }),
};

export const Settings = {
  loadAppSettings: (data) => Object.assign({ type: "LOAD_SETTINGS", data }),
  setBlueprintSettings: (data) =>
    Object.assign({ type: "SET_BLUEPRINTS", data }),
  setBranding: (data) => Object.assign({ type: "SET_BRANDING", data }),
  setSettings: (data) => Object.assign({ type: "SET_SETTINGS", data }),
  setMenu: (data) => Object.assign({ type: "SET_MENU", data }),
  setTranslations: (data) => Object.assign({ type: "SET_TRANSLATIONS", data }),
  setCustomStatuses: (data) =>
    Object.assign({ type: "SET_CUSTOM_STATUSES", data }),
  setStatusOrder: (data) => Object.assign({ type: "SET_STATUS_ORDER", data }),
  setStatusTranslations: (data) =>
    Object.assign({ type: "SET_STATUS_TRANSLATIONS", data }),
  setHost: (data) => Object.assign({ type: "SET_HOST", data }),
  setTimezone: (data) => Object.assign({ type: "SET_TIMEZONE", data }),
  setCompanyName: (data) => Object.assign({ type: "SET_COMPANY_NAME", data }),
  setSecurity: (data) => Object.assign({ type: "SET_SECURITY", data }),
  setPrimaryLogo: (data) => Object.assign({ type: "SET_PRIMARY_LOGO", data }),
  setSecondaryLogo: (data) =>
    Object.assign({ type: "SET_SECONDARY_LOGO", data }),
  setAllowedStatuses: (data) =>
    Object.assign({ type: "SET_ALLOWED_STATUSES", data }),
  setAllDay: (data) => Object.assign({ type: "SET_ALL_DAY", data }),
  setDefaultJobSort: (data) =>
    Object.assign({ type: "SET_DEFAULT_JOB_SORT", data }),
  setDefaultFilterViews: (data) =>
    Object.assign({ type: "SET_DEFAULT_FILTER_VIEWS", data }),
  setToggles: (data) => Object.assign({ type: "SET_TOGGLES", data }),
  setProjectFields: (data) =>
    Object.assign({ type: "SET_PROJECT_FIELDS", data }),
  setShiftViewByDay: (data) =>
    Object.assign({ type: "SET_SHIFT_VIEW_BY_DAY", data }),
  setEmailSenderName: (data) =>
    Object.assign({ type: "SET_EMAIL_SENDER_NAME", data }),
  setNotifications: (data) =>
    Object.assign({ type: "SET_NOTIFICATIONS", data }),
  setGoogleDriveCode: (data) =>
    Object.assign({ type: "SET_GOOGLE_DRIVE_CODE", data }),
  setHasChange: (data) => Object.assign({ type: "SET_HAS_CHANGE", data }),
  setUserFields: (data) => Object.assign({ type: "SET_USER_FIELDS", data }),
};

export const NotificationPanel = {
  show: () => Object.assign({ type: "TOGGLE_PANEL", data: true }),
  hide: () => Object.assign({ type: "TOGGLE_PANEL", data: false }),
  setUnreadCount: (count) =>
    Object.assign({ type: "SET_NOTIFICATION_UNREAD_COUNT", data: count }),
};

export const WizardJob = {
  setProject: (data) =>
    Object.assign({
      type: WIZARD_JOB_SELECT_PROJECT,
      data,
    }),
  setPrevJob: (data) => {
    return Object.assign({
      type: WIZARD_JOB_SELECT_PREV_JOB,
      data,
    });
  },
  setNextJob: (data) => {
    return Object.assign({
      type: WIZARD_JOB_SELECT_NEXT_JOB,
      data,
    });
  },
  setJobType: (data) =>
    Object.assign({
      type: WIZARD_JOB_SELECT_JOB_TYPE,
      data,
    }),
  setStartDate: (data) =>
    Object.assign({
      type: WIZARD_JOB_SELECT_START_DATE,
      data,
    }),
  setId: (data) =>
    Object.assign({
      type: WIZARD_JOB_SET_ID,
      data,
    }),
  setMainQuestions: (data) =>
    Object.assign({
      type: WIZARD_JOB_SET_MAIN_QUESTIONS,
      data,
    }),
  setSkills: (data) =>
    Object.assign({
      type: WIZARD_JOB_SET_SKILLS,
      data,
    }),
  assignUsers: (data) =>
    Object.assign({
      type: WIZARD_JOB_ASSIGN_USERS,
      data,
    }),
  answerMainQuestions: (data) =>
    Object.assign({
      type: WIZARD_JOB_ANSWER_MAIN_QUESTIONS,
      data,
    }),
  setLinkId: (data) =>
    Object.assign({
      type: WIZARD_JOB_SET_LINK_ID,
      data,
    }),
  reset: (data) =>
    Object.assign({
      type: WIZARD_JOB_RESET,
      data,
    }),
};

export const WizardProject = {
  setName: (data) =>
    Object.assign({
      type: WIZARD_PROJECT_SET_NAME,
      data,
    }),
  setSkillId: (data) =>
    Object.assign({
      type: WIZARD_PROJECT_SET_ID,
      data,
    }),
  setSkillFields: (data) =>
    Object.assign({
      type: WIZARD_PROJECT_SET_SKILL_FIELDS,
      data,
    }),
  setCurrentIndex: (data) =>
    Object.assign({
      type: WIZARD_PROJECT_SET_CURRENT_INDEX,
      data,
    }),
  reset: (data) =>
    Object.assign({
      type: WIZARD_PROJECT_RESET,
      data,
    }),
};

export const WizardUser = {
  setEmail: (data) =>
    Object.assign({
      type: WIZARD_USER_SET_EMAIL,
      data,
    }),
  setRole: (data) =>
    Object.assign({
      type: WIZARD_USER_SELECT_ROLE,
      data,
    }),
  setCompanies: (data) =>
    Object.assign({
      type: WIZARD_USER_SELECT_COMPANIES,
      data,
    }),
  setProjects: (data) =>
    Object.assign({
      type: WIZARD_USER_SELECT_PROJECTS,
      data,
    }),
  reset: (data) =>
    Object.assign({
      type: WIZARD_USER_RESET,
      data,
    }),
  setId: (data) =>
    Object.assign({
      type: WIZARD_USER_SET_ID,
      data,
    }),
};

export const Wizard = {
  setType: (data) =>
    Object.assign({
      type: WIZARD_SET_TYPE,
      data,
    }),
  setTitle: (data) => Object.assign({ type: WIZARD_SET_TITLE, data }),
};

export const FieldEditor = {
  setVisible: (data) =>
    Object.assign({
      type: FIELD_EDITOR_SET_VISIBLE,
      data,
    }),
  setField: (data) =>
    Object.assign({
      type: FIELD_EDITOR_SET_FIELD,
      data,
    }),
  setFieldList: (data) =>
    Object.assign({
      type: SET_FIELD_LIST,
      data,
    }),
  updateFieldsInDatabase: (next) =>
    Object.assign({
      type: UPDATE_FIELDS,
      data: next,
    }),
  addField: (data) =>
    Object.assign({
      type: FIELD_EDITOR_ADD_FIELD,
      data,
    }),
  removeField: (index) =>
    Object.assign({
      type: FIELD_EDITOR_REMOVE_FIELD,
      data: index,
    }),
  updateField: (index, fieldData) =>
    Object.assign({
      type: UPDATE_FIELD,
      data: {
        index,
        fieldData,
      },
    }),
};

export const CurrentProject = {
  setProject: (data) =>
    Object.assign({
      type: CURRENT_PROJECT_SET_PROJECT,
      data,
    }),
  resetProject: () =>
    Object.assign({
      type: CURRENT_PROJECT_RESET_PROJECT,
      data: null,
    }),
  updateProject: (data) =>
    Object.assign({
      type: CURRENT_PROJECT_UPDATE_PROJECT,
      data,
    }),
};

export const StepQuestions = {
  setSteps: (data) =>
    Object.assign({
      type: SET_STEPS,
      data,
    }),
  addStep: (data) =>
    Object.assign({
      type: ADD_STEP,
      data,
    }),
  setStepsModalOpen: (data) =>
    Object.assign({
      type: SET_STEPS_MODAL_OPEN,
      data,
    }),
  addQuestion: (data) =>
    Object.assign({
      type: ADD_STEP_QUESTIONS,
      data,
    }),
  setButtonPressed: (data) =>
    Object.assign({
      type: SET_BUTTON_PRESSED,
      data,
    }),
  setLastActiveQuestion: (data) =>
    Object.assign({
      type: SET_LAST_ACTIVE_QUESTION,
      data,
    }),
  setPreviousStep: (data) =>
    Object.assign({
      type: SET_PREVIOUS_STEP,
      data,
    }),
  setStepPathActivated: (data) =>
    Object.assign({
      type: SET_STEP_PATH_ACTIVATED,
      data,
    }),
};

export const UserAutocomplete = {
  withAvailability: () =>
    Object.assign({
      type: USER_AUTOCOMPLETE_WITH_AVAILABILITY,
      data: null,
    }),
  setAvailabilities: (data) =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_AVAILABILITIES,
      data,
    }),
  setJobDate: (data) =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_JOB_DATE,
      data,
    }),
  reset: () =>
    Object.assign({
      type: USER_AUTOCOMPLETE_RESET,
    }),
  resetKeepingUsers: () =>
    Object.assign({
      type: USER_AUTOCOMPLETE_RESET_KEEPING_USERS,
    }),
  sortByAvailability: () =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_TAB,
      data: USER_AUTOCOMPLETE_TAB.AVAILABILITY,
    }),
  sortBySkills: () =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_TAB,
      data: USER_AUTOCOMPLETE_TAB.SKILLS,
    }),
  setInitialUsers: (data) =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_USERS,
      data,
    }),
  setAssignedUsers: (data) =>
    Object.assign({
      type: USER_AUTOCOMPLETE_SET_ASSIGNED_USERS,
      data,
    }),
};

export const BlueprintEditor = {
  setMeasurements: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_MEASUREMENTS,
      data,
    }),
  setIsMeasuring: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_IS_MEASURING,
      data,
    }),
  setPdfInfo: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_PDF_INFO,
      data,
    }),
  setFilePreviewVisible: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FILE_PREVIEW_VISIBLE,
      data,
    }),
  setShowEditBpModal: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SHOW_EDIT_MODAL,
      data,
    }),
  setShowHeightInput: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_SHOW_HEIGHT_INPUT,
      data,
    }),
  setShowWidthInput: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_SHOW_WIDTH_INPUT,
      data,
    }),
  resetMeasurements: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_RESET_MEASUREMENTS,
    }),
  setMeasurementsChecked: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_MEASUREMENTS_CHECKED,
      data,
    }),
  setFloatingInput: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FLOATING_INPUT,
      data,
    }),
  setTools: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_TOOLS,
      data,
    }),
  updateLayer: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_UPDATE_LAYER,
      data,
    }),
  changeTool: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_CHANGE_TOOL,
      data,
    }),
  changeMode: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_CHANGE_MODE,
      data,
    }),
  setInfoPanelData: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_INFO_PANEL_DATA,
      data,
    }),
  setCurrentLayer: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_LAYER,
      data,
    }),
  setExportImage: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_EXPORT_IMAGE,
      data,
    }),
  openLayerPanel: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_OPEN_LAYER_PANEL,
    }),
  closeLayerPanel: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_CLOSE_LAYER_PANEL,
    }),
  setApp: (app) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_KONVA_APP,
      data: app,
    }),
  updateHistory: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_UPDATE_HISTORY,
      data,
    }),
  undo: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_UNDO,
    }),
  redo: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_REDO,
    }),
  setFile: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_OPEN_FILE,
      data,
    }),
  setFileName: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FILENAME,
      data,
    }),
  setFileId: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FILE_ID,
      data,
    }),
  setFileOptions: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FILE_OPTIONS,
      data,
    }),
  addElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_ADD_ELEMENT,
      data,
    }),
  setElements: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_ELEMENTS,
      data,
    }),
  setPreviuosLayer: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_PREVIOUS_LAYER,
      data,
    }),
  updateElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_UPDATE_ELEMENT,
      data,
    }),
  removeElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_REMOVE_ELEMENT,
      data,
    }),
  setPdfResizing: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_PDF_RESIZING,
      data,
    }),
  setJobs: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_JOBS,
      data,
    }),
  setElementsInfoPanelOpen: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_ELEMENTS_INFO_PANEL,
      data,
    }),
  setSelectedElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_SELECTED_ELEMENT,
      data,
    }),
  toggleElementVisibility: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_TOGGLE_ELEMENT_VISIBILITY,
      data,
    }),
  reset: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_RESET,
      data,
    }),
  setShowFilters: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_SHOW_FILTERS,
      data,
    }),
  setQuestionOpen: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_QUESTION_OPEN,
      data,
    }),
  setDimensionsPanel: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_DIMENSIONS_COMPONENT,
      data,
    }),
  setImageRotation: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_IMAGE_ROTATION,
      data,
    }),
  setImageGrayscale: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_IMAGE_GRAYSCALE,
      data,
    }),
  setFilePage: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_FILE_PAGE,
      data,
    }),
  setMeasureIntroDisplayed: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_MEASURE_INTRO_DISPLAYED,
      data,
    }),
  setIsBpEditorOpen: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_BP_EDITOR_OPEN,
      data,
    }),
  selectElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SELECT_ELEMENT,
      data,
    }),
  selectLine: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SELECT_LINE,
      data,
    }),
  deselectElement: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_DESELECT_ELEMENT,
      data,
    }),
  setRightPanelJob: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_RIGHT_PANEL_JOB,
      data,
    }),
  setOpenPolygonSides: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_OPEN_POLYGON_SIDES,
      data,
    }),
  setPdfExportModalVisible: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_PDF_EXPORT_MODAL_VISIBLE,
      data,
    }),
  setCroppedDataUrl: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_CROPPED_DATA_URL,
      data,
    }),
  setCropSize: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_CROP_SIZE,
      data,
    }),
  setActiveCropStep: (data) =>
    Object.assign({
      type: BLUEPRINT_EDITOR_SET_ACTIVE_CROP_STEP,
      data,
    }),
  cancelCrop: () =>
    Object.assign({
      type: BLUEPRINT_EDITOR_CANCEL_CROP,
    }),
};

export const Shifts = {
  setUsers: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_USERS,
      data,
    }),
  setJobs: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_JOBS,
      data,
    }),
  setRange: ({ from, to }) =>
    Object.assign({
      type: SHIFT_VIEW_SET_RANGE,
      data: { from, to },
    }),
  setUserPanelMode: (mode) =>
    Object.assign({
      type: SHIFT_VIEW_SET_USER_PANEL_MODE,
      data: mode,
    }),
  setShowStatuses: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SHOW_STATUSES,
      data,
    }),
  setShowJobTypes: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SHOW_JOB_TYPES,
      data,
    }),
  toggleHideAssigned: () =>
    Object.assign({
      type: SHIFT_VIEW_TOGGLE_HIDE_ASSIGNED,
    }),
  setSearchPhrase: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SEARCH_PHRASE,
      data,
    }),
  setTempAssigned: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_TEMP_ASSIGNED,
      data,
    }),
  setTempAssignedDates: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_TEMP_ASSIGNED_DATES,
      data,
    }),
  setDropzones: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_DROPZONES,
      data,
    }),
  setDragger: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_DRAGGER,
      data,
    }),
  setDragged: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_DRAGGED,
      data,
    }),
  setRightPanelUser: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_RIGHT_PANEL_USER,
      data,
    }),
  setRightPanelJob: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_RIGHT_PANEL_JOB,
      data,
    }),
  setOutsideConfirm: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_OUTSIDE_CONFIRM,
      data,
    }),
  setOutsideConfirmJob: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_OUTSIDE_CONFIRM_JOB,
      data,
    }),
  setToBeAssignedTemp: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP,
      data,
    }),
  setToBeAssignedTempDates: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_TO_BE_ASSIGNED_TEMP_DATES,
      data,
    }),
  setIsAssigning: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_IS_ASSIGN,
      data,
    }),
  setAssignmentProgress: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_ASSIGNMENT_PROGRESS,
      data,
    }),
  setSelectedUser: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SELECTED_USER,
      data,
    }),
  setUserHover: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_USER_HOVER,
      data,
    }),
  setDropableCache: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_DROPPABLE_CACHE,
      data,
    }),
  setUserHoverAndDropzones: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_USER_HOVER_AND_DROPZONES,
      data,
    }),
  setUsersLoading: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_USERS_LOADING,
      data,
    }),
  setJobsLoading: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_JOBS_LOADING,
      data,
    }),
  setMiniView: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_MINI_VIEW,
      data,
    }),
  setShowShareModal: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SHARE_MODAL,
      data,
    }),
  setShareLink: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_SHARE_LINK,
      data,
    }),
  setOneDayView: (data) =>
    Object.assign({
      type: SHIFT_VIEW_SET_ONE_DAY_VIEW,
      data,
    }),
  loadData: (data) =>
    Object.assign({
      type: SHIFT_VIEW_LOAD_DATA,
      data,
    }),
};

export const UserLocation = {
  setLocation: (data) =>
    Object.assign({
      type: USER_LOCATION_SET_LOCATION,
      data,
    }),
};

export const CurrentWidget = {
  setWidget: (data) =>
    Object.assign({
      type: CURRENT_WIDGET_SET_WIDGET,
      data,
    }),
  resetWidget: () =>
    Object.assign({
      type: CURRENT_WIDGET_RESET_WIDGET,
      data: null,
    }),
  updateWidget: (data) =>
    Object.assign({
      type: CURRENT_WIDGET_UPDATE_WIDGET,
      data,
    }),
  setMobileTab: (data) =>
    Object.assign({
      type: CURRENT_WIDGET_SET_MOBILE_TAB,
      data,
    }),
};

export const InstanceWizard = {
  setDevProcedure: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_DEV_PROCEDURE,
      data,
    }),
  setEmail: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_EMAIL,
      data,
    }),
  setPhone: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_PHONE,
      data,
    }),
  setUserName: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_USER_NAME,
      data,
    }),
  setPassword: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_PASSWORD,
      data,
    }),
  setDb: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_DB,
      data,
    }),
  setInstanceName: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_INSTANCE_NAME,
      data,
    }),
  setIncognito: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_INCOGNITO,
      data,
    }),
  setAddYourself: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_ADD_YOURSELF,
      data,
    }),
  setToken: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_TOKEN,
      data,
    }),
  setEnableSso: (data) =>
    Object.assign({
      type: INSTANCE_WIZARD_ENABLE_SSO,
      data,
    }),
};

export const GridTable = {
  init: () =>
    Object.assign({
      type: TABLE_INIT,
    }),
  setType: (data) =>
    Object.assign({
      type: TABLE_SET_TYPE,
      data,
    }),
  setRows: (data) =>
    Object.assign({
      type: TABLE_SET_ROWS,
      data,
    }),
  setSelectedRows: (data) =>
    Object.assign({
      type: TABLE_SET_SELECTED_ROWS,
      data,
    }),
  setRightPanelOpen: (data) =>
    Object.assign({
      type: TABLE_RIGHT_PANEL_OPEN,
      data,
    }),
  setTotalPages: (data) =>
    Object.assign({
      type: TABLE_SET_TOTAL_PAGES,
      data,
    }),
  setTotal: (data) =>
    Object.assign({
      type: TABLE_SET_TOTAL,
      data,
    }),
  setCurrentPage: (data) =>
    Object.assign({
      type: TABLE_SET_CURRENT_PAGE,
      data,
    }),
  setPageSize: (data) =>
    Object.assign({
      type: TABLE_SET_PAGE_SIZE,
      data,
    }),
  setLoading: (data) =>
    Object.assign({
      type: TABLE_SET_LOADING,
      data,
    }),
  setSearch: (data) =>
    Object.assign({
      type: TABLE_SET_SEARCH,
      data,
    }),
  setActiveFilters: (data) =>
    Object.assign({
      type: TABLE_SET_ACTIVE_FILTERS,
      data,
    }),
  setHasDefaultFilters: (data) =>
    Object.assign({
      type: TABLE_SET_HAS_DEFAULT_FILTERS,
      data,
    }),
  setRightPanelWithQuestion: (data) =>
    Object.assign({
      type: TABLE_SET_RIGHT_PANEL_WITH_QUESTION,
      data,
    }),
  setWithRightPanel: (data) =>
    Object.assign({
      type: TABLE_SET_WITH_RIGHT_PANEL,
      data,
    }),
  setHasActiveFilter: (data) =>
    Object.assign({
      type: TABLE_SET_HAS_ACTIVE_FILTER,
      data,
    }),
  setGridTitle: (data) =>
    Object.assign({
      type: GRID_SET_TITLE,
      data
    }),
  setShowFiltersModal: (data) =>
    Object.assign({
      type: TABLE_SET_SHOW_FILTERS_MODAL,
      data,
    }),
  setTempFilterSelection: (data) =>
    Object.assign({
      type: TABLE_SET_TEMP_FILTER_SELECTION,
      data,
    }),
  setShowUsersModal: (data) =>
    Object.assign({
      type: TABLE_SET_SHOW_USERS_MODAL,
      data,
    }),
  setShowReportsBuilder: (data) =>
    Object.assign({
      type: TABLE_SET_SHOW_REPORTS_BUILDER,
      data,
    }),
  setShowWarningModal: (data) =>
    Object.assign({
      type: TABLE_SET_SHOW_WARNING_MODAL,
      data,
    }),
  setShowActionButtons: (data) =>
    Object.assign({
      type: TABLE_SET_SHOW_ACTION_BUTTONS,
      data,
    }),
  toggleSelectMode: (data) =>
    Object.assign({
      type: TABLE_SET_SELECT_MODE,
      data
    }),
  setProjectFieldsData: (data) => Object.assign({
    type: FILTERS_SET_PROJECT_FIELDS_DATA,
    data
  })
};

export const FilterViews = {
  setName: (data) =>
    Object.assign({
      type: FILTER_VIEWS_SET_NAME,
      data,
    }),
  setSavedFilters: (data) =>
    Object.assign({
      type: FILTER_VIEWS_SET_SAVED_FILTERS,
      data,
    }),
  setFiltersChecked: (data) =>
    Object.assign({
      type: FILTER_VIEWS_SET_CHECKED,
      data,
    }),
};

export const FiltersJobs = {
  setFilterInspector: (data) =>
    Object.assign({
      type: FILTERS_SET_INSPECTOR,
      data,
    }),
  setFilterMe: (data) =>
    Object.assign({
      type: FILTERS_SET_FILTER_ME,
      data,
    }),
  setFilterDate: (data) =>
    Object.assign({
      type: FILTERS_SET_DATE,
      data,
    }),
  setFilterStatus: (data) =>
    Object.assign({
      type: FILTERS_SET_STATUS,
      data,
    }),
  setFilterJobType: (data) =>
    Object.assign({
      type: FILTERS_SET_JOB_TYPE,
      data,
    }),
  setFilterQuestions: (data) =>
    Object.assign({
      type: FILTERS_SET_QUESTIONS,
      data,
    }),
  setFilterAnswers: (data) =>
    Object.assign({
      type: FILTERS_SET_ANSWERS,
      data,
    }),
  setFilterProject: (data) =>
    Object.assign({
      type: FILTERS_SET_PROJECT,
      data,
    }),
  setFilterProjectManagers: (data) =>
    Object.assign({
      type: FILTERS_SET_PROJECT_MANAGERS,
      data,
    }),
  setFilterMyProjects: (data) =>
    Object.assign({
      type: FILTERS_SET_MY_PROJECTS,
      data,
    }),
  setFilterMyProjectManager: (data) =>
    Object.assign({
      type: FILTERS_SET_MY_PROJECT_MANAGER,
      data,
    }),
  setFilterSkills: (data) =>
    Object.assign({
      type: FILTERS_SET_SKILLS,
      data,
    }),
  setFilterProjectFields: (data) =>
    Object.assign({
      type: FILTER_PROJECT_FIELDS,
      data,
    }),
  setSort: (data) =>
    Object.assign({
      type: FILTERS_SET_SORT,
      data,
    }),
  setShowProjectsModal: (data) =>
    Object.assign({
      type: FILTERS_SET_SHOW_PROJECTS_MODAL,
      data,
    }),
  setShowProjectManagersModal: (data) =>
    Object.assign({
      type: FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL,
      data,
    }),
  setShowSkillsModal: (data) =>
    Object.assign({
      type: FILTERS_SET_SHOW_SKILLS_MODAL,
      data,
    }),
};

export const Apps = {
  set: (data) =>
    Object.assign({
      type: APPS_SET_ENABLED,
      data,
    }),
};

export const Projects = {
  list: (data) =>
    Object.assign({
      type: PROJECTS_LIST,
      data,
    }),
  setShowActionButtons: (data) =>
    Object.assign({
      type: PROJECTS_SHOW_ACTION_BUTTONS,
      data,
    }),
};

export const MultiStepFilterReducer = {
  setShowModal: (data) =>
    Object.assign({
      type: MULTI_STEP_FILTER_SET_SHOW,
      data,
    }),
  setActiveStep: (data) =>
    Object.assign({
      type: MULTI_STEP_FILTER_SET_ACTIVE_STEP,
      data,
    }),
  setMultistepAnswers: (data) =>
    Object.assign({
      type: MULTI_STEP_SET_ANSWERS,
      data,
    }),
  setQuestionsList: (data) =>
    Object.assign({
      type: MULTI_STEP_SET_QUESTIONS_LIST,
      data,
    }),
  setProjects: (data) =>
    Object.assign({
      type: MULTI_STEP_SET_PROJECTS,
      data,
    }),
};

export const AutomationWizard = {
  setTopic: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_TOPIC,
      data,
    }),
  setTopics: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_TOPICS,
      data,
    }),
  setEvent: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_EVENT,
      data,
    }),
  setInitialEvent: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_INITIAL_EVENT,
      data,
    }),
  setAutomationId: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ID,
      data,
    }),
  setConditionOptions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONDITION_OPTIONS,
      data,
    }),
  setConditions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONDITIONS,
      data,
    }),
  setRequiredConditions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_REQUIRED_CONDITIONS,
      data,
    }),
  setConditionsConfig: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONDITIONS_CONFIG,
      data,
    }),
  setLastStep: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_LAST_STEP,
      data,
    }),
  setAction: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTION,
      data,
    }),
  setActionChanged: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTION_CHANGED,
      data,
    }),
  setActions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTIONS,
      data,
    }),
  setActionsConfig: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTIONS_CONFIG,
      data,
    }),
  setActionsConfigInfo: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO,
      data,
    }),
  setName: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_NAME,
      data,
    }),
  setConnectedData: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONNECTED_DATA,
      data,
    }),
  setConnectedDataRequest: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST,
      data,
    }),
  setReceivers: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_RECEIVERS,
      data,
    }),
  setTemplateParams: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS,
      data,
    }),
  setConditionQuestions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS,
      data,
    }),
  setActionQuestions: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_ACTION_QUESTIONS,
      data,
    }),
  setTestUrl: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_TEST_URL,
      data,
    }),
  setConditionsChecked: (data) =>
    Object.assign({
      type: AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED,
      data,
    }),
  reset: () =>
    Object.assign({
      type: AUTOMATION_WIZARD_RESET,
    }),
};

export const FiltersBlueprints = {
  resetFilters: () =>
    Object.assign({
      type: FILTERS_BP_RESET,
    }),
  setFilterUser: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_USER,
      data,
    }),
  setFilterDate: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_DATE,
      data,
    }),
  setFilterJobType: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_JOB_TYPE,
      data,
    }),
  setFilterElement: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_ELEMENT,
      data,
    }),
  setFilterStatus: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_STATUS,
      data,
    }),
  setFilterJobId: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_JOB_ID,
      data,
    }),
  setFiltersChanged: (data) =>
    Object.assign({
      type: FILTERS_BP_SET_FILTERS_CHANGED,
      data,
    }),
};

export const Questions = {
  setActiveQuestion: (data) =>
    Object.assign({
      type: QUESTIONS_ACTIVE_QUESTION,
      data,
    }),
  toggleMailSection: (data) =>
    Object.assign({
      type: QUESTIONS_TOGGLE_MAIL_SECTION,
      data,
    }),
  setAnswers: (data) =>
    Object.assign({
      type: QUESTIONS_SET_ANSWERS,
      data,
    }),
  setPublicStartNodeId: (data) =>
    Object.assign({
      type: QUESTIONS_SET_PUBLIC_START_NODE_ID,
      data,
    }),
};

export const PopupWidget = {
  setWidgetCode: (data) =>
    Object.assign({
      type: SET_WIDGET_CODE,
      data,
    }),
};

export const JobTableRow = {
  setAnswersModal: (data) =>
    Object.assign({
      type: JOB_ROW_SET_ANSWERS_MODAL,
      data,
    }),
};

export const Sso = {
  setSettings: (data) =>
    Object.assign({
      type: SSO_SET_SETTINGS,
      data,
    }),
};

export const Util = {
  bpListVisible: (data) =>
    Object.assign({
      type: 'BP_LIST_VISIBLE',
      data,
    }),
}
