import { ROLE } from "../services/roles";
import { APP_FILES, APP_GAMES, APP_PROJECTS, APP_SHIFT_VIEW } from "./app";
import withMuiIcon from "../hoc/withMuiIcon";
import PieChartIcon from "@material-ui/icons/PieChart";
import WorkIcon from "@material-ui/icons/Work";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CreateIcon from "@material-ui/icons/Create";
import GroupIcon from "@material-ui/icons/Group";
import BarChartIcon from "@material-ui/icons/BarChart";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PowerIcon from "@material-ui/icons/Power";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

export default class MenuService {
  static getItems() {
    return [
      {
        label: "default:_DASHBOARD",
        url: "/dashboard",
        icon: " icon-projects",
        muiIcon: withMuiIcon(PieChartIcon),
      },
      {
        label: "default:_JOBS",
        url: "/jobs",
        icon: "icon-case",
        muiIcon: withMuiIcon(WorkIcon),
      },
      {
        label: "default:_PROJECTS",
        url: "/projects",
        icon: "icon-note",
        muiIcon: withMuiIcon(FormatListBulletedIcon),
      },
      {
        label: "default:_SIDE_BAR_JOB_TYPE",
        url: null,
        icon: "icon-menu_2",
        subItems: true,
        subMenuListProp: "jobType",
        subMenuRenderer: "renderJobTypeSubMenu",
        allowTo: [ROLE.SYSTEM_ADMIN, ROLE.BUSINESS_ADMIN],
        muiIcon: withMuiIcon(CreateIcon),
      },
      {
        label: "default:_USERS",
        url: "/users",
        icon: "icon-group_users",
        allowTo: [ROLE.SYSTEM_ADMIN, ROLE.BUSINESS_ADMIN, ROLE.PROJECT_MANAGER],
        muiIcon: withMuiIcon(GroupIcon),
      },
      {
        label: "default:_REPORTS",
        url: "/reports",
        icon: "icon-line-chart",
        muiIcon: withMuiIcon(BarChartIcon),
      },
      {
        label: "default:_FILES",
        url: "/files",
        icon: "icon-clipboard_report",
        allowTo: [ROLE.SYSTEM_ADMIN, ROLE.BUSINESS_ADMIN, ROLE.PROJECT_MANAGER],
        muiIcon: withMuiIcon(AssignmentIndIcon),
      },
      {
        label: "default:_APPS",
        url: null,
        icon: "mdi d-block mdi-power-plug",
        subItems: true,
        subMenuListProp: "apps",
        subMenuRenderer: "renderApps",
        muiIcon: withMuiIcon(PowerIcon),
      },
    ];
  }

  static getApps() {
    return [
      {
        label: "default:_SHIFT_VIEW",
        url: "/shifts",
        icon: "icon-clipboard_report",
        allowTo: [ROLE.SYSTEM_ADMIN, ROLE.BUSINESS_ADMIN, ROLE.PROJECT_MANAGER],
        muiIcon: withMuiIcon(DateRangeIcon),
      },
      {
        label: "default:_GAMES",
        url: "/games",
        icon: "mdi d-block mdi-gamepad-variant",
        muiIcon: withMuiIcon(SportsEsportsIcon),
      },
      {
        label: "default:_SKILLS",
        url: "/settings/skills",
        icon: "mdi d-block mdi-shield",
        muiIcon: withMuiIcon(VerifiedUserIcon),
        guardFunc: (user) => {
          return user.canManageSkills;
        },
      },
      {
        label: "default:_AUTOMATIONS",
        url: "/settings/automations",
        icon: "mdi d-block mdi-settings-box",
        allowTo: [ROLE.SYSTEM_ADMIN, ROLE.BUSINESS_ADMIN],
        muiIcon: withMuiIcon(SettingsApplicationsIcon),
      },
    ];
  }

  static filterDisabledMenus(item, apps, menuPrefix, userData, settings) {
    //check if app for the menu item is disabled and filter out if so
    if (
      item.label === `${menuPrefix ? menuPrefix : "default:"}_FILES` &&
      !apps.enabled.includes(APP_FILES)
    ) {
      return false;
    }
    if (
      item.label === `${menuPrefix ? menuPrefix : "default:"}_GAMES` &&
      !apps.enabled.includes(APP_GAMES)
    ) {
      return false;
    }
    if (
      item.label === `${menuPrefix ? menuPrefix : "default:"}_PROJECTS` &&
      !apps.enabled.includes(APP_PROJECTS)
    ) {
      return false;
    }
    if (
      item.label === `${menuPrefix ? menuPrefix : "default:"}_PROJECTS` &&
      userData &&
      userData.profile &&
      userData.profile.role === ROLE.INSPECTOR &&
      settings &&
      settings.security.allowInspectorManageProjects === false
    ) {
      return false;
    }
    if (
      item.label === `${menuPrefix ? menuPrefix : "default:"}_SHIFT_VIEW` &&
      !apps.enabled.includes(APP_SHIFT_VIEW)
    ) {
      return false;
    }
    return true;
  }
}
