export const APP_CLIENT = "client";
export const APP_REPORT_TEMPLATES = "report_templates";
export const APP_TEMPLATES = "templates";
export const APP_STEPS = "steps";
export const APP_FILES = "files";
export const APP_SKILLS = "skills";
export const APP_GAMES = "games";
export const APP_SSO = "sso";
export const APP_GOOGLE_SSO = "google_sso";
export const APP_MICROSOFT_SSO = "microsoft_sso";
export const APP_IMPERSONATE = "impersonate";
export const APP_PREVIOUS_JOB = "previous_job";
export const APP_BLUEPRINTS = "blueprints";
export const APP_JOBS = "jobs";
export const APP_ANSWERS_RECALL = "answers_recall";
export const APP_FILTER_VIEWS = "filter_views";
export const APP_PROJECTS = "projects";
export const APP_SHIFT_VIEW = "shift_view";
export const APP_SENDGRID = "sendgrid";
