import {
  WIZARD_JOB_SELECT_PROJECT,
  WIZARD_JOB_SELECT_JOB_TYPE,
  WIZARD_JOB_SELECT_START_DATE,
  WIZARD_JOB_ASSIGN_USERS,
  WIZARD_JOB_ANSWER_MAIN_QUESTIONS,
  WIZARD_JOB_RESET,
  WIZARD_JOB_SET_ID,
  WIZARD_JOB_SET_MAIN_QUESTIONS,
  WIZARD_JOB_SELECT_PREV_JOB,
  WIZARD_JOB_SELECT_NEXT_JOB,
  WIZARD_JOB_SET_SKILLS,
  WIZARD_JOB_SET_LINK_ID,
} from "../constants";

const initialState = {
  id: null,
  jobType: null,
  project: null,
  prevJob: null,
  nextJob: null,
  startDate: null,
  users: [],
  mainQuestions: [],
  answersMain: [],
  skills: [],
  linkId: null,
};

const wizardJob = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case WIZARD_JOB_SELECT_PROJECT:
    case WIZARD_JOB_SELECT_JOB_TYPE:
    case WIZARD_JOB_SELECT_START_DATE:
    case WIZARD_JOB_ASSIGN_USERS:
    case WIZARD_JOB_ANSWER_MAIN_QUESTIONS:
    case WIZARD_JOB_SET_ID:
    case WIZARD_JOB_SET_LINK_ID:
    case WIZARD_JOB_SET_MAIN_QUESTIONS:
    case WIZARD_JOB_SELECT_PREV_JOB:
    case WIZARD_JOB_SELECT_NEXT_JOB:
    case WIZARD_JOB_SET_SKILLS:
      return Object.assign({}, state, data);
    case WIZARD_JOB_RESET:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default wizardJob;
