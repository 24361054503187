import React, { useEffect, useCallback } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { STEP_JOB_CREATED } from "../steps";
import { Wizard, WizardJob } from "../../../redux/actions";
import { addPrevious, getJobsList } from "../../../services/api/jobs";
import AutoSelect from "../../autoselect/autoselect";
//import { WIZARD_TYPE_JOB } from "../constants";
import { getFileTarget } from "../../../services/api/file_upload";

function SelectPrevJob({ goToStep, wizardJob, dispatch, isActive, dismiss }) {
  const { prevJob, id } = wizardJob;

  const requestURL = {
    get: async (params) => {
      const jobs = await getJobsList(params);
      return jobs.docs && jobs.docs.length > 0
        ? jobs.docs.map(({ _id, ID, jobType }) => {
            return {
              _id,
              name: "#" + ID,
              avatar: !jobType.icon ? (
                <i className="mdi mdi-briefcase-outline circle" />
              ) : (
                <img
                  src={getFileTarget(jobType.icon, "100x100")}
                  alt={jobType.title}
                />
              ),
            };
          })
        : [];
    },
  };
  const onJobSelect = useCallback(
    (val) => {
      let dispatchValue = { prevJob: null };
      if (val.length > 0) {
        dispatchValue = { prevJob: val };
      }
      dispatch(WizardJob.setPrevJob(dispatchValue));
    },
    [dispatch]
  );

  const updateJob = useCallback(async () => {
    if (prevJob && prevJob.length > 0) {
      for (let index = 0; index < prevJob.length; index++) {
        const job = prevJob[index];
        if (job) await addPrevious(id, job.value);
      }
    }
  }, [prevJob, id]);

  useEffect(() => {
    if (isActive)
      dispatch(
        Wizard.setTitle(
          i18n.t("default:_SELECT_PREVIOUS_JOB", {
            jobWord: i18n.t("default:_JOBS"),
          })
        )
      );
  }, [dispatch, isActive]);

  return (
    <div
      className="SelectProject ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 10, width: "100%", maxWidth: 400, marginTop: 10 }}>
        <div className="form-group">
          <AutoSelect
            selectedSuggestions={prevJob}
            requestUrl={requestURL}
            noInitialRender={true}
            onChange={(val) => onJobSelect(val)}
            disableAdd={true}
          />
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: async () => {
              await updateJob();
              goToStep(STEP_JOB_CREATED);
            },
            variant: "white",
          },
          {
            label: i18n.t("default:_DONE"),
            onClick: async () => {
              await updateJob();
              goToStep(STEP_JOB_CREATED);
            },
            variant: "black",
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(SelectPrevJob, ["wizardJob", "user"]);
