import React, { Component } from "react";
import { connectWithStore } from "../../../services/redux";
import "./logo.scss";
import { getFileTarget } from "../../../services/api/file_upload";

const logo = "/assets/img/logos/logo.png";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        {!this.props.collapsed && (
          <img
            src={
              this.props.settings &&
              this.props.settings.settings &&
              this.props.settings.settings.logo
                ? getFileTarget(this.props.settings.settings.logo, "100x100")
                : logo
            }
            width={this.props.width ? this.props.width : "200"}
            alt=""
          />
        )}
        {!this.props.hideSecondary &&
          this.props.settings &&
          this.props.settings.secondaryLogoUrl && (
            <img
              alt="Samurai for business"
              className={`logoWatermark ${
                this.props.collapsed ? "pull-right" : ""
              }`}
              src={
                this.props.collapsed
                  ? getFileTarget(
                      this.props.settings.secondaryLogoUrl,
                      "100x100"
                    )
                  : "./logo.default.png"
              }
            />
          )}
        {!this.props.hideSecondary &&
          (!this.props.settings || !this.props.settings.secondaryLogoUrl) && (
            <img
              alt="Samurai for business"
              className={`logoWatermark ${
                this.props.collapsed ? "pull-right" : ""
              }`}
              src={"/logo.default.png"}
            />
          )}
      </div>
    );
  }
}
export default connectWithStore(Logo, ["settings"]);
