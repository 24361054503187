import Http from "../http";
import { STORAGE_KEY } from "../../redux/local_storage";

export const toggleFeature = async (id) => {
  const path = `/v2/apps/toggles/${id}`;

  try {
    const response = await Http.sharedInstance.withAuth().put(path);

    return response.data;
  } catch (e) {
    return null;
  }
};

export const getFeatureToggles = async () => {
  const path = "/v2/apps/toggles";

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return [];
  }
};

export const getFeatureSettings = async (params) => {
  const path = `/v2/apps/${params.toggleId}/settings`;

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return [];
  }
};

export const setFeatureSettings = async (params, id) => {
  const path = `/v2/apps/${id}/settings`;

  try {
    const response = await Http.sharedInstance
      .withAuth()
      .post(path, { ...params });

    return response.data;
  } catch (e) {
    return [];
  }
};

export const getAppSettings = async (params) => {
  const path = `/v2/apps/bootstrap`;
  const storeState = localStorage.getItem(STORAGE_KEY);
  if (storeState) {
    const state = JSON.parse(storeState);
    if (
      state &&
      state.user &&
      state.user.access_token &&
      state.user.access_token.accessToken
    ) {
      const response = await Http.sharedInstance.withAuth().get(path, {
        params,
      });

      return response.data;
    }
  }
  const response = await Http.sharedInstance.getClient().get(path, {
    params: params,
  });
  return response.data;
};

export const getSettings = async (params) => {
  const path = `/v2/settings`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateSettings = async (params) => {
  const path = `/v2/settings`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateProjectFields = async (params) => {
  const path = `/v2/settings/projectFields`;
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateBlueprints = async (params) => {
  const path = "/v2/settings/blueprints";
  try {
    const response = await Http.sharedInstance.withAuth().put(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getGDriveConnectUrl = async () => {
  const path = "/v2/settings/connect_drive";
  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {};
  }
};

export const isGDriveConnected = async () => {
  const path = "/v2/settings/is_connect_drive";

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {};
  }
};

export const disconnectDrive = async () => {
  const path = "/v2/settings/disconnect_drive";

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return {};
  }
};
