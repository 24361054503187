import React, { useEffect, useRef } from "react";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { MOVE_TOOL, LAMP_TOOL } from "../constants";
import store from "../../../../redux/store";
import { BlueprintEditor } from "../../../../redux/actions";
import {
  addNewElementToJob,
  convertMeterToPixels,
  getRelativePointerPosition,
  canUserEditElement,
  canProceedEditingElement,
} from "../utils";
import { v1 as uuid } from "uuid";
import i18n from "../../../../i18n";

const ELEMENT_NAME = "Lamp";

function LampTool({ blueprintEditor, dispatch, question, job }) {
  const { app } = blueprintEditor;
  let transformer = useRef();

  useEffect(() => {
    if (app) {
      app.on("mousedown touchstart", (e) => {
        const state = store.getState();
        const { blueprintEditor } = state;
        const { tool } = blueprintEditor;
        if (tool && tool.type !== LAMP_TOOL) {
          return;
        }
        if (e.target !== app && e.target.id() !== "blueprint") {
          return;
        }
      });

      app.on("click tap", function (e) {
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool, layer, mode } = blueprintEditor;
        const { profile } = user;
        if (
          transformer &&
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          transformer.current.nodes([]);
          layer.batchDraw();
          //return;
        }

        if (tool && tool.type !== MOVE_TOOL && tool.type !== LAMP_TOOL) {
          return;
        }
        if (canProceedEditingElement(mode, tool)) {
          if (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name.includes("Layer")))
            dispatch(
              BlueprintEditor.updateHistory(tool.element && tool.element.id)
            );
        } else {
          return;
        }
        // if click on empty area - remove all selections
        if (
          (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name.includes("Layer"))) &&
          tool.type === LAMP_TOOL
        ) {
          const pos = getRelativePointerPosition(app);
          const id = uuid();
          const width = convertMeterToPixels(tool.width) / 5;
          const height = convertMeterToPixels(tool.width) / 5;
          Konva.Image.fromURL(require("./lightbulb.png"), (image) => {
            image.setAttrs({
              width: width,
              height: height,
              x: pos.x,
              y: pos.y,
              name: ELEMENT_NAME,
              id: id,
              draggable: true,
            });
            image.hitStrokeWidth(0);
            image.shadowForStrokeEnabled(false);
            layer.add(image);
            dispatch(BlueprintEditor.setSelectedElement(image));
            transformer.current = new Konva.Transformer({
              nodes: [image],
            });
            layer.add(transformer.current);
            layer.batchDraw();
            const updatedJobs = addNewElementToJob(
              JSON.parse(image.toJSON()),
              ELEMENT_NAME,
              tool,
              id,
              blueprintEditor
            );
            dispatch(BlueprintEditor.setJobs(updatedJobs));
            image.on("dragstart", () => {
              if (
                blueprintEditor.mode !== "EDIT" ||
                !(
                  transformer &&
                  transformer.current &&
                  transformer.current._nodes &&
                  transformer.current._nodes.length > 0
                )
              ) {
                image.stopDrag();
              } else {
                dispatch(BlueprintEditor.setSelectedElement(image));
              }
            });
            image.on("transform", () => {
              image.setAttrs({
                width: Math.max(image.width() * image.scaleX(), 5),
                height: Math.max(image.height() * image.scaleY(), 5),
                scaleX: 1,
                scaleY: 1,
              });
            });
            image.on("transformend", () => {
              dispatch(
                BlueprintEditor.updateElement({
                  id: image.getAttr("id"),
                  elementId: tool.element.id,
                  user: profile,
                  visible: true,
                  job,
                })
              );
              dispatch(BlueprintEditor.updateHistory());
            });
            image.on("dragend", () => {
              dispatch(
                BlueprintEditor.updateElement({
                  id: image.getAttr("id"),
                  elementId: tool.element.id,
                  user: profile,
                  visible: true,
                  job,
                })
              );
              dispatch(BlueprintEditor.updateHistory());
            });
            dispatch(
              BlueprintEditor.addElement({
                id,
                elementId: tool.element.id,
                user: profile,
                job,
              })
            );
            dispatch(
              BlueprintEditor.changeTool({
                type: MOVE_TOOL,
                color: "#000",
                name: i18n.t("default:_MOVE_TOOL"),
              })
            );
            return;
          });
        }
        if (
          e.target &&
          e.target.attrs &&
          e.target.attrs.name === ELEMENT_NAME
        ) {
          const selectedElementArr = blueprintEditor.elements.filter(
            (el) => el.id === e.target.attrs.id
          );
          const elementJob =
            selectedElementArr.length > 0 ? selectedElementArr[0].job : null;
          if (!canUserEditElement(elementJob, job, question)) {
            return;
          }
          if (!transformer.current) {
            transformer.current = new Konva.Transformer({
              nodes: [e.target],
            });
            layer.add(transformer.current);
          } else {
            transformer.current.nodes([e.target]);
          }
          layer.batchDraw();
          dispatch(BlueprintEditor.selectElement(e.target));
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [app, dispatch]);
  return <div />;
}

export default connectWithStore(LampTool, ["blueprintEditor"]);
