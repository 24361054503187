import i18n from "../../../i18n";

export const FIELD_TYPES = {
  TEXT: 1,
  DATE: 2,
  NUMBER: 3,
  FILE: 4,
  TABLE: 5,
  BOOLEAN: 6,
  ADDRESS: 7,
  TEXT_WITH_FILES: 8,
  USER_SELECTION: 9,
  SELECT: 10,
  RICH_TEXT: 11,
  MULTI_SELECT: 12,
};

const SHOE_SIZES = [38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48];
const CLOTHING_SIZES = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
const DRIVING_LICENSE_CATEGORIES = [
  "A",
  "1A",
  "2A",
  "B",
  "1C",
  "C",
  "D",
  "1D",
  "2D",
  "3D",
  "E1",
  "other",
];

export const USER_FIELDS = {
  /* FULL_NAME: {
    name: i18n.t("default:_NAME"),
    value: "full_name",
  }, */
  EMAIL: {
    name: i18n.t("default:_USER_EMAIL"),
    value: "email",
  },
  DATE_OF_BIRTH: {
    name: i18n.t("default:_DATE_OF_BIRTH"),
    value: "dateOfBirth",
    type: FIELD_TYPES.DATE,
  },
  ID: {
    name: i18n.t("default:_PERSONAL_ID"),
    value: "personalId",
    type: FIELD_TYPES.TEXT_WITH_FILES,
  },
  DRIVING_LICENSE: {
    name: i18n.t("default:_DRIVING_LICENSE"),
    value: "drivingLicense",
    type: FIELD_TYPES.FILE,
  },
  DRIVING_LICENSE_CATEGORIES: {
    name: i18n.t("default:_DRIVING_LICENSE_CATEGORIES"),
    value: "drivingLicenseCategories",
    type: FIELD_TYPES.MULTI_SELECT,
    options: DRIVING_LICENSE_CATEGORIES,
  },
  FORM_101: {
    name: i18n.t("default:_FORM_101"),
    value: "form101",
    type: FIELD_TYPES.FILE,
  },
  CV: {
    name: i18n.t("default:_CV"),
    value: "cv",
    type: FIELD_TYPES.FILE,
  },
  HOME_ADDRESS: {
    name: i18n.t("default:_HOME_ADDRESS"),
    value: "homeAddress",
    type: FIELD_TYPES.ADDRESS,
  },
  PHONE: {
    name: i18n.t("default:_PHONE"),
    value: "phoneNumber",
    type: FIELD_TYPES.TEXT,
  },
  MA_COMPLETE: {
    name: i18n.t("default:_MA_COMPLETE"),
    value: "maComplete",
    type: FIELD_TYPES.DATE,
  },
  BANK_DETAILS: {
    name: i18n.t("default:_BANK_DETAILS"),
    value: "bankDetails",
    type: FIELD_TYPES.TEXT,
  },
  START_DATE: {
    name: i18n.t("default:_BEGINNING_OF_EMPLOYMENT"),
    value: "startDate",
    type: FIELD_TYPES.DATE,
  },
  EMPLOYEE_RECOMMENDATION: {
    name: i18n.t("default:_EMPLOYEE_RECOMMENDATION"),
    value: "employeeRecommendation",
    type: FIELD_TYPES.USER_SELECTION,
  },
  PROJECTS: {
    name: i18n.t("default:_PROJECTS"),
    value: "projects",
  },
  SKILLS: {
    name: i18n.t("default:_SKILLS"),
    value: "skills",
  },
  PENSION: {
    name: i18n.t("default:_PENSION"),
    value: "pension",
    type: FIELD_TYPES.BOOLEAN,
  },
  EMPLOYEE_AGREEMENT: {
    name: i18n.t("default:_EMPLOYEE_AGREEMENT"),
    value: "employeeAgreement",
    type: FIELD_TYPES.BOOLEAN,
  },
  WORK_SAFETY_PERMIT: {
    name: i18n.t("default:_WORK_SAFETY_PERMIT"),
    value: "workSafetyPermit",
    type: FIELD_TYPES.FILE,
  },
  SHOE_SIZE: {
    name: i18n.t("default:_SHOE_SIZE"),
    value: "shoeSize",
    type: FIELD_TYPES.SELECT,
    options: SHOE_SIZES,
  },
  SHIRT_SIZE: {
    name: i18n.t("default:_SHIRT_SIZE"),
    value: "shirtSize",
    type: FIELD_TYPES.SELECT,
    options: CLOTHING_SIZES,
  },
  COAT_SIZE: {
    name: i18n.t("default:_COAT_SIZE"),
    value: "coatSize",
    type: FIELD_TYPES.SELECT,
    options: CLOTHING_SIZES,
  },
  HARNESS_NUMBER: {
    name: i18n.t("default:_HARNESS_NUMBER"),
    value: "harnessNumber",
    type: FIELD_TYPES.TEXT,
  },
  ITEMS: {
    name: i18n.t("default:_ITEMS"),
    value: "items",
    type: FIELD_TYPES.RICH_TEXT,
  },
  FILES: {
    name: i18n.t("default:_FILES"),
    value: "files",
  },
};

export const USER_FIELDS_ACCESS = {
  full_name: {},
  email: {},
};

/* const CATEGORIES = {
  PERSONAL_DATA: 1,
  WORK_RELATIONS: 2,
  EQUIPMENT: 3,
}; */
