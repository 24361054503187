import React from "react";
import { QuestionType } from "../../services/question";
import DateService from "../../services/date";
import i18n from "../../i18n";
import { getFileTarget } from "../../services/api/file_upload";
import RichTextRenderer from "../../components/question/rich-text-renderer";
import { ROLE } from "../../services/roles";
import ReactTooltip from "react-tooltip";

const avatar = require("../../assets/img/project-placeholder.png");

function AnswerHistory({ currentAnswer, answers, currentUserLang, user }) {
  /* const addAnswerHistoryEventListeners = (ev) => {
    const targetName = ev.target.getAttribute("data-target");
    const targetItem = document.querySelector(
      '[data-id*="' + targetName + '"]'
    );
    if (targetItem) {
      if (targetItem.classList.contains("show")) {
        targetItem.classList.remove("show");
        return;
      }
      targetItem.classList.add("show");
    }
  }; */

  const renderAnswerHistoryData = (answers, getValue, k) => {
    return (
      <div
        className="UserAnswers px-2"
        style={{ width: "30%" }}
        key={"history_" + k}
      >
        {answers.map((history, key) => {
          if (!history) {
            return "";
          }
          let value = history.value;

          if (typeof getValue === "function") {
            value = getValue.apply(this, [history, answers]);
          }

          if (Array.isArray(value)) {
            return value.map((item, i) => {
              if (typeof item === "object") {
                if (item.hasOwnProperty("avatar") && item.value) {
                  return (
                    <div className="d-flex flex-row w-100" key={i}>
                      <div className="ListComponent-AvatarCircle">
                        {item.avatar && (
                          <img
                            src={getFileTarget(item.avatar, "100x100")}
                            alt={item.value}
                          />
                        )}
                        {!item.avatar && <img src={avatar} alt={item.value} />}
                      </div>
                      <p
                        dir="auto"
                        className="UserAnswers-item w-100 mb-0 mt-2 ml-2"
                        data-tip={item.value}
                      >
                        {item.value}
                      </p>
                    </div>
                  );
                }

                if (item.value) {
                  return (
                    <p
                      dir="auto"
                      className="UserAnswers-item w-100 mb-0"
                      data-tip={item.value}
                      key={i}
                    >
                      {item.value}
                    </p>
                  );
                }

                return "";
              }
              return (
                <p
                  dir="auto"
                  className="UserAnswers-item w-100 mb-0"
                  data-tip={item}
                  key={i}
                >
                  {item}
                </p>
              );
            });
          } else if (typeof value === "object") {
            if (value.hasOwnProperty("avatar") && value.value) {
              return (
                <div key={key} className="d-flex flex-row">
                  <div className="ListComponent-AvatarCircle">
                    {value.avatar && (
                      <img
                        src={getFileTarget(value.avatar, "100x100")}
                        alt={value.value}
                      />
                    )}
                    {!value.avatar && <img src={avatar} alt={value.value} />}
                  </div>
                  <p
                    dir="auto"
                    className="UserAnswers-item w-100 mb-0 ml-2"
                    data-tip={value.value}
                  >
                    {value.value}
                  </p>
                </div>
              );
            }

            if (value.value) {
              return (
                <p
                  dir="auto"
                  data-tip={value.value}
                  className="UserAnswers-item w-100"
                >
                  {value.value}
                </p>
              );
            }

            return "";
          }

          return (
            <p
              key={key}
              dir="auto"
              data-tip={value}
              className="UserAnswers-item w-100"
            >
              {value}
            </p>
          );
        })}
      </div>
    );
  };

  const renderAnswerHistory = (
    answers,
    currentUserLang,
    getValue,
    mainUser
  ) => {
    const users = answers.reduce(function (rv, x) {
      (rv[x.name] = rv[x.name] || []).push(x);
      return rv;
    }, {});

    /* const getUser = (userName) => {
      const targetItem = answers.filter((a) => a.name === userName);

      if (targetItem && targetItem.length > 0) {
        return targetItem[0];
      }

      return userName;
    }; */

    if (users && Object.keys(users).length > 0) {
      return Object.keys(users).map((user, index) => {
        return (
          <div
            key={index}
            className="answer-history-block d-block w-100 text-left"
          >
            <ReactTooltip effect="solid" />
            <div className="answer-history-data">
              <div style={{ width: "70%" }}>
                {users[user] &&
                  users[user].length > 0 &&
                  users[user].map((answer, i) => {
                    return (
                      <div key={i} className="user-data">
                        <p dir="auto" className="UserAnswers-user">
                          <span data-tip={answer.email}>{answer.name}</span>
                          <span className="history-date">{`(${DateService.getFormattedDate(
                            answer.date
                          )})`}</span>
                        </p>
                        {answer.location &&
                          mainUser &&
                          mainUser.profile &&
                          mainUser.profile.role === ROLE.SYSTEM_ADMIN && (
                            <div
                              data-tip={i18n.t(
                                "default:_APPROXIMATE_LOCATION_CLICK_TO_SEE"
                              )}
                              className="JobPanelQuestion-location"
                              style={{ marginLeft: "5px" }}
                            >
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://www.google.com/maps/search/?api=1&query=${answer.location.lat},${answer.location.long}`}
                              >
                                <i className="mdi mdi-map-marker" />
                              </a>
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
              {renderAnswerHistoryData(users[user], getValue, index)}
            </div>
          </div>
        );
      });
    }

    return "";
  };

  const getAnswerHistory = (answer, userAnswers, currentUserLang, user) => {
    const isInAppropriate = (val) => {
      return typeof val === "object";
    };
    const value = answer ? answer.value : null;
    if (!answer) {
      return value;
    }

    let preformatedValue;
    switch (answer.question.type) {
      case QuestionType.DATE_TIME:
        if (typeof value == "object" && value !== null) {
          if (
            answer.question.settings.isRange &&
            !answer.question.settings.isPunchClock
          ) {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <div className="dialog-tooltip-content">
                    {userAnswers &&
                      renderAnswerHistory(
                        userAnswers,
                        currentUserLang,
                        (history) => {
                          let newValue;
                          if (history.value) {
                            newValue =
                              DateService.getFormattedDay(history.value.date) +
                              ", " +
                              DateService.getFormattedTime(
                                history.value.from,
                                "HH:mm"
                              ) +
                              " - " +
                              DateService.getFormattedTime(
                                history.value.to,
                                "HH:mm"
                              );
                          } else {
                            newValue = "";
                          }

                          return newValue;
                        },
                        user
                      )}
                  </div>
                </div>
              </div>
            );
          } else if (
            answer.question.settings.isRange &&
            answer.question.settings.isPunchClock
          ) {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <div className="dialog-tooltip-content">
                    {userAnswers &&
                      renderAnswerHistory(
                        userAnswers,
                        currentUserLang,
                        (history) => {
                          let value = history.value;
                          if (
                            history.value &&
                            typeof history.value === "object"
                          ) {
                            value = `${DateService.getFormattedDate(
                              history.value.punchStart
                            )}`;
                          }

                          return value;
                        },
                        user
                      )}
                  </div>
                </div>
              </div>
            );
          } else {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <div className="dialog-tooltip-content">
                    {userAnswers &&
                      renderAnswerHistory(
                        userAnswers,
                        currentUserLang,
                        (history) => {
                          let value = history.value;
                          if (
                            history.value &&
                            typeof history.value === "object"
                          ) {
                            value = `${DateService.getFormattedDate(
                              history.value.date
                            )}`;
                          }

                          return value;
                        },
                        user
                      )}
                  </div>
                </div>
              </div>
            );
          }
        } else {
          preformatedValue = (
            <div>
              <div className="dialog-tooltip">
                <div className="dialog-tooltip-content">
                  {userAnswers &&
                    renderAnswerHistory(
                      userAnswers,
                      currentUserLang,
                      (history) => {
                        let value = history.value;
                        if (
                          history.value &&
                          typeof history.value === "object"
                        ) {
                          value = history.value.value;
                        }

                        return value;
                      },
                      user
                    )}
                </div>
              </div>
            </div>
          );
        }
        break;
      case QuestionType.LIST:
        if (value) {
          preformatedValue = (
            <div className="ListComponent">
              {userAnswers &&
                renderAnswerHistory(
                  userAnswers,
                  currentUserLang,
                  (history) => {
                    return history.value.value || history.value;
                  },
                  user
                )}
            </div>
          );
        }
        break;
      case QuestionType.SYSTEM_LIST:
      case QuestionType.PROJECT_LIST:
        if (value) {
          preformatedValue = (
            <div className="ListComponent">
              {userAnswers &&
                renderAnswerHistory(
                  userAnswers,
                  currentUserLang,
                  (history) => {
                    return history.value;
                  },
                  user
                )}
            </div>
          );
        }
        break;
      case QuestionType.BOOL:
        if (value) {
          preformatedValue = value.toString();
          if (preformatedValue === "YES") {
            preformatedValue = i18n.t("default:_YES");
          }
          if (preformatedValue === "NO") {
            preformatedValue = i18n.t("default:_NO");
          }
          preformatedValue = (
            <div>
              <div className="dialog-tooltip">
                <div className="dialog-tooltip-content">
                  {userAnswers &&
                    renderAnswerHistory(
                      userAnswers.filter(
                        (history) => !isInAppropriate(history.value)
                      ),
                      currentUserLang
                    )}
                </div>
              </div>
            </div>
          );
        }
        break;
      case QuestionType.IMAGE:
        if (
          !(
            answer &&
            answer.question &&
            answer.question.settings &&
            answer.question.settings.childs
          )
        ) {
          return;
        }

        preformatedValue = (
          <div>
            <div className="dialog-tooltip">
              <div className="dialog-tooltip-content">
                {userAnswers &&
                  renderAnswerHistory(
                    userAnswers,
                    currentUserLang,
                    (history) => {
                      const filter = answer.question.settings.childs.filter(
                        (img) => img.value === history.value
                      );
                      let val = history.value;
                      if (filter.length > 0) {
                        val = (
                          <img
                            style={{
                              maxWidth: 100,
                              maxHeight: 100,
                              objectFit: "contain",
                            }}
                            src={getFileTarget(filter[0].file, "100x100")}
                            alt={value}
                          />
                        );
                      }

                      return val;
                    },
                    user
                  )}
              </div>
            </div>
          </div>
        );
        break;
      case QuestionType.TEXT:
        typeof value !== "string"
          ? (preformatedValue = (
              <div>
                {userAnswers &&
                  renderAnswerHistory(
                    userAnswers,
                    currentUserLang,
                    (history) => {
                      if (history.value && typeof history.value === "object") {
                        return <RichTextRenderer value={history.value.value} />;
                      }

                      return <RichTextRenderer value={history.value} />;
                    },
                    user
                  )}
              </div>
            ))
          : (preformatedValue = (
              <div>
                {userAnswers &&
                  renderAnswerHistory(
                    userAnswers,
                    currentUserLang,
                    (history) => {
                      if (history.value && typeof history.value === "object") {
                        return history.value.value;
                      }

                      return history.value;
                    },
                    user
                  )}
              </div>
            ));
        break;
      case QuestionType.PROJECT_TABLE:
        preformatedValue = (
          <div className="ListComponent">
            {userAnswers &&
              renderAnswerHistory(
                userAnswers,
                currentUserLang,
                (history) => {
                  return history.value.value || history.value;
                },
                user
              )}
          </div>
        );
        break;
      default:
        preformatedValue = (
          <div>
            {userAnswers &&
              renderAnswerHistory(
                userAnswers,
                currentUserLang,
                (history) => {
                  if (history.value && typeof history.value === "object") {
                    return history.value.value;
                  }

                  return history.value;
                },
                user
              )}
          </div>
        );
        break;
    }
    return preformatedValue;
  };

  return (
    <div>{getAnswerHistory(currentAnswer, answers, currentUserLang, user)}</div>
  );
}

export default AnswerHistory;
