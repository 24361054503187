import React, { useState, useEffect, useCallback } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import {
  STEP_SELECT_PROJECT,
  STEP_CREATE_NEW,
  STEP_NEW_JOB,
  STEP_SELECT_START_DATE,
} from "../steps";
import { getJobTypeList } from "../../../services/api/job_type";
import { WizardJob, Wizard } from "../../../redux/actions";
import ListWizard from "../list_wizard";
import { getFileTarget } from "../../../services/api/file_upload";
import { APP_PROJECTS } from "../../../services/app";

function SelectJobType({ goToStep, wizardJob, dispatch, isActive, apps }) {
  const { project, startDate } = wizardJob;
  const [jt, setJt] = useState(wizardJob.jobType);
  const [activityTypes, setActivityTypes] = useState([]);

  const onJobTypeChange = (val) => {
    let dispatchValue = { jobType: null };
    if (val) {
      dispatchValue = { jobType: val };
    }
    dispatch(WizardJob.setJobType(dispatchValue));
    setJt(dispatchValue.jobType);
  };

  const getAvatarCircle = (item) => {
    if (item.icon) {
      return <img src={getFileTarget(item.icon, "100x100")} alt={item.title} />;
    } else {
      return (
        <div className="IconAvatar">
          <i className="mdi mdi-briefcase-outline" />
        </div>
      );
    }
  };

  const getActivityTypes = useCallback(async () => {
    const data = await getJobTypeList();
    const list = data.filter((jt) => !jt.settings.isDisabled);
    setActivityTypes(list);
  }, []);

  useEffect(() => {
    if (isActive)
      dispatch(
        Wizard.setTitle(
          i18n.t("default:_SELECT_JOB_TYPE", {
            job_type: i18n.t("default:_MENU_JOB_TYPE"),
          })
        )
      );
    //get and set activity types !
    getActivityTypes();
  }, [dispatch, isActive, getActivityTypes]);

  const goToNextStep = () => {
    if (
      (project && startDate) ||
      (!apps.enabled.includes(APP_PROJECTS) && startDate)
    ) {
      goToStep(STEP_NEW_JOB);
    } else if (!apps.enabled.includes(APP_PROJECTS)) {
      goToStep(STEP_SELECT_START_DATE);
    } else {
      goToStep(STEP_SELECT_PROJECT);
    }
  };

  const getButtonLabel = () => {
    if (
      (project && startDate) ||
      (!apps.enabled.includes(APP_PROJECTS) && startDate)
    ) {
      return i18n.t("default:_FINISH");
    }
    if (!apps.enabled.includes(APP_PROJECTS)) {
      return i18n.t(`default:_SELECT_START_DATE`);
    }
    return i18n.t("default:_SELECT_PROJECT");
  };

  return (
    <div
      className="SelectJobType ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListWizard
        items={activityTypes}
        selectionType={jt}
        onSelectionChange={onJobTypeChange}
        getAvatarCircle={getAvatarCircle}
      />
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_CREATE_NEW),
            variant: "white",
          },
          {
            label: getButtonLabel(),
            onClick: () => goToNextStep(),
            variant: "black",
            disabled: !jt,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(SelectJobType, ["wizardJob", "apps"]);
