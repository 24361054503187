import {
  MULTI_STEP_FILTER_SET_ACTIVE_STEP,
  MULTI_STEP_FILTER_SET_SHOW,
  MULTI_STEP_SET_ANSWERS,
  MULTI_STEP_SET_PROJECTS,
  MULTI_STEP_SET_QUESTIONS_LIST,
} from "../constants";

const initialState = {
  showModal: false,
  activeStep: 1,
  multiStepAnswers: {},
  questionList: [],
  projects: [],
};

const multiStepFilter = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case MULTI_STEP_FILTER_SET_ACTIVE_STEP:
      return Object.assign({}, state, {
        activeStep: data,
      });
    case MULTI_STEP_FILTER_SET_SHOW:
      return Object.assign({}, state, {
        showModal: data,
      });
    case MULTI_STEP_SET_ANSWERS:
      return Object.assign({}, state, {
        multiStepAnswers: data,
      });
    case MULTI_STEP_SET_QUESTIONS_LIST:
      return Object.assign({}, state, {
        questionList: data,
      });
    case MULTI_STEP_SET_PROJECTS:
      return Object.assign({}, state, {
        projects: data,
      });
    default:
      return state;
  }
};

export default multiStepFilter;
