import React, { useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import i18n from "../../i18n";
import { ROLE } from "../../services/roles";
import StepWizard from "react-step-wizard";
import CreateNewJob from "./create_new_job";
import CreateNewProject from "./create_new_project";
import CreateNewUser from "./create_new_user";
import FinalStep from "./final_step";

import "./wizard.scss";

import {
  STEP_NEW_PROJECT,
  STEP_NEW_USER,
  STEP_FINAL,
  STEP_SELECT_JOB_TYPE,
} from "./steps";
import {
  SelectJobType,
  SelectProject,
  SelectStartDate,
  JobCreated,
  AssignUsers,
  SelectPrevJob,
} from "./create_job";
import SelectProjectSkills from "./select_project_skills";
import SelectUserSkills from "./create_user/select_user_skills";
import SelectJobSkills from "./create_job/select_job_skills";
import { connectWithStore } from "../../services/redux";
import { WizardJob, Wizard } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import SelectUserCompanies from "./create_user/select_user_companies";
import SelectUserProjects from "./create_user/select_user_projects";
import { APP_PROJECTS } from "../../services/app";

const WhichEntity = connectWithStore(
  function ({ goToStep, dispatch, isActive, jobOnly, apps, settings }) {
    useEffect(() => {
      if (isActive)
        dispatch(Wizard.setTitle(i18n.t("default:_WHAT_WOULD_LIKE_TO_CREATE")));
    }, [dispatch, isActive]);
    return (
      <div
        className="row fullHeight"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "nowrap",
        }}
      >
        <div className="WhichEntity">
          {((!jobOnly && apps.enabled.includes(APP_PROJECTS)) || settings.security.allowInspectorManageProjects === true) && (
            <div className="wizardCol">
              <div
                onClick={() => goToStep(STEP_NEW_PROJECT)}
                className="option-box-grid"
              >
                <div>
                  <i className="h1 d-block icon-note" />
                  <span className="h5">{i18n.t("default:_PROJECT")}</span>
                </div>
              </div>
            </div>
          )}
          <div className={"wizardCol"}>
            <div
              onClick={() => goToStep(STEP_SELECT_JOB_TYPE)}
              className="option-box-grid"
            >
              <div>
                <i className="mdi h1  d-block mdi-briefcase" />
                <span className="h5">{i18n.t("default:_JOB")}</span>
              </div>
            </div>
          </div>
          {!jobOnly && (
            <div className="wizardCol">
              <div
                onClick={() => goToStep(STEP_NEW_USER)}
                className="option-box-grid"
              >
                <div>
                  <i className="mdi h1  d-block mdi-account" />
                  <span className="h5">{i18n.t("default:_USERS")}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
  ["apps", "settings"]
);

function CreateNewEntity(props) {
  const {
    modalIsOpen,
    dismiss,
    dispatch,
    history,
    settings,
    activePrevJob,
    featureToggles,
    userRole,
    jobOnly,
  } = props;
  const [wizard, setWizard] = useState(null);
  const dismissFn = useCallback(() => {
    if (wizard) {
      wizard.goToStep(STEP_FINAL);
    }
  }, [wizard]);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={() => {
        return null;
      }}
      style={{
        content: {
          padding: 10,
        },
      }}
      contentLabel={i18n.t("default:_NEW_TYPE")}
    >
      <div className="modal-mobile-header pointer">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            onClick={() => {
              dispatch(WizardJob.reset());
              history.go();
              dismiss();
            }}
            style={{
              background: "rgba(204, 204, 204, 0.5)",
              position: "absolute",
              fontSize: "35px",
              width: "40px",
              height: "40px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              left: "30px",
              top: "20px",
              zIndex: "9999",
              opacity: "1",
            }}
            className="close-modal-mobile"
          >
            <i
              className="mdi mdi-chevron-left"
              style={{ color: "#fff !important" }}
            />
          </div>
          {props.wizard.title && (
            <div style={{ paddingTop: 70, textAlign: "center" }}>
              <span
                className="wizard-heading"
                style={{
                  color:
                    settings.branding &&
                    settings.branding.primary &&
                    settings.branding.primary.color
                      ? settings.branding.primary.color.hex
                      : "#0083ca",
                }}
              >
                {props.wizard.title}
              </span>
            </div>
          )}
        </div>
      </div>
      <StepWizard
        className="CreateNewEntity-Wizard"
        ref={(ref) => {
          setWizard(ref);
        }}
        isLazyMount={true}
      >
        <WhichEntity
          dismiss={dismissFn}
          jobOnly={
            jobOnly || userRole === ROLE.CLIENT || userRole === ROLE.INSPECTOR
          }
        />
        <CreateNewProject dismiss={dismissFn} />
        <CreateNewUser dismiss={dismissFn} />
        <SelectJobType />
        <SelectProject />
        <SelectStartDate />
        <CreateNewJob dismiss={dismissFn} />
        <JobCreated dismiss={dismissFn} />
        <SelectPrevJob dismiss={dismissFn} activePrevJob={activePrevJob} />
        <AssignUsers
          featureToggles={featureToggles}
          dismiss={dismissFn}
          userRole={userRole}
        />
        <FinalStep dismiss={dismiss} />
        <SelectProjectSkills />
        <SelectJobSkills />
        <SelectUserSkills />
        <SelectUserCompanies />
        <SelectUserProjects />
      </StepWizard>
    </Modal>
  );
}

export default connectWithStore(withRouter(CreateNewEntity), [
  "wizard",
  "settings",
  "user",
]);
