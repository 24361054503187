import React, { useEffect, useMemo } from "react";
import GoogleButton from "../../components/social/google_button";
import i18n from "../../i18n";
import { jwtDecode } from "jwt-decode";

function GoogleLogin() {
  const location = window.location;
  const queryString = useMemo(() => new URLSearchParams(location.search), [
    location,
  ]);
  const redirectUri = queryString.get("redirectUri");
  let postMessage = false;

  if (redirectUri === "postMessage") {
    postMessage = true;
  }

  const handleSocialLogin = (response) => {
    const user = jwtDecode(response.credential);
    if (postMessage) {
      window.opener.postMessage(
        {
          source: "google_sso_login",
          email: user.email,
          name: user.name,
          avatar: user.picture
        },
        "*"
      );
      window.close();
      return;
    }
    window.location =
      redirectUri +
      "?email=" +
      user.email +
      '&provider=google'
  };

  const handleSocialLoginFailure = (err) => {
    console.log('social login error ->', err)
    if (postMessage) {
      window.opener.postMessage("error", "*");
      window.close();
      return;
    }
    window.location = redirectUri + "?sso_error=1";
  };

  useEffect(() => {
    setTimeout(() => {
      const buttons = document.getElementsByTagName("button");

      if (buttons && buttons[0]) {
        buttons[0].click();
      }
    }, 2000);
  }, []);

  return (
    <div id={"google-login-button"}>
      <GoogleButton
        loading={false}
        label={i18n.t("default:_SIGN_IN_WITH_GOOGLE")}
        handleSocialLogin={handleSocialLogin.bind(this)}
        handleSocialLoginFailure={handleSocialLoginFailure.bind(this)}
      />
    </div>
  );
}

export default GoogleLogin;
