import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import Header from "./partials/header/header";
import { connectWithStore } from "../services/redux";
import Loader from "../components/loader/loader";
import Sidebar from "./partials/sidebar/sidebar";
import { JobType, Projects, UserAutocomplete } from "../redux/actions";
import { getJobTypeList } from "../services/api/job_type";
import { getAllProjectList } from "../services/api/projects";
import { getUsersList } from "../services/api/user";
import NotificationsModal from "./partials/modals/user_notifications_modal";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastr";
import LoginService from "../services/login";
import { getFileTarget } from "../services/api/file_upload";
import { BigLoader } from "../components/loader/big_loader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import samuraiTheme from "../theme/themes/mui_theme";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: this.props.topNavMenu.buttons,
      showModalFunc: null,
      //showModalJobType: null,
      hideSidebar: null,
      theme: createTheme(samuraiTheme),
      themeInitiated: false,
    };
    getJobTypeList().then((list) => {
      this.props.dispatch(JobType.list({ list }));
      //this.setState({ loading: false });
    });
    getAllProjectList({}).then((list) => {
      const projectsList = list ? list.docs : [];
      this.props.dispatch(Projects.list(projectsList));
      this.setState({ loading: false });
    });
    getUsersList({ page_size: 100 }).then((response) => {
      const transformedUsersList =
        response.docs && response.docs.length > 0
          ? response.docs.map((user) => {
              const hasName = user.name && user.name.first && user.name.last;
              if (hasName) {
                return {
                  value: user._id,
                  label: user.name.first + " " + user.name.last,
                  subLabel: user.email,
                  avatar: user.imgUrl,
                  role: user.role,
                  inTraining: user.inTraining,
                  skills: user.skills,
                };
              } else {
                return {
                  value: user._id,
                  label: user.email,
                  avatar: user.imgUrl,
                  role: user.role,
                  inTraining: user.inTraining,
                  skills: user.skills,
                };
              }
            })
          : [];
      this.props.dispatch(
        UserAutocomplete.setInitialUsers(transformedUsersList)
      );
    });
    this.headerRef = null;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.user.access_token.accessToken) {
      nextProps.children.props.history.push("/auth");
      return null;
    }
    if (
      JSON.stringify(nextProps.topNavMenu.buttons) !==
      JSON.stringify(prevState.buttons)
    ) {
      return {
        buttons: nextProps.topNavMenu.buttons,
      };
    }
    return null;
  }

  componentDidUpdate() {
    const { theme, themeInitiated } = this.state;
    const { settings } = this.props;
    const primaryColor =
      settings &&
      settings.branding &&
      settings.branding.primary &&
      settings.branding.primary.color &&
      settings.branding.primary.color.hex;

    if (primaryColor && !themeInitiated) {
      const updatedTheme = { ...theme };
      updatedTheme.palette.primary.main = primaryColor;

      this.setState({
        themeInitiated: true,
        theme: updatedTheme,
      });
    }
  }

  render() {
    const { loading, buttons, theme } = this.state;
    const { visible } = this.props.notificationPanel;

    return (
      <ThemeProvider theme={theme}>
        <div className="adminLayout">
          {this.props.loadingBarApp.showLoader && <BigLoader />}
          {/*isMobileDevice() && (
            <AddToHomeScreen
              appId="root"
              startAutomatically={true}
              startDelay={0}
              displayPace={10080}
              lifespan={60}
              skipFirstVisit={false}
              activateLogging={true}
              mustShowCustomPrompt={true}
              customPromptContent={{
                cancelMsg: "Not now",
                installMsg: "Install",
                guidanceCancelMsg: "",
              }}
            />
            )*/}
          <Helmet>
            <link
              rel="apple-touch-icon"
              href={
                this.props.settings.secondaryLogoUrl
                  ? getFileTarget(
                      this.props.settings.secondaryLogoUrl,
                      "100x100"
                    )
                  : "/logo.default.png"
              }
            />
            {LoginService.isImpersonating(this.props.settings) && (
              <style>{"body {background-color: #FFCCCC !important}"}</style>
            )}
            <script src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/build/pdf.min.js" />
          </Helmet>
          <ToastContainer
            ref={(ref) => (window.toast = ref)}
            className="toast-top-center"
          />
          <Loader store={this.props.store} />
          <Header
            modalNewPass={(func) => {
              this.setState({
                showModalFunc: func,
              });
            }}
            setHideFunction={(fn) => this.setState({ hideSidebar: fn })}
            buttons={buttons}
            {...this.props.children.props}
          />
          {!loading && (
            <Sidebar
              {...this.props.children.props}
              toggleModal={this.state.showModalFunc}
              closeMenu={this.state.hideSidebar}
            />
          )}
          <div
            className="admin-main"
            onClick={() => {
              if (this.state.hideSidebar) {
                this.state.hideSidebar();
              }
            }}
          >
            <div
              className="row container-fluid"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div
                className={
                  visible
                    ? "col-md-9 d-sm-block"
                    : "col-md-12  d-sm-block wrapper"
                }
              >
                {this.props.children}
              </div>
              <div
                style={{
                  display: !visible ? "none" : "block",
                }}
                className={
                  visible ? "col-md-3  d-sm-block NotificationPanelWrapper" : ""
                }
              >
                <NotificationsModal />
              </div>
            </div>
          </div>
          <ReactTooltip effect="solid" type="info" />
        </div>
      </ThemeProvider>
    );
  }
}

export default connectWithStore(AdminLayout, [
  "loadingBar",
  "user",
  "jobType",
  "topNavMenu",
  "notificationPanel",
  "settings",
  "loadingBarApp",
  "apps",
]);
