import React, { useCallback, useState, useEffect } from "react";
import { STEP_JOB_CREATED, STEP_SELECT_PREV_JOB } from "../steps";
import { connectWithStore } from "../../../services/redux";
import WizardButtons from "../wizard_buttons";
import i18n from "../../../i18n";
import { WizardJob, Wizard } from "../../../redux/actions";
import UserSelectModal from "../../../layout/partials/modals/user_select_modal";
import { assignMultiple } from "../../../services/api/jobs";
import { withRouter } from "react-router-dom";
import LeftPanel from "./left_panel";
import { WIZARD_TYPE_JOB } from "../constants";
import ToggleService from "../../../services/toggle";

function AssignUsers({
  goToStep,
  wizardJob,
  dismiss,
  dispatch,
  isActive,
  featureToggles,
  userRole,
}) {
  const { users, mainQuestions, id } = wizardJob;
  const [show, setShow] = useState(false);

  const onUsersChange = (selectedUsers) => {
    dispatch(WizardJob.assignUsers({ users: selectedUsers }));
    setShow(false);
  };

  const shouldSelectPreviousJob = useCallback(() => {
    return (
      ToggleService._isToggleActive("previous_job", featureToggles) &&
      !wizardJob.prevJob &&
      userRole !== "inspector"
    );
  }, [featureToggles, wizardJob, userRole]);

  const assignAndDismiss = useCallback(async () => {
    if (users.length > 0 && id) {
      await assignMultiple({
        users: users.map(({ value }) => value),
        jobs: [id],
      });
    }
    if (shouldSelectPreviousJob()) {
      goToStep(STEP_SELECT_PREV_JOB);
    } else {
      dispatch(Wizard.setType(WIZARD_TYPE_JOB));
      dismiss();
    }
  }, [id, users, dismiss, dispatch, shouldSelectPreviousJob, goToStep]);

  useEffect(() => {
    if (!(mainQuestions && mainQuestions.length > 0) && isActive) {
      dispatch(
        Wizard.setTitle(
          i18n.t("default:_NEW_ACTIVITY_CREATED", {
            job: i18n.t("default:_JOB"),
          })
        )
      );
    }
  }, [dispatch, mainQuestions, isActive]);

  return (
    <div
      className="row fullHeight"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
      }}
    >
      <div className="WhichEntity">
        <div className="wizardCol" style={{ flex: 1, height: "100%" }}>
          <LeftPanel />
        </div>
        <div className={"wizardCol"}>
          <UserSelectModal
            show={show}
            onChange={(users) => onUsersChange(users)}
            close={() => setShow(false)}
            includeMe={true}
          />
          <div onClick={() => setShow(true)} className="option-box-grid">
            {(!users || users.length === 0) && (
              <div>
                <i className="mdi h1  d-block mdi-account-group-outline" />
                <span className="h5">
                  {i18n.t("default:_ASSIGN_USERS", {
                    users: i18n.t("default:_USERS"),
                  })}
                </span>
              </div>
            )}
            {users && users.length > 0 && (
              <div>
                <i className="mdi h1 d-block mdi-account-group leading-colour" />
                <span className="h5">
                  {users.length} {i18n.t("default:_ASSIGNED")}
                </span>
              </div>
            )}
          </div>
        </div>
        <WizardButtons
          actions={[
            mainQuestions && mainQuestions.length > 0
              ? {
                  label: i18n.t("default:_BACK"),
                  onClick: () => goToStep(STEP_JOB_CREATED),
                  variant: "white",
                }
              : null,
            /* shouldSelectPreviousJob() &&
            {
              label: i18n.t("default:_FINISH"),
              onClick: () => assignAndDismiss(),
              variant: "blue",
              disabled: false,
              size: 2,
            }, */
            {
              label: shouldSelectPreviousJob()
                ? i18n.t(`default:_SELECT_PREVIOUS_JOB`, {
                    jobWord: i18n.t("default:_JOBS"),
                  })
                : i18n.t("default:_FINISH"),
              onClick: () => assignAndDismiss(),
              variant: "black",
              disabled: false,
              size: 2,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default connectWithStore(withRouter(AssignUsers), ["wizardJob"]);
