import React from "react";
import { DateTimePicker } from "@material-ui/pickers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    textAlign: "center",
  },
}));

function MuiDateTimePicker({
  defaultValue,
  onClose,
  clearable,
  placeholder,
  disabled,
}) {
  const classes = useStyles();

  return (
    <DateTimePicker
      disabled={disabled}
      clearable={clearable}
      autoOk
      hideTabs
      ampm={false}
      format={"DD/MM/YY, HH:mm"}
      value={defaultValue}
      onChange={(d) => {
        if (d || clearable) onClose(d);
      }}
      allowKeyboardControl={false}
      leftArrowIcon={<ChevronLeftIcon />}
      leftArrowButtonProps={{ "aria-label": "Prev month" }}
      rightArrowButtonProps={{ "aria-label": "Next month" }}
      rightArrowIcon={<ChevronRightIcon />}
      InputProps={{
        disableUnderline: true,
        className: classes.input,
        placeholder: placeholder,
      }}
      inputVariant={clearable ? "outlined" : "standard"}
    />
  );
}

export default MuiDateTimePicker;
