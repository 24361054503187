import {
  INSTANCE_WIZARD_DEV_PROCEDURE,
  INSTANCE_WIZARD_EMAIL,
  INSTANCE_WIZARD_DB,
  INSTANCE_WIZARD_INSTANCE_NAME,
  INSTANCE_WIZARD_INCOGNITO,
  INSTANCE_WIZARD_ADD_YOURSELF,
  INSTANCE_WIZARD_PASSWORD,
  INSTANCE_WIZARD_PHONE,
  INSTANCE_WIZARD_USER_NAME,
  INSTANCE_WIZARD_TOKEN,
  INSTANCE_WIZARD_ENABLE_SSO,
} from "../constants";

const initialState = {
  devProcedure: null,
  email: "",
  db: "",
  instanceName: "",
  userName: "",
  incognito: null,
  addYourself: null,
  password: "",
  phone: "",
  token: "",
  enableSso: false,
};

const instanceWizard = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case INSTANCE_WIZARD_DEV_PROCEDURE:
      return Object.assign({}, state, {
        devProcedure: data,
      });
    case INSTANCE_WIZARD_EMAIL:
      return Object.assign({}, state, {
        email: data,
      });
    case INSTANCE_WIZARD_DB:
      return Object.assign({}, state, {
        db: data,
      });
    case INSTANCE_WIZARD_USER_NAME:
      return Object.assign({}, state, {
        userName: data,
      });
    case INSTANCE_WIZARD_PASSWORD:
      return Object.assign({}, state, {
        password: data,
      });
    case INSTANCE_WIZARD_PHONE:
      return Object.assign({}, state, {
        phone: data,
      });
    case INSTANCE_WIZARD_INSTANCE_NAME:
      return Object.assign({}, state, {
        instanceName: data,
      });
    case INSTANCE_WIZARD_INCOGNITO:
      return Object.assign({}, state, {
        incognito: data,
      });
    case INSTANCE_WIZARD_ADD_YOURSELF:
      return Object.assign({}, state, {
        addYourself: data,
      });
    case INSTANCE_WIZARD_TOKEN:
      return Object.assign({}, state, {
        token: data,
      });
    case INSTANCE_WIZARD_ENABLE_SSO:
      return Object.assign({}, state, {
        enableSso: data,
      });
    default:
      return state;
  }
};

export default instanceWizard;
