import { APP_MICROSOFT_SSO } from "./app";
import Http from "./http";
import ToggleService from "./toggle";

export default class LoginService {
  static async doLogin(email, password) {
    const params = new URLSearchParams();
    params.append("username", email.toLowerCase());
    params.append("password", password);
    params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    params.append("grant_type", "password");
    const url = `/v1/oauth/token`;
    try {
      const response = await Http.sharedInstance.getClient().post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const json = response.data;
      if (json) {
        return json;
      }
    } catch (e) {
      throw e;
    }
  }

  static async msSsoLogin(params) {
    const url = `/v2/auth/ms-login`;
    try {
      const response = await Http.sharedInstance.getClient().post(url, params);
      const json = response.data;
      if (json) {
        return json;
      }
    } catch (e) {
      throw e;
    }
  }

  static async getSignInLink() {
    const url = `/v2/auth/ms-signin`;
    try {
      const response = await Http.sharedInstance.getClient().get(url);
      const link = response.data;
      if (link) {
        return link;
      }
    } catch (e) {
      throw e;
    }
  }

  static async ssoGoogle({ email, token }) {
    const params = new URLSearchParams();
    params.append("username", email.toLowerCase());
    params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    params.append("grant_type", "password");
    const url = `/v1/oauth/google`;
    try {
      const response = await Http.sharedInstance.getClient().post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const json = response.data;
      if (json) {
        return json;
      }
    } catch (e) {
      throw e;
    }
  }

  static async doImpersonate(email) {
    const params = new URLSearchParams();
    params.append("username", email.toLowerCase());
    params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    params.append("grant_type", "password");
    const url = `/v1/oauth/impersonate`;
    try {
      const response = await Http.sharedInstance.getClient().post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const json = response.data;
      if (json) {
        return json;
      }
    } catch (e) {
      throw e;
    }
  }

  static isImpersonating(settings) {
    if (!ToggleService._isToggleActive("impersonate", settings.toggles)) {
      return false;
    }

    return (
      localStorage.getItem("oldUserProfile") !== null &&
      localStorage.getItem("oldAccessToken") !== null
    );
  }

  static getSsoSettings = (settings) => {
    if (!settings || !settings.toggles) {
      return null;
    }

    const targetApp = settings.toggles.find(
      (a) => a.toggleId.toString() === APP_MICROSOFT_SSO
    );

    if (!targetApp) {
      return null;
    }

    if (!targetApp.isActive) {
      return null;
    }

    return targetApp.settings;
  };
}
