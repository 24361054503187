import React, { useCallback, useEffect, useState } from "react";
import i18n from "../../../i18n";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";
import { GridTable } from "../../../redux/actions";
import { connectWithStore } from "../../../services/redux";

function ShowFilesButton({ jobInfo, history, customWidth, dispatch }) {
  const [jobIds, setJobIds] = useState("");

  const getJobIds = useCallback(() => {
    if (jobInfo && Array.isArray(jobInfo)) {
      const ids = jobInfo.map((job) => job._id).join();
      setJobIds(ids);
    }
  }, [jobInfo]);

  const openJobFiles = () => {
    dispatch(GridTable.setSelectedRows([]));
    dispatch(GridTable.setRightPanelOpen(false));
    history.push(`/files?jobId=${jobIds}`);
  };

  useEffect(() => {
    getJobIds();
  }, [getJobIds]);

  return (
    <Button
      color="primary"
      fullWidth
      variant="outlined"
      style={{ height: "55px", width: customWidth }}
      onClick={() => openJobFiles()}
    >
      {i18n.t("default:_SHOW_FILES")}
    </Button>
  );
}

export default connectWithStore(withRouter(ShowFilesButton), []);
