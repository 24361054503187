import Http from "../http";

export const getUsersList = async (params) => {
  const path = `/v2/users`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data.data;
  } catch (e) {
    return [];
  }
};

export const getUser = async (params) => {
  const path = `/v2/users/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createUser = async (params) => {
  const path = `/v2/users`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      email: params.email,
      role: params.role,
      ...(params.companies ? { companies: params.companies } : {}),
      ...(params.client_projects
        ? { client_projects: params.client_projects }
        : {}),
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const confirmUser = async (token) => {
  const path = `/v2/users/confirm/${token}`;
  try {
    const response = await Http.sharedInstance.getClient().put(path, {});
    return response.data;
  } catch (e) {
    return [];
  }
};

export const completeUser = async (params) => {
  const path = `/v2/users/register`;
  try {
    const response = await Http.sharedInstance.getClient().put(path, {
      email: params.email,
      password: params.password,
      name: params.name,
      phoneNumber: params.phone_number,
      terms_and_privacy: params.terms_and_privacy,
      avatar: params.avatar ? params.avatar : null,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const getCurrentUser = async () => {
  const path = `/v2/users/me`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const getUserActivities = async (params) => {
  const path = `/v2/users/activities?user=${params.userId}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

/**
 * TODO: add the new settings for the project fields and create the API for listing teams
 * @param data
 * @return {Promise<any|{success: boolean}>}
 */

export const connectWithMs = async (data) => {
  try {
    const response = await Http.sharedInstance
      .withAuth()
      .put("/v2/users/ms-connect", data);
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const updateUser = async (params) => {
  const path = `/v2/users/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().put(path, params);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response && e.response.data ? e.response.data : {},
    };
  }
};

export const logout = async () => {
  const path = `/v2/auth/logout`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const reinviteUsers = async (params) => {
  const path = `/v2/users/reinvite`;
  try {
    const response = await Http.sharedInstance.getClient().post(path, {
      data: params.users,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const banMultiple = async (params) => {
  const path = `/v2/users/ban`;
  try {
    const response = await Http.sharedInstance.getClient().put(path, {
      data: params.users,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const feedsUsers = async (params) => {
  const path = `/v2/users/${params.id}/notifications`;
  try {
    const response = await Http.sharedInstance.getClient().get(path, {
      params,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const forgotPassword = async (email) => {
  const path = `/v2/users/forgot_password`;

  try {
    const response = await Http.sharedInstance.getClient().post(path, {
      email,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const validateForgotPasswordToken = async (token) => {
  const path = `/v2/users/forgot_password/validate_token/${token}`;

  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const resetPassword = async (email, params) => {
  const path = `/v2/users/forgot_password/reset_password/${email}`;

  try {
    const response = await Http.sharedInstance.getClient().put(path, params);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const eraseUsers = async (params) => {
  const path = `/v2/users/remove`;

  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};
