import { updateProjectFields } from "../../services/api/settings";
import {
  FIELD_EDITOR_SET_VISIBLE,
  FIELD_EDITOR_ADD_FIELD,
  FIELD_EDITOR_REMOVE_FIELD,
  FIELD_EDITOR_SET_FIELD,
  SET_FIELD_LIST,
  UPDATE_FIELDS,
  UPDATE_FIELD,
} from "../constants";

const initialState = {
  visible: false,
  field: null,
  fieldList: [],
};

const fieldEditor = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case FIELD_EDITOR_SET_VISIBLE:
      return Object.assign({}, state, {
        visible: data,
      });
    case UPDATE_FIELD:
      const { index, fieldData } = data;
      const existing = [...state.fieldList];
      existing[index] = fieldData;
      return Object.assign({}, state, {
        fieldList: existing,
      });
    case FIELD_EDITOR_ADD_FIELD:
      const current = [...state.fieldList];
      current.push(data);
      return Object.assign({}, state, {
        fieldList: current,
      });
    case FIELD_EDITOR_REMOVE_FIELD:
      console.log(data, state.fieldList);
      const next = [...state.fieldList];
      next.splice(data, 1);
      console.log(next);
      return Object.assign({}, state, {
        fieldList: next,
      });
    case FIELD_EDITOR_SET_FIELD:
      return Object.assign({}, state, {
        field: data,
      });
    case SET_FIELD_LIST:
      return Object.assign({}, state, {
        fieldList: data,
      });
    case UPDATE_FIELDS:
      updateProjectFields({
        fields: data ? data : state.fieldList,
      });
      if (data) {
        return Object.assign({}, state, {
          fieldList: data,
        });
      }
      return state;
    default:
      return state;
  }
};

export default fieldEditor;
