const SKILL_COLORS = {
  GREEN: "#80F05E",
  YELLOW: "#FFF78A",
  RED: "#F05F63",
  GREY: "#B6BECD",
  DARK_GREY: "#2E394D",
  WHITE: "white",
  BLACK: "black",
};

export default SKILL_COLORS;
