import React from "react";
import {
  STEP_SELECT_JOB_TYPE,
  STEP_SELECT_START_DATE,
  STEP_SELECT_PROJECT,
} from "../steps";
import DateService from "../../../services/date";
import i18n from "../../../i18n";
import { getFileTarget } from "../../../services/api/file_upload";
import { connectWithStore } from "../../../services/redux";
import { APP_PROJECTS } from "../../../services/app";
//import { isMobileDevice } from "../../../services/mobile";

const defaultProjectAvatar = require("../../../assets/img/project-placeholder.png");

function JobCreatedInfoRow({ goToStep, wizardJob, disabledClick, apps }) {
  const { jobType, project, startDate } = wizardJob;
  const optionBoxGridClass = `option-box-grid ${
    disabledClick ? "option-box-grid-no-hover" : ""
  }`;
  return (
    <>
      <div className={"wizardCol"}>
        <div
          onClick={() =>
            disabledClick ? null : goToStep(STEP_SELECT_JOB_TYPE)
          }
          className={optionBoxGridClass}
        >
          {!jobType && (
            <div>
              <i className="mdi h1  d-block mdi-briefcase-outline" />
              <span className="h5">
                {i18n.t("default:_SELECT_JOB_TYPE", {
                  job_type: i18n.t("default:_MENU_JOB_TYPE"),
                })}
              </span>
            </div>
          )}
          {jobType && (
            <div>
              <div className="circle">
                {jobType.icon ? (
                  <img
                    src={getFileTarget(jobType.icon, "100x100")}
                    alt={jobType.title}
                  />
                ) : (
                  <div style={{ fontSize: "50px" }}>
                    {" "}
                    <i className="mdi mdi-briefcase-outline" />{" "}
                  </div>
                )}
              </div>
              <span className="h5">{jobType.title}</span>
            </div>
          )}
        </div>
      </div>
      {apps.enabled.includes(APP_PROJECTS) && (
        <div className="wizardCol">
          <div
            onClick={() =>
              disabledClick ? null : goToStep(STEP_SELECT_PROJECT)
            }
            className={optionBoxGridClass}
          >
            {!project && (
              <div>
                <i className="h1 d-block icon-note" />
                <span className="h5">{i18n.t("default:_SELECT_PROJECT")}</span>
              </div>
            )}
            {project && (
              <div>
                <div className="circle">
                  {project.avatar ? (
                    <img
                      src={getFileTarget(project.avatar, "100x100")}
                      alt={project.name}
                    />
                  ) : (
                    <img src={defaultProjectAvatar} alt={project.name} />
                  )}
                </div>
                <span className="h5">{project.name}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="wizardCol">
        <div
          onClick={() =>
            disabledClick ? null : goToStep(STEP_SELECT_START_DATE)
          }
          className={optionBoxGridClass}
        >
          {!startDate && (
            <div>
              <div className="circle">
                <i className="mdi h1  d-block mdi-clock-outline" />
              </div>
              <span className="h5">{i18n.t("default:_START_DATE")}</span>
            </div>
          )}
          {startDate && (
            <div>
              <div className="circle">
                <i className="mdi h1  d-block mdi-clock leading-colour" />
              </div>
              <span className="h5">
                {DateService.getFormattedDate(startDate)}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default connectWithStore(JobCreatedInfoRow, ["wizardJob", "apps"]);
