import {
  WIZARD_PROJECT_RESET,
  WIZARD_PROJECT_SET_NAME,
  WIZARD_PROJECT_SET_SKILL_FIELDS,
  WIZARD_PROJECT_SET_CURRENT_INDEX,
  WIZARD_PROJECT_SET_ID,
} from "../constants";

const initialState = {
  name: "",
  skillFields: [],
  currentField: 0,
  id: null,
};

const wizardProject = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case WIZARD_PROJECT_SET_NAME:
      return Object.assign({}, state, data);
    case WIZARD_PROJECT_SET_ID:
      return Object.assign({}, state, data);
    case WIZARD_PROJECT_SET_SKILL_FIELDS:
      return Object.assign({}, state, data);
    case WIZARD_PROJECT_SET_CURRENT_INDEX:
      return Object.assign({}, state, data);
    case WIZARD_PROJECT_RESET:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default wizardProject;
