import React, { useState, useCallback, useEffect } from "react";
import AutoComplete from "./v2autoselect";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import i18n from "../../i18n";
import { getCompanies } from "../../services/api/projects";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  labelSpan: {
    paddingLeft: "20px",
  },
  labelSpanSelected: {
    color: "#408FF8",
    paddingLeft: "20px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

function OptionRow({ option, selected }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Checkbox
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<CheckCircleOutlineOutlinedIcon />}
          color="primary"
          style={{ marginRight: 8 }}
          checked={selected.includes(option)}
        />
        <Avatar>
          <i className="mdi mdi-office-building" />
        </Avatar>
        <ListItemText className={classes.labelSpan} primary={option} />
      </Grid>
    </React.Fragment>
  );
}

function SelectedRow({ selected, index, item, onClick }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} key={index} button>
      <Checkbox
        onClick={onClick}
        icon={<RadioButtonUncheckedOutlinedIcon />}
        checkedIcon={<CheckCircleIcon className={classes.checkedUserIcon} />}
        style={{ marginRight: 8 }}
        checked={selected.includes(item)}
      />
      <Avatar>
        <i className="mdi mdi-office-building" />
      </Avatar>
      <ListItemText className={classes.labelSpanSelected} primary={item} />
    </ListItem>
  );
}

function CompanyAutocomplete({ selected, unclickable, onCompanyChange }) {
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies({}).then((response) => {
      console.log(response);
      if (response.success) {
        setCompanies(response.data);
      }
    });
  }, []);

  const getOptionLabel = useCallback((option) => {
    return option;
  }, []);

  const onChange = (activityType) => {
    const currentlySelected = [...selected];
    currentlySelected.push(activityType);
    onCompanyChange(currentlySelected);
  };

  const onDeselect = (activityType) => {
    if (unclickable) return;
    const currentlySelected = [...selected];
    currentlySelected.splice(currentlySelected.indexOf(activityType), 1);
    onCompanyChange(currentlySelected);
  };

  const filterSuggestion = (suggestion) => {
    const query = selected.filter((s) => s === suggestion);
    if (query) {
      return query.length === 0;
    }
    return true;
  };

  const options = companies.filter(filterSuggestion);
  return (
    <React.Fragment>
      <AutoComplete
        list={options}
        selected={selected}
        onChange={(option) => onChange(option)}
        renderOption={(option, index) => (
          <OptionRow index={index} selected={selected} option={option} />
        )}
        getOptionLabel={getOptionLabel}
        noOptionsDisplay="none"
        text={i18n.t("default:_TYPE_TEXT_SEARCH")}
      />
      {selected.length > 0 ? (
        <List className={classes.root}>
          {selected.map((row, index) => {
            return (
              <SelectedRow
                key={index}
                item={row}
                selected={selected}
                onClick={() => onDeselect(row)}
              />
            );
          })}
        </List>
      ) : null}
    </React.Fragment>
  );
}

export default CompanyAutocomplete;
