import React, { useCallback, useEffect } from "react";
import AutoComplete from "./v2autoselect";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import i18n from "../../i18n";
import { getFileTarget } from "../../services/api/file_upload";
import { getJobTypeList } from "../../services/api/job_type";
import { connectWithStore } from "../../services/redux";
import { JobType } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  labelSpan: {
    paddingLeft: "20px",
    overflowWrap: "break-word",
  },
  labelSpanSelected: {
    color: "#408FF8",
    paddingLeft: "20px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

function ActivityTypeAutocomplete({
  selected,
  setSelected,
  unclickable,
  fromFilters,
  autocompleteWidth,
  withoutSelected,
  jobType,
  dispatch,
}) {
  const classes = useStyles();
  const { list, listFromFilter } = jobType;

  const suggestionTransformer = ({ _id, title, icon, requiredSkills }) => {
    return {
      label: title,
      value: _id,
      icon,
      requiredSkills,
    };
  };

  const getActivityTypes = useCallback(async () => {
    const data = await getJobTypeList(fromFilters ? true : false);
    dispatch(JobType.listFromFilter({ listFromFilter: data }));
  }, [fromFilters, dispatch]);

  useEffect(() => {
    if (fromFilters && listFromFilter.length === 0) {
      getActivityTypes();
    }
  }, [getActivityTypes, fromFilters, listFromFilter.length]);

  const getOptionLabel = useCallback(({ label }) => {
    return label;
  }, []);

  function OptionRow({ option, selected }) {
    return (
      <React.Fragment>
        <Grid container justifyContent="space-between">
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon />}
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected.includes(option)}
          />
          {option.icon ? (
            <Avatar
              alt="Activity type icon"
              src={getFileTarget(option.icon, "100x100")}
            />
          ) : (
            <Avatar>
              <i className="mdi mdi-briefcase-outline"></i>
            </Avatar>
          )}
          <ListItemText className={classes.labelSpan} primary={option.label} />
        </Grid>
      </React.Fragment>
    );
  }

  function SelectedRow({ selected, index, item, onClick }) {
    return (
      <ListItem className={classes.listItem} key={index} button>
        <Checkbox
          onClick={onClick}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<CheckCircleIcon className={classes.checkedUserIcon} />}
          style={{ marginRight: 8 }}
          checked={selected.includes(item)}
        />
        {item.icon ? (
          <Avatar
            alt="Activity type icon"
            src={getFileTarget(item.icon, "100x100")}
          />
        ) : (
          <Avatar>
            <i className="mdi mdi-briefcase-outline"></i>
          </Avatar>
        )}
        <ListItemText
          className={classes.labelSpanSelected}
          primary={item.label ? item.label : item.title}
        />
      </ListItem>
    );
  }

  const onChange = (activityType) => {
    const currentlySelected = [...selected];
    currentlySelected.push(activityType);
    setSelected(currentlySelected);
  };

  const onDeselect = (activityType) => {
    if (unclickable) return;
    const currentlySelected = [...selected];
    currentlySelected.splice(currentlySelected.indexOf(activityType), 1);
    setSelected(currentlySelected);
  };

  const filterSuggestion = (suggestion) => {
    return selected.filter((s) => s.value === suggestion.value).length === 0;
  };

  const options = list.map(suggestionTransformer).filter(filterSuggestion);
  return (
    <>
      <AutoComplete
        list={options}
        selected={selected}
        onChange={(option) => onChange(option)}
        renderOption={(option, index) => (
          <OptionRow index={index} selected={selected} option={option} />
        )}
        getOptionLabel={getOptionLabel}
        noOptionsDisplay="none"
        text={i18n.t("default:_TYPE_TEXT_SEARCH")}
        autocompleteWidth={autocompleteWidth}
      />
      {selected.length > 0 && !withoutSelected ? (
        <List className={classes.root}>
          {selected.map((row, index) => {
            return (
              <SelectedRow
                key={index}
                item={row}
                selected={selected}
                onClick={() => onDeselect(row)}
              />
            );
          })}
        </List>
      ) : null}
    </>
  );
}

export default connectWithStore(ActivityTypeAutocomplete, ["jobType"]);
