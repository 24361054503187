import React, { Component } from "react";
import Dropzone from "react-dropzone";
import i18n from "../../i18n";
import { s3Upload } from "../../services/api/file_upload";
import BlockUi from "react-block-ui";
import "./s3_file_upload.scss";
import { Button } from "@material-ui/core";

export default class S3FileUpload2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.maxFiles = this.props.limit;
    this.allowed = this.props.allowed;
    this.selfRef = null;
    this.uploadAsPrivate = this.props.uploadAsPrivate || false;
    this.targetUser = this.props.targetUser || null;
  }

  triggerOpen() {
    this.selfRef.open();
  }

  async onDrop(acceptedFiles, rejectedFiles) {
    if (this.maxFiles > 0 && acceptedFiles.length > this.maxFiles) {
      return alert(`Error, maximum files allowed ${this.maxFiles}`);
    }
    if (acceptedFiles.length > 0) {
      if (this.props.onUploadStart) {
        this.props.onUploadStart();
      }
      this.setState({
        loading: true,
      });
      const allowed = this.allowed.split(", ");
      const files = [];
      if (this.props.onBeforeUpload) {
        this.props.onBeforeUpload();
      }
      for (let index = 0; index < acceptedFiles.length; index++) {
        if (!acceptedFiles.hasOwnProperty(index)) {
          continue;
        }
        const file = acceptedFiles[index];
        if (allowed.indexOf(file.type) === -1) {
          if (this.props.onUploadFailed) {
            this.props.onUploadFailed();
          }
          alert(`Invalid file extension allowed only ${this.allowed}`);
          break;
        }
        const formData = new FormData();
        formData.append("file", file);

        const response = await s3Upload(
          formData,
          false,
          this.uploadAsPrivate,
          this.targetUser
        );
        response.originalName = file.name;
        files.push(response);
      }
      if (this.props.onUpload) {
        this.props.onUpload(files);
      }
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { loading } = this.state;
    const { buttonText } = this.props;

    return (
      <BlockUi tag="div" className="S3FileUpload" blocking={loading}>
        <Dropzone
          ref={(ref) => (this.selfRef = ref)}
          onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={!isDragActive ? "dropzone" : "dropzone--isActive"}
              >
                <input {...getInputProps()} accept={this.allowed} />
                {
                  <Button variant="outlined" color="primary">
                    <i className=" mdi mdi-file-plus mr-1"></i>
                    {buttonText ? buttonText : i18n.t("default:_DRAG_FILES")}
                  </Button>
                }
              </div>
            );
          }}
        </Dropzone>
      </BlockUi>
    );
  }
}
