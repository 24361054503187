import React, { Component } from "react";
import BlockUi from "react-block-ui";
import S3FileUpload from "../dropzone/s3_file_upload";
import { getFileIcon } from "../../services/question";
import { getFileInfo, getFileTarget } from "../../services/api/file_upload";
import { RIEInput } from "riek";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./file_manager.scss";
import i18n from "../../i18n";
import { Grid } from "@material-ui/core";
import BpQuestions from "./bp_questions";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { IconButton } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import VideoPreview from "./video_preview";
import S3FileUpload2 from "../dropzone/s3_file_upload_v2";

export default class FileManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || [],
      fileInfo: {},
      activeDropDownIndex: null,
      videoPreviewModalOpen: false,
    };
    this.ref = null;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (
      nextProps.files &&
      JSON.stringify(nextProps.files) !== JSON.stringify(state.files)
    ) {
      return {
        files: nextProps.files
          .filter((f) => f !== null)
          .map((f) => (typeof f === "object" ? f.assetId : f)),
      };
    }

    return {};
  }

  async componentDidMount() {
    if (this.state.files) {
      const exts = {};
      for (const file of this.state.files
        .filter((f) => f !== null)
        .map((f) => (typeof f === "object" && f.assetId ? f.assetId : f))) {
        exts[file] = await this.getFileExtension(file);
      }

      this.setState({
        fileInfo: exts,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.files !== this.state.files) {
      for (const file of this.state.files) {
        if (!this.state.fileInfo[file]) {
          const fileInfo = this.state.fileInfo;
          fileInfo[file] = await this.getFileExtension(file);
          this.setState({
            fileInfo,
          });
        }
      }
    }
  }

  async getFileExtension(filename) {
    if (!filename) {
      return "";
    }
    const result = await getFileInfo(filename);

    if (result !== null) {
      return result;
    }

    return "";
  }

  getIcon(extension) {
    const result = getFileIcon(extension);
    if (result) {
      return result;
    }
    return "";
  }

  onActionsClick(itemIndex) {
    this.setState({
      activeActionsIndex: itemIndex,
    });
  }

  resetActiveActions(event) {
    const target =
      event.target &&
      event.target.className &&
      event.target.className.constructor.name === "SVGAnimatedString"
        ? event.target.className.animVal
        : event.target.className;
    if (target && target.split(" ").indexOf("mdi-dots-vertical") > -1) {
      return false;
    }

    this.setState({
      activeActionsIndex: null,
    });
  }

  replaceFile(fileIndex) {
    if (!this.ref) {
      return false;
    }

    this.props.removeAttachment(fileIndex);

    this.ref.triggerOpen();
  }

  handleArrowClick(i) {
    const { activeDropDownIndex } = this.state;
    if (activeDropDownIndex === i) {
      this.setState({
        activeDropDownIndex: null,
      });
    } else {
      this.setState({
        activeDropDownIndex: i,
      });
    }
  }

  render() {
    const {
      files,
      fileInfo,
      activeDropDownIndex,
      videoPreviewModalOpen,
    } = this.state;
    const {
      editableName,
      expandableRow,
      names,
      fieldFiles,
      canSetBp,
    } = this.props;

    return (
      <BlockUi tag="div" className="FileManager" blocking={this.state.loading}>
        {files && files.length > 0 && (
          <ul
            className="list-files text-left"
            onClick={(e) => this.resetActiveActions(e)}
          >
            {files.map((file, index) => {
              const fileInfoObject = fileInfo[file];
              const fileFieldInfo =
                fieldFiles &&
                fieldFiles.filter((fieldFile) => fieldFile.id === file)[0];
              return (
                fileInfoObject && (
                  <React.Fragment key={index}>
                    <li className="file">
                      <Grid container>
                        {expandableRow && (
                          <div className="file-accordeon">
                            {activeDropDownIndex === index ? (
                              <ArrowDropDownIcon
                                onClick={() => this.handleArrowClick(index)}
                              />
                            ) : (
                              <ArrowRightIcon
                                onClick={() => this.handleArrowClick(index)}
                              />
                            )}
                          </div>
                        )}
                        <div className="file-image">
                          {fileInfoObject.extension === "png" ||
                          fileInfoObject.extension === "jpg" ||
                          fileInfoObject.extension === "jpeg" ? (
                            <img
                              alt="fileImage"
                              src={getFileTarget(file, "100x100")}
                            />
                          ) : (
                            <i
                              className={this.getIcon(fileInfoObject.extension)}
                            />
                          )}
                          {(!file || !fileInfoObject.originalName) && (
                            <i className="mdi mdi-file-remove" />
                          )}
                        </div>
                        <div className="file-content">
                          <h6>
                            {file && fileInfoObject.originalName ? (
                              <RIEInput
                                value={
                                  names
                                    ? names[index]
                                    : fileInfoObject.originalName
                                }
                                change={(newVal) =>
                                  this.props.updateName(
                                    newVal.name,
                                    fileInfoObject.assetId
                                  )
                                }
                                propName="name"
                                editProps={{ disabled: !editableName }}
                              />
                            ) : (
                              i18n.t("default:_CORRUPTED_FILE")
                            )}
                          </h6>
                        </div>
                        <div className="file-actions">
                          <ReactTooltip effect="solid" />
                          {!this.props.viewOnly &&
                            this.props.bpFile &&
                            fileFieldInfo &&
                            !fileFieldInfo.measurements && (
                              <IconButton
                                data-place="top"
                                data-tip={i18n.t("default:_MEASURE_WARNING")}
                              >
                                <WarningIcon color="error" className="blink" />
                              </IconButton>
                            )}
                          {!this.props.viewOnly &&
                            this.props.bpFile &&
                            canSetBp && (
                              <IconButton
                                onClick={() =>
                                  this.props.editBlueprint(fileInfoObject)
                                }
                                data-place="top"
                                data-tip={i18n.t("default:_SET")}
                              >
                                <WidgetsIcon />
                              </IconButton>
                            )}
                          <i
                            className="mdi mdi-dots-vertical"
                            onClick={() => this.onActionsClick(index)}
                          />

                          <ul
                            className={
                              this.state.activeActionsIndex === index
                                ? "file-actions-list show"
                                : "file-actions-list"
                            }
                          >
                            <li>
                              {fileInfoObject.isVideo ? (
                                <span
                                  className="remove pointer"
                                  onClick={() =>
                                    this.setState({
                                      videoPreviewModalOpen:
                                        videoPreviewModalOpen &&
                                        videoPreviewModalOpen === file
                                          ? null
                                          : file,
                                    })
                                  }
                                >
                                  {videoPreviewModalOpen &&
                                  videoPreviewModalOpen === file
                                    ? "Hide preview"
                                    : i18n.t("default:_PREVIEW")}
                                </span>
                              ) : (
                                <span className="remove pointer">
                                  <a
                                    href={getFileTarget(file, false, true)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {i18n.t("default:_PREVIEW")}
                                  </a>
                                </span>
                              )}
                            </li>
                            {this.props.showDownloadButton && (
                              <li>
                                <span className="remove pointer">
                                  <a
                                    href={getFileTarget(file)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {i18n.t("default:_DOWNLOAD")}
                                  </a>
                                </span>
                              </li>
                            )}
                            {!this.props.viewOnly && (
                              <li>
                                <span
                                  className="remove pointer"
                                  onClick={() => this.replaceFile(index)}
                                >
                                  {i18n.t("default:_REPLACE")}
                                </span>
                              </li>
                            )}
                            {!this.props.viewOnly && (
                              <li>
                                <span
                                  onClick={() =>
                                    this.props.removeAttachment(index)
                                  }
                                  className="remove pointer"
                                >
                                  {i18n.t("default:_DELETE")}
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                      </Grid>
                      {activeDropDownIndex === index && (
                        <BpQuestions
                          file={fileInfoObject.Location}
                          fileName={
                            names ? names[index] : fileInfoObject.originalName
                          }
                        />
                      )}
                      {videoPreviewModalOpen &&
                        videoPreviewModalOpen === file && (
                          <VideoPreview videoId={file} />
                        )}
                    </li>
                  </React.Fragment>
                )
              );
            })}
          </ul>
        )}

        {!this.props.viewOnly && !this.props.newS3Upload && (
          <S3FileUpload
            ref={(ref) => (this.ref = ref)}
            limit={0}
            allowed={this.props.allowedFiles}
            onUpload={(files) => this.props.onAttachment(files)}
            onBeforeUpload={() => this.beforeUpload()}
            uploadAsPrivate={this.props.uploadAsPrivate}
            targetUser={this.props.targetUser}
          />
        )}

        {!this.props.viewOnly && this.props.newS3Upload && (
          <S3FileUpload2
            ref={(ref) => (this.ref = ref)}
            limit={0}
            allowed={this.props.allowedFiles}
            onUpload={(files) => this.props.onAttachment(files)}
            onBeforeUpload={() => this.beforeUpload()}
            uploadAsPrivate={this.props.uploadAsPrivate}
            targetUser={this.props.targetUser}
          />
        )}
      </BlockUi>
    );
  }

  beforeUpload() {
    if (this.props.onBefereS3Upload) {
      this.props.onBefereS3Upload();
    }
  }
}
