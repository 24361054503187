import React, { useCallback, useEffect, useState } from "react";
import { getQuestionElements } from "../../services/api/blueprints";
import { makeStyles, Grid, Avatar } from "@material-ui/core";
import { getFileTarget } from "../../services/api/file_upload";
import { useTheme } from "@material-ui/core/styles";
import DateService from "../../services/date";
import { isMobileDevice } from "../../services/mobile";
import FullScreenModal from "../../layout/partials/modals/full_screen_modal";
import QuestionRenderer from "../../components/question/question_renderer";
import { listQuestionsWithFilters } from "../../services/api/question";
import { connectWithStore } from "../../services/redux";
import { BlueprintEditor } from "../../redux/actions";

const PROJECT_AVATAR = require("../../assets/img/project-placeholder.png");
const USER_AVATAR = require("../../assets/img/img_avatar.png");

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: isMobileDevice() ? 20 : 50,
    width: "95%",
    marginLeft: 10,
    overflow: "auto",
    marginBottom: 5,
    background: "#F5F5F5",
    cursor: "pointer",
    "&:hover": {
      background: "lightgrey",
    },
  },
  elementInfoIcon: {
    width: 25,
    height: 25,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

function BpQuestions({ file, fileName, blueprintEditor, dispatch }) {
  const classes = useStyles();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [showBpEditor, setShowBpEditor] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const getBpQuestions = useCallback(async () => {
    const response = await getQuestionElements({
      questionId: null,
      allJobs: true,
      jobId: null,
      file,
    });
    if (response) {
      setRows(response);
    }
  }, [file]);

  useEffect(() => {
    getBpQuestions();
  }, [getBpQuestions]);

  const onRowClick = async (row) => {
    const ids = [];
    ids.push(row.question);
    const questionsList = await listQuestionsWithFilters({
      ids,
    });
    setCurrentJob(row.job);
    setSelectedQuestions(questionsList);
    dispatch(BlueprintEditor.setFile(file));
    dispatch(BlueprintEditor.setFileName(fileName));
    setShowBpEditor(true);
  };

  const questionAnswered = () => {
    setCurrentJob(null);
    setSelectedQuestions([]);
    dispatch(BlueprintEditor.setFile(null));
    dispatch(BlueprintEditor.setFileName(""));
    setShowBpEditor(false);
  };

  return (
    <>
      <FullScreenModal show={showBpEditor}>
        <QuestionRenderer
          job={currentJob}
          jobType={currentJob && currentJob.jobType}
          questions={selectedQuestions}
          done={() => questionAnswered()}
          //update={(result) => this.updateAnswers(result)}
          close={() => setShowBpEditor(true)}
        />
      </FullScreenModal>
      {rows.map((row, i) => {
        return (
          <Grid
            container
            alignItems="center"
            className={classes.content}
            key={i}
            onClick={() => onRowClick(row)}
          >
            <Grid item xs={3} sm={2}>
              <span>#{row.job.ID}</span>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Avatar
                className={classes.elementInfoIcon}
                alt="Activity type icon"
                src={
                  row.job.project.avatar
                    ? getFileTarget(row.job.project.avatar, "100x100")
                    : PROJECT_AVATAR
                }
              />
            </Grid>
            <Grid item xs={2} sm={1} className={classes.iconContainer}>
              {row.job.jobType.icon ? (
                <Avatar
                  className={classes.elementInfoIcon}
                  alt="Activity type icon"
                  src={getFileTarget(row.job.jobType.icon, "100x100")}
                />
              ) : (
                <i
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "1.1rem",
                  }}
                  className="icon-case_v2"
                ></i>
              )}
            </Grid>
            <Grid item xs={2} sm={1} className={classes.iconContainer}>
              <Avatar
                className={classes.elementInfoIcon}
                alt="Activity type icon"
                src={
                  row.elements[0] && row.elements[0].user.imgUrl
                    ? getFileTarget(row.elements[0].user.imgUrl, "100x100")
                    : USER_AVATAR
                }
              />
            </Grid>
            <Grid item xs={3} sm={2} className={classes.iconContainer}>
              <span>
                {DateService.getFormattedDateWithoutYear(row.created_at)}
              </span>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default connectWithStore(BpQuestions, ["blueprintEditor"]);
