import React, { Component } from "react";

export default class Alert extends Component {
  render() {
    return (
      <div
        className={
          this.props.success
            ? "alert-success alert alert-dismissible fade show"
            : "alert-danger alert alert-dismissible fade show"
        }
        role="alert"
      >
        {this.props.label}

        <button
          onClick={() => this.props.closeAlert(this.props.target)}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }
}
