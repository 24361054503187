import React, { useState, useCallback, useEffect, forwardRef } from "react";
import Select from "react-select";
import { getStatusProps } from "../../../../services/job_status";
import { JOB_STATUS } from "../../../../services/job_status";
import i18n from "../../../../i18n";
import { connectWithStore } from "../../../../services/redux";

const StatusSelect = forwardRef((props, ref) => {
  const { onStatusChange, currentJob, settings, changeStatus } = props;
  const [status, setStatus] = useState([]);

  const InitialStatusSet = useCallback(() => {
    let statuses = [
      {
        value: JOB_STATUS.OPEN,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.OPEN.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.OPEN.toUpperCase()]
            : i18n.t("default:_OPEN"),
      },
      {
        value: JOB_STATUS.ASSIGNED,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.ASSIGNED.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.ASSIGNED.toUpperCase()]
            : i18n.t("default:_ASSIGNED"),
      },
      {
        value: JOB_STATUS.CONFIRMED,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.CONFIRMED.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.CONFIRMED.toUpperCase()]
            : i18n.t("default:_CONFIRMED"),
      },
      {
        value: JOB_STATUS.REJECTED,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.REJECTED.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.REJECTED.toUpperCase()]
            : i18n.t("default:_REJECTED"),
      },
      {
        value: JOB_STATUS.REPORTED,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.REPORTED.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.REPORTED.toUpperCase()]
            : i18n.t("default:_REPORTED"),
      },
      {
        value: JOB_STATUS.REVIEW,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.REVIEW.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.REVIEW.toUpperCase()]
            : i18n.t("default:_REVIEW"),
      },
      {
        value: JOB_STATUS.DONE,
        label:
          settings.statusTranslations &&
          settings.statusTranslations[JOB_STATUS.DONE.toUpperCase()]
            ? settings.statusTranslations[JOB_STATUS.DONE.toUpperCase()]
            : i18n.t("default:_DONE"),
      },
    ];
    statuses.sort((a, b) => settings.statusOrder[a.value.toUpperCase()] - settings.statusOrder[b.value.toUpperCase()])
    if (
      settings &&
      settings.customStatuses &&
      settings.customStatuses.length > 0
    ) {
      for (const status of settings.customStatuses) {
        statuses.push({
          value: status.name,
          label: status.name,
        });
      }
    }
    console.log(statuses);
    setStatus(statuses);
  }, [settings]);

  useEffect(() => {
    InitialStatusSet();
  }, [InitialStatusSet]);

  const getSelectCustomStyles = () => {
    const dot = (color = "#ccc") => {
      return {
        alignItems: "center",
        display: "flex",

        ":before": {
          backgroundColor: color,
          borderRadius: 10,
          content: '" "',
          display: "block",
          marginRight: 8,
          height: 10,
          width: 20,
        },
      };
    };

    return {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled }) => {
        const { color } = getStatusProps(data.value, settings);
        return {
          ...styles,
          backgroundColor: "white",
          color: "black",
          cursor: isDisabled ? "not-allowed" : "default",
          ...dot(color),
        };
      },
      input: (styles, { data }) => {
        const { color } = getStatusProps(data.value, settings);
        return { ...styles, ...dot(color) };
      },
      placeholder: (styles, { data }) => {
        const { color } = getStatusProps(data && data.value, settings);
        return { ...styles, ...dot(color) };
      },
      singleValue: (styles, { data }) => {
        const { color } = getStatusProps(data.value, settings);
        return { ...styles, ...dot(color) };
      },
    };
  };

  return (
    <Select
      styles={getSelectCustomStyles()}
      className="react-select"
      classNamePrefix="react-select"
      ref={ref}
      options={status}
      value={
        status.filter(
          (item) => item.value === currentJob && currentJob.status
        )[0]
      }
      onChange={onStatusChange}
      readonly
      menuIsOpen={changeStatus}
      isSearchable={false}
    />
  );
});

export default connectWithStore(StatusSelect, ["settings"]);
