import React, { useCallback } from "react";
import i18n from "../../i18n";
import { exportJob } from "../../services/api/jobs";

function ExportJobButton({ id, multiple }) {
  const onExportClick = useCallback(async () => {
    if (multiple) {
      const ids = id.map((job) => job._id);
      await exportJob(ids);
      return;
    }
    exportJob(id).then((response) => {
      if (!response) {
        return;
      }
      if (response.success) {
        window.open(response.link, "_blank");
      }
    });
  }, [id, multiple]);

  return (
    <button onClick={onExportClick} className="btn-full">
      <i className="mdi mdi-file-download" />
      <span>{i18n.t("default:_EXPORT_AS_PDF")}</span>
    </button>
  );
}

export default ExportJobButton;
