import React, { Component } from "react";
import i18n from "../../../i18n/index";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
/* const ButtonPending = require("./assets/bool/button pending.png");
const NoButton = require("./assets/bool/button no.png");
const YesButton = require("./assets/bool/button yes.png"); */

export default class Bool extends Component {
  static VALUE = {
    NO: "NO",
    YES: "YES",
  };

  state = {
    selected: null,
  };

  componentDidMount() {
    if (!this.props.question) {
      this.setState({
        selected: this.props.selected,
      });
      return;
    }
    let cmpData = this.props.answers[this.props.question.ID];

    if (
      cmpData &&
      typeof cmpData.value !== "undefined" &&
      cmpData.value !== null
    ) {
      this.setState({
        selected: cmpData.value,
      });
    }

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value && cmpData.value != null)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
  }

  chooseNo() {
    const next = Bool.VALUE.NO;
    this.setState(
      {
        selected: next,
      },
      () => this.props.handleChange(next)
    );
  }

  chooseYes() {
    const next = Bool.VALUE.YES;
    this.setState(
      {
        selected: next,
      },
      () => this.props.handleChange(next)
    );
  }

  isNo() {
    return this.state.selected === Bool.VALUE.NO;
  }

  isYes() {
    return this.state.selected === Bool.VALUE.YES;
  }

  render() {
    /* const NoBackground = this.isNo() ? NoButton : ButtonPending;
    const YesBackground = this.isYes() ? YesButton : ButtonPending; */
    return (
      <div className="question-content">
        <div className="form-row">
          <div className="form-group col-md-12 BoolComponent-Wrapper">
            <div
              className="BoolComponent "
              /* style={{
                backgroundImage: `url('${NoBackground}'), url('${YesBackground}')`,
                backgroundPosition: "left top, right top",
              }} */
            >
              <div
                onClick={() => this.chooseNo()}
                className={`BoolComponent-No BoolComponent-Part${
                  this.isNo() ? " selected" : ""
                }`}
                style={{
                  color:
                    this.props.question && this.props.question.settings.darkMode
                      ? "white"
                      : this.props.primary && this.isNo()
                      ? this.props.primary
                      : "#b0bac9",
                }}
              >
                <div className="hideDiv">
                  <span className="hide">
                    <CancelIcon style={{ width: "50px", height: "50px" }} />
                  </span>
                </div>
                <div>
                  <span dir="auto">{i18n.t("default:_NO")}</span>
                </div>
              </div>
              <div
                onClick={() => this.chooseYes()}
                className={`BoolComponent-Yes BoolComponent-Part${
                  this.isYes() ? " selected" : ""
                }`}
                style={{
                  color:
                    this.props.question && this.props.question.settings.darkMode
                      ? "white"
                      : this.props.primary && this.isYes()
                      ? this.props.primary
                      : "#b0bac9",
                }}
              >
                <div className="hideDiv">
                  <span className="hide">
                    <CheckCircleIcon
                      style={{ width: "50px", height: "50px" }}
                    />
                  </span>
                </div>
                <div>
                  <span dir="auto">{i18n.t("default:_YES")}</span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
