import {
  FILTER_VIEWS_SET_CHECKED,
  FILTER_VIEWS_SET_NAME,
  FILTER_VIEWS_SET_SAVED_FILTERS,
} from "../constants";

const initialState = {
  name: "",
  savedFilters: [],
  filtersChecked: false,
};

const filterViews = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case FILTER_VIEWS_SET_NAME:
      return Object.assign({}, state, {
        name: data,
      });
    case FILTER_VIEWS_SET_SAVED_FILTERS:
      return Object.assign({}, state, {
        savedFilters: data,
      });
    case FILTER_VIEWS_SET_CHECKED:
      return Object.assign({}, state, {
        filtersChecked: data,
      });
    default:
      return state;
  }
};

export default filterViews;
