import React, { useState, useEffect } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { STEP_NEW_USER, STEP_FINAL } from "../steps";
import SkillAutoComplete from "../../autoselect/skill_autoselect";
import { Wizard } from "../../../redux/actions";
import SKILL_COLORS from "../../../services/skill_colors";
import { WIZARD_TYPE_USER } from "../constants";
import { withRouter } from "react-router-dom";
import { assignSkillsToUser } from "../../../services/api/skills";

function SelectUserSkills({ goToStep, dispatch, isActive, wizardUser }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const onSkillsChange = (selected) => {
    setSelectedSkills(
      selected.filter((val) => val && val._id && typeof val._id !== "undefined")
    );
  };

  const registerUser = async () => {
    const userId = wizardUser._id;
    const skillIds = selectedSkills.map((skill) => skill._id);
    const data = {
      skills: skillIds,
    };
    await assignSkillsToUser(data, userId);
  };

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_SELECT_SKILLS")));
  }, [dispatch, isActive]);

  return (
    <div
      className="SelectJobType ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 10, width: "100%", maxWidth: 600, marginTop: 10 }}>
        <div className="form-group">
          <SkillAutoComplete
            onSkillsChange={(selected) => onSkillsChange(selected)}
            selectedSuggestions={selectedSkills}
            skillColor={SKILL_COLORS.GREEN}
          />
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_NEW_USER),
            variant: "white",
          },
          {
            label: i18n.t("default:_FINISH"),
            onClick: async () => {
              await registerUser();
              dispatch(Wizard.setType(WIZARD_TYPE_USER));
              goToStep(STEP_FINAL);
            },
            variant: "black",
            //disabled: selectedSkills.length === 0,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(withRouter(SelectUserSkills), [
  "wizardUser",
  "settings",
]);
