import React, { useState, useCallback, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AutoComplete from "./v2autoselect";
import { getSkillsList } from "../../services/api/skills";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import i18n from "../../i18n";
import SkillChip from "../../components/skills/skill_chip";
import { setSkillColor } from "../../services/helpers";
import SKILL_COLORS from "../../services/skill_colors";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "50px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  labelSpan: {
    paddingLeft: "20px",
  },
  labelSpanSelected: {
    color: "#408FF8",
    paddingLeft: 20,
  },
  skillChip: {
    marginRight: "20px",
    marginBottom: "15px",
    padding: "20px 0px",
    width: "180px",
    justifyContent: "space-evenly",
    border: "2px solid #B6BECD",
    color: "#B6BECD",
    borderRadius: "10px",
  },
  skillAvatar: {
    border: "3px solid #C7DF94",
    background: "#FDBD84",
    height: "30px",
    width: "30px",
  },
  skillIconAuto: {
    fontSize: "20px",
    lineHeight: "20px",
    height: "24px",
    width: "24px",
  },
  selectedRow: {
    background: "#D3D8E4",
    marginBottom: "20px",
    cursor: "pointer",
    "&:hover": {
      background: "#F5F5F5",
    },
  },
  checkedIcon: {
    color: "#408FF8",
  },
}));

function SkillAutoComplete({
  disableNonTemp,
  selectedSuggestions,
  maxSelection,
  onSkillsChange,
  initialSkills,
  selectedToRemove,
  setSelectedToRemove,
  userRole,
  skillColor,
  backgroundColor,
  inFilters,
  onDeselect,
}) {
  const classes = useStyles();
  const [maxSelected] = useState(maxSelection ? maxSelection : null);
  const [skillsList, setSkillsList] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState(
    selectedSuggestions ? selectedSuggestions : []
  );

  const updateSelectedSkills = useCallback(async () => {
    setSelectedSkills(selectedSuggestions);
  }, [selectedSuggestions]);

  const loadSkillsList = useCallback(async () => {
    const skillsFilterParams = {
      page_size: 100,
    };
    const response = await getSkillsList(skillsFilterParams);
    if (response.success) {
      setSkillsList(response.data.docs);
    }
  }, [setSkillsList]);

  const filterSkills = () => {
    const arrID = selectedSuggestions
      ? [...selectedSuggestions].map((skill) => skill._id)
      : [];
    const filtered =
      skillsList && skillsList.filter((skill) => !arrID.includes(skill._id));
    if (userRole) {
      const filteredByUser =
        filtered && filtered.filter((skill) => skill.roles.includes(userRole));
      return filteredByUser;
    } else {
      return filtered;
    }
  };

  useEffect(() => {
    loadSkillsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSelectedSkills();
  }, [updateSelectedSkills]);

  const getOptionLabel = (option) => {
    return option.name;
  };

  const renderOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon />}
            color="primary"
            style={{ marginRight: 8 }}
            checked={selectedSkills.includes(option)}
          />
        </Grid>
        <Grid item xs={2} container justifyContent="center">
          {option.avatar ? (
            <Avatar
              className={classes.skillAvatar}
              alt="skill icon"
              src={option.avatar}
            />
          ) : (
            <Avatar className={classes.skillAvatar}>
              <Grid
                container
                justify="center"
                alignContent="center"
                className={`mdi mdi-shield-check ${classes.skillIconAuto}`}
              ></Grid>
            </Avatar>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            overflow: "hidden",
            paddingRight: "10px",
            textOverflow: "ellipsis",
          }}
        >
          <span>{option.name}</span>
          {/* <SkillChip
            withAvatar
            skillAvatar={option.avatar}
            name={option.name}
          /> */}
        </Grid>
        <Grid item xs={3}>
          {/* <span>Permanent</span> */}
        </Grid>
      </Grid>
    );
  };

  const changeSelectedSkills = (option, reason) => {
    if (reason === "select-option") {
      const newSelectedSkills = [...selectedSkills];
      newSelectedSkills.push(option);
      if (onSkillsChange) {
        onSkillsChange(newSelectedSkills);
      }
      setSelectedSkills(newSelectedSkills);
    }
  };

  const setSelectedSkillColor = (skillId, expStatus) => {
    let skillIsInitial = false;
    if (initialSkills) {
      for (let skill of initialSkills) {
        if (skill._id === skillId) {
          skillIsInitial = true;
        }
      }
    }
    if (skillIsInitial) {
      if (skillColor) {
        return skillColor;
      } else {
        return setSkillColor(expStatus);
      }
    } else {
      return SKILL_COLORS.DARK_GREY;
    }
  };

  const setBackgroundColor = (skill) => {
    if (selectedToRemove && selectedToRemove.includes(skill)) {
      return SKILL_COLORS.WHITE;
    }
  };

  const handleClick = (skill) => {
    const updatedSkillsToRemove = [...selectedToRemove];
    if (updatedSkillsToRemove.includes(skill)) {
      updatedSkillsToRemove.splice(updatedSkillsToRemove.indexOf(skill), 1);
    } else {
      updatedSkillsToRemove.push(skill);
    }
    setSelectedToRemove(updatedSkillsToRemove);
  };

  function SelectedRow({ selected, index, /* item,  */ onClick }) {
    return (
      <ListItem
        key={index}
        className={classes.selectedRow}
        container
        alignItems="center"
      >
        <Checkbox
          onClick={onClick}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<CheckCircleIcon className={classes.checkedIcon} />}
          color="primary"
          style={{ marginRight: 8 }}
          checked={selectedSkills.includes(selected)}
        />
        {selected.avatar ? (
          <Avatar
            className={classes.skillAvatar}
            alt="skill icon"
            src={selected.avatar}
          />
        ) : (
          <Avatar className={classes.skillAvatar}>
            <Grid
              container
              justify="center"
              alignContent="center"
              className={`mdi mdi-shield-check ${classes.skillIconAuto}`}
            ></Grid>
          </Avatar>
        )}
        <ListItemText
          className={classes.labelSpanSelected}
          primary={selected.label}
        />
      </ListItem>
    );
  }

  const options = filterSkills();

  return (
    <React.Fragment>
      {(maxSelected === null || maxSelected > selectedSkills.length) &&
        !disableNonTemp && (
          <AutoComplete
            list={options}
            selected={selectedSkills}
            onChange={(option, reason) => changeSelectedSkills(option, reason)}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            noOptionsDisplay="none"
            text={i18n.t("default:_TYPE_TEXT_SEARCH")}
          />
        )}
      {selectedSuggestions.length > 0 && inFilters && (
        <List className={classes.root}>
          {selectedSkills.map((skill, index) => {
            return (
              <SelectedRow
                key={index}
                //item={row}
                selected={skill}
                onClick={() => onDeselect(skill)}
              />
            );
          })}
        </List>
      )}
      {selectedSuggestions.length > 0 && !inFilters && (
        <Grid className={classes.root}>
          {selectedSkills.map((skill, index) => {
            let skillStatus = skill.expirationStatus
              ? skill.expirationStatus.status
              : 3;
            let date = skill.expirationStatus
              ? skill.expirationStatus.date
              : null;
            return (
              <SkillChip
                withMargin
                key={index}
                withAvatar
                skillAvatar={skill.avatar}
                name={skill.name}
                skillColor={setSelectedSkillColor(skill._id, skillStatus)}
                backgroundColor={
                  backgroundColor ? backgroundColor : setBackgroundColor(skill)
                }
                handleClick={
                  selectedToRemove && setSelectedToRemove
                    ? () => handleClick(skill)
                    : null
                }
                date={date}
              />
            );
          })}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default SkillAutoComplete;
