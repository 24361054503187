import React from "react";
import { QaSummaryType } from "../../../services/question";

function PrevNextJobSummary({ question, currentJob }) {
  const renderJobLinks = (jobs) => {
    const allowedJobs = jobs.filter(
      (job) => job.jobType.settings.allowAddToSummary
    );
    return (
      <div className="JobPanelQuestion pointer">
        <div className="JobPanelQuestion-row">
          <div className="JobPanelQuestion-icon">
            <i
              className="mdi mdi-clipboard material-icons"
              style={{ color: "rgb(139,69,19)" }}
            ></i>
          </div>
          <div className="JobPanelQuestion-title">
            <span dir="auto">{question.label}</span>
          </div>
        </div>
        <div className="JobPanelQuestion-row">
          <div className="JobPanelQuestion-job-link">
            <h6>
              {allowedJobs.map((job, i) => {
                return (
                  <a
                    key={i}
                    href={`/jobs/${job.ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {job.jobType && job.jobType.title
                      ? job.jobType.title
                      : job.ID}{" "}
                    #{job.ID}
                    {i < allowedJobs.length - 1 && ", "}
                  </a>
                );
              })}
            </h6>
          </div>
        </div>
      </div>
    );
  };

  const getLinks = () => {
    if (
      question.id === QaSummaryType.PREVIOUS_JOB &&
      currentJob.prevJob &&
      currentJob.prevJob.length > 0
    ) {
      return renderJobLinks(currentJob.prevJob);
    }
    if (
      question.id === QaSummaryType.NEXT_JOB &&
      currentJob.next_jobs &&
      currentJob.next_jobs.length > 0
    ) {
      return renderJobLinks(currentJob.next_jobs);
    }
    return null;
  };

  return getLinks();
}

export default PrevNextJobSummary;
