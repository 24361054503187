import Http from "../http";

export const getTemplatesList = async (params) => {
  const path = `/v2/${params.instance}/templates`;

  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const checkForTemplate = async (params) => {
  const path = `/v2/${params.instance}/${params.id}/template`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createTemplate = async (params) => {
  const path = `/v2/${params.instance}/${params.id}/template`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createTemplateWithCustomName = async (params) => {
  const path = `/v2/${params.instance}/${params.id}/template`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      name: params.name,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteTemplate = async (params, byId = false) => {
  const path = `/v2/${params.instance}/${params.id}/template${
    byId ? "-by-id" : ""
  }`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createFromTemplate = async ({ id, instance, filters, name }) => {
  const path = `/v2/${instance}/template/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      filters,
      name,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};
