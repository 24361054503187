import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { connectWithStore } from "../../../services/redux";
import { getCurrentUser, feedsUsers } from "../../../services/api/user";
import i18n from "../../../i18n";
import {
  User,
  TopNavMenu,
  Settings,
  NotificationPanel,
  GridTable,
  Sso,
} from "../../../redux/actions";
import CreateNewEntity from "../../../components/create_wizard/create_new_entity";
import CreateNewJobType from "../../../components/job_type_wizard/create_new_job_type";
import Http from "../../../services/http";
import { getFileTarget } from "../../../services/api/file_upload";
import "./header.scss";
import { withRouter } from "react-router-dom";
import { getFeatureToggles } from "../../../services/api/settings";
import ToggleService from "../../../services/toggle";
import UserSelectModal from "../modals/user_select_modal";
import LoginService from "../../../services/login";
import {
  APP_SKILLS,
  APP_BLUEPRINTS,
  APP_MICROSOFT_SSO,
} from "../../../services/app";
import { getFeatureSettings } from "../../../services/api/settings";

class Header extends Component {
  notificationsModal = null;

  constructor(props) {
    super(props);
    this.state = {
      dropdownProfile: false,
      modalOpen: false,
      jobTypeModalOpen: false,
      modalNotificationsOpen: false,
      navMenu: false,
      featureToggles: [],
      showUserSelectModal: false,
      targetImpersonate: null,
    };
    if (this.props.user.profile) {
      this.getCurrentUserData();
    }
    this.ref = null;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (
      nextProps.topNavMenu &&
      nextProps.topNavMenu.activeMenu !== state.navMenu
    ) {
      return {
        navMenu: nextProps.topNavMenu.activeMenu,
      };
    }
    return null;
  }

  async getCurrentUserData() {
    const res = await getCurrentUser();
    if (res) {
      if (!res.id) {
        res.id = res._id;
      }
      this.props.dispatch(User.setProfileData(res));
    }
  }

  async logout() {
    if (LoginService.isImpersonating(this.props.settings)) {
      const userProfile = JSON.parse(localStorage.getItem("oldUserProfile"));
      const accessToken = JSON.parse(localStorage.getItem("oldAccessToken"));
      localStorage.removeItem("oldUserProfile");
      localStorage.removeItem("oldAccessToken");

      this.props.dispatch(User.setAccessToken(accessToken));
      this.props.dispatch(User.updateProfileData(userProfile));

      window.location.href = "/";

      return true;
    }
    /**
     * TODO: call POST /v2/auth/logout first here!!!
     */
    this.setState({ dropdownProfile: false });
    this.props.dispatch(User.logout());
    this.props.dispatch(Settings.loadAppSettings());
    Http.sharedInstance.resetAxiosHeaders();
    this.props.history.push("/auth");
  }

  onChange(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  toggleModal = (modal) => {
    Object.keys(this.state).forEach((state) => {
      if (state.indexOf("modal") > -1 && state !== modal) {
        this.setState({ [state]: false });
      }
    });

    this.setState({ [modal]: !this.state[modal] });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  closeJobTypeModal = () => {
    this.setState({ jobTypeMmodalOpen: false });
  };

  closeAlert = (state) => {
    this.setState({ [state]: null });
  };

  toogleProfile() {
    this.setState({ dropdownProfile: !this.state.dropdownProfile });
  }

  toggleMenu = () => {
    this.setState({ navMenu: !this.state.navMenu });
    this.props.dispatch(TopNavMenu.setActiveMenu(!this.state.navMenu));
  };

  hideMenu = () => {
    this.setState({ navMenu: false });
    this.props.dispatch(TopNavMenu.setActiveMenu(false));
  };

  navigate({ url, callback }) {
    if (url) {
      this.props.history.push(url);
      setTimeout(() => {
        if (callback) {
          callback();
        }
      }, 1000);
    }
    if (!url && callback) {
      callback();
    }
  }

  goToPage(page) {
    if (page.indexOf("http://") !== -1 || page.indexOf("https://") !== -1) {
      window.location = page;
    }
    this.setState(
      {
        dropdownProfile: false,
      },
      () => {
        this.props.history.push(page);
      }
    );
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside.bind(this));
    this.props.modalNewPass(this.toggleModal.bind(this));
    //this.props.modalNewJobType(this.toggleJobTypeModal.bind(this));
    this.props.setHideFunction(this.hideMenu.bind(this));

    this.setState(
      {
        featureToggles: await getFeatureToggles(),
      },
      async () => {
        //set microsoft settings after getting the toggles, if app is on
        const { featureToggles } = this.state;
        const activeToggles = featureToggles.filter(
          (toggle) => toggle.isActive
        );
        const toggleIds = activeToggles.map((toggle) => toggle.toggleId);
        if (toggleIds.includes(APP_MICROSOFT_SSO)) {
          const params = {
            toggleId: APP_MICROSOFT_SSO,
          };
          const ssoSettings = await getFeatureSettings(params);
          this.props.dispatch(Sso.setSettings(ssoSettings));
        }
      }
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "mousedown",
      this.handleClickOutside.bind(this)
    );
  }

  handleClickOutside(event) {
    if (
      this.state.dropdownProfile &&
      this.ref &&
      !this.ref.contains(event.target)
    ) {
      this.setState({
        dropdownProfile: false,
      });
    }
  }

  toggleNotificationPanel() {
    if (this.props.notificationPanel.visible) {
      this.props.dispatch(NotificationPanel.hide());
    } else {
      this.props.dispatch(GridTable.setRightPanelOpen(false));
      this.props.dispatch(NotificationPanel.show());
      feedsUsers({
        id: this.props.user.profile.id,
        mark_as_read: true,
      }).then(() => {});
    }
  }

  onSearchClick() {
    if (this.props.topNavMenu.searchHandler) {
      this.props.topNavMenu.searchHandler();
    }
  }

  toggleUserSelectModal() {
    const { showUserSelectModal } = this.state;

    this.setState({
      showUserSelectModal: !showUserSelectModal,
    });
  }

  async impersonate() {
    const { targetImpersonate } = this.state;

    if (targetImpersonate && targetImpersonate.length === 1) {
      const user = targetImpersonate[0];

      if (user.value === this.props.user.profile.id) {
        this.setState({
          showUserSelectModal: false,
        });
        return false;
      }

      if (
        !ToggleService._isToggleActive(
          "impersonate",
          this.props.settings.toggles
        )
      ) {
        return false;
      }
      localStorage.setItem(
        "oldUserProfile",
        JSON.stringify(this.props.user.profile)
      );
      localStorage.setItem(
        "oldAccessToken",
        JSON.stringify(this.props.user.access_token)
      );
      const json = await LoginService.doImpersonate(
        user.hasOwnProperty("subLabel") ? user.subLabel : user.label
      );

      this.props.dispatch(User.setAccessToken(json));
      const res = await getCurrentUser();
      if (res) {
        this.props.dispatch(User.setImpersonateProfileData(res));
        this.props.dispatch(Settings.loadAppSettings());
      }

      window.location.href = "/";
    }

    this.setState({
      showUserSelectModal: false,
    });
  }

  render() {
    const { showUserSelectModal, jobTypeModalOpen } = this.state;
    const unreadCount = this.props.notificationPanel.unread_count
      ? this.props.notificationPanel.unread_count
      : 0;
    return (
      <div
        className={
          "header admin-header" +
          (LoginService.isImpersonating(this.props.settings)
            ? ""
            : " color-primary")
        }
        style={{
          backgroundColor: LoginService.isImpersonating(this.props.settings)
            ? "#FFCCCC"
            : null,
        }}
      >
        <CreateNewEntity
          modalIsOpen={this.state.modalOpen || this.props.topNavMenu.openWizard}
          toggleModal={this.toggleModal}
          userRole={this.props.user.profile.role}
          dismiss={this.closeModal.bind(this)}
          featureToggles={this.state.featureToggles}
          jobOnly={this.props.topNavMenu.openJobWizard}
        />
        <CreateNewJobType
          modalIsOpen={jobTypeModalOpen}
          toggleModal={this.toggleModal}
          dismiss={this.closeJobTypeModal.bind(this)}
          featureToggles={this.state.featureToggles}
        />
        <UserSelectModal
          maxSelection={1}
          show={showUserSelectModal}
          triggerImmediateChange={true}
          onChange={(users) => this.setState({ targetImpersonate: users })}
          close={() => this.impersonate()}
        />
        <MediaQuery minWidth={992}>
          <div className="header-content">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                className={
                  this.state.navMenu
                    ? "hamburger hamburger--collapse is-active"
                    : "hamburger hamburger--collapse"
                }
                onClick={this.toggleMenu}
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>

              <h1
                style={{
                  color:
                    this.props.settings.branding &&
                    this.props.settings.branding.primary &&
                    this.props.settings.branding.primary.color
                      ? this.props.settings.branding.primary.color.hex
                      : "#0083C9",
                }}
              >
                {this.props.pageTitle ? this.props.pageTitle.title : ""}
              </h1>
            </div>
            <div className="headers-buttons">
              {this.props.buttons &&
                this.props.buttons.map((button, index) => {
                  return (
                    <button
                      style={{
                        borderColor:
                          this.props.settings.branding &&
                          this.props.settings.branding.primary &&
                          this.props.settings.branding.primary.color
                            ? this.props.settings.branding.primary.color.hex
                            : "#0083C9",
                        ...(!button.active
                          ? {
                              background: "#fff",
                              color:
                                this.props.settings.branding &&
                                this.props.settings.branding.primary &&
                                this.props.settings.branding.primary.color
                                  ? this.props.settings.branding.primary.color
                                      .hex
                                  : "#0083C9",
                            }
                          : {
                              color: "#fff",
                              background:
                                this.props.settings.branding &&
                                this.props.settings.branding.primary &&
                                this.props.settings.branding.primary.color
                                  ? this.props.settings.branding.primary.color
                                      .hex
                                  : "#0083C9",
                            }),
                      }}
                      key={index}
                      onClick={() => this.navigate(button)}
                      className={
                        !button.active
                          ? "btn btn-outline-dark"
                          : "btn btn-outline-dark active"
                      }
                    >
                      {button.label}
                    </button>
                  );
                })}
            </div>
            <div className="header-inner">
              <ul className="nav-profile">
                {/*<li>
                                    <button className="btn btn-rounded btn-success new" onClick={() => this.toggleModal('modalOpen')}>{i18n.t('default:_NEW')}</button>
                                </li>*/}
                <li>
                  <span className="circle-link">
                    <i
                      className={"icon-bell"}
                      onClick={() => this.toggleNotificationPanel()}
                    />
                    {unreadCount > 0 && (
                      <span className="circle-link-badge leading-colour-background">
                        {unreadCount}
                      </span>
                    )}
                  </span>
                </li>
                <li
                  className="nav-profile-user"
                  onClick={() => this.toogleProfile()}
                >
                  <span
                    style={{
                      color:
                        this.props.settings.branding &&
                        this.props.settings.branding.primary &&
                        this.props.settings.branding.primary.color
                          ? this.props.settings.branding.primary.color.hex
                          : "#0083C9",
                    }}
                    className="nav-profile-avatar"
                  >
                    {this.props.user.profile.imgUrl ? (
                      <img
                        referrerPolicy="no-referrer"
                        src={getFileTarget(
                          this.props.user.profile.imgUrl,
                          "100x100"
                        )}
                        alt="avatar"
                      />
                    ) : (
                      <i className="circle mdi mdi-account" />
                    )}
                  </span>
                  <span
                    style={{
                      color:
                        this.props.settings.branding &&
                        this.props.settings.branding.primary &&
                        this.props.settings.branding.primary.color
                          ? this.props.settings.branding.primary.color.hex
                          : "#0083C9",
                    }}
                  >
                    {this.props.user.profile.name
                      ? this.props.user.profile.name.first +
                        " " +
                        this.props.user.profile.name.last
                      : this.props.user.profile.email}
                  </span>

                  <div
                    ref={(ref) => (this.ref = ref)}
                    className={
                      this.state.dropdownProfile
                        ? "dropdown-menu nav-utils show"
                        : "dropdown-menu nav-utils"
                    }
                  >
                    <span onClick={() => this.goToPage("/profile")}>
                      {i18n.t("default:_PROFILE")}
                    </span>
                    {(this.props.user.profile.role === "sys_admin" ||
                      this.props.user.profile.role === "business_admin") && (
                      <span onClick={() => this.goToPage("/settings")}>
                        {i18n.t("default:_SETTINGS")}
                      </span>
                    )}
                    {this.props.user.profile.role === "business_admin" &&
                      this.props.apps.enabled.includes(APP_SKILLS) && (
                        <span onClick={() => this.goToPage("/settings/skills")}>
                          {i18n.t("default:_SKILLS")}
                        </span>
                      )}
                    {this.props.user.profile.role === "business_admin" &&
                      this.props.apps.enabled.includes(APP_BLUEPRINTS) && (
                        <span
                          onClick={() => this.goToPage("/settings/blueprints")}
                        >
                          {i18n.t("default:_BLUEPRINTS")}
                        </span>
                      )}
                    {this.props.user.profile.role === "business_admin" && (
                      <span
                        onClick={() => this.goToPage("/settings/automations")}
                      >
                        Automations
                      </span>
                    )}
                    {this.props.user.profile.role === "business_admin" &&
                      this.props.apps.enabled.includes(APP_MICROSOFT_SSO) && (
                        <span
                          onClick={() =>
                            this.goToPage("/settings/microsoft_sso")
                          }
                        >
                          {i18n.t("default:_MICROSOFT_SINGLE_SIGN_ON")}
                        </span>
                      )}
                    {this.props.user.profile.role === "sys_admin" && (
                      <span onClick={() => this.goToPage("/workers")}>
                        {i18n.t("default:_WORKERS")}
                      </span>
                    )}
                    {this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null && (
                        <span onClick={() => this.goToPage("/instances")}>
                          {i18n.t("default:_INSTANCES")}
                        </span>
                      )}
                    {ToggleService._isToggleActive(
                      "impersonate",
                      this.props.settings.toggles
                    ) &&
                      this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null &&
                      !LoginService.isImpersonating(this.props.settings) && (
                        <span
                          onClick={() => this.toggleUserSelectModal()}
                          style={{
                            color: "#ffcccc",
                          }}
                        >
                          {i18n.t("default:_IMPERSONATE")}
                        </span>
                      )}
                    {this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null && (
                        <span
                          onClick={() =>
                            this.goToPage("https://qaenv.pbsisrael.xyz")
                          }
                        >
                          {i18n.t("default:_TEST_SERVER")}
                        </span>
                      )}
                    {this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null && (
                        <a href={"/v1/documentation"}>API v1 Docs</a>
                      )}
                    {this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null && (
                        <a href={"/v2/documentation"}>API v2 Docs</a>
                      )}
                    {this.props.user.profile.role === "sys_admin" &&
                      this.props.settings.host === null && (
                        <span
                          onClick={() =>
                            this.goToPage("https://samurai-app.com/graylog")
                          }
                        >
                          {i18n.t("default:_LOG_SERVER")}
                        </span>
                      )}
                    <span onClick={() => this.logout()}>
                      {LoginService.isImpersonating(this.props.settings)
                        ? i18n.t("default:_RETURN_TO_ACCOUNT")
                        : i18n.t("default:_LOGOUT")}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={992}>
          <div className="header-content">
            <button
              className={
                this.state.navMenu
                  ? "hamburger hamburger--collapse is-active"
                  : "hamburger hamburger--collapse"
              }
              onClick={this.toggleMenu}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>

            <h1>{this.props.pageTitle ? this.props.pageTitle.title : ""}</h1>
            {["/jobs", "/projects", "/users", "/files"].includes(
              this.props.location.pathname
            ) && (
              <div style={{ position: "relative", color: "#9b9b9b" }}>
                <i
                  className="mdi mdi-magnify search"
                  onClick={() => this.onSearchClick()}
                />
                {/* {this.props.topNavMenu.isActiveFilter && (
                  <span
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 10,
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                    }}
                    className={"circle-link-badge leading-colour-background"}
                  >
                    &nbsp;
                  </span>
                )} */}
              </div>
            )}
            {!["/jobs", "/projects", "/users", "/files"].includes(
              this.props.location.pathname
            ) && <div style={{ width: 26 }} />}
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default connectWithStore(withRouter(Header), [
  "pageTitle",
  "user",
  "topNavMenu",
  "settings",
  "notificationPanel",
  "apps",
]);
