import React, { useEffect } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { STEP_NEW_USER, STEP_SELECT_PROJECTS } from "../steps";
import { Wizard, WizardUser } from "../../../redux/actions";
import { withRouter } from "react-router-dom";
import CompanyAutocomplete from "../../autoselect/company_autoselect";

function SelectUserCompanies({
  goToStep,
  dispatch,
  isActive,
  wizardUser,
  fromUserEditor,
}) {
  const onCompanyChange = (selected) => {
    dispatch(
      WizardUser.setCompanies({
        companies: selected.filter(
          (val) => typeof val !== "undefined" && val !== "" && val !== null
        ),
      })
    );
  };

  useEffect(() => {
    if (isActive)
      dispatch(Wizard.setTitle(i18n.t("default:_SELECT_COMPANIES")));
  }, [dispatch, isActive]);
  return (
    <div
      className="SelectJobType ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {fromUserEditor && (
        <h4 style={{ margin: "40px" }}>
          {i18n.t("default:_SELECT_COMPANIES")}
        </h4>
      )}
      <div style={{ flex: 10, width: "100%", maxWidth: 600, marginTop: 10 }}>
        <div className="form-group">
          <CompanyAutocomplete
            onCompanyChange={(selected) => onCompanyChange(selected)}
            selected={wizardUser.companies}
          />
        </div>
      </div>
      {!fromUserEditor && (
        <WizardButtons
          actions={[
            {
              label: i18n.t("default:_BACK"),
              onClick: () => goToStep(STEP_NEW_USER),
              variant: "white",
            },
            {
              label: i18n.t("default:_NEXT"),
              onClick: () => goToStep(STEP_SELECT_PROJECTS),
              variant: "black",
              disabled: false,
            },
          ]}
        />
      )}
    </div>
  );
}

export default connectWithStore(withRouter(SelectUserCompanies), [
  "wizardUser",
  "settings",
]);
