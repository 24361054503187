import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#999",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    fontSize: "400",
  },
  activeButton: {
    color: "#606060",
  },
}));

function RichTextButton({ onButtonClick, type, isActive, button }) {
  const classes = useStyles();

  return (
    <Grid
      onMouseDown={(event) => {
        onButtonClick(button.style, type);
        event.preventDefault();
      }}
      onTouchStart={(event) => {
        onButtonClick(button.style, type);
        event.preventDefault();
      }}
      className={`${classes.button} ${isActive ? classes.activeButton : ""}`}
    >
      {button.label}
    </Grid>
  );
}

export default RichTextButton;
