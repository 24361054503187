import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const logo = require("../../../assets/img/samurai_logo.png");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: ({ backgroundImg }) => (backgroundImg ? "white" : "inherit"),
  },
  logo: {
    margin: "5px",
    height: "50px",
    width: "50px",
  },
  img: {
    width: "auto",
  },
  logoText: {
    color: "#487DB2",
  },
}));

function SamuraiLogo({ backgroundImg }) {
  const classes = useStyles({ backgroundImg });

  return (
    <Grid container direction="column" className={classes.root}>
      <Avatar
        variant="square"
        className={classes.logo}
        classes={{
          img: classes.img,
        }}
        src={logo}
      />
      <span className={classes.logoText}>
        Powered by{" "}
        <b>
          <a href="https://www.samurai-app.com">Samurai</a>
        </b>
      </span>
    </Grid>
  );
}

export default SamuraiLogo;
