import Http from "../http";

export const getQuestionsForReport = async (params) => {
  const path = `/v1/reports/questions`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getReportFiles = async ({ id, job }) => {
  const path = `/v1/reports/${id}/files/${job}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getReportCounters = async ({ id }) => {
  const path = `/v1/reports/${id}/counters`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createReport = async (params) => {
  const path = `/v1/reports`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const showReport = async (params) => {
  const path = `/v1/reports/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const showReportPaginated = async (params) => {
  const path = `/v1/reports/${params.id}/paginated?page=${params.page}&pageSize=${params.pageSize}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateReport = async (params) => {
  const path = `/v1/reports/${params.id}`;
  try {
    const response = await Http.sharedInstance
      .withAuth()
      .patch(path, params.data);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getReportList = async (params) => {
  const path = `/v1/reports`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, { params });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteReport = async (params) => {
  const path = `/v2/report/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateColumn = async (params) => {
  const path = `/v1/reports/${params.id}/column`;
  try {
    const response = await Http.sharedInstance.withAuth().patch(path, {
      oldName: params.oldName,
      newName: params.newName,
      index: params.index,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const dashboard = async () => {
  const path = `/v1/reports/dashboard`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const exportSpreadsheet = async (params) => {
  const path = `/v1/reports/export/xlsx/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const exportPdf = async (params) => {
  const path = `/v1/reports/export/pdf/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getTemplateList = async (params) => {
  const path = `/v1/reports/templates`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createTemplate = async (params) => {
  const path = `/v1/reports/templates`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const updateTemplate = async ({ id, name }) => {
  const path = `/v1/reports/templates/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      name,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const removeTemplate = async (params) => {
  const path = `/v1/reports/templates/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const cloneReport = async (params) => {
  const path = `/v1/reports/${params.id}/clone`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      filters: params.filters ? params.filters : null,
    });
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const refreshReport = async (params) => {
  const path = `/v1/reports/${params.id}/refresh`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
    };
  }
};
