import React, { useState, useCallback } from "react";
import FieldPreview from "./field_preview";
import { CurrentProject } from "../../../redux/actions";
import { TextField, Grid, Button } from "@material-ui/core";
import { updateSignleProjectField } from "../../../services/api/projects";
import withModal from "../../../hoc/withModal";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { makeStyles } from "@material-ui/core/styles";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import { canUserEdit } from "../../../services/access";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100%",
  },
  previewText: {
    fontWeight: 400,
  },
}));

let lateUpdate = null;

const TextAreaModal = withModal(TextField);

function TextModal({
  visible,
  dispatch,
  onClose,
  id,
  currentText,
  project,
  title,
  accessRules,
  user,
}) {
  const classes = useStyles();
  const onTextChange = (newValue) => {
    if (lateUpdate) {
      clearTimeout(lateUpdate);
      lateUpdate = null;
    }
    lateUpdate = setTimeout(async () => {
      await updateSignleProjectField({
        id: project._id,
        field: {
          id,
          value: newValue,
        },
      });
      dispatch(
        CurrentProject.updateProject({
          [id]: newValue,
        })
      );
      lateUpdate = null;
    }, 500);
  };
  const FooterComponent = function FooterComponent() {
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        style={{
          padding: 10,
          paddingRight: 20,
        }}
      >
        <Button onClick={() => onClose()} variant="contained" color="primary">
          {i18n.t("default:_SAVE")}
        </Button>
      </Grid>
    );
  };

  return (
    <TextAreaModal
      visible={visible}
      onClose={() => onClose()}
      onChange={(e) =>
        canUserEdit(user, accessRules) && onTextChange(e.target.value)
      }
      defaultValue={currentText}
      title={title}
      multiline={true}
      className={classes.textarea}
      rows={10}
      variant="outlined"
      FooterComponent={canUserEdit(user, accessRules) && FooterComponent}
    />
  );
}

const TextModalConnected = connectWithStore(TextModal, []);

function Text({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  user,
}) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const { project } = currentProject;
  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);
  return (
    <div>
      {!editable && (
        <TextModalConnected
          project={project}
          onClose={onClose}
          id={id}
          currentText={project[id]}
          visible={modalVisible}
          title={label}
          accessRules={accessRules}
          user={user}
        />
      )}
      <FieldPreview
        label={/* !editable && project[id] ? project[id] :  */ label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.TEXT]}
        onClick={() => (!editable ? setModalVisible(true) : null)}
        accessRules={accessRules}
      >
        <Grid className={classes.previewText}>
          {!editable && project[id] ? project[id] : null}
        </Grid>
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(Text, ["currentProject", "user"]);
