import React from "react";
import { connectWithStore } from "../../../services/redux";
import DateService from "../../../services/date";
import { getFileTarget } from "../../../services/api/file_upload";

const defaultProjectAvatar = require("../../../assets/img/project-placeholder.png");

function LeftPanel({ wizardJob }) {
  const displayValues = [];
  if (wizardJob.jobType) {
    displayValues.push({
      icon: wizardJob.jobType.icon ? (
        <img
          src={getFileTarget(wizardJob.jobType.icon, "100x100")}
          alt={wizardJob.jobType.title}
        />
      ) : (
        <div className="IconAvatar">
          <i className="mdi mdi-briefcase-outline" />
        </div>
      ),
      text: wizardJob.jobType.title,
    });
  }
  if (wizardJob.project) {
    displayValues.push({
      icon: wizardJob.project.avatar ? (
        <img
          src={getFileTarget(wizardJob.project.avatar, "100x100")}
          alt={wizardJob.project.name}
        />
      ) : (
        <img src={defaultProjectAvatar} alt={wizardJob.project.name} />
      ),
      text: wizardJob.project.name,
    });
  }
  if (wizardJob.startDate) {
    displayValues.push({
      icon: <i className="mdi mdi-clock leading-colour" />,
      text: DateService.getFormattedDate(wizardJob.startDate),
    });
  }

  if (wizardJob.prevJob) {
    if (wizardJob.prevJob.length === 1) {
      displayValues.push({
        icon: wizardJob.prevJob[0].avatar,
        text: wizardJob.prevJob[0].label,
      });
    } else {
      displayValues.push({
        icon: wizardJob.prevJob[0].avatar,
        text: wizardJob.prevJob.map((j) => j.label).join(", "),
      });
    }
  }
  return (
    <div className="fullHeight desktopOnly LeftPanel-Wizard">
      <ul>
        {displayValues.length > 0 &&
          displayValues.map(({ icon, text }, index) => {
            return (
              <li key={index}>
                <div className="icon circle h5">{icon}</div>
                <div className="text h5">{text}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default connectWithStore(LeftPanel, ["wizardJob"]);
