import React from "react";
import ReactDOM from "react-dom";
import SamuraiFrontend from "./apps/SamuraiFrontend";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
//import { register } from "./services/serviceWorker";
import i18n from "./i18n";
import store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "toastr/build/toastr.css";
import "animate.css/animate.css";
import "./index.scss";
import "./theme/icons/css/fontello.css";
import "react-block-ui/style.css";
import "./ui_updates.scss";
import "draft-js/dist/Draft.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js-anchor-plugin/lib/plugin.css";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import "./../node_modules/flatpickr/dist/themes/material_blue.css";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { GoogleOAuthProvider } from '@react-oauth/google';

const error = console.error;

console.error = function (errMessage) {
  if (
    typeof errMessage == "string" &&
    (errMessage.indexOf(
      `Warning`
    ) !== -1 ||
      errMessage.indexOf(`React does not recognize the`) !== -1)
  ) {
    return;
  }
  return error.apply(console, arguments);
};

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId="745704541063-hqva5p81cl0qftomi9gt30362sk95rfa.apps.googleusercontent.com">
          <I18nextProvider store={store} i18n={i18n}>
            <SamuraiFrontend />
          </I18nextProvider>
        </GoogleOAuthProvider>

      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
// if (module.hot) {
//   module.hot.accept(() => {
//     ReactDOM.render(<App />, document.getElementById("root"));
//   });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//register();
