import React, { useEffect, useState } from "react";
import UserFieldWrapper from "./field_wrapper";
import { getFileTarget } from "../../../services/api/file_upload";
import i18n from "../../../i18n";
import { Button, Grid } from "@material-ui/core";

const avatarProject = require("../../../assets/img/project-image.png");

function UserProjectsField({ user }) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (!user.projects) return;
    const allProjects = Object.keys(user.projects);
    const projectsShortList =
      allProjects.length > 5 ? allProjects.slice(0, 5) : allProjects;
    setProjects(projectsShortList);
  }, [user.projects]);

  const loadAllProjects = () => {
    setProjects(Object.keys(user.projects));
  };

  return (
    <UserFieldWrapper label={i18n.t("default:_PROJECTS")}>
      <ul className="list-projects">
        {projects.length > 0
          ? projects.map((project, i) => {
              let currentProject = user.projects[project];
              return (
                <li key={i}>
                  <img
                    alt="avatar"
                    src={
                      currentProject?.avatar
                        ? getFileTarget(currentProject.avatar, "100x100")
                        : avatarProject
                    }
                  />
                  <span>{currentProject?.name}</span>
                </li>
              );
            })
          : "-"}
      </ul>
      {user.projects && Object.keys(user.projects).length > projects.length && (
        <Grid container justifyContent="center">
          <Button color="primary" onClick={() => loadAllProjects()}>
            {i18n.t("default:_LOAD_MORE")}
          </Button>
        </Grid>
      )}
    </UserFieldWrapper>
  );
}

export default UserProjectsField;
