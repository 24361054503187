import React from "react";
import { setSkillColor } from "../../services/helpers";
import { Grid, Typography } from "@material-ui/core";
import SkillChip from "../skills/skill_chip";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import HtmlTooltip from "../tooltip/html_tooltip";
import i18n from "../../i18n";
import SKILL_COLORS from "../../services/skill_colors";

const SkillToolip = ({ skills }) => (
  <HtmlTooltip
    title={
      <React.Fragment>
        <Grid
          container
          direction="row"
          wrap="wrap"
          justify="center"
          alingnItems="center"
        >
          {(!skills || skills.length === 0) && (
            <Typography color="secondary" variant="body1">
              {i18n.t("default:_NO_SKILLS")}
            </Typography>
          )}
          {skills &&
            skills.length > 0 &&
            skills.map((skill, index) => {
              return (
                <Grid item key={index}>
                  <SkillChip
                    withMargin={true}
                    backgroundColor={SKILL_COLORS.WHITE}
                    withAvatar
                    sidePanel={true}
                    skillAvatar={skill.avatar}
                    name={skill.name}
                    skillColor={
                      skill.expirationStatus
                        ? setSkillColor(skill.expirationStatus.status)
                        : SKILL_COLORS.GREEN
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </React.Fragment>
    }
  >
    <VerifiedUserIcon />
  </HtmlTooltip>
);

export default SkillToolip;
