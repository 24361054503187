import React, { lazy } from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, Typography, Avatar } from "@material-ui/core";
import UserAutocomplete from "../../autoselect/user_autoselect";
import ActivityTypeAutocomplete from "../../autoselect/activity_type_autoselect";
import BlueprintAutocomplete from "../../autoselect/blueprint_autoselect";
import { FiltersBlueprints } from "../../../redux/actions/index";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import DateService from "../../../services/date";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { isMobileDevice } from "../../../services/mobile";
import moment from "moment";
import StatusSelect from "../../../pages/settings/automations/common/status_select";
import SimpleAutocomplete from "../../autoselect/simple_autocomplete";
import {
  MEASURE_TOOL_HORIZONTAL,
  MEASURE_TOOL_VERTICAL,
  MOVE_TOOL,
} from "./constants";
//import RangePicker from "../../date_picker/range_picker";

const Flatpickr = lazy(() => import("react-flatpickr"));

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #F0F0F0",
    maxWidth: "360px",
    paddingBottom: 30,
  },
  filterRow: {
    marginTop: isMobileDevice() ? 15 : 30,
  },
  infoRow: {
    marginLeft: "10%",
    marginBottom: 10,
  },
  dateInput: {
    width: "80%",
  },
  chip: {
    marginLeft: 10,
    marginBottom: 10,
  },
  clearFilters: {
    marginTop: 20,
    marginLeft: "10%",
  },
}));

function BlueprintFilters({
  bluePrintsFilters,
  dispatch,
  blueprintEditor,
  settings,
}) {
  const classes = useStyles();
  const {
    filterUser,
    filterDate,
    filterJobType,
    filterElement,
    filterStatus,
    filterJobId,
  } = bluePrintsFilters;

  const { jobs, toolbar, showEditBpModal } = blueprintEditor;

  const userSelect = (userArr) => {
    const newFilter = [...filterUser];
    const user = userArr.pop();
    if (filterUser.indexOf(user) === -1) {
      newFilter.push(user);
    }
    dispatch(FiltersBlueprints.setFilterUser(newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const changeDate = (v) => {
    let dateFilter = [];
    let val;
    if (Array.isArray(v) && v.length > 1) {
      val = Object.assign(
        {},
        {
          from: moment(v[0]),
          to: moment(v[1]),
        }
      );
    }
    dateFilter.push(val);
    dispatch(FiltersBlueprints.setFilterDate(dateFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const jobTypeSelect = (jobTypeArr) => {
    const newFilter = [...filterJobType];
    const jobType = jobTypeArr.pop();
    if (filterJobType.indexOf(jobType) === -1) {
      newFilter.push(jobType);
    }

    dispatch(FiltersBlueprints.setFilterJobType(newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const changeBlueprint = (bp) => {
    const newFilter = [...filterElement];
    if (filterElement.indexOf(bp) === -1) {
      newFilter.push(bp);
    }

    dispatch(FiltersBlueprints.setFilterElement(newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const onStatusFilterChange = (e, statuses) => {
    const newFilter = [...filterStatus];
    const status = statuses.pop();
    const filterValues = newFilter.map((status) => status.value);
    if (filterValues.indexOf(status.value) === -1) {
      newFilter.push(status);
    }
    dispatch(FiltersBlueprints.setFilterStatus(newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const onJobIdChange = (e, jobs) => {
    const newFilter = [...filterJobId];
    const job = jobs.pop();
    const filterValues = newFilter.map((jobId) => jobId.value);
    if (filterValues.indexOf(job.value) === -1) {
      newFilter.push(job);
    }
    dispatch(FiltersBlueprints.setFilterJobId(newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const handleDeleteFilteredChip = (i, action, filter) => () => {
    const newFilter = [...filter];
    newFilter.splice(i, 1);
    dispatch(FiltersBlueprints[action](newFilter));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const handleDeleteFilteredDate = () => {
    dispatch(FiltersBlueprints.setFilterDate([]));
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const deleteFilters = () => {
    dispatch(FiltersBlueprints.resetFilters());
    dispatch(FiltersBlueprints.setFiltersChanged(true));
  };

  const setDateLabel = () => {
    let dateLabel = "";
    if (filterDate && filterDate[0]) {
      dateLabel = DateService.getFormattedDay(filterDate[0].from);
      if (!moment(filterDate[0].from).isSame(filterDate[0].to)) {
        dateLabel += ` - ${DateService.getFormattedDay(filterDate[0].to)}`;
      }
    }
    return dateLabel;
  };

  const renderAlternativeJobTypeAvatar = () => {
    return (
      <Avatar>
        <i className="mdi mdi-briefcase-outline"></i>
      </Avatar>
    );
  };

  const jobIds = jobs.map((bp) => {
    return {
      label: bp.job.ID.toString(),
      value: bp.job.ID,
      icon: bp.job.icon,
    };
  });

  const getProjectElements = () => {
    if (toolbar && Array.isArray(toolbar) && !showEditBpModal) {
      const filteredTools = toolbar
        .filter(
          (t) =>
            t.type !== MOVE_TOOL &&
            t.type !== MEASURE_TOOL_HORIZONTAL &&
            t.type !== MEASURE_TOOL_VERTICAL
        )
        .map((tool) => tool.element);
      return filteredTools;
    }
    if (showEditBpModal) {
      return settings && settings.blueprintSettings.elements;
    }
    return [];
  };

  return (
    <Grid container className={classes.root}>
      <Grid container justifyContent="center" item>
        <Grid container alignItems="center" item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_USER")}: `}</Typography>
          {filterUser.length > 0 &&
            filterUser.map((user, i) => (
              <Chip
                key={i}
                onDelete={handleDeleteFilteredChip(
                  i,
                  "setFilterUser",
                  filterUser
                )}
                className={classes.chip}
                label={user.label}
                variant="outlined"
              />
            ))}
        </Grid>
        <Grid container justifyContent="center" item>
          <UserAutocomplete
            autocompleteWidth={"80%"}
            selectedSuggestions={filterUser}
            onUsersChange={userSelect}
            predefinedText={i18n.t("default:_TYPE_TEXT_SEARCH")}
            withoutRole={true}
            withoutSelected={true}
            includeMe={true}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.filterRow}>
        <Grid container alignItems="center" item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_DATE")}:`}</Typography>
          {filterDate && filterDate.length > 0 && filterDate[0] && (
            <Chip
              className={classes.chip}
              onDelete={handleDeleteFilteredDate}
              label={setDateLabel()}
              variant="outlined"
            />
          )}
        </Grid>
        <Grid container justifyContent="center" item>
          {/* <RangePicker
            onChange={(date) => changeDate(date)}
            fromValue={
              filterDate && filterDate[0]
                ? moment(filterDate[0].from).toDate()
                : null
            }
            toValue={
              filterDate && filterDate[0]
                ? moment(filterDate[0].to).toDate()
                : null
            }
          /> */}
          <Flatpickr
            onChange={(date) => changeDate(date)}
            className={classes.dateInput}
            options={{
              multiple: true,
              mode: "range",
              dateFormat: "d/m/Y",
              defaultDate:
                filterDate && filterDate[0]
                  ? [
                      moment(filterDate[0].from).toDate(),
                      moment(filterDate[0].to).toDate(),
                    ]
                  : null,
            }}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.filterRow}>
        <Grid container item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_JOB_TYPE")}:`}</Typography>
          {filterJobType.length > 0 &&
            filterJobType.map((jt, i) => (
              <Chip
                key={i}
                onDelete={handleDeleteFilteredChip(
                  i,
                  "setFilterJobType",
                  filterJobType
                )}
                className={classes.chip}
                label={jt.label}
                variant="outlined"
              />
            ))}
        </Grid>
        <Grid container justifyContent="center" item>
          <ActivityTypeAutocomplete
            autocompleteWidth={"80%"}
            selected={filterJobType}
            setSelected={jobTypeSelect}
            withoutSelected={true}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.filterRow}>
        <Grid container item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_ELEMENT")}:`}</Typography>
          {filterElement.length > 0 &&
            filterElement.map((el, i) => (
              <Chip
                key={i}
                className={classes.chip}
                onDelete={handleDeleteFilteredChip(
                  i,
                  "setFilterElement",
                  filterElement
                )}
                label={el.label}
                variant="outlined"
              />
            ))}
        </Grid>
        <Grid container justifyContent="center" item>
          <BlueprintAutocomplete
            selected={filterElement}
            onChange={changeBlueprint}
            autocompleteWidth={"80%"}
            withoutSelected={true}
            suggestedElements={getProjectElements()}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.filterRow}>
        <Grid container alignItems="center" item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_JOB_STATUS")}: `}</Typography>
          {filterStatus.length > 0 &&
            filterStatus.map((status, i) => (
              <Chip
                key={i}
                onDelete={handleDeleteFilteredChip(
                  i,
                  "setFilterStatus",
                  filterStatus
                )}
                className={classes.chip}
                label={status.label}
                variant="outlined"
              />
            ))}
        </Grid>
        <Grid container justifyContent="center" item>
          <StatusSelect
            multiple={true}
            selectedStatuses={filterStatus}
            onStatusChange={onStatusFilterChange}
            inputWidth={"80%"}
            centeredInput={true}
            fullParentWidth={true}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.filterRow}>
        <Grid container alignItems="center" item className={classes.infoRow}>
          <Typography>{`${i18n.t("default:_JOB_ID")}: `}</Typography>
          {filterJobId.length > 0 &&
            filterJobId.map((job, i) => (
              <Chip
                key={i}
                onDelete={handleDeleteFilteredChip(
                  i,
                  "setFilterJobId",
                  filterJobId
                )}
                className={classes.chip}
                label={job.value}
                variant="outlined"
              />
            ))}
        </Grid>
        <Grid container justifyContent="center" item>
          <SimpleAutocomplete
            options={jobIds}
            onValueChange={onJobIdChange}
            multiple={true}
            value={[]}
            width={"80%"}
            label={i18n.t("default:_JOB_ID")}
            disableClearable={true}
            renderAlternativeAvatar={renderAlternativeJobTypeAvatar}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.clearFilters}>
        <Typography>Clear filters:</Typography>
        <IconButton onClick={() => deleteFilters()}>
          <HighlightOffIcon color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default connectWithStore(BlueprintFilters, [
  "bluePrintsFilters",
  "blueprintEditor",
  "settings",
]);
