import Http from "../http";

export const getProjectsList = async (params) => {
  const path = `/v2/projects`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getAllDayList = async (params) => {
  const path = "/v2/settings";
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });

    if (response.data && response.data.allDay) {
      return response.data.allDay;
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getAllProjectList = async (params) => {
  const path = `/v2/projects/all`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getCompanies = async (params) => {
  const path = `/v2/projects/companies`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const creteProject = async (params) => {
  const path = `/v2/projects`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export const cloneProject = async (params) => {
  const path = `/v2/projects/${params.id}/clone`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateProject = async (params) => {
  const paramsForUpdate = Object.assign({}, params);
  if (paramsForUpdate.users) {
    const projectUsers = [];

    for (const user of paramsForUpdate.users) {
      if (typeof user !== "string") {
        if (user._id) {
          projectUsers.push(user._id);
        } else if (user.value) {
          projectUsers.push(user.value);
        }
      }
    }

    if (projectUsers.length > 0) {
      paramsForUpdate.users = projectUsers;
    }
  }
  const path = `/v2/projects/${params.id}`;
  try {
    const response = await Http.sharedInstance
      .getClient()
      .put(path, paramsForUpdate);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const getProject = async (params) => {
  const path = `/v1/projects/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const getCover = async (params) => {
  const path = `/v2/projects/${params.id}/cover`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const deleteProject = async (params) => {
  const path = `/v2/projects/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getProjectFactories = async () => {
  const path = "/v1/projects/factories";
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createProjectFactories = async (params) => {
  const path = "/v1/projects/factories";
  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const deleteProjectFactories = async (params) => {
  const path = `/v1/projects/factories/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const getProjectMaterials = async () => {
  const path = "/v1/projects/materials";
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createProjectMaterials = async (params) => {
  const path = "/v1/projects/materials";
  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const deleteProjectMaterials = async (params) => {
  const path = `/v1/projects/materials/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const getProjectCustomer = async () => {
  const path = "/v1/projects/customers";
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createProjectCustomer = async (params) => {
  const path = "/v1/projects/customers";
  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const deleteProjectCustomer = async (params) => {
  const path = `/v1/projects/customers/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const getProjectContacts = async () => {
  const path = "/v1/projects/contacts";
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const createProjectContacts = async (params) => {
  const path = "/v1/projects/contacts";
  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const deleteProjectContacts = async (params) => {
  const path = `/v1/projects/contacts/${params.id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    return {};
  }
};

export const feedsProjects = async (params) => {
  const path = `/v2/projects/${params.id}/feeds?page=${params.page}&page_size=${params.page_size}`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: e.response.data,
    };
  }
};

export const projectsDashboard = async () => {
  const path = `/v2/projects/dashboard`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const updateSignleProjectField = async (params) => {
  return updateProjectiFields({
    id: params.id,
    fields: [params.field],
  });
};

export const updateProjectiFields = async (params) => {
  const path = `/v2/projects/${params.id}/fields`;
  try {
    const response = await Http.sharedInstance
      .getClient()
      .patch(path, params.fields);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const getListSuggestions = async ({ group, isPrivate, project }) => {
  const path = `/v2/projects/fields/list/${group}?isPrivate=${
    isPrivate ? 1 : 0
  }&project=${project}`;
  try {
    const response = await Http.sharedInstance.getClient().get(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const createListSuggestion = async (params) => {
  const path = `/v2/projects/fields/list`;
  try {
    const response = await Http.sharedInstance.getClient().post(path, params);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const deleteListSuggestion = async ({ id }) => {
  const path = `/v2/projects/fields/list/${id}`;
  try {
    const response = await Http.sharedInstance.getClient().delete(path);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const changeFieldVisibility = async ({ id, ...rest }) => {
  const path = `/v2/projects/${id}/fields/visibility`;
  try {
    const response = await Http.sharedInstance.getClient().post(path, rest);
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
};

export const getAllFieldsValues = async () => {
  const path = `/v2/projects/fields/values`;
  try {
    const response = await Http.sharedInstance.getClient().get(path, {});
    return response.data;
  } catch (e) {
    return {
      success: false,
      data: {},
    };
  }
}
