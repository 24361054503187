import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { RIEInput } from "riek";
import i18n from "../../i18n";
import Confirm from "../../components/confirm/confirm";
import { canUserEdit } from "../../services/access";
import QRCode from "react-qr-code";
import { Button } from "@material-ui/core";
import CsvParser from "../dropzone/csv_parser";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  rowData: {
    minWidth: "50px",
    cursor: "pointer",
  },
  addButton: {
    minHeight: "25px",
    height: "25px",
    width: "25px",
    "&:hover": {
      color: theme.palette.primary.main,
      background: "white",
    },
    margin: "15px",
  },
  removeButton: {
    minHeight: "25px",
    height: "25px",
    width: "25px",
  },
  qrCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  downloadButton: {
    width: 50,
    fontSize: 8,
    marginTop: 7,
  },
}));

function ProjectTable({
  table,
  columnHeaders,
  onCellUpdate,
  addRow,
  removeRow,
  accessRules,
  user,
  onCsvImport,
}) {
  const classes = useStyles();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const updateCell = (value, key, i) => {
    onCellUpdate(value, key, i);
  };

  const onDeleteRow = (i) => {
    setDeleteConfirm(true);
    setRowIndex(i);
  };

  const onConfirmRowDelete = () => {
    setDeleteConfirm(false);
    removeRow(rowIndex);
    setRowIndex(null);
  };

  const onImageDownload = (i) => {
    const svg = document.getElementById(`QRCode-${i}`);
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(
      unescape(encodeURIComponent(svgData))
    )}`;
  };

  return columnHeaders ? (
    <TableContainer component={Paper}>
      {canUserEdit(user, accessRules) && (
        <CsvParser
          onRawChange={(data) => (onCsvImport ? onCsvImport(data) : null)}
        />
      )}
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_CONFIRM_DELETE_ROW")}
        show={deleteConfirm}
        onConfirm={() => onConfirmRowDelete()}
        onClose={() => setDeleteConfirm(false)}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnHeaders &&
              columnHeaders.map((header, i) => (
                <TableCell align="center" key={i}>
                  {header}
                </TableCell>
              ))}
            <TableCell size="small"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {table &&
            table.length &&
            table.map((row, i) => (
              <TableRow key={i}>
                {Object.entries(row).map(([rowKey, value], y) => {
                  let header = columnHeaders && columnHeaders[y];
                  if (header === "QR") {
                    const cleanRow = { ...row };
                    delete cleanRow.QR;
                    delete cleanRow[""];
                    return (
                      <TableCell key={y} className={classes.qrCell}>
                        <div>
                          <QRCode
                            id={`QRCode-${i}`}
                            value={btoa(
                              unescape(
                                encodeURIComponent(JSON.stringify(cleanRow))
                              )
                            )}
                            size={200}
                          />
                        </div>
                        <Button
                          variant="contained"
                          className={classes.downloadButton}
                          type="button"
                          onClick={() => onImageDownload(i)}
                        >
                          {i18n.t("default:_DOWNLOAD")}
                        </Button>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      align="center"
                      key={y} /* component="th" scope="row" */
                    >
                      <RIEInput
                        value={rowKey === header ? value : "-"}
                        change={(newVal) => updateCell(newVal.name, rowKey, i)}
                        propName="name"
                        className={classes.rowData}
                        editProps={{
                          disabled: !canUserEdit(user, accessRules),
                        }}
                      />
                    </TableCell>
                  );
                })}
                <TableCell align="right" size="small">
                  {canUserEdit(user, accessRules) && (
                    <Fab
                      color="secondary"
                      aria-label="add"
                      size="small"
                      className={classes.removeButton}
                      onClick={() => onDeleteRow(i)}
                    >
                      <HighlightOffIcon />
                    </Fab>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {canUserEdit(user, accessRules) && (
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          className={classes.addButton}
          onClick={() => addRow()}
        >
          <AddIcon />
        </Fab>
      )}
    </TableContainer>
  ) : (
    <div>{"You need to specify table columns in project settings!"}</div>
  );
}

export default ProjectTable;
