import React, { Component } from "react";
import Modal from "react-modal";
import i18n from "../../../i18n";
import BlockUi from "react-block-ui";
import { MediaQuery } from "react-responsive";
import { isMobileDevice } from "../../../services/mobile";
import UserAutoComplete from "../../../components/autoselect/user_autoselect";
import { connectWithStore } from "../../../services/redux";
import { FormControlLabel, Radio } from "@material-ui/core";
import { USER_AUTOCOMPLETE_TAB } from "../../../redux/reducers/user_autocomplete";
import { UserAutocomplete } from "../../../redux/actions";
import { APP_SKILLS } from "../../../services/app";

class UserSelectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: this.props.selected ? this.props.selected : [],
      newSelected: [],
      newSelection: false,
    };
    this.listener = document.addEventListener(
      "resetUserSelect",
      this.reset.bind(this)
    );
  }

  componentWillUnmount() {
    document.removeEventListener("resetUserSelect", this.listener);
  }

  reset() {
    this.setState({
      loading: false,
      selected: this.props.selected ? this.props.selected : [],
      newSelected: [],
      newSelection: false,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      JSON.stringify(nextProps.selected) !== JSON.stringify(prevState.selected)
    ) {
      return {
        selected: nextProps.selected || [],
      };
    }
    return null;
  }

  onChange(selected) {
    if (this.props.onChange) {
      this.setState(
        {
          newSelection: true,
          newSelected: selected.filter(
            (val) => val && val.value && typeof val.value !== "undefined"
          ),
        },
        () => {
          if (this.props.triggerImmediateChange) {
            this.props.onChange(this.state.newSelected);
          }
        }
      );
    }
  }

  onSelect() {
    if (!this.props.triggerImmediateChange && this.state.newSelection) {
      this.props.onChange(this.state.newSelected, true);
    }
    this.reset();
    this.props.close();
  }

  render() {
    const { loading, newSelected, selected, newSelection } = this.state;
    const { userAutocomplete, apps } = this.props;
    const { withAvailabilities, tab } = userAutocomplete;

    const CloseIcon = () => {
      return (
        <MediaQuery maxWidth={992}>
          <div className="modal-mobile-header">
            <div>
              <div
                onClick={() => this.props.close()}
                style={{ fontSize: 22 }}
                className="close-modal-mobile"
              >
                <i className="mdi mdi-chevron-left" />
              </div>
            </div>
          </div>
        </MediaQuery>
      );
    };
    const customStyles = {
      content: {
        padding: 0,
        height: isMobileDevice() ? "100%" : 600,
        width: isMobileDevice() ? "100%" : 600,
      },
    };
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.props.show}
        onRequestClose={() => this.props.close()}
        contentLabel={i18n.t("default:_SELECT_USERS_FROM_LIST", {
          users: i18n.t("default:_USERS"),
        })}
        style={customStyles}
      >
        <BlockUi
          tag="div"
          className="SelectUserModal Modal-SelectUser"
          blocking={loading}
        >
          <div className="Modal-header">
            <CloseIcon />
            <h5>
              {i18n.t("default:_SELECT_USERS", {
                users: i18n.t("default:_USERS"),
              })}
            </h5>
          </div>
          <div className="Modal-content">
            <UserAutoComplete
              skills={this.props.skills ? this.props.skills : []}
              listItemColorFn={this.props.listItemColorFn}
              withRole={this.props.withRole}
              disableNonTemp={this.props.disableNonTemp}
              includeMe={this.props.includeMe || false}
              excludeRoles={this.props.excludeRoles}
              onUsersChange={(selected) => this.onChange(selected)}
              selectedSuggestions={
                !this.props.disableNonTemp && newSelection
                  ? newSelected
                  : /* selected.concat(newSelected) */
                    selected
              }
              maxSelection={this.props.maxSelection || null}
              searchCallback={
                this.props.searchUser ? this.props.searchUser : null
              }
              predefinedUsersList={this.props.predefinedUsersList}
              additionalFilter={this.props.additionalFilter}
            />
          </div>
          <div
            className="actions Modal-actions"
            style={
              apps.enabled.includes(APP_SKILLS) && withAvailabilities
                ? {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 20,
                  }
                : {}
            }
          >
            {apps.enabled.includes(APP_SKILLS) && withAvailabilities && (
              <div>
                <FormControlLabel
                  control={
                    <Radio
                      onClick={() =>
                        this.props.dispatch(UserAutocomplete.sortBySkills())
                      }
                      checked={tab === USER_AUTOCOMPLETE_TAB.SKILLS}
                    />
                  }
                  label={i18n.t("default:_SKILLS")}
                />
              </div>
            )}
            {apps.enabled.includes(APP_SKILLS) && withAvailabilities && (
              <div>
                <FormControlLabel
                  control={
                    <Radio
                      onClick={() =>
                        this.props.dispatch(
                          UserAutocomplete.sortByAvailability()
                        )
                      }
                      checked={tab === USER_AUTOCOMPLETE_TAB.AVAILABILITY}
                    />
                  }
                  label={i18n.t("default:_AVAILABILITY")}
                />
              </div>
            )}
            <button onClick={() => this.onSelect()} className="btn btn-primary">
              {i18n.t("default:_SELECT")}
            </button>
          </div>
        </BlockUi>
      </Modal>
    );
  }
}

export default connectWithStore(UserSelectModal, [
  "settings",
  "userAutocomplete",
  "apps",
]);
