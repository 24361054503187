import React, { useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FieldRenderer from "./fields/field_renderer";
import { connectWithStore } from "../../services/redux";
import { withRouter } from "react-router-dom";
import { getProject } from "../../services/api/projects";
import { CurrentProject } from "../../redux/actions";
import FieldLoader from "./fields/field_loader";
import { ROLE } from "../../services/roles";
import { PROJECT_FIELD_TYPE } from "./fields/constants";
import { APP_BLUEPRINTS, APP_CLIENT } from "../../services/app";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: ({ noPadding }) => (noPadding ? 10 : 30),
    marginBottom: ({ noPadding }) => (noPadding ? 10 : 30),
    paddingLeft: ({ noPadding }) => (noPadding ? 10 : 30),
    paddingRight: ({ noPadding }) => (noPadding ? 10 : 30),
    flexWrap: "wrap",
  },
}));

function ProjectFieldRenderer({
  settings,
  match,
  currentProject,
  dispatch,
  vertical,
  turnOffHidden,
  user,
  noPadding,
  projectData,
  onUpdate,
  apps,
}) {
  const id = match.params.id;
  const { projectFields } = settings;
  const classes = useStyles({ noPadding });

  const { project } = currentProject;
  const fieldFilter = (field) =>
    turnOffHidden &&
    project &&
    project.visibility &&
    typeof project.visibility[field.id] !== "undefined"
      ? project.visibility[field.id]
      : true;

  const asyncGetProject = useCallback(
    async (id) => {
      if (!id || projectData) {
        if (projectData) {
          id = projectData._id;
        } else {
          return;
        }
      }
      dispatch(CurrentProject.resetProject());
      const { data, success } = await getProject({ id });
      if (success) dispatch(CurrentProject.setProject(data));
    },
    [dispatch, projectData]
  );

  const canReadProjectField = (accessRules) => {
    if (
      user.profile.role === ROLE.SYSTEM_ADMIN ||
      user.profile.role === ROLE.BUSINESS_ADMIN
    ) {
      return true;
    }
    return accessRules &&
      accessRules[user.profile.role] &&
      accessRules[user.profile.role].read
      ? accessRules[user.profile.role].read
      : false;
  };

  const shouldRenderProjectField = (type) => {
    if (
      type === PROJECT_FIELD_TYPE.CUSTOMER_TABLE &&
      !apps.enabled.includes(APP_CLIENT)
    ) {
      return false;
    } else if (
      type === PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS &&
      !apps.enabled.includes(APP_BLUEPRINTS)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(
    () => {
      asyncGetProject(id);
    },
    // eslint-disable-next-line
    [id]
  );

  return (
    <Grid container className={classes.container} spacing={vertical ? 0 : 4}>
      {projectFields.length > 0 &&
        project &&
        projectFields
          .sort((a, b) => a.order > b.order)
          .filter(fieldFilter)
          .filter((field) => canReadProjectField(field.accessRules))
          .filter((field) => shouldRenderProjectField(field.type))
          .map((field) => (
            <FieldRenderer
              fullWidth={vertical}
              project={currentProject.project}
              key={field.id}
              editable={false}
              field={field}
              vertical={vertical}
              turnOffHidden={turnOffHidden}
              onUpdate={onUpdate}
            />
          ))}
      {!project &&
        projectFields.length > 0 &&
        projectFields
          .filter((field) => canReadProjectField(field.accessRules))
          .map((field) => {
            return <FieldLoader key={field.id} />;
          })}
    </Grid>
  );
}

export default connectWithStore(withRouter(ProjectFieldRenderer), [
  "settings",
  "currentProject",
  "user",
  "apps",
]);
