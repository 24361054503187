import {
  canUserEditElement,
  setScaledPoints,
  subscribeScissorsToParentPosition,
  updateScissorsPositionOnParentDrag,
} from "./utils";
import store from "../../../redux/store";
import { BlueprintEditor } from "../../../redux/actions";
import Konva from "konva";
import { v1 as uuid } from "uuid";
import { CONSTRUCTOR_NAME } from "./constants";

export const attachInitialEvents = (
  node,
  row,
  job,
  question,
  dispatch,
  elementId,
  visible,
  user,
  _id
) => {
  const isFromTheSameJobOrProject = (row.job._id && job._id && row.job._id !== job._id)
  if (!isFromTheSameJobOrProject) {
    if (!canUserEditElement(row.job, job, question)) {
      node.draggable(false);
    }


    node.on("dragstart", () => {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { mode, selectedElement } = blueprintEditor;
      const parentGroup = node.parent;
      //if is in group, drag together with scissors
      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        subscribeScissorsToParentPosition(node);
        parentGroup.clearCache();
      }
      if (mode !== "EDIT" || !selectedElement) {
        node.stopDrag();
      } else {
        dispatch(BlueprintEditor.setSelectedElement(node));
      }
    });
    node.on("dragend", () => {
      const parentGroup = node.parent;
      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        updateScissorsPositionOnParentDrag(node);
        parentGroup.cache();
      }
      dispatch(
        BlueprintEditor.updateElement({
          id: node.getAttr("id"),
          elementId: elementId,
          user,
          visible,
          job: row.job,
          question: row.question,
        })
      );
      dispatch(BlueprintEditor.updateHistory());
      //dispatch(BlueprintEditor.setSelectedElement(null));
    });
    node.on("click", () => {
      if (!isFromTheSameJobOrProject) return;
      const state = store.getState();
      const { blueprintEditor } = state;
      if (blueprintEditor.mode === "VIEW") {
        const comment = node.attrs.comment
          ? `${node.attrs.comment} - #${row.job && row.job.ID}`
          : row.job
            ? row.job.ID
            : "";
        var mousePos = blueprintEditor.app.getPointerPosition();
        const simpleLabel = new Konva.Label({
          x: mousePos.x + 5,
          y: mousePos.y + 5,
          //opacity: 0.75,
          visble: true,
          name: "Label",
          id: uuid(),
        });

        simpleLabel.add(
          new Konva.Tag({
            fill: "black",
            cornerRadius: 10,
          })
        );

        simpleLabel.add(
          new Konva.Text({
            text: comment,
            fontFamily: "Calibri",
            fontSize: 22,
            padding: 15,
            fill: "white",
          })
        );
        setTimeout(() => {
          blueprintEditor.layer.add(simpleLabel);
          blueprintEditor.layer.batchDraw();
        }, 100);
      }
    });
    if (canUserEditElement(row.job, job, question) && isFromTheSameJobOrProject) {
      node.on("transformstart", function () {
        //get group by grouId and clear cache to see changes
        const parentGroup = node.parent;
        if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
          parentGroup.clearCache();
        }
      });
      node.on("transform", () => {
        if (
          node &&
          node.attrs &&
          node.attrs.name &&
          (node.attrs.name === CONSTRUCTOR_NAME.RECT ||
            node.attrs.name === CONSTRUCTOR_NAME.CIRCLE ||
            node.attrs.name === CONSTRUCTOR_NAME.LAMP)
        ) {
          node.setAttrs({
            width: Math.max(node.width() * node.scaleX(), 5),
            height: Math.max(node.height() * node.scaleY(), 5),
            scaleX: 1,
            scaleY: 1,
          });
        }
      });
      node.on("transformend", () => {
        const parentGroup = node.parent;
        if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
          parentGroup.cache();
        }
        if (node.attrs && node.attrs.name && node.attrs.name === "Polygon") {
          setScaledPoints(node);
        }
        dispatch(
          BlueprintEditor.updateElement({
            id: node.getAttr("id"),
            elementId: elementId,
            user,
            visible,
            job: row.job,
            question: row.question,
          })
        );
        dispatch(BlueprintEditor.updateHistory());
      });
    }
    node.setAttr("id", _id);
    if (visible) {
      node.show();
    } else {
      node.hide();
    }
  }
};
