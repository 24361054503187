import React, { useState } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import SubMenu from "./sub_menu";
import Paper from "@material-ui/core/Paper";
import MenuItem from "./menu_item";
import Logo from "../logo/logo";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import withMuiIcon from "../../../hoc/withMuiIcon";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 65,
    zIndex: "998",
    minHeight: "auto",
    height: "calc(100% - 50px)",
    overflowY: "visible",
    width: 82,
  },
  impersonating: {
    backgroundColor: "#FFCCCC",
  },
  list: {},
  logoContainer: {
    padding: "0 5px",
    height: "100%",
    width: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  rowWrapper: {
    position: "relative",
    height: 74,
  },
  subMenuWrapper: {
    position: "absolute",
    left: "100%",
  },
});

function MenuList({
  menuItems,
  activeLink,
  gotoPage,
  jobTypes,
  handleCreateJobTypeClick,
  handleCreateNewClick,
}) {
  const classes = useStyles();
  const [hoveredSubMenu, setHoveredSubMenu] = useState(null);

  const getItemHeight = () => {
    if (menuItems.length > 7) {
      return "54px";
    }
    if (menuItems.length > 6) {
      return "60px";
    }
    return "70px";
  };

  const onMenuItemClick = (item) => {
    if (!item.url) return;
    gotoPage(i18n.t(item.label), item.url);
  };

  return (
    <Paper className={classes.root}>
      <List
        className={classes.list}
        component="nav"
        aria-labelledby="nested-list-menu"
      >
        <Grid
          container
          style={{
            height: 70,
            maxHeight: menuItems && menuItems.length > 6 && "70px",
          }}
        >
          <span className={classes.logoContainer}>
            <a
              href="# "
              onClick={() =>
                gotoPage(i18n.t("default:_DASHBOARD"), "/dashboard")
              }
            >
              <Logo collapsed={true} secondaryOnly={true} />
            </a>
          </span>
        </Grid>
        {menuItems.map((item, i) => {
          const isJobTypeSubmenu = item.subMenuListProp === "jobType";
          return (
            <Grid
              key={i}
              onMouseOver={() => {
                setHoveredSubMenu(item.subMenuListProp);
              }}
              onMouseLeave={() => {
                setHoveredSubMenu(null);
              }}
              className={classes.rowWrapper}
              style={{
                height: getItemHeight(),
              }}
            >
              {item.subMenuListProp && (
                <>
                  {hoveredSubMenu === item.subMenuListProp && (
                    <Grid
                      className={classes.subMenuWrapper}
                      style={{
                        top: isJobTypeSubmenu
                          ? jobTypes.length < 5
                            ? "0"
                            : "-25vh"
                          : "unset",
                        bottom: isJobTypeSubmenu ? "unset" : "0",
                      }}
                    >
                      <Paper>
                        <SubMenu
                          gotoPage={gotoPage}
                          subMenuType={item.subMenuListProp}
                          jobTypes={jobTypes}
                          handleCreateJobTypeClick={handleCreateJobTypeClick}
                        />
                      </Paper>
                    </Grid>
                  )}
                </>
              )}
              <MenuItem
                key={i}
                icon={item.icon}
                label={i18n.t(item.label)}
                selected={activeLink === item.url}
                onMenuItemClick={() => onMenuItemClick(item)}
                subMenu={item.subMenuListProp}
                muiIcon={item.muiIcon}
              />
            </Grid>
          );
        })}
        <MenuItem
          label={i18n.t("default:_CREATE_NEW")}
          onMenuItemClick={handleCreateNewClick}
          muiIcon={withMuiIcon(AddCircleIcon)}
          addRow={true}
        />
      </List>
    </Paper>
  );
}

export default connectWithStore(MenuList, []);
