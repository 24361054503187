import React, { useState } from "react";
import withModal from "../../../hoc/withModal";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import { canManageRole, ROLE } from "../../../services/roles";
import UserFieldWrapper from "./field_wrapper";
import MapWithButtons from "../../../components/google_map/map_with_buttons";
import { locationFromCoords } from "../../../services/location";

function UserAddressField({ value, user, field, changeUserProp }) {
  const [addressModalVisible, setAddressModalVisible] = useState(false);

  const openModal = () => {
    setAddressModalVisible(true);
  };

  const closeModal = () => {
    setAddressModalVisible(false);
  };

  const onLocationChanged = async (coords) => {
    if (!coords.address) {
      const { formatted_address } = await locationFromCoords(
        coords.lat,
        coords.lng
      );
      coords.address = formatted_address;
    }
    const newLoc = {
      lat: coords.lat,
      long: coords.lng,
      address: coords.address,
    };
    changeUserProp(field.value, newLoc);
  };

  const renderAddressModal = () => {
    const MapButtonsModal = withModal(MapWithButtons);

    const project = {
      location: value ? value : null,
    };

    return (
      <MapButtonsModal
        visible={addressModalVisible}
        onClose={() => closeModal()}
        project={project}
        onLocationChanged={onLocationChanged.bind(this)}
        //copyLink={copyLink.bind(this)}
        title={i18n.t("default:_ADDRESS")}
        accessAllowed={canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN)}
      />
    );
  };

  return (
    <>
      {renderAddressModal()}
      <UserFieldWrapper
        label={field.name}
        withPointer={true}
        onFieldClick={() => openModal()}
      >
        <div>{value ? value.address : ""}</div>
      </UserFieldWrapper>
    </>
  );
}

export default connectWithStore(UserAddressField, ["user"]);
