import React, { useState, useEffect } from "react";
import WizardButtons from "./wizard_buttons";
import { connectWithStore } from "../../services/redux";
import i18n from "../../i18n";
import { STEP_NEW_PROJECT, STEP_FINAL } from "./steps";
import SkillAutoComplete from "../autoselect/skill_autoselect";
import { /* creteProject, */ getProject } from "../../services/api/projects";
import { CurrentProject } from "../../redux/actions";
import { updateSignleProjectField } from "../../services/api/projects";
import { Wizard } from "../../redux/actions";
import SKILL_COLORS from "../../services/skill_colors";
import { WIZARD_TYPE_PROJECT } from "./constants";
import { withRouter } from "react-router-dom";

function SelectProjectSkills({ goToStep, dispatch, isActive, wizardProject }) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const onSkillsChange = (selected) => {
    setSelectedSkills(
      selected.filter((val) => val && val._id && typeof val._id !== "undefined")
    );
  };
  const skillFieldId = wizardProject.skillFields[0].id;

  const addSkillsToProject = async () => {
    const skillIds = selectedSkills.map((skill) => skill._id);
    //get project by id
    const params = {
      id: wizardProject.id,
    };
    const projectResponse = await getProject(params);
    const currentProject = projectResponse.data;

    dispatch(CurrentProject.setProject(currentProject));

    await updateSignleProjectField({
      id: wizardProject.id,
      field: {
        id: skillFieldId,
        value: skillIds,
      },
    });
    dispatch(
      CurrentProject.updateProject({
        [skillFieldId]: skillIds,
      })
    );
  };

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_SELECT_SKILLS")));
  }, [dispatch, isActive]);

  return (
    <div
      className="SelectJobType ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 10, width: "100%", maxWidth: 600, marginTop: 10 }}>
        <div className="form-group">
          <SkillAutoComplete
            onSkillsChange={(selected) => onSkillsChange(selected)}
            selectedSuggestions={selectedSkills}
            skillColor={SKILL_COLORS.GREEN}
          />
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_NEW_PROJECT),
            variant: "white",
          },
          {
            label: i18n.t("default:_FINISH"),
            onClick: () => {
              addSkillsToProject();
              dispatch(Wizard.setType(WIZARD_TYPE_PROJECT));
              goToStep(STEP_FINAL);
            },
            variant: "black",
            //disabled: selectedSkills.length === 0,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(withRouter(SelectProjectSkills), [
  "wizardProject",
]);
