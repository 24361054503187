import React, { Component } from "react";
import "./editor.scss";
import i18n from "../../i18n";
import { getRolesWithLabels, ROLE, canManageRole } from "../../services/roles";
import {
  updateUser,
  reinviteUsers,
  banMultiple,
  eraseUsers,
  getCurrentUser,
} from "../../services/api/user";
import { assignSkillsToUser } from "../../services/api/skills";
import JobBadge from "../../components/job_badge/job_badge";
import BackButton from "../../components/back/back_button";
import { connectWithStore } from "../../services/redux";
import { RIEInput } from "riek";
import Confirm from "../../components/confirm/confirm";
import NotificationsControl from "../../components/notifications_control/notifications_control";
import Modal from "react-modal";
import BlockUi from "react-block-ui";
import Switch from "../../components/switch/switch";
import LoginService from "../../services/login";
import ToggleService from "../../services/toggle";
import { Settings, User } from "../../redux/actions";
import SkillAutoComplete from "../../components/autoselect/skill_autoselect";
import Grid from "@material-ui/core/Grid";
import withModal from "../../hoc/withModal";
import SkillChip from "../../components/skills/skill_chip";
import { isObject, setSkillColor } from "../../services/helpers";
import { getFileTarget, deleteAsset } from "../../services/api/file_upload";
import SKILL_COLORS from "../../services/skill_colors";
import ToastMessage from "../../components/toast_message/toast_message";
import ClientWizard from "../../components/client_wizard/client_wizard";
import { List, ListItem, Chip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { getFilesList } from "../../services/api/files";
import { APP_CLIENT } from "../../services/app";
import MobileUserRow from "./user_mobile_row";
import UserActivities from "./user_activities";
import UserFileField from "./fields/file_field";
import { USER_FIELDS } from "./fields/constants";
import FieldRenderer from "./fields/field_renderer";
import { shouldDisplayUserField } from "../../services/access";
import ProjectsField from "./fields/projects_field";

const avatar = require("../../assets/img/img_avatar.png");
const avatarProject = require("../../assets/img/project-image.png");

const useStyles = (theme) => ({
  chip: {
    width: "100%",
    marginRight: "5px",
    marginBottom: "5px",
    color: "inherit",
  },
  listRoot: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
    maxWidth: "100%",
  },
});

class UserEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.users,
      user: this.props.users.length > 0 ? this.props.users[0] : {},
      showReinvitedAlert: false,
      roleSelect: false,
      deleteConfirm: false,
      deleteSkillConfirm: false,
      notificationsModalOpened: false,
      notificationsTargetUser: null,
      addModalVisible: false,
      selectedSkills:
        this.props.users.length > 0 ? this.props.users[0].skills : [],
      initialSkills:
        this.props.users.length > 0 ? this.props.users[0].skills : [],
      selectedToRemove: [],
      shouldLoadSkillsTab: false,
      asignProjectsModalVisible: false,
      files: [],
    };
  }

  openModal() {
    this.setState({ addModalVisible: true });
  }

  closeModal() {
    this.setState({ addModalVisible: false });
  }

  openAsignProjectsModal() {
    this.setState({ asignProjectsModalVisible: true });
  }

  closeAsignProjectsModal() {
    this.setState({ asignProjectsModalVisible: false });
  }

  async checkSkillsToggle() {
    const response = await ToggleService._isToggleActive(
      "skills",
      this.props.settings.toggles
    );
    this.setState({ shouldLoadSkillsTab: response });
  }

  async componentDidMount() {
    const { user } = this.state;
    this.checkSkillsToggle();
    let params = {
      users: [user._id],
      show_private: true,
      page_size: 999999,
    };
    const response = await getFilesList(params);

    this.setState({
      files: response.docs,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      users: nextProps.users,
      user: nextProps.users.length > 0 ? nextProps.users[0] : {},
    });
  }

  validateEmail(value) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regex.test(String(value).toLowerCase());
    return isValid;
  }

  validateNotEmpty(value) {
    if (!value) {
      return false;
    }
    if (value.length <= 0) {
      return false;
    }
    return true;
  }

  validatePhone(value) {
    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    const isValid = regex.test(String(value));
    return isValid;
  }

  async onUserDataChanged() {
    const { user } = this.state;
    if (!user.id) {
      user.id = user._id;
    }
    await updateUser(user);
    if (this.props.onUpdate) {
      this.props.onUpdate(user);
    }
  }

  changeUserProp(propName, newValue) {
    const { user } = this.state;
    if (propName === "name") {
      newValue.name = {
        first: newValue.name.split(" ")[0],
        last: newValue.name.split(" ")[1] ? newValue.name.split(" ")[1] : "",
      };
    }
    const updatedValue =
      isObject(newValue) && propName in newValue
        ? newValue[propName]
        : newValue;
    //update profile data in case user is updating its own data
    if (user._id === this.props.user.profile._id) {
      const updatedProfile = { ...this.props.user.profile };
      updatedProfile[propName] = updatedValue;
      this.props.dispatch(User.updateProfileData(updatedProfile));
    }
    user[propName] = updatedValue;

    this.setState(
      {
        user: user,
      },
      () => this.onUserDataChanged()
    );
  }

  getRoles() {
    return getRolesWithLabels();
  }

  renderStatusLabel(status, count) {
    return <JobBadge count={count} status={status} noLabel={true} />;
  }

  toggleBanned() {
    const { user } = this.state;
    const banned = user.banned;
    const newStatus = banned ? false : true;
    this.changeUserProp("banned", { banned: newStatus });
  }

  async banMultiple() {
    const { users } = this.state;
    const ids = users.map((u) => u._id);
    await banMultiple({
      users: ids,
    });
    if (this.props.onBanMultiple) {
      this.props.onBanMultiple();
    }
  }

  async impersonateUser(user) {
    if (
      !ToggleService._isToggleActive("impersonate", this.props.settings.toggles)
    ) {
      return false;
    }
    localStorage.setItem(
      "oldUserProfile",
      JSON.stringify(this.props.user.profile)
    );
    localStorage.setItem(
      "oldAccessToken",
      JSON.stringify(this.props.user.access_token)
    );
    const json = await LoginService.doImpersonate(user.email);

    this.props.dispatch(User.setAccessToken(json));
    const res = await getCurrentUser();

    if (res) {
      this.props.dispatch(User.setProfileData(res));
      this.props.dispatch(Settings.loadAppSettings());
    }

    window.location.href = "/";
  }

  async resendInivation() {
    const { users } = this.state;
    const data = users.map((u) => {
      return {
        email: u.email,
        role: u.role,
      };
    });
    await reinviteUsers({
      users: data,
    });
    this.setState({
      showReinvitedAlert: true,
    });
  }

  async onEraseUser() {
    const { users } = this.state;
    const data = users.map((user) => user._id);
    await eraseUsers({
      users: data,
    });
    if (this.props.onBanMultiple) {
      this.props.onBanMultiple();
    }
  }

  changeRole(newRole) {
    this.setState(
      {
        roleSelect: false,
      },
      () => this.changeUserProp("role", { role: newRole })
    );
  }

  canEraseUser() {
    const { allowErase } = this.props.settings.security;
    const { role } = this.props.user.profile;
    const condition =
      allowErase === false &&
      [ROLE.INSPECTOR, ROLE.PROJECT_MANAGER].includes(role);
    return !condition;
  }

  async onNotificationsChange(notifications) {
    const user = this.state.notificationsTargetUser;

    if (!user) {
      return false;
    }
    user.notifications = notifications;

    await updateUser(user);
  }

  toggleManageSkills(val) {
    this.changeUserProp("canManageSkills", {
      canManageSkills: val,
    });
  }

  toggleTraining(val) {
    this.changeUserProp("inTraining", { inTraining: val });
  }

  onSkillsChange(selected) {
    this.setState({
      selectedSkills: selected.filter(
        (val) => val && val._id && typeof val._id !== "undefined"
      ),
    });
  }

  async assignSkills() {
    const { user } = this.state;
    const updatedSelectedSkills = [...this.state.selectedSkills].filter(
      (skill) => !this.state.selectedToRemove.includes(skill)
    );
    const skillIds = updatedSelectedSkills.map((skill) => skill._id);
    const data = {
      skills: skillIds,
    };
    const response = await assignSkillsToUser(data, user._id);
    if (response.success) {
      //update user manually
      const updatedUser = { ...user };
      updatedUser.skills = updatedSelectedSkills;
      /* const params = {
        id: user._id,
      };
      let updatedUser = await getUser(params); */
      this.setState({
        user: updatedUser,
        selectedSkills: updatedSelectedSkills,
        initialSkills: updatedSelectedSkills,
        selectedToRemove: [],
        deleteSkillConfirm: false,
      });

      if (this.props.onUpdate) {
        this.props.reloadRows(updatedUser);
      }
    }
    this.closeModal();
  }

  setSelectedToRemove = (selected) => {
    this.setState({ selectedToRemove: selected });
  };

  onSelect() {
    if (this.state.selectedToRemove && this.state.selectedToRemove.length > 0) {
      this.closeModal();
      this.setState({
        deleteSkillConfirm: true,
      });
    } else {
      this.assignSkills();
    }
  }

  canManageSkillsByDefault(user) {
    return ["business_admin", "sys_admin"].indexOf(user.role) !== -1;
  }

  renderAddModal() {
    const AddSkillModal = withModal(SkillAutoComplete);
    return (
      <AddSkillModal
        FooterComponent={() => (
          <div className="actions Modal-actions">
            <button onClick={() => this.onSelect()} className="btn btn-primary">
              {i18n.t("default:_SELECT")}
            </button>
          </div>
        )}
        title="SELECT SKILLS"
        visible={this.state.addModalVisible}
        onClose={() => this.closeModal()}
        onSkillsChange={(selected) => this.onSkillsChange(selected)}
        selectedSuggestions={this.state.selectedSkills}
        selectedToRemove={this.state.selectedToRemove}
        initialSkills={this.state.initialSkills}
        setSelectedToRemove={this.setSelectedToRemove}
        userRole={this.state.user.role}
      />
    );
  }

  handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showReinvitedAlert: false });
  };

  renderAsignProjectsModal = () => {
    const AsignPrijectsModal = withModal(ClientWizard);
    return (
      <AsignPrijectsModal
        title="Edit client projects"
        visible={this.state.asignProjectsModalVisible}
        onClose={() => this.closeAsignProjectsModal()}
        user={this.state.user}
        onUpdate={this.props.onUpdate}
      />
    );
  };

  onAttachment(attachedFiles) {
    const { files } = this.state;
    let currentFiles = files.concat(attachedFiles);
    this.setState(
      {
        files: currentFiles,
      },
      () => this.changeUserProp("files", { files: currentFiles })
    );
  }

  async removeAttachment(index) {
    const { files } = this.state;

    if (files[index]) {
      const assetId = files[index].ID || files[index].assetId;

      const result = await deleteAsset(assetId);

      if (result && result.success) {
        files.splice(index, 1);
        this.setState(
          {
            files,
          },
          () => this.changeUserProp("files", { files })
        );
      }
    }
  }

  render() {
    const {
      user,
      users,
      showReinvitedAlert,
      roleSelect,
      deleteConfirm,
      shouldLoadSkillsTab,
      initialSkills,
      deleteSkillConfirm,
      files,
    } = this.state;

    const { classes, apps } = this.props;
    const userFields = Object.values(USER_FIELDS);

    return (
      <div className="UserEditor container">
        {/* {this.renderFilesModal()} */}
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_CONFIRM_DELETE_USER")}
          show={deleteConfirm}
          onConfirm={() => this.onEraseUser()}
          onClose={() => this.setState({ deleteConfirm: false })}
        />
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_REMOVE_SKILLS")}
          show={deleteSkillConfirm}
          onConfirm={() => this.assignSkills()}
          onClose={() => {
            this.openModal();
            this.setState({
              deleteSkillConfirm: false,
            });
          }}
        />
        <Modal
          ariaHideApp={false}
          isOpen={this.state.notificationsModalOpened}
          onRequestClose={() =>
            this.setState({ notificationsModalOpened: false })
          }
          contentLabel={i18n.t("default:_NOTIFICATIONS")}
          style={{
            content: {
              width: "700px",
              maxHeight: "500px",
            },
          }}
        >
          <BlockUi tag="div">
            <NotificationsControl
              noAutoSave={true}
              notifications={user.notifications}
              onChange={(notifications) =>
                this.onNotificationsChange(notifications)
              }
            />
          </BlockUi>
        </Modal>
        {this.renderAddModal()}
        {this.renderAsignProjectsModal()}
        <ToastMessage
          showAlert={showReinvitedAlert}
          handleAlertClose={this.handleAlertClose}
          message={i18n.t("default:_USER_SUCCESSFULLY_INVITED")}
        />
        {users.length === 1 && (
          <div className="EditorView">
            {this.props.withBack && (
              <div className="col-md-12">
                <BackButton custom={true} onBack={() => this.props.onBack()} />
              </div>
            )}
            <div className="EditorView-head">
              <div className="col-md-12">
                <img
                  className={
                    user.inTraining
                      ? "avatar-img avatar-sm rounded-circle trainee"
                      : "avatar-img avatar-sm rounded-circle"
                  }
                  src={
                    user.imgUrl ? getFileTarget(user.imgUrl, "100x100") : avatar
                  }
                  alt={user.email}
                />
              </div>
              <div className="col-md-12">
                <h2 className="EditorView-title">
                  <RIEInput
                    value={
                      user.name
                        ? user.name.first + " " + user.name.last
                        : user.email
                    }
                    change={(newVal) => this.changeUserProp("name", newVal)}
                    propName="name"
                  />
                  <span />
                </h2>

                <h5 className="EditorView-hint">
                  {!roleSelect && (
                    <p
                      onClick={() => {
                        if (
                          this.props.user.profile.role === ROLE.SYSTEM_ADMIN ||
                          this.props.user.profile.role === ROLE.BUSINESS_ADMIN
                        ) {
                          this.setState({ roleSelect: true });
                        }
                      }}
                    >
                      {i18n.t(
                        `default:_${
                          user && user.role
                            ? user.role.toUpperCase()
                            : "INSPECTOR"
                        }`
                      )}
                    </p>
                  )}
                  {roleSelect &&
                    canManageRole(
                      this.props.user.profile.role,
                      ROLE.PROJECT_MANAGER
                    ) && (
                      <select
                        onChange={(e) => this.changeRole(e.target.value)}
                        value={user.role}
                        ref={(ref) => (this.roleSelectRef = ref)}
                      >
                        {this.getRoles().map((role) => {
                          if (
                            role.value === ROLE.CLIENT &&
                            !apps.enabled.includes(APP_CLIENT)
                          ) {
                            return null;
                          }
                          return (
                            <option key={role.value} value={role.value}>
                              {role.label}
                            </option>
                          );
                        })}
                      </select>
                    )}
                </h5>
              </div>
            </div>
            <div className="EditorView-content">
              {shouldDisplayUserField(
                this.props.settings,
                this.props.user,
                user,
                "email"
              ) && (
                <div className="col-md-12 m-b-30">
                  <p className="editor-title">{i18n.t("default:_EMAIL")}</p>

                  {!canManageRole(
                    this.props.user.profile.role,
                    ROLE.BUSINESS_ADMIN
                  ) && <p>{user.email}</p>}
                  {canManageRole(
                    this.props.user.profile.role,
                    ROLE.BUSINESS_ADMIN
                  ) && (
                    <RIEInput
                      value={user.email}
                      change={(newVal) => this.changeUserProp("email", newVal)}
                      propName="email"
                      validate={this.validateEmail}
                    />
                  )}
                </div>
              )}
              {user.role === ROLE.INSPECTOR && (
                <div className="col-md-12 m-b-30">
                  <Switch
                    className="float-switch"
                    label={i18n.t("default:_IN_TRAINING")}
                    onChange={(e) =>
                      this.toggleTraining(user.inTraining ? false : true)
                    }
                    checked={user.inTraining}
                  />
                </div>
              )}
              <div className="col-md-12 m-b-30">
                <Switch
                  className="float-switch"
                  label={i18n.t("default:_ALLOW_MANAGE_SKILLS")}
                  onChange={(e) =>
                    this.toggleManageSkills(!user.canManageSkills)
                  }
                  checked={
                    this.canManageSkillsByDefault(user) || user.canManageSkills
                  }
                  disabled={this.canManageSkillsByDefault(user)}
                />
              </div>
              <div className="col-md-12 m-b-30">
                <p className="editor-title">
                  {i18n.t("default:_ACCEPTED_LOCATION")}
                </p>
                <p>
                  {"acceptedLocation" in user && (
                    <div>
                      {user.acceptedLocation
                        ? i18n.t("default:_YES")
                        : i18n.t("default:_NO")}
                    </div>
                  )}
                  {!("acceptedLocation" in user) && (
                    <div>{i18n.t("default:_NOT_KNOWN_YET")}</div>
                  )}
                </p>
              </div>
              {userFields.map((field, i) => {
                return (
                  <FieldRenderer
                    key={i}
                    field={field}
                    changeUserProp={this.changeUserProp.bind(this)}
                    value={user[field.value]}
                    systemUser={user}
                  />
                );
              })}
              {apps.enabled.includes(APP_CLIENT) &&
                user.role === ROLE.CLIENT &&
                this.props.user.profile.role ===
                  (ROLE.SYSTEM_ADMIN || ROLE.PROJECT_MANAGER) && (
                  <>
                    <div
                      className="col-md-12 m-b-30"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.openAsignProjectsModal()}
                    >
                      <p className="editor-title">
                        {i18n.t("default:_CLIENT_PROJECTS")}
                      </p>

                      <ul className="list-projects">
                        {user.client_projects &&
                        Object.keys(user.client_projects).length
                          ? Object.keys(user.client_projects).map(
                              (project, i) => {
                                let currentProject =
                                  user.client_projects[project];
                                return (
                                  <li key={i}>
                                    <img
                                      alt="avatar"
                                      src={
                                        currentProject.avatar
                                          ? getFileTarget(
                                              currentProject.avatar,
                                              "100x100"
                                            )
                                          : avatarProject
                                      }
                                    />
                                    <span>{currentProject.name}</span>
                                  </li>
                                );
                              }
                            )
                          : "-"}
                      </ul>
                    </div>

                    <div
                      className="col-md-12 m-b-30"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.openAsignProjectsModal()}
                    >
                      <p className="editor-title">
                        {i18n.t("default:_COMPANIES")}
                      </p>
                      <List className={classes.listRoot}>
                        {user.companies &&
                          user.companies.length > 0 &&
                          user.companies.map((company, index) => {
                            return (
                              <ListItem
                                className={classes.listItemRoot}
                                key={index}
                              >
                                <Chip
                                  className={classes.chip}
                                  label={company}
                                  variant="outlined"
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </div>
                  </>
                )}

              {shouldDisplayUserField(
                this.props.settings,
                this.props.user,
                user,
                "projects"
              ) && <ProjectsField user={user} />}
              {shouldLoadSkillsTab &&
                shouldDisplayUserField(
                  this.props.settings,
                  this.props.user,
                  user,
                  "skills"
                ) && (
                  <div
                    className="col-md-12 m-b-30 pointer"
                    onClick={() => this.openModal()}
                  >
                    <p className="editor-title">{i18n.t("default:_SKILLS")}</p>
                    {initialSkills.length > 0 ? (
                      <Grid>
                        {initialSkills.map((skill, index) => {
                          return (
                            <SkillChip
                              withMargin
                              key={index}
                              name={skill.name}
                              backgroundColor={SKILL_COLORS.WHITE}
                              withAvatar
                              sidePanel={true}
                              skillAvatar={skill.avatar}
                              skillColor={
                                skill.expirationStatus
                                  ? setSkillColor(skill.expirationStatus.status)
                                  : SKILL_COLORS.GREEN
                              }
                            />
                          );
                        })}
                      </Grid>
                    ) : null}
                  </div>
                )}
              {shouldDisplayUserField(
                this.props.settings,
                this.props.user,
                user,
                "files"
              ) && (
                <UserFileField
                  files={files}
                  onAttachment={this.onAttachment.bind(this)}
                  removeAttachment={this.removeAttachment.bind(this)}
                  currentUser={this.state.user}
                  label={i18n.t("_default:FILES")}
                />
              )}
              <div className="col-md-12 m-b-30">
                <p className="editor-title">
                  {i18n.t("default:_RECENT_JOBS", {
                    jobs: i18n.t("default:_JOBS"),
                  })}
                </p>

                <div className="badges">
                  {user.recent_jobs && Object.keys(user.recent_jobs).length
                    ? Object.keys(user.recent_jobs).map((job, i) => (
                        <div className="badge" key={i}>
                          {this.renderStatusLabel(job, user.recent_jobs[job])}
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
              <UserActivities user={user} />
            </div>
          </div>
        )}
        {users.length > 1 && (
          <div className="MultiUserView">
            <h2>
              {i18n.t("default:_SELECTED_USERS", {
                users: i18n.t("default:_USERS"),
              })}
            </h2>
            {users.map((user, i) => (
              <MobileUserRow key={i} user={user} />
            ))}
          </div>
        )}

        <button
          onClick={() =>
            this.setState({
              notificationsModalOpened: true,
              notificationsTargetUser: user,
            })
          }
          className="btn-full"
        >
          <i className="mdi mdi-bell-ring" />
          <span>{i18n.t("default:_EDIT_NOTIFICATIONS")}</span>
        </button>
        <div className="EditorView-actions">
          <button
            onClick={() => this.resendInivation()}
            disabled={users.length === 1 && user.confirmed && user.verified}
            className="btn-full btn-lightgray"
          >
            <i className="mdi mdi-refresh" />
            <span>{i18n.t("default:_RESEND_INVITATION")}</span>
          </button>
          {this.props.user.profile.role === "sys_admin" &&
            user.confirmed &&
            user.valid &&
            ToggleService._isToggleActive(
              "impersonate",
              this.props.settings.toggles
            ) && (
              <button
                onClick={() => this.impersonateUser(user)}
                // disabled={users.length === 1 && user.confirmed && user.verified}
                className="btn-full btn-impersonate"
              >
                <i className="mdi mdi-refresh" />
                <span>Impersonate</span>
              </button>
            )}

          {users.length === 1 && (
            <button onClick={() => this.toggleBanned()} className="btn-full">
              <i className="mdi mdi-account-off" />
              <span>
                {user.banned
                  ? i18n.t("default:_UNBAN")
                  : i18n.t("default:_BAN")}
              </span>
            </button>
          )}
          {users.length > 1 && this.props.onDeselect && (
            <button onClick={() => this.banMultiple()} className="btn-full">
              <i className="mdi mdi-account-check" />
              <span>
                {user.banned
                  ? i18n.t("default:_UNBAN")
                  : i18n.t("default:_BAN")}
              </span>
            </button>
          )}
          {this.canEraseUser() && (
            <button
              onClick={() => this.setState({ deleteConfirm: true })}
              className="btn-full  btn-red"
            >
              <i className="icon-cancel" />
              <span>{i18n.t("default:_ERASE")}</span>
            </button>
          )}
          {users.length > 1 && this.props.onDeselect && (
            <button
              onClick={() => this.props.onDeselect()}
              className="btn-full btn-lightgray"
            >
              <i className="mdi mdi-file-document-box" />
              <span>{i18n.t("default:_DESELECT_ALL")}</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(
  connectWithStore(UserEditor, ["user", "settings", "apps"])
);
