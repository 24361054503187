import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import i18n from "../../../../i18n";
import DateService from "../../../../services/date";
import { feedsJobs, visit } from "../../../../services/api/jobs";

function JobActivities({ job, settings }) {
  const [jobFeeds, setJobFeeds] = useState([]);
  const [feedsPage, setFeedsPage] = useState(1);
  const [totalFeedsPages, setTotalFeedsPages] = useState(1);

  const checkForNextFeeds = async () => {
    const params = {
      page: feedsPage + 1,
      page_size: 10,
      id: job._id,
    };
    const moreFeeds = await feedsJobs(params);
    const totalPages =
      moreFeeds && moreFeeds.length > 0 ? totalFeedsPages + 1 : totalFeedsPages;
    setTotalFeedsPages(totalPages);
  };

  const getFeeds = useCallback(async () => {
    if (!job._id) return;
    const params = {
      page: feedsPage,
      page_size: 10,
      id: job._id,
    };
    const currentFeeds = await feedsJobs(params);
    await visit({
      id: job._id,
    });
    const feeds = currentFeeds ? currentFeeds : [];
    setJobFeeds(feeds);
    checkForNextFeeds();
    // eslint-disable-next-line
  }, [feedsPage, job._id]);

  useEffect(() => {
    getFeeds();
  }, [getFeeds]);

  const loadMoreResults = () => {
    setFeedsPage(feedsPage + 1);
  };

  const loadPreviousResults = () => {
    setFeedsPage(feedsPage - 1);
    setTotalFeedsPages(totalFeedsPages - 1);
  };

  return (
    <div className="m-b-30">
      <p className="editor-title">{i18n.t("default:_RECENT_FEED")}</p>
      <ul className="list-activity">
        {jobFeeds.length > 0
          ? jobFeeds.map((feed, i) => {
              return (
                <li key={i}>
                  <p dangerouslySetInnerHTML={{ __html: feed.message }} />
                  <span>
                    {DateService.getFormattedDate(
                      feed.date,
                      null,
                      settings.timezone
                    )}
                  </span>
                </li>
              );
            })
          : "-"}
      </ul>
      <Grid container justifyContent="center">
        {feedsPage > 1 && (
          <Button color="primary" onClick={() => loadPreviousResults()}>
            <ArrowBackIosIcon />
          </Button>
        )}
        {totalFeedsPages > Number(feedsPage) && (
          <Button color="primary" onClick={() => loadMoreResults()}>
            {i18n.t("default:_LOAD_MORE")}
          </Button>
        )}
      </Grid>
    </div>
  );
}

export default JobActivities;
