import Http from "../http";

export const getClientState = async (params) => {
  const path = `/v1/client/state/${params.screen}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getClientStateFromId = async (id) => {
  const path = `/v1/client/state/byId/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateClientState = async (params) => {
  const path = `/v1/client/state/${params.screen}`;
  try {
    const response = await Http.sharedInstance
      .withAuth()
      .post(path, params.state);
    return response.data;
  } catch (e) {
    return [];
  }
};
