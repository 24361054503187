import React from "react";
import { connect } from "react-redux";
import store from "../redux/store";

const mapStateToProps = (state, reducers) => {
  var obj = {};
  reducers.forEach((reducer) => {
    obj[reducer] = state[reducer];
  });
  return obj;
};

export const connectWithStore = (WrappedComponent, reducers = []) => {
  let ConnectedWrappedComponent = connect(
    (state) => {
      return mapStateToProps(state, reducers);
    },
    null,
    null,
    { forwardRef: true }
  )(WrappedComponent);
  return React.forwardRef((props, ref) => {
    return <ConnectedWrappedComponent ref={ref} {...props} store={store} />;
  });
};
