import React, { useState, useCallback, useEffect } from "react";
import { connectWithStore } from "../../services/redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsCategory from "./notifications_category";
import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_DELIVERY_TYPE,
} from "./notification.models";
import { MyProfile } from "../../redux/actions";
import { isMobileDevice } from "../../services/mobile";

const useStyles = makeStyles((theme) => ({
  headerItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    borderLeft: "1px solid lightgrey",
  },
}));

const NotificationsControl = ({
  dispatch,
  targetDeliveries,
  notifications,
  settings,
  noAutoSave,
  onChange,
  fromProfile,
}) => {
  if (!targetDeliveries) {
    targetDeliveries = [];
  }
  const getInitialState = () => {
    const initialState = {};
    if (notifications && notifications.length > 0) {
      for (const cat of NOTIFICATION_CATEGORIES) {
        for (const not of notifications) {
          if (not.startsWith(cat.type)) {
            if (initialState.hasOwnProperty(cat.type)) {
              initialState[cat.type] = [...initialState[cat.type], not];
            } else {
              initialState[cat.type] = [not];
            }
          }
        }
      }
    }

    return initialState;
  };
  const classes = useStyles();
  const [notis, setNotis] = useState(getInitialState());

  const getDeliveries = () => {
    if (targetDeliveries.length > 0) {
      return NOTIFICATION_DELIVERY_TYPE.filter(
        (d) => targetDeliveries.indexOf(d.type) !== -1
      );
    }

    return NOTIFICATION_DELIVERY_TYPE;
  };

  useEffect(() => {
    if (!noAutoSave) {
      let result = [];
      for (const key in notis) {
        result = [...result, ...notis[key]];
      }
      dispatch(MyProfile.setNotifications(result));
    }
  }, [notis, dispatch, noAutoSave, onChange]);

  const change$ = useCallback(
    (items, categoryType) => {
      const newNotis = {
        ...notis,
        [categoryType]: items,
      };
      setNotis(newNotis);
      if (noAutoSave) {
        let result = [];
        for (const key in newNotis) {
          result = [...result, ...newNotis[key]];
        }
        onChange(result);
      }
    },
    [notis, onChange, noAutoSave]
  );

  return (
    <div className="NotificationsControl">
      <Grid container item xs={12}>
        <Grid item xs={isMobileDevice() ? 9 : fromProfile ? 4 : 6} />
        {getDeliveries().map((delivery, delIndex) => (
          <Grid
            className={classes.headerItem}
            item
            xs={2}
            key={"delivery_" + delIndex}
          >
            <span>{delivery.label}</span>
          </Grid>
        ))}
      </Grid>
      {NOTIFICATION_CATEGORIES.map((category, catIndex) => (
        <NotificationsCategory
          settings={settings}
          targetDeliveries={targetDeliveries}
          key={"category_" + catIndex}
          category={category}
          notifications={notifications.filter((n) =>
            n.startsWith(category.type)
          )}
          onChange={(items) => change$(items, category.type)}
          fromProfile={fromProfile}
        />
      ))}
    </div>
  );
};

export default connectWithStore(NotificationsControl, ["user", "settings"]);
