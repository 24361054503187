import React, { useState, useCallback, useEffect } from "react";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import { EditorState, convertFromRaw } from "draft-js";
import {
  myFunctionForGrabbingAllPluginDecorators,
  transformToValidDraftObject,
} from "../../services/draft_editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank", // default is '_self'
});
const plugins = [linkifyPlugin];

function DecoratedTextReader({ text }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const initEditorState = useCallback(() => {
    let messageValue;
    let decorator = myFunctionForGrabbingAllPluginDecorators(plugins);
    if (text && typeof text !== "string") {
      messageValue = transformToValidDraftObject(text);
    } else {
      messageValue = text;
    }
    const rawMessage =
      messageValue && typeof messageValue === "string"
        ? createEditorStateWithText(messageValue)
        : messageValue
        ? EditorState.createWithContent(convertFromRaw(messageValue), decorator)
        : EditorState.createEmpty();

    setEditorState(rawMessage);
  }, [text]);

  useEffect(() => {
    initEditorState();
  }, [initEditorState]);

  const onChange = () => {
    return null;
  };

  return (
    <div className={`text-area-editor`}>
      <div className="editor">
        <Editor
          id="item"
          editorState={editorState}
          onChange={onChange}
          readOnly={true}
          plugins={plugins}
        />
      </div>
    </div>
  );
}

export default DecoratedTextReader;
