import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import i18n from "../../i18n";
import { getUserCurrentLocation } from "../../services/location";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { connectWithStore } from "../../services/redux";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  async handleSelect(address) {
    const geoCode = await geocodeByAddress(address);
    const latLng = await getLatLng(geoCode[0]);
    this.props.onChange(
      Object.assign(latLng, {
        address: geoCode[0].formatted_address,
      })
    );
  }

  async getUserLocation() {
    if (!this.props.onMyLocation) {
      return;
    }
    this.props.dispatch(showLoading());
    try {
      const position = await getUserCurrentLocation();
      this.props.onMyLocation(position);
    } catch (e) {
      console.error(e);
    }
    this.props.dispatch(hideLoading());
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect.bind(this)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 3 }}>
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input form-control m-b-10",
                  })}
                />
              </div>
              <div className="m-l-10 text-right" style={{ flex: 1 }}>
                <button
                  onClick={() => this.getUserLocation()}
                  className="btn btn-primary btn-my-location"
                >
                  {" "}
                  <i
                    style={{ fontSize: "12px" }}
                    className="mdi mdi-crosshairs-gps"
                  />
                  {i18n.t("default:_MY_LOCATION")}
                </button>
              </div>
            </div>
            <div
              className="autocomplete-dropdown-container"
              style={{
                position: "absolute",
                width: "95%",
                zIndex: 9999,
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, suggIndex) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggIndex}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default connectWithStore(LocationSearchInput);
