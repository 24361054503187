import React, { Component } from "react";
import Modal from "react-modal";

export default class FullScreenModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.show !== state.show) {
      return {
        show: nextProps.show,
      };
    }
    return null;
  }

  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.show}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            padding: "0px",
            overflowX: "hidden",
          },
        }}
        onRequestClose={() => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
      >
        {this.props.children}
      </Modal>
    );
  }
}
