import { USER_LOCATION_SET_LOCATION } from "../constants";

const initalState = { coords: null };

const userLocation = (state = Object.assign({}, initalState), action) => {
  switch (action.type) {
    case USER_LOCATION_SET_LOCATION:
      return Object.assign({}, state, {
        coords: action.data,
      });
    default:
      return state;
  }
};

export default userLocation;
