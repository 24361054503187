import React from "react";
import { connectWithStore } from "../../../../services/redux";
import { BlueprintEditor } from "../../../../redux/actions";
import FloatingInput from "./floating_input";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 50,
    left: 0,
    background: "white",
    border: "2px solid grey",
    borderRadius: 10,
    display: ({ showHeightInput }) => (showHeightInput ? "flex" : "none"),
    width: 200,
    padding: "10px 30px 10px 10px",
    zIndex: 10002,
  },
}));

function HeightInput({ blueprintEditor, dispatch, updateFileProp }) {
  const { showHeightInput, measurements, fileId, elements } = blueprintEditor;
  const classes = useStyles({ showHeightInput });

  const handleClose = () => {
    const currentMeasurements = { ...measurements };
    elements
      .filter((el) => (el.type ? el.type === "height" : null))
      .forEach((el) => {
        const target = blueprintEditor.app.find("#" + el.id);
        if (target.length > 0) {
          const measure = {
            elementId: el.elementId,
            canvasData: target.toJSON()[0],
            visible: el.visible,
            id: el.id,
          };
          currentMeasurements.arrows.height = measure;
        }
      });
    updateFileProp("measurements", currentMeasurements, fileId);
    dispatch(BlueprintEditor.setShowHeightInput(false));
  };

  return (
    <Grid container className={classes.root}>
      <FloatingInput onClose={handleClose} type="height" />
    </Grid>
  );
}

export default connectWithStore(HeightInput, ["blueprintEditor"]);
