const initialState = {
  activeMenu: false,
  buttons: [],
  searchHandler: null,
  isActiveFilter: false,
  openWizard: false,
  openJobWizard: false,
};

const topNavMenu = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case "SET_BUTTONS":
      state.buttons = action.data;
      return Object.assign({}, state);
    case "SET_ACTIVE_MENU":
      state.activeMenu = action.data;
      return Object.assign({}, state);
    case "SET_SEARCH_HANDLER":
      state.searchHandler = action.data;
      return Object.assign({}, state);
    case "SET_ACTIVE_SEARCH":
      state.isActiveFilter = action.data || false;
      return Object.assign({}, state);
    case "OPEN_WIZARD":
      state.openWizard = action.data;
      return Object.assign({}, state);
    case "OPEN_JOB_WIZARD":
      state.openJobWizard = action.data;
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default topNavMenu;
