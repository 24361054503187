import {
  FILTERS_SET_INSPECTOR,
  FILTERS_SET_FILTER_ME,
  FILTERS_SET_DATE,
  FILTERS_SET_STATUS,
  FILTERS_SET_JOB_TYPE,
  FILTERS_SET_QUESTIONS,
  FILTERS_SET_ANSWERS,
  FILTERS_SET_MY_PROJECTS,
  FILTERS_SET_PROJECT,
  FILTERS_SET_SKILLS,
  FILTERS_SET_SORT,
  FILTERS_SET_SHOW_PROJECTS_MODAL,
  FILTERS_SET_SHOW_SKILLS_MODAL,
  FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL,
  FILTERS_SET_PROJECT_MANAGERS,
  FILTERS_SET_MY_PROJECT_MANAGER,
  FILTER_PROJECT_FIELDS
} from "../constants";

const initialState = {
  filterInspector: null,
  filterMe: false,
  filterDate: [],
  filterStatus: [],
  filterJobType: [],
  filterQuestions: [],
  filterAnswers: {},
  filterMyProjects: false,
  filterMyProjectManager: false,
  filterProject: [],
  filterProjectManagers: [],
  filterSkills: [],
  sort: "created_at",
  showProjectsModal: false,
  showSkillsModal: false,
  filterProjectFields: []
};

const jobsFilters = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case FILTERS_SET_INSPECTOR:
      return Object.assign({}, state, {
        filterInspector: data,
      });
    case FILTERS_SET_FILTER_ME:
      return Object.assign({}, state, {
        filterMe: data,
      });
    case FILTERS_SET_DATE:
      return Object.assign({}, state, {
        filterDate: data,
      });
    case FILTERS_SET_STATUS:
      return Object.assign({}, state, {
        filterStatus: data,
      });
    case FILTERS_SET_JOB_TYPE:
      return Object.assign({}, state, {
        filterJobType: data,
      });
    case FILTERS_SET_QUESTIONS:
      return Object.assign({}, state, {
        filterQuestions: data,
      });
    case FILTERS_SET_ANSWERS:
      return Object.assign({}, state, {
        filterAnswers: data,
      });
    case FILTERS_SET_PROJECT:
      return Object.assign({}, state, {
        filterProject: data,
      });
    case FILTERS_SET_PROJECT_MANAGERS:
      return Object.assign({}, state, {
        filterProjectManagers: data,
      });
    case FILTERS_SET_MY_PROJECTS:
      return Object.assign({}, state, {
        filterMyProjects: data,
      });
    case FILTERS_SET_MY_PROJECT_MANAGER:
      return Object.assign({}, state, {
        filterMyProjectManager: data,
      });
    case FILTERS_SET_SKILLS:
      return Object.assign({}, state, {
        filterSkills: data,
      });
    case FILTERS_SET_SORT:
      return Object.assign({}, state, {
        sort: data,
      });
    case FILTER_PROJECT_FIELDS:
      return Object.assign({}, state, {
        filterProjectFields: data,
      });
    case FILTERS_SET_SHOW_PROJECTS_MODAL:
      return Object.assign({}, state, {
        showProjectsModal: data,
      });
    case FILTERS_SET_SHOW_PROJECT_MANAGERS_MODAL:
      return Object.assign({}, state, {
        showProjectManagersModal: data,
      });
    case FILTERS_SET_SHOW_SKILLS_MODAL:
      return Object.assign({}, state, {
        showSkillsModal: data,
      });
    default:
      return state;
  }
};

export default jobsFilters;
