import React from "react";
import Konva from "konva";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { connectWithStore } from "../../../services/redux";
import { BlueprintEditor } from "../../../redux/actions";
import { removeTransformers } from "./utils";
import { MEASURE_TYPE } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 15,
    borderBottom: "1px solid #F0F0F0",
  },
  marginBottom: {
    marginBottom: 10,
  },
  label: {
    color: "#b0bac9",
  },
  pointer: {
    cursor: "pointer",
  },
}));

function MeasureRow({
  onMetricsClick,
  onPixelsClick,
  measurements,
  measureType,
}) {
  const classes = useStyles();

  return (
    <Grid
      className={`${classes.marginBottom}`}
      container
      item
      xs={10}
      justify="center"
    >
      <Grid item xs={4} className={classes.label}>
        <Typography align="left">{measureType}</Typography>
      </Grid>
      <Grid
        container
        justify="flex-end"
        item
        xs={3}
        className={classes.pointer}
        onClick={onPixelsClick}
      >
        {`${Math.round(measurements.pixels[measureType])} px`}
      </Grid>
      <Grid container justifyContent="center" item xs={1}>
        {`/`}
      </Grid>
      <Grid
        container
        justify="flex-start"
        item
        xs={3}
        className={classes.pointer}
        onClick={onMetricsClick}
      >
        {`${measurements.metrics[measureType]} m`}
      </Grid>
    </Grid>
  );
}

function MeasurePanel({ blueprintEditor, dispatch }) {
  const classes = useStyles();
  const { measurements } = blueprintEditor;

  const selectMeasure = (type) => {
    removeTransformers();
    const transformer = new Konva.Transformer();
    const measure = measurements.arrows[type];
    const target = blueprintEditor.app.find("#" + measure.id);
    const shape = target[0];
    transformer.nodes([shape]);
    dispatch(BlueprintEditor.setSelectedElement(shape));
    blueprintEditor.layer.add(transformer);
    blueprintEditor.layer.batchDraw();
  };

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid className={classes.marginBottom} container item justify="center">
        <Typography variant="h6">Measures</Typography>
      </Grid>
      {measurements && measurements.arrows && measurements.arrows.width && (
        <MeasureRow
          measurements={measurements}
          measureType={MEASURE_TYPE.WIDTH}
          onPixelsClick={() => selectMeasure(MEASURE_TYPE.WIDTH)}
          onMetricsClick={() =>
            dispatch(BlueprintEditor.setShowWidthInput(true))
          }
        />
      )}
      {measurements && measurements.arrows && measurements.arrows.height && (
        <MeasureRow
          measurements={measurements}
          measureType={MEASURE_TYPE.HEIGHT}
          onPixelsClick={() => selectMeasure(MEASURE_TYPE.HEIGHT)}
          onMetricsClick={() =>
            dispatch(BlueprintEditor.setShowHeightInput(true))
          }
        />
      )}
    </Grid>
  );
}

export default connectWithStore(MeasurePanel, ["blueprintEditor"]);
