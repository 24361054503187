import {
  CURRENT_PROJECT_SET_PROJECT,
  CURRENT_PROJECT_RESET_PROJECT,
  CURRENT_PROJECT_UPDATE_PROJECT,
} from "../constants";

const initialState = {
  project: null,
};

const currentProject = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case CURRENT_PROJECT_SET_PROJECT:
      return Object.assign({}, state, {
        project: data,
      });
    case CURRENT_PROJECT_RESET_PROJECT:
      return Object.assign({}, state, {
        project: null,
      });
    case CURRENT_PROJECT_UPDATE_PROJECT:
      return Object.assign({}, state, {
        project: Object.assign({}, state.project, data),
      });
    default:
      return state;
  }
};

export default currentProject;
