import React from "react";
import { connectWithStore } from "../../../services/redux";
import { canManageRole, ROLE } from "../../../services/roles";
import UserFieldWrapper from "./field_wrapper";
import { RIEInput } from "riek";
import { TextField } from "@material-ui/core";

function UserTextField({ field, user, value, changeUserProp, isSubField }) {
  const path = window.location.pathname;
  const isProfilePage = path.includes("profile");

  const onInputChange = (newVal) => {
    if (isSubField) {
      const propValue = isProfilePage ? newVal : newVal[field.value];
      changeUserProp(propValue);
    } else {
      changeUserProp(field.value, newVal);
    }
  };

  return (
    <UserFieldWrapper label={field.name}>
      {isProfilePage ? (
        <TextField
          id={field.value}
          placeholder={field.name}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          onChange={(e) => onInputChange(e.target.value)}
        />
      ) : (
        <>
          {!canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN) && (
            <p>{value}</p>
          )}
          {canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN) && (
            <RIEInput
              value={value || `No ${field.name} available`}
              change={(newVal) => onInputChange(newVal)}
              propName={field.value}
            />
          )}
        </>
      )}
    </UserFieldWrapper>
  );
}

export default connectWithStore(UserTextField, ["user"]);
