import React, { useState, useCallback, useEffect } from "react";
import FieldPreview from "./field_preview";
import { connectWithStore } from "../../../services/redux";
import { Grid } from "@material-ui/core";
import { PROJECT_FIELD_TYPE, ICON } from "./constants";
import SkillAutoComplete from "../../../components/autoselect/skill_autoselect";
import withModal from "../../../hoc/withModal";
import i18n from "../../../i18n";
import SKILL_COLORS from "../../../services/skill_colors";
import { updateSignleProjectField } from "../../../services/api/projects";
import { CurrentProject } from "../../../redux/actions";
import { getSkill } from "../../../services/api/skills";
import Confirm from "../../../components/confirm/confirm";
import SkillChip from "../../../components/skills/skill_chip";

function Skills({
  label,
  icon,
  id,
  currentProject,
  editable,
  dispatch,
  accessRules,
}) {
  const { project } = currentProject;
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState(
    project && project[id] ? project[id] : []
  );
  const [initialSkills, setInitialSkills] = useState(
    project && project[id] ? project[id] : []
  );
  const [selectedToRemove, setSelectedToRemove] = useState([]);
  const [deleteSkillConfirm, setDeleteSkillConfirm] = useState(false);

  const openModal = () => {
    setAddModalVisible(true);
  };

  const closeModal = () => {
    setAddModalVisible(false);
  };

  const loadSkills = useCallback(async () => {
    const currentSkills = [];
    if (project && project[id]) {
      const promises = [];
      for (let skill of project[id]) {
        promises.push(getSkill(skill));
      }
      const result = await Promise.all(promises);
      for (let index = 0; index < result.length; index++) {
        const response = result[index];
        if (response.success) {
          currentSkills.push(response.data);
        }
      }
    }
    setSelectedSkills(currentSkills);
    setInitialSkills(currentSkills);
  }, [id, project]);

  useEffect(() => {
    loadSkills();
  }, [loadSkills, project]);

  const onSkillsChange = (selected) => {
    setSelectedSkills(
      selected.filter((val) => val && val._id && typeof val._id !== "undefined")
    );
  };

  const assignSkills = async () => {
    const updatedSelectedSkills = [...selectedSkills].filter(
      (skill) => !selectedToRemove.includes(skill)
    );
    const skillIds = updatedSelectedSkills.map((skill) => skill._id);
    await updateSignleProjectField({
      id: project._id,
      field: {
        id,
        value: skillIds,
      },
    });
    dispatch(
      CurrentProject.updateProject({
        [id]: skillIds,
      })
    );
    setSelectedSkills(updatedSelectedSkills);
    setInitialSkills(updatedSelectedSkills);
    setSelectedToRemove([]);
    setDeleteSkillConfirm(false);

    closeModal();
  };

  const onSelect = () => {
    if (selectedToRemove && selectedToRemove.length > 0) {
      closeModal();
      setDeleteSkillConfirm(true);
    } else {
      assignSkills();
    }
  };

  const setNewSelectedToRemove = (selected) => {
    setSelectedToRemove(selected);
  };

  const renderAddModal = () => {
    const AddSkillModal = withModal(SkillAutoComplete);
    return (
      <AddSkillModal
        FooterComponent={() => (
          <div className="actions Modal-actions">
            <button onClick={() => onSelect()} className="btn btn-primary">
              {i18n.t("default:_SELECT")}
            </button>
          </div>
        )}
        title="SELECT SKILLS"
        visible={addModalVisible}
        onClose={() => closeModal()}
        onSkillsChange={(selected) => onSkillsChange(selected)}
        selectedSuggestions={
          selectedSkills && Array.isArray(selectedSkills) ? selectedSkills : []
        }
        selectedToRemove={selectedToRemove}
        initialSkills={initialSkills}
        setSelectedToRemove={setNewSelectedToRemove}
        skillColor={SKILL_COLORS.GREEN}
      />
    );
  };

  return (
    <div>
      {renderAddModal()}
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_REMOVE_SKILLS")}
        show={deleteSkillConfirm}
        onConfirm={() => assignSkills()}
        onClose={() => {
          openModal();
          setDeleteSkillConfirm(false);
        }}
      />
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.SKILLS]}
        accessRules={accessRules}
        onClick={() => openModal()}
      >
        {initialSkills.length > 0 ? (
          <Grid>
            {initialSkills.map((skill, index) => {
              return (
                <SkillChip
                  withMargin
                  backgroundColor={SKILL_COLORS.WHITE}
                  withAvatar
                  sidePanel={true}
                  skillAvatar={skill.avatar}
                  key={index}
                  name={skill.name}
                  skillColor={SKILL_COLORS.GREEN}
                />
              );
            })}
          </Grid>
        ) : null}
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(Skills, ["currentProject", "user"]);
