/****
 * IMPORTANT NOTE: When creating new reducers use constants and put them in constants.js, do not attempt to change existing one's
 * to not cause bugs in existing codebase unless required by someone to do so
 */
import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import user from "./user";
import jobType from "./job_type";
import pageTitle from "./page_title";
import topNavMenu from "./top_nav_menu";
import settings from "./settings";
import notificationPanel from "./notification_panel";
import wizardProject from "./wizard_project";
import wizardJob from "./wizard_job";
import wizardUser from "./wizard_user";
import wizard from "./wizard";
import my_profile from "./my_profile";
import fieldEditor from "./field_editor";
import currentProject from "./current_project";
import stepQuestions from "./step_questions";
import userAutocomplete from "./user_autocomplete";
import blueprintEditor from "./blueprint_editor";
import shiftView from "./shift_view";
import userLocation from "./location";
import loadingBarApp from "./loading_bar_app";
import currentWidget from "./current_widget";
import instanceWizard from "./instance_wizard";
import gridTable from "./grid_table";
import jobsFilters from "./filters_jobs";
import apps from "./apps";
import projects from "./projects";
import multiStepFilter from "./multi_step_filter";
import automationWizard from "./automation_wizard";
import bluePrintsFilters from "./filters_blueprints";
import jobQuestions from "./questions";
import popupWidget from "./popupWidget";
import jobRow from "./job_row";
import sso from "./sso";
import filterViews from "./filter_views";
import utils from "./utils";

export default combineReducers({
  user,
  loadingBar: loadingBarReducer,
  jobType,
  pageTitle,
  settings,
  topNavMenu,
  notificationPanel,
  wizardJob,
  wizardUser,
  wizardProject,
  wizard,
  my_profile,
  fieldEditor,
  currentProject,
  stepQuestions,
  userAutocomplete,
  blueprintEditor,
  shiftView,
  userLocation,
  loadingBarApp,
  currentWidget,
  instanceWizard,
  gridTable,
  jobsFilters,
  apps,
  projects,
  multiStepFilter,
  automationWizard,
  bluePrintsFilters,
  jobQuestions,
  popupWidget,
  jobRow,
  sso,
  filterViews,
  utils
});
