import React, { Component } from "react";
import Logo from "./partials/logo/logo";
import { connectWithStore } from "../services/redux";
import Loader from "../components/loader/loader";
import { ToastContainer } from "react-toastr";
import { Helmet } from "react-helmet";
import { getFileTarget } from "../services/api/file_upload";
import SamuraiLogo from "./partials/logo/samurai_logo";

class AuthLayout extends Component {
  componentWillMount() {
    if (this.props.user.access_token.accessToken) {
      this.props.children.props.history.push("/dashboard");
    }
  }

  render() {
    return (
      <div className="authLayout">
        <Helmet>
          <link
            rel="apple-touch-icon"
            href={
              this.props.settings.secondaryLogoUrl
                ? getFileTarget(this.props.settings.secondaryLogoUrl, "100x100")
                : "/logo.default.png"
            }
          />
        </Helmet>
        <ToastContainer
          ref={(ref) => (window.toast = ref)}
          className="toast-top-center"
        />
        <Loader store={this.props.store} />
        <div className="container-fluid">
          <div className="row ">
            <div
              className={
                !this.props.children.props.hideLogo
                  ? "col-lg-4  bg-white"
                  : "col-lg-12  bg-white"
              }
            >
              <div className="row align-items-center m-h-100">
                <div className="mx-auto col-md-8">
                  <div className="p-b-20 text-center">
                    <Logo hideSecondary={true} />
                  </div>
                  {this.props.children}
                  <SamuraiLogo />
                </div>
              </div>
            </div>
            {!this.props.children.props.hideLogo && (
              <div className="col-lg-8 d-none d-md-block bg-cover login-bg" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connectWithStore(AuthLayout, ["loadingBar", "user", "settings"]);
