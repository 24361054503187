import React, { useState, useRef } from "react";
import RichTextEditor from "../../../components/draft_editor/rich_text_editor";
import { connectWithStore } from "../../../services/redux";
import { canManageRole, ROLE } from "../../../services/roles";
import UserFieldWrapper from "./field_wrapper";
import i18n from "../../../i18n";
import { useOutsideClick } from "../../../services/hooks";

function UserRichTextField({ user, field, value, changeUserProp }) {
  const [isActive, setIsActive] = useState(false);
  const [richValue, setRichValue] = useState(value);
  const wrapperRef = useRef(null);

  const updateUser = () => {
    if (isActive) {
      //alert("You clicked outside of me!");
      changeUserProp(field.value, richValue);
      setIsActive(false);
    }
  };

  useOutsideClick(wrapperRef, updateUser);

  return (
    <div ref={wrapperRef}>
      <UserFieldWrapper
        label={field.name}
        onFieldClick={() => setIsActive(true)}
      >
        <RichTextEditor
          placeholder={i18n.t("default:_ITEMS")}
          value={richValue}
          onValueChange={(val) => setRichValue(val)}
          id="user-items"
          previewMode={
            !(canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN) && isActive)
          }
        />
      </UserFieldWrapper>
    </div>
  );
}

export default connectWithStore(UserRichTextField, ["user"]);
