import React, { useState, useCallback, useEffect } from "react";
import FieldPreview from "./field_preview";
import { CurrentProject } from "../../../redux/actions";
import FileManager from "../../../components/file_manager/file_manager";
import { updateSignleProjectField } from "../../../services/api/projects";
import withModal from "../../../hoc/withModal";
import { connectWithStore } from "../../../services/redux";
import { List, ListItem, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import { canSetBp, canUserEdit } from "../../../services/access";
import FullScreenModal from "../../../layout/partials/modals/full_screen_modal";
import { BlueprintEditor } from "../../../redux/actions";
import BPEditor from "../../../components/blueprints/editor/editor";
import { batch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    padding: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
  },
  chip: {
    width: "100%",
    marginRight: "5px",
    marginBottom: "5px",
    color: "inherit",
  },
}));

const ALLOWED_MIMES =
  "image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const FileManagerModal = withModal(FileManager);

function FileListModal({
  visible,
  dispatch,
  onClose,
  id,
  currentText,
  project,
  title,
  accessRules,
  user,
  onUpdate,
}) {
  const onAttachment = useCallback(
    (files) => {
      const newFiles = project[id] ? [...project[id]] : [];
      files.forEach((file) => {
        const fileInfo = {
          value: file.originalName,
          id: file.assetId,
          location: file.Location,
        };
        newFiles.push(fileInfo);
      });
      onUpdate(newFiles);
    },
    [onUpdate, id, project]
  );

  const onRemoveAttachment = useCallback(
    (index) => {
      const newFiles = project[id] ? [...project[id]] : [];
      if (newFiles[index]) {
        newFiles.splice(index, 1);
      }
      onUpdate(newFiles);
    },
    [onUpdate, id, project]
  );

  const onUpdateName = useCallback(
    (name, assetId) => {
      const newFiles = project[id] ? [...project[id]] : [];
      const currentFile = newFiles.filter((file) => file.id === assetId)[0];
      currentFile.value = name;
      onUpdate(newFiles);
    },
    [onUpdate, id, project]
  );

  const onEditBlueprint = (fileInfo) => {
    const newFiles = project[id] ? [...project[id]] : [];
    const currentFile = newFiles.filter(
      (file) => file.id === fileInfo.assetId
    )[0];
    batch(() => {
      dispatch(BlueprintEditor.setFile(fileInfo.Location));
      dispatch(BlueprintEditor.setFileId(fileInfo.assetId));
      dispatch(
        BlueprintEditor.setImageRotation(
          currentFile.rotation ? currentFile.rotation : 0
        )
      );
      dispatch(
        BlueprintEditor.setImageGrayscale(
          currentFile.grayscale ? currentFile.grayscale : false
        )
      );
      dispatch(
        BlueprintEditor.setMeasurements(
          currentFile.measurements ? currentFile.measurements : null
        )
      );
      dispatch(
        BlueprintEditor.setMeasureIntroDisplayed(
          currentFile.measureIntroDisplayed
            ? currentFile.measureIntroDisplayed
            : false
        )
      );
      dispatch(BlueprintEditor.setShowEditBpModal(true));
      dispatch(BlueprintEditor.changeMode("SET"));
    });
    onClose();
  };

  return (
    <FileManagerModal
      visible={visible}
      onClose={() => onClose()}
      title={title}
      files={project[id] ? [...project[id].map((file) => file.id)] : []}
      fieldFiles={project[id] ? [...project[id]] : []}
      onAttachment={(files) => onAttachment(files)}
      removeAttachment={(index) => onRemoveAttachment(index)}
      allowedFiles={ALLOWED_MIMES}
      editableName={canUserEdit(user, accessRules)}
      bpFile={true}
      editBlueprint={onEditBlueprint}
      updateName={onUpdateName}
      names={project[id] ? [...project[id].map((file) => file.value)] : []}
      canSetBp={canSetBp(user, accessRules)}
    />
  );
}

const FileListModalConnected = connectWithStore(FileListModal, ["user"]);

function BlueprintList({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  blueprintEditor,
  dispatch,
  user,
}) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const [finalValue, setFinalValue] = useState([]);
  const { project } = currentProject;
  const { showEditBpModal } = blueprintEditor;

  const onUpdate = useCallback(
    async (newFiles) => {
      await updateSignleProjectField({
        id: project._id,
        field: {
          id,
          value: newFiles,
        },
      });
      dispatch(
        CurrentProject.updateProject({
          [id]: newFiles,
        })
      );
    },
    [dispatch, id, project]
  );

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  useEffect(() => {
    if (editable) return;
    const value = project[id] ? [...project[id]] : [];
    if (value && value.length > 0) {
      setFinalValue(value);
    }
  }, [id, project, editable]);

  const updateFileProp = (prop, value, assetId) => {
    const newFiles = project[id] ? [...project[id]] : [];
    const currentFile = newFiles.filter((file) => file.id === assetId)[0];
    currentFile[prop] = value;
    onUpdate(newFiles);
  };

  return (
    <div>
      <FullScreenModal show={showEditBpModal}>
        <BPEditor job={{}} updateFileProp={updateFileProp} onClose={() => setModalVisible(true)} />
      </FullScreenModal>
      {!editable && (
        <FileListModalConnected
          project={project}
          onClose={onClose}
          id={id}
          currentText={project[id]}
          visible={modalVisible}
          title={label}
          accessRules={accessRules}
          onUpdate={onUpdate}
        />
      )}
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.BLUEPRINT_LIST]}
        onClick={() => (!editable ? setModalVisible(true) : null)}
        accessRules={accessRules}
      >
        {!editable && (
          <List className={classes.listRoot}>
            {finalValue.length > 0 &&
              finalValue
                .filter((file) => file.id)
                .map(({ value }, index) => {
                  return (
                    <ListItem className={classes.listItemRoot} key={index}>
                      <Chip
                        onClick={() =>
                          canUserEdit(user, accessRules)
                            ? setModalVisible(true)
                            : null
                        }
                        className={classes.chip}
                        label={value}
                        variant="outlined"
                      />
                    </ListItem>
                  );
                })}
          </List>
        )}
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(BlueprintList, [
  "currentProject",
  "blueprintEditor",
  "user"
]);
