import React, { forwardRef } from "react";
import { getFileTarget } from "../../../../services/api/file_upload";
import JobBadge from "../../../../components/job_badge/job_badge";
import CircleUserList from "../../../../components/circle_user_list/circle_user_list";
import { connectWithStore } from "../../../../services/redux";
import { ROLE } from "../../../../services/roles";
import StatusSelect from "./status_select";
import { assignMultiple, getCurrentJob } from "../../../../services/api/jobs";
import MsTeamsButton from "../../../../components/microsoft/ms_teams_icon";
import DateTimePicker from "../../../../components/date_picker/date_time_picker";

const JobHeader = forwardRef((props, ref) => {
  const {
    avatar,
    changeStatus,
    changeJobProp,
    jobs,
    settings,
    user,
    toggleChangeStatus,
    onShare,
    fromFiles,
    onUpdate,
    currentJob,
    setCurrentJob,
  } = props;

  const canAssignUsers = () => {
    const { allowInspectorAssign } = settings.security;
    const { role } = user.profile;
    const condition =
      allowInspectorAssign === false && [ROLE.INSPECTOR].includes(role);
    return !condition;
  };

  const toggleChangeAssignees = async (users) => {
    const requestJobs = jobs.map((job) => job._id);
    const moderatedCurrentJob = { ...currentJob };

    moderatedCurrentJob.users = [];
    users.forEach((user) => {
      moderatedCurrentJob.users.push(user.value);
    });

    await assignMultiple({
      users: moderatedCurrentJob.users,
      jobs: requestJobs,
    });

    if (onUpdate) {
      onUpdate(moderatedCurrentJob);
    }

    const updatedCurrentJob = await getCurrentJob(
      { id: moderatedCurrentJob._id },
      "v2"
    );
    setCurrentJob(updatedCurrentJob);
    //this.setState({ currentJob: updatedCurrentJob });
  };

  return (
    <>
      <div className="EditorView-head">
        <div
          className="EditorView-avatar"
          style={{
            backgroundImage: `url(${
              currentJob.project && currentJob.project.avatar
                ? getFileTarget(currentJob.project.avatar, "500x500")
                : avatar
            })`,
          }}
        >
          <MsTeamsButton
            styles={{ position: "absolute", top: 20, right: 20 }}
            projectId={currentJob.project && currentJob.project._id}
            msChannelId={currentJob.project && currentJob.project.msChannelId}
          />
          <div className="ImagePreview" />

          {currentJob.jobType && !currentJob.jobType.icon && (
            <div className="EditorView-time">
              <i className="mdi mdi-briefcase-outline" />
            </div>
          )}

          {currentJob.jobType && currentJob.jobType.icon && (
            <div
              className="EditorView-time"
              style={{
                backgroundImage: `url(${getFileTarget(
                  currentJob.jobType.icon,
                  "100x100"
                )})`,
              }}
            />
          )}
          <div className="EditorView-start_date">
            <DateTimePicker
              defaultValue={currentJob.startDate}
              onClose={(date) => {
                if (date) {
                  changeJobProp("startDate", {
                    startDate: date.toString(),
                  });
                }
              }}
            />
          </div>

          {!changeStatus && (
            <div onClick={() => toggleChangeStatus()}>
              <JobBadge status={currentJob.status} />
            </div>
          )}

          {changeStatus && (
            <StatusSelect
              onStatusChange={(e) =>
                changeJobProp("status", { status: e.value })
              }
              currentJob={currentJob}
              changeStatus={changeStatus}
              ref={ref}
            />
          )}
          <div onClick={() => onShare()}>
            <i className="icon-share circle share" />
          </div>
        </div>
      </div>
      <CircleUserList
        fromFiles={fromFiles ? fromFiles : false}
        skills={
          currentJob && currentJob.requiredSkills
            ? currentJob.requiredSkills
            : []
        }
        showAddButton={canAssignUsers() ? true : false}
        job={currentJob ? currentJob : null}
        /* ref={(ref) => {
                this.refUsers = ref;
              }} */
        users={
          currentJob.users &&
          currentJob.users.map((user) => {
            if (
              currentJob.confirmedUsers &&
              currentJob.confirmedUsers.filter(
                (u) => u.user.toString() === user._id.toString()
              ).length === 1
            ) {
              return {
                ...user,
                greenMark: true,
              };
            }

            return user;
          })
        }
        includeMe={true}
        onChange={(users) => toggleChangeAssignees(users)}
      />
    </>
  );
});

export default connectWithStore(JobHeader, ["settings", "user"]);
