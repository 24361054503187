import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { getUsersList } from "../../services/api/user";
import Switch from "../switch/switch";
import i18n from "../../i18n";
import "./autoselect.scss";
import { getFileTarget } from "../../services/api/file_upload";
import { isMobileDevice } from "../../services/mobile";
import { connectWithStore } from "../../services/redux";
import SearchButton from "../search_button/search_button";

class AutoSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableDelete: true,
      value: "",
      suggestions: [],
      selectedSuggestions: this.props.selectedSuggestions
        ? this.props.selectedSuggestions
        : [],
      requestUrl: this.props.requestUrl ? this.props.requestUrl : null,
      noSuggestions: false,
      response: this.props.response ? this.props.response : [],
      maxSelection: this.props.maxSelection ? this.props.maxSelection : null,
      newSuggestions: [],
      primaryColor: "",
    };

    if (this.state.selectedSuggestions.length === 0) {
      this.onSuggestionsFetchRequested("");
    }
  }

  componentDidMount() {
    const { branding } = this.props.settings;
    this.setState({
      primaryColor:
        branding &&
        branding.primary &&
        branding.primary.color &&
        branding.primary.color.hex,
    });
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let newResponse = this.props.response;

    newResponse = newResponse.filter((obj) => {
      return !this.state.selectedSuggestions.some((obj2) => {
        return obj.value.toString() === obj2.value.toString();
      });
    });

    return inputLength === 0
      ? []
      : newResponse.filter(
          (res) => res.label.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    return suggestion.label;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => {
    return this.props.searchUser ? (
      <div
        className="Autoselect-user"
        data-avatar={suggestion.avatar}
        data-role={suggestion.role}
        data-label={suggestion.label}
        data-value={suggestion.value}
      >
        <i
          className="mdi mdi-checkbox-blank-circle-outline large"
          onClick={() => this.deleteSelected(suggestion)}
        />
        <div className="Autoselect-user-content">
          <div className="Autoselect-user-avatar">
            {suggestion.avatar ? (
              <img
                src={getFileTarget(suggestion.avatar, "100x100")}
                alt="avatar"
                className={suggestion.inTraining ? "circle trainee" : "circle"}
              />
            ) : (
              <i
                className={
                  suggestion.inTraining
                    ? "circle mdi mdi-account trainee"
                    : "circle mdi mdi-account"
                }
              />
            )}
          </div>

          <div className="Autoselect-user-name">
            <span>{suggestion.label}</span>
            {suggestion.subLabel && (
              <p>
                <small>{suggestion.subLabel}</small>
              </p>
            )}
          </div>
        </div>

        <div className="Autoselect-user-role">
          <span>{suggestion.role}</span>
        </div>
      </div>
    ) : (
      <div
        className="Autoselect-user not-an-user"
        data-label={suggestion.label}
        data-value={suggestion.value}
      >
        <i
          className="mdi mdi-checkbox-blank-circle-outline large"
          onClick={() => this.deleteSelected(suggestion)}
        />
        {suggestion.avatar && (
          <div className="Autoselect-user-avatar">{suggestion.avatar}</div>
        )}
        <div className="Autoselect-user-name">
          <span>{suggestion.label}</span>
          {suggestion.subLabel && (
            <p>
              <small>{suggestion.subLabel}</small>
            </p>
          )}
        </div>
        <div className="Autoselect-user-role" />
        {this.state.requestUrl.hasOwnProperty("delete") &&
          !this.state.disableDelete && (
            <span
              className="delete"
              onClick={() => this.deleteSelected(suggestion, true)}
            >
              {i18n.t("default:_REMOVE")}
            </span>
          )}
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    if (newValue === "") {
      this.renderSuggestionWithSearch();
    }
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason && reason === "suggestion-selected") {
      this.onSuggestionsClearRequested();
      return;
    }
    if (reason && reason === "input-changed") {
      return;
    } else if (
      reason &&
      reason === "input-focused" &&
      this.state.selectedSuggestions.length === 0
    ) {
      return;
    }
    if (this.props.searchCallback) {
      return this.props.searchCallback(value, (transformed) => {
        this.setState({
          suggestions: transformed,
        });
      });
    }
    if (this.state.requestUrl) {
      if (this.props.noInitialRender) {
        return;
      }
      this.searchByRequest(value);
    } else if (this.props.searchUser) {
      this.searchByUser(value);
    } else {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    }
  };

  async searchByRequest(query) {
    const filterParams = {
      search: query,
      page_size: 100,
    };
    let transformed = [];

    const response = await this.state.requestUrl.get(filterParams);

    if (typeof response === "object" && response.hasOwnProperty("docs")) {
      transformed = response.docs.map((item) => {
        const result = {
          value: item.name,
          label: item.name,
          avatar: item.avatar,
        };

        if (this.props.valueFromID) {
          result.value = item._id;
        }

        return result;
      });
    } else {
      transformed = response.map((item) => {
        return {
          value: item._id,
          label: item.name,
          avatar: item.avatar,
          jobTypeId: item.jobTypeId,
        };
      });
    }

    transformed = transformed.filter((obj) => {
      return !this.state.selectedSuggestions.some((obj2) => {
        return obj.value === obj2.value;
      });
    });

    if (!query || query.length === 0) {
      this.setState({
        suggestions: transformed,
      });

      return;
    }

    const inputValue = query.trim().toLowerCase();

    transformed = transformed.filter(
      (res) => res.label.toLowerCase().indexOf(inputValue) !== -1
    );

    this.setState({
      noSuggestions: transformed.length ? false : true,
      suggestions: transformed,
    });
  }

  async searchByUser(query) {
    const usersFilterParams = {
      search: query,
      page_size: 100,
    };
    if (this.props.withRole) {
      usersFilterParams["role"] = this.props.withRole;
    }

    if (this.props.includeMe) {
      usersFilterParams["include_me"] = true;
    }

    if (this.props.excludeRoles) {
      usersFilterParams["excludeRoles"] = this.props.excludeRoles;
    }

    const response = await getUsersList(usersFilterParams);
    let transformed =
      response.docs && response.docs.length > 0
        ? response.docs.map((user) => {
            const hasName = user.name && user.name.first && user.name.last;
            if (hasName) {
              return {
                value: user._id,
                label: user.name.first + " " + user.name.last,
                subLabel: user.email,
                avatar: user.imgUrl,
                role: user.role,
                inTraining: user.inTraining,
              };
            } else {
              return {
                value: user._id,
                label: user.email,
                avatar: user.imgUrl,
                role: user.role,
                inTraining: user.inTraining,
              };
            }
          })
        : [];

    transformed = transformed.filter((obj) => {
      return !this.state.selectedSuggestions.some((obj2) => {
        return obj.value === obj2.value;
      });
    });

    if (!query || query.length === 0) {
      this.setState({
        suggestions: transformed,
      });

      return;
    }

    this.setState({
      suggestions: transformed,
    });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
      //noSuggestions: true,
    });
  };

  renderSuggestionWithSearch() {
    const { value } = this.state;
    if (this.props.searchCallback) {
      return this.props.searchCallback(value, (transformed) => {
        this.setState({
          suggestions: transformed,
        });
      });
    }
    if (this.state.requestUrl) {
      this.searchByRequest(value);
    } else if (this.props.searchUser) {
      this.searchByUser(value);
    } else {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    }
  }

  onSuggestionSelected(e, { suggestion }) {
    if (e.target.classList.contains("delete")) {
      return;
    }
    if (typeof suggestion.value === "undefined") {
      return;
    }
    this.setState(
      {
        selectedSuggestions: [...this.state.selectedSuggestions, suggestion],
        newSuggestions: [...this.state.newSuggestions, suggestion.value],
        value: "",
      },
      () => {
        if (document.querySelector(".react-autosuggest__input")) {
          document.querySelector(".react-autosuggest__input").blur();
        }
      }
    );
    if (this.props.onChange) {
      this.props.onChange([...this.state.selectedSuggestions, suggestion]);
    }
    setTimeout(() => {
      if (this.props.searchCallback) {
        return this.props.searchCallback("", (transformed) => {
          this.setState({
            suggestions: transformed,
          });
        });
      }
    }, 300);
  }

  async deleteSuggestion(selected) {
    await this.state.requestUrl.delete({ id: selected.value });
    this.setState(
      {
        value: "",
      },
      () => this.onSuggestionsClearRequested()
    );
    if (document.querySelector(".react-autosuggest__input")) {
      document.querySelector(".react-autosuggest__input").blur();
    }
  }

  async addSuggestion(value) {
    if (!value || value.length === 0) {
      return;
    }
    await this.state.requestUrl.create({ name: value });

    if (document.querySelector(".react-autosuggest__input")) {
      document.querySelector(".react-autosuggest__input").blur();
      setTimeout(() => {
        document.querySelector(".react-autosuggest__input").focus();
      }, 500);
    }
  }

  deleteSelected({ label, value }, deleteFromServer) {
    if (deleteFromServer) {
      this.deleteSuggestion({ label, value });
    }
    const filtered = this.state.selectedSuggestions.filter(
      (item) => typeof item.value !== "undefined" && item.value !== value
    );
    this.setState({ selectedSuggestions: filtered }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.selectedSuggestions);
        if (this.props.searchCallback) {
          setTimeout(() => {
            this.props.searchCallback("", (transformed) => {
              this.setState({
                suggestions: transformed,
              });
            });
          }, 500);
        }
      }
    });
  }

  toggleDelete() {
    this.setState({
      disableDelete: this.state.disableDelete ? false : true,
    });
  }

  handleKeyPressed(e) {
    if (e.key === "Enter" && this.state.value) {
      this.renderSuggestionWithSearch();
    }
  }

  render() {
    const {
      value,
      suggestions,
      selectedSuggestions,
      noSuggestions,
      maxSelection,
    } = this.state;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.searchUser
        ? i18n.t("default:_TYPE_TO_SEARCH_USER")
        : i18n.t("default:_TYPE_TEXT_SEARCH"),
      value,
      onChange: this.onChange,
    };
    return (
      <div className="Autoselect" style={{ width: isMobileDevice() && "100%" }}>
        {!this.props.searchUser &&
          this.state.requestUrl.hasOwnProperty("delete") && (
            <Switch
              className="float-switch"
              label={i18n.t("default:_DISABLE_DELETE")}
              onChange={() => this.toggleDelete()}
              checked={!this.state.disableDelete}
              labelLeft={true}
            />
          )}
        {(maxSelection === null || maxSelection > selectedSuggestions.length) &&
          !this.props.disableNonTemp && (
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={(e, params) =>
                this.onSuggestionSelected(e, params)
              }
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              renderInputComponent={(props) => {
                return (
                  <div className="input-group">
                    <input
                      {...props}
                      type="text"
                      className="form-control react-autosuggest__input"
                      onKeyPress={this.handleKeyPressed.bind(this)}
                    />
                    {this.state.value && (
                      <div
                        className="input-group-prepend with-search-button"
                        onClick={() => this.renderSuggestionWithSearch()}
                      >
                        <SearchButton primary={this.state.primaryColor} />
                      </div>
                    )}
                  </div>
                );
              }}
              alwaysRenderSuggestions={true}
            />
          )}

        {noSuggestions &&
          (maxSelection === null ||
            maxSelection > selectedSuggestions.length) &&
          this.state.value.length > 0 &&
          !this.props.disableAdd && (
            <div
              className="no-suggestions"
              onClick={() => this.addSuggestion(value)}
            >
              <span>{i18n.t("default:_NO_SUGGESTIONS")}</span>
            </div>
          )}

        <ul className="Autoselect-list">
          {!this.props.noSelection &&
            selectedSuggestions
              .filter((u, index, self) => self.indexOf(u) === index)
              .filter(
                (option) =>
                  typeof option.label !== "undefined" &&
                  typeof option.value !== "undefined"
              )
              .reverse()
              .map((selected, index) => (
                <li
                  key={index}
                  className={
                    this.state.newSuggestions.includes(selected.value)
                      ? "isNew"
                      : ""
                  }
                  style={
                    this.props.listItemColorFn
                      ? {
                          background: this.props.listItemColorFn(selected),
                        }
                      : {}
                  }
                >
                  {this.props.searchUser ? (
                    <div
                      className={
                        selected.isTempAssigned
                          ? "Autoselect-user green"
                          : "Autoselect-user"
                      }
                    >
                      <i
                        className="mdi mdi-check-circle large"
                        onClick={() =>
                          selected.isTempAssigned || !this.props.disableNonTemp
                            ? this.deleteSelected(selected)
                            : null
                        }
                      />
                      <div className="Autoselect-user-content">
                        <div className="Autoselect-user-avatar">
                          {selected.avatar ? (
                            <img
                              src={getFileTarget(selected.avatar, "100x100")}
                              alt="avatar"
                              className={
                                selected.inTraining
                                  ? "circle trainee"
                                  : "circle"
                              }
                            />
                          ) : (
                            <i
                              className={
                                selected.inTraining
                                  ? "circle mdi mdi-account trainee"
                                  : "circle mdi mdi-account"
                              }
                            />
                          )}
                        </div>
                        <div className="Autoselect-user-name">
                          <span>{selected.label}</span>
                          {selected.subLabel && <p>{selected.subLabel}</p>}
                        </div>
                      </div>

                      <div className="Autoselect-user-role">
                        <span>{selected.role}</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="Autoselect-item not-an-user"
                      onClick={() => this.deleteSelected(selected)}
                    >
                      <i className="mdi mdi-check-circle large" />
                      <div className="Autoselect-user-content">
                        <div className="Autoselect-user-avatar">
                          {selected.avatar && selected.avatar}
                        </div>
                        <div className="Autoselect-user-name">
                          <span>{selected.label}</span>
                          {selected.subLabel && <p>{selected.subLabel}</p>}
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              ))}
        </ul>
      </div>
    );
  }
}

export default connectWithStore(AutoSelect, ["settings"]);
