import moment from "moment-timezone";

moment.suppressDeprecationWarnings = true;

class DateService {
  static SYSTEM_DAY_MONTH_YEAR_FORMAT = "DD/MM/YY";
  static SYSTEM_TIME_FORMAT = "HH:mm";
  static SYSTEM_WIDE_FORMAT = "DD/MM/YY, HH:mm";
  static SYSTEM_DAY_MONTH = "DD/MM";

  static humanize(date) {
    return moment(date).fromNow();
  }

  static getFormattedDate(date, inputFormat = null, tz = null) {
    let result;
    if (tz !== null) {
      result = this.toTimezone(date, tz);
    } else {
      result = moment(date, inputFormat);
    }
    if (!result.isValid()) {
      return "";
    }
    return result.format(DateService.SYSTEM_WIDE_FORMAT);
  }

  static getFormattedDateWithoutYear(date, inputFormat = null, tz = null) {
    let result;
    if (tz !== null) {
      result = this.toTimezone(date, tz);
    } else {
      result = moment(date, inputFormat);
    }
    if (!result.isValid()) {
      return "";
    }
    return result.format(DateService.SYSTEM_DAY_MONTH);
  }

  static getFormattedTime(date, inputFormat = null) {
    return moment(date, inputFormat).format(DateService.SYSTEM_TIME_FORMAT);
  }

  static getFormattedDay(date, inputFormat = null) {
    return moment(date, inputFormat).format(
      DateService.SYSTEM_DAY_MONTH_YEAR_FORMAT
    );
  }

  static parseDateExpression(expression, tz = "Asia/Jerusalem") {
    const DATE_VALUES = {
      today: {
        from: moment().tz(tz, false).startOf("day"),
        to: moment().tz(tz, false).endOf("day"),
      },
      tomorrow: {
        from: moment().tz(tz, false).add(1, "day").startOf("day"),
        to: moment().tz(tz, false).add(1, "day").endOf("day"),
      },
      last_week: {
        from: moment().tz(tz, false).subtract(7, "days").startOf("week"),
        to: moment().tz(tz, false).subtract(7, "days").endOf("week"),
      },
      next_week: {
        from: moment().tz(tz, false).add(7, "days").startOf("week"),
        to: moment().tz(tz, false).add(7, "days").endOf("week"),
      },
      last_month: {
        from: moment().tz(tz, false).subtract(1, "month").startOf("month"),
        to: moment().tz(tz, false).subtract(1, "month").endOf("month"),
      },
      this_month: {
        from: moment().tz(tz, false).startOf("month"),
        to: moment().tz(tz, false).endOf("month"),
      },
      next_month: {
        from: moment().tz(tz, false).add(1, "month").startOf("month"),
        to: moment().tz(tz, false).add(1, "month").endOf("month"),
      },
    };
    return DATE_VALUES[expression];
  }

  static getFromOfExpression(expression, tz = "Asia/Jerusalem") {
    const { from } = this.parseDateExpression(expression, tz);
    return DateService.getFormattedDay(from);
  }

  static toTimezone(date, tz) {
    if(typeof tz !== 'string' && typeof tz === 'object' && tz.type) {
      tz = tz.type;
    }
    return moment.utc(date).tz(tz);
  }

  static formattedDateToMoment(date) {
    return date ? moment(date, DateService.SYSTEM_WIDE_FORMAT) : null;
  }

  static formattedDayToMoment(date) {
    return date ? moment(date, DateService.SYSTEM_DAY_MONTH_YEAR_FORMAT) : null;
  }

  static formattedTimeToMoment(date) {
    return date ? moment(date, DateService.SYSTEM_TIME_FORMAT) : null;
  }
}

export default DateService;
