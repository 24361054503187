import React, { Component } from "react";
import i18n from "../../i18n";
import Actionsheet from "react-actionsheet";

class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.actionSheetList = [
      {
        content: i18n.t("default:_WAZE"),
        onClick: this.onShareWaze.bind(this),
      },
      {
        content: i18n.t("default:_MAPS"),
        onClick: this.onShareMaps.bind(this),
      },
    ];
  }

  onRequestClose = () => {
    this.onClick();
  };

  onClick() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  async onShareSamurai() {
    if (navigator.share) {
      try {
        await navigator.share({
          title: this.props.samuraiShare.title,
          text: this.props.samuraiShare.text,
          url: this.props.samuraiShare.link,
        });
      } catch {
        this.props.copy(this.props.samuraiShare.link);
      }
    } else {
      this.props.copy(this.props.samuraiShare.link);
    }
  }

  async onShareWaze() {
    if (navigator.share) {
      try {
        await navigator.share({
          title: this.props.wazeShare.title,
          text: this.props.wazeShare.text,
          url: this.props.wazeShare.link,
        });
      } catch {
        this.props.copy(this.props.wazeShare.link);
      }
    } else {
      this.props.copy(this.props.wazeShare.link);
    }
  }

  async onShareMaps() {
    if (navigator.share) {
      try {
        await navigator.share({
          title: this.props.mapsShare.title,
          text: this.props.mapsShare.text,
          url: this.props.mapsShare.link,
        });
      } catch {
        this.props.copy(this.props.mapsShare.link);
      }
    } else {
      this.props.copy(this.props.mapsShare.link);
    }
  }

  render() {
    const { show } = this.state;
    return (
      <button onClick={() => this.onClick()} className="btn-full btn-gray">
        <i className="mdi mdi-content-copy" />
        {i18n.t("default:_SHARE")}
        {show && (
          <Actionsheet
            show={show}
            menus={this.actionSheetList}
            onRequestClose={this.onRequestClose}
            cancelText={i18n.t("default:_CANCEL")}
            onCancel={this.onRequestClose}
          />
        )}
      </button>
    );
  }
}

export default ShareButton;
