import { isMobileDevice } from "../../services/mobile";
const initialState = { access_token: {}, profile: {} };

const user = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      state.access_token = action.data;
      return Object.assign({}, state);
    case "SET_IMPERSONATE_PROFILE":
      state.profile = Object.assign({}, action.data);
      return Object.assign({}, state);
    case "SET_PROFILE":
      const profile = state.profile;
      let name = profile.email;

      if (profile.name && (profile.name.first || profile.name.last)) {
        name = profile.name.first;
        if (profile.name.first) {
          name += " ";
        }
        name += profile.name.last;
      }

      const device = isMobileDevice() ? "mobile" : "desktop";

      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: `${device}_${state.profile.id}`, // Required if user is logged in
            email: state.profile.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: name, // Recommended if using Pendo Feedback
            role: profile.role, // Optional
            device,
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: window.location.hostname, // Highly recommended
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      }

      state.profile = Object.assign(state.profile, action.data);
      return Object.assign({}, state);
    case "UPDATE_PROFILE":
      state.profile = Object.assign({}, action.data);
      return Object.assign({}, state);
    case "REFRESH_ACCESS_TOKEN":
      state.access_token = Object.assign(state.data.access_token, action.data);
      return Object.assign({}, state);
    case "LOGOUT":
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default user;
