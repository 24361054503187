import React, { Component } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { TAG_TYPE } from "./constants";

const ReactTags = require("react-tag-autocomplete");

const SortableContainer = sortableContainer(({ children }) => {
  return <>{children}</>;
});

class ReportTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.tag.type || TAG_TYPE.FIELD,
      name: props.tag.name || null,
      field: props.tag.field || null,
      table: props.tag.table || null,
      id: props.tag.id || null,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  static getDerivedStateFromProps(nextPros, state) {
    const newState = {
      type: nextPros.tag.type || TAG_TYPE.FIELD,
      name: nextPros.tag.name || null,
      field: nextPros.tag.field || null,
      table: nextPros.tag.table || null,
      id: nextPros.tag.id || null,
    };
    if (JSON.stringify(newState) !== JSON.stringify(state)) {
      return newState;
    }
    return null;
  }

  render() {
    const { table, name, id } = this.state;

    return (
      <div
        className={table ? `reportTag ${table}` : `reportTag`}
        style={{ zIndex: 9999 }}
      >
        <span>{name}</span>
        <span
          className="tagDeleteButton"
          onClick={() => this.props.onDelete(id)}
        >
          &#10006;
        </span>
      </div>
    );
  }
}

const DragHandle = sortableHandle((props) => {
  return <ReportTag {...props} />;
});

const SortableItem = sortableElement((props) => {
  return <DragHandle {...props} />;
});

const ReportTagWrapper = (props) => {
  const tagPosition = props.tag.order ? props.tag.order : 0;
  return <SortableItem {...props} index={tagPosition} />;
};

function ReportTags({
  onSortEnd,
  columns,
  suggestions,
  suggestionsFilter,
  handleDelete,
  handleAddition,
}) {
  return (
    <SortableContainer
      onSortEnd={onSortEnd}
      useDragHandle
      axis="xy"
      distance={10}
    >
      <ReactTags
        inputAttributes={{
          className: "form-control",
        }}
        minQueryLength={0}
        allowNew={false}
        tags={columns}
        suggestions={suggestions.filter(
          (sugg) =>
            columns.indexOf(sugg) === -1 || sugg.type === TAG_TYPE.CONDITIONAL
        )}
        suggestionsFilter={suggestionsFilter}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        tagComponent={ReportTagWrapper}
        maxSuggestionsLength={suggestions.length}
      />
    </SortableContainer>
  );
}

export default ReportTags;
