import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stage } from "react-konva";
import { BlueprintEditor } from "../../../redux/actions";
import { Layer } from "konva";
import { isMobileDevice } from "../../../services/mobile";
import { connectWithStore } from "../../../services/redux";
import { CONSTRUCTOR_NAME } from "./constants";
import { removeElements } from "./utils";

const TOP_TOOLBAR_HEIGHT = 55;
const SIDEBAR_WIDTH = 50;
const MOBILE_WIDTH_OFFSET = 30;

function BlueprintEditorCanvas({ blueprintEditor, dispatch }) {
  const [stageDimensions, setStageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const { pdfInfo } = blueprintEditor;
  const { size } = pdfInfo;
  const stage = useRef(null);
  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const stageHeight =
      height -
      /* HEADER_HEIGHT -  */ TOP_TOOLBAR_HEIGHT; /* - BOTTOM_TOOLBAR_HEIGHT */
    const stageWidth = isMobileDevice()
      ? width - MOBILE_WIDTH_OFFSET
      : width - SIDEBAR_WIDTH;
    setStageDimensions({
      width: stageWidth,
      height: stageHeight,
    });
    if (stage.current) {
      const layersCount = stage.current.getLayers().length;
      dispatch(BlueprintEditor.setApp(stage.current));
      if (layersCount === 0) {
        const layer = new Layer({ name: "Layer 1" });
        layer.zIndex = 1;
        stage.current.add(layer);
        dispatch(BlueprintEditor.setCurrentLayer(layer));
      }
      dispatch(BlueprintEditor.setCurrentLayer(stage.current.getLayers()[0]));
    }
  }, [stage, dispatch]);

  const updateCanvasSize = useCallback(() => {
    setStageDimensions({
      width: size.width,
      height: size.height,
    });
  }, [size]);

  useEffect(() => {
    if (size) {
      updateCanvasSize();
    }
  }, [size, updateCanvasSize]);

  return (
    <Stage
      ref={(ref) => (stage.current = ref)}
      width={stageDimensions.width}
      height={stageDimensions.height}
      x={0}
      y={0}
      scale={{ x: 1, y: 1 }}
      style={{
        marginTop: 7,
        height: "92%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        background: "#fff",
      }}
      draggable={false}
      onClick={() => removeElements(CONSTRUCTOR_NAME.LABEL)}
    />
  );
}

export default connectWithStore(BlueprintEditorCanvas, ["blueprintEditor"]);
