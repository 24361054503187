import React, { Component } from "react";
import i18n from "../../i18n";
import Actionsheet from "react-actionsheet";

class NavigateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.actionSheetList = [
      {
        content: i18n.t("default:_WAZE"),
        onClick: this.onNavigateViaWaze.bind(this),
      },
      {
        content: i18n.t("default:_MAPS"),
        onClick: this.onNavigateViaMaps.bind(this),
      },
    ];
  }

  onRequestClose = () => {
    this.onClick();
  };

  onClick() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  async onNavigateViaWaze() {
    const { wazeUrl } = this.props;
    window.open(wazeUrl, "_blank");
  }

  async onNavigateViaMaps() {
    const { mapsUrl } = this.props;
    window.open(mapsUrl, "_blank");
  }

  render() {
    const { show } = this.state;
    return (
      <button onClick={() => this.onClick()} className="btn-full btn-red">
        <i className="mdi mdi-navigation" />
        {i18n.t("default:_NAVIGATE")}
        {show && (
          <Actionsheet
            show={show}
            menus={this.actionSheetList}
            onRequestClose={this.onRequestClose}
            cancelText={i18n.t("default:_CANCEL")}
            onCancel={this.onRequestClose}
          />
        )}
      </button>
    );
  }
}

export default NavigateButton;
