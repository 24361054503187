import React, { Component } from "react";
import "./confirmation_button.scss";
// import i18n from '../../i18n';

export default class ConfirmationButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.isActive || false,
      oneWay: this.props.oneWay || true,
      activeText: this.props.activeText || "Confirm",
      inactiveText: this.props.inactiveText || "Cancel",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isActive !== this.state.active) {
      this.setState({
        active: this.props.isActive,
      });
    }
  }

  onClick() {
    if (this.state.oneWay && this.state.active === true) {
      return false;
    }

    if (this.state.active === false && this.props.onConfirm) {
      this.props.onConfirm();
    }

    if (this.state.active === true && this.props.onReject) {
      this.props.onReject();
    }

    this.setState({
      active: !this.state.action,
    });
  }

  render() {
    return (
      <button
        onClick={() => this.onClick()}
        className={`ConfirmationButton ${this.state.active ? "active" : ""}`}
        data-tip={
          this.state.oneWay &&
          this.state.active === true &&
          this.props.inactiveTooltip
            ? this.props.inactiveTooltip
            : ""
        }
      >
        {!this.state.active ? this.state.activeText : this.state.inactiveText}{" "}
        <i className="mdi mdi-checkbox-marked-circle" />
      </button>
    );
  }
}
