import React from "react";
import { connectWithStore } from "../../services/redux";

function PaginationInfo({ perPage, total, page, settings }) {
  const seeing = perPage * (page + 1);
  const current = seeing > total ? total : seeing;
  return (
    <span
      style={
        settings.branding &&
        settings.branding.primary &&
        settings.branding.primary.color
          ? {
              color: settings.branding.primary.color.hex,
            }
          : {}
      }
      className="results-info"
    >
      {current} | {total}
    </span>
  );
}

export default connectWithStore(PaginationInfo, ["settings"]);
