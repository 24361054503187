import List from "./list";

export default class ProjectList extends List {
  getChilds() {
    const type = this.props.question.settings.projectListType || null;
    let result = [];
    if (!Array.isArray(this.props.project)) {
      if (this.props.project[type]) {
        result = this.props.project[type].map((m) => m.label);
      }
    }
    if (Array.isArray(this.props.project)) {
      this.props.project.forEach((project) => {
        if (project && project[type]) {
          const allValues = result.concat(project[type].map((m) => m.label));
          //filter out duplicates
          result = [...new Set(allValues)];
        }
      });
    }
    return result;
  }
}
