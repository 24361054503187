import React, { useCallback, useEffect, useState } from "react";
import { connectWithStore } from "../../services/redux";
import SearchButton from "../search_button/search_button";

function ListWizard({
  items,
  selectionType,
  settings,
  onSelectionChange,
  getAvatarCircle,
  isMultiSelect,
  selectedProjects,
  withoutSearch,
  selected,
}) {
  const [searchWord, setSearchWord] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [renderedItems, setRenderedItems] = useState([]);
  const primary =
    settings &&
    settings.branding &&
    settings.branding.primary &&
    settings.branding.primary.color &&
    settings.branding.primary.color.hex;

  const setInitialRenderedItems = useCallback(() => {
    setRenderedItems(items);
  }, [items]);

  const setInitialSelection = useCallback(() => {
    if (isMultiSelect) {
      if (selectedProjects) {
        setActiveItem(selectedProjects);
      } else {
        setActiveItem([]);
      }
    } else {
      if (selected) setActiveItem(selected);
    }
  }, [isMultiSelect, selectedProjects, selected]);

  useEffect(() => {
    setInitialRenderedItems();
    setInitialSelection();
  }, [setInitialRenderedItems, setInitialSelection]);

  const search = (keyword) => {
    if (keyword === "") {
      setInitialRenderedItems();
    }
    setSearchWord(keyword.length > 0 ? keyword : "");
  };

  const handleKeyPressed = (e) => {
    if (e.key === "Enter" && searchWord && searchWord.length > 0) {
      filterItems();
    }
  };

  const renderSearch = () => {
    if (withoutSearch) {
      return null;
    } else {
      return (
        <li
          className={"ListComponent-Search"}
          style={{ width: "95%", minHeight: "54px" }}
        >
          <div className="input-group">
            <input
              onChange={(e) => search(e.target.value)}
              onKeyPress={handleKeyPressed}
              type="text"
              placeholder="type here"
              value={searchWord}
              style={{ color: primary }}
            />
            {searchWord && searchWord.length > 0 && (
              <div className="input-group-append" onClick={() => filterItems()}>
                <SearchButton primary={primary} />
              </div>
            )}
          </div>
        </li>
      );
    }
  };

  const searchFilter = (item) => {
    const inputValue = searchWord.trim().toLowerCase();

    if (searchWord.length > 0) {
      return item.title
        ? item.title.toLowerCase().indexOf(inputValue) !== -1
        : item.name.toLowerCase().indexOf(inputValue) !== -1;
    }
    return true;
  };

  const filterItems = () => {
    const currentItems = [...items].filter(searchFilter);
    setRenderedItems(currentItems);
  };

  const isSelectedItem = (option) => {
    if (isMultiSelect) {
      const actives = [...activeItem];
      return actives
        .map((a) => JSON.stringify(a))
        .includes(JSON.stringify(option));
    }
    if (!activeItem && !selectionType) {
      return false;
    } else if (activeItem) {
      if (activeItem._id) {
        return activeItem._id === option._id;
      } else {
        return activeItem === option;
      }
    } else {
      return selectionType._id === option._id;
    }
  };

  const selectValue = (value) => {
    if (isMultiSelect) {
      const index = activeItem
        .map((a) => JSON.stringify(a))
        .indexOf(JSON.stringify(value));
      const actives = [...activeItem];
      if (index === -1) {
        actives.push(value);
      } else {
        actives.splice(index, 1);
      }
      onSelectionChange(actives);
      return setActiveItem(actives);
    }
    let nextValue = value;
    if (activeItem === nextValue) {
      nextValue = null;
    }
    setActiveItem(nextValue);
    onSelectionChange(nextValue);
  };

  const renderItem = (item, index, arr, viewOnly = true) => {
    const isSelected = isSelectedItem(item);
    const liHeight = arr && arr.length < 3 ? "88px" : "54px";
    let liBackgroundColor = isSelected && primary ? primary : null;
    const liColor = isSelected && primary ? "white" : null;
    const liBorder = primary ? `1px solid ${primary}` : null;

    return (
      <li
        className={
          isSelected
            ? "ListComponent-Item ListComponent-Selected"
            : "ListComponent-Item"
        }
        style={{
          backgroundColor: liBackgroundColor,
          color: liColor,
          border: liBorder,
          minHeight: liHeight,
          cursor: "pointer",
          width: "95%",
        }}
        key={index}
        onClick={() => selectValue(item)}
        dir="auto"
      >
        {isSelected && (
          <div className="ListComponent-Checked">
            <i className="mdi mdi-check" />
          </div>
        )}
        {getAvatarCircle && (
          <div className="ListComponent-Avatar ListComponentAvatar-Wizard">
            <div className="ListComponent-AvatarCircle">
              {getAvatarCircle(item)}
            </div>
          </div>
        )}
        <div className="ListComponent-Value" dir="auto">
          {item.title ? item.title : item.name ? item.name : item}
        </div>
      </li>
    );
  };
  return (
    <div className="ListComponent ListWizard">
      <ul className="ListOptions">
        {renderSearch()}
        {renderedItems &&
          renderedItems
            //.filter(searchFilter)
            .map((item, i, arr) => renderItem(item, i, arr))}
      </ul>
    </div>
  );
}

export default connectWithStore(ListWizard, ["settings"]);
