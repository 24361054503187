import React, { Component } from "react";
import LoadingBar from "react-redux-loading-bar";

export default class Loader extends Component {
  render() {
    return (
      <LoadingBar
        showFastActions
        style={{
          backgroundColor: "red",
          height: "3px",
          zIndex: 999999999,
        }}
        store={this.props.store}
      />
    );
  }
}
