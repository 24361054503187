import React, { useState, useEffect, useCallback } from "react";
import i18n from "../../i18n";
import { getRolesWithLabels, ROLE } from "../../services/roles";
import { createUser } from "../../services/api/user";
import { connectWithStore } from "../../services/redux";
import {
  STEP_CREATE_NEW,
  STEP_SELECT_COMPANIES,
  STEP_SELECT_USER_SKILLS,
} from "./steps";
import WizardButtons from "./wizard_buttons";
import { WIZARD_TYPE_USER } from "./constants";
import { Wizard, WizardUser } from "../../redux/actions";
import { isMobileDevice } from "../../services/mobile";
import ToggleService from "../../services/toggle";
import { APP_CLIENT } from "../../services/app";
import Alert from "../alert/alert";

const ROLES = getRolesWithLabels();

function CreateNewUser({
  user,
  dismiss,
  goToStep,
  dispatch,
  isActive,
  settings,
  apps,
}) {
  const [errorEmail, setErrorEmail] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [role, setRole] = useState(ROLES[3]);
  const [email, setEmail] = useState("");
  const [shouldLoadSkillsTab, setShouldLoadSkillsTab] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertLabel, setAlertLabel] = useState("");

  const userRole = user.profile.role;

  const checkSkillsToggle = useCallback(async () => {
    const response = await ToggleService._isToggleActive(
      "skills",
      settings.toggles
    );
    setShouldLoadSkillsTab(response);
  }, [settings]);

  const onRoleChange = (value) => {
    let dispatchValue = { role: value };
    dispatch(WizardUser.setRole(dispatchValue));
    setRole(value);
  };

  const validateEmail = (value) => {
    var regex = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = regex.test(String(value).toLowerCase());
    let error = false;
    if (!isValidEmail) {
      error = true;
    }
    setErrorEmail(error);
    let dispatchValue = { email: value };
    dispatch(WizardUser.setEmail(dispatchValue));
    setEmail(value);
    checkUserRegister(error);
  };

  const checkUserRegister = (error) => {
    let disabled = true;
    if (!error) {
      disabled = false;
    }
    setSubmitDisabled(disabled);
  };

  const closeAlert = () => {
    setShowAlert(false);
    setAlertLabel("");
    setSubmitDisabled(false);
  };

  const registerUser = async () => {
    let data = {
      email: email.toLowerCase(),
      role: role.value,
    };
    const response = await createUser(data);
    if (response.success) {
      dispatch(Wizard.setType(WIZARD_TYPE_USER));
      dispatch(WizardUser.setId(response.data));
    } else {
      const errMessage =
        response.data && !response.data.success && response.data.data
          ? response.data.data
          : "Could not register user!";
      setShowAlert(true);
      setAlertLabel(errMessage);
      setSubmitDisabled(true);
    }
    return response.success;
  };

  const onProceed = async () => {
    const success = await registerUser();
    if (!success) {
      return;
    }
    if (shouldLoadSkillsTab) {
      goToStep(STEP_SELECT_USER_SKILLS);
    } else {
      dismiss();
    }
  };

  const isChecked = (val) => {
    return role && val && role.value && val.value && role.value === val.value
      ? true
      : false;
  };

  const roles = ROLES.filter((role) => {
    if (userRole === ROLE.SYSTEM_ADMIN) {
      return true;
    } else if (userRole === ROLE.BUSINESS_ADMIN) {
      return (
        [ROLE.INSPECTOR, ROLE.PROJECT_MANAGER, ROLE.CLIENT].indexOf(
          role.value
        ) > -1
      );
    } else if (userRole === ROLE.PROJECT_MANAGER) {
      return [ROLE.INSPECTOR, ROLE.CLIENT].indexOf(role.value) > -1;
    }

    return false;
  });

  const isClient = useCallback(() => {
    return role.value === ROLE.CLIENT;
  }, [role]);

  useEffect(() => {
    checkSkillsToggle();
  }, [checkSkillsToggle]);

  useEffect(() => {
    if (isActive)
      dispatch(Wizard.setTitle(i18n.t("default:_WHAT_WOULD_LIKE_TO_CREATE")));
  }, [dispatch, isActive]);

  return (
    <div
      className="UserModal ModalBody"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showAlert && (
        <Alert
          success={false}
          label={alertLabel}
          closeAlert={() => closeAlert()}
        ></Alert>
      )}
      <div
        className="new-user"
        style={{
          flex: 10,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div className="form-row">
          <div className="form-group floating-label col-md-12">
            <label>{i18n.t("default:_EMAIL")}</label>
            <input
              required
              type="email"
              value={email}
              onChange={({ target }) => validateEmail(target.value)}
              className={
                errorEmail ? "form-control is-invalid" : "form-control"
              }
              placeholder={i18n.t("default:_EMAIL")}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: isMobileDevice() ? "center" : "space-between",
              alignItems: "center",
              padding: 10,
              width: "100%",
              flexDirection: isMobileDevice() ? "column" : "row",
            }}
          >
            {roles.map((role) => {
              if (
                role.value === ROLE.CLIENT &&
                !apps.enabled.includes(APP_CLIENT)
              ) {
                return null;
              }
              return (
                <div
                  key={role.value}
                  className={
                    isChecked(role)
                      ? "CustomCheckbox checked"
                      : "CustomCheckbox"
                  }
                  onClick={() => onRoleChange(role)}
                >
                  <label className="ControlLabel" htmlFor={role.value}>
                    {role.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_CREATE_NEW),
            variant: "white",
          },
          shouldLoadSkillsTab &&
            !isClient() && {
              label: i18n.t("default:_NEXT"),
              onClick: () => onProceed(),
              variant: "black",
              disabled: submitDisabled,
            },
          isClient() && {
            label: i18n.t("default:_NEXT"),
            onClick: () => goToStep(STEP_SELECT_COMPANIES),
            variant: "black",
            disabled: submitDisabled,
          },
          !shouldLoadSkillsTab && {
            label: i18n.t("default:_FINISH"),
            onClick: () => onProceed(),
            variant: "black",
            disabled: submitDisabled,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(CreateNewUser, ["user", "settings", "apps"]);
