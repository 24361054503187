import React from "react";
import CircleUserList from "../../../components/circle_user_list/circle_user_list";
import { connectWithStore } from "../../../services/redux";
import { canManageRole } from "../../../services/roles";
import { ROLE } from "../../../services/roles";
import UserFieldWrapper from "./field_wrapper";

function UserSelectionField({ field, value, user, changeUserProp }) {
  return (
    <UserFieldWrapper
      label={field.name}
      withPointer={true}
      //onFieldClick={() => openModal()}
    >
      <CircleUserList
        users={value ? value : []}
        includeMe={true}
        onChange={(users) => changeUserProp(field.value, users)}
        showAddButton={canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN)}
      />
    </UserFieldWrapper>
  );
}

export default connectWithStore(UserSelectionField, ["user"]);
