import React, { useState, useCallback, useEffect } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { getPolygonPoints, getLinearLength, getAreaFromCoords } from "./utils";
import { SHAPE } from "./constants";
import { connectWithStore } from "../../../services/redux";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: "90%",
    marginLeft: "5%",
  },
  polygonSizeContainer: {
    marginLeft: "3%",
  },
  polygonSizes: {
    fontSize: "0.85em",
  },
}));

function PolygonSizeInfo({ element, type, blueprintEditor }) {
  const classes = useStyles();
  const [linearLength, setLinerLength] = useState(null);
  const [area, setArea] = useState(null);
  const { history } = blueprintEditor;

  const getPolygonLength = useCallback(() => {
    const points = getPolygonPoints(element.id);
    const length = getLinearLength(points);
    setLinerLength(length);
  }, [element]);

  const getPolygonArea = useCallback(() => {
    const points = getPolygonPoints(element.id);
    const polygonArea = getAreaFromCoords(points);
    setArea(polygonArea);
  }, [element]);

  useEffect(() => {
    getPolygonLength();
    getPolygonArea();
  }, [getPolygonLength, getPolygonArea, history]);

  return (
    <Grid container className={classes.inputContainer}>
      <Grid container item className={classes.polygonSizeContainer}>
        <Typography color="primary" className={classes.polygonSizes}>
          Linear length: &nbsp;
        </Typography>
        <Typography
          className={classes.polygonSizes}
        >{`${linearLength} m`}</Typography>
      </Grid>
      {type !== SHAPE.POLYGON_OPEN && (
        <Grid container item className={classes.polygonSizeContainer}>
          <Typography color="primary" className={classes.polygonSizes}>
            Area: &nbsp;
          </Typography>
          <Typography
            className={classes.polygonSizes}
          >{`${area} m`}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default connectWithStore(PolygonSizeInfo, ["blueprintEditor"]);
