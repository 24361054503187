import React, { Component } from "react";

export default class Marker extends Component {
  render() {
    return (
      <img
        style={{ objectFit: "contain", width: 25, height: 25 }}
        src="/assets/img/marker_2-512.png"
        alt="Marker"
      />
    );
  }
}
