import React, { useCallback, useEffect, useState } from "react";
import i18n from "../../i18n";
import { Wizard } from "../../redux/actions";
import { connectWithStore } from "../../services/redux";
import {
  getTemplatesList,
  createFromTemplate,
} from "../../services/api/templates";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { isMobileDevice } from "../../services/mobile";
import { getFileTarget } from "../../services/api/file_upload";
import { STEP_FINAL } from "./steps";

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
    width: (props) => (props.isMobileDevice() ? "92%" : "25%"),
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
    margin: "4%",
    position: "relative",
  },
  iconContainer: {
    height: 120,
  },
  divider: {
    margin: "15px 0",
    background: theme.palette.primary.main,
  },
  jobTypeIcon: {
    width: "70px",
    height: "70px",
    background: "white",
  },
  questionsIcon: {
    width: "25px",
    height: "25px",
    background: theme.palette.primary.main,
    marginRight: "10px",
  },
  questionsContainer: {
    position: "position",
    bottom: 0,
  },
}));

function SelectTemplate({ dispatch, isActive, goToStep, setJobTypeId }) {
  const classes = useStyles({ isMobileDevice });
  const [templates, setTemplates] = useState([]);
  const getTemplates = useCallback(async () => {
    const params = {
      instance: "jobtype",
      show_generic: true,
    };
    const response = await getTemplatesList(params);
    if (response.success) {
      setTemplates(response.data);
    }
  }, []);

  const createFromJobTypeTemplate = async (template) => {
    const params = {
      id: template.uniqueId,
      instance: "jobtype",
    };
    const response = await createFromTemplate(params);
    const newJobTypeId = response._id;
    setJobTypeId(newJobTypeId);
    goToStep(STEP_FINAL);
  };

  useEffect(() => {
    getTemplates();
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_SELECT_TEMPLATE")));
  }, [dispatch, isActive, getTemplates]);

  return (
    <div
      className="ProjectModal ModalBody"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container>
        {templates &&
          templates.map((template, index) => {
            return (
              <Card key={index} className={classes.root}>
                <CardActionArea
                  onClick={() => createFromJobTypeTemplate(template)}
                >
                  <CardContent>
                    <Grid
                      container
                      className={classes.iconContainer}
                      alignItems="center"
                      justify="center"
                    >
                      {template.icon ? (
                        <Avatar
                          alt="icon"
                          src={getFileTarget(template.icon, "100x100")}
                        />
                      ) : (
                        <Avatar className={classes.jobTypeIcon}>
                          <i
                            style={{ fontSize: "40px", color: "darkgray" }}
                            className="icon-case_v2"
                          ></i>
                        </Avatar>
                      )}
                    </Grid>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      align="center"
                    >
                      {template.title}
                    </Typography>
                    <Typography
                      style={{ minHeight: "75px" }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      align="center"
                    >
                      {template.settings.description}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      className={classes.questionsContainer}
                    >
                      <Avatar className={classes.questionsIcon}>
                        {template.questions ? template.questions.length : 0}
                      </Avatar>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        align="center"
                      >
                        {i18n.t("default:_QUESTIONS")}
                      </Typography>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
      </Grid>
    </div>
  );
}

export default connectWithStore(SelectTemplate, ["settings"]);
