import React from "react";
import { connectWithStore } from "../../../services/redux";
import { canManageRole, ROLE } from "../../../services/roles";
import UserFieldWrapper from "./field_wrapper";
import MuiSwitch from "../../../components/switch/mui_switch";
import FieldWithFiles from "./field_with_files";

function UserSwitchField({ field, user, value, changeUserProp }) {
  let currentValue = value ? { ...value } : { value: false, files: [] };

  const onSwitchToggle = (val) => {
    currentValue.value = val;
    changeUserProp(field.value, currentValue);
  };

  const path = window.location.pathname;
  const isProfilePage = path.includes("profile");

  return (
    <FieldWithFiles
      field={field}
      value={value}
      changeUserProp={changeUserProp}
      hideField={!currentValue.value || isProfilePage}
    >
      <UserFieldWrapper label={field.name}>
        <MuiSwitch
          onToggle={(e) => onSwitchToggle(e.target.checked)}
          checked={currentValue.value}
          disabled={!canManageRole(user.profile.role, ROLE.BUSINESS_ADMIN)}
        />
      </UserFieldWrapper>
    </FieldWithFiles>
  );
}

export default connectWithStore(UserSwitchField, ["user"]);
