import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import i18n from "../../../i18n";
import { Box, Button } from "@material-ui/core";
import { QRScanner } from "../../qrreader/component";
import { isMobileDevice } from "../../../services/mobile";
import stringScore from "string-score";

const useStyles = (theme) => ({
  container: {
    width: isMobileDevice() ? "95%" : "80%",
    overflowX: isMobileDevice() ? "auto" : "unset",
  },
  table: {
    minWidth: 600,
  },
  row: {
    cursor: "pointer",
  },
  selectedRow: {
    background: theme.palette.primary.main,
    color: "white",
  },
  tableData: {
    color: "inherit",
  },
});

class ProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectField: props.question.settings.projectTableType,
      columnHeaders:
        props.question.settings.filter &&
        props.question.settings.filter.map((item) => item.label),
      table: [],
      activeItem: this.isMultiSelect() ? [] : null,
      searchWord: "",
      ID: props.question.ID,
      showQrReader: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    let cmpData = this.props.answers[this.props.question.ID];
    if (!cmpData) {
      return;
    }
    if (
      JSON.stringify(prevState.activeItem) ===
      JSON.stringify(this.props.answers[this.props.question.ID]["value"])
    ) {
      return;
    }
  }

  componentDidMount() {
    let cmpData = this.props.answers[this.props.question.ID];
    const { projectField } = this.state;
    const currentTable = this.props.project && this.props.project[projectField];

    if (cmpData && this.props.answers[this.props.question.ID]["value"]) {
      this.setState({
        activeItem: this.isMultiSelect()
          ? [...this.props.answers[this.props.question.ID]["value"]]
          : this.props.answers[this.props.question.ID]["value"],
        table: currentTable,
      });
    } else {
      this.setState({
        table: currentTable,
      });
    }

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
  }

  isMultiSelect() {
    return this.props.question.settings.multiSelect || false;
  }

  isSelected(option, rowInd) {
    const { activeItem } = this.state;
    if (!activeItem) {
      return false;
    }
    if (Array.isArray(activeItem) && activeItem.length === 0) {
      return false;
    }

    if (this.isMultiSelect()) {
      const indexesArr = activeItem.map((item) => item.rowIndex);
      return indexesArr.indexOf(rowInd) !== -1;
    } else {
      return activeItem.rowIndex === rowInd;
      /* if (typeof activeItem == "string") {
            if (typeof option === "string") {
              return activeItem === option;
            } else {
              return activeItem === option[0];
            }
          } else {
            return activeItem.value === option.value;
          } */
    }
  }

  selectValue(value, rowIndex) {
    if (this.isMultiSelect()) {
      this.handleMultiSelect(value, rowIndex);
    } else {
      this.handleSelect(value, rowIndex);
    }
  }

  handleMultiSelect(value, rowInd) {
    const restructuredValue = { ...value };
    restructuredValue.rowIndex = rowInd;
    let { activeItem } = this.state;
    if (!activeItem) {
      return;
    }

    if (!Array.isArray(activeItem) && typeof activeItem === "string") {
      activeItem = [activeItem];
    }
    const indexesArr = activeItem.map((item) => item.rowIndex);

    const index = indexesArr.indexOf(rowInd);
    if (index !== -1) {
      activeItem.splice(index, 1);
    } else {
      activeItem.push(restructuredValue);
    }
    this.setState(
      {
        activeItem,
      },
      () => {
        this.props.handleChange(activeItem);
      }
    );
  }

  handleSelect(value, rowInd) {
    const restructuredValue = { ...value };
    restructuredValue.rowIndex = rowInd;
    if (Array.isArray(value) && value.length > 0) {
      value = value[0];
    }
    const { activeItem } = this.state;
    let nextValue = restructuredValue;
    if (activeItem && activeItem.rowIndex === rowInd) {
      nextValue = null;
    }
    this.setState(
      {
        activeItem: nextValue,
      },
      () => this.props.handleChange(nextValue)
    );
  }

  renderCells = (row, y) => {
    const { classes } = this.props;
    let result = [];
    for (
      let headerIndex = 0;
      headerIndex < this.state.columnHeaders.length;
      headerIndex++
    ) {
      const header = this.state.columnHeaders[headerIndex];
      result.push(row[header]);
    }
    return result.map((value, ind) => (
      <TableCell className={classes.tableData} key={ind}>
        {value}
      </TableCell>
    ));
  };

  renderQrReader() {
    return (
      <div style={{ maxWidth: 310, maxHeight: 310, minHeight: 310 }}>
        <p>{i18n.t("default:_SCAN_QR_HELP_TEXT")}</p>
        <QRScanner
          visible={this.showQrReader}
          onScan={(res) => this.handleScan(res)}
        />
        <Box css={{ marginTop: 10 }}>
          <Button
            onClick={() => this.setState({ showQrReader: false })}
            variant="contained"
            color="secondary"
          >
            {i18n.t("default:_CANCEL")}
          </Button>
        </Box>
      </div>
    );
  }

  handleError() {
    this.setState(
      {
        showQrReader: false,
      },
      () => {
        alert(i18n.t("default:_FAILED_TO_READ_QR_CODE"));
      }
    );
  }

  handleScan(data) {
    if (!data) {
      return;
    }
    const index = this.state.table
      .map((r) => {
        delete r[""];
        delete r["QR"];
        return btoa(unescape(encodeURIComponent(JSON.stringify(r))));
      })
      .indexOf(data);
    if (index !== -1) {
      this.setState(
        {
          showQrReader: false,
        },
        () => {
          if (
            !this.state.activeItem ||
            this.state.activeItem.rowIndex !== index
          )
            this.selectValue(this.state.table[index], index);
        }
      );
    }
  }

  searchFilter(item) {
    const { searchWord } = this.state;
    if (this.hasSearch()) {
      if (this.isClosed() && searchWord.length < 3) {
        return false;
      }
      if (searchWord.length > 0 && item !== null) {
        const itemString = Object.values(item).join(", ").toLowerCase();
        const score =
          stringScore(itemString, searchWord.toLocaleLowerCase()) > 0.5;
        return score;
      }
    }
    return true;
  }

  hasSearch() {
    return this.props.question.settings.type === 1 || this.isClosed();
  }

  search(keyword) {
    this.setState({
      searchWord: keyword.length > 0 ? keyword : "",
    });
  }

  renderSearch() {
    return (
      <li className={"ListComponent-Search"}>
        <div className="input-group">
          <input
            onChange={(e) => this.search(e.target.value)}
            type="text"
            placeholder="type here"
            value={this.state.searchWord}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="mdi mdi-magnify" />
            </span>
          </div>
        </div>
      </li>
    );
  }

  isClosed() {
    return this.props.question.settings.type === 2;
  }

  render() {
    const { classes } = this.props;
    const { columnHeaders, table } = this.state;
    if (this.state.showQrReader) {
      return this.renderQrReader();
    }

    const tableToRender = Array.isArray(table)
      ? table.filter((row) => this.searchFilter(row))
      : [];
    return table ? (
      <>
        <div>
          {this.props.question.settings.qrReader && (
            <Box css={{ marginBottom: 10 }}>
              <Button
                onClick={() => this.setState({ showQrReader: true })}
                variant="text"
                color="secondary"
              >
                {i18n.t("default:_GOT_A_QR_CODE")}
              </Button>
            </Box>
          )}
        </div>
        <TableContainer component={Paper} className={classes.container}>
          {this.hasSearch() && this.renderSearch()}
          <Table
            className={classes.table}
            /* style={{minWidth: "600px"}} */ aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {columnHeaders &&
                  columnHeaders.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableToRender.map((row, i) => (
                <TableRow
                  className={`${classes.row} ${
                    this.isSelected(row, i) && classes.selectedRow
                  }`}
                  onClick={() => this.selectValue(row, i)}
                  key={i}
                >
                  {this.renderCells(row, i)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <div>{"No results found"}</div>
    );
  }
}

export default withStyles(useStyles)(ProjectTable);
