import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { getFileTarget } from "../../../services/api/file_upload";

const useStyles = makeStyles({
  menuIcon: {
    fontSize: "25px",
  },
  menuText: {
    fontSize: "0.75rem",
    textTransform: "uppercase",
    textAlign: "center",
  },
  listItem: {
    color: "#9b9b9b;",
    height: "100%",
    flexDirection: "column",
    padding: "5px 4px 0 4px",
  },
  selected: {
    background: "rgba(46,91,255,.1)",
  },
  muiIcon: {
    justifyContent: "center",
  },
  addRow: {
    color: "white",
    background: "#0083c9",
    minHeight: 60,
    "&:hover": {
      color: "#0083c9",
    },
  },
  addRowIcon: {
    color: "white",
  },
  addRowIconHovered: {
    color: "#0083c9",
  },
  text: {
    marginTop: 0,
  },
});

function MenuItem({
  icon,
  avatar,
  label,
  selected,
  onMenuItemClick,
  muiIcon,
  addRow,
  secondaryLabel,
}) {
  const classes = useStyles();
  const [addRowHovered, setAddRowHovered] = useState(false);

  return (
    <ListItem
      button
      className={`${classes.listItem} ${selected ? classes.selected : ""} ${
        addRow ? classes.addRow : ""
      }`}
      onClick={onMenuItemClick}
      onMouseEnter={() => setAddRowHovered(true)}
      onMouseLeave={() => setAddRowHovered(false)}
    >
      {!avatar && muiIcon && (
        <ListItemIcon
          className={`${classes.muiIcon} ${addRow ? classes.addRowIcon : ""} ${
            addRowHovered && addRow ? classes.addRowIconHovered : ""
          }`}
        >
          {muiIcon()}
        </ListItemIcon>
      )}
      {!muiIcon && icon && (
        <ListItemIcon>
          <i className={`${classes.menuIcon} ${icon}`} />
        </ListItemIcon>
      )}
      {avatar && (
        <ListItemAvatar>
          <Avatar
            src={getFileTarget(avatar, "100x100")}
            alt="avatar"
            className={classes.avatar}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          className: classes.menuText,
        }}
        className={classes.text}
        secondary={secondaryLabel}
      />
    </ListItem>
  );
}

export default MenuItem;
