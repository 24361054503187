import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ primary }) => ({
    backgroundColor: primary && primary,
    color: primary && "white !important",
    "&:hover": {
      backgroundColor: primary && "#F7F7F7",
      color: primary && `${primary} !important`,
    },
  }),
}));

function SearchButton({ primary }) {
  const classes = useStyles({ primary });

  return (
    <IconButton className={classes.root}>
      <SearchIcon />
    </IconButton>
  );
}

export default SearchButton;
