/*global google*/

export const getUserCurrentLocation = async () => {
  return new Promise((resolve, reject) => {
    return navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (err) => reject(err),
      {
        timeout: 20 * 1000,
      }
    );
  });
};

export const locationFromCoords = async (lat, long) => {
  const geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(lat, long);
  return new Promise((resolve, reject) => {
    return geocoder.geocode(
      {
        latLng: latlng,
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            resolve(results[0]);
          } else {
            reject("No results found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      }
    );
  });
};
