import { QuestionType } from "../../../services/question";

export const findStepOfQuestion = (questions, questionIndex) => {
  let stepIndex = 0;
  const stepQuestions = questions.filter((q) => q.type === QuestionType.STEP);
  for (let i = 0; i < stepQuestions.length; i++) {
    const currentStepIndex = questions.indexOf(stepQuestions[i]);
    if (currentStepIndex < questionIndex) {
      stepIndex = currentStepIndex;
    }
  }
  const step = questions[stepIndex];
  return step;
};
