import rootReducer from "./reducers";
import { createStore } from "redux";
import { saveState, loadState } from "./local_storage";

// setup redux store
let store;
const persistedState = loadState();
if (process.env.NODE_ENV !== "production") {
  store = createStore(
    rootReducer,
    persistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(rootReducer, persistedState);
}

store.subscribe(() => {
  const currentState = store.getState();
  saveState({
    user: currentState.user,
    shiftView: {
      ...currentState.shiftView,
      usersLoading: true,
      jobsLoading: true,
      users: [],
      jobs: [],
      userHover: null,
      rightPanelJob: null,
      rightPanelUser: null,
    },
    currentWidget: {
      ...currentState.currentWidget,
      widget: null,
    },
  });
});

export default store;
