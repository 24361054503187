import React from "react";
import { shouldDisplayUserField } from "../../../services/access";
import { connectWithStore } from "../../../services/redux";
import { FIELD_TYPES } from "./constants";
import UserDateField from "./date_field";
import TextWithFiles from "./text_with_files";
import UserFileField from "./file_field";
import UserAddressField from "./address_field";
import UserTextField from "./text_field";
import UserSelectionField from "./user_select_field";
import UserSwitchField from "./switch_field";
import UserSelectField from "./select_field";
import UserRichTextField from "./rich_text_field";

function FieldRenderer({
  field,
  value,
  changeUserProp,
  systemUser,
  settings,
  user,
}) {
  const renderUserField = () => {
    switch (field.type) {
      case FIELD_TYPES.DATE:
        return (
          <UserDateField
            field={field}
            changeUserProp={changeUserProp}
            value={value}
          />
        );
      case FIELD_TYPES.TEXT_WITH_FILES:
        return (
          <TextWithFiles
            field={field}
            changeUserProp={changeUserProp}
            value={value}
          />
        );
      case FIELD_TYPES.FILE:
        return (
          <UserFileField
            field={field}
            changeUserProp={changeUserProp}
            files={value}
          />
        );
      case FIELD_TYPES.ADDRESS:
        return (
          <UserAddressField
            field={field}
            changeUserProp={changeUserProp}
            value={value}
          />
        );
      case FIELD_TYPES.TEXT:
        return (
          <UserTextField
            field={field}
            value={value}
            changeUserProp={changeUserProp}
          />
        );
      case FIELD_TYPES.USER_SELECTION:
        return (
          <UserSelectionField
            field={field}
            value={value}
            changeUserProp={changeUserProp}
            currentUser={user}
          />
        );
      case FIELD_TYPES.BOOLEAN:
        return (
          <UserSwitchField
            field={field}
            changeUserProp={changeUserProp}
            value={value}
          />
        );
      case FIELD_TYPES.SELECT:
        return (
          <UserSelectField
            field={field}
            changeUserProp={changeUserProp}
            value={value}
          />
        );
      case FIELD_TYPES.MULTI_SELECT:
        return (
            <UserSelectField
                field={field}
                changeUserProp={changeUserProp}
                value={value}
                multiple={true}
            />
        );
      case FIELD_TYPES.RICH_TEXT:
        return (
          <UserRichTextField
            field={field}
            changeUserProp={changeUserProp}
            value={value ? value : "No items"}
          />
        );
      default:
        return <div />;
    }
  };

  const displayUserField = shouldDisplayUserField(
    settings,
    user,
    systemUser,
    field.value
  );

  if (!displayUserField) {
    return null;
  }

  return <>{renderUserField()}</>;
}

export default connectWithStore(FieldRenderer, ["settings", "user"]);
