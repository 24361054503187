import i18n from "../../i18n";

export const NOTIFICATION_JOB_ANSWERED = "notification_job_answered";
export const NOTIFICATION_JOB_DELETED = "notification_job_deleted";
export const NOTIFICATION_USER_ASSIGNED_JOB =
  "notification_user_assigned_to_job";
export const NOTIFICATION_USER_UNASSIGNED_JOB =
  "notification_user_unassigned_from_job";
export const NOTIFICATION_JOB_TYPE_CREATED = "notification_job_type_created";
export const NOTIFICATION_JOB_CREATED = "notification_job_created";
export const NOTIFICATION_JOB_STATUS_CHANGED =
  "notification_job_status_changed";
export const NOTIFICATION_PROJECT_CREATED = "notification_project_created";
export const NOTIFICATION_PROJECT_DELETED = "notification_project_deleted";
export const NOTIFICATION_PROJECT_DETAILS_CHANGED =
  "notification_project_details_changed";
export const NOTIFICATION_USER_ASSIGNED = "notification_user_assigned";
export const NOTIFICATION_USER_CONFIRMED_ATTENDANCE = "user_confirm_attendance";
export const NOTIFICATION_FORGOT_PASSWORD = "forgot_password";

export const NOTIFICATION_TYPES = {
  [NOTIFICATION_JOB_ANSWERED]: i18n.t("default:_NOTIFICATION_JOB_ANSWERED", {
    jobWord: i18n.t("default:_JOBS"),
  }),
  [NOTIFICATION_JOB_DELETED]: i18n.t("default:_NOTIFICATION_ITEM_DELETED", {
    itemWord: i18n.t("default:_JOBS"),
  }),
  [NOTIFICATION_USER_ASSIGNED_JOB]: i18n.t(
    "default:_NOTIFICATION_USER_ASSIGNED_JOB",
    {
      jobWord: i18n.t("default:_JOBS"),
    }
  ),
  [NOTIFICATION_USER_UNASSIGNED_JOB]: i18n.t(
    "default:_NOTIFICATION_USER_UNASSIGNED_JOB",
    {
      jobWord: i18n.t("default:_JOBS"),
    }
  ),
  [NOTIFICATION_JOB_TYPE_CREATED]: i18n.t(
    "default:_NOTIFICATION_ITEM_CREATED",
    {
      itemWord: i18n.t("default:_JOB_TYPES"),
    }
  ),
  [NOTIFICATION_JOB_CREATED]: i18n.t("default:_NOTIFICATION_ITEM_CREATED", {
    itemWord: i18n.t("default:_JOBS"),
  }),
  [NOTIFICATION_JOB_STATUS_CHANGED]: i18n.t(
    "default:_NOTIFICATION_JOB_STATUS_CHANGED",
    {
      jobWord: i18n.t("default:_JOBS"),
    }
  ),
  [NOTIFICATION_PROJECT_CREATED]: i18n.t("default:_NOTIFICATION_ITEM_CREATED", {
    itemWord: i18n.t("default:_PROJECTS"),
  }),
  [NOTIFICATION_PROJECT_DELETED]: i18n.t("default:_NOTIFICATION_ITEM_DELETED", {
    itemWord: i18n.t("default:_PROJECTS"),
  }),
  [NOTIFICATION_PROJECT_DETAILS_CHANGED]: i18n.t(
    "default:_NOTIFICATION_PROJECT_DETAILS_CHANGED",
    {
      projectWord: i18n.t("default:_PROJECTS"),
    }
  ),
  [NOTIFICATION_USER_ASSIGNED]: i18n.t(
    "default:_NOTIFICATION_USER_ASSIGNED_JOB",
    {
      jobWord: i18n.t("default:_PROJECTS"),
    }
  ),
  [NOTIFICATION_USER_CONFIRMED_ATTENDANCE]: i18n.t(
    "default:_USER_CONFIRMED_ATTENDANCE"
  ),
  [NOTIFICATION_FORGOT_PASSWORD]: i18n.t(
    "default:_NOTIFICATION_FORGOT_PASSWORD"
  ),
};

export const NOTIFICATION_CATEGORY_MY_JOBS = "general_my_jobs";
export const NOTIFICATION_CATEGORY_MY_ACTIVITY = "general_my_activity";
export const NOTIFICATION_CATEGORY_PROJECT = "general_project";
export const NOTIFICATION_CATEGORY_JOB_ACTIVITY = "general_job_activity";
export const NOTIFICATION_CATEGORY_SYSTEM = "general_system";

export const NOTIFICATION_CATEGORIES = [
  {
    type: NOTIFICATION_CATEGORY_MY_JOBS,
    label: i18n.t("default:_MY_JOBS", {
      jobs: i18n.t("default:_JOBS"),
    }),
    notifications: [
      NOTIFICATION_JOB_ANSWERED,
      NOTIFICATION_JOB_CREATED,
      NOTIFICATION_JOB_STATUS_CHANGED,
      NOTIFICATION_USER_ASSIGNED,
      NOTIFICATION_USER_ASSIGNED_JOB,
      NOTIFICATION_USER_UNASSIGNED_JOB,
      NOTIFICATION_USER_CONFIRMED_ATTENDANCE,
    ],
    subtitle: "Where I am assigned",
  },
  {
    type: NOTIFICATION_CATEGORY_MY_ACTIVITY,
    label: i18n.t("default:_MY_ACTIVITY"),
    notifications: [
      NOTIFICATION_JOB_TYPE_CREATED,
      NOTIFICATION_JOB_ANSWERED,
      NOTIFICATION_JOB_CREATED,
      NOTIFICATION_JOB_STATUS_CHANGED,
      NOTIFICATION_PROJECT_CREATED,
      NOTIFICATION_PROJECT_DELETED,
      NOTIFICATION_PROJECT_DETAILS_CHANGED,
      NOTIFICATION_USER_ASSIGNED_JOB,
      NOTIFICATION_USER_UNASSIGNED_JOB,
      NOTIFICATION_USER_CONFIRMED_ATTENDANCE,
      NOTIFICATION_JOB_DELETED,
      NOTIFICATION_USER_ASSIGNED,
    ],
    subtitle: "What I did",
  },
  {
    type: NOTIFICATION_CATEGORY_PROJECT,
    label: i18n.t("default:_PROJECT_ACTIVITY"),
    notifications: [
      NOTIFICATION_PROJECT_CREATED,
      NOTIFICATION_PROJECT_DELETED,
      NOTIFICATION_PROJECT_DETAILS_CHANGED,
      NOTIFICATION_USER_ASSIGNED,
    ],
    subtitle: "Where I am assigned",
  },
  {
    type: NOTIFICATION_CATEGORY_JOB_ACTIVITY,
    label: i18n.t("default:_JOB_ACTIVITY"),
    notifications: [
      NOTIFICATION_JOB_TYPE_CREATED,
      NOTIFICATION_JOB_ANSWERED,
      NOTIFICATION_JOB_CREATED,
      NOTIFICATION_JOB_STATUS_CHANGED,
      NOTIFICATION_USER_ASSIGNED_JOB,
      NOTIFICATION_USER_UNASSIGNED_JOB,
      NOTIFICATION_USER_CONFIRMED_ATTENDANCE,
    ],
    subtitle: "Whatever I can see",
  },
  {
    type: NOTIFICATION_CATEGORY_SYSTEM,
    label: i18n.t("default:_SYSTEM_NOTIFICATIONS"),
    notifications: [NOTIFICATION_FORGOT_PASSWORD],
  },
];

export const NOTIFICATION_DELIVERY_TYPE = [
  {
    type: "notification",
    label: i18n.t("default:_NOTIFICATIONS"),
  },
  {
    type: "email",
    label: i18n.t("default:_EMAIL"),
  },
  {
    type: "push",
    label: i18n.t("default:_PUSH_NOTIFICATIONS"),
  },
  {
    type: "SMS",
    label: i18n.t("default:_SMS"),
  },
];
