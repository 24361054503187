import React, { Component } from "react";
import stringScore from "string-score";
import i18n from "../../../i18n";
import { getFileTarget } from "../../../services/api/file_upload";
import TiledListItem from "./tiled_list_item";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.isMultiSelect() ? [] : null,
      searchWord: "",
      ID: props.question.ID,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    let cmpData = this.props.answers[this.props.question.ID];
    if (!cmpData) {
      return;
    }
    if (
      JSON.stringify(prevState.activeItem) ===
      JSON.stringify(this.props.answers[this.props.question.ID]["value"])
    ) {
      return;
    }
  }

  componentDidMount() {
    let cmpData = this.props.answers[this.props.question.ID];

    if (cmpData && this.props.answers[this.props.question.ID]["value"]) {
      const list =
        this.props.question.settings.childs &&
        this.props.question.settings.childs.map((child) =>
          Array.isArray(child) ? child[0] : child
        );
      const answers =
        list &&
        Array.isArray(this.props.answers[this.props.question.ID]["value"])
          ? [...this.props.answers[this.props.question.ID]["value"]].filter(
              (answer) => list.indexOf(answer) !== -1
            )
          : Array.isArray(this.props.answers[this.props.question.ID]["value"])
          ? [...this.props.answers[this.props.question.ID]["value"]]
          : [];
      this.setState({
        activeItem: this.isMultiSelect()
          ? answers
          : this.props.answers[this.props.question.ID]["value"],
      });
    }

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
  }

  getDefaultAvatar() {
    return null;
  }

  getChilds() {
    return this.props.question.settings.childs || [];
  }

  isMultiSelect() {
    return this.props.question.settings.multiSelect || false;
  }

  renderItem(item, index, arr, viewOnly = true) {
    const isTileQuestion = this.props.question.settings.tiledList || false;
    const isSelected = this.isSelected(item);
    const liHeight = arr && arr.length < 3 ? "88px" : "54px";
    let liBackgroundColor =
      isSelected && this.props.primary
        ? this.props.primary
        : this.props.question.settings.darkMode
        ? "rgba(0, 0, 0, 0.4)"
        : null;
    const liColor =
      (isSelected && this.props.primary) ||
      this.props.question.settings.darkMode
        ? "white"
        : null;
    const liBorder = this.props.question.settings.darkMode
      ? `none`
      : this.props.primary
      ? `1px solid ${this.props.primary}`
      : null;

    return (
      <li
        className={
          isSelected
            ? "ListComponent-Item ListComponent-Selected"
            : "ListComponent-Item"
        }
        style={{
          backgroundColor: liBackgroundColor,
          color: liColor,
          border: liBorder,
          height: liHeight,
          cursor: "pointer",
        }}
        key={index}
        onClick={() => (!viewOnly ? null : this.selectValue(item))}
        dir="auto"
      >
        {isSelected && (
          <div className="ListComponent-Checked">
            <i className="mdi mdi-check" />
          </div>
        )}
        {typeof item == "object" && "avatar" in item && (
          <div className="ListComponent-Avatar">
            <div className="ListComponent-AvatarCircle">
              {item.avatar && (
                <img
                  src={getFileTarget(item.avatar, "100x100")}
                  alt={item.value}
                />
              )}
              {!item.avatar && this.getDefaultAvatar() !== null && (
                <img src={this.getDefaultAvatar()} alt={item.value} />
              )}
            </div>
          </div>
        )}
        {isTileQuestion ? (
          <TiledListItem item={item} />
        ) : (
          <div className="ListComponent-Value" dir="auto">
            {item.value || item}
            {this.renderMarkings(item)}
          </div>
        )}
      </li>
    );
  }

  renderMarkings(item){
    const { markings } = this.props.question.settings;
    if(!markings) return '';
    const parts = item && item.location ? item.location.split('?') : [];
    if(parts.length > 0 && markings[parts[0]]){
      return " - ( " +markings[parts[0]] + " )";
    }
  }

  renderSelected() {
    return this.getChilds()
      .filter((item) => this.isSelected(item))
      .sort(this.sortAlphabetically.bind(this))
      .map((item, i) => this.renderItem(item, i, null, false));
  }

  hasSearch() {
    return this.props.question.settings.type === 1 || this.isClosed();
  }

  search(keyword) {
    this.setState({
      searchWord: keyword.length > 0 ? keyword : "",
    });
  }

  renderSearch() {
    return (
      <li className={"ListComponent-Search"}>
        <div className="input-group">
          <input
            onChange={(e) => this.search(e.target.value)}
            type="text"
            placeholder="type here"
            value={this.state.searchWord}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="mdi mdi-magnify" />
            </span>
          </div>
        </div>
      </li>
    );
  }

  isClosed() {
    return this.props.question.settings.type === 2;
  }

  searchFilter(item) {
    const { searchWord } = this.state;
    if (this.hasSearch()) {
      if (this.isClosed() && searchWord.length < 3) {
        return false;
      }
      if (searchWord.length > 0) {
        const searchable = item.value || item;
        return stringScore(searchable.toLowerCase(), searchWord.toLowerCase()) > 0.5;
      }
    }
    return true;
  }

  isSelected(option) {
    const { activeItem } = this.state;
    if (!activeItem) {
      return false;
    }
    if (Array.isArray(activeItem) && activeItem.length === 0) {
      return false;
    }
    if (this.isMultiSelect()) {
      if (typeof option == "string") {
        return activeItem.indexOf(option) !== -1;
      } else if (typeof option === "object" && option.value) {
        const activeValues = activeItem.map((item) => item.value);
        return activeValues.indexOf(option.value) !== -1;
      } else {
        return activeItem.indexOf(option[0]) !== -1;
      }
    } else {
      if (typeof activeItem == "string") {
        if (typeof option === "string") {
          return activeItem === option;
        } else if (Array.isArray(option)) {
          return activeItem === option[0];
        } else {
          return activeItem === option.value;
        }
      } else {
        return activeItem.value === option.value;
      }
    }
  }

  selectValue(value) {
    let valueStr = value;
    if (Array.isArray(value) && value.length > 0) {
      valueStr = value[0];
    }
    if (this.isMultiSelect()) {
      this.handleMultiSelect(valueStr);
    } else {
      this.handleSelect(valueStr);
    }
  }

  handleMultiSelect(value) {
    let { activeItem } = this.state;

    if (!activeItem) {
      return;
    }

    if (!Array.isArray(activeItem) && typeof activeItem === "string") {
      activeItem = [activeItem];
    }
    let index;
    if (typeof value === "object" && value.value) {
      const activeValues = activeItem.map((item) => item.value);
      index = activeValues.indexOf(value.value);
    } else {
      index = activeItem.indexOf(value);
    }
    if (index !== -1) {
      activeItem.splice(index, 1);
    } else {
      activeItem.push(value);
    }
    this.setState(
      {
        activeItem,
      },
      () => {
        this.props.handleChange(activeItem);
      }
    );
  }

  handleSelect(value) {
    /* if (Array.isArray(value) && value.length > 0) {
      value = value[0];
    } */

    const { activeItem } = this.state;
    let nextValue = value;
    if (activeItem === nextValue) {
      nextValue = null;
    }
    this.setState(
      {
        activeItem: nextValue,
      },
      () => this.props.handleChange(nextValue)
    );
  }

  sortAlphabetically(a, b) {
    if (!this.props.question.settings.sortAlphabet) {
      return 0;
    }

    if (Array.isArray(a) && a.length === 1) {
      a = a[0];
    }

    if (Array.isArray(b) && b.length === 1) {
      b = b[0];
    }
    const nameA =
      typeof a == "object" ? a.value.toLowerCase() : a.toLowerCase();
    const nameB =
      typeof b == "object" ? b.value.toLowerCase() : b.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  }

  renderAnswersOutsideList() {
    const { activeItem } = this.state;
    const items = this.getChilds();
    if (
      activeItem &&
      typeof activeItem === "string" &&
      items.indexOf(activeItem) === -1
    ) {
      return (
        <div className="selected-answer">
          {`Selected answer: ${activeItem}`}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="question-content ListComponent">
        <ul className="list-answers">
          {this.hasSearch() && this.renderSearch()}
          {this.getChilds()
            .filter(this.searchFilter.bind(this))
            .sort(this.sortAlphabetically.bind(this))
            .map((item, i, arr) => this.renderItem(item, i, arr))}
          {this.isClosed() && (
            <div className="w-100 pt-3">
              <p>{i18n.t("default:_CURRENTLY_SELECTED")}</p>
              {this.renderSelected()}
            </div>
          )}
        </ul>
        {this.renderAnswersOutsideList()}
      </div>
    );
  }
}

export default List;
