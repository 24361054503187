import React from "react";
import Grid from "@material-ui/core/Grid";
import { isMobileDevice } from "../../services/mobile";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: ({ position }) => (position ? position : "absolute"),
    right: isMobileDevice() ? 0 : 20,
    bottom: 0,
    zIndex: "9999",
    background: ({ backgroundColor }) =>
      backgroundColor ? backgroundColor : "auto",
    borderTop: ({ borderTop }) => (borderTop ? borderTop : "unset"),
  },
}));

function ButtonBlock({ children, position, backgroundColor, borderTop }) {
  const classes = useStyles({ position, backgroundColor, borderTop });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      direction="row"
      className={classes.buttonContainer}
    >
      {children}
    </Grid>
  );
}

export default ButtonBlock;
