import React, { useState, useCallback, useEffect } from "react";
import i18n from "../../i18n";
import { creteProject } from "../../services/api/projects";
import WizardButtons from "./wizard_buttons";
import { STEP_CREATE_NEW, STEP_SELECT_PROJECT_SKILLS } from "./steps";
import { Wizard, WizardProject } from "../../redux/actions";
import { WIZARD_TYPE_PROJECT } from "./constants";
import { connectWithStore } from "../../services/redux";
import ToggleService from "../../services/toggle";
import { PROJECT_FIELD_TYPE } from "../../pages/projects/fields/constants";

function CreateNewProject({
  dismiss,
  goToStep,
  dispatch,
  isActive,
  settings,
  wizardProject,
}) {
  const [name, setName] = useState(
    wizardProject.name ? wizardProject.name : ""
  );
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [shouldLoadSkillsTab, setShouldLoadSkillsTab] = useState(false);
  const [hasSkillField, setHasSkillField] = useState(false);

  const checkSkillsToggle = useCallback(async () => {
    const response = await ToggleService._isToggleActive(
      "skills",
      settings.toggles
    );
    setShouldLoadSkillsTab(response);
  }, [settings]);

  const checkForSkillFields = useCallback(() => {
    const currentSkillFields = settings.projectFields.filter(
      (field) => field.type === PROJECT_FIELD_TYPE.SKILLS
    );
    currentSkillFields.length > 0
      ? setHasSkillField(true)
      : setHasSkillField(false);
    let dispatchValue = { skillFields: currentSkillFields };
    dispatch(WizardProject.setSkillFields(dispatchValue));
  }, [settings.projectFields, dispatch]);

  const onChange = (value) => {
    let dispatchValue = { name: "" };
    if (value) {
      setError(false);
      dispatchValue = { name: value };
    } else {
      setError(true);
    }
    dispatch(WizardProject.setName(dispatchValue));
    setName(dispatchValue.name);
  };

  const createProject = async () => {
    let data = {
      name,
    };

    const response = await creteProject(data);
    return response;
  };

  useEffect(() => {
    checkSkillsToggle();
    checkForSkillFields();
    if (isActive)
      dispatch(Wizard.setTitle(i18n.t("default:_WHAT_WOULD_LIKE_TO_CREATE")));
  }, [dispatch, isActive, checkSkillsToggle, checkForSkillFields]);

  const onFinishClick = async (withSkills) => {
    const response = await createProject();
    if (response.success) {
      if (withSkills) {
        const { data } = response;

        const idValue = { id: data && data.id };
        dispatch(WizardProject.setSkillId(idValue));
        goToStep(STEP_SELECT_PROJECT_SKILLS);
      } else {
        dispatch(Wizard.setType(WIZARD_TYPE_PROJECT));
        dismiss();
      }
    } else {
      setError(true);
      setErrMessage(response.data.message);
    }
  };

  return (
    <div
      className="ProjectModal ModalBody"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="new-project"
        style={{
          flex: 10,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "70%",
        }}
      >
        <div className="form-row" style={{ width: "100%", maxWidth: 350 }}>
          <div
            className="form-group floating-label col-md-12"
            style={{ marginTop: 25 }}
          >
            <label>{i18n.t("default:_NAME")}</label>
            <input
              required
              value={name}
              onChange={({ target }) => onChange(target.value)}
              className={error ? "form-control is-invalid" : "form-control"}
              placeholder={i18n.t("default:_PROJECT_NAME")}
            />
            {error && (
              <div>
                <p style={{ color: "red", marginTop: 20 }}>{errMessage}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_CREATE_NEW),
            variant: "white",
          },
          shouldLoadSkillsTab &&
            hasSkillField && {
              label: i18n.t("default:_NEXT"),
              onClick: () => onFinishClick(true),
              variant: "black",
              disabled: name.length === 0 || error ? true : false,
            },
          (!shouldLoadSkillsTab || !hasSkillField) && {
            label: i18n.t("default:_FINISH"),
            onClick: () => onFinishClick(),
            variant: "black",
            disabled: name.length === 0 || error ? true : false,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(CreateNewProject, [
  "settings",
  "wizardProject",
]);
