const initalState = { notifications: [] };

const my_profile = (state = Object.assign({}, initalState), action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      state.notifications = action.data;
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default my_profile;
