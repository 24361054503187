import React, { useState, useCallback, useEffect } from "react";
import FieldPreview from "./field_preview";
import { CurrentProject } from "../../../redux/actions";
import FileManager from "../../../components/file_manager/file_manager";
import { updateSignleProjectField } from "../../../services/api/projects";
import withModal from "../../../hoc/withModal";
import { connectWithStore } from "../../../services/redux";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getFileIcon } from "../../../services/question";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import {
  getFileInfo /* , getFileTarget */,
} from "../../../services/api/file_upload";
import { ALLOWED_MIMES } from "../../../components/file_manager/constants";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    padding: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
  },
}));

const FileManagerModal = withModal(FileManager);

function FileListModal({
  visible,
  dispatch,
  onClose,
  id,
  currentText,
  project,
  title,
}) {
  const onUpdate = useCallback(
    async (newFiles) => {
      await updateSignleProjectField({
        id: project._id,
        field: {
          id,
          value: newFiles,
        },
      });
      dispatch(
        CurrentProject.updateProject({
          [id]: newFiles,
        })
      );
    },
    [dispatch, id, project._id]
  );

  const onAttachment = useCallback(
    (files) => {
      const newFiles = project[id] ? [...project[id]] : [];
      files.forEach((file) => {
        newFiles.push(file.assetId);
      });
      onUpdate(newFiles);
    },
    [onUpdate, id, project]
  );

  const onRemoveAttachment = useCallback(
    (index) => {
      const newFiles = project[id] ? [...project[id]] : [];
      if (newFiles[index]) {
        newFiles.splice(index, 1);
      }
      onUpdate(newFiles);
    },
    [onUpdate, id, project]
  );

  return (
    <FileManagerModal
      visible={visible}
      onClose={() => onClose()}
      title={title}
      files={project[id] ? [...project[id]] : []}
      onAttachment={(files) => onAttachment(files)}
      removeAttachment={(index) => onRemoveAttachment(index)}
      allowedFiles={ALLOWED_MIMES}
    />
  );
}

const FileListModalConnected = connectWithStore(FileListModal, []);

function FileList({ label, icon, editable, id, currentProject, accessRules }) {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const { project } = currentProject;
  const [finalValue, setFinalValue] = useState([]);
  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);
  useEffect(() => {
    if (editable) return;
    const value = project[id] ? [...project[id]] : [];
    if (value && value.length > 0) {
      const promises = [];
      /* const newValue =  */ value.map((v) => {
        return promises.push(getFileInfo(v));
      });

      Promise.all(promises).then((result) => {
        setFinalValue(
          result
            .filter((r) => r !== null)
            .map((v) => {
              return {
                label: v.originalName,
                value: v.assetId,
              };
            })
        );
      });
    }
  }, [id, project, editable]);
  return (
    <div>
      {!editable && (
        <FileListModalConnected
          project={project}
          onClose={onClose}
          id={id}
          currentText={project[id]}
          visible={modalVisible}
          title={label}
        />
      )}
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.FILE_LIST]}
        onClick={() => (!editable ? setModalVisible(true) : null)}
        accessRules={accessRules}
      >
        {!editable && (
          <List className={classes.listRoot}>
            {finalValue.length > 0 &&
              finalValue.map(({ label, value }) => {
                return (
                  <ListItem
                    className={classes.listItemRoot}
                    key={value}
                    style={
                      value === -1
                        ? {
                            width: "100%",
                          }
                        : {}
                    }
                  >
                    {value !== -1 && (
                      <ListItemAvatar>
                        <div data-tip={label}>
                          <Avatar>
                            <i
                              className={`mdi ${getFileIcon(
                                label && label.split(".").pop()
                              )}`}
                            />
                          </Avatar>
                        </div>
                      </ListItemAvatar>
                    )}
                    {value === -1 && (
                      <ListItemText
                        primary={label}
                        style={{ textAlign: "center" }}
                      />
                    )}
                  </ListItem>
                );
              })}
          </List>
        )}
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(FileList, ["currentProject"]);
