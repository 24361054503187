import React, { useCallback, useState, useEffect } from "react";
import i18n from "../../i18n";
import WizardButtons from "./wizard_buttons";
import {
  STEP_SELECT_START_DATE,
  STEP_ASSIGN_USERS,
  STEP_JOB_CREATED,
} from "./steps";
import { connectWithStore } from "../../services/redux";
import { withRouter } from "react-router-dom";
import { createJob, viewJob, addPrevious } from "../../services/api/jobs";
import { WizardJob, Wizard } from "../../redux/actions";
import BlockUi from "react-block-ui";
import ToggleService from "../../services/toggle";
import JobCreatedInfoRow from "./create_job/job_created_info_row";
import { APP_PROJECTS } from "../../services/app";

function CreateNewJob({
  goToStep,
  wizardJob,
  dispatch,
  isActive,
  settings,
  apps,
}) {
  const { jobType, project, startDate, prevJob, nextJob } = wizardJob;
  const [loading, setLoading] = useState(false);
  const onCreateJob = useCallback(async () => {
    setLoading(true);
    const params = {
      job_type: jobType._id,
      project: project ? project._id : null,
      prev_job: prevJob ? prevJob[0].value : null,
      startDate: startDate,
      users: [],
    };
    const { success, data } = await createJob(params);
    const { id } = data;
    if (success) {
      //Assign the new job as previous job
      if (nextJob) {
        await addPrevious(nextJob, id);
      }
      //Assign the new job as next
      if (prevJob) {
        await addPrevious(id, prevJob[0].value);
      }
    }
    const jobResponse = await viewJob(id);
    if (!jobResponse) {
      return;
    }
    const { ID } = jobResponse;
    const jobTypeSettings = jobResponse.jobType.settings;
    const { previewSettings } = jobTypeSettings;
    let nextStep = STEP_ASSIGN_USERS;
    dispatch(WizardJob.setId({ id }));
    dispatch(WizardJob.setLinkId({ linkId: ID }));
    if (
      (previewSettings && previewSettings.length > 0) ||
      ToggleService._isToggleActive("previous_job", settings.toggles)
    ) {
      if (previewSettings && previewSettings.length > 0) {
        dispatch(
          WizardJob.setMainQuestions({ mainQuestions: previewSettings })
        );
      }
      nextStep = STEP_JOB_CREATED;
    }
    //setLoading(false);
    setTimeout(() => {
      goToStep(nextStep);
    }, 500);
  }, [
    jobType,
    project,
    startDate,
    dispatch,
    goToStep,
    prevJob,
    nextJob,
    settings,
  ]);

  useEffect(() => {
    if (isActive)
      dispatch(Wizard.setTitle(i18n.t("default:_WE_GOT_EVERYTHING")));
  }, [dispatch, isActive]);

  return (
    <BlockUi
      tag="div"
      className="row fullHeight"
      blocking={loading}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
      }}
    >
      <div className="WhichEntity CreateJob">
        <div className="InfoRowContainer">
          <JobCreatedInfoRow goToStep={goToStep} />
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_SELECT_START_DATE),
            variant: "white",
          },
          {
            label: i18n.t("default:_CREATE_NEW"),
            onClick: () => onCreateJob(),
            variant: "black",
            disabled: !(
              wizardJob.jobType &&
              ((apps.enabled.includes(APP_PROJECTS) && wizardJob.project) ||
                !apps.enabled.includes(APP_PROJECTS)) &&
              wizardJob.startDate
            ),
            size: 2,
          },
        ]}
      />
    </BlockUi>
  );
}

export default connectWithStore(withRouter(CreateNewJob), [
  "wizardJob",
  "settings",
  "apps",
]);
