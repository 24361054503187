import React from "react";
import ContentLoader from "react-content-loader";

const FieldLoader = () => (
  <ContentLoader style={{ width: "100%", height: 50, marginBottom: 0 }}>
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="50" />
  </ContentLoader>
);

export default FieldLoader;
