import {
  CURRENT_WIDGET_SET_WIDGET,
  CURRENT_WIDGET_RESET_WIDGET,
  CURRENT_WIDGET_UPDATE_WIDGET,
  CURRENT_WIDGET_SET_MOBILE_TAB,
} from "../constants";

const initialState = {
  widget: null,
  mobileTab: "charts",
};

const currentWidget = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case CURRENT_WIDGET_SET_WIDGET:
      return Object.assign({}, state, {
        widget: data,
      });
    case CURRENT_WIDGET_RESET_WIDGET:
      return Object.assign({}, state, {
        widget: null,
      });
    case CURRENT_WIDGET_UPDATE_WIDGET:
      return Object.assign({}, state, {
        widget: Object.assign({}, state.widget, data),
      });
    case CURRENT_WIDGET_SET_MOBILE_TAB:
      return Object.assign({}, state, {
        mobileTab: data,
      });
    default:
      return state;
  }
};

export default currentWidget;
