import {
  AUTOMATION_WIZARD_SET_TOPIC,
  AUTOMATION_WIZARD_SET_EVENT,
  AUTOMATION_WIZARD_SET_ID,
  AUTOMATION_WIZARD_SET_CONDITIONS,
  AUTOMATION_WIZARD_SET_CONDITIONS_CONFIG,
  AUTOMATION_WIZARD_SET_LAST_STEP,
  AUTOMATION_WIZARD_SET_ACTION,
  AUTOMATION_WIZARD_SET_ACTIONS_CONFIG,
  AUTOMATION_WIZARD_SET_NAME,
  AUTOMATION_WIZARD_SET_CONDITION_OPTIONS,
  AUTOMATION_WIZARD_SET_INITIAL_EVENT,
  AUTOMATION_WIZARD_SET_CONNECTED_DATA,
  AUTOMATION_WIZARD_SET_RECEIVERS,
  AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS,
  AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO,
  AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS,
  AUTOMATION_WIZARD_SET_ACTION_QUESTIONS,
  AUTOMATION_WIZARD_SET_TOPICS,
  AUTOMATION_WIZARD_SET_REQUIRED_CONDITIONS,
  AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST,
  AUTOMATION_WIZARD_SET_TEST_URL,
  AUTOMATION_WIZARD_SET_ACTIONS,
  AUTOMATION_WIZARD_SET_ACTION_CHANGED,
  AUTOMATION_WIZARD_RESET,
  AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED,
} from "../constants";

const initialState = {
  topics: [],
  topic: null,
  event: null,
  initialEvent: null,
  automationId: null,
  conditionOptions: [],
  conditions: [],
  conditionsConfig: {},
  requiredConditions: [],
  lastStep: 1,
  action: null,
  actions: [],
  actionsConfig: {},
  actionsConfigInfo: {},
  actionChanged: false,
  name: "",
  connectedDataParams: [],
  receivers: [],
  templateParams: [],
  conditionQuestions: [],
  actionQuestions: [],
  connectedDataRequest: null,
  testUrl: "",
  conditionsChecked: false,
};

const automationWizard = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case AUTOMATION_WIZARD_SET_TOPIC:
      return Object.assign({}, state, {
        topic: data,
      });
    case AUTOMATION_WIZARD_SET_TOPICS:
      return Object.assign({}, state, {
        topics: data,
      });
    case AUTOMATION_WIZARD_SET_EVENT:
      return Object.assign({}, state, {
        event: data,
      });
    case AUTOMATION_WIZARD_SET_INITIAL_EVENT:
      return Object.assign({}, state, {
        initialEvent: data,
      });
    case AUTOMATION_WIZARD_SET_ID:
      return Object.assign({}, state, {
        automationId: data,
      });
    case AUTOMATION_WIZARD_SET_CONDITIONS:
      return Object.assign({}, state, {
        conditions: data,
      });
    case AUTOMATION_WIZARD_SET_REQUIRED_CONDITIONS:
      return Object.assign({}, state, {
        requiredConditions: data,
      });
    case AUTOMATION_WIZARD_SET_CONDITION_OPTIONS:
      return Object.assign({}, state, {
        conditionOptions: data,
      });
    case AUTOMATION_WIZARD_SET_CONDITIONS_CONFIG:
      return Object.assign({}, state, {
        conditionsConfig: data,
      });
    case AUTOMATION_WIZARD_SET_LAST_STEP:
      return Object.assign({}, state, {
        lastStep: data,
      });
    case AUTOMATION_WIZARD_SET_ACTION:
      return Object.assign({}, state, {
        action: data,
      });
    case AUTOMATION_WIZARD_SET_ACTION_CHANGED:
      return Object.assign({}, state, {
        actionChanged: data,
      });
    case AUTOMATION_WIZARD_SET_ACTIONS:
      return Object.assign({}, state, {
        actions: data,
      });
    case AUTOMATION_WIZARD_SET_ACTIONS_CONFIG:
      return Object.assign({}, state, {
        actionsConfig: data,
      });
    case AUTOMATION_WIZARD_SET_ACTIONS_CONFIG_INFO:
      return Object.assign({}, state, {
        actionsConfigInfo: data,
      });
    case AUTOMATION_WIZARD_SET_NAME:
      return Object.assign({}, state, {
        name: data,
      });
    case AUTOMATION_WIZARD_SET_CONNECTED_DATA:
      return Object.assign({}, state, {
        connectedDataParams: data,
      });
    case AUTOMATION_WIZARD_SET_CONNECTED_DATA_REQUEST:
      return Object.assign({}, state, {
        connectedDataRequest: data,
      });
    case AUTOMATION_WIZARD_SET_RECEIVERS:
      return Object.assign({}, state, {
        receivers: data,
      });
    case AUTOMATION_WIZARD_SET_TEMPLATE_PARAMS:
      return Object.assign({}, state, {
        templateParams: data,
      });
    case AUTOMATION_WIZARD_SET_CONDITION_QUESTIONS:
      return Object.assign({}, state, {
        conditionQuestions: data,
      });
    case AUTOMATION_WIZARD_SET_ACTION_QUESTIONS:
      return Object.assign({}, state, {
        actionQuestions: data,
      });
    case AUTOMATION_WIZARD_SET_TEST_URL:
      return Object.assign({}, state, {
        testUrl: data,
      });
    case AUTOMATION_WIZARD_SET_CONDITIONS_CHECKED:
      return Object.assign({}, state, {
        conditionsChecked: data,
      });
    case AUTOMATION_WIZARD_RESET:
      return Object.assign(
        {},
        { ...initialState },
        {
          actionsConfig: {},
        }
      );
    default:
      return state;
  }
};

export default automationWizard;
