import React, { useState, useEffect, useCallback } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { STEP_JOB_CREATED } from "../steps";
import SkillAutoComplete from "../../autoselect/skill_autoselect";
import { Wizard, WizardJob } from "../../../redux/actions";
import SKILL_COLORS from "../../../services/skill_colors";
import { getJobType } from "../../../services/api/job_type";
import { getProject } from "../../../services/api/projects";
import { PROJECT_FIELD_TYPE } from "../../../pages/projects/fields/constants";
import { getSkill } from "../../../services/api/skills";
import { assignSkillsToJob } from "../../../services/api/skills";

function SelectJobSkills({
  goToStep,
  dispatch,
  isActive,
  wizardJob,
  settings,
}) {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [initialSkills, setInitialSkills] = useState([]);
  const [selectedToRemove, setSelectedToRemove] = useState([]);
  const { id } = wizardJob;

  const onSkillsChange = (selected) => {
    setSelectedSkills(
      selected.filter((val) => val && val._id && typeof val._id !== "undefined")
    );
  };

  const setJobSkills = useCallback(async () => {
    const response = await getJobType({
      id: wizardJob.jobType.value,
    });
    const currentSkillFields = settings.projectFields.filter(
      (field) => field.type === PROJECT_FIELD_TYPE.SKILLS
    );
    const requiredSkills = response.requiredSkills;

    const { data, success } = await getProject({ id: wizardJob.project.value });

    if (success && requiredSkills) {
      const requiredSkillsIds = requiredSkills.map((skill) => skill._id);

      if (currentSkillFields) {
        for (let field of currentSkillFields) {
          if (data[field.id]) {
            for (let skill of data[field.id]) {
              let uniqueSkill = true;
              if (requiredSkillsIds.includes(skill)) {
                uniqueSkill = false;
              }
              if (uniqueSkill) {
                requiredSkillsIds.push(skill);
                const skillResponse = await getSkill(skill);
                if (skillResponse.success) {
                  requiredSkills.push(skillResponse.data);
                }
              }
            }
          }
        }
      }
    }

    if (response || success) {
      setInitialSkills(
        wizardJob.skills && wizardJob.skills.length !== 0
          ? wizardJob.skills
          : requiredSkills
          ? requiredSkills
          : []
      );
      setSelectedSkills(
        wizardJob.skills && wizardJob.skills.length !== 0
          ? wizardJob.skills
          : requiredSkills
          ? requiredSkills
          : []
      );
    }
  }, [wizardJob, settings.projectFields]);

  useEffect(() => {
    setJobSkills();
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_SELECT_SKILLS")));
  }, [dispatch, isActive, setJobSkills]);

  const assignSkills = async () => {
    const updatedSelectedSkills = [...selectedSkills].filter(
      (skill) => !selectedToRemove.includes(skill)
    );
    let dispatchValue = { skills: updatedSelectedSkills };

    //Assign skills to job
    const skillIds = updatedSelectedSkills.map((skill) => skill._id);
    const skillData = {
      skills: skillIds,
    };

    await assignSkillsToJob(skillData, id);
    dispatch(WizardJob.setSkills(dispatchValue));
    setSelectedSkills(updatedSelectedSkills);
    setInitialSkills(updatedSelectedSkills);
    setSelectedToRemove([]);
    goToStep(STEP_JOB_CREATED);
  };

  return (
    <div
      className="SelectJobType ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 10, width: "100%", maxWidth: 600, marginTop: 10 }}>
        <div className="form-group">
          <SkillAutoComplete
            onSkillsChange={(selected) => onSkillsChange(selected)}
            selectedSuggestions={selectedSkills}
            initialSkills={initialSkills}
            skillColor={SKILL_COLORS.GREEN}
            selectedToRemove={selectedToRemove}
            setSelectedToRemove={(selected) => setSelectedToRemove(selected)}
          />
        </div>
      </div>
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_JOB_CREATED),
            variant: "white",
          },
          {
            label: i18n.t(`default:_DONE`),
            onClick: () => {
              assignSkills();
            },
            variant: "black",
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(SelectJobSkills, ["wizardJob", "settings"]);
