import Http from "./http";

class ToggleService {
  static _isToggleActive(toggleId, toggles = null) {
    if (toggles !== null && Array.isArray(toggles) && toggles.length > 0) {
      const targetToggle = toggles.filter((t) => t.toggleId === toggleId);

      if (targetToggle && targetToggle.length === 1) {
        return targetToggle[0].isActive;
      }
    }

    return false;
  }

  static async isToggleActive(toggleId, toggles = null) {
    if (toggles !== null) {
      return ToggleService._isToggleActive(toggleId, toggles);
    }
    try {
      const path = `/v2/apps/${toggleId}/active`;
      const result = await Http.sharedInstance.getClient().get(path);

      if (result && result.data) {
        return result.data.active || false;
      }
    } catch (e) {
      return false;
    }
  }
}

export default ToggleService;
