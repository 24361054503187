import React from "react";
import {
  makeStyles,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toggleLabel: {
    color: "#3e4676",
    fontSize: "1em",
    fontWeight: 500,
  },
  centerContent: {
    justifyContent: "center",
  },
  noMargin: {
    margin: 0,
  },
}));

export default function MuiSwitch({
  checked,
  onToggle,
  name,
  label,
  centered,
  labelStyles,
  labelPlacement,
  labelPlacementStartStyles,
  disabled,
}) {
  const classes = useStyles();

  return (
    <FormGroup row className={centered ? classes.centerContent : ""}>
      <FormControlLabel
        classes={{
          label: labelStyles ? labelStyles : classes.toggleLabel,
          labelPlacementStart: labelPlacementStartStyles,
        }}
        className={centered ? classes.noMargin : ""}
        control={
          <Switch
            checked={checked}
            onChange={onToggle}
            name={name ? name : label}
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
        labelPlacement={labelPlacement ? labelPlacement : "end"}
      />
    </FormGroup>
  );
}
