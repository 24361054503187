import React from "react";

export default function WizardButtons({ actions, notSticky, withMarginRight }) {
  return (
    <div
      className="WizardButtonWrapper"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        position: "sticky",
        bottom: 0,
        left: 0,
        marginRight: withMarginRight && "25px",
      }}
    >
      {actions &&
        actions.length > 0 &&
        actions.map((action, index) => {
          if (!action) return null;
          const { label, onClick, variant, size, disabled } = action;
          return (
            <button
              style={{
                margin: 0,
                height: 50,
                flex: size ? size : 1,
                borderRadius: 0,
                textTransform: "uppercase",
              }}
              key={index}
              onClick={() => onClick()}
              disabled={typeof disabled !== "undefined" ? disabled : false}
              className={`btn btn-primary btn-block btn-${variant}`}
            >
              {label}
            </button>
          );
        })}
    </div>
  );
}
