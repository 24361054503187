import React from "react";
import { RichUtils } from "draft-js";
import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RichTextButton from "./rich_text_button";
import { STYLE_TYPES, BLOCK_BUTTONS, STYLE_BUTTONS } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  buttonContainer: {
    paddingBottom: theme.spacing(1),
  },
  divider: {
    width: "100%",
  },
}));

function RichTextButtons({ onEditorChange, editorState }) {
  const classes = useStyles();

  const onButtonClick = (style, type) => {
    if (type === STYLE_TYPES.RICH) {
      onEditorChange(RichUtils.toggleInlineStyle(editorState, style));
    }
    if (type === STYLE_TYPES.BLOCK) {
      onEditorChange(RichUtils.toggleBlockType(editorState, style));
    }
  };

  const isButtonActive = (style, type) => {
    if (type === STYLE_TYPES.RICH) {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }

    if (type === STYLE_TYPES.BLOCK) {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return style === blockType;
    }

    return false;
  };

  const blockButtons = Object.values(BLOCK_BUTTONS);
  const styleButtons = Object.values(STYLE_BUTTONS);

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.buttonContainer}>
        {blockButtons.map((button, i) => {
          return (
            <RichTextButton
              key={i}
              onButtonClick={onButtonClick}
              button={button}
              isActive={isButtonActive(button.style, STYLE_TYPES.BLOCK)}
              type={STYLE_TYPES.BLOCK}
            />
          );
        })}
        {styleButtons.map((button, i) => {
          return (
            <RichTextButton
              key={i}
              onButtonClick={onButtonClick}
              button={button}
              isActive={isButtonActive(button.style, STYLE_TYPES.RICH)}
              type={STYLE_TYPES.RICH}
            />
          );
        })}
      </Grid>
      <Divider className={classes.divider} />
    </Grid>
  );
}

export default RichTextButtons;
