import React, { Component } from "react";
import i18n from "../../i18n";
import Switch from "../switch/switch";
import { REPORT_TYPE } from "../../services/reports";
import {
  createReport,
  getQuestionsForReport,
  createTemplate,
  cloneReport,
  updateTemplate,
} from "../../services/api/reports";
import stringScore from "string-score";
import BlockUi from "react-block-ui";
import { withRouter } from "react-router";
import { QuestionType, DateType } from "../../services/question";
import "./builder.scss";
import { Button } from "@material-ui/core";
import Confirm from "../confirm/confirm";
import { withStyles } from "@material-ui/core/styles";
import store from "../../redux/store";
import Grid from "@material-ui/core/Grid";
import { isMobileDevice } from "../../services/mobile";
import {
  getTemplatesList,
  createFromTemplate,
  deleteTemplate,
} from "../../services/api/templates";
import ToggleService from "../../services/toggle";
import { connectWithStore } from "../../services/redux";
import { GridTable } from "../../redux/actions";
import ReportName from "./report_name_select";
import { PROJECT_FIELD_TYPE } from "../../pages/projects/fields/constants";
import { APP_PROJECTS } from "../../services/app";
import ReportTags from "./report_tags";
import {
  TAG_TYPE,
  TABLE,
  EXPRESSION,
  builtInSuggestions,
  SETTING_NAME,
} from "./constants";
import ReportTemplateItem from "./report_template_item";

const styles = (theme) => ({
  root: {
    //maxWidth: 345,
    //minHeight: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
    width: () => (isMobileDevice() ? "92%" : "25%"),
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: "none",
    margin: "4%",
    position: "relative",
  },
  jobTypeIcon: {
    width: "70px",
    height: "70px",
    background: "white",
  },
  greenButton: {
    backgroundColor: "#1CED89",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#13A55F",
    },
  },
  pinkButton: {
    backgroundColor: "#ED1C80",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#F04999",
    },
  },
  isNotEditedButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",

    "&:hover": {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
    },
  },
  actionArea: {
    height: "100%",
  },
});

let SUGGESTIONS = [...builtInSuggestions];

class ReportBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: this.props.defaultSelectedType || REPORT_TYPE.JOBS,
      columns: this.props.columns
        ? this.props.columns.map((column, index) => {
          return {
            ...column,
            order: column.order ? column.order : index,
          };
        })
        : [],
      settings: this.props.reportSettings
        ? this.props.reportSettings
        : {
          thumbnails: false,
          links: false,
          page_image: false,
          question_status: false,
          job_status: false,
          show_comment: false,
          limit_historical_data: true,
          rtl: false,
          projectReport:
            this.props.defaultSelectedType === REPORT_TYPE.PROJECTS
              ? true
              : false,
        },
      loading: true,
      isStart:
        typeof this.props.isStart !== "undefined" ? this.props.isStart : true,
      templates: [],
      isTemplate: this.props.isTemplate || false,
      templateName: this.props.templateName || "",
      editTemplate: null,
      editTemplateName: "",
      showConfirmDelete: false,
      deleteTemplateIndex: null,
      withReportTemplates: false,
      showNameSelect: false,
      selectedTemplate: null,
    };
    this.timeout = null;
  }

  async getReportBuilderData(props) {
    SUGGESTIONS = [...builtInSuggestions];
    let params = Object.assign({}, props.filters);
    if (params.jobType) {
      params.jobType = params.jobType
        .filter((jobType) => typeof jobType !== "undefined")
        .map((jobType) => jobType.value)
        .filter((jobType) => typeof jobType !== "undefined");
    }
    if (params.users) {
      params.users = params.users
        .filter((user) => typeof user !== "undefined")
        .map((user) => user.value)
        .filter((user) => typeof user !== "undefined");
    }
    if (this.props.project) {
      params = Object.assign(params, {
        project: this.props.project._id,
      });
    }

    const jobTypesWithQuestions = await getQuestionsForReport({
      type: this.state.table,
      ...params,
    });
    let nextId = SUGGESTIONS.length + 1;
    const reduxState = store.getState();
    const { settings } = reduxState;
    const { projectFields } = settings;
    for (let index in jobTypesWithQuestions) {
      const jobType = jobTypesWithQuestions[index];
      const questions = jobType.questions;
      if (!questions || questions.length === 0) {
        continue;
      }
      // eslint-disable-next-line
      questions.forEach((question) => {
        const newSuggestion = {
          id: nextId,
          type: TAG_TYPE.FIELD,
          table: TABLE.JOB_TYPE,
          name: `${question.title} #${question.ID} - ${jobType.title}`,
          field: `${question.ID}`,
          _id: `${question._id}`,
          root: question.root,
        };
        const filter = SUGGESTIONS.filter(
          (sugg) => sugg.name === newSuggestion.name
        );
        if (
          filter.length === 0 &&
          question.type !== QuestionType.PROJECT_TABLE
        ) {
          SUGGESTIONS.push(newSuggestion);
          nextId++;
        }
        /* if(question.settings.multiSelect){
           const spreadSuggestion = {
             id: nextId,
             type: TAG_TYPE.EXPRESSION,
             table: TABLE.JOB_TYPE,
             name: `EXPAND(${question.title} #${question.ID} - ${question.job})`,
             field: `${question.ID}`,
             _id: `${question._id}`,
             expression: EXPRESSION.EXPAND,
             root: question.root,
           };
           SUGGESTIONS.push(spreadSuggestion);
           nextId++;
         }*/
        //split question type project table into column suggestions
        if (question.type === QuestionType.PROJECT_TABLE) {
          const tableType = question.settings.projectTableType;
          const projectField = projectFields.find(
            (project) => project.id === tableType
          );
          const filter =
            question.settings && question.settings.filter
              ? question.settings.filter.map((col) => col.label)
              : null;
          const tableColumns =
            projectField && projectField.table && projectField.table[0]
              ? Object.keys(projectField.table[0])
              : [];
          const columnHeaders = filter ? filter : tableColumns;

          columnHeaders.forEach((columnHeader) => {
            const columnSuggestion = {
              id: nextId,
              type: TAG_TYPE.PROJECT_TABLE,
              table: TABLE.JOB_TYPE,
              target: columnHeader,
              name: `${question.title} #${question.ID} - ${columnHeader}`,
              field: `${question.ID}`,
              _id: `${question._id}`,
              root: question.root,
            };
            SUGGESTIONS.push(columnSuggestion);
            nextId++;
          });
        }
        if (question.type === QuestionType.DATE_TIME) {
          const commonExpr = {
            [EXPRESSION.MONTH_DAY]: `${i18n.t("default:_MONTH")} / ${i18n.t(
              "default:_DAY"
            )}`,
            [EXPRESSION.DAY_MONTH]: `${i18n.t("default:_DAY")} / ${i18n.t(
              "default:_MONTH"
            )}`,
            [EXPRESSION.YEAR]: `${i18n.t("default:_YEAR")}`,
            [EXPRESSION.DATE]: `${i18n.t("default:_DATE")}`,
          };
          const expressionMap = {
            ...commonExpr,
            [EXPRESSION.TIME]: `${i18n.t("default:_TIME")}`,
            [EXPRESSION.HOUR]: `${i18n.t("default:_HOUR")}`,
            [EXPRESSION.MINUTES]: `${i18n.t("default:_MINUTES")}`,
          };
          const timeExpr = {
            [EXPRESSION.HOUR]: `${i18n.t("default:_HOUR")}`,
            [EXPRESSION.MINUTES]: `${i18n.t("default:_MINUTES")}`,
          };
          const rangeExprMap = {
            [EXPRESSION.TIME_FROM]: `${i18n.t("default:_TIME")} ${i18n.t(
              "default:_FROM"
            )}`,
            [EXPRESSION.TIME_TO]: `${i18n.t("default:_TIME")} ${i18n.t(
              "default:_TO"
            )}`,
            [EXPRESSION.HOUR_FROM]: `${i18n.t("default:_HOUR")} ${i18n.t(
              "default:_FROM"
            )}`,
            [EXPRESSION.HOUR_TO]: `${i18n.t("default:_HOUR")} ${i18n.t(
              "default:_TO"
            )}`,
            [EXPRESSION.MINUTES_FROM]: `${i18n.t("default:_MINUTES")} ${i18n.t(
              "default:_FROM"
            )}`,
            [EXPRESSION.MINUTES_TO]: `${i18n.t("default:_MINUTES")} ${i18n.t(
              "default:_TO"
            )}`,
            [EXPRESSION.LENGHT]: `${i18n.t("default:_LENGTH")}`,
            [EXPRESSION.DATE_TIME_FROM]: `${i18n.t(
              "default:_QUESTION_TYPE_DATE_TIME"
            )} ${i18n.t("default:_FROM")}`,
            [EXPRESSION.DATE_TIME_TO]: `${i18n.t(
              "default:_QUESTION_TYPE_DATE_TIME"
            )} ${i18n.t("default:_TO")}`,
            [EXPRESSION.DATE_FROM]: `${i18n.t("default:_DATE")} ${i18n.t(
              "default:_FROM"
            )}`,
            [EXPRESSION.DATE_TO]: `${i18n.t("default:_DATE")} ${i18n.t(
              "default:_TO"
            )}`,
            [EXPRESSION.MONTH_DAY_FROM]: `${i18n.t(
              "default:_MONTH"
            )} / ${i18n.t("default:_DAY")} ${i18n.t("default:_FROM")}`,
            [EXPRESSION.MONTH_DAY_TO]: `${i18n.t("default:_MONTH")} / ${i18n.t(
              "default:_DAY"
            )} 
            ${i18n.t("default:_TO")}`,
            [EXPRESSION.DAY_MONTH_FROM]: `${i18n.t("default:_DAY")} / ${i18n.t(
              "default:_MONTH"
            )} 
            ${i18n.t("default:_FROM")}`,
            [EXPRESSION.DAY_MONTH_TO]: `${i18n.t("default:_DAY")} / ${i18n.t(
              "default:_MONTH"
            )} 
            ${i18n.t("default:_TO")}`,
            [EXPRESSION.YEAR_FROM]: `${i18n.t("default:_YEAR")} ${i18n.t(
              "default:_FROM"
            )}`,
            [EXPRESSION.YEAR_TO]: `${i18n.t("default:_YEAR")} ${i18n.t(
              "default:_TO"
            )}`,
          };
          let expressions;
          if (question.settings.isRange || question.settings.isPunchClock) {
            expressions = { ...rangeExprMap };
          } else {
            expressions = { ...expressionMap };
          }
          if (question.settings.type === DateType.TIME) {
            expressions = { ...timeExpr };
          }
          if (question.settings.type === DateType.DATE) {
            expressions = { ...commonExpr };
          }
          if (question.settings.type === DateType.DATE_TIME || question.settings.type === DateType.DATE_PLUS_TIME) {
            expressions = { ...expressionMap };
          }
          Object.keys(expressions).forEach((exprKey) => {
            const label = expressions[exprKey];
            const exprSuggestion = {
              id: nextId,
              type: TAG_TYPE.EXPRESSION,
              table: TABLE.JOB_TYPE,
              name: `${label}(${question.title} #${question.ID} - ${jobType.title})`,
              field: `${question.ID}`,
              expression: exprKey,
              _id: `${question._id}`,
              root: question.root,
            };
            const fltr = SUGGESTIONS.filter(
              (sugg) => sugg.name === exprSuggestion.name
            );
            if (fltr.length === 0) {
              SUGGESTIONS.push(exprSuggestion);
            }
            nextId++;
          });
        }
        if (question.settings.signature) {
          const signatureSuggestion = {
            id: nextId,
            type: TAG_TYPE.FIELD,
            table: TABLE.JOB_TYPE,
            isSignature: true,
            name: `Signature - (${question.title} #${question.ID} - ${jobType.title})`,
            field: `${question.ID}`,
            _id: `${question._id}`,
            root: question.root,
          };
          SUGGESTIONS.push(signatureSuggestion);
          nextId++;
        }
      });
    }
    if (projectFields && this.props.apps.enabled.includes(APP_PROJECTS)) {
      projectFields.forEach((field) => {
        if (field.type === PROJECT_FIELD_TYPE.STATUS_LIST) {
          return;
        }
        const projectSuggestion = {
          id: nextId,
          type: TAG_TYPE.FIELD,
          table: TABLE.PROJECTS,
          name: `${field.label} - ${i18n.t("default:_PROJECT")}`,
          field: field.id,
          fieldType: field.type,
        };
        const fltr = SUGGESTIONS.filter(
          (sugg) => sugg.name === projectSuggestion.name
        );
        if (fltr.length === 0) {
          SUGGESTIONS.push(projectSuggestion);
        }
        nextId++;
      });
    }

    const roots = SUGGESTIONS.filter((sugg) => (sugg.root ? true : false))
      .map((sugg) => {
        return {
          _id: sugg.root,
          type: TAG_TYPE.FIELD,
          name: "",
          table: TABLE.JOB_TYPE,
          field: `${sugg.field}`,
          root: null,
        };
      })
      .filter(function onlyUnique(value, index, self) {
        return self.map((r) => r._id).indexOf(value._id) === index;
      })
      .filter((root) => SUGGESTIONS.map((s) => s._id).indexOf(root._id) === -1);
    SUGGESTIONS = [...SUGGESTIONS, ...roots].map((sugg, index) => {
      const { _id } = sugg;
      const relatives = SUGGESTIONS.filter(
        (s) =>
          s.root !== null &&
          typeof s.root !== "undefined" &&
          s.root === _id &&
          s.type === TAG_TYPE.FIELD
      );
      let newName = sugg.name;
      if (relatives.length > 0) {
        relatives.forEach((r) => {
          const { name } = r;
          newName = newName.length > 0 ? newName + ", " + name : name;
        });
      }
      return {
        ...sugg,
        name: newName,
      };
    });

    SUGGESTIONS = SUGGESTIONS.filter((sugg) => {
      if (sugg.root) {
        const rootPresent = SUGGESTIONS.filter(
          (suggRoot) => suggRoot._id === sugg.root
        );
        if (rootPresent.length > 1) {
          return false;
        }
      }
      return true;
    }).filter(
      (sugg) =>
        sugg.fieldType !== PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS &&
        sugg.fieldType !== PROJECT_FIELD_TYPE.TABLE
    );

    if (this.state.settings["projectReport"] === true) {
      SUGGESTIONS = SUGGESTIONS.filter(
        ({ table }) =>
          table !== TABLE.JOB_TYPE &&
          table !== TABLE.JOBS &&
          table !== TABLE.USERS
      );
    }
    if (!this.props.apps.enabled.includes(APP_PROJECTS)) {
      SUGGESTIONS = SUGGESTIONS.filter(({ table }) => table !== TABLE.PROJECTS);
    }
    const cols = [...this.state.columns];
    const newCols = cols;
    const templateParams = {
      instance: "report",
      show_generic: true,
    };
    const response = await getTemplatesList(templateParams);
    const templatesList = response && response.success ? response.data : [];
    this.setState({
      loading: false,
      columns: newCols,
      templates: templatesList,
    });
  }

  async checkReportTemplatesToggle() {
    const response = await ToggleService._isToggleActive(
      "report_templates",
      this.props.settings.toggles
    );
    this.setState({ withReportTemplates: response });
  }

  async componentDidMount() {
    this.checkReportTemplatesToggle();
    await this.getReportBuilderData(this.props);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.filters) === JSON.stringify(this.props.filters)
    ) {
      return;
    }
    await this.getReportBuilderData(nextProps);
  }

  handleColumnDelete(index) {
    if (!index && index !== 0) {
      return;
    }
    const tags = this.state.columns.slice(0);
    tags.splice(index, 1);
    this.setState({ columns: tags });
  }

  handleColumnAddition(tag) {
    const updatedTag = { ...tag };
    updatedTag.order = this.state.columns.length;
    const tags = [].concat(this.state.columns, updatedTag);
    const query = this.state.columns.filter(column => column.table === TABLE.JOB_TYPE)
    const nextState = {
      columns: tags,
    }
    if (query.length > 0) {
      const { settings } = this.state;
      settings[SETTING_NAME.PROJECT_REPORT] = false;
      nextState.settings = settings;
    }
    this.setState(nextState);
  }

  changeTable(table) {
    this.setState({
      table: table,
    });
  }

  changeFilters() {
    this.props.changeFilter();
  }

  suggestionsFilter(item, query) {
    if (query.length === 0) return true;
    const score = stringScore(item.name, query);
    return score > 0.1;
  }

  async createReport() {
    this.setState({
      loading: true,
    });
    const { table, columns, settings, isTemplate, templateName } = this.state;
    const { id, success } = await createReport({
      type: table,
      filters: this.props.filters,
      columns,
      settings,
    });

    if (isTemplate) {
      await createTemplate({
        name: templateName,
        report: id,
        show_generic: true,
      });
    }
    if (success) {
      this.props.dispatch(GridTable.setShowReportsBuilder(false));
      this.props.history.push(`/reports/${id}`);
    }
  }

  async toggleSetting(settingName) {
    const { settings } = this.state;
    settings[settingName] = !settings[settingName] ? true : false;
    if (settingName === "projectReport") {
      await this.getReportBuilderData(this.props);
    }
    this.setState({
      settings: settings,
    });
  }

  reset() {
    this.setState({
      table: this.props.defaultSelectedType || REPORT_TYPE.JOBS,
      columns: [],
      settings: {
        thumbnails: false,
        links: false,
        page_image: false,
        question_status: false,
        job_status: false,
        attachments: false
      },
    });
  }

  isSettingChecked(settingName, customSettings) {
    let settings = this.state.settings;
    if (customSettings) {
      settings = customSettings;
    }
    return settingName in settings && settings[settingName] === true;
  }

  shouldComponentUpdate() {
    return true;
  }

  createNew() {
    this.setState({
      isStart: false,
    });
  }

  fromTemplate() {
    this.props.dispatch(GridTable.setGridTitle("Report's Template"));
    this.setState({
      showTemplateList: true,
      isStart: false,
    });
  }

  renderChooseReportType() {
    const { loading, templates, withReportTemplates } = this.state;
    const isTemplatesDisabled = templates.length === 0;
    return (
      <div className="ReportsBuilder">
        <BlockUi tag="div" blocking={loading} style={{ height: "100%" }}>
          <div className="container-fluid" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div
                className={withReportTemplates ? "col-md-6" : "col-md-12"}
                style={{ height: "100%" }}
              >
                <div
                  className="ReportButtonWithIcon"
                  onClick={() => this.createNew()}
                >
                  <i className="mdi mdi-plus" />
                  <i className="icon-line-chart" />
                  <span>{i18n.t("default:_CREATE_NEW")}</span>
                </div>
              </div>
              {withReportTemplates && (
                <div className="col-md-6" style={{ height: "100%" }}>
                  <div
                    className={
                      isTemplatesDisabled
                        ? "ReportButtonWithIcon disabled"
                        : "ReportButtonWithIcon"
                    }
                    onClick={() =>
                      !isTemplatesDisabled ? this.fromTemplate() : null
                    }
                  >
                    <i className="mdi mdi-view-list" />
                    <i className="icon-line-chart" />
                    <span>{i18n.t("default:_FROM_TEMPLATE")}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </BlockUi>
      </div>
    );
  }

  cloneReport(reportId) {
    this.setState(
      {
        loading: true,
      },
      async () => {
        const { id, success } = await cloneReport({
          id: reportId,
          filters: this.props.filters,
        });
        if (success) {
          this.props.history.push(`/reports/${id}`);
        }
      }
    );
  }

  proceedToReportName(template) {
    this.props.dispatch(GridTable.setGridTitle(null));
    this.setState({
      showTemplateList: false,
      showNameSelect: true,
      selectedTemplate: template,
    });
  }

  async createReportFromTemplate(name) {
    const { selectedTemplate } = this.state;
    const params = {
      id: selectedTemplate.uniqueId,
      instance: "report",
      filters: this.props.filters,
      name,
    };
    const response = await createFromTemplate(params);
    if (response) {
      const newReportId = response._id;
      this.props.dispatch(GridTable.setShowReportsBuilder(false));
      this.props.history.push(`/reports/${newReportId}`);
    }
  }

  renderTemplateList() {
    const { templates } = this.state;
    return (
      <div
        className="ReportsBuilder"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_TEMPLATE_WILL_BE_DELETED") + "!"}
          show={this.state.showConfirmDelete}
          onConfirm={() => this.onDeleteConfirm()}
          onClose={() => this.onDeleteCancel()}
        />
        <Grid container spacing={1}>
          {templates.map((t, index) => {
            return (
              <Grid key={index} item spacing={3}>
                <ReportTemplateItem
                  name={t.name}
                  canDelete={t.canBeDeleted}
                  onDelete={() => this._deleteTemplate(index)}
                  onSelect={() => this.proceedToReportName(t)}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }

  async updateEditedTemplate() {
    const { editTemplate, editTemplateName, templates } = this.state;
    const template = templates[editTemplate];
    if (template) {
      try {
        const response = await updateTemplate({
          id: template._id,
          name: editTemplateName,
        });
        if (response.success) {
          templates[editTemplate].name = editTemplateName;
          this.setState({
            editTemplate: null,
            editTemplateName: "",
            templates,
          });
        } else {
          throw new Error("Failed to update template");
        }
      } catch (e) {
        this.setState({
          editTemplate: null,
          editTemplateName: "",
        });
      }
    }
  }

  isEditedCurrently(index) {
    return this.state.editTemplate === index;
  }

  editTemplateName(index) {
    const { templates } = this.state;
    const template = templates[index];
    this.setState({
      editTemplate: index,
      editTemplateName: template.name,
    });
  }

  _deleteTemplate(index) {
    this.setState({
      showConfirmDelete: true,
      deleteTemplateIndex: index,
    });
  }

  async onDeleteConfirm() {
    const { deleteTemplateIndex, templates } = this.state;
    if (deleteTemplateIndex !== null) {
      const template = templates[deleteTemplateIndex];
      try {
        const params = {
          id: template.uniqueId,
          instance: "report",
          show_generic: true,
        };

        const response = await deleteTemplate(params, true);

        if (response && response.success) {
          this.setState({
            templates: this.state.templates.filter(
              (t) => t._id.toString() !== template._id.toString()
            ),
            deleteTemplateIndex: null,
            showConfirmDelete: false,
          });
        }
      } catch (e) {
        console.log(e);
        this.onDeleteCancel();
      }
    }
  }

  onDeleteCancel() {
    this.setState({
      showConfirmDelete: false,
      deleteTemplateIndex: null,
    });
  }

  toggleTemplate() {
    this.setState({
      isTemplate: this.state.isTemplate ? false : true,
    });
  }

  setTemplateName(val) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.setState(
        {
          templateName: val,
        },
        () => clearTimeout(this.timeout)
      );
    }, 500);
  }

  isProjectReportDisabled() {
    const query = this.state.columns.filter(column => column.table === TABLE.JOB_TYPE)
    return query.length > 0;
  }

  setAllFields() {
    const fields = [];
    SUGGESTIONS.filter(
      (sugg) =>
        this.state.columns.indexOf(sugg) === -1 && sugg.type === TAG_TYPE.FIELD
    ).forEach((sugg) => {
      fields.push(sugg);
    });
    this.setState({
      columns: fields,
    });
  }

  onSortEnd({ newIndex, oldIndex }) {
    const { columns } = this.state;
    let updatedColumns = [...columns];
    updatedColumns[oldIndex].order = newIndex;
    updatedColumns[newIndex].order = oldIndex;
    updatedColumns.splice(newIndex, 0, updatedColumns.splice(oldIndex, 1)[0]);

    this.setState({
      columns: updatedColumns,
    });
  }

  render() {
    const {
      table,
      loading,
      columns,
      settings,
      isStart,
      showTemplateList,
      isTemplate,
      templateName,
      showNameSelect,
      selectedTemplate,
    } = this.state;
    if (isStart) {
      return this.renderChooseReportType();
    }
    if (showTemplateList) {
      return this.renderTemplateList();
    }
    if (showNameSelect) {
      return (
        <ReportName
          createReportFromTemplate={this.createReportFromTemplate.bind(this)}
          template={selectedTemplate}
        />
      );
    }
    return (
      <div className="ReportsBuilder">
        <BlockUi tag="div" blocking={loading}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <label>{i18n.t("default:_TABLE")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <select
                  disabled={true}
                  value={table}
                  className="form-control"
                  onChange={(e) => this.changeTable(e.target.value)}
                >
                  <option value={REPORT_TYPE.JOBS}>
                    {i18n.t("default:_JOBS")}
                  </option>
                  <option value={REPORT_TYPE.PROJECTS}>
                    {i18n.t("default:_PROJECTS")}
                  </option>
                  <option value={REPORT_TYPE.USERS}>
                    {i18n.t("default:_USERS")}
                  </option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>{i18n.t("default:_STRUCTURE")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ReportTags
                  onSortEnd={this.onSortEnd.bind(this)}
                  columns={columns}
                  suggestions={SUGGESTIONS}
                  suggestionsFilter={this.suggestionsFilter}
                  handleDelete={this.handleColumnDelete.bind(this)}
                  handleAddition={this.handleColumnAddition.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(SETTING_NAME.QUESTION_STATUS)}
                    onChange={() =>
                      this.toggleSetting(SETTING_NAME.QUESTION_STATUS)
                    }
                    label={i18n.t("default:_SHOW_QUESTION_STATUS")}
                  />
                  <span className="tooltiptext">
                    {i18n.t('default:_TOOLTIP_REPORT_SETTINGS_QUESTION_STATUS')}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(SETTING_NAME.JOB_STATUS)}
                    onChange={() => this.toggleSetting(SETTING_NAME.JOB_STATUS)}
                    label={i18n.t("default:_SHOW_JOB_STATUS")}
                  />
                  <span className="tooltiptext"> {i18n.t('default:_TOOLTIP_REPORT_SETTINGS_JOB_STATUS')}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(SETTING_NAME.SHOW_COMMENT)}
                    onChange={() => this.toggleSetting(SETTING_NAME.SHOW_COMMENT)}
                    label={i18n.t("default:_SHOW_COMMENT_AS_TEXT")}
                  />
                  <span className="tooltiptext">{i18n.t('default:_TOOLTIP_REPORT_SETTINGS_SHOW_COMMENT')}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(SETTING_NAME.RTL)}
                    onChange={() => this.toggleSetting(SETTING_NAME.RTL)}
                    label={i18n.t("default:_INVERSE_COLUMN_ORDER")}
                  />
                  <span className="tooltiptext">{i18n.t('default:_TOOLTIP_REPORT_SETTINGS_INVERSE_COLUMN_ORDER')}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(
                      SETTING_NAME.LIMIT_HISTORICAL_DATA
                    )}
                    onChange={() =>
                      this.toggleSetting(SETTING_NAME.LIMIT_HISTORICAL_DATA)
                    }
                    label={i18n.t("default:_LIMIT_HISTORICAL_DATA")}
                  />
                  <span className="tooltiptext">{i18n.t('default:_TOOLTIP_REPORT_SETTINGS_LIMIT_HISTORICAL_DATA')}</span>
                </div>
              </div>
              {this.props.apps.enabled.includes(APP_PROJECTS) && (
                <div className="col-md-6">
                  <div className="my-tooltip">
                    <Switch
                      checked={this.isSettingChecked(SETTING_NAME.PROJECT_REPORT)}
                      onChange={() =>
                        this.toggleSetting(SETTING_NAME.PROJECT_REPORT)
                      }
                      disabled={this.isProjectReportDisabled()}
                      label={i18n.t("default:_PROJECT_REPORT")}
                    />
                    <span className="tooltiptext">{i18n.t('default:_TOOLTIP_REPORT_SETTINGS_PROJECT_REPORT')}</span>
                  </div>
                </div>
              )}

              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="my-tooltip">
                  <Switch
                    checked={this.isSettingChecked(SETTING_NAME.ATTACHMENTS)}
                    onChange={() =>
                      this.toggleSetting(SETTING_NAME.ATTACHMENTS)
                    }
                    label={i18n.t("default:_ADD_ATTACHMENTS")}
                  />
                  <span className="tooltiptext">{i18n.t('default:_TOOLTIP_REPORT_SETTINGS_ATTACHMENTS')}</span>
                </div>
              </div>
            </div>
            <div className="bottom-offset" />
            {!this.props.report && (
              <div className="row">
                <div className="col-md-3 text-center">
                  <Button
                    onClick={() => this.reset()}
                    variant="contained"
                    color="secondary"
                  >
                    {i18n.t("default:_RESET")}
                  </Button>
                </div>
                <div className="col-md-3 text-center">
                  <Button
                    onClick={() => this.setAllFields()}
                    variant="contained"
                    className={this.props.classes.pinkButton}
                  >
                    {i18n.t("default:_ALL_FIELDS")}
                  </Button>
                </div>
                <div className="col-md-3 text-center">
                  <Button
                    onClick={() => this.changeFilters()}
                    variant="contained"
                    color="primary"
                  >
                    {i18n.t("default:_CHANGE_ACTIVE_FILTERS")}
                  </Button>
                </div>
                <div className="col-md-3 text-center">
                  <Button
                    disabled={columns.length === 0}
                    onClick={() => this.createReport()}
                    variant="contained"
                    className={this.props.classes.greenButton}
                  >
                    {i18n.t("default:_CREATE_REPORT")}
                  </Button>
                </div>
              </div>
            )}
            {this.props.report && (
              <div className="row">
                <div className="col-12 text-center">
                  <Button
                    disabled={columns.length === 0}
                    onClick={() =>
                      this.props.updateReport({
                        columns,
                        settings,
                        isTemplate,
                        templateName,
                      })
                    }
                    variant="contained"
                    className={this.props.classes.greenButton}
                  >
                    {i18n.t("default:_UPDATE_REPORT")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </BlockUi>
      </div>
    );
  }
}

export default connectWithStore(withRouter(withStyles(styles)(ReportBuilder)), [
  "settings",
  "grid_table",
  "apps",
]);
