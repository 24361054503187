import React from "react";
import { connectWithStore } from "../../../services/redux";
import UserFieldWrapper from "./field_wrapper";
import DateService from "../../../services/date";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    "& input": {
      cursor: "pointer",
    },
  },
}));

function UserDateField({ user, value, changeUserProp, field }) {
  const classes = useStyles();

  return (
    <UserFieldWrapper label={field.name}>
      <DatePicker
        format={DateService.SYSTEM_DAY_MONTH_YEAR_FORMAT}
        value={value ? value : null}
        onChange={(newVal) => changeUserProp(field.value, newVal.toString())}
        animateYearScrolling
        clearable={true}
        InputProps={{
          disableUnderline: value,
          className: classes.input,
        }}
      />
    </UserFieldWrapper>
  );
}

export default connectWithStore(UserDateField, ["user"]);
