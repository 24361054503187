import React, { useCallback, useEffect, useState } from "react";
import { connectWithStore } from "../../../services/redux";
import WizardButtons from "../wizard_buttons";
import i18n from "../../../i18n";
import UserSelectModal from "../../../layout/partials/modals/user_select_modal";
import FullScreenModal from "../../../layout/partials/modals/full_screen_modal";
import QuestionRenderer from "../../question/question_renderer";
import { viewJob } from "../../../services/api/jobs";
import { WizardJob, Wizard } from "../../../redux/actions";
import { stringify } from "../../../services/helpers";
import { WIZARD_TYPE_JOB } from "../constants";
import { STEP_SELECT_PREV_JOB, STEP_SELECT_JOB_SKILLS } from "../steps";
import { isMobileDevice } from "../../../services/mobile";
import JobCreatedInfoRow from "./job_created_info_row";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { assignMultiple } from "../../../services/api/jobs";
import { APP_SKILLS, APP_PREVIOUS_JOB } from "../../../services/app";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    position: "absolute",
    top: "-15px",
    color: "green",
    fontSize: "2.5rem",
  },
}));

function JobCreated({
  goToStep,
  wizardJob,
  dismiss,
  dispatch,
  isActive,
  apps,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { mainQuestions, answersMain, id, users, prevJob, skills } = wizardJob;

  const [currentJob, setCurrentJob] = useState(null);

  const [showAnswer, setShowAnswer] = useState(false);

  const [selected, setSelected] = useState([]);

  const [showUsers, setShowUsers] = useState(false);

  const onUsersChange = (selectedUsers) => {
    dispatch(WizardJob.assignUsers({ users: selectedUsers }));
    setShowUsers(false);
  };

  const getMainAnswers = useCallback(
    (job) => {
      if (!job.answers) return [];
      const result = mainQuestions
        .map((questionInfo) => {
          const currentAnswer = job.answers.filter(
            (a) =>
              a.question &&
              a.question.ID.split("_")[0] === stringify(questionInfo.value)
          );
          if (currentAnswer.length > 0) {
            return currentAnswer[0];
          }
          return null;
        })
        .filter((v) => v !== null);
      return result;
    },
    [mainQuestions]
  );

  const getMainQuestions = useCallback(
    (job) => {
      if (!job.questions) return [];
      const result = mainQuestions
        .map((questionInfo) => {
          const currentQuestion = job.questions.filter(
            (q) => stringify(q.ID) === stringify(questionInfo.value)
          );
          if (currentQuestion.length > 0) {
            return currentQuestion[0];
          }
          return null;
        })
        .filter((v) => v !== null);
      return result;
    },
    [mainQuestions]
  );

  const getSelectedQuestions = useCallback(
    (job) => {
      const questions = getMainQuestions(job);
      const selectedQuestions = questions.map((question) => {
        const newQuestions = Object.assign(question, {
          answer: null,
        });
        const filter = job.answers.filter((a) => {
          return a.question.ID.split("_")[0] === stringify(question.ID);
        });
        const answer =
          filter.length > 0 ? filter[filter.length - 1] : { value: "" };
        if (answer) {
          newQuestions.answer = answer;
        }
        return newQuestions;
      });

      return selectedQuestions;
    },
    [getMainQuestions]
  );

  const refreshJob = useCallback(async () => {
    if (!id) {
      return;
    }
    const getJob = async () => {
      const job = await viewJob(id);
      setCurrentJob(job);
      const mainAnswers = getMainAnswers(job);
      dispatch(WizardJob.answerMainQuestions({ answersMain: mainAnswers }));
      setSelected(getSelectedQuestions(job));
    };
    getJob();
  }, [id, getMainAnswers, dispatch, getSelectedQuestions]);

  useEffect(() => {
    if (isActive)
      dispatch(
        Wizard.setTitle(
          i18n.t("default:_NEW_ACTIVITY_CREATED", {
            job: i18n.t("default:_JOB"),
          })
        )
      );
    setTimeout(() => {
      refreshJob();
    }, 1000);
  }, [refreshJob, dispatch, isActive]);

  const assignUsers = useCallback(async () => {
    if (users.length > 0 && id) {
      await assignMultiple({
        users: users.map(({ value }) => value),
        jobs: [id],
      });
    }
  }, [id, users]);

  return (
    <div
      className="row fullHeight"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
      }}
    >
      <div className="WhichEntity JobCreated">
        <div className="InfoRowContainer">
          {/* <LeftPanel /> */}
          <JobCreatedInfoRow
            goToStep={goToStep}
            disabledClick={true}
            //withBorder={true}
            //primary={theme.palette.primary.main}
          />
        </div>
        {isMobileDevice() && (
          <div className="DoMore">{i18n.t("default:_DO_MORE")}</div>
        )}
        <div className="OptionBoxesContainer">
          {currentJob && (
            <FullScreenModal show={showAnswer}>
              <QuestionRenderer
                job={currentJob}
                jobType={currentJob.jobType}
                questions={selected}
                done={() => {
                  setShowAnswer(false);
                  refreshJob();
                }}
                update={() => refreshJob()}
                close={() => setShowAnswer(false)}
              />
            </FullScreenModal>
          )}
          <div
            onClick={() => setShowAnswer(true)}
            className="option-box"
            style={{
              border:
                answersMain &&
                answersMain.length > 0 &&
                `1px solid ${theme.palette.primary.main}`,
            }}
          >
            {(!answersMain || answersMain.length === 0) && (
              <div className="option-box-content">
                <i className="mdi h1  d-block mdi-format-list-checkbox" />
                <span className="h5">
                  {i18n.t("default:_ANSWER_MAIN_QUESTIONS")}
                </span>
              </div>
            )}
            {answersMain && answersMain.length > 0 && (
              <>
                {!isMobileDevice() && (
                  <CheckCircleOutlineIcon className={classes.checkIcon} />
                )}
                <div className="option-box-content">
                  <i className="mdi h1 d-block mdi-format-list-checkbox leading-colour" />
                  <span className="h5">
                    {answersMain.length} {i18n.t("default:_OF")}{" "}
                    {mainQuestions.length}
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            className="option-box"
            style={{
              border:
                users &&
                users.length > 0 &&
                `1px solid ${theme.palette.primary.main}`,
            }}
          >
            <UserSelectModal
              show={showUsers}
              onChange={(users) => onUsersChange(users)}
              close={() => setShowUsers(false)}
              includeMe={true}
            />
            <div onClick={() => setShowUsers(true)} className="option-box-grid">
              {(!users || users.length === 0) && (
                <div className="option-box-content">
                  <i className="mdi h1  d-block mdi-account-group-outline" />
                  <span className="h5">
                    {i18n.t("default:_ASSIGN_USERS", {
                      users: i18n.t("default:_USERS"),
                    })}
                  </span>
                </div>
              )}
              {users && users.length > 0 && (
                <div className="option-box-content">
                  {!isMobileDevice() && (
                    <CheckCircleOutlineIcon className={classes.checkIcon} />
                  )}
                  <i className="mdi h1 d-block mdi-account-group leading-colour" />
                  <span className="h5">
                    {users.length} {i18n.t("default:_ASSIGNED")}
                  </span>
                </div>
              )}
            </div>
          </div>
          {apps.enabled.includes(APP_PREVIOUS_JOB) && (
            <div
              className="option-box"
              onClick={() => goToStep(STEP_SELECT_PREV_JOB)}
              style={{
                border: prevJob && `1px solid ${theme.palette.primary.main}`,
              }}
            >
              {prevJob && !isMobileDevice() && (
                <CheckCircleOutlineIcon className={classes.checkIcon} />
              )}
              <div className="option-box-content">
                <i className="mdi h1  d-block mdi-briefcase" />
                <span className="h5">
                  {i18n.t(`default:_SELECT_PREVIOUS_JOB`, {
                    jobWord: i18n.t("default:_JOBS"),
                  })}
                </span>
              </div>
            </div>
          )}
          {apps.enabled.includes(APP_SKILLS) && (
            <div
              className="option-box"
              onClick={() => goToStep(STEP_SELECT_JOB_SKILLS)}
              style={{
                border:
                  skills.length > 0 &&
                  `1px solid ${theme.palette.primary.main}`,
              }}
            >
              {skills.length > 0 && !isMobileDevice() && (
                <CheckCircleOutlineIcon className={classes.checkIcon} />
              )}
              <div className="option-box-content">
                <i className="mdi h1  d-block mdi-shield" />
                <span className="h5">{i18n.t(`default:_SELECT_SKILLS`)}</span>
              </div>
            </div>
          )}
        </div>
        <WizardButtons
          withMarginRight={isMobileDevice() ? true : false}
          notSticky={isMobileDevice() ? true : false}
          actions={[
            {
              label: i18n.t("default:_FINISH"),
              onClick: () => {
                if (users) {
                  assignUsers();
                }
                dispatch(Wizard.setType(WIZARD_TYPE_JOB));
                dismiss();
              },
              variant: "black",
            },
          ]}
        />
      </div>
    </div>
  );
}

export default connectWithStore(JobCreated, ["wizardJob", "apps"]);
