import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { IconPickerItem } from "react-fa-icon-picker";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: "1.1rem",
    textAlign: "start",
  },
  description: {
    fontSize: "0.9rem",
    textAlign: "start",
  },
}));

function TiledListItem({ item }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container alignItems="center" justifyContent="center" item xs={2}>
        {item.icon && (
          <IconPickerItem icon={item.icon} size={18} color="#000" />
        )}
      </Grid>
      <Grid container direction="column" item xs={10}>
        <Typography className={classes.value}>
          {item && item.value ? item.value : item}
        </Typography>
        <Typography className={classes.description}>
          {item && item.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TiledListItem;
