import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  muiIcon: {
    fontSize: "2rem",
  },
});

export default function withMuiIcon(Component) {
  return function () {
    const classes = useStyles();
    return <Component className={classes.muiIcon} />;
  };
}
