/* eslint-disable no-use-before-define */
import React, { useState, useCallback, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { getFileTarget } from "../../services/api/file_upload";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { isMobileDevice } from "../../services/mobile";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  labelSpan: {
    paddingLeft: "20px",
    overflowWrap: "break-word",
  },
  labelSpanSelected: {
    color: "#408FF8",
    paddingLeft: "20px",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

export default function SimpleAutocomplete({
  options,
  multiple,
  onValueChange,
  value,
  fullWidth,
  width,
  label,
  renderAlternativeAvatar,
  withoutAvatar,
  autocompleteId,
  disableClearable,
}) {
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = useState(
    multiple ? [] : ""
  );

  const setValue = useCallback(async () => {
    if (value) {
      setAutocompleteValue(value);
    } else {
      setAutocompleteValue(multiple ? [] : "");
    }
  }, [value, multiple]);

  useEffect(() => {
    setValue();
  }, [setValue]);

  function OptionRow({ option }) {
    return (
      <Grid container justifyContent="space-between">
        {!withoutAvatar && (
          <Grid item xs={2}>
            {option.icon ? (
              <Avatar
                alt="Activity type icon"
                src={getFileTarget(option.icon, "100x100")}
              />
            ) : (
              renderAlternativeAvatar && renderAlternativeAvatar(option)
            )}
          </Grid>
        )}
        <Grid item xs={10}>
          <ListItemText
            className={classes.labelSpan}
            primary={option.title ? option.title : option.label}
            secondary={option.subLabel}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Autocomplete
      id={autocompleteId ? autocompleteId : "simple-autocomplete"}
      disablePortal={isMobileDevice() ? true : false}
      multiple={multiple}
      options={options}
      onChange={onValueChange}
      value={autocompleteValue}
      getOptionLabel={(option) =>
        option.title ? option.title : option.label ? option.label : ""
      }
      renderOption={(option, index) => (
        <OptionRow index={index} option={option} />
      )}
      fullWidth={fullWidth}
      style={{ width: width }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      disableClearable={disableClearable}
    />
  );
}
