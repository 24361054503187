import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connectWithStore } from "../../../services/redux";
import { getStatusProps } from "../../../services/job_status";
import { getFileTarget } from "../../../services/api/file_upload";
import CircleUserList from "../../../components/circle_user_list/circle_user_list";
import DateService from "../../../services/date";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70px",
    marginBottom: "5px",
  },
  status: {
    position: "relative",
    width: "10px",
    padding: "1px 2px",
    fontSize: "13px",
    height: "100%",
  },
  fileImageContainer: {
    height: "100%",
  },
  fileImage: {
    height: "100%",
    width: "100%",
  },
  iconImage: {
    background: "#2E384D",
  },
  nameContainer: {
    paddingLeft: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

function MobileJobRow({ job, settings, status }) {
  const classes = useStyles();
  const { color } = getStatusProps(status, settings);
  return (
    <Grid container className={classes.root}>
      <Grid
        item
        className={classes.status}
        style={{ backgroundColor: color }}
      />
      <Grid item xs={2} className={`grid-table-time`}>
        <span className="jobs-time" style={{ fontSize: "1em" }}>
          <strong>{DateService.getFormattedDate(job.startDate)}</strong>
        </span>
      </Grid>
      <Grid item xs={2} className={classes.fileImageContainer}>
        {job.jobType.icon ? (
          <img
            className={classes.fileImage}
            src={getFileTarget(job.jobType.icon, "100x100")}
            alt="file avatar"
          />
        ) : (
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            className={`${classes.fileImage} ${classes.iconImage}`}
          >
            <i
              style={{ color: "white", fontSize: 30 }}
              className="icon-case_v2"
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        xs={5}
        alignContent="center"
        jusfity="center"
        className={classes.nameContainer}
      >
        {job.project.name}
      </Grid>
      <Grid item xs={2}>
        <CircleUserList
          showAddButton={false}
          users={job.users.map((user) => {
            if (
              job.confirmedUsers &&
              job.confirmedUsers.filter(
                (u) => u.user.toString() === user._id.toString()
              ).length === 1
            ) {
              return {
                ...user,
                greenMark: true,
              };
            }
            return user;
          })}
        />
      </Grid>
    </Grid>
  );
}

export default connectWithStore(MobileJobRow, ["settings"]);
