import React, { Component } from "react";
import QuestionSummary from "../../answer/question_summary";
import "../../../pages/jobs/editor.scss";
import { view } from "../../../services/api/jobs";
import { getFileInfo } from "../../../services/api/file_upload";
import { QaSummaryType, QuestionType } from "../../../services/question";
import PrevNextJobSummary from "./prev_next_job_summary";

export default class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentJob: this.props.job && this.props.job,
      fileInfo: {},
    };
  }

  async componentDidMount() {
    if (this.props.job) {
      const updatedJob = await view(this.props.job._id);
      const fileInfo = {};
      if (updatedJob && updatedJob.answers) {
        for (const answer of updatedJob.answers) {
          if (answer.files && answer.files.length > 0) {
            for (const file of answer.files) {
              fileInfo[file] = await getFileInfo(file);
            }
          }
        }
      }
      this.setState({ currentJob: updatedJob, fileInfo });
    }
  }

  renderQuestionList() {
    const ql = this.props.question.settings.questionList || [];
    return ql.map((question, index) => {
      const answers =
        this.state.currentJob && this.state.currentJob.answers
          ? this.state.currentJob.answers
          : [];
      const qs = this.props.job ? this.props.job.questions : [];
      let currentAnswer = answers.filter(
        (a) => a.question.ID.split("_")[0] === question.id.toString()
      );
      currentAnswer = currentAnswer[currentAnswer.length - 1];
      const currentQuestion = qs.filter(
        (q) => q.ID === parseInt(question.id)
      )[0];
      if (currentQuestion) {
        if (currentQuestion.type === QuestionType.STEP) {
          return (
            <div className="step" key={index}>
              {currentQuestion.title}
            </div>
          );
        }
        return (
          <QuestionSummary
            key={index}
            index={index}
            question={currentQuestion}
            currentAnswer={currentAnswer}
            fileInfo={this.state.fileInfo}
          />
        );
      } else {
        //check if is prev or next job and render each of them
        if (
          question.id === QaSummaryType.PREVIOUS_JOB ||
          question.id === QaSummaryType.NEXT_JOB
        ) {
          return (
            <PrevNextJobSummary
              key={index}
              question={question}
              currentJob={this.props.job}
            />
          );
        }
        return null;
      }
    });
  }

  render() {
    return (
      <div className="SummaryComponent" dir="auto">
        <div className="SummaryComponent-QuestionList">
          {this.renderQuestionList()}
        </div>
      </div>
    );
  }
}
