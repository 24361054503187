import Http from "../http";
import { getRawAnswerValue } from "../question";

export const getAnswerList = async (params) => {
  const path = `/v1/answers`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const submitSurvey = async (params) => {
  const transformedParams = {
    ...params,
    data: params.data.map((row) => {
      const newRow = { ...row };
      newRow.answer.search_value = getRawAnswerValue(newRow.answer.value);
      return newRow;
    }),
  };
  const path = `/v2/answers`;
  try {
    const response = await Http.sharedInstance
      .withAuth()
      .post(path, transformedParams);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getUsersList = async (params) => {
  const path = `/v2/answers/users`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data.data && response.data.data.docs
      ? response.data.data.docs
      : [];
  } catch (e) {
    return [];
  }
};

export const getProjectList = async (params) => {
  const path = `/v1/answers/projects`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: {
        customers: params.filters ? params.filters.map((c) => c.id) : [],
      },
    });
    return response.data && response.data.docs ? response.data.docs : [];
  } catch (e) {
    return [];
  }
};

export const getJobTypeList = async (params) => {
  const path = `/v2/jobtype`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: {
        type: params.filters.map((type) => type.id),
        fromFilters: true,
      },
    });
    return response.data;
  } catch (e) {
    return [];
  }
};
