import Http from "../http";

export const downloadAllFiles = async (params) => {
  const path = `/v1/assets/zip`;

  try {
    const response = await Http.sharedInstance
      .withAuth()
      .get(path, { params, responseType: "blob" });
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "assets.zip";
    link.href = URL.createObjectURL(
      new Blob([response.data], { type: "application/zip" })
    );
    link.click();
    return response.blob();
  } catch (e) {
    return null;
  }
};

export const getFilesList = async (params) => {
  const path = `/v1/assets`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteFiles = async (id) => {
  const path = `/v1/assets/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const replaceFiles = async (params) => {
  const path = `/v1/assets/${params.targetId}/${params.sourceId}`;
  try {
    const response = await Http.sharedInstance.withAuth().patch(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const rotateFile = async ({ id, rotation }) => {
  const path = `/v2/asset/${id}/rotate`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      rotation,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};
