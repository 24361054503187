import React, { Component } from "react";
import { withRouter } from "react-router";
import "./back_button.scss";

class BackButton extends Component {
  goBack() {
    if (this.props.custom && this.props.onBack) {
      return this.props.onBack();
    }
    if (this.props.home) {
      return this.props.history.push("/dashboard");
    }
    return this.props.history.goBack();
  }

  render() {
    return (
      <div className="BackButton">
        <span
          onClick={() => this.goBack()}
          className="btn-back mdi mdi-chevron-left large"
        ></span>
      </div>
    );
  }
}

export default withRouter(BackButton);
