import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  emailChip: {
    marginTop: "10px",
    marginRight: "10px",
    backgroundColor: "#A29BFE",
  },
}));

function EmailSection({
  item,
  toggleSection,
  showSendEmailSection,
  userAutocomplete,
  answers,
  handleChangeSettings,
  job,
  //jobQuestions
}) {
  const classes = useStyles();
  const emailsFromTable = item.settings.emailsFromTable;
  const fieldId = item.settings.projectTableType;
  const displayColumn = emailsFromTable && item.settings.tableDisplayFilter;
  const emailColumn = emailsFromTable && item.settings.tableMailFilter;
  const project = job && job.project;
  const table = project && project[fieldId];
  const emailChips = table && table.map((row) => row[displayColumn]);
  const emails = table && table.map((row) => row[emailColumn]);

  const emailsFromSystemList = item.settings.emailsFromSystemList;
  const usersFilter = emailsFromSystemList && item.settings.filter;
  const filterParams = usersFilter
    ? usersFilter.map((filter) => filter.id)
    : [];
  const { users } = userAutocomplete;
  const userEmails = users
    .filter((user) =>
      filterParams.length > 0 ? filterParams.indexOf(user.role) !== -1 : user
    )
    .map((user) => (user.subLabel ? user.subLabel : user.label));

  return (
    <div className="commentSection emailSignatureCommentSection">
      <div className="commentToolbar" onClick={() => toggleSection()}>
        <div
          className={
            answers[item.ID] && answers[item.ID]["emails"]
              ? "commentSectionCol left hasComment pointer"
              : "commentSectionCol left pointer"
          }
        >
          <i className="mdi mdi-rocket" />
          <span>{i18n.t("default:_SHARE_VIA_EMAIL")}</span>
        </div>
        {!showSendEmailSection && (
          <div className="commentSectionCol right">
            <i className="mdi mdi-chevron-up" />
          </div>
        )}
        {showSendEmailSection && (
          <div className="commentSectionCol right">
            <i className="mdi mdi-chevron-down" />
          </div>
        )}
      </div>
      <div
        className={
          showSendEmailSection ? "commentTextarea visible" : "commentTextarea"
        }
      >
        <textarea
          className="form-control emailTextArea"
          value={answers[item.ID] && answers[item.ID]["emails"]}
          onChange={(e) => handleChangeSettings(e.target.value, "emails")}
        />
        {emailsFromTable && (
          <div
            className="responseOptions"
            style={{ marginBottom: "10px", flexWrap: "wrap" }}
          >
            {emailChips &&
              emailChips.map((option, i) => (
                <Chip
                  key={i}
                  className={classes.emailChip}
                  label={option}
                  onClick={() =>
                    handleChangeSettings(emails[i], "emailChips", item)
                  }
                />
              ))}
          </div>
        )}
        {emailsFromSystemList && (
          <div
            className="responseOptions"
            style={{ marginBottom: "10px", flexWrap: "wrap" }}
          >
            {userEmails &&
              userEmails.map((option, i) => (
                <Chip
                  key={i}
                  className={classes.emailChip}
                  label={option}
                  onClick={() =>
                    handleChangeSettings(option, "emailChips", item)
                  }
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default connectWithStore(EmailSection, [
  "userAutocomplete",
  "jobQuestions",
]);
