import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import i18n from "../../../i18n";
import MeasureAdjuster from "../../measure_adjuster/measure_adjuster";
import { connectWithStore } from "../../../services/redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "10%",
    marginBottom: 10,
    "& > :first-child": {
      marginBottom: theme.spacing(1),
    },
  },
  label: {
    fontSize: "0.8rem",
  },
}));

function StrokeFields({ element, blueprintEditor, widthAsHeight }) {
  const classes = useStyles();
  const { layer /* , elements */ } = blueprintEditor;
  const shape = blueprintEditor.app.find("#" + element.id)[0];
  const [strokeWidth, setStrokeWidth] = useState(1);
  const [strokeHeight, setStrokeHeight] = useState(1);

  useEffect(() => {
    if (!shape) return;
    if (widthAsHeight && shape.attrs && shape.attrs.strokeHeight) {
      setStrokeHeight(shape.attrs.strokeHeight);
    }
    setStrokeWidth(shape.strokeWidth());
  }, [widthAsHeight, shape]);

  const onStrokeWidthChange = (val) => {
    setStrokeWidth(Number(val));
    shape.strokeWidth(val);
    layer.batchDraw();
  };

  const onStrokeHeightChange = (val) => {
    setStrokeHeight(Number(val));
    shape.setAttr("strokeHeight", val);
    layer.batchDraw();
  };

  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Grid container alignItems="center" item xs={5}>
          <Typography className={classes.label}>
            {i18n.t("default:_STROKE_WIDTH")}
          </Typography>
        </Grid>
        <Grid container alignItems="center" item xs={7}>
          <MeasureAdjuster
            value={strokeWidth}
            onValueChange={onStrokeWidthChange}
            onDecrement={() => onStrokeWidthChange(Number(strokeWidth) - 1)}
            onIncrement={() => onStrokeWidthChange(Number(strokeWidth) + 1)}
            name="strokeWidthInput"
            step={1}
          />
        </Grid>
      </Grid>
      {widthAsHeight && (
        <Grid container>
          <Grid container alignItems="center" item xs={5}>
            <Typography className={classes.label}>
              {i18n.t("default:_STROKE_HEIGHT")}
            </Typography>
          </Grid>
          <Grid container alignItems="center" item xs={7}>
            <MeasureAdjuster
              value={strokeHeight}
              onValueChange={onStrokeHeightChange}
              onDecrement={() => onStrokeHeightChange(Number(strokeHeight) - 1)}
              onIncrement={() => onStrokeHeightChange(Number(strokeHeight) + 1)}
              name="strokeWidthInput"
              step={1}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default connectWithStore(StrokeFields, ["blueprintEditor"]);
