import React, { useState, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_DELIVERY_TYPE,
  NOTIFICATION_TYPES,
} from "./notification.models";
import { isMobileDevice } from "../../services/mobile";

const useStyles = makeStyles((theme) => ({
  expanded: {
    margin: "0 !important",
  },
  borderedElement: {
    borderLeft: "1px solid lightgrey",
  },
  checkBox: {
    paddingBottom: "3px",
  },
  label: {
    fontSize: "12px",
    fontWeight: "300",
  },
}));

const NotificationsCategory = ({
  category,
  notifications,
  onChange,
  targetDeliveries,
  settings,
  fromProfile,
}) => {
  const classes = useStyles();
  const [userNotifications, setUserNotifications] = useState(notifications);

  const emitOnChange = useCallback(
    (notis) => {
      if (onChange && Array.isArray(notis)) {
        onChange(notis);
      }
    },
    [onChange]
  );

  const handleChangeMain = (deliveryType) => {
    const targetCategory = NOTIFICATION_CATEGORIES.filter(
      (c) => c.type === category.type
    );
    if (isMainTypeChecked(deliveryType)) {
      if (targetCategory && targetCategory.length === 1) {
        const currentNotis = [...userNotifications];
        for (const noti of targetCategory[0].notifications) {
          const notiName = `${
            targetCategory[0].type
          }_${noti}_${deliveryType.toLowerCase()}`;
          if (currentNotis.indexOf(notiName) !== -1) {
            currentNotis.splice(currentNotis.indexOf(notiName), 1);
          }
        }

        setUserNotifications(currentNotis);
        emitOnChange(currentNotis);
      }

      return true;
    }

    if (targetCategory && targetCategory.length === 1) {
      const currentNotis = [...userNotifications];
      for (const noti of targetCategory[0].notifications) {
        const notiName = `${
          targetCategory[0].type
        }_${noti}_${deliveryType.toLowerCase()}`;
        if (currentNotis.indexOf(notiName) === -1) {
          currentNotis.push(notiName);
        }
      }

      setUserNotifications(currentNotis);
      emitOnChange(currentNotis);
    }
  };

  const isMainTypeChecked = (deliveryType) => {
    const targetCategory = NOTIFICATION_CATEGORIES.filter(
      (c) => c.type === category.type
    );

    if (targetCategory && targetCategory.length === 1) {
      for (const noti of targetCategory[0].notifications) {
        if (
          userNotifications.indexOf(
            `${targetCategory[0].type}_${noti}_${deliveryType.toLowerCase()}`
          ) === -1
        ) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const getDeliveries = () => {
    if (targetDeliveries.length > 0) {
      return NOTIFICATION_DELIVERY_TYPE.filter(
        (d) => targetDeliveries.indexOf(d.type) !== -1
      );
    }

    return NOTIFICATION_DELIVERY_TYPE;
  };

  const isItemCheckboxChecked = (
    categoryType,
    notificationType,
    deliveryType
  ) => {
    return (
      userNotifications.indexOf(
        `${categoryType}_${notificationType}_${deliveryType}`
      ) !== -1
    );
  };

  const pushItemNotification = (
    categoryType,
    notificationType,
    deliveryType
  ) => {
    const noti = `${categoryType}_${notificationType}_${deliveryType}`;
    const currentNotis = [...userNotifications];
    if (currentNotis.indexOf(noti) === -1) {
      currentNotis.push(noti);
    } else {
      currentNotis.splice(currentNotis.indexOf(noti), 1);
    }

    setUserNotifications(currentNotis);
    emitOnChange(currentNotis);
  };

  const getPrimaryColor = () => {
    let mainBorder = {
      border: "inherit",
    };
    if (
      settings &&
      settings.branding &&
      settings.branding.primary &&
      settings.branding.primary.color &&
      settings.branding.primary.color.hex
    ) {
      mainBorder = {
        border: "1px solid " + settings.branding.primary.color.hex,
      };
    }

    return makeStyles(() => ({
      mainBorder,
    }))();
  };

  return (
    <Accordion
      classes={{
        expanded: classes.expanded,
      }}
      className={getPrimaryColor().mainBorder}
    >
      <Grid container>
        <Grid item xs={isMobileDevice() ? 9 : fromProfile ? 4 : 6}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h5>{category.label}</h5>
            {category.subtitle && (
              <span className="ml-2 text-muted">{category.subtitle}</span>
            )}
          </AccordionSummary>
        </Grid>
        {getDeliveries().map((delivery, delIndex) => (
          <Grid
            item
            container
            alignItems="center"
            justify="center"
            className={classes.borderedElement}
            key={"delivery_inner_" + delIndex}
            xs={2}
          >
            <Checkbox
              color="primary"
              checked={isMainTypeChecked(delivery.type)}
              onChange={(event) => handleChangeMain(delivery.type, event)}
            />
          </Grid>
        ))}
      </Grid>
      {category.notifications &&
        category.notifications.length > 0 &&
        category.notifications.map((notificationType, notiIndex) => (
          <Grid container key={notiIndex}>
            <Grid item xs={isMobileDevice() ? 9 : fromProfile ? 4 : 6}>
              <AccordionDetails>
                <p>{NOTIFICATION_TYPES[notificationType]}</p>
              </AccordionDetails>
            </Grid>
            {getDeliveries().map((delivery, catIndex) => (
              <Grid
                key={catIndex}
                item
                container
                alignItems="center"
                justify="center"
                className={classes.borderedElement}
                xs={2}
              >
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  value="bottom"
                  control={
                    <Checkbox
                      className={classes.checkBox}
                      checked={isItemCheckboxChecked(
                        category.type,
                        notificationType,
                        delivery.type.toLowerCase()
                      )}
                      onChange={() =>
                        pushItemNotification(
                          category.type,
                          notificationType,
                          delivery.type.toLowerCase()
                        )
                      }
                      color="primary"
                    />
                  }
                  label={""}
                  labelPlacement="bottom"
                />
              </Grid>
            ))}
          </Grid>
        ))}
    </Accordion>
  );
};

export default NotificationsCategory;
