import Konva from "konva";
import React, { useEffect, useRef } from "react";
import i18n from "../../../../i18n";
import { BlueprintEditor } from "../../../../redux/actions";
import store from "../../../../redux/store";
import { connectWithStore } from "../../../../services/redux";
import { MEASURE_TOOL_HORIZONTAL, MEASURE_TYPE, MOVE_TOOL } from "../constants";
import {
  getRelativePointerPosition,
  getScaledWidth,
  selectOrDeselectMeasure,
  removeTransformers,
} from "../utils";
import { v1 as uuid } from "uuid";

function MeasureToolHorizontal({ blueprintEditor, dispatch, user }) {
  const { app, layer } = blueprintEditor;
  const x2 = useRef(0);
  const y2 = useRef(0);
  const selectionWidthLine = useRef(
    new Konva.Line({
      stroke: "red",
      strokeWidth: 2,
      visible: false,
      points: [0, 0, 0, 0, 0, 0],
      pointerAtBeginning: true,
      pointerAtEnding: true,
      id: "",
      name: "Arrow",
      mtype: MEASURE_TYPE.WIDTH,
      draggable: true,
      hitStrokeWidth: 15,
    })
  );

  let transformer = useRef();

  useEffect(() => {
    if (!app) return;
    app.on("mousedown touchstart", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, measurements } = blueprintEditor;
      // do nothing if measure tool is not selected
      if (!tool || (tool && tool.type !== MEASURE_TOOL_HORIZONTAL)) {
        return;
      }
      if (
        !measurements ||
        (measurements && !measurements.arrows) ||
        (measurements && measurements.arrows && !measurements.arrows.width)
      ) {
        app.draggable(false);
        layer.add(selectionWidthLine.current);
        const relativePos = getRelativePointerPosition(app);
        const widthId = uuid();
        x2.current = relativePos.x;
        y2.current = relativePos.y;
        selectionWidthLine.current.visible(true);
        selectionWidthLine.current.points([
          relativePos.x,
          relativePos.y,
          relativePos.x,
          relativePos.y,
        ]);
        selectionWidthLine.current.zIndex(9999);
        selectionWidthLine.current.id(widthId);
        dispatch(
          BlueprintEditor.addElement({
            id: widthId,
            elementId: `m${uuid()}`,
            user: user.profile,
            type: MEASURE_TYPE.WIDTH,
          })
        );
        dispatch(BlueprintEditor.setIsMeasuring(true));
        layer.add(selectionWidthLine.current); //?
        layer.batchDraw(); //?
      }
    });

    //select or deselect element
    app.on("click tap", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { selectedElement, mode } = blueprintEditor;
      if (mode !== "SET") {
        return;
      }
      if (
        selectedElement &&
        selectedElement.attrs &&
        selectedElement.attrs.mtype &&
        selectedElement.attrs.mtype === MEASURE_TYPE.WIDTH &&
        (!transformer.current ||
          (transformer.current &&
            transformer.current._nodes &&
            transformer.current._nodes.length === 0))
      ) {
        removeTransformers();
        dispatch(BlueprintEditor.setSelectedElement(null));
      } else {
        selectOrDeselectMeasure(transformer, dispatch, e, MEASURE_TYPE.WIDTH);
      }
    });

    app.on("mousemove touchmove", function () {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, layer, measurements, isMeasuring } = blueprintEditor;
      // do nothing if we didn't start selection
      if ((tool && tool.type !== MEASURE_TOOL_HORIZONTAL) || !isMeasuring)
        return;
      const relativePos = getRelativePointerPosition(app);
      if (
        //x2.current > 0 &&
        !measurements ||
        (measurements && !measurements.pixels) ||
        (measurements && measurements.pixels && measurements.pixels.width === 0)
      ) {
        selectionWidthLine.current.points([
          x2.current,
          y2.current,
          relativePos.x,
          y2.current,
          relativePos.x,
          y2.current,
        ]);
      }
      layer.batchDraw();
    });
    app.on("mouseup touchend", function () {
      const state = store.getState();
      const { blueprintEditor } = state;
      const {
        /* app,  */ tool,
        layer,
        measurements,
        isMeasuring,
        //pdfInfo,
      } = blueprintEditor;
      if (
        !selectionWidthLine.current.visible() ||
        tool.type !== MEASURE_TOOL_HORIZONTAL
      ) {
        return;
      }

      let currentMeasurements =
        measurements && measurements.pixels && measurements.metrics
          ? { ...measurements }
          : {
              pixels: {
                width: 0,
                height: 0,
              },
              metrics: {
                width: 1,
                height: 1,
              },
              arrows: {
                width: null,
                height: null,
              },
            };

      const points = selectionWidthLine.current.getAttr("points");
      const width = points[2] - points[0];

      if (x2.current > 0 && width > 10 && isMeasuring) {
        currentMeasurements.pixels.width = width;

        dispatch(BlueprintEditor.setShowWidthInput(true));

        if (currentMeasurements.pixels.width !== 0) {
          dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
        }

        // update visibility in timeout, so we can check it in click event
        setTimeout(() => {
          //attach transformer to the measure
          transformer.current = new Konva.Transformer({
            nodes: [selectionWidthLine.current],
          });
          selectionWidthLine.current.on("transformend", () => {
            const scaledWidth = getScaledWidth(selectionWidthLine.current);
            currentMeasurements.pixels.width = scaledWidth;
            dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
          });
          dispatch(
            BlueprintEditor.setSelectedElement(selectionWidthLine.current)
          );
          layer.add(transformer.current);
          layer.batchDraw();

          dispatch(
            BlueprintEditor.changeTool({
              type: MOVE_TOOL,
              color: "#000",
              name: i18n.t("default:_MOVE_TOOL"),
            })
          );
          dispatch(BlueprintEditor.setIsMeasuring(false));
          if (
            currentMeasurements.pixels.height !== 0 &&
            currentMeasurements.pixels.width !== 0
          ) {
            app.draggable(true);
          }
        });
      } else {
        dispatch(BlueprintEditor.setIsMeasuring(false));
      }
    });
  }, [app, dispatch, layer, user.profile]);
  return <div />;
}

export default connectWithStore(MeasureToolHorizontal, [
  "blueprintEditor",
  "user",
]);
