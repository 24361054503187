import React, { useCallback } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AutoComplete from "./v2autoselect";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "../../i18n";
import { canUserEdit } from "../../services/access";
import { connectWithStore } from "../../services/redux";
import ElementContainer from "../blueprints/editor/elements/element_container";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  listItem: {
    background: "#D3D8E4",
    marginBottom: "20px",
  },
  checkedUserIcon: {
    color: "#408FF8",
  },
}));

function OptionRow({ option, selected }) {
  return (
    <React.Fragment>
      <Grid container /* justify="space-between" */>
        <Grid item xs={2}>
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<CheckCircleOutlineOutlinedIcon />}
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected.includes(option)}
          />
        </Grid>
        <Grid container alignItems="center" justify="flex-end" item xs={5}>
          <ElementContainer
            type={option.type}
            buttonColor={option.color}
            label={option.label}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function SelectedRow({ selected, index, item, onClick }) {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem} key={index} button onClick={onClick}>
      <Grid container>
        <Grid item xs={2}>
          <Checkbox
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={
              <CheckCircleIcon className={classes.checkedUserIcon} />
            }
            color="primary"
            style={{ marginRight: 8 }}
            checked={selected.includes(item)}
          />
        </Grid>
        <Grid container alignItems="center" justify="flex-end" item xs={5}>
          <ElementContainer
            type={item.type}
            buttonColor={item.color}
            label={item.label}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
}

function BlueprintAutocomplete({
  selected,
  onChange,
  onDeselect,
  accessRules,
  user,
  settings,
  autocompleteWidth,
  withoutSelected,
  suggestedElements,
}) {
  const classes = useStyles();
  const blueprintElements = suggestedElements
    ? suggestedElements
    : settings.blueprintSettings.elements
    ? settings.blueprintSettings.elements
    : [];
  const suggestionTransformer = (data, i) => {
    const { name, color, type, id } = data;
    return {
      label: name,
      value: id,
      color,
      type,
    };
  };
  const suggestions = blueprintElements.filter((data) => typeof data != 'undefined').map(suggestionTransformer);
  const getOptionLabel = useCallback(({ label }) => {
    return label;
  }, []);

  const filterSuggestion = (suggestion) => {
    return selected.filter((s) => s.label === suggestion.label).length === 0;
  };

  const options = suggestions.filter(filterSuggestion);

  return (
    <React.Fragment>
      <AutoComplete
        list={options}
        selected={selected}
        autocompleteWidth={autocompleteWidth}
        onChange={(option) => onChange(option)}
        renderOption={(option, index) => (
          <OptionRow index={index} selected={selected} option={option} />
        )}
        getOptionLabel={getOptionLabel}
        noOptionsDisplay="none"
        text={i18n.t("default:_TYPE_TEXT_SEARCH")}
        withSearchButton={true}
      />
      {selected && selected.length > 0 && !withoutSelected ? (
        <List className={classes.root}>
          {selected.map((row, index) => {
            return (
              <SelectedRow
                key={index}
                item={row}
                selected={selected}
                onClick={() =>
                  canUserEdit(user, accessRules) && onDeselect(row)
                }
              />
            );
          })}
        </List>
      ) : null}
    </React.Fragment>
  );
}

export default connectWithStore(BlueprintAutocomplete, ["settings"]);
