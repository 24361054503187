import React, { useState, useCallback, useEffect } from "react";
import FieldPreview from "./field_preview";
import { CurrentProject } from "../../../redux/actions";
import { updateSignleProjectField } from "../../../services/api/projects";
import { connectWithStore } from "../../../services/redux";
import { Grid, Button, List, ListItem, Chip } from "@material-ui/core";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import ProjectTable from "../../../components/project_table/project_table";
import withModal from "../../../hoc/withModal";
import i18n from "../../../i18n";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    width: "100%",
    marginRight: "5px",
    marginBottom: "5px",
    color: "inherit",
  },
  listRoot: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
    maxWidth: "100%",
  },
}));

function CustomerTable({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  dispatch,
  settings,
  user,
}) {
  const classes = useStyles();
  const { project } = currentProject;
  const [tableModalVisible, setTableModalVisible] = useState(false);

  const currentField = settings.projectFields.filter(
    (field) => field.id === id
  )[0];

  const table =
    project && project[id] ? project[id] : currentField && currentField.table;
  const columnHeaders =
    currentField && currentField.table && Object.keys(currentField.table[0]);
  const firstColumn = columnHeaders && columnHeaders[0];

  const formatPreview = useCallback(
    (value) => {
      if (editable) return [];
      if (value && value.length > 0) {
        const newValue = value.slice(0, 4);
        const remaining = value.length - 4;
        if (remaining > 0) {
          newValue.push({
            label: `${remaining} ${i18n.t("default:_MORE")}`,
            value: -1,
          });
        }
        return newValue;
      }
      return value;
    },
    [editable]
  );

  const [finalValue, setFinalValue] = useState(
    formatPreview(
      currentProject.project && currentProject.project[id]
        ? currentProject.project[id]
        : []
    )
  );

  useEffect(() => {
    if (editable) return;

    setFinalValue(
      formatPreview(
        currentProject.project[id] ? currentProject.project[id] : []
      )
    );
  }, [id, editable, setFinalValue, currentProject, formatPreview]);

  const openModal = () => {
    setTableModalVisible(true);
  };

  const closeModal = () => {
    setTableModalVisible(false);
  };

  const onCellUpdate = useCallback(
    async (newValue, key, i) => {
      let newTable = [...table];
      let currentRow = newTable[i];
      currentRow[key] = newValue;
      newTable.splice(i, 1, currentRow);
      project &&
        (await updateSignleProjectField({
          id: project._id,
          field: {
            id,
            value: newTable,
          },
        }));
      dispatch(
        CurrentProject.updateProject({
          [id]: newTable,
        })
      );
    },
    [dispatch, id, project, table]
  );

  const addRow = async () => {
    const newTable = [...table];
    const newRow = { ...currentField.table[0] };
    newTable.push(newRow);
    project &&
      (await updateSignleProjectField({
        id: project._id,
        field: {
          id,
          value: newTable,
        },
      }));
    dispatch(
      CurrentProject.updateProject({
        [id]: newTable,
      })
    );
  };

  const removeRow = async (i) => {
    let newTable = [...table];
    newTable.splice(i, 1);
    project &&
      (await updateSignleProjectField({
        id: project._id,
        field: {
          id,
          value: newTable,
        },
      }));
    dispatch(
      CurrentProject.updateProject({
        [id]: newTable,
      })
    );
  };

  const FooterComponent = function FooterComponent() {
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        style={{
          padding: 10,
          paddingRight: 20,
        }}
      >
        <Button
          onClick={() => closeModal()}
          variant="contained"
          color="primary"
        >
          {i18n.t("default:_BACK")}
        </Button>
      </Grid>
    );
  };

  const renderTableModal = () => {
    const TableModal = withModal(ProjectTable);
    return (
      <TableModal
        FooterComponent={FooterComponent}
        title={currentField.label}
        visible={tableModalVisible}
        onClose={() => closeModal()}
        table={table}
        onCellUpdate={(value, key, i) => onCellUpdate(value, key, i)}
        addRow={addRow}
        removeRow={removeRow}
        columnHeaders={columnHeaders}
        accessRules={accessRules}
        user={user}
      />
    );
  };

  return (
    <Grid container justifyContent="center">
      {currentField && renderTableModal()}
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.CUSTOMER_TABLE]}
        accessRules={accessRules}
        onClick={() => openModal()}
      >
        {!editable && (
          <List className={classes.listRoot}>
            {finalValue.length > 0 &&
              finalValue.map((row, index) => {
                return (
                  <ListItem className={classes.listItemRoot} key={index}>
                    <Chip
                      onClick={() => openModal(true)}
                      className={classes.chip}
                      label={row[firstColumn] ? row[firstColumn] : row.label}
                      variant="outlined"
                    />
                  </ListItem>
                );
              })}
          </List>
        )}
      </FieldPreview>
    </Grid>
  );
}

export default connectWithStore(CustomerTable, [
  "currentProject",
  "settings",
  "user",
]);
