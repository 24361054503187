import React from "react";
import DateService from "../../services/date";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { getFileTarget } from "../../services/api/file_upload";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
  },
  jobAvatar: {
    color: theme.palette.primary.main,
    display: "inline-flex",
    background: "white",
    height: 30,
    width: 30,
    marginLeft: 5,
  },
}));

function JobListItem({ onItemClick, job, userRole }) {
  const classes = useStyles();

  return (
    <li className={`list-group-item flex-center-align ${classes.root}`}>
      <Grid container alignItems="center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/${job.ID}`}
          data-tip={DateService.getFormattedDate(job.startDate)}
          className={"editor-attendance in-blue"}
        >
          #{job.ID}
        </a>
        <Avatar
          aria-label="Job type icon"
          className={classes.jobAvatar}
          src={getFileTarget(job.jobType.icon, "100x100")}
        >
          {!job.jobType.icon && <i className="icon-case_v2" />}
        </Avatar>
      </Grid>
      {userRole !== "inspector" && (
        <i
          className="mdi mdi-close-circle float-right JobEditor-remove_attendance"
          onClick={() => onItemClick(job)}
        />
      )}
    </li>
  );
}

export default JobListItem;
