import React, { useCallback, useState, Fragment } from "react";
import { Grid, Button, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { PROJECT_FIELD_TYPE } from "./constants";
import Text from "./text";
import Address from "./address";
import FileList from "./file_list";
import StatusList from "./status_list";
import List from "./list";
import Ranking from "./ranking";
import Skills from "./skills";
import Table from "./table";
import CustomerTable from "./customer_table";
import BlueprintElements from "./blueprint_elements";
import BlueprintList from "./blueprint_list";
import VisibilityIcon from "@material-ui/icons/Visibility";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import { ROLE } from "../../../services/roles";
import { changeFieldVisibility } from "../../../services/api/projects";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: 15,
    cursor: "pointer",
    "&:hover": {
      "& $button": {
        color: "unset",
      },
    },
  },
  hidden: {
    opacity: 0.5,
  },
  button: {
    color: "white",
    padding: 3,
    fontSize: 12,
    /* "&:hover": {
      color: "unset"
    } */
  },
}));

function renderField(field, editable, onUpdate) {
  switch (field.type) {
    case PROJECT_FIELD_TYPE.TEXT:
      return <Text editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.ADDRESS:
      return <Address editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.FILE_LIST:
      return <FileList editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.BLUEPRINT_LIST:
      return <BlueprintList editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.STATUS_LIST:
      return <StatusList editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.LIST:
      return <List editable={editable} {...field} onUpdate={onUpdate} />;
    case PROJECT_FIELD_TYPE.RANKING:
      return <Ranking editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.SKILLS:
      return <Skills editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.TABLE:
      return <Table editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.CUSTOMER_TABLE:
      return <CustomerTable editable={editable} {...field} />;
    case PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS:
      return <BlueprintElements editable={editable} {...field} />;
    default:
      return <div />;
  }
}

function FieldRenderer({
  field,
  toggleDrawer,
  editable,
  user,
  project,
  fullWidth,
  turnOffHidden,
  onUpdate,
}) {
  const classes = useStyles();
  const [visibility, setVisbility] = useState(
    project &&
      project.visibility &&
      typeof project.visibility[field.id] !== "undefined"
      ? project.visibility[field.id]
      : true
  );

  const canChangeVisibility = useCallback(() => {
    return (
      user.profile.role === ROLE.SYSTEM_ADMIN ||
      user.profile.role === ROLE.BUSINESS_ADMIN
    );
  }, [user]);

  const changeVisibility = useCallback(async () => {
    if (!project._id) {
      return null;
    }
    const response = await changeFieldVisibility({
      id: project._id,
      [field.id]: visibility ? false : true,
    });
    if (response.success) {
      setVisbility(visibility ? false : true);
    }
  }, [project, field, visibility]);

  if (!visibility && !canChangeVisibility()) {
    return null;
  }

  return !visibility && turnOffHidden ? null : (
    <Grid
      item
      xs={12}
      sm={12}
      md={fullWidth ? 12 : 4}
      lg={fullWidth ? 12 : 3}
      className={clsx(classes.item, {
        [classes.hidden]: !visibility && !editable,
      })}
      onClick={() => (editable ? toggleDrawer() : null)}
    >
      {!turnOffHidden && !editable && canChangeVisibility() && (
        <Button className={classes.button} onClick={() => changeVisibility()}>
          {visibility && (
            <Fragment>
              <VisibilityIcon fontSize="small" /> &nbsp;{" "}
              {i18n.t("default:_HIDE")}
            </Fragment>
          )}
          {!visibility && (
            <Fragment>
              <VisibilityOffIcon fontSize="small" /> &nbsp;{" "}
              {i18n.t("default:_UNHIDE")}
            </Fragment>
          )}
        </Button>
      )}
      {renderField(field, editable, onUpdate)}
      {fullWidth && <Divider style={{ marginTop: 10, marginBottom: 10 }} />}
    </Grid>
  );
}

export default connectWithStore(FieldRenderer, ["user"]);
