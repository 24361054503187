const initialState = {
  showLoader: false,
};

const loadingBarApp = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case "loading-bar/SHOW":
      if (state.showLoader !== true)
        return Object.assign({}, state, { showLoader: true });
      else return state;
    case "loading-bar/HIDE":
      if (state.showLoader !== false)
        return Object.assign({}, state, { showLoader: false });
      else return state;
    default:
      return state;
  }
};

export default loadingBarApp;
