import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { QuestionType } from "../../../services/question";
import { Questions } from "../../../redux/actions";
import { connectWithStore } from "../../../services/redux";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    left: 0,
    top: 100,
    zIndex: 999,
  },
  root: {
    fontSize: 12,
  },
  question: {
    fontSize: 11,
  },
  active: {
    fontWeight: "bold",
  },
});

function StepNavigation({ questions, active, goToStep, activeStep, dispatch }) {
  const [expanded, setExpanded] = useState([activeStep.ID]);
  const classes = useStyles();
  const stepMap = {};
  const steps = questions.filter((q) => q.type === QuestionType.STEP);
  let currentStep = null;
  const toggleExpanded = (id) => {
    const tmp = [...expanded];
    if (expanded.includes(id)) {
      tmp.splice(tmp.indexOf(id), 1);
    } else {
      tmp.push(id);
    }
    setExpanded(tmp);
  };

  questions.forEach((q) => {
    if (q.type === QuestionType.BLUEPRINT) {
      return;
    }
    if (q.type === QuestionType.STEP) {
      currentStep = q.ID;
      return;
    }
    if (currentStep) {
      if (!stepMap[currentStep]) {
        stepMap[currentStep] = [];
      }
      stepMap[currentStep].push(q);
    }
  });
  return (
    <div className={classes.container}>
      <ol style={{ paddingLeft: 25, textAlign: "left" }}>
        {steps.map((step, index) => {
          return (
            <li key={step.ID}>
              <Button
                className={classes.root}
                variant={active === index ? "contained" : "text"}
                onClick={() => toggleExpanded(step.ID)}
                color={active === index ? "primary" : "default"}
              >
                {step.title}
              </Button>
              {stepMap[step.ID] && expanded.includes(step.ID) && (
                <ol style={{ paddingLeft: 40 }}>
                  {stepMap[step.ID].map((q) => {
                    const questionIndex = questions
                      .filter(
                        (question) =>
                          question && question.type !== QuestionType.STEP
                      )
                      .indexOf(q);
                    return (
                      <li key={q.ID}>
                        <Button
                          variant="text"
                          className={classes.question}
                          //href={"#question_" + q.ID}
                          onClick={() => {
                            dispatch(
                              Questions.setActiveQuestion(questionIndex)
                            );
                            goToStep(index + 1);
                          }}
                        >
                          {q.title}
                        </Button>
                      </li>
                    );
                  })}
                </ol>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default connectWithStore(StepNavigation, []);
