const initialState = { visible: false, unread_count: 0 };

const notificationPanel = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case "TOGGLE_PANEL":
      state.visible = action.data;
      if (state.visible === true) {
        state.unread_count = 0;
      }
      return Object.assign({}, state);
    case "SET_NOTIFICATION_UNREAD_COUNT":
      state.unread_count = action.data;
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default notificationPanel;
