import i18n from "../../i18n";

export const TAG_TYPE = {
  FIELD: 0,
  CONDITIONAL: 1,
  EXPRESSION: 2,
  PROJECT_TABLE: 3
};

export const TABLE = {
  JOB_TYPE: "job_type",
  JOBS: "jobs",
  PROJECTS: "projects",
  USERS: "users",
};

export const CONDITIONALS = {
  MULTIPLY: 0,
  ADD: 1,
  SUBSTRACT: 2,
  DIVIDE: 3,
  CONCATE: 4,
  LEFT_BRACKET: 5,
  RIGHT_BRACKET: 6,
};

export const EXPRESSION = {
  MONTH_DAY: 0,
  YEAR: 1,
  DATE: 2,
  TIME: 3,
  HOUR: 4,
  MINUTES: 5,
  LENGHT: 6,
  TIME_FROM: 7,
  TIME_TO: 8,
  HOUR_FROM: 9,
  HOUR_TO: 10,
  MINUTES_FROM: 11,
  MINUTES_TO: 12,
  DAY_MONTH: 13,
  DATE_TIME_FROM: 14,
  DATE_TIME_TO: 15,
  DATE_FROM: 16,
  DATE_TO: 17,
  MONTH_DAY_FROM: 18,
  MONTH_DAY_TO: 19,
  DAY_MONTH_FROM: 20,
  DAY_MONTH_TO: 21,
  YEAR_FROM: 22,
  YEAR_TO: 23,
  EXPAND: 24
};

export const BUILT_IN = {
  ID: "ID",
  CREATED_AT: "created_at",
  CREATED_AT_DATE: "created_at_date",
  CREATED_AT_TIME: "created_at_time",
  CREATED_BY: "createdBy",
  SCHEDULED_TIME: "startDate",
  NAME: "name",
  LOCATION: "location",
  EMAIL: "email",
  FIRST_NAME: "name.first",
  LAST_NAME: "name.last",
  ROLE: "role",
  TITLE: "jobType.title",
  STATUS: "status",
  FULL_NAME: "name",
  USERS: "users",
  PREV_JOB: "prev_job",
  NEXT_JOB: "next_job",
};

export const builtInSuggestions = [
  {
    id: 1,
    type: TAG_TYPE.CONDITIONAL,
    field: CONDITIONALS.MULTIPLY,
    name: "*",
  },
  {
    id: 2,
    type: TAG_TYPE.CONDITIONAL,
    field: CONDITIONALS.ADD,
    name: "+",
  },
  {
    id: 3,
    type: TAG_TYPE.CONDITIONAL,
    field: CONDITIONALS.SUBSTRACT,
    name: "-",
  },
  {
    id: 4,
    type: TAG_TYPE.CONDITIONAL,
    field: CONDITIONALS.DIVIDE,
    name: "/",
  },
  {
    id: 5,
    type: TAG_TYPE.CONDITIONAL,
    field: CONDITIONALS.CONCATE,
    name: "&",
  },
  {
    id: 8,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_JOB_ID"),
    field: BUILT_IN.ID,
  },
  {
    id: 9,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_CREATED_AT"),
    field: BUILT_IN.CREATED_AT,
  },
  {
    id: 10,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_START_DATE"),
    field: BUILT_IN.SCHEDULED_TIME,
  },
  {
    id: 11,
    type: TAG_TYPE.FIELD,
    table: TABLE.PROJECTS,
    name: i18n.t("default:_REPORT_PROJECT_NAME"),
    field: BUILT_IN.NAME,
  },
  {
    id: 12,
    type: TAG_TYPE.FIELD,
    table: TABLE.PROJECTS,
    name: i18n.t("default:_REPORT_PROJECT_ID"),
    field: BUILT_IN.ID,
  },
  {
    id: 14,
    type: TAG_TYPE.FIELD,
    table: TABLE.USERS,
    name: i18n.t("default:_REPORT_USER_EMAIL"),
    field: BUILT_IN.EMAIL,
  },
  {
    id: 15,
    type: TAG_TYPE.FIELD,
    table: TABLE.USERS,
    name: i18n.t("default:_REPORT_USER_FIRST_NAME"),
    field: BUILT_IN.FIRST_NAME,
  },
  {
    id: 16,
    type: TAG_TYPE.FIELD,
    table: TABLE.USERS,
    name: i18n.t("default:_REPORT_USER_LAST_NAME"),
    field: BUILT_IN.LAST_NAME,
  },
  {
    id: 17,
    type: TAG_TYPE.FIELD,
    table: TABLE.USERS,
    name: i18n.t("default:_REPORT_USER_ROLE"),
    field: BUILT_IN.ROLE,
  },
  {
    id: 18,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_JOB_TYPE_TITLE"),
    field: BUILT_IN.TITLE,
  },
  {
    id: 19,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_JOB_STATUS"),
    field: BUILT_IN.STATUS,
  },
  {
    id: 10,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_CREATED_BY"),
    field: BUILT_IN.CREATED_BY,
  },
  {
    id: 11,
    type: TAG_TYPE.FIELD,
    table: TABLE.USERS,
    name: i18n.t("default:_REPORT_FULL_NAME"),
    field: BUILT_IN.FULL_NAME,
  },
  {
    id: 12,
    type: TAG_TYPE.FIELD,
    table: TABLE.PROJECTS,
    name: i18n.t("default:_REPORT_PROJECT_MANAGERS"),
    field: BUILT_IN.USERS,
  },
  {
    id: 13,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_PREV_JOB", {
      jobWord: i18n.t("default:_JOB"),
    }),
    field: BUILT_IN.PREV_JOB,
  },
  {
    id: 14,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_NEXT_JOB", {
      jobWord: i18n.t("default:_JOB"),
    }),
    field: BUILT_IN.NEXT_JOB,
  },
  {
    id: 15,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_CREATED_AT_DATE"),
    field: BUILT_IN.CREATED_AT_DATE,
  },
  {
    id: 16,
    type: TAG_TYPE.FIELD,
    table: TABLE.JOBS,
    name: i18n.t("default:_REPORT_CREATED_AT_TIME"),
    field: BUILT_IN.CREATED_AT_TIME,
  },
];

export const SETTING_NAME = {
  THUMBNAILS: "thumbnails",
  LINKS: "links",
  PAGE_IMAGE: "page_image",
  QUESTION_STATUS: "question_status",
  JOB_STATUS: "job_status",
  SHOW_COMMENT: "show_comment",
  RTL: "rtl",
  PROJECT_REPORT: "projectReport",
  LIMIT_HISTORICAL_DATA: 'limit_historical_data',
  ATTACHMENTS: "attachments"
};
