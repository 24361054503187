export const STEP_CREATE_NEW = 1;
export const STEP_NEW_PROJECT = 2;
export const STEP_NEW_USER = 3;

export const STEP_SELECT_JOB_TYPE = 4;
export const STEP_SELECT_PROJECT = 5;
export const STEP_SELECT_START_DATE = 6;
export const STEP_NEW_JOB = 7;
export const STEP_JOB_CREATED = 8;
export const STEP_SELECT_PREV_JOB = 9;
export const STEP_ASSIGN_USERS = 10;

export const STEP_FINAL = 11;

export const STEP_SELECT_PROJECT_SKILLS = 12;
export const STEP_SELECT_JOB_SKILLS = 13;
export const STEP_SELECT_USER_SKILLS = 14;
export const STEP_SELECT_COMPANIES = 15;
export const STEP_SELECT_PROJECTS = 16;
