import React from "react";
import { connectWithStore } from "../../../services/redux";
import UserTextField from "./text_field";
import FieldWithFiles from "./field_with_files";

function TextWithFiles({ field, user, value, changeUserProp }) {
  let currentValue = value ? { ...value } : { value: "", files: [] };

  const onTextChange = (val) => {
    currentValue.value = val;
    changeUserProp(field.value, currentValue);
  };

  return (
    <FieldWithFiles field={field} value={value} changeUserProp={changeUserProp}>
      <UserTextField
        value={currentValue.value}
        changeUserProp={onTextChange}
        field={field}
        isSubField={true}
      />
    </FieldWithFiles>
  );
}

export default connectWithStore(TextWithFiles, ["user"]);
