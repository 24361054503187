import React, { Component } from "react";
import Dropzone from "react-dropzone";
import i18n from "../../i18n";
import "./csv_parser.scss";

export default class CsvParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  async onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length > 0) {
      this.parse(acceptedFiles[0]);
    }
  }

  parse(csv) {
    var Papa = require("papaparse/papaparse.min.js");
    Papa.parse(csv, {
      header: false,
      download: true,
      skipEmptyLines: true,
      // Here this is also available. So we can call our custom class method
      complete: (csv) => {
        if (this.props.onRawChange) {
          this.props.onRawChange(csv.data);
        }
        const pureData = csv.data.map((row) =>
          typeof row !== "string" ? row[0] : row
        ).filter((row) => row.length > 0 || row !== "");
        if (this.props.onChange) {
          this.props.onChange(pureData);
        }
      },
    });
  }

  render() {
    return (
      <Dropzone
        onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={!isDragActive ? "dropzone" : "dropzone--isActive"}
            >
              <input {...getInputProps()} />
              {
                <div className="dz-message text-center">
                  <h1 className="display-4">
                    <i className=" mdi mdi-progress-upload"></i>
                  </h1>
                  {i18n.t("default:_FROM_CSV_FILE")}.<br />
                </div>
              }
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
