export const REPORT_TYPE = {
  JOBS: "Jobs",
  PROJECTS: "Projects",
  USERS: "Users",
  FILES: "Files",
};

export const COLOR = {
  JOBS: "yellow",
  PROJECTS: "blue",
  USERS: "pink",
  JOB_TYPE: "green",
  NEUTRAL: "grey",
};
