import {
  SET_STEPS,
  ADD_STEP_QUESTIONS,
  SET_BUTTON_PRESSED,
  SET_LAST_ACTIVE_QUESTION,
  ADD_STEP,
  SET_STEPS_MODAL_OPEN,
  SET_PREVIOUS_STEP,
  SET_STEP_PATH_ACTIVATED,
} from "../constants";

const initialState = {
  buttonPressed: null,
  lastActiveQuestion: null,
  loadedSteps: [],
  stepsModalOpen: false,
  previousStep: null,
  stepPathActivated: false,
};

const stepQuestions = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case SET_STEPS:
      return Object.assign({}, state, {
        loadedSteps: data,
      });
    case ADD_STEP:
      const currentSteps = [...state.loadedSteps];
      currentSteps.push(data);
      return Object.assign({}, state, {
        loadedSteps: currentSteps,
      });
    case SET_STEPS_MODAL_OPEN:
      return Object.assign({}, state, {
        stepsModalOpen: data,
      });
    case ADD_STEP_QUESTIONS:
      const { index, loadedQuestions } = data;
      const current = [...state.loadedSteps];
      current[index] = loadedQuestions;
      return Object.assign({}, state, {
        loadedSteps: current,
      });
    case SET_BUTTON_PRESSED:
      return Object.assign({}, state, {
        buttonPressed: data,
      });
    case SET_LAST_ACTIVE_QUESTION:
      return Object.assign({}, state, {
        lastActiveQuestion: data,
      });
    case SET_PREVIOUS_STEP:
      return Object.assign({}, state, {
        previousStep: data,
      });
    case SET_STEP_PATH_ACTIVATED:
      return Object.assign({}, state, {
        stepPathActivated: data,
      });
    default:
      return state;
  }
};

export default stepQuestions;
