import Konva from "konva";
import React, { useEffect, useRef } from "react";
//import { batch } from "react-redux";
import i18n from "../../../../i18n";
import { BlueprintEditor } from "../../../../redux/actions";
import store from "../../../../redux/store";
import { connectWithStore } from "../../../../services/redux";
import { SCISSORS_TOOL, MOVE_TOOL } from "../constants";
import { getRelativePointerPosition } from "../utils";
import { v1 as uuid } from "uuid";

function ScissorsTool({ blueprintEditor, dispatch, job }) {
  const { app, layer } = blueprintEditor;
  const x1 = useRef(0);
  const y1 = useRef(0);
  const x2 = useRef(0);
  const y2 = useRef(0);
  const targetEl = useRef(null);
  const selectionRectangle = useRef(
    new Konva.Rect({
      fill: "rgba(0,0,255,0.5)",
      visible: false,
      globalCompositeOperation: "destination-out",
    })
  );

  useEffect(() => {
    if (app) {
      layer.add(selectionRectangle.current);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    app.on("mousedown touchstart", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool } = blueprintEditor;
      // do nothing if we mousedown on any shape
      if (
        tool.type !== SCISSORS_TOOL ||
        !e.target ||
        e.target === app ||
        e.target.id() === "blueprint" ||
        (e.target.parent && e.target.parent.attrs.name.includes("Layer"))
      ) {
        return;
      }

      //set target id on initial touch
      const currentEl = e.target;
      targetEl.current = currentEl;

      app.draggable(false);
      const relativePos = getRelativePointerPosition(app);
      x1.current = relativePos.x;
      y1.current = relativePos.y;
      x2.current = relativePos.x;
      y2.current = relativePos.y;

      selectionRectangle.current.visible(true);
      selectionRectangle.current.width(0);
      selectionRectangle.current.height(0);

      selectionRectangle.current.zIndex(9999);
    });

    app.on("mousemove touchmove", function () {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, layer } = blueprintEditor;

      // no nothing if we didn't start selection
      if (
        !selectionRectangle.current.visible() ||
        tool.type !== SCISSORS_TOOL
      ) {
        return;
      }
      const relativePos = getRelativePointerPosition(app);
      x2.current = relativePos.x;
      y2.current = relativePos.y;

      selectionRectangle.current.setAttrs({
        x: Math.min(x1.current, x2.current),
        y: Math.min(y1.current, y2.current),
        width: Math.abs(x2.current - x1.current),
        height: Math.abs(y2.current - y1.current),
      });
      layer.batchDraw();
    });
    app.on("mouseup touchend", function (e) {
      const state = store.getState();
      const { blueprintEditor } = state;
      const { app, tool, layer } = blueprintEditor;

      //  do nothing if we didn't start selection
      if (
        !selectionRectangle.current.visible() ||
        tool.type !== SCISSORS_TOOL
      ) {
        return;
      }

      //create group only if the scissors rect is within the element and it wasn't just click on it
      if (
        targetEl.current &&
        !targetEl.current.attrs.groupId &&
        selectionRectangle.current.height() &&
        selectionRectangle.current.width()
      ) {
        const groupId = uuid();
        const group = new Konva.Group({
          //...targetEl.current.attrs,
          name: "Group",
          id: groupId,
          draggable: false,
        });
        layer.add(group);
        group.add(targetEl.current);
        targetEl.current.draggable(true);

        //set group id to the target element, so it can help redraw groups on reload
        targetEl.current.setAttr("groupId", groupId);

        selectionRectangle.current.setAttr("fill", "rgba(0,0,255)");

        //add id to scissors
        selectionRectangle.current.setAttr("groupId", groupId);
        const scissorsId = uuid();
        selectionRectangle.current.id(scissorsId);
        targetEl.current.setAttr("scissorsId", scissorsId);

        group.add(selectionRectangle.current);
        group.cache();
        layer.draw();
      }

      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.current.visible(false);
        app.draggable(true);
        //layer.batchDraw();

        dispatch(
          BlueprintEditor.changeTool({
            type: MOVE_TOOL,
            color: "#000",
            name: i18n.t("default:_MOVE_TOOL"),
          })
        );
      });
    });
    // eslint-disable-next-line
  }, [app, dispatch]);
  return <div />;
}

export default connectWithStore(ScissorsTool, ["blueprintEditor"]);
