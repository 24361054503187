import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import ButtonBlock from "../../../button_block/button_block";
import i18n from "../../../../i18n";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { BlueprintEditor } from "../../../../redux/actions";
import { makeStyles } from "@material-ui/styles";
import { CROP_PREVIEW } from "./steps";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 10,
  },
  cropArea: {
    marginBottom: 20,
  },
}));

function BlueprintPdfGenerator({ blueprintEditor, dispatch }) {
  const classes = useStyles();
  const [isCropping, setIsCropping] = useState(false);
  const { cropSize } = blueprintEditor;

  const onCrop = () => {
    const croppedX = cropSize ? cropSize.x / cropSize.ratio : 0;
    const croppedY = cropSize ? cropSize.y / cropSize.ratio : 0;
    const croppedWidth = cropSize
      ? (cropSize.width * cropSize.scaleX) / cropSize.ratio
      : 0;
    const croppedHeight = cropSize
      ? (cropSize.height * cropSize.scaleY) / cropSize.ratio
      : 0;

    const imageUrl = cropSize
      ? blueprintEditor.app.toDataURL({
          x: croppedX,
          y: croppedY,
          width: croppedWidth,
          height: croppedHeight,
          pixelRatio: 2,
        })
      : blueprintEditor.app.toDataURL({
          pixelRatio: 2,
        });
    dispatch(BlueprintEditor.setCroppedDataUrl(imageUrl));
    dispatch(BlueprintEditor.setActiveCropStep(CROP_PREVIEW));
    //goToStep(2);
  };

  const getFitRatio = (size) => {
    let ratio = 1;
    if (size.height > size.width) {
      if (size.height > 600) {
        ratio = 600 / size.height;
      }
    } else {
      if (size.width > 500) {
        ratio = 500 / size.width;
      }
    }

    return ratio;
  };

  useEffect(() => {
    const dataUrl = blueprintEditor.app.toDataURL({
      pixelRatio: 2,
    });

    const size = blueprintEditor.app.getSize();
    const ratio = getFitRatio(size);

    var stage = new Konva.Stage({
      container: "container",
      width: size.width * ratio,
      height: size.height * ratio,
    });
    var layer = new Konva.Layer();
    stage.add(layer);

    Konva.Image.fromURL(dataUrl, function (konvaImage) {
      konvaImage.setAttrs({
        y: 0,
        x: 0,
        width: size.width,
        height: size.height,
        scale: {
          x: ratio,
          y: ratio,
        },
        //opacity: 0.5
      });
      layer.add(konvaImage);
      const canvasData = {
        x: cropSize ? cropSize.x : 5,
        y: cropSize ? cropSize.y : 5,
        width: cropSize
          ? cropSize.width * cropSize.scaleX
          : size.width * ratio - 12,
        height: cropSize
          ? cropSize.height * cropSize.scaleY
          : size.height * ratio - 12,
        fill: "rgba(0,0,0,0)",
        strokeEnabled: false,
        name: "Empty element",
        draggable: true,
      };

      const emptyElement = new Konva.Rect(canvasData);

      emptyElement.on("transformstart", () => {
        setIsCropping(true);
      });

      emptyElement.on("transformend", () => {
        dispatch(
          BlueprintEditor.setCropSize({
            x: emptyElement.x(),
            y: emptyElement.y(),
            scaleX: emptyElement.scaleX(),
            scaleY: emptyElement.scaleY(),
            width: emptyElement.width(),
            height: emptyElement.height(),
            ratio,
          })
        );
        setIsCropping(false);
      });
      const tr = new Konva.Transformer({
        nodes: [emptyElement],
        rotateEnabled: false,
        borderStrokeWidth: 3,
      });
      layer.add(emptyElement);
      layer.add(tr);
      layer.draw();
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid id="container" className={classes.cropArea}></Grid>
      <ButtonBlock position="relative">
        <Button
          className={classes.button}
          color="secondary"
          onClick={() => dispatch(BlueprintEditor.cancelCrop())}
        >
          {i18n.t("default:_CANCEL")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCrop()}
          disabled={isCropping}
        >
          {i18n.t("default:_CROP")}
        </Button>
      </ButtonBlock>
    </Grid>
  );
}

export default connectWithStore(BlueprintPdfGenerator, ["blueprintEditor"]);
