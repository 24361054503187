import React from "react";
import { Player } from "video-react";
import { getFileTarget } from "../../services/api/file_upload";

function VideoPreview({ videoId }) {
  console.log({ videoId });
  return <Player src={getFileTarget(videoId)} />;
}

export default VideoPreview;
