const pageTitle = (state = { title: "" }, action) => {
  switch (action.type) {
    case "SET_PAGE_TITLE":
      state = action.data;
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default pageTitle;
