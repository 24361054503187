import Http from "../http";

export const getJobTypeList = async (fromFilters) => {
  const path = `/v1/job_types?fromFilters=${fromFilters ? fromFilters : false}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getJobType = async (params) => {
  const path = `/v2/jobtype/${params.id}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createJobType = async (params) => {
  const path = `/v2/jobtype`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateJobType = async ({ title, icon, id, settings }) => {
  const path = `/v2/jobtype/${id}`;
  try {
    const saveData = {
      title: title,
      icon: icon,
    };
    if (settings) {
      saveData.settings = settings;
    }
    const response = await Http.sharedInstance.withAuth().put(path, saveData);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteJobType = async ({ id }) => {
  const path = `/v2/jobtype/${id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};
