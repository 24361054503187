import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ExpandedMenuItem from "./expanded_menu_item";
import { Grid } from "@material-ui/core";
import i18n from "../../../i18n";
import Logo from "../logo/logo";
import { connectWithStore } from "../../../services/redux";
import { getDisplayName } from "../../../services/user";
import withMuiIcon from "../../../hoc/withMuiIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LoginService from "../../../services/login";
import SubMenu from "./sub_menu";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 65,
    width: "25%",
    zIndex: "998",
    minHeight: "auto",
    height: "calc(100% - 50px)",
    overflowY: "visible",
  },
  impersonating: {
    backgroundColor: "#FFCCCC",
  },
  logoContainer: {
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  rowWrapper: {
    position: "relative",
  },
  subMenuWrapper: {
    position: "absolute",
    left: "100%",
  },
});

function MenuDrawerDesktop({
  open,
  menuItems,
  activeLink,
  gotoPage,
  user,
  jobTypes,
  handleCreateJobTypeClick,
  handleCreateNewClick,
  settings,
}) {
  const classes = useStyles();
  const [hoveredSubMenu, setHoveredSubMenu] = useState(null);

  const onMenuItemClick = (item) => {
    if (!item.url) return;
    gotoPage(i18n.t(item.label), item.url);
  };

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={() => console.log("close drawer")}
      classes={{
        paper: `${classes.root} ${
          LoginService.isImpersonating(settings) ? classes.impersonating : ""
        }`,
      }}
      variant={"persistent"}
    >
      <Grid style={{ maxHeight: menuItems && menuItems.length > 6 && "70px" }}>
        <span className={classes.logoContainer}>
          <a
            href="# "
            onClick={() => gotoPage(i18n.t("default:_DASHBOARD"), "/dashboard")}
          >
            <Logo collapsed={!open} hideSecondary={true} />
          </a>
        </span>
      </Grid>
      <List component="nav" aria-labelledby="nested-list-menu">
        <ExpandedMenuItem
          icon={user.profile.imgUrl ? null : "mdi mdi-account"}
          avatar={user.profile.imgUrl ? user.profile.imgUrl : null}
          label={getDisplayName(user.profile)}
          selected={activeLink === "/profile"}
          onMenuItemClick={() =>
            gotoPage(i18n.t("default:_PERSONAL_DATA"), "/profile")
          }
          muiIcon={withMuiIcon(AccountCircleIcon)}
        />
        {menuItems.map((item, i) => {
          const isJobTypeSubmenu = item.subMenuListProp === "jobType";
          return (
            <Grid
              onMouseOver={() => {
                setHoveredSubMenu(item.subMenuListProp);
              }}
              onMouseLeave={() => {
                setHoveredSubMenu(null);
              }}
              className={classes.rowWrapper}
            >
              {item.subMenuListProp && (
                <>
                  {hoveredSubMenu === item.subMenuListProp && (
                    <Grid
                      className={classes.subMenuWrapper}
                      style={{
                        top: isJobTypeSubmenu
                          ? jobTypes.length < 5
                            ? "0"
                            : "-25vh"
                          : "unset",
                        bottom: isJobTypeSubmenu ? "unset" : "0",
                      }}
                    >
                      <Paper>
                        <SubMenu
                          gotoPage={gotoPage}
                          subMenuType={item.subMenuListProp}
                          jobTypes={jobTypes}
                          handleCreateJobTypeClick={handleCreateJobTypeClick}
                        />
                      </Paper>
                    </Grid>
                  )}
                </>
              )}
              <ExpandedMenuItem
                key={i}
                icon={item.icon}
                label={i18n.t(item.label)}
                selected={activeLink === item.url}
                onMenuItemClick={() => onMenuItemClick(item)}
                subMenu={item.subMenuListProp}
                muiIcon={item.muiIcon}
              />
            </Grid>
          );
        })}
        <ExpandedMenuItem
          key={"CREATE_NEW"}
          label={i18n.t("default:_CREATE_NEW")}
          onMenuItemClick={handleCreateNewClick}
          muiIcon={withMuiIcon(AddCircleIcon)}
          addRow={true}
        />
        <ExpandedMenuItem
          label="TERMS & CONDITIONS"
          selected={activeLink === "/tos"}
          onMenuItemClick={() => gotoPage("Terms & Condition", "/tos")}
          secondaryLabel={window.location.host}
        />
      </List>
    </Drawer>
  );
}

export default connectWithStore(MenuDrawerDesktop, ["user", "settings"]);
