import React, { useState, useEffect } from "react";
import { connectWithStore } from "../../../../services/redux";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import ButtonBlock from "../../../button_block/button_block";
import { CROP_PDF } from "./steps";
import { makeStyles } from "@material-ui/styles";
import i18n from "../../../../i18n";
import { BlueprintEditor } from "../../../../redux/actions";
import {
  convertPixelsToUnit,
  getA4Crop,
  getAreaFromCoords,
  getElementSize,
  getLinearLength,
  getUnit,
  getUnitSize,
} from "../utils";
import moment from "moment";
import { exportBlueprintPdf } from "../../../../services/api/blueprints";
import { CONSTRUCTOR_NAME } from "../constants";
import { getAssetKey } from "../../../../services/api/file_upload";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 10,
  },
  shape: {
    height: 10,
    width: 60,
  },
  headCell: {
    color: "#fff",
    backgroundColor: "#269CE0",
    border: "1px solid #d3d3d3",
  },
  bodyCell: {
    border: "1px solid #d3d3d3",
  },
  middleTable: {
    margin: "20px 0",
  },
  loadingContainer: {
    minHeight: 500,
  },
}));

function PdfPreview({ blueprintEditor, goToStep, dispatch, settings }) {
  const classes = useStyles();
  const [croppedElements, setCroppedElements] = useState([]);
  const [elementsByName, setElementsByName] = useState([]);
  const [length, setLength] = useState(0);
  const [square, setSuqare] = useState(0);
  const [loading, setLoading] = useState(false);
  const { croppedDataUrl, cropSize } = blueprintEditor;

  const getAreaAndSquare = (elements) => {
    let calculateLength = 0;
    let calculatedSquare = 0;

    for (let element of elements) {
      calculatedSquare += element.totalArea;
      calculateLength += Number(element.totalLength);
    }

    return {
      calculateLength,
      calculatedSquare,
    };
  };

  const getElementsByName = (elements) => {
    const elementsByName = {};
    for (let element of elements) {
      if (elementsByName[element.name]) {
        elementsByName[element.name].totalLength += element.totalLength;
        elementsByName[element.name].totalArea += element.totalArea;
      } else {
        elementsByName[element.name] = { ...element };
      }
    }
    return Object.values(elementsByName);
  };

  const isPointWithinArea = (x, y) => {
    if (!cropSize) return false;
    const croppedX = cropSize.x / cropSize.ratio;
    const croppedY = cropSize.y / cropSize.ratio;
    const croppedWidth = (cropSize.width * cropSize.scaleX) / cropSize.ratio;
    const croppedHeight = (cropSize.height * cropSize.scaleY) / cropSize.ratio;
    return (
      x >= croppedX &&
      x <= croppedX + croppedWidth &&
      y >= croppedY &&
      y <= croppedY + croppedHeight
    );
  };

  const arePointsWithin = (points, shape) => {
    let isWithin = false;
    for (let i = 0; i < points.length; i += 2) {
      const x = points[i];
      const y = points[i + 1];
      const absolutePosition = shape.points
        ? shape.getAbsoluteTransform().point({ x, y })
        : { x, y };
      const withinArea = isPointWithinArea(
        absolutePosition.x,
        absolutePosition.y
      );
      if (withinArea) {
        isWithin = true;
        break;
      }
    }
    return isWithin;
  };

  const elementIsInCroppedArea = (shape) => {
    if (!cropSize) {
      return true;
    }
    const x1 = shape.x();
    const x2 = shape.x() + shape.width();
    const y1 = shape.y();
    const y2 = shape.y() + shape.height();
    const points = shape.points
      ? shape.points()
      : [x1, y1, x2, y1, x2, y2, x1, y2];

    if (points) {
      return arePointsWithin(points, shape);
    }
    return false;
  };

  const getTotaLength = (shape, width, height) => {
    if (!shape) return null;
    if (shape.attrs.name === CONSTRUCTOR_NAME.LINE) {
      return Number(width);
    }
    if (shape.attrs.name === CONSTRUCTOR_NAME.RECT) {
      const perimeter = (width + height) * 2;
      return Number(perimeter.toFixed(2));
    }
    if (shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE) {
      const radius = shape.radius();
      const convertedRadius = Number(convertPixelsToUnit(radius, "width"));
      const perimeter = 2 * convertedRadius * Math.PI;
      return Number(perimeter.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.POLYGON ||
      shape.attrs.name === CONSTRUCTOR_NAME.DOODLE
    ) {
      const points = shape.attrs.points;
      const polygonLength = getLinearLength(points);
      return polygonLength;
    }
    return null;
  };

  const getTotalArea = (shape, width, height) => {
    if (!shape) return null;
    //get area of filled elements
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.RECT &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const sqArea = width * height;
      return Number(sqArea.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const radius = shape.radius();
      const convertedRadius = Number(convertPixelsToUnit(radius, "width"));
      const circleArea = Math.PI * convertedRadius * convertedRadius;
      return Number(circleArea.toFixed(2));
    }
    if (
      shape.attrs.name === CONSTRUCTOR_NAME.POLYGON &&
      shape.attrs.fill &&
      shape.attrs.fill !== "rgba(0, 0, 0, 0)"
    ) {
      const points = shape.attrs.points;
      const polygonArea = getAreaFromCoords(points);
      return Number(polygonArea);
    }
    return null;
  };

  useEffect(() => {
    const elements = [];
    const units = getUnit();
    const ids = blueprintEditor.elements
      .filter((e) => e.visible)
      .map((e) => e.id);
    blueprintEditor.jobs.forEach((job) => {
      job.elements
        .filter((elementFilter) => ids.includes(elementFilter._id))
        .forEach((el) => {
          const tools = settings.blueprintSettings.elements.filter(
            (t) => t.id === el.elementId
          );
          if (tools.length > 0) {
            const { width, height } = getElementSize(el.canvasData);
            const elementTarget = blueprintEditor.elements.filter(
              (e) => e.id === el._id
            );
            const targetId =
              elementTarget && elementTarget[0] && elementTarget[0].id;
            const shape = blueprintEditor.app.find("#" + targetId)[0];
            const sizeWidth = getUnitSize(shape, width);

            const convertedWidth =
              shape && shape.attrs && shape.attrs.name === "Polygon"
                ? ""
                : shape && shape.attrs && shape.attrs.name === "Line"
                ? sizeWidth.replace("m", "")
                : Number(convertPixelsToUnit(width, "width"));
            const convertedHeight =
              shape && shape.attrs && shape.attrs.name === "Polygon"
                ? ""
                : Number(convertPixelsToUnit(height, "height"));

            //set length (perimeter) and area of the element
            const totalLength = getTotaLength(
              shape,
              convertedWidth,
              convertedHeight
            );
            const totalArea = getTotalArea(
              shape,
              convertedWidth,
              convertedHeight
            );

            //only push element if is in cropped area
            if (elementIsInCroppedArea(shape)) {
              elements.push({
                blueprint: job,
                element: elementTarget[0],
                color: tools[0].color,
                name: tools[0].name,
                type: tools[0].type,
                width:
                  shape && shape.attrs && shape.attrs.name === "Polygon"
                    ? ""
                    : sizeWidth,
                convertedHeight,
                convertedWidth,
                totalLength,
                totalArea,
              });
            }
          } else {
            elements.push({
              blueprint: job,
              element: el,
              color: { hex: "#000" },
              name: i18n.t("default:_REMOVED_ELEMENT"),
              type: -1,
              width: 0 + units,
            });
          }
        });
    });
    const elsByName = getElementsByName(elements);
    setElementsByName(elsByName);
    setCroppedElements(elements);
    const { calculateLength, calculatedSquare } = getAreaAndSquare(elements);
    setLength(calculateLength);
    setSuqare(calculatedSquare);
    // eslint-disable-next-line
  }, []);

  const getShapeColor = (color) => {
    return color.hex
      ? color.hex
      : `rgba(${color.rgb ? color.rgb.r : color.r}, ${
          color.rgb ? color.rgb.g : color.g
        }, ${color.rgb ? color.rgb.b : color.b}, ${
          color.rgb ? color.rgb.a : color.a
        })`;
  };

  const onDownload = () => {
    setLoading(true);
    const fullSize = blueprintEditor.app.findOne("#blueprint").getSize();
    const croppedSize = cropSize
      ? {
          height: (cropSize.height * cropSize.scaleY) / cropSize.ratio,
          width: (cropSize.width * cropSize.scaleX) / cropSize.ratio,
        }
      : null;
    const a4Crop = croppedSize ? getA4Crop(croppedSize) : getA4Crop(fullSize);
    exportBlueprintPdf({
      blueprintImage: croppedDataUrl,
      elements: croppedElements,
      size: a4Crop ? a4Crop : fullSize,
    })
      .then((response) => {
        if (response.success) {
          window.open(getAssetKey(response.link), "_blank");
        }
        setLoading(false);
        dispatch(BlueprintEditor.cancelCrop());
      })
      .catch(() => setLoading(false));
  };

  const mainCols = ["Color", "Job time", "Job no #", "Name", "Measure"];
  const totalPerNameCols = ["Color", "Name", "Measure"];

  return (
    <Grid container>
      {loading ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.loadingContainer}
        >
          <CircularProgress size={170} />
        </Grid>
      ) : (
        <>
          <Grid style={{ marginBottom: 30 }} container justifyContent="center">
            <img
              height="200"
              width="160"
              src={croppedDataUrl}
              alt="cropped-bp"
            />
          </Grid>
          <Grid>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {mainCols.map((col, i) => {
                    return (
                      <TableCell
                        classes={{ head: classes.headCell }}
                        colSpan={col === "Measure" ? "2" : "1"}
                        align="center"
                        key={i}
                      >
                        {col}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {croppedElements.map((row, i) => {
                  const shapeColor = getShapeColor(row.color);
                  //const splitWidth = row.width.split("x");
                  //const rawWidth = splitWidth[0];
                  //const height = splitWidth[1] ? splitWidth[1] : "";
                  //const width = height ? rawWidth.concat("m") : rawWidth;

                  const date = moment(row.blueprint.job.startDate).format(
                    "DD/MM/YY, HH:mm"
                  );
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        component="th"
                        scope="row"
                      >
                        <div
                          className={classes.shape}
                          style={{ backgroundColor: shapeColor }}
                        ></div>
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {date}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.blueprint.job.ID}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.totalLength ? `${row.totalLength}m` : ""}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.totalArea ? `${row.totalArea}㎡` : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Table
              style={{ width: "auto" }}
              className={classes.middleTable}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{ head: classes.headCell }}
                    colSpan={4}
                    align="center"
                  >
                    Total per name
                  </TableCell>
                </TableRow>
                <TableRow>
                  {totalPerNameCols.map((col, i) => {
                    return (
                      <TableCell
                        classes={{ head: classes.headCell }}
                        colSpan={col === "Measure" ? "2" : "1"}
                        align="center"
                        key={i}
                      >
                        {col}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {elementsByName.map((row, i) => {
                  const shapeColor = getShapeColor(row.color);
                  return (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        component="th"
                        scope="row"
                      >
                        <div
                          className={classes.shape}
                          style={{ backgroundColor: shapeColor }}
                        ></div>
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.totalLength ? row.totalLength.toFixed(2) : ""}
                      </TableCell>
                      <TableCell
                        classes={{ body: classes.bodyCell }}
                        align="center"
                      >
                        {row.totalArea ? row.totalArea : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Table
              style={{ width: "auto" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{ head: classes.headCell }}
                    colSpan={2}
                    align="center"
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    classes={{ body: classes.bodyCell }}
                    align="center"
                  >
                    {"M (length)"}
                  </TableCell>
                  <TableCell
                    classes={{ body: classes.bodyCell }}
                    align="center"
                  >
                    {"M (square)"}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    classes={{ body: classes.bodyCell }}
                    align="center"
                  >
                    {length.toFixed(2)}
                  </TableCell>
                  <TableCell
                    classes={{ body: classes.bodyCell }}
                    align="center"
                  >
                    {square.toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <ButtonBlock position="relative">
            <Button
              className={classes.button}
              color="secondary"
              onClick={() =>
                dispatch(BlueprintEditor.setActiveCropStep(CROP_PDF))
              }
            >
              {i18n.t("default:_BACK")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownload()}
            >
              {i18n.t("default:_DOWNLOAD")}
            </Button>
          </ButtonBlock>
        </>
      )}
    </Grid>
  );
}

export default connectWithStore(PdfPreview, ["blueprintEditor", "settings"]);
