import React, { useCallback, useEffect } from "react";
import { connectWithStore } from "../../services/redux";
import {
  WIZARD_TYPE_JOB,
  WIZARD_TYPE_PROJECT,
  WIZARD_TYPE_USER,
} from "./constants";
import i18n from "../../i18n";
import { withRouter } from "react-router-dom";
import { STEP_CREATE_NEW, STEP_SELECT_JOB_TYPE } from "./steps";
import WizardButtons from "./wizard_buttons";
import { Wizard, WizardJob, WizardProject } from "../../redux/actions";
import { mapJobForAutoselect } from "../../services/job";

function FinalStep({
  wizard,
  history,
  goToStep,
  dismiss,
  dispatch,
  isActive,
  wizardJob,
  wizardProject,
}) {
  const { type } = wizard;
  const { linkId } = wizardJob;

  const getButtonWord = useCallback(() => {
    switch (type) {
      case WIZARD_TYPE_JOB:
        return i18n.t("default:_JOBS");
      case WIZARD_TYPE_PROJECT:
        return i18n.t("default:_PROJECTS");
      case WIZARD_TYPE_USER:
        return i18n.t("default:_USERS");
      default:
        return null;
    }
  }, [type]);

  const getMessageWord = useCallback(() => {
    switch (type) {
      case WIZARD_TYPE_JOB:
        return i18n.t("default:_JOB");
      case WIZARD_TYPE_PROJECT:
        return i18n.t("default:_PROJECT");
      case WIZARD_TYPE_USER:
        return i18n.t("default:_USER");
      default:
        return null;
    }
  }, [type]);

  const getButtonCallback = useCallback(() => {
    switch (type) {
      case WIZARD_TYPE_JOB:
        dispatch(WizardJob.reset());
        history.push("/jobs");
        break;
      case WIZARD_TYPE_PROJECT:
        history.push("/projects");
        break;
      case WIZARD_TYPE_USER:
        history.push("/users");
        break;
      default:
        return null;
    }
    history.go();
  }, [type, history, dispatch]);

  const onMoreClick = () => {
    if (type === WIZARD_TYPE_JOB) {
      dispatch(WizardJob.reset());
    }
    if (type === WIZARD_TYPE_PROJECT) {
      dispatch(WizardProject.reset());
    }
    goToStep(STEP_CREATE_NEW);
  };

  const createRelatedJob = async () => {
    const prevJobRaw = {
      _id: wizardJob.id,
      jobType: wizardJob.jobType,
      ID: wizardJob.linkId,
    };
    const prevJobMapped = mapJobForAutoselect(prevJobRaw);
    const prevJob = [];
    prevJob.push(prevJobMapped);
    dispatch(WizardJob.reset());
    //set newly created job as previous
    let dispatchValue = { prevJob };
    dispatch(WizardJob.setPrevJob(dispatchValue));
    goToStep(STEP_SELECT_JOB_TYPE);
  };

  let buttons = [
    {
      label: i18n.t("default:_MORE"),
      onClick: () => onMoreClick(),
      variant: "white",
    },
    {
      label:
        type === WIZARD_TYPE_USER
          ? i18n.t("default:_GO_TO", {
              type: getButtonWord(),
            })
          : i18n.t("default:_HOME_SCREEN", {
              type: getButtonWord(),
            }),
      onClick: async () => {
        dismiss();
        getButtonCallback();
      },
      variant: "black",
      size: 2,
    },
  ];

  if (type === WIZARD_TYPE_JOB) {
    buttons.push({
      label: i18n.t("default:_GO_TO", {
        type: i18n.t("default:_JOB"),
      }),
      onClick: async () => {
        dismiss();
        dispatch(WizardJob.reset());
        history.push(`/jobs/${linkId}`);
        history.go();
      },
      variant: "white",
      size: 2,
    });
    buttons.push({
      label: i18n.t("default:_CREATE_RELATED_JOB"),
      onClick: () => createRelatedJob(),
      variant: "white",
      size: 2,
    });
  }

  if (type === WIZARD_TYPE_PROJECT) {
    buttons.push({
      label: i18n.t("default:_GO_TO", {
        type: i18n.t("default:_PROJECT"),
      }),
      onClick: async () => {
        dismiss();
        history.push(`/project/view/${wizardProject.id}`);
      },
      variant: "white",
      size: 2,
    });
  }

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(null));
  }, [dispatch, isActive]);

  return (
    <div className="FinalStep ModalBody">
      <div
        className="final-step"
        style={{
          flex: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <i className="mdi mdi-rocket h1" style={{ fontSize: "20rem" }} />
        <h5>
          {i18n.t("default:_WIZARD_SUCCESS", {
            type: getMessageWord(),
          })}
        </h5>
      </div>
      <WizardButtons actions={buttons} />
    </div>
  );
}

export default connectWithStore(withRouter(FinalStep), [
  "wizard",
  "wizardJob",
  "wizardProject",
]);
