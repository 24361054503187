import React, { Component } from "react";
import i18n from "../../../i18n";
import 'draft-js/dist/Draft.css'

import { InputType, QuestionType } from "../../../services/question"; // you will use this inside input component not here.
import { withStyles } from "@material-ui/core/styles";
import Editor from "draft-js-plugins-editor";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createLinkPlugin from "draft-js-anchor-plugin";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import createMentionPlugin from "@draft-js-plugins/mention";
import { ItalicButton, BoldButton, UnderlineButton } from "draft-js-buttons";

import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from "draft-js";
import { getMobileOperatingSystem } from "../../../services/mobile";
import { APP_ANSWERS_RECALL } from "../../../services/app";
import { connectWithStore } from "../../../services/redux";

const styles = {
  textInput: {
    width: "100%",
    maxWidth: "800px",
  },
};

function FileMentionComponent({ mention: { name, value }, children }) {
  return (
    <>
      <span>{value}</span>
      <span style={{ display: "none" }}>{children}</span>
    </>
  );
}

//emoji plugin configuration
const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
});
const { EmojiSelect, EmojiSuggestions } = emojiPlugin;

//plugins configuration
const mentionPlugin = createMentionPlugin({
  mentionTrigger: "$",
  mentionComponent: FileMentionComponent,
});
const { MentionSuggestions } = mentionPlugin;

//anchor plugin configuration
const linkPlugin = createLinkPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin({
  structure: [
    BoldButton,
    ItalicButton,
    UnderlineButton
  ]
});

const { InlineToolbar } = inlineToolbarPlugin;

const regex = /\B\$question\w+?\s/gm;

class Text extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      inputFocused: false,
      opSystem: "unknown",
      openMention: false,
      suggestions: [],
      initialSuggestions: [],
      initiated: false,
    };
  }

  focus = () => {
    this.questionField.focus();
  };

  /* focusInput = () => {
    const { editorState } = this.state;
    const rows = editorState.getCurrentContent() ? editorState.getCurrentContent().getPlainText().split(/\n/) : [];
    if (rows && rows.length < 2) {
      this.setState({
        inputFocused: true,
        editorState: EditorState.moveFocusToEnd(editorState),
      });
    } else {
      this.setState({
        inputFocused: true,
      });
    }
  }; */

  customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) =>
      list.constructor.name === "List" ? list.size : list.length;

    const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        !value || get(suggestion, "name").toLowerCase().indexOf(value) > -1
    );
    const length =
      size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 10;
    return filteredSuggestions.slice(0, length);
  };

  getContentFromProps() {
    if (this.props.answers[this.props.question.ID]) {
      if (
        typeof this.props.answers[this.props.question.ID]["value"] === "string"
      ) {
        /* return createEditorStateWithText(
          this.props.answers[this.props.question.ID]["value"]
        ); */
        return EditorState.push(
          this.state.editorState,
          ContentState.createFromText(
            this.props.answers[this.props.question.ID]["value"]
          )
        );
      }
      if (
        this.props.answers[this.props.question.ID] &&
        this.props.answers[this.props.question.ID].value
      ) {
        let val = this.props.answers[this.props.question.ID].value;
        if (!isNaN(val)) {
          //return createEditorStateWithText(val.toString());
          return EditorState.push(
            this.state.editorState,
            ContentState.createFromText(val.toString())
          );
        }
        if (!val) {
          val = {};
        }
        if (!val.blocks) {
          val.blocks = [];
        }
        if (!val.entityMap) {
          val.entityMap = {};
        }
        //return EditorState.createWithContent(convertFromRaw(val));
        if(val[0]){
          val.blocks.push(val[0]);
          delete val[0];
        }
        return EditorState.push(this.state.editorState, convertFromRaw(val));
      }
    }
    return EditorState.push(
      this.state.editorState,
      ContentState.createFromText("")
    );
  }

  componentDidUpdate() {
    if (!this.state.initiated) {
      const newState = this.getContentFromProps();
      this.setState({
        initiated: true,
        editorState: EditorState.moveFocusToEnd(newState),
      });
    }
  }

  getSuggestions(answers) {
    const suggestions = [];
    if (!answers) {
      return suggestions;
    }
    const filteredAnswers = answers
      .filter(
        (a) =>
          a.question.type !== QuestionType.STEP &&
          a.question.type !== QuestionType.IMAGE &&
          a.question.type !== QuestionType.SUMMARY &&
          a.question.type !== QuestionType.BLUEPRINT
      )
      .filter((a) => a.value)
      .filter((a) =>
        isNaN(a.question.ID)
          ? !a.question.ID.includes(this.props.question.ID.toString())
          : a.question.ID !== this.props.question.ID
      );
    for (let answer of filteredAnswers) {
      let clearValue =
        answer.value && typeof answer.value === "string"
          ? answer.value.split("\n")[0]
          : answer.value;
      const questionID =
        answer.question.ID && isNaN(answer.question.ID)
          ? answer.question.ID.split("_")[0]
          : answer.question.ID;
      let suggestion = {
        name: `$question_${questionID} ${clearValue}`,
        value: clearValue,
      };
      if (Array.isArray(answer.value)) {
        for (let i = 0; i < answer.value.length; i++) {
          clearValue =
            answer.value[i] && typeof answer.value[i] === "string"
              ? answer.value[i].split("\n")[0]
              : answer.value[i];
          let arraySuggestion = {
            name: `$question_${questionID}_${i + 1} ${clearValue}`,
            value: clearValue,
          };
          if (
            typeof answer.value[i] === "object" &&
            !Array.isArray(answer.value[i]) &&
            answer.value[i] !== null
          ) {
            arraySuggestion.name = `$question_${questionID}_${i + 1} ${
              answer.value[i].value
            }`;
            arraySuggestion.value = answer.value[i].value;
          }
          suggestions.push(arraySuggestion);
        }
      } else {
        if (
          typeof answer.value === "object" &&
          !Array.isArray(answer.value) &&
          answer.value !== null
        ) {
          suggestion.name = `$question_${questionID} ${answer.value.value}`;
          suggestion.value = answer.value.value;
        }
        suggestions.push(suggestion);
      }
    }
    return suggestions;
  }

  componentDidMount() {
    const opSystem = getMobileOperatingSystem();
    let cmpData = this.props.answers[this.props.question.ID];
    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value)) {
        this.props.handleValid(true);
      }
    } else {
      this.props.handleValid(false);
    }
    //const newState = this.getContentFromProps();
    const suggestions = this.getSuggestions(this.props.allAnswers);
    this.setState({
      //editorState: EditorState.moveFocusToEnd(newState),
      opSystem,
      suggestions,
      initialSuggestions: suggestions,
    });
  }

  setInputType = () => {
    switch (this.props.question.settings.type) {
      case InputType.TEXT:
        return "text";
      case InputType.EMAIL:
        return "email";
      case InputType.NUMBER:
        return "number";
      default:
        return "text";
    }
  };

  validateEmail(email) {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange = (newState) => {
    //avoid duplication of rows bug
    const lastChangeType =
      newState && newState._immutable && newState._immutable.lastChangeType;
    if (lastChangeType === "apply-entity") return;

    const currentContentState =
      typeof this.state.editorState === "string"
        ? this.state.editorState
        : this.state.editorState.getCurrentContent();
    const newContentState =
      typeof newState === "string" ? newState : newState.getCurrentContent();

    if (!(currentContentState !== newContentState)) {
      if (newState.getDecorator() && !this.state.initiated) {
        this.setState({
          editorState: newState,
        });
      }
      return;
    }
    if (this.props.activeQuestion.ID !== this.props.question.ID) {
      return;
    }
    let val;
    if (typeof newState === "string") {
      if (this.props.fromMultiStepFilter && !newState) {
        return;
      }
      val = newState;
    } else {
      /* if (
        this.props.fromMultiStepFilter &&
        !newState.getCurrentContent().getPlainText()
      ) {
        return;
      } */
      val = convertToRaw(newState.getCurrentContent());
    }
    if (this.props.question.settings.type === InputType.EMAIL) {
      const valid = this.validateEmail(
        val
        /* newState.getCurrentContent().getPlainText() */
      );
      if (!valid) {
        return this.props.handleValid(true);
      }
    }

    this.props.handleChange(val);
    this.setState({
      editorState: newState,
    });
  };

  onOpenChange(_open) {
    this.setState({
      openMention: _open,
    });
  }

  onSearchChange({ value }) {
    this.setState({
      suggestions: this.customSuggestionsFilter(
        value,
        this.state.initialSuggestions
      ),
    });
  }

  render() {
    const {
      editorState,
      inputFocused,
      opSystem,
      openMention,
      suggestions,
    } = this.state;
    return (
      <div className="question-content">
        <div className="form-row">
          <div className="form-group col-md-12">
            {this.setInputType() === "text" && opSystem === "Android" && (
              <textarea
                ref={(element) => {
                  this.questionField = element;
                }}
                rows="1"
                defaultValue={
                  this.props.answers[this.props.question.ID] &&
                  this.props.answers[this.props.question.ID]["value"] &&
                  typeof this.props.answers[this.props.question.ID]["value"] ===
                    "string"
                    ? this.props.answers[this.props.question.ID]["value"]
                    : editorState
                    ? editorState
                        .getCurrentContent()
                        .getPlainText()
                        .replaceAll(regex, "")
                    : ""
                }
                onChange={(event) => this.handleChange(event.target.value)}
                dir="auto"
                //type={this.setInputType()}
                className="form-control free-text"
                name="questionField"
                id="questionField"
                placeholder={i18n.t("default:_TYPE_HERE")}
              />
            )}
            {this.setInputType() === "text" && opSystem !== "Android" && (
              <div
                className="editor-wrapper"
                style={{
                  width: "100%",
                  height: "auto",
                  background: "white",
                  border: inputFocused
                    ? "2px solid #005fcc"
                    : "2px solid #b0bac9",
                }}
                //onClick={() => this.focusInput()}
              >
                <div
                  className="editor"
                  //onClick={() => this.focus()}
                  style={{
                    background: "white",
                    width: "90%",
                    height: "auto",
                    position: 'static'
                  }}
                >
                  <Editor
                    id="questionField"
                    editorState={editorState}
                    onChange={this.handleChange.bind(this)}
                    placeholder={i18n.t("default:_TYPE_HERE")}
                    plugins={[
                      emojiPlugin,
                      linkPlugin,
                      mentionPlugin,
                      inlineToolbarPlugin,
                    ]}
                    ref={(element) => {
                      this.questionField = element;
                    }}
                    style={{ postion: 'relative' }}
                  />
                  {this.props.apps.enabled.includes(APP_ANSWERS_RECALL) && (
                    <MentionSuggestions
                      open={openMention}
                      onOpenChange={this.onOpenChange.bind(this)}
                      suggestions={suggestions}
                      onSearchChange={this.onSearchChange.bind(this)}
                      onAddMention={() => {
                        // get the mention object selected
                      }}
                    />
                  )}
                  <InlineToolbar style={{
                    transform: 'none',
                    visiblity: 'visible'
                  }}>
                    {(externalProps) => (
                      <React.Fragment>
                        <BoldButton {...externalProps} />
                      </React.Fragment>
                    )}
                  </InlineToolbar>
                  <EmojiSuggestions />
                </div>
                <div className="options">
                  <EmojiSelect />
                </div>
              </div>
            )}

            {this.setInputType() !== "text" && (
              <input
                ref={(node) => (this.questionField = node)}
                defaultValue={
                  this.props.answers[this.props.question.ID] &&
                  this.props.answers[this.props.question.ID]["value"]
                }
                onChange={(event) => this.handleChange(event.target.value)}
                dir="auto"
                type={this.setInputType()}
                className="form-control free-text"
                name="questionField"
                id="questionField"
                placeholder={i18n.t("default:_TYPE_HERE")}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connectWithStore(withStyles(styles)(Text), ["apps"]);
