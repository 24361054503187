import React from "react";
import { connectWithStore } from "../../../services/redux";
import UserFieldWrapper from "./field_wrapper";
import SelectWrapper from "../../../components/select_wrapper/select_wrapper";

function UserSelectField({ field, user, value, changeUserProp, multiple }) {
  const path = window.location.pathname;
  const isProfilePage = path.includes("profile");

  const getUserOptions = () => {
    const options = field.options ? field.options : [];
    const transformedOptions = options.map((option) => {
      return {
        value: isNaN(option) ? option.toLowerCase() : option,
        name: option,
      };
    });
    return transformedOptions;
  };

  return (
    <UserFieldWrapper label={field.name}>
      <SelectWrapper
        selectId={field.value}
        value={value}
        handleChange={(e) => changeUserProp(field.value, e.target.value)}
        options={getUserOptions()}
        fullWidth
        multiple={multiple}
        selectStyle={
          isProfilePage
            ? {}
            : {
                height: 50,
              }
        }
        standardVariant={isProfilePage}
      />
    </UserFieldWrapper>
  );
}

export default connectWithStore(UserSelectField, ["user"]);
