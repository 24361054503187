import React, { useCallback } from "react";
import FieldPreview from "./field_preview";
import { CurrentProject } from "../../../redux/actions";
import { updateSignleProjectField } from "../../../services/api/projects";
import { connectWithStore } from "../../../services/redux";
import { Grid } from "@material-ui/core";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import { canUserEdit } from "../../../services/access";
import PrettoSlider from "../../../components/slider/slider";
import { useTheme } from "@material-ui/core/styles";

const marks = Array.from(Array(5).keys()).map((value) => {
  return {
    label: value + 1,
    value: value + 1,
  };
});

function Ranking({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  dispatch,
  user,
}) {
  const theme = useTheme();
  const { project } = currentProject;

  const onUpdate = useCallback(
    async (newValue) => {
      project &&
        (await updateSignleProjectField({
          id: project._id,
          field: {
            id,
            value: newValue,
          },
        }));
      dispatch(
        CurrentProject.updateProject({
          [id]: newValue,
        })
      );
    },
    [dispatch, id, project]
  );
  return (
    <Grid container justifyContent="center">
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.RANKING]}
        accessRules={accessRules}
      >
        {!editable && (
          <PrettoSlider
            style={{ color: theme.palette.primary.main }}
            value={project[id] || 1}
            step={1}
            marks={marks}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={marks[0].value}
            max={marks[marks.length - 1].value}
            onChangeCommitted={(e, value) =>
              canUserEdit(user, accessRules) && onUpdate(value)
            }
          />
        )}
      </FieldPreview>
    </Grid>
  );
}

export default connectWithStore(Ranking, ["currentProject", "user"]);
