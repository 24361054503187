import React from "react";
import { getFileTarget } from "../../../services/api/file_upload";

function QuestionHeader({ jobType, hideBackButton, goBack, job }) {
  return (
    <div className="question-header">
      {!hideBackButton && (
        <div className="back-btn">
          <a onClick={() => goBack()} href="# ">
            <i className="mdi mdi-chevron-left" />
          </a>
        </div>
      )}
      <div className="title" dir="auto">
        {job ? job.project.name : "Project title"}
      </div>
      <div className="jobInfo">
        {!jobType.icon && (
          <div className="EditorView-time">
            <i className="mdi mdi-briefcase-outline" />
          </div>
        )}

        {jobType.icon && (
          <div
            className="EditorView-time"
            style={{
              backgroundImage: `url(${getFileTarget(jobType.icon, "100x100")})`,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default QuestionHeader;
