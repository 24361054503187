import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { getFileTarget } from "../../services/api/file_upload";

const avatar = require("../../assets/img/img_avatar.png");

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70px",
    marginBottom: "5px",
  },
  fileImageContainer: {
    height: "100%",
  },
  fileImage: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  iconImage: {
    background: "#2E384D",
  },
  nameContainer: {
    paddingLeft: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

function MobileUserRow({ user }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} className={classes.fileImageContainer}>
        <img
          className={classes.fileImage}
          src={user.imgUrl ? getFileTarget(user.imgUrl, "100x100") : avatar}
          alt="file avatar"
        />
      </Grid>
      <Grid
        container
        item
        xs={5}
        alignContent="center"
        jusfity="center"
        className={classes.nameContainer}
      >
        {user.name ? `${user.name.first} ${user.name.last}` : user.email}
      </Grid>
      <Grid
        container
        item
        xs={5}
        alignContent="center"
        jusfity="center"
        className={classes.nameContainer}
      >
        {user.role}
      </Grid>
    </Grid>
  );
}

export default MobileUserRow;
