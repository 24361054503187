import {
  TABLE_SET_SELECTED_ROWS,
  TABLE_SET_ROWS,
  TABLE_RIGHT_PANEL_OPEN,
  TABLE_SET_TOTAL_PAGES,
  TABLE_SET_TOTAL,
  TABLE_SET_CURRENT_PAGE,
  TABLE_SET_PAGE_SIZE,
  TABLE_SET_LOADING,
  TABLE_SET_SEARCH,
  TABLE_SET_ACTIVE_FILTERS,
  TABLE_SET_HAS_DEFAULT_FILTERS,
  TABLE_SET_RIGHT_PANEL_WITH_QUESTION,
  TABLE_SET_WITH_RIGHT_PANEL,
  TABLE_SET_TYPE,
  TABLE_INIT,
  TABLE_SET_HAS_ACTIVE_FILTER,
  TABLE_SET_SHOW_FILTERS_MODAL,
  TABLE_SET_SHOW_USERS_MODAL,
  TABLE_SET_TEMP_FILTER_SELECTION,
  TABLE_SET_SHOW_REPORTS_BUILDER,
  TABLE_SET_SHOW_WARNING_MODAL,
  TABLE_SET_SHOW_ACTION_BUTTONS,
  TABLE_SET_SELECT_MODE, GRID_SET_TITLE,
  FILTERS_SET_PROJECT_FIELDS_DATA
} from "../constants";

const initialState = {
  initialized: false,
  type: null,
  rows: [],
  selectedRows: [],
  rightPanelOpen: false,
  totalPages: -1,
  total: 0,
  currentPage: 0,
  pageSize: 20,
  loading: true,
  search: null,
  activeFilters: false,
  hasDefaultFilters: false,
  rightPanelWithQuestion: null,
  withRightPanel: false,
  hasActiveFilter: false,
  showFiltersModal: false,
  tempFilterSelection: {},
  showUserModal: false,
  showReportBuilder: false,
  showWarningModal: false,
  showActionButtons: null,
  selectionMode: false,
  gridTitle: null,
  projectFieldsData: {}
};

const gridTable = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case TABLE_INIT:
      return Object.assign({}, state, {
        initialized: true,
      });
    case TABLE_SET_TYPE:
      return Object.assign({}, state, {
        type: data,
      });
    case TABLE_SET_ROWS:
      return Object.assign({}, state, {
        rows: data,
      });
    case TABLE_SET_SELECTED_ROWS:
      return Object.assign({}, state, {
        selectedRows: data,
      });
    case TABLE_RIGHT_PANEL_OPEN:
      return Object.assign({}, state, {
        rightPanelOpen: data,
      });
    case TABLE_SET_TOTAL_PAGES:
      return Object.assign({}, state, {
        totalPages: data,
      });
    case TABLE_SET_TOTAL:
      return Object.assign({}, state, {
        total: data,
      });
    case TABLE_SET_CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: data,
      });
    case TABLE_SET_PAGE_SIZE:
      return Object.assign({}, state, {
        pageSize: data,
      });
    case TABLE_SET_LOADING:
      return Object.assign({}, state, {
        loading: data,
      });
    case TABLE_SET_SEARCH:
      return Object.assign({}, state, {
        search: data,
      });
    case TABLE_SET_ACTIVE_FILTERS:
      return Object.assign({}, state, {
        activeFilters: data,
      });
    case TABLE_SET_HAS_DEFAULT_FILTERS:
      return Object.assign({}, state, {
        hasDefaultFilters: data,
      });
    case TABLE_SET_RIGHT_PANEL_WITH_QUESTION:
      return Object.assign({}, state, {
        rightPanelWithQuestion: data,
      });
    case TABLE_SET_WITH_RIGHT_PANEL:
      return Object.assign({}, state, {
        withRightPanel: data,
      });
    case TABLE_SET_HAS_ACTIVE_FILTER:
      return Object.assign({}, state, {
        hasActiveFilter: data,
      });
    case GRID_SET_TITLE:
      return Object.assign({}, state, {
        gridTitle: data
      });
    case TABLE_SET_SHOW_FILTERS_MODAL:
      return Object.assign({}, state, {
        showFiltersModal: data,
      });
    case TABLE_SET_TEMP_FILTER_SELECTION:
      return Object.assign({}, state, {
        tempFilterSelection: data,
      });
    case FILTERS_SET_PROJECT_FIELDS_DATA:
      return Object.assign({}, state, {
        projectFieldsData: data
      });
    case TABLE_SET_SHOW_USERS_MODAL:
      return Object.assign({}, state, {
        showUsersModal: data,
      });
    case TABLE_SET_SHOW_REPORTS_BUILDER:
      return Object.assign({}, state, {
        showReportBuilder: data,
      });
    case TABLE_SET_SHOW_WARNING_MODAL:
      return Object.assign({}, state, {
        showWarningModal: data,
      });
    case TABLE_SET_SHOW_ACTION_BUTTONS:
      return Object.assign({}, state, {
        showActionButtons: data,
      });
    case TABLE_SET_SELECT_MODE:
      return Object.assign({}, state, {
        selectionMode: data
      })
    default:
      return state;
  }
};

export default gridTable;
