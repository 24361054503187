import React from "react";
import { connectWithStore } from "../../../services/redux";
//import UserFieldWrapper from "./field_wrapper";
import { deleteAsset } from "../../../services/api/file_upload";
import UserFileField from "./file_field";
import { USER_FIELDS } from "./constants";
import i18n from "../../../i18n";

function FieldWithFiles({ field, value, changeUserProp, children, hideField }) {
  let currentValue = value ? { ...value } : { value: "", files: [] };

  const onAttachment = (attachedFiles) => {
    const fileIds = attachedFiles.map((fileInfo) => fileInfo.assetId);
    let currentFiles = currentValue.files.concat(fileIds);
    currentValue.files = currentFiles;
    changeUserProp(field.value, currentValue);
  };

  const removeAttachment = async (index) => {
    let currentFiles = currentValue.files;

    if (currentFiles && currentFiles[index]) {
      const assetId = currentFiles[index];

      const result = await deleteAsset(assetId);

      if (result && result.success) {
        currentFiles.splice(index, 1);
        currentValue.files = currentFiles;
        changeUserProp(field.value, currentValue);
      }
    }
  };

  const getFieldLabel = () => {
    switch (field.name) {
      case USER_FIELDS.ID.name:
        return i18n.t("default:_ID_FILES");
      case USER_FIELDS.PENSION.name:
        return i18n.t("default:_PENSION_FILES");
      case USER_FIELDS.EMPLOYEE_AGREEMENT.name:
        return i18n.t("default:_EMPLOYEE_AGREEMENT_FILES");
      default:
        return `${field.name} files`;
    }
  };

  return (
    <>
      {children}
      {!hideField && (
        <UserFileField
          files={currentValue && currentValue.files ? currentValue.files : []}
          onAttachment={onAttachment}
          removeAttachment={removeAttachment}
          label={getFieldLabel()}
        />
      )}
    </>
  );
}

export default connectWithStore(FieldWithFiles, ["user"]);
