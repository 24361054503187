import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MenuService from "../../../services/menu";
import { connectWithStore } from "../../../services/redux";
import { PageTitle, TopNavMenu, User, Settings } from "../../../redux/actions";
import i18n from "../../../i18n";
import { isMobileDevice } from "../.././../services/mobile";
import Http from "../../../services/http";
import "./sidebar.scss";
import LoginService from "../../../services/login";
import ToggleService from "../../../services/toggle";
import MenuDrawerMobile from "./menu_drawer_mobile";
import MenuDrawerDesktop from "./menu_drawer_desktop";
import withMuiIcon from "../../../hoc/withMuiIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuList from "./menu_list";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      jobType: this.props.jobType,
      activeLink: this.props.location.pathname,
      activeSubLink: "",
      withJobTypeTemplates: false,
      toggles: this.props.setting && this.props.setting.toggles,
      checkForToggles: false,
    };
  }

  async componentDidMount() {
    if (this.state.jobType.list) {
      this.state.jobType.list.forEach((item) => {
        if (this.props.location.pathname.indexOf(item._id) > -1) {
          this.setState({ activeLink: null, activeSubLink: item._id });
        }
      });
    }
  }

  async checkToggles() {
    const templatesResponse = await ToggleService._isToggleActive(
      "templates",
      this.props.settings.toggles
    );
    this.setState({
      withJobTypeTemplates: templatesResponse,
      checkForToggles: false,
    });
  }

  async componentDidUpdate() {
    if (this.state.checkForToggles) {
      await this.checkToggles();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      JSON.stringify(nextProps.jobType) !== JSON.stringify(prevState.jobType)
    ) {
      return {
        jobType: nextProps.jobType,
      };
    }
    if (
      JSON.stringify(nextProps.settings.toggles) !==
      JSON.stringify(prevState.toggles)
    ) {
      return {
        toggles: nextProps.settings.toggles,
        checkForToggles: true,
      };
    }
    return null;
  }

  gotoPage(nextTitle, url) {
    if (isMobileDevice()) {
      this.props.dispatch(TopNavMenu.setActiveMenu(false));
      this.setState({
        sidebarOpen: false,
      });
    }
    this.switchPage(nextTitle, url);
  }

  switchPage(nextTitle, url) {
    if (this.props.closeMenu) {
      this.props.closeMenu();
    }
    this.props.dispatch(
      PageTitle.setPageTitle({
        title: nextTitle,
      })
    );
    this.activeMenu(url);
    this.props.history.push(url);
  }

  activeMenu = (url) => {
    this.setState({ activeLink: url });
  };

  activeSubMenu = (url) => {
    this.setState({ activeLink: null, activeSubLink: url });
  };

  handleCreateJobTypeClick() {
    const { withJobTypeTemplates } = this.state;
    if (withJobTypeTemplates) {
      this.props.dispatch(TopNavMenu.setActiveMenu(false));
      this.setState(
        {
          sidebarOpen: false,
        },
        () => this.props.toggleModal("jobTypeModalOpen")
      );
    } else {
      this.props.history.push(`/job_type/new`);
    }
  }

  getCustomMenuLabel(currentLabel) {
    if (this.props.settings.menu) {
      const customMenus = this.props.settings.menu
        .filter((menuItem) => menuItem.label === currentLabel)
        .filter((menuItem) => true);
      if (customMenus[0] && customMenus[0].newLabel) {
        return customMenus[0].newLabel;
      }

      return i18n.t(currentLabel);
    }

    return i18n.t(currentLabel);
  }

  logout() {
    if (LoginService.isImpersonating(this.props.settings)) {
      const userProfile = JSON.parse(localStorage.getItem("oldUserProfile"));
      const accessToken = JSON.parse(localStorage.getItem("oldAccessToken"));
      localStorage.removeItem("oldUserProfile");
      localStorage.removeItem("oldAccessToken");

      this.props.dispatch(User.setAccessToken(accessToken));
      this.props.dispatch(User.updateProfileData(userProfile));

      window.location.href = "/";

      return true;
    }
    this.setState({ dropdownProfile: false });
    this.props.dispatch(User.logout());
    this.props.dispatch(Settings.loadAppSettings());
    Http.sharedInstance.resetAxiosHeaders();
    this.props.history.push("/auth");
  }

  handleCreateNewClick() {
    this.props.dispatch(TopNavMenu.setActiveMenu(false));
    this.setState(
      {
        sidebarOpen: false,
      },
      () => this.props.toggleModal("modalOpen")
    );
  }

  render() {
    let { sidebarOpen, activeLink, jobType } = this.state;
    const menuItems = MenuService.getItems()
      .filter((item) => MenuService.filterDisabledMenus(item, this.props.apps, null, this.props.user, this.props.settings))
      .filter((item) => {
        if (item.hasOwnProperty("allowTo")) {
          return item.allowTo.indexOf(this.props.user.profile.role) !== -1;
        }

        return true;
      });

    if (isMobileDevice()) {
      menuItems.push({
        label: "default:_SETTINGS",
        url: "/settings",
        icon: "mdi mdi-settings",
        muiIcon: withMuiIcon(SettingsIcon),
      });
    }

    let menuOpened = false;
    if (this.props.topNavMenu.activeMenu) {
      menuOpened = true;
    } else if (sidebarOpen) {
      menuOpened = true;
    }

    return (
      <>
        {menuOpened && !isMobileDevice() && (
          <MenuDrawerDesktop
            open={menuOpened}
            menuItems={menuItems}
            activeLink={activeLink}
            gotoPage={this.gotoPage.bind(this)}
            jobTypes={jobType ? jobType.list : []}
            handleCreateJobTypeClick={this.handleCreateJobTypeClick.bind(this)}
            handleCreateNewClick={this.handleCreateNewClick.bind(this)}
          />
        )}
        {menuOpened && isMobileDevice() && (
          <MenuDrawerMobile
            open={menuOpened}
            menuItems={menuItems}
            activeLink={activeLink}
            gotoPage={this.gotoPage.bind(this)}
            jobTypes={jobType ? jobType.list : []}
            handleCreateJobTypeClick={this.handleCreateJobTypeClick.bind(this)}
            handleCreateNewClick={this.handleCreateNewClick.bind(this)}
            handleLogoutClick={this.logout.bind(this)}
          />
        )}
        {!menuOpened && !isMobileDevice() && (
          <MenuList
            menuItems={menuItems}
            activeLink={activeLink}
            gotoPage={this.gotoPage.bind(this)}
            jobTypes={jobType ? jobType.list : []}
            handleCreateJobTypeClick={this.handleCreateJobTypeClick.bind(this)}
            handleCreateNewClick={this.handleCreateNewClick.bind(this)}
          />
        )}
      </>
    );
  }
}

export default connectWithStore(withRouter(Sidebar), [
  "jobType",
  "settings",
  "topNavMenu",
  "user",
  "apps",
]);
