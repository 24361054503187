import React, { useCallback, useEffect } from "react";
import i18n from "../../../i18n";
import { getFileTarget } from "../../../services/api/file_upload";
import { isMobileDevice } from "../../../services/mobile";
import { QuestionType } from "../../../services/question";
import "../questions.scss";
import HorizontalLinearStepper from "./stepper";
import { StepQuestions, Questions } from "../../../redux/actions";
import { connectWithStore } from "../../../services/redux";
import StepQuestion from "./step_question";
import StepNavigation from "./step_navigation";
import { Hidden } from "@material-ui/core";
import { findStepOfQuestion } from "./utils";

function Step({
  goToStep,
  ind,
  allQuestions,
  questions,
  step,
  steps,
  job,
  jobType,
  goBack,
  renderSignatureSection,
  renderTabToolbar,
  renderCommentSection,
  answers,
  renderQuestionComponent,
  renderFileModal,
  submitStepQuestions,
  stepQuestions,
  dispatch,
  answerAllQuestions,
  handleChangeSettings,
  fromQuestionnaire,
  jobQuestions,
}) {
  const {
    loadedSteps,
    stepsModalOpen,
    previousStep,
    stepPathActivated,
    lastActiveQuestion,
  } = stepQuestions;
  const { activeQuestion } = jobQuestions;

  const loadedQuestions =
    loadedSteps && loadedSteps[ind] ? loadedSteps[ind] : [];

  const currentStepIndex = allQuestions.indexOf(step);
  const remainingQuestions = allQuestions.slice(
    currentStepIndex + 1,
    allQuestions.length
  );
  const nextStep = remainingQuestions.find(
    (question) => question.type === QuestionType.STEP
  );
  const nextStepIndex = allQuestions.indexOf(nextStep);

  const loadStepQuestions = useCallback(() => {
    const start = currentStepIndex + 1;
    const end =
      nextStepIndex && nextStepIndex !== -1
        ? nextStepIndex
        : allQuestions.length;
    const currentStepQuestions = allQuestions.slice(start, end);
    dispatch(StepQuestions.addStep(currentStepQuestions));
  }, [allQuestions, currentStepIndex, nextStepIndex, dispatch]);

  const changeQuestionFocus = useCallback(() => {
    let myElement =
      questions &&
      questions[activeQuestion] &&
      document.getElementById(questions[activeQuestion].ID);
    if (!myElement) {
      myElement =
        questions &&
        questions[activeQuestion] &&
        document.getElementById(`question_${questions[activeQuestion].ID}`);
    }
    myElement && myElement.scrollIntoView();
  }, [activeQuestion, questions]);

  useEffect(() => {
    changeQuestionFocus();
    if (!loadedSteps[ind] && stepsModalOpen) {
      loadStepQuestions();
    }
  }, [
    changeQuestionFocus,
    loadStepQuestions,
    ind,
    loadedSteps,
    stepsModalOpen,
  ]);

  useEffect(() => {
    if (window.location.hash.includes("question_")) {
      const element = document.getElementById(
        window.location.hash.replace("#", "")
      );
      if (element) element.scrollIntoView();
    }
  }, []);

  const checkAnswers = () => {
    dispatch(StepQuestions.setButtonPressed("next"));
    // find all mandatory questions in the step (including questions with obligatory file upload)
    const mandatoryQuestions = loadedQuestions.filter(
      (question) => question.settings.isMandatory
    );
    const questionsWithoutFiles = loadedQuestions.filter((question) => {
      const filesLength =
        answers[question.ID] && answers[question.ID].files
          ? answers[question.ID].files.length
          : 0;
      const hasFiles = filesLength > 0;
      if (
        question.settings.allowAddingFile &&
        question.settings.fileIsRequired &&
        !hasFiles
      ) {
        return question;
      }
      return null;
    });
    // check if there is unanswered mandatory question
    const unansweredQuestions = mandatoryQuestions.filter(
      (question) =>
        answers[question.ID] &&
        answers[question.ID].value !== 0 &&
        !answers[question.ID].value
    );
    if (
      unansweredQuestions.length === 0 &&
      questionsWithoutFiles.length === 0
    ) {
      return true;
    }
    // if there  are unansweredQuestions set the active question to the first of them and move page to it
    let firstEmptyAnswerIndex = questions.indexOf(unansweredQuestions[0]);
    if (firstEmptyAnswerIndex === -1) {
      firstEmptyAnswerIndex = questions.indexOf(questionsWithoutFiles[0]);
    }
    if (firstEmptyAnswerIndex !== -1) {
      dispatch(Questions.setActiveQuestion(firstEmptyAnswerIndex));
    }
    return false;
  };

  const comparePathToAnswers = (questionWithPath, answerToCompare) => {
    //check all path options and return the first that matches its condition with the answer
    let nextStepInfo = null;
    for (let pathOption of questionWithPath.settings.path) {
      const nextQuestionId = pathOption.toQuestion;
      const condition = pathOption.value;

      if (
        condition === answerToCompare.value ||
        (condition === "any" && answerToCompare.value) ||
        (condition === "" &&
          (answerToCompare.value === "" || answerToCompare.value === null))
      ) {
        if (
          condition === "any" &&
          answerToCompare.value &&
          Array.isArray(answerToCompare.value) &&
          answerToCompare.value.length === 0
        ) {
          continue;
        }
        const nextQuestion = allQuestions.filter(
          (q) => q.ID === Number(nextQuestionId)
        )[0];
        const questionIndex = allQuestions.indexOf(nextQuestion);
        const step = findStepOfQuestion(allQuestions, questionIndex);
        const stepQuestions = allQuestions.filter(
          (q) => q.type === QuestionType.STEP
        );
        const nextPathStep = stepQuestions.indexOf(step) + 1;
        const nextQuestionIndex = questions.indexOf(nextQuestion);
        const isQuestionInArray = (q) => q.ID === questionWithPath.ID;
        const lastQuestionIndex = questions.findIndex(isQuestionInArray);
        nextStepInfo = {
          nextPathStep,
          nextQuestionId,
          nextQuestionIndex,
          lastQuestionIndex,
        };
        break;
      }
    }
    return nextStepInfo;
  };

  const getNextStep = (response) => {
    if (!response) return;
    const { result } = response;
    const questionsWithPath = loadedQuestions.filter(
      (q) => q.settings.pathOrder && q.settings.path.length > 0
    );
    const questionWithPath =
      questionsWithPath.length > 0 ? questionsWithPath[0] : null;
    if (!questionWithPath) return;

    const answersToCompare =
      result &&
      result.length > 0 &&
      questionWithPath &&
      questionWithPath.answer &&
      result.filter((a) => a.ID === questionWithPath.answer.ID);
    const answerToCompare =
      answersToCompare && answersToCompare.length > 0 && answersToCompare[0];
    if (!answerToCompare) return;

    //compare path values to answers and return the required info for next step
    return comparePathToAnswers(questionWithPath, answerToCompare);
  };

  const onNext = async () => {
    const canProceed = checkAnswers();
    if (canProceed) {
      dispatch(StepQuestions.setButtonPressed(null));
      dispatch(StepQuestions.setPreviousStep(ind + 1));
      window.location.hash = "";
      const surveyResponse = await answerAllQuestions(loadedQuestions, true);
      const pathResponse = getNextStep(surveyResponse);
      if (pathResponse) {
        const {
          nextPathStep,
          nextQuestionIndex,
          lastQuestionIndex,
        } = pathResponse;
        dispatch(StepQuestions.setLastActiveQuestion(lastQuestionIndex));
        dispatch(Questions.setActiveQuestion(nextQuestionIndex));
        dispatch(StepQuestions.setStepPathActivated(true));
        goToStep(nextPathStep);
        //window.location.hash = `#question_${nextQuestionId}`;
      } else {
        goToStep(ind + 2);
        dispatch(StepQuestions.setStepPathActivated(false));
      }
    }
  };

  const resetStepQuestions = () => {
    dispatch(StepQuestions.setButtonPressed(null));
    dispatch(StepQuestions.setSteps([]));
    dispatch(StepQuestions.setStepsModalOpen(false));
    dispatch(StepQuestions.setPreviousStep(null));
    dispatch(StepQuestions.setStepPathActivated(false));
    dispatch(StepQuestions.setLastActiveQuestion(null));
  };

  const onSubmit = () => {
    const canProceed = checkAnswers();
    if (canProceed) {
      submitStepQuestions(loadedQuestions);
      resetStepQuestions();
    }
  };

  const onBack = () => {
    if (stepPathActivated && lastActiveQuestion) {
      const previousSteps = loadedSteps.slice(0, previousStep);

      dispatch(StepQuestions.setSteps(previousSteps));
      dispatch(Questions.setActiveQuestion(lastActiveQuestion));
      dispatch(StepQuestions.setStepPathActivated(false));
    }
    window.location.hash = "";
    goToStep(previousStep ? previousStep : ind);
  };

  const onQuestionsExit = () => {
    resetStepQuestions();
    goBack();
  };

  return (
    <div className="question" style={{ height: "99vh", position: "relative" }}>
      <div style={{ height: "10vh" }} className="question-header">
        {!fromQuestionnaire && (
          <div className="back-btn">
            <a onClick={() => onQuestionsExit()} href="# ">
              <i className="mdi mdi-chevron-left" />
            </a>
          </div>
        )}
        {!isMobileDevice() && (
          <div className="title" dir="auto" style={{ width: "20%" }}>
            {job ? job.project.name : "Project title"}
          </div>
        )}
        <div
          style={{
            width: isMobileDevice() ? "90%" : "70%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HorizontalLinearStepper
            activeStep={ind}
            steps={steps}
            jobType={jobType}
          />
        </div>
        {!isMobileDevice() && (
          <div style={{ width: "5%" }} className="jobInfo">
            {!jobType.icon && (
              <div className="EditorView-time">
                <i className="mdi mdi-briefcase-outline" />
              </div>
            )}
            {jobType.icon && (
              <div
                className="EditorView-time"
                style={{
                  backgroundImage: `url(${getFileTarget(
                    jobType.icon,
                    "100x100"
                  )})`,
                }}
              />
            )}
          </div>
        )}
      </div>
      <div
        style={{
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        <Hidden smDown>
          <StepNavigation
            questions={allQuestions}
            goToStep={goToStep}
            active={ind}
            activeStep={step}
          />
        </Hidden>
        {loadedQuestions &&
          loadedQuestions
            .filter(
              (question) => question && question.type !== QuestionType.BLUEPRINT
            )
            .map((question, i) => (
              <StepQuestion
                key={i}
                answers={answers}
                question={question}
                questions={questions}
                renderTabToolbar={renderTabToolbar}
                renderSignatureSection={renderSignatureSection}
                renderCommentSection={renderCommentSection}
                renderQuestionComponent={renderQuestionComponent}
                renderFileModal={renderFileModal}
                handleChangeSettings={handleChangeSettings}
                job={job}
              />
            ))}
        <div className="question-actions">
          <div>
            <button
              disabled={ind === 0}
              onClick={() => onBack()}
              className="btn btn-rounded btn-primary btn-back"
            >
              {i18n.t("default:_BACK")}
            </button>
          </div>
          {nextStepIndex && nextStepIndex !== -1 ? (
            <div>
              <button
                onClick={() => onNext()}
                className="btn btn-rounded btn-primary"
              >
                {i18n.t("default:_NEXT")}
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => onSubmit()}
                className="btn btn-rounded btn-primary"
              >
                {i18n.t("default:_SUBMIT")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default connectWithStore(Step, ["stepQuestions", "jobQuestions"]);
