import Http from "../http";

export const getSkillsList = async (params) => {
  const path = `/v1/skills`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path, {
      params: params,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getSkill = async (skillId) => {
  const path = `/v1/skills/${skillId}`;
  try {
    const response = await Http.sharedInstance.withAuth().get(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const createSkill = async (params) => {
  const path = `/v1/skills`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateSkill = async (params) => {
  const path = `/v1/skills/${params._id}`;
  try {
    const response = await Http.sharedInstance.withAuth().patch(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const deleteSkill = async (params) => {
  const path = `/v1/skills/${params._id}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const assignSkillsToUser = async (params, userId) => {
  const path = `/v1/skills/user/${userId}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const removeSkillsFromUser = async (params, userId) => {
  const path = `/v1/skills/user/${userId}`;
  try {
    const response = await Http.sharedInstance.withAuth().delete(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const assignSkillsToJob = async (params, jobId) => {
  const path = `/v1/skills/job/${jobId}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const assignSkillsToJobType = async (params, jobTypeId) => {
  const path = `/v1/skills/jobType/${jobTypeId}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, params);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const updateSkillExpiration = async ({
  skillId,
  userId,
  expiration,
}) => {
  const path = `/v1/skills/${skillId}/expiration/${userId}`;
  try {
    const response = await Http.sharedInstance.withAuth().post(path, {
      expiration,
    });
    return response.data;
  } catch (e) {
    return [];
  }
};
