import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: ({ fullWidth }) => (fullWidth ? "100%" : "90%"),
  },
  select: {
    zIndex: "99999 !important",
  },
}));

function SelectWrapper({
  label,
  labelId,
  selectId,
  value,
  handleChange,
  options,
  multiple,
  fullWidth,
  standardVariant,
  children,
  selectStyle,
}) {
  const classes = useStyles({ fullWidth });

  if (multiple && !value) {
    value = [];
  }

  return (
    <FormControl
      variant={standardVariant ? "standard" : "outlined"}
      className={classes.formControl}
    >
      {label && (
        <InputLabel id="simple-select-outlined-label">{label}</InputLabel>
      )}
      <Select
        labelId={labelId ? labelId : label}
        id={selectId}
        value={value}
        onChange={handleChange}
        label={label}
        multiple={multiple}
        style={selectStyle ? selectStyle : {}}
        //MenuProps={{ className: classes.select }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {children
          ? children
          : options.map((option, i) => {
              return (
                <MenuItem key={i} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>
  );
}

export default SelectWrapper;
