import React, { useState } from "react";
import i18n from "../../i18n";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { isMobileDevice } from "../../services/mobile";

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    minHeight: 200,
  },
  button: {
    width: isMobileDevice() ? "auto" : 165,
  },
}));

function ReportName({ createReportFromTemplate, template }) {
  const classes = useStyles();
  const [name, setName] = useState(template ? template.name : "");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Grid container>
      <Grid
        className={classes.nameContainer}
        container
        alignItems="center"
        justify="center"
        item
      >
        <TextField
          value={name}
          onChange={handleNameChange}
          label="Enter report name"
          variant="outlined"
        />
      </Grid>
      <Grid container item alignItems="center" justify="space-around">
        <Button
          variant="contained"
          color="primary"
          disabled={!name}
          className={classes.button}
          onClick={() => createReportFromTemplate(name)}
        >
          {i18n.t("default:_CONFIRM")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ReportName;
