import React, { useState, useCallback, useEffect } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { STEP_FINAL, STEP_SELECT_COMPANIES } from "../steps";
import { Wizard, WizardUser } from "../../../redux/actions";
import { getAllProjectList } from "../../../services/api/projects";
import ListWizard from "../list_wizard";
import { getFileTarget } from "../../../services/api/file_upload";
import { createUser } from "../../../services/api/user";
import { WIZARD_TYPE_USER } from "../constants";
import Alert from "../../alert/alert";

const defaultProjectAvatar = require("../../../assets/img/project-placeholder.png");

function SelectUserProjects({
  goToStep,
  wizardUser,
  dispatch,
  isActive,
  fromUserEditor,
  selectedProjects,
}) {
  const [projects, setProjects] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertLabel, setAlertLabel] = useState("");

  const closeAlert = () => {
    setShowAlert(false);
  };

  const onProjectChange = (val) => {
    dispatch(WizardUser.setProjects({ projects: val }));
  };

  const getProjects = useCallback(async () => {
    const data = await getAllProjectList({ companies: wizardUser.companies });
    if (data) {
      setProjects(data.docs);
    }
  }, [wizardUser.companies]);

  const getAvatarCircle = (item) => {
    if (item.avatar) {
      return (
        <img src={getFileTarget(item.avatar, "100x100")} alt={item.name} />
      );
    } else {
      return <img src={defaultProjectAvatar} alt={item.name} />;
    }
  };

  useEffect(
    () => {
      if (isActive)
        dispatch(Wizard.setTitle(i18n.t("default:_SELECT_PROJECTS")));
      getProjects();
    },
    // eslint-disable-next-line
    [dispatch, isActive]
  );

  return (
    <div
      className="SelectProject ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {fromUserEditor && (
        <h4 style={{ margin: "40px" }}>{i18n.t("default:_SELECT_PROJECTS")}</h4>
      )}
      {showAlert && (
        <Alert
          success={false}
          label={alertLabel}
          closeAlert={() => closeAlert()}
        ></Alert>
      )}
      <ListWizard
        items={projects}
        selectionType={wizardUser.projects}
        onSelectionChange={onProjectChange}
        getAvatarCircle={getAvatarCircle}
        isMultiSelect={true}
        selectedProjects={selectedProjects}
      />
      {!fromUserEditor && (
        <WizardButtons
          actions={[
            {
              label: i18n.t("default:_BACK"),
              onClick: () => goToStep(STEP_SELECT_COMPANIES),
              variant: "white",
            },
            {
              label: i18n.t(`default:_FINISH`),
              onClick: async () => {
                let data = {
                  email: wizardUser.email,
                  role: wizardUser.role.value,
                  companies: wizardUser.companies,
                  client_projects: wizardUser.projects.map((p) => p._id),
                };
                const response = await createUser(data);
                if (response.success) {
                  dispatch(Wizard.setType(WIZARD_TYPE_USER));
                  goToStep(STEP_FINAL);
                } else {
                  setShowAlert(true);
                  setAlertLabel(response.data.errors.error);
                }
              },
              variant: "black",
              disabled: wizardUser.projects && wizardUser.projects.length === 0,
              size: 2,
            },
          ]}
        />
      )}
    </div>
  );
}

export default connectWithStore(SelectUserProjects, ["settings", "wizardUser"]);
