import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    height: "100%",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 5,
    display: "flex",
  },
  popper: {
    zIndex: 999999,
    position: "absolute",
  },
}))(Tooltip);

export default HtmlTooltip;
