import React, { Component } from "react";
import { feedsUsers } from "../../../services/api/user";
import { connectWithStore } from "../../../services/redux";
import { NotificationPanel } from "../../../redux/actions";
import i18n from "../../../i18n";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import "./notification_panel.scss";
import DateService from "../../../services/date";
import LoginService from "../../../services/login";
import { getFileTarget } from "../../../services/api/file_upload";

const avatar = require("../../../assets/img/project-placeholder.png");

class NotificationsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feeds: [],
    };
  }

  setFeeds(feeds) {
    const groups = {};
    feeds.map((feed) => {
      if (feed.date) {
        const today = moment();
        const dayDiff = today.diff(moment(feed.date), "days");

        if (!groups[dayDiff] && dayDiff <= 7) {
          groups[dayDiff] = [];
        }

        if (dayDiff <= 7) {
          groups[dayDiff].push(feed);
        } else {
          if (!groups["else"]) {
            groups["else"] = [];
          }

          groups["else"].push(feed);
        }
      }

      return feed;
    });

    this.setState({
      feeds: groups,
    });
  }

  async getFeeds(unread = true) {
    const feeds = await feedsUsers({
      id: this.props.user.profile.id,
      unread,
    });

    if (unread === true) {
      this.props.dispatch(NotificationPanel.setUnreadCount(feeds.length));
      await this.getFeeds(false);
      return;
    }

    if (feeds && feeds.length > 0) {
      this.setFeeds(feeds);
    }
  }

  async componentDidMount() {
    await this.getFeeds();
  }

  onItemClick(feed) {
    if (feed.idProject) {
      window.location.href = "/project/view/" + feed.idProject;
    } else if (feed.intIdJob) {
      window.location.href = "/jobs/" + feed.intIdJob;
    } else if (feed.idJobType) {
      window.location.href = "/job_type/editor/" + feed.idJobType;
    }
  }

  renderFeed(feed, index) {
    return (
      <div className="NotificationsModal_body_group_item d-flex" key={index}>
        <img
          className="NotificationsModal_body_group_item_avatar"
          src={feed.icon ? getFileTarget(feed.icon, "100x100") : avatar}
          alt="test"
        />
        <div
          data-tip={feed.message}
          data-place="top"
          className="NotificationsModal_body_group_item_message"
          onClick={() => this.onItemClick(feed)}
        >
          <p dangerouslySetInnerHTML={{ __html: feed.message }}></p>
          <span>{DateService.getFormattedDate(feed.date)}</span>
        </div>
      </div>
    );
  }

  close() {
    this.props.dispatch(NotificationPanel.hide());
  }

  getNameOfDay(dayNumber) {
    if (dayNumber === "else") {
      return i18n.t("default:_MORE_THAN_7_DAYS");
    }

    const todayDay = moment().format("dddd");
    const dayName = moment().subtract(dayNumber, "days").format("dddd");

    if (todayDay === dayName) {
      return i18n.t("default:_TODAY");
    }

    return i18n.t("default:_" + dayName.toUpperCase());
  }

  render() {
    return (
      <div
        className="NotificationPanel"
        style={{
          backgroundColor: LoginService.isImpersonating(this.props.settings)
            ? "#FFCCCC"
            : null,
        }}
      >
        <ReactTooltip effect="solid" />
        <div className="NotificationsModal_header">
          <div
            onClick={() => this.close()}
            style={{ fontSize: 22 }}
            className="close-modal-mobile pointer"
          >
            <i className="mdi mdi-chevron-left" />
          </div>
        </div>
        <div className="NotificationsModal_body container">
          {Object.keys(this.state.feeds).map((group, groupIndex) => {
            return (
              <div className="NotificationsModal_body_group" key={groupIndex}>
                <p className="NotificationsModal_body_group_title">
                  {this.getNameOfDay(group)}
                </p>
                <div>
                  {this.state.feeds[group].map((feed, index) =>
                    this.renderFeed(feed, index)
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connectWithStore(NotificationsModal, ["user", "settings"]);
