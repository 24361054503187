import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ExpandedMenuItem from "./expanded_menu_item";
import { Grid, Collapse } from "@material-ui/core";
import i18n from "../../../i18n";
import Logo from "../logo/logo";
import { connectWithStore } from "../../../services/redux";
import { getDisplayName } from "../../../services/user";
import SubMenu from "./sub_menu";
import withMuiIcon from "../../../hoc/withMuiIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LoginService from "../../../services/login";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 65,
    width: "100%",
    zIndex: "998",
    minHeight: "100vh",
    height: "100%",
    overflowY: "visible",
  },
  impersonating: {
    backgroundColor: "#FFCCCC",
  },
  logoContainer: {
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
});

function MenuDrawerMobile({
  open,
  menuItems,
  activeLink,
  gotoPage,
  user,
  jobTypes,
  handleCreateJobTypeClick,
  handleCreateNewClick,
  handleLogoutClick,
  settings,
}) {
  const classes = useStyles();
  const [expandedSubMenu, setExpandedSubMenu] = useState(null);

  const onMenuItemClick = (item) => {
    if (item.subMenuListProp) {
      setExpandedSubMenu(
        expandedSubMenu && expandedSubMenu === item.subMenuListProp
          ? null
          : item.subMenuListProp
      );
    } else {
      if (!item.url) return;
      gotoPage(i18n.t(item.label), item.url);
    }
  };

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={() => console.log("close drawer")}
      classes={{
        paper: `${classes.root} ${
          LoginService.isImpersonating(settings) ? classes.impersonating : ""
        }`,
      }}
      variant={"persistent"}
    >
      <Grid style={{ maxHeight: menuItems && menuItems.length > 6 && "70px" }}>
        <span className={classes.logoContainer}>
          <a
            href="# "
            onClick={() => gotoPage(i18n.t("default:_DASHBOARD"), "/dashboard")}
          >
            <Logo collapsed={!open} hideSecondary={true} />
          </a>
        </span>
      </Grid>
      <List component="nav" aria-labelledby="nested-list-menu">
        <ExpandedMenuItem
          icon={user.profile.imgUrl ? null : "mdi mdi-account"}
          avatar={user.profile.imgUrl ? user.profile.imgUrl : null}
          label={getDisplayName(user.profile)}
          selected={activeLink === "/profile"}
          onMenuItemClick={() =>
            gotoPage(i18n.t("default:_PERSONAL_DATA"), "/profile")
          }
          muiIcon={withMuiIcon(AccountCircleIcon)}
        />
        <ExpandedMenuItem
          key={"CREATE_NEW"}
          label={i18n.t("default:_CREATE_NEW")}
          onMenuItemClick={handleCreateNewClick}
          muiIcon={withMuiIcon(AddCircleIcon)}
          addRow={true}
        />
        {menuItems.map((item, i) => {
          const expanded = expandedSubMenu === item.subMenuListProp;
          return (
            <>
              <ExpandedMenuItem
                key={i}
                icon={item.icon}
                label={i18n.t(item.label)}
                selected={activeLink === item.url}
                onMenuItemClick={() => onMenuItemClick(item)}
                subMenu={item.subMenuListProp}
                expanded={expanded}
                muiIcon={item.muiIcon}
              />
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <SubMenu
                  gotoPage={gotoPage}
                  subMenuType={item.subMenuListProp}
                  jobTypes={jobTypes}
                  handleCreateJobTypeClick={handleCreateJobTypeClick}
                />
              </Collapse>
            </>
          );
        })}
        <ExpandedMenuItem
          label={
            LoginService.isImpersonating(settings)
              ? i18n.t("default:_RETURN_TO_ACCOUNT")
              : i18n.t("default:_LOGOUT")
          }
          onMenuItemClick={handleLogoutClick}
          muiIcon={withMuiIcon(ExitToAppIcon)}
          logoutRow={true}
        />
        <ExpandedMenuItem
          label="TERMS & CONDITIONS"
          selected={activeLink === "/tos"}
          onMenuItemClick={() => gotoPage("Terms & Condition", "/tos")}
          secondaryLabel={window.location.host}
        />
      </List>
    </Drawer>
  );
}

export default connectWithStore(MenuDrawerMobile, ["user", "settings"]);
