import { WIZARD_SET_TYPE, WIZARD_SET_TITLE } from "../constants";

const initialState = {
  type: null,
  title: null,
};

const wizardJob = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case WIZARD_SET_TYPE:
      return Object.assign({}, state, {
        type: data,
      });
    case WIZARD_SET_TITLE:
      return Object.assign({}, state, {
        title: data,
      });
    default:
      return state;
  }
};

export default wizardJob;
