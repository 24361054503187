import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DateService from "../../services/date";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import i18n from "../../i18n";
import { getUserActivities } from "../../services/api/user";

function UserActivities({ user }) {
  const [activities, setActivities] = useState([]);
  const [activitiesPage, setActivitiesPage] = useState(1);
  const [totalActivitiesPages, setTotalActivitiesPages] = useState(1);

  const getActivities = useCallback(async () => {
    const params = {
      page: activitiesPage,
      userId: user._id,
    };
    const response = await getUserActivities(params);
    const userActivities = response.docs ? response.docs : [];
    const totalPages = response.pages ? response.pages : 1;
    setActivities(userActivities);
    setTotalActivitiesPages(totalPages);
  }, [activitiesPage, user._id]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  const loadMoreResults = () => {
    setActivitiesPage(activitiesPage + 1);
  };

  const loadPreviousResults = () => {
    setActivitiesPage(activitiesPage - 1);
  };

  const getActivityUrl = (activity) => {
    if (!activity || !activity.tokens) {
      return null;
    }

    if (activity.tokens.job) {
      return "/jobs/" + activity.tokens.job;
    }

    if (activity.tokens.idProject) {
      return "/project/view/" + activity.tokens.project;
    }

    if (activity.tokens.jobType) {
      return "/job_type/editor/" + activity.tokens.jobType;
    }
  };

  return (
    <div className="col-md-12 m-b-30">
      <p className="editor-title">{i18n.t("default:_RECENT_ACTIVITY")}</p>
      <div className="activities">
        {activities &&
          activities.length > 0 &&
          activities.map((activity, i) => (
            <div key={i} className="activity__item d-flex flex-column">
              <a
                href={getActivityUrl(activity)}
                className="activity__item_text w-100"
              >
                {activity.message}
              </a>
              <span className="activity__item__date text-right text-muted">
                {DateService.humanize(activity.created_at)}
              </span>
            </div>
          ))}
      </div>
      <Grid container justifyContent="center">
        {activitiesPage > 1 && (
          <Button color="primary" onClick={() => loadPreviousResults()}>
            <ArrowBackIosIcon />
          </Button>
        )}
        {totalActivitiesPages > Number(activitiesPage) && (
          <Button color="primary" onClick={() => loadMoreResults()}>
            {i18n.t("default:_LOAD_MORE")}
          </Button>
        )}
      </Grid>
    </div>
  );
}

export default UserActivities;
