import React from "react";
import StepWizard from "react-step-wizard";
import { connectWithStore } from "../../../../services/redux";
import BlueprintPdfGenerator from "./bp_pdf_generator";
import LoadingStep from "./loading_step";
import PdfPreview from "./pdf_preview";

function PdfExportWizard({ blueprintEditor }) {
  const { activeCropStep } = blueprintEditor;
  return (
    <StepWizard
      className="stepWizard"
      isLazyMount={true}
      initialStep={activeCropStep}
    >
      <BlueprintPdfGenerator />
      <PdfPreview />
      <LoadingStep />
    </StepWizard>
  );
}

export default connectWithStore(PdfExportWizard, ["blueprintEditor"]);
