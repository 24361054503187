import { SSO_SET_SETTINGS } from "../constants";

const initialState = {
  ssoSettings: null,
};

const sso = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case SSO_SET_SETTINGS:
      return Object.assign({}, state, {
        ssoSettings: data,
      });
    default:
      return state;
  }
};

export default sso;
