import React, { useState } from "react";
import i18n from "../../../../i18n";
import { connectWithStore } from "../../../../services/redux";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { TopNavMenu, WizardJob } from "../../../../redux/actions";
import Confirm from "../../../../components/confirm/confirm";
import {
  removePrevious,
  view,
  addPrevious,
} from "../../../../services/api/jobs";
import withModal from "../../../../hoc/withModal";
import ButtonBlock from "../../../../components/button_block/button_block";
import Button from "@material-ui/core/Button";
import JobListItem from "../../../../components/jobs/job_list_item";
import PreviousJobAutoselect from "../../../../components/autoselect/previous_job_autoselect";

const useStyles = makeStyles((theme) => ({
  circleJobButton: {
    width: "24px",
    height: "24px",
  },
}));

function PreviousJob({ currentJob, setCurrentJob, user, dispatch }) {
  const classes = useStyles();
  const [removePrevJobConfirm, setRemovePrevJobConfirm] = useState(false);
  const [removePrevJobTarget, setRemovePrevJobTarget] = useState(null);
  const [modalPreviousJobsOpen, setModalPreviousJobsOpen] = useState(null);
  const [previousJobsSelection, setPreviousJobsSelection] = useState([]);

  const onRemovePrevJob = (job) => {
    setRemovePrevJobConfirm(true);
    setRemovePrevJobTarget(job._id);
  };

  const removePrevJob = async () => {
    await removePrevious(currentJob._id, removePrevJobTarget);

    const changedJob = { ...currentJob };

    changedJob.prevJob = currentJob.prevJob.filter(
      (job) => job._id.toString() !== removePrevJobTarget.toString()
    );

    setCurrentJob(changedJob);
    setRemovePrevJobConfirm(false);
    setRemovePrevJobTarget(null);
  };

  const addPreviousJobs = async () => {
    for (const job of previousJobsSelection) {
      const result = await addPrevious(currentJob._id, job.value);

      if (result && result.success) {
        const jobInfo = await view(job.value);
        const changedJob = { ...currentJob };

        if (!changedJob.prevJob) {
          changedJob.prevJob = [jobInfo];
        } else {
          changedJob.prevJob.push(jobInfo);
        }
        setCurrentJob(changedJob);
      }
    }
    setPreviousJobsSelection([]);
  };

  const onPreviousJobsSelect = async () => {
    console.log('added');
    await addPreviousJobs();
    setModalPreviousJobsOpen(false);
  };

  const onPreviousJobChange = (val) => {
    if (val.length > 0) {
      if (currentJob._id.toString() === val[0].value.toString()) {
        return false;
      }

      let newPreviousJobsSelection = [];

      for (const job of val) {
        newPreviousJobsSelection.push(job);
      }

      setPreviousJobsSelection(newPreviousJobsSelection);
    }
  };

  const renderPreviousJobsModal = () => {
    const PreviousJobsModal = withModal(PreviousJobAutoselect);

    return (
      <PreviousJobsModal
        title={i18n.t("default:_PREVIOUS_JOB")}
        visible={modalPreviousJobsOpen}
        onClose={() => setModalPreviousJobsOpen(false)}
        onJobChange={(job) => onPreviousJobChange(job)}
        selectedSuggestions={previousJobsSelection}
        FooterComponent={() => (
          <ButtonBlock>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onPreviousJobsSelect()}
              style={{ marginBottom: 20 }}
            >
              {i18n.t("default:_SELECT")}
            </Button>
          </ButtonBlock>
        )}
      />
    );
  };

  return (
    <div className="m-b-30">
      {modalPreviousJobsOpen && renderPreviousJobsModal()}
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_REMOVE_PREVIOUS_JOB", {
          jobWord: i18n.t("default:_JOBS"),
        })}
        show={removePrevJobConfirm}
        onConfirm={() => removePrevJob()}
        onClose={() => setRemovePrevJobConfirm(false)}
      />
      <p className="editor-title">
        {i18n.t("default:_PREVIOUS_JOBS", {
          jobWord: i18n.t("default:_JOBS"),
        })}
      </p>
      <div className="row px-4 px-md-0">
        <div className="col col-12 col-md-6">
          {currentJob.prevJob && (
            <ul className="list-group">
              {currentJob.prevJob.map((job, index) => (
                <JobListItem
                  key={`prev_${index}`}
                  onItemClick={onRemovePrevJob}
                  job={job}
                  userRole={user.profile.role}
                />
              ))}
            </ul>
          )}
        </div>
        <div className="col col-12 col-md-6">
          {user.profile.role !== "inspector" && (
            <React.Fragment>
              <IconButton
                classes={{ label: classes.circleJobButton }}
                onClick={() => setModalPreviousJobsOpen(true)}
                data-tip={i18n.t("default:_SELECT_JOB", {
                  jobWord: i18n.t("default:_JOB").toLowerCase(),
                })}
              >
                <i className="mdi  mdi-link-variant"></i>
              </IconButton>
              <IconButton
                classes={{
                  label: classes.circleJobButton,
                }}
                onClick={() => {
                  dispatch(
                    WizardJob.setNextJob({
                      nextJob: currentJob._id,
                    })
                  );
                  dispatch(TopNavMenu.openJobWizard(true));
                  dispatch(TopNavMenu.openWizard(true));
                }}
                data-tip={i18n.t("default:_CREATE_NEW")}
              >
                <i className="mdi  mdi-plus-circle"></i>
              </IconButton>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default connectWithStore(PreviousJob, ["user"]);
