import React from "react";
import Modal from "react-modal";
import { isMobileDevice } from "../services/mobile";
const customStyles = {
  content: {
    padding: 0,
    height: isMobileDevice() ? "100%" : 700,
    width: isMobileDevice() ? "100%" : 700,
    overflow: "hidden",
  },
};
const footerStyles = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  background: "#fff",
};
const CloseIcon = ({ closeHandler }) => {
  return (
    <div className="modal-mobile-header">
      <div>
        <div
          onClick={() => closeHandler()}
          style={{ fontSize: 22 }}
          className="close-modal-mobile"
        >
          <i className="mdi mdi-chevron-left" />
        </div>
      </div>
    </div>
  );
};
export default function withModal(WrappedComponent) {
  return function (props) {
    const {
      visible,
      modalStyle,
      onClose,
      title,
      HeaderComponent,
      FooterComponent,
      withoutPaddingBottom,
      ...rest
    } = props;
    return (
      <Modal
        ariaHideApp={false}
        style={{
          ...customStyles,
          ...modalStyle,
        }}
        isOpen={visible}
        onRequestClose={() => onClose()}
      >
        <div className="Modal-header">
          {HeaderComponent ? (
            <React.Fragment>
              <CloseIcon closeHandler={() => onClose()} />
              {HeaderComponent}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CloseIcon closeHandler={() => onClose()} />
              <h5>{title}</h5>
            </React.Fragment>
          )}
        </div>
        <div
          className={"Modal-content"}
          style={{
            height: "calc(100% - 122px) !important",
            overflow: "scroll !important",
            overflowX: "hidden !important",
            paddingBottom: withoutPaddingBottom ? 0 : 50,
          }}
        >
          <WrappedComponent {...rest} onClose={onClose} />
        </div>
        <div className="Modal-footer">
          {FooterComponent && (
            <div style={{ ...footerStyles }}>
              <FooterComponent />
            </div>
          )}
        </div>
      </Modal>
    );
  };
}
