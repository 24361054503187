import {
  QUESTIONS_ACTIVE_QUESTION,
  QUESTIONS_SET_ANSWERS,
  QUESTIONS_SET_PUBLIC_START_NODE_ID,
  QUESTIONS_TOGGLE_MAIL_SECTION,
} from "../constants";

const initialState = {
  activeQuestion: 0,
  showMailSection: false,
  answers: {},
  publicStartNodeId: null,
};

const jobQuestions = (state = Object.assign({}, initialState), action) => {
  const { type, data } = action;
  switch (type) {
    case QUESTIONS_ACTIVE_QUESTION:
      return Object.assign({}, state, {
        activeQuestion: data,
      });
    case QUESTIONS_TOGGLE_MAIL_SECTION:
      return Object.assign({}, state, {
        showMailSection: data,
      });
    case QUESTIONS_SET_ANSWERS:
      return Object.assign({}, state, {
        answers: data,
      });
    case QUESTIONS_SET_PUBLIC_START_NODE_ID:
      return Object.assign({}, state, {
        publicStartNodeId: data,
      });
    default:
      return state;
  }
};

export default jobQuestions;
