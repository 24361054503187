import React from "react";
import List from "@material-ui/core/List";
import ExpandedMenuItem from "./expanded_menu_item";
import MenuService from "../../../services/menu";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import withMuiIcon from "../../../hoc/withMuiIcon";
import { isMobileDevice } from "../../../services/mobile";
import { ROLE } from "../../../services/roles";

const useStyles = makeStyles({
  root: {
    marginLeft: isMobileDevice() ? 30 : 0,
    maxHeight: !isMobileDevice() && "50vh",
    overflow: !isMobileDevice() && "auto",
    width: !isMobileDevice() && 250,
  },
});

function SidebarSubMenu({
  gotoPage,
  apps,
  user,
  subMenuType,
  jobTypes,
  handleCreateJobTypeClick,
}) {
  const classes = useStyles();

  const renderApps = () => {
    const appItems = MenuService.getApps()
      .filter((item) => MenuService.filterDisabledMenus(item, apps))
      .filter((item) => {
        if (item.hasOwnProperty("allowTo")) {
          return item.allowTo.indexOf(user.profile.role) !== -1;
        }

        return true;
      })
      .filter((item) => {
        if (
          [ROLE.BUSINESS_ADMIN, ROLE.SYSTEM_ADMIN].indexOf(
            user.profile.role
          ) !== -1
        ) {
          return true;
        }
        if (item.hasOwnProperty("guardFunc")) {
          return item.guardFunc(user.profile);
        }

        return true;
      });

    return (
      <>
        {appItems &&
          appItems.length > 0 &&
          appItems.map((item, i) => {
            return (
              <ExpandedMenuItem
                key={i}
                icon={item.icon}
                label={i18n.t(item.label)}
                //selected={activeLink === item.url}
                onMenuItemClick={() => gotoPage(i18n.t(item.label), item.url)}
                muiIcon={item.muiIcon}
              />
            );
          })}
      </>
    );
  };

  const renderJobTypeSubMenu = () => {
    const list = jobTypes;
    return (
      <>
        <ExpandedMenuItem
          key={"CREATE_NEW"}
          label={i18n.t("default:_CREATE_NEW")}
          onMenuItemClick={handleCreateJobTypeClick}
          muiIcon={withMuiIcon(AddCircleIcon)}
          addRow={true}
        />
        {list &&
          list.length > 0 &&
          list.map((item, i) => {
            const title = item.title;
            return (
              <ExpandedMenuItem
                key={i}
                icon={!item.icon && "icon-case_v2"}
                label={title}
                avatar={item.icon ? item.icon : null}
                onMenuItemClick={() =>
                  gotoPage(i18n.t("_JOB_TYPES"), `/job_type/editor/${item._id}`)
                }
                muiIcon={null}
              />
            );
          })}
      </>
    );
  };

  const renderSubMenu = () => {
    if (subMenuType === "apps") {
      return renderApps();
    }
    if (subMenuType === "jobType") {
      return renderJobTypeSubMenu();
    }
    return null;
  };

  return (
    <List component="div" disablePadding className={classes.root}>
      {renderSubMenu()}
    </List>
  );
}

export default connectWithStore(SidebarSubMenu, ["apps", "user"]);
