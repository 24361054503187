import React, { useCallback, useState, useEffect } from "react";
import FieldPreview from "./field_preview";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import i18n from "../../../i18n";
import { connectWithStore } from "../../../services/redux";
import withModal from "../../../hoc/withModal";
import { updateSignleProjectField } from "../../../services/api/projects";
import { Button, Grid } from "@material-ui/core";
import { CurrentProject } from "../../../redux/actions";
import { ICON, PROJECT_FIELD_TYPE } from "./constants";
import { canUserEdit } from "../../../services/access";
import BlueprintAutocomplete from "../../../components/autoselect/blueprint_autoselect";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  listItemRoot: {
    padding: 0,
    width: "auto",
    maxWidth: "100%",
  },
  chip: {
    width: "100%",
    marginRight: "5px",
    marginBottom: "5px",
    color: "inherit",
  },
}));

const BlueprintAutocompleteModal = withModal(BlueprintAutocomplete);

function BlueprintModal({
  visible,
  onClose,
  id,
  title,
  project,
  dispatch,
  isPrivate,
  accessRules,
  user,
  onUpdate,
}) {
  const [selected, setSelected] = useState([]);

  const updateSelected = useCallback(async () => {
    if (project[id]) {
      setSelected(project[id]);
    }
  }, [id, project]);

  useEffect(() => {
    updateSelected();
  }, [updateSelected]);

  const change = useCallback(
    (option) => {
      canUserEdit(user, accessRules) && setSelected(selected.concat([option]));
    },
    [selected, user, accessRules]
  );

  const deselect = useCallback(
    (option) => {
      const temp = [...selected];
      const filter = temp.filter((o) => o.value === option.value);
      if (filter.length > 0) {
        const index = temp.indexOf(filter[0]);
        if (index !== -1) {
          temp.splice(index, 1);
          setSelected(temp);
        }
      }
    },
    [selected]
  );

  const saveProject = async () => {
    await updateSignleProjectField({
      id: project._id,
      field: {
        id,
        value: selected,
      },
    });
    dispatch(
      CurrentProject.updateProject({
        [id]: selected,
      })
    );
    const updatedProject = { ...project };
    updatedProject[id] = selected;
    if (onUpdate) {
      onUpdate(updatedProject);
    }
    onClose();
  };

  const FooterComponent = function FooterComponent() {
    return (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        style={{
          padding: 10,
          paddingRight: 20,
        }}
      >
        <Button
          onClick={() => saveProject()}
          variant="contained"
          color="primary"
        >
          {i18n.t("default:_SELECT")}
        </Button>
      </Grid>
    );
  };
  return (
    <BlueprintAutocompleteModal
      visible={visible}
      onClose={() => onClose()}
      title={title}
      id={id}
      FooterComponent={canUserEdit(user, accessRules) && FooterComponent}
      selected={selected}
      onChange={change.bind(this)}
      onDeselect={deselect.bind(this)}
      project={project}
      isPrivate={isPrivate}
      accessRules={accessRules}
      user={user}
    />
  );
}

const BlueprintModalConnected = connectWithStore(BlueprintModal, []);

function BlueprintElements({
  label,
  icon,
  editable,
  id,
  currentProject,
  accessRules,
  isPrivate,
  user,
  onUpdate,
}) {
  const formatPreview = useCallback(
    (value) => {
      if (editable) return [];
      if (value && value.length > 0) {
        const newValue = value.slice(0, 4);
        const remaining = value.length - 4;
        if (remaining > 0) {
          newValue.push({
            label: `${remaining} ${i18n.t("default:_MORE")}`,
            value: -1,
          });
        }
        return newValue;
      }
      return value;
    },
    [editable]
  );

  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const { project } = currentProject;

  const [finalValue, setFinalValue] = useState(
    formatPreview(
      currentProject.project && currentProject.project[id]
        ? currentProject.project[id]
        : []
    )
  );

  const onClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  useEffect(() => {
    if (editable) return;
    setFinalValue(
      formatPreview(
        currentProject.project[id] ? currentProject.project[id] : []
      )
    );
  }, [
    id,
    project,
    editable,
    setFinalValue,
    currentProject.project,
    formatPreview,
  ]);
  return (
    <div>
      {!editable && (
        <BlueprintModalConnected
          onClose={onClose}
          id={id}
          visible={modalVisible}
          title={label}
          isPrivate={isPrivate}
          project={project}
          accessRules={accessRules}
          user={user}
          onUpdate={onUpdate}
        />
      )}
      <FieldPreview
        label={label}
        icon={icon}
        defaultIcon={ICON[PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS]}
        onClick={() => (!editable ? setModalVisible(true) : null)}
        accessRules={accessRules}
      >
        {!editable && (
          <List className={classes.listRoot}>
            {finalValue.length > 0 &&
              finalValue.map(({ label, value }, index) => {
                return (
                  <ListItem className={classes.listItemRoot} key={index}>
                    <Chip
                      onClick={() => setModalVisible(true)}
                      className={classes.chip}
                      label={label}
                      variant="outlined"
                    />
                  </ListItem>
                );
              })}
          </List>
        )}
      </FieldPreview>
    </div>
  );
}

export default connectWithStore(BlueprintElements, ["currentProject", "user"]);
