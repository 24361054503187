import React, { useState, useCallback, useEffect } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import {
  STEP_SELECT_JOB_TYPE,
  STEP_SELECT_START_DATE,
  STEP_NEW_JOB,
} from "../steps";
import { WizardJob, Wizard } from "../../../redux/actions";
import { getAllProjectList } from "../../../services/api/projects";
import ListWizard from "../list_wizard";
import { getFileTarget } from "../../../services/api/file_upload";

const defaultProjectAvatar = require("../../../assets/img/project-placeholder.png");

function SelectProject({ goToStep, wizardJob, dispatch, isActive }) {
  const { startDate } = wizardJob;
  const [project, setProject] = useState(wizardJob.project);
  const [projects, setProjects] = useState([]);

  const onProjectChange = (val) => {
    let dispatchValue = { project: null };
    if (val) {
      dispatchValue = { project: val };
    }
    dispatch(WizardJob.setProject(dispatchValue));
    setProject(dispatchValue.project);
  };

  const getProjects = useCallback(async () => {
    const data = await getAllProjectList();
    if (data) {
      setProjects(data.docs);
    }
  }, []);

  const getAvatarCircle = (item) => {
    if (item.avatar) {
      return (
        <img src={getFileTarget(item.avatar, "100x100")} alt={item.name} />
      );
    } else {
      return <img src={defaultProjectAvatar} alt={item.name} />;
    }
  };

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_SELECT_PROJECT")));
    getProjects();
  }, [dispatch, isActive, getProjects]);

  return (
    <div
      className="SelectProject ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListWizard
        items={projects}
        selectionType={project}
        onSelectionChange={onProjectChange}
        getAvatarCircle={getAvatarCircle}
      />
      <WizardButtons
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () => goToStep(STEP_SELECT_JOB_TYPE),
            variant: "white",
          },
          {
            label: startDate
              ? i18n.t("default:_FINISH")
              : i18n.t(`default:_SELECT_START_DATE`),
            onClick: () => {
              if (project && startDate) {
                goToStep(STEP_NEW_JOB);
              } else {
                goToStep(STEP_SELECT_START_DATE);
              }
            },
            variant: "black",
            disabled: !project,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(SelectProject, ["settings", "wizardJob"]);
