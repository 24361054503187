import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RouteWithLayout } from "../services/routing";
import store from "../redux/store";
import { Settings, UserLocation } from "../redux/actions";
//import "../pages/abstract/grid_view.scss";

//Layouts
import AdminLayout from "../layout/admin_layout";
import AuthLayout from "../layout/auth_layout";
import AdminLayoutMock from "../layout/admin_layout_mock";
import Landing from "../pages/landing/landing";
import { connectWithStore } from "../services/redux";
import { updateUser } from "../services/api/user";
//eslint-disable-next-line
import GridView from "../pages/abstract/grid_view";
import GoogleLogin from "../pages/sso/google_login";

//Pages
const ShiftViewLoadState = lazy(() =>
  import("../pages/shift_view/shift_view_load_state")
);
const Auth = lazy(() => import("../pages/login/auth"));
const SignUp = lazy(() => import("../pages/register/signup")); // some comment here
const Onboarding = lazy(() =>
  import("../pages/register/onboarding_wizard/onboarding_wizard")
);
const CustomSignUp = lazy(() => import("../pages/register/custom_signup"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const Dashboard2 = lazy(() => import("../pages/dashboard/dashboard_v2"));
const Profile = lazy(() => import("../pages/my_profile/profile"));
const AppSettings = lazy(() => import("../pages/settings/settings"));
const JobTypeEditor = lazy(() => import("../pages/job_type/editor"));
const JobTypeSettings = lazy(() => import("../pages/job_type/settings"));
const CreateJobType = lazy(() => import("../pages/job_type/create_job_type"));
const ForgotPassword = lazy(() =>
  import("../pages/forgot_password/forgot_password")
);
const ResetPassword = lazy(() =>
  import("../pages/forgot_password/reset_password")
);
const Users = lazy(() => import("../pages/users/users"));
const Files = lazy(() => import("../pages/files/files"));
const Games = lazy(() => import("../pages/games/games"));
const Game = lazy(() => import("../pages/games/game"));
const Workers = lazy(() => import("../pages/workers/workers"));
const WorkerView = lazy(() => import("../pages/workers/worker_view"));
const Projects = lazy(() => import("../pages/projects/projects"));
const ProjectView = lazy(() => import("../pages/projects/project_view"));
const JobTypeAnswers = lazy(() => import("../pages/job_type/answers"));
/* const CreateInstallation = lazy(() =>
  import("../pages/installation/create_installation")
); */
const Listing = lazy(() => import("../pages/installation/listing"));
const InstanceReminder = lazy(() =>
  import("../pages/installation/instance_reminder")
);
const Privacy = lazy(() => import("../pages/privacy/privacy"));
const ErrorPage = lazy(() => import("../pages/abstract/error"));
const Reports = lazy(() => import("../pages/reports/reports"));
const ReportsView = lazy(() => import("../pages/reports/reports_view"));
const ShiftView = lazy(() => import("../pages/shift_view/shift_view"));
const InternalError = lazy(() => import("../pages/error/internal"));
const JobTypeWebhooks = lazy(() => import("../pages/job_type/webhooks"));
const Webhooks = lazy(() => import("../pages/webhooks/webhooks"));
const Playground = lazy(() => import("../pages/playground/playground"));
const Jobs = lazy(() => import("../pages/jobs/jobs_v2"));
const AutomationLogs = lazy(() =>
  import("../pages/settings/automations/automation_logs")
);
const SettingsHome = lazy(() => import("../pages/settings/settings_home"));
const Skills = lazy(() => import("../pages/skills/skills"));
const BlueprintsSettingsPage = lazy(() =>
  import("../pages/settings/blueprints")
);
const Automations = lazy(() =>
  import("../pages/settings/automations/automations")
);
const MsAppSettingsPage = lazy(() => import("../pages/settings/app_settings"));
const SendgridSettingsPage = lazy(() => import("../pages/settings/sendgrid"));

class SamuraiFrontend extends Component {
  constructor(props) {
    super(props);

    store.dispatch(Settings.loadAppSettings());
  }

  requestLocationAccess() {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = Object.assign(
                {},
                {
                  long: position.coords.longitude,
                  lat: position.coords.latitude,
                }
              );
              this.props.dispatch(UserLocation.setLocation(coords));
              if (this.props.user.profile.id) {
                updateUser({
                  id: this.props.user.profile.id,
                  acceptedLocation: true,
                });
              }
            },
            (err) => {
              if (this.props.user.profile.id) {
                updateUser({
                  id: this.props.user.profile.id,
                  acceptedLocation: false,
                });
              }
            },
            {
              timeout: 20 * 1000,
            }
          );
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = Object.assign(
                {},
                {
                  long: position.coords.longitude,
                  lat: position.coords.latitude,
                }
              );
              this.props.dispatch(UserLocation.setLocation(coords));
              if (this.props.user.profile.id) {
                updateUser({
                  id: this.props.user.profile.id,
                  acceptedLocation: true,
                });
              }
            },
            (err) => {
              if (this.props.user.profile.id)
                updateUser({
                  id: this.props.user.profile.id,
                  acceptedLocation: false,
                });
            },
            {
              timeout: 20 * 1000,
            }
          );
        } else if (result.state === "denied") {
          if (this.props.user.profile.id)
            updateUser({
              id: this.props.user.profile.id,
              acceptedLocation: false,
            });
        }
      });
    } else {
    }
  }

  _onGetCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      const coords = Object.assign(
        {},
        { long: position.coords.longitude, lat: position.coords.latitude }
      );
      this.props.dispatch(UserLocation.setLocation(coords));
      if (this.props.user.profile.id) {
        updateUser({
          id: this.props.user.profile.id,
          acceptedLocation: true,
        });
      }
    });
  }

  componentDidMount() {
    const path = this.props.location && this.props.location.pathname;
    if (path && path.includes("answer")) {
      return;
    }
    this.requestLocationAccess();
  }

  componentDidCatch() {
    if (
      process.env.NODE_ENV !== "development" &&
      this.props.location.pathname !== "/error/500"
    ) {
      this.props.history.push("/error/500");
    }
  }

  render() {
    return (
      <Suspense fallback={<AdminLayoutMock />}>
        <Switch>
          <Route
            exact={true}
            path="/"
            component={(props) => <Landing {...props} />}
          />
          <Route
            exact={true}
            path="/webhooks"
            component={(props) => <Webhooks {...props} />}
          />
          <Route
            exact={true}
            path="/job_type/asnwer/:id"
            component={(props) => <JobTypeAnswers {...props} />}
          />
          <Route
            exact={true}
            path="/answer/:id/:project_id"
            component={(props) => <JobTypeAnswers {...props} />}
          />
          <Route
            exact={false}
            path="/google_login"
            component={(props) => <GoogleLogin {...props} />}
          />
          <Route
            exact={true}
            path="/create_installation"
            component={Onboarding}
          />
          <Route
            exact={true}
            path="/instance_reminder"
            component={InstanceReminder}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/auth"
            component={Auth}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/files"
            component={Files}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/games"
            component={Games}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/games/:id"
            component={Game}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/workers"
            component={Workers}
          />
          <RouteWithLayout
            layout={AdminLayout}
            //exact={true}
            path="/worker/view/:id"
            component={WorkerView}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/automation_logs/:id"
            component={AutomationLogs}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/signup"
            component={SignUp}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/custom_signup"
            component={CustomSignUp}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/forgot_password"
            component={ForgotPassword}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/reset_password"
            component={ResetPassword}
          />
          <RouteWithLayout
            layout={AuthLayout}
            hideLogo={true}
            exact={true}
            path="/privacy_and_tou"
            component={Privacy}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/dashboard"
            component={Dashboard2}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/dashboard_old"
            component={Dashboard}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/profile"
            component={Profile}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings"
            component={SettingsHome}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/general"
            component={AppSettings}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/skills"
            component={Skills}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/apps"
            component={AppSettings}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/blueprints"
            component={BlueprintsSettingsPage}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/sendgrid"
            component={SendgridSettingsPage}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/microsoft_sso"
            component={MsAppSettingsPage}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/settings/automations"
            component={Automations}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/users"
            component={Users}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/users/:id"
            component={Users}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/projects"
            component={Projects}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/project/view/:id"
            component={ProjectView}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/project/view/:id/:dialog"
            component={ProjectView}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/job_type/editor/:id"
            component={JobTypeEditor}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/job_type/settings/:id"
            component={JobTypeSettings}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/job_type/webhooks/:id"
            component={JobTypeWebhooks}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/job_type/new"
            component={CreateJobType}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/jobs"
            component={Jobs}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/jobs/:id?"
            component={Jobs}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/jobs/:id?/:dialog?"
            component={Jobs}
          />
          {/* <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/create_installation"
            component={CreateInstallation}
          /> */}
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/instances"
            component={Listing}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/reports"
            component={Reports}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/reports/:id"
            component={ReportsView}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/shifts"
            component={ShiftView}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/shifts/mini"
            component={ShiftView}
          />
          <RouteWithLayout
            layout={AuthLayout}
            exact={true}
            path="/error"
            component={ErrorPage}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/error/500"
            component={InternalError}
          />
          <RouteWithLayout
            layout={AdminLayout}
            exact={true}
            path="/shiftview/load/:id"
            component={ShiftViewLoadState}
          />
          {process.env.NODE_ENV !== "production" && (
            <RouteWithLayout
              layout={AdminLayout}
              exact={true}
              path="/playground"
              component={Playground}
            />
          )}
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(connectWithStore(SamuraiFrontend, ["user"]));
