import React, { useState, useEffect } from "react";
import WizardButtons from "../wizard_buttons";
import { connectWithStore } from "../../../services/redux";
import i18n from "../../../i18n";
import {
  STEP_NEW_JOB,
  STEP_SELECT_JOB_TYPE,
  STEP_SELECT_PROJECT,
} from "../steps";
import "./../../../../node_modules/flatpickr/dist/themes/light.css";
import { WizardJob, Wizard } from "../../../redux/actions";
import { isMobileDevice } from "../../../services/mobile";
import DateTimePicker from "../../date_picker/date_time_picker";
import { APP_PROJECTS } from "../../../services/app";

function SelectStartDate({ goToStep, wizardJob, dispatch, isActive, apps }) {
  const [startDate, setStartDate] = useState(wizardJob.startDate);

  const onChangeDate = (date) => {
    let dispatchValue = { startDate: null };
    if (date) {
      dispatchValue = { startDate: date };
    }
    dispatch(WizardJob.setStartDate(dispatchValue));
    setStartDate(date);
  };

  useEffect(() => {
    if (isActive) dispatch(Wizard.setTitle(i18n.t("default:_START_DATE")));
  }, [isActive, dispatch]);

  const onFinishClick = () => {
    if (!startDate) {
      onChangeDate(new Date());
    }
    goToStep(STEP_NEW_JOB);
  };

  return (
    <div
      className="SelectStartDate ModalBody"
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 10, maxWidth: 400, marginTop: 10 }}>
        <DateTimePicker
          defaultValue={startDate ? startDate : new Date()}
          onClose={(date) => onChangeDate(date)}
          clearable={true}
        />
      </div>
      <WizardButtons
        notSticky={isMobileDevice() ? true : false}
        actions={[
          {
            label: i18n.t("default:_BACK"),
            onClick: () =>
              apps.enabled.includes(APP_PROJECTS)
                ? goToStep(STEP_SELECT_PROJECT)
                : goToStep(STEP_SELECT_JOB_TYPE),
            variant: "white",
          },
          {
            label: i18n.t("default:_FINISH"),
            onClick: onFinishClick,
            variant: "black",
            //disabled: !startDate,
            size: 2,
          },
        ]}
      />
    </div>
  );
}

export default connectWithStore(SelectStartDate, ["wizardJob", "apps"]);
