import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connectWithStore } from "../../services/redux";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: this.props.user ? true : false,
    };
  }

  render() {
    const { isLoggedIn } = this.state;
    return <Redirect to={isLoggedIn ? "/dashboard" : "/auth"} />;
  }
}

export default connectWithStore(Landing, ["user"]);
