const jobType = (state = { list: [], listFromFilter: [] }, action) => {
  switch (action.type) {
    case "LIST_JOB_TYPE":
      state.list = action.data.list;
      return Object.assign({}, state);
    case "LIST_JOB_TYPE_FROM_FILTER":
      state.listFromFilter = action.data.listFromFilter;
      return Object.assign({}, state);
    case "ADD_JOB_TYPE":
      state.list.push(action.data);
      return Object.assign({}, state);
    case "UPDATE_JOB_TYPE":
      const filtered = state.list.filter((item) => item._id === action.data.id);
      if (filtered.length > 0) {
        const index = state.list.indexOf(filtered[0]);
        if (index !== -1) {
          state.list[index].title = action.data.title;
          state.list[index].icon = action.data.icon;
        }
      }
      return Object.assign({}, state);
    case "DELETE_JOB_TYPE":
      const filter = state.list.filter((item) => item._id === action.data.id);
      if (filter.length > 0) {
        const index = state.list.indexOf(filter[0]);
        state.list.splice(index, 1);
      }
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default jobType;
