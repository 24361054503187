import React, { useEffect, useRef } from "react";
import Konva from "konva";
import { connectWithStore } from "../../../../services/redux";
import { MOVE_TOOL, RECT_TOOL } from "../constants";
import store from "../../../../redux/store";
import { BlueprintEditor } from "../../../../redux/actions";
import {
  addNewElementToJob,
  convertMeterToPixels,
  getRelativePointerPosition,
  removeTransformers,
  canUserEditElement,
  canProceedEditingElement,
  subscribeScissorsToParentPosition,
  updateScissorsPositionOnParentDrag,
} from "../utils";
import { v1 as uuid } from "uuid";
import i18n from "../../../../i18n";

const ELEMENT_NAME = "Rect";

function RectTool({ blueprintEditor, dispatch, job, question }) {
  const { app } = blueprintEditor;
  let transformer = useRef();

  useEffect(() => {
    if (app) {
      app.on("mousedown touchstart", (e) => {
        const state = store.getState();
        const { blueprintEditor } = state;
        const { tool } = blueprintEditor;
        if (tool && tool.type !== RECT_TOOL) {
          return;
        }
        if (e.target !== app && e.target.id() !== "blueprint") {
          return;
        }
      });

      app.on("click tap", function (e) {
        const state = store.getState();
        const { blueprintEditor, user } = state;
        const { tool, layer, mode } = blueprintEditor;
        const { profile } = user;
        if (canProceedEditingElement(mode, tool)) {
          if (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name.includes("Layer")))
            dispatch(
              BlueprintEditor.updateHistory(tool.element && tool.element.id)
            );
        } else {
          return;
        }
        if (
          transformer &&
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          transformer.current.nodes([]);
          layer.batchDraw();
          removeTransformers();
          dispatch(BlueprintEditor.setSelectedElement(null));
          dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
          dispatch(BlueprintEditor.setDimensionsPanel(false));
        }

        if (tool && tool.type !== MOVE_TOOL && tool.type !== RECT_TOOL) {
          return;
        }

        if (
          (e.target === app || e.target.id() === "blueprint" || (e.target.parent && e.target.parent.attrs.name.includes("Layer"))) &&
          tool.type === RECT_TOOL
        ) {
          const pos = getRelativePointerPosition(app);
          const id = uuid();
          const canvasData = {
            x: pos.x,
            y: pos.y,
            width: convertMeterToPixels(tool.width),
            height: convertMeterToPixels(tool.width),
            fill: tool.outlined ? "rgba(0, 0, 0, 0)" : tool.color,
            stroke: tool.outlined ? tool.color : "rgba(0,0,0,0)",
            strokeWidth: 3,
            strokeEnabled: tool.outlined ? true : false,
            name: ELEMENT_NAME,
            draggable: true,
            id,
          };
          const rect = new Konva.Rect(canvasData);
          rect.hitStrokeWidth(0);
          rect.shadowForStrokeEnabled(false);
          layer.add(rect);
          dispatch(BlueprintEditor.setSelectedElement(rect));
          const updatedJobs = addNewElementToJob(
            canvasData,
            ELEMENT_NAME,
            tool,
            id,
            blueprintEditor
          );
          dispatch(BlueprintEditor.setJobs(updatedJobs));
          transformer.current = new Konva.Transformer({
            nodes: [rect],
          });
          layer.add(transformer.current);
          layer.batchDraw();
          dispatch(
            BlueprintEditor.changeTool({
              type: MOVE_TOOL,
              color: "#000",
              name: i18n.t("default:_MOVE_TOOL"),
            })
          );
          rect.on("dragstart", () => {
            const parentGroup = rect.parent;
            //if is in group, drag together with scissors
            if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
              subscribeScissorsToParentPosition(rect);
              parentGroup.clearCache();
            }
            if (
              blueprintEditor.mode !== "EDIT" ||
              !(
                transformer &&
                transformer.current &&
                transformer.current._nodes &&
                transformer.current._nodes.length > 0
              )
            ) {
              rect.stopDrag();
            } else {
              dispatch(BlueprintEditor.setSelectedElement(rect));
            }
          });
          rect.on("dragend", () => {
            const parentGroup = rect.parent;
            if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
              updateScissorsPositionOnParentDrag(rect);
              parentGroup.cache();
            }
            dispatch(
              BlueprintEditor.updateElement({
                id: rect.getAttr("id"),
                elementId: tool.element.id,
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
            //dispatch(BlueprintEditor.setSelectedElement(null));
          });
          rect.on("transformstart", function () {
            //get group by grouId and clear cache to see changes
            const parentGroup = rect.parent;
            if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
              parentGroup.clearCache();
            }
          });
          rect.on("transform", () => {
            rect.setAttrs({
              width: Math.max(rect.width() * rect.scaleX(), 5),
              height: Math.max(rect.height() * rect.scaleY(), 5),
              scaleX: 1,
              scaleY: 1,
            });
          });
          rect.on("transformend", () => {
            const parentGroup = rect.parent;
            if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
              parentGroup.cache();
            }
            dispatch(
              BlueprintEditor.updateElement({
                id: rect.getAttr("id"),
                elementId: tool.element.id,
                user: profile,
                visible: true,
                job,
              })
            );
            dispatch(BlueprintEditor.updateHistory());
          });
          dispatch(
            BlueprintEditor.addElement({
              id,
              elementId: tool.element.id,
              user: profile,
              job,
            })
          );
          return;
        }
        if (
          e.target &&
          e.target.attrs &&
          e.target.attrs.name === ELEMENT_NAME
        ) {
          //check if user can edit element before select it
          const selectedElement = blueprintEditor.elements.find(
            (el) => el.id === e.target.attrs.id
          );
          const elementJob = selectedElement ? selectedElement.job : null;
          if (!canUserEditElement(elementJob, job, question)) {
            return;
          }

          //add transformer to element

          e.target.attrs.height = Number(e.target.attrs.height);
          if (!transformer.current) {
            transformer.current = new Konva.Transformer({
              nodes: [e.target],
            });
            layer.add(transformer.current);
          } else {
            transformer.current.nodes([e.target]);
          }
          dispatch(BlueprintEditor.selectElement(e.target));
          layer.batchDraw();
          return;
        }

        // if click on empty area - remove all selections
        if (
          transformer &&
          transformer.current &&
          transformer.current._nodes &&
          transformer.current._nodes.length > 0
        ) {
          dispatch(BlueprintEditor.deselectElement());
          transformer.current.nodes([]);
          layer.batchDraw();
          return;
        }
      });
    }
    // eslint-disable-next-line
  }, [app, dispatch]);
  return <div />;
}

export default connectWithStore(RectTool, ["blueprintEditor"]);
