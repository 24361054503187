import React, { useState } from "react";
import MsTeamsIcon from "../../assets/img/ms_teams.png";
import { canUserRead } from "../../services/access";
import { joinChannel } from "../../services/api/microsoft";
import { connectWithStore } from "../../services/redux";
import { APP_MICROSOFT_SSO } from "../../services/app";

function MsTeamsButton({ projectId, msChannelId, styles, sso, user, apps }) {
  const [activeIcon, setActiveIcon] = useState(msChannelId);
  const { ssoSettings } = sso;
  const ms_access_rules = ssoSettings && ssoSettings.ms_access_rules;

  const onMsIconClick = async () => {
    const params = {
      projectId,
    };
    const response = await joinChannel(params);
    if (response.success) {
      setActiveIcon(true);
      window.open(response.webUrl, "_blank");
    } else {
      if (response && response.message) {
        alert(response.message);
      } else {
        alert("Something went wrong!");
      }
    }
  };

  if (!apps.enabled.includes(APP_MICROSOFT_SSO)) {
    return null;
  }

  if (!canUserRead(user, ms_access_rules)) {
    return null;
  }

  return (
    <img
      style={{
        cursor: "pointer",
        filter: activeIcon ? "none" : "grayscale(100%)",
        ...styles,
      }}
      onClick={onMsIconClick}
      alt="Ms teams icon"
      src={MsTeamsIcon}
    />
  );
}

export default connectWithStore(MsTeamsButton, ["sso", "user", "apps"]);
