import Http from "../http";

export const getMsTeams = async () => {
  const path = `/v2/microsoft/teams`;

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return [];
  }
};

export const joinChannel = async (params) => {
  const path = `/v2/microsoft/join-channel/${params.projectId}`;

  try {
    const response = await Http.sharedInstance.withAuth().get(path);

    return response.data;
  } catch (e) {
    return e.message;
  }
};
