import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
//import useIsInViewport from "use-is-in-viewport";
import ElementsInfoPanel from "./element_info_panel";
import DimensionsPanel from "./dimension_panel";
//import ContentLoader from "react-content-loader";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connectWithStore } from "../../../services/redux";
import { BlueprintEditor } from "../../../redux/actions";
import ElementContainer from "./elements/element_container";
import Konva from "konva";
import { removeTransformers, canUserEditElement, isPolygon } from "./utils";
import StrokeFields from "./stroke_fields";
import { SHAPE } from "./constants";
import { JOB_STATUS } from "../../../services/job_status";
import PolygonSizeInfo from "./polygon_size_info";
import HeightAsWidthToggle from "./height_as_width_toggle";

const useStyles = makeStyles((theme) => ({
  elementRow: {
    width: "95%",
    marginLeft: 5,
    marginTop: 10,
    height: 50,
    //cursor: "pointer",
  },
  grayedOut: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  rowIcon: {
    padding: 0,
    color: theme.palette.primary.main,
    //background: "#A0D1EE",
    width: 40,
    height: 40,
  },
  inputContainer: {
    width: "90%",
    marginLeft: "5%",
  },
  nameInput: {
    marginBottom: 5,
  },
  polygonSizeContainer: {
    marginLeft: "3%",
  },
  polygonSizes: {
    fontSize: "0.85em",
  },
  highlight: {
    border: `2px solid ${theme.palette.primary.main}`
  }
}));

/* const CardLoader = () => (
  <ContentLoader viewBox={`0 0 300 40`}>
    <rect x="0" y="0" rx="0" ry="10" width="300" height="40" />
  </ContentLoader>
); */

function LayerRow({
  type,
  color,
  label,
  width,
  blueprint,
  element,
  blueprintEditor,
  job,
  dispatch,
  comment,
  question,
  widthAsHeight,
}) {
  const classes = useStyles();
  const [tooltipValue, setTooltipValue] = useState("");
  const {
    elementsInfoPanelOpen,
    dimensionsPanel,
    mode,
    selectedElement,
  } = blueprintEditor;

  useEffect(() => {
    if (comment) {
      setTooltipValue(comment);
    } else {
      setTooltipValue("");
    }
  }, [comment, element.job]);

  const addTransformerOnShape = (target) => {
    const transformer = new Konva.Transformer();
    const shape = target[0];
    transformer.nodes([shape]);
    blueprintEditor.layer.add(transformer);
    blueprintEditor.layer.batchDraw();
  };

  const toggleDimensionsPanel = (id) => {
    if (blueprintEditor.mode !== "EDIT") {
      return;
    }
    removeTransformers();
    if (elementsInfoPanelOpen) {
      dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
      dispatch(BlueprintEditor.setInfoPanelData(null));
    }
    if (dimensionsPanel && dimensionsPanel === id) {
      dispatch(BlueprintEditor.setDimensionsPanel(false));
    } else {
      dispatch(BlueprintEditor.setDimensionsPanel(id));
      const target = blueprintEditor.app.find("#" + id);
      addTransformerOnShape(target);
      dispatch(BlueprintEditor.setSelectedElement(target));
    }
  };

  const onRowClicked = (data) => {
    if (dimensionsPanel) {
      dispatch(BlueprintEditor.setDimensionsPanel(false));
    }
    const target = blueprintEditor.app.find("#" + data.element.id);
    if (elementsInfoPanelOpen && elementsInfoPanelOpen === data.element.id) {
      dispatch(BlueprintEditor.setElementsInfoPanelOpen(false));
      dispatch(BlueprintEditor.setInfoPanelData(null));
      dispatch(BlueprintEditor.setSelectedElement(null));
      if (target.length > 0 && mode === "EDIT") {
        removeTransformers();
      }
      return;
    }
    dispatch(BlueprintEditor.setElementsInfoPanelOpen(data.element.id));
    data.currentJob = job;
    dispatch(BlueprintEditor.setInfoPanelData(data));

    //if user cant edit - dont select the element by opening the element info panel
    if (!canUserEditElement(element.job, job, question)) {
      return;
    }

    dispatch(
      BlueprintEditor.setSelectedElement(
        target && target.length > 0 && target[0]
      )
    );
    if (target.length > 0 && mode === "EDIT") {
      removeTransformers();
      addTransformerOnShape(target);
    }
  };

  const handleCommentChange = (val) => {
    const target = blueprintEditor.app.find("#" + element.id);
    const shape = target && target.length > 0 ? target[0] : null;
    setTooltipValue(val);
    if (shape) {
      const attrs = shape.attrs;
      const updatedAttrs = { ...attrs };
      updatedAttrs.comment = val;
      shape.setAttrs({
        ...updatedAttrs,
      });
    }
  };

  const isJobInReview = () => {
    return element.job && element.job.status === JOB_STATUS.REVIEW;
  };

  const isLineOrOutlinedPolygon = () => {
    return (
      type === SHAPE.POLYGON_OUTLINED ||
      type === SHAPE.POLYGON_OPEN ||
      type === SHAPE.THICK_LINE ||
      type === SHAPE.THIN_LINE
    );
  };

  const isInputHidden = selectedElement && selectedElement.attrs.id !== element.id && tooltipValue.length === 0;

  return (
    <div
      className={`${element.visible ? "" : classes.grayedOut} ${selectedElement && element.id === selectedElement.attrs['id'] ? classes.highlight : ''}`} /* ref={targetRef} */
    >
      <>
        <Grid
          container
          alignItems="center"
          className={classes.elementRow}
          style={{ borderLeft: isJobInReview() ? "3px solid red" : "none" }}
        >
          <Grid item xs={8}>
            <ElementContainer
              type={type}
              buttonColor={color}
              label={label}
              width={width}
              clickable={
                canUserEditElement(element.job, job, question) ? true : false
              }
              renderDimensionsPanel={() => toggleDimensionsPanel(element.id)}
              renderElementDetails={() =>
                onRowClicked({ blueprint, element, label })
              }
            />
          </Grid>
          <Grid container justifyContent="center" item xs={4}>
            <IconButton
              className={classes.rowIcon}
              onClick={() => onRowClicked({ blueprint, element, label })}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>
        </Grid>
        {elementsInfoPanelOpen && elementsInfoPanelOpen === element.id && (
          <Grid>
            <ElementsInfoPanel currentJob={job} question={question} />
          </Grid>
        )}
        {dimensionsPanel && dimensionsPanel === element.id && (
          <DimensionsPanel element={element} widthAsHeight={widthAsHeight} />
        )}
        {mode === "EDIT" &&
          canUserEditElement(element.job, job, question) &&
          (type === SHAPE.POLYGON_OUTLINED || type === SHAPE.POLYGON_OPEN) && (
            <StrokeFields element={element} widthAsHeight={widthAsHeight} />
          )}
        {mode === "EDIT" &&
          canUserEditElement(element.job, job, question) &&
          isLineOrOutlinedPolygon() && (
            <HeightAsWidthToggle element={element} type={type} />
          )}
        <Grid container className={classes.inputContainer}>
          {mode === "EDIT" && canUserEditElement(element.job, job, question) && !isInputHidden ? (
            <TextField
              id={"bp-tooltip"}
              value={tooltipValue}
              onChange={(e) => handleCommentChange(e.target.value)}
              placeholder="Description"
              fullWidth
              variant="outlined"
              inputProps={{
                style: { padding: "8.5px 14px" },
              }}
              className={classes.nameInput + " bp-tooltip-input"}
              disabled={
                !selectedElement ||
                (selectedElement.attrs &&
                  selectedElement.attrs.id !== element.id)
              }
            />
          ) : (
            <Typography style={{ margin: 8 }}>
              {`${tooltipValue} ${element.job ? `- #${element.job.ID}` : ""}`}
            </Typography>
          )}
        </Grid>
        {isPolygon(type) && <PolygonSizeInfo element={element} type={type} />}
      </>
      {/* {isInViewport ? (
        
      ) : (
        <CardLoader />
      )} */}
    </div>
  );
}

export default connectWithStore(LayerRow, ["blueprintEditor"]);
