import React, { useState } from "react";
import { connectWithStore } from "../../../services/redux";
import { Questions } from "../../../redux/actions";
import { getFileTarget } from "../../../services/api/file_upload";
import { isMobileDevice } from "../../../services/mobile";
import MediaQuery from "react-responsive";
import { QuestionType } from "../../../services/question";
import EmailSection from "../partials/email_section";

function StepQuestion({
  answers,
  question,
  questions,
  stepQuestions,
  dispatch,
  renderSignatureSection,
  renderTabToolbar,
  renderCommentSection,
  renderQuestionComponent,
  renderFileModal,
  settings,
  handleChangeSettings,
}) {
  const { buttonPressed } = stepQuestions;
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [showSignatureSection, setShowSignatureSection] = useState(false);
  const [showSendEmailSection, setShowSendEmailSection] = useState(false);

  const toggleCommentFromStep = () => {
    setShowCommentSection(showCommentSection ? false : true);
  };

  const toggleSignatureFromStep = () => {
    setShowSignatureSection(showSignatureSection ? false : true);
  };

  const toggleSendEmailFromStep = () => {
    setShowSendEmailSection(showSendEmailSection ? false : true);
  };

  const hideCommentIfPresent = (e) => {
    if (
      showCommentSection &&
      !e.target.classList.contains("commentSection") &&
      !e.target.classList.contains("commentTextarea") &&
      !e.target.classList.contains("commentTextarea-form") &&
      !e.target.classList.contains("responseOptions") &&
      !e.target.classList.contains("MuiChip-label")
    ) {
      setShowCommentSection(false);
    }
  };

  const isWithoutAnswer = () => {
    const answerValue = answers[question.ID] && answers[question.ID].value;
    if (buttonPressed && !answerValue && question.settings.isMandatory) {
      return true;
    }
    return false;
  };

  const setActiveQuestion = (question) => {
    const questionIndex = questions.indexOf(question);
    if (questionIndex !== -1) {
      dispatch(Questions.setActiveQuestion(questionIndex));
    }
  };

  return (
    <div
      style={{
        minHeight: "90vh",
        border: isWithoutAnswer() && "2px solid red",
      }}
      id={"question_" + question.ID}
      onClick={(e) => hideCommentIfPresent(e)}
    >
      <div
        onClick={() => setActiveQuestion(question)}
        style={{
          backgroundImage: isMobileDevice()
            ? `url(${getFileTarget(question.settings.backgroundMobile)})`
            : `url(${getFileTarget(question.settings.backgroundDesktop)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          color: question.settings.darkMode ? "white" : null,
          minHeight: "90vh",
          position: "relative",
        }}
        className={
          question.settings.managementNote
            ? "question-container managementNote"
            : "question-container"
        }
      >
        <div className="question-toolbar-sticky">
          {(question.settings.allowAddingFile ||
            question.settings.allowComment ||
            question.settings.allowTaggingIssue) &&
            renderTabToolbar(question, true, toggleCommentFromStep)}
          {question.settings.allowComment &&
            renderCommentSection(question, true, showCommentSection)}
        </div>
        {question.type !== QuestionType.SUMMARY && (
          <div
            className={"question-title".concat(
              answers[question.ID] && answers[question.ID].isIssue
                ? " issueQuestion active"
                : question.settings.allowTaggingIssue
                ? " issueQuestion"
                : ""
            )}
          >
            <h5
              className={
                showCommentSection ? "question-header-commentOpen" : ""
              }
              dir="auto"
              style={{
                color: question.settings.darkMode ? "white" : null,
              }}
            >
              {answers[question.ID] && answers[question.ID].isIssue && (
                <React.Fragment>
                  <MediaQuery minWidth={768}>
                    <i
                      style={{
                        color: settings.branding["review"].color.hex,
                        paddingRight: "30px",
                      }}
                      className={"mdi mdi-lightbulb-on"}
                    />
                  </MediaQuery>
                  <MediaQuery maxWidth={767}>
                    <div>
                      <i
                        style={{
                          color: settings.branding["review"].color.hex,
                          fontSize: "30px",
                        }}
                        className={"mdi mdi-lightbulb-on"}
                      />
                    </div>
                  </MediaQuery>
                </React.Fragment>
              )}
              {question.title}
            </h5>
            <p
              style={{
                color: question.settings.darkMode ? "white" : null,
              }}
              dir="auto"
            >
              {question.description}
            </p>
          </div>
        )}
        {renderQuestionComponent(question)}
        <div
          className="question-toolbar-sticky toolbar-bottom"
          style={{ bottom: isMobileDevice() && 0 }}
        >
          {question.settings.signature &&
            renderSignatureSection(
              question,
              true,
              toggleSignatureFromStep,
              showSignatureSection
            )}
          {question.settings.emails && (
            <EmailSection
              item={question}
              answers={answers}
              toggleSection={toggleSendEmailFromStep}
              showSendEmailSection={showSendEmailSection}
              handleChangeSettings={handleChangeSettings}
            />
          )}
        </div>
        {question.settings.allowAddingFile && renderFileModal(question)}
      </div>
    </div>
  );
}

export default connectWithStore(StepQuestion, ["stepQuestions", "settings"]);
