import SKILL_STATUS from "./skill_status";
import SKILL_COLORS from "./skill_colors";

export const stringify = (val) => val.toString();

export const arrayComparer = (otherArray) => {
  return (current) => {
    return (
      otherArray.filter((other) => {
        return (
          other.value === current.value && other.display === current.display
        );
      }).length === 0
    );
  };
};

export const setSkillColor = (expStatus) => {
  if (expStatus === SKILL_STATUS.EXPIRED) {
    return SKILL_COLORS.RED;
  } else if (expStatus === SKILL_STATUS.CLOSE_TO_EXPIRE) {
    return SKILL_COLORS.YELLOW;
  } else if (
    expStatus === SKILL_STATUS.NOT_EXPIRED ||
    expStatus === SKILL_STATUS.PERMAMENT
  ) {
    return SKILL_COLORS.GREEN;
  } else {
    return null;
  }
};

export const checkObjectsArrayForElement = (arr, objKey, element) => {
  let includedInArray = false;
  for (let item of arr) {
    if (item[objKey] === element) {
      includedInArray = true;
    }
  }
  return includedInArray;
};

export const intersect = (a, b) => {
  let t;
  if (b.length > a.length) {
    t = b;
    b = a;
    a = t;
  } // indexOf to loop over shorter
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

export const isObject = (obj) => {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
};

export const isEmptyObject = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};
