import React, { createRef } from 'react';
import { Button, Select, TextField } from "@material-ui/core";
import { connectWithStore } from '../../../services/redux';
import i18n from '../../../i18n';
import { ROLE } from '../../../services/roles';
import { PROJECT_FIELD_TYPE } from '../../../pages/projects/fields/constants';
import { GridTable } from '../../../redux/actions';

const OPERATOR = {
    EQUAL: 0,
    CONTAINS: 1,
    NOT_EQUAL: 2
}

function CustomProjectFilters({ dispatch, settings, user, gridTable }) {
    const filters = gridTable.tempFilterSelection.filterProjectFields;
    const values = gridTable.projectFieldsData;
    const availableProjectFields = settings.projectFields ? settings.projectFields.filter((field) => {
        return (field.accessRules && field.accessRules[user.profile.role] && field.accessRules[user.profile.role].read === true) || user.profile.role === ROLE.SYSTEM_ADMIN || user.profile.role === ROLE.BUSINESS_ADMIN;
    }) : [];

    const timeout = createRef();
    const setFilters = (next) => {
        const updatedFilters = { ...gridTable.tempFilterSelection };
        updatedFilters.filterProjectFields = next;
        dispatch(GridTable.setTempFilterSelection(updatedFilters));
    }

    const addFilter = () => {
        setFilters([...(filters ? filters : []), {
            id: availableProjectFields[0].id,
            operator: OPERATOR.EQUAL,
            value: null
        }])
    }

    const changeFilter = (index, field, value) => {
        const nextFilters = filters ? [...filters] : [];
        const target = index;

        if (target > -1) {
            nextFilters[target][field] = value;
            if (field === 'id') {
                const f = values[value];
                if (f.field.type === PROJECT_FIELD_TYPE.TEXT) {
                    nextFilters[target]['operator'] = OPERATOR.CONTAINS;
                }
            }
            setFilters(nextFilters);
        }
    }

    const removeFilter = (index) => {
        if (index > -1) {
            const nextFilters = [...filters];
            nextFilters.splice(index, 1);
            setFilters(nextFilters)
        }
    }

    const processValues = (fieldId) => {
        if (!values[fieldId]) return <></>
        const list = values[fieldId];
        switch (list.field.type) {
            default:
            case PROJECT_FIELD_TYPE.LIST:
                return list.data.filter(function onlyUnique(value, index, array) {
                    if(!value){
                        return false;
                    }
                    const val = value.value;
                    return array.findIndex(a => a.value === val) === index;
                }).map((row) => {
                    return <option value={row.value}>{row.label}</option>
                })
            case PROJECT_FIELD_TYPE.TABLE:
                return list.data.filter(function onlyUnique(value, index, array) {
                    if(!value){
                        return false;
                    }
                    return array.findIndex(a => JSON.stringify(a) === JSON.stringify(value)) === index;
                }).map((row) => {
                    const value = Object.keys(row).filter((key) => key !== 'QR').map((key) => row[key]).join(' - ');
                    return <option value={JSON.stringify(row)}>{value}</option>
                })
        }
    }

    const changeTextField = (index, value) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            changeFilter(index, 'value', value)
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        }, 1000);
    }

    return <>
        <div className='custom-filter-wrapper' style={{ display: 'flex', flexDirection: 'column' }}>
            {filters && filters.length > 0 && filters.map((filter, index) => {
                const fieldData = values[filter.id];
                if (!fieldData) return '';
                return <div className='custom-filter-row' style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }} >
                    <Select style={{ marginBottom: 5 }} defaultValue={filter.id} onChange={(e) => changeFilter(index, 'id', e.target.value)}>
                        {availableProjectFields.filter((field) => [PROJECT_FIELD_TYPE.LIST, PROJECT_FIELD_TYPE.TABLE, PROJECT_FIELD_TYPE.TEXT].includes(field.type)).map((field) => {
                            return <option value={field.id}>{field.label}</option>
                        })}
                    </Select>
                    <Select style={{ marginBottom: 5 }} value={filter.operator} onChange={(e) => changeFilter(index, 'operator', e.target.value)}>
                        {fieldData.field.type !== PROJECT_FIELD_TYPE.TEXT && <option value={OPERATOR.EQUAL}>{i18n.t('default:_EQUAL')}</option>}
                        <option value={OPERATOR.CONTAINS}>{i18n.t('default:_CONTAINS')}</option>
                        {fieldData.field.type !== PROJECT_FIELD_TYPE.TEXT && <option value={OPERATOR.NOT_EQUAL}>{i18n.t('default:_NOT_EQUAL')}</option>}
                    </Select>
                    {filter.operator !== OPERATOR.CONTAINS && <Select style={{ marginBottom: 5 }} value={filter.value} onChange={(e) => changeFilter(index, 'value', e.target.value)}>{processValues(filter.id)}</Select>}
                    {filter.operator === OPERATOR.CONTAINS && <TextField style={{ marginBottom: 5 }} value={filter.value} onChange={(e) => changeTextField(index, e.target.value)} />}
                    <Button onClick={() => removeFilter(index)}>Remove</Button>
                </div>
            })}
        </div>
        <Button onClick={() => addFilter()} color="primary" variant="outlined" startIcon={<>+</>}>Add Filter</Button>
    </>
}


export default connectWithStore(CustomProjectFilters, ["settings", "user", "gridTable"]);