import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: "1.5rem",
  },
  label: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "400",
    fontSize: "0.75rem",
  },
}));

function UserFieldWrapper({ children, onFieldClick, label, withPointer }) {
  const path = window.location.pathname;
  const isProfilePage = path.includes("profile");
  const classes = useStyles();

  return (
    <div
      className={isProfilePage ? classes.field : "col-md-12 m-b-30"}
      style={{ cursor: withPointer && "pointer" }}
      onClick={onFieldClick}
    >
      {isProfilePage ? (
        <label className={classes.label}>{label}</label>
      ) : (
        <p className="editor-title">{label}</p>
      )}
      {children}
    </div>
  );
}

export default UserFieldWrapper;
