import React, { useState } from "react";
import i18n from "../../../../i18n";
import { connectWithStore } from "../../../../services/redux";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { TopNavMenu, WizardJob } from "../../../../redux/actions";
import { getFileTarget } from "../../../../services/api/file_upload";
import Confirm from "../../../../components/confirm/confirm";
import {
  removePrevious,
  view,
  addPrevious,
} from "../../../../services/api/jobs";
import withModal from "../../../../hoc/withModal";
import ButtonBlock from "../../../../components/button_block/button_block";
import Button from "@material-ui/core/Button";
import JobListItem from "../../../../components/jobs/job_list_item";
import PreviousJobAutoselect from "../../../../components/autoselect/previous_job_autoselect";

const useStyles = makeStyles((theme) => ({
  circleJobButton: {
    width: "24px",
    height: "24px",
  },
}));

function NextJob({ currentJob, user, dispatch, setCurrentJob }) {
  const classes = useStyles();
  const [removeNextJobConfirm, setRemoveNextJobConfirm] = useState(false);
  const [removeNextJobId, setRemoveNextJobId] = useState(null);
  const [modalNextJobsOpen, setModalNextJobsOpen] = useState(false);

  const [nextJobsSelection, setNextJobsSelection] = useState([]);

  const onRemoveNextJob = (job) => {
    setRemoveNextJobConfirm(true);
    setRemoveNextJobId(job._id);
  };

  const removeNextJob = async () => {
    await removePrevious(removeNextJobId, currentJob._id);

    const changedJob = { ...currentJob };

    changedJob.next_jobs = currentJob.next_jobs.filter(
      (j) => j._id.toString() !== removeNextJobId.toString()
    );

    setCurrentJob(changedJob);
    setRemoveNextJobConfirm(false);
    setRemoveNextJobId(null);
  };

  const onNextJobChange = (val) => {
    if (val.length > 0) {
      if (currentJob._id.toString() === val[0].value.toString()) {
        return false;
      }

      let newNextJobsSelection = [];

      for (const job of val) {
        newNextJobsSelection.push(job);
      }

      setNextJobsSelection(newNextJobsSelection);
    }
  };

  const addNextJobs = async () => {
    for (const job of nextJobsSelection) {
      const result = await addPrevious(job.value, currentJob._id);

      if (result && result.success) {
        const jobInfo = await view(job.value);
        const changedJob = { ...currentJob };

        if (!changedJob.next_jobs) {
          changedJob.next_jobs = [jobInfo];
        } else {
          changedJob.next_jobs.push(jobInfo);
        }
        setCurrentJob(changedJob);
      }
    }
    setNextJobsSelection([]);
  };

  const onNextJobsSelect = async () => {
    await addNextJobs();
    setModalNextJobsOpen(false);
  };

  const renderNextJobsModal = () => {
    const NextJobsModal = withModal(PreviousJobAutoselect);

    return (
      <NextJobsModal
        title={i18n.t("default:_NEXT_JOB")}
        visible={modalNextJobsOpen}
        onClose={() => setModalNextJobsOpen(false)}
        onJobChange={(job) => onNextJobChange(job)}
        selectedSuggestions={nextJobsSelection}
        FooterComponent={() => (
          <ButtonBlock>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onNextJobsSelect()}
              style={{ marginBottom: 20 }}
            >
              {i18n.t("default:_SELECT")}
            </Button>
          </ButtonBlock>
        )}
      />
    );
  };

  return (
    <div className="m-b-30">
      {modalNextJobsOpen && renderNextJobsModal()}
      <Confirm
        title={i18n.t("default:_ARE_YOU_SURE")}
        message={i18n.t("default:_REMOVE_NEXT_JOB", {
          jobWord: i18n.t("default:_JOBS"),
        })}
        show={removeNextJobConfirm}
        onConfirm={() => removeNextJob()}
        onClose={() => setRemoveNextJobConfirm(false)}
      />
      <p className="editor-title">
        {i18n.t("default:_NEXT_JOBS", {
          jobWord: i18n.t("default:_JOBS"),
        })}
      </p>
      <div className="row px-4 px-md-0">
        <div className="col col-12 col-md-6">
          {currentJob.next_jobs && currentJob.next_jobs.length > 0 && (
            <ul className="list-group">
              {currentJob.next_jobs.map((job, index) => (
                <JobListItem
                  key={"next_job_" + index}
                  onItemClick={onRemoveNextJob}
                  job={job}
                  userRole={user.profile.role}
                />
              ))}
            </ul>
          )}
        </div>
        <div className="col col-12 col-md-6">
          {user.profile.role !== "inspector" && (
            <React.Fragment>
              <IconButton
                classes={{
                  label: classes.circleJobButton,
                }}
                onClick={() => setModalNextJobsOpen(true)}
                data-tip={i18n.t("default:_SELECT_JOB", {
                  jobWord: i18n.t("default:_JOB").toLowerCase(),
                })}
              >
                <i className="mdi  mdi-link-variant"></i>
              </IconButton>
              <IconButton
                classes={{
                  label: classes.circleJobButton,
                }}
                onClick={() => {
                  dispatch(
                    WizardJob.setPrevJob({
                      prevJob: [
                        {
                          value: currentJob._id,
                          label: "#" + currentJob.ID,
                          avatar:
                            currentJob.jobType && !currentJob.jobType.icon ? (
                              <i className="mdi mdi-briefcase-outline circle" />
                            ) : (
                              <img
                                src={getFileTarget(
                                  currentJob.jobType && currentJob.jobType.icon,
                                  "100x100"
                                )}
                                alt={
                                  currentJob.jobType && currentJob.jobType.title
                                }
                              />
                            ),
                        },
                      ],
                    })
                  );
                  dispatch(TopNavMenu.openJobWizard(true));
                  dispatch(TopNavMenu.openWizard(true));
                }}
                data-tip={i18n.t("default:_CREATE_NEW")}
              >
                <i className="mdi  mdi-plus-circle"></i>
              </IconButton>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default connectWithStore(NextJob, ["user"]);
