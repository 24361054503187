export const PROJECT_FIELD_TYPE = {
  ADDRESS: 0,
  STATUS_LIST: 1,
  FILE_LIST: 2,
  LIST: 3,
  TEXT: 4,
  MINI_CHART: 5,
  RANKING: 6,
  SKILLS: 7,
  TABLE: 8,
  CUSTOMER_TABLE: 9,
  BLUEPRINT_ELEMENTS: 10,
  BLUEPRINT_LIST: 11,
};

export const ICON = {
  [PROJECT_FIELD_TYPE.ADDRESS]: "mdi-map-marker",
  [PROJECT_FIELD_TYPE.FILE_LIST]: "mdi-file-multiple",
  [PROJECT_FIELD_TYPE.LIST]: "mdi-format-list-checkbox",
  [PROJECT_FIELD_TYPE.RANKING]: "mdi-star-circle",
  [PROJECT_FIELD_TYPE.TEXT]: "mdi-tooltip-text",
  [PROJECT_FIELD_TYPE.STATUS_LIST]: "mdi-circle",
  [PROJECT_FIELD_TYPE.SKILLS]: "mdi-shield-check",
  [PROJECT_FIELD_TYPE.TABLE]: "mdi-table",
  [PROJECT_FIELD_TYPE.CUSTOMER_TABLE]: "mdi-office-building",
  [PROJECT_FIELD_TYPE.BLUEPRINT_ELEMENTS]: "mdi-developer-board",
  [PROJECT_FIELD_TYPE.BLUEPRINT_LIST]: "mdi-file-pdf-box",
};
