import React, { Component } from "react";
import { connectWithStore } from "../../services/redux";
import ReactTooltip from "react-tooltip";
import { getStatusProps } from "../../services/job_status";
import "./job_badge.scss";
import { Link } from "react-router-dom";

class JobBadge extends Component {
  componentWillUnmount() {
    ReactTooltip.hide(this.fooRef);
  }

  render() {
    const { color, label } = getStatusProps(
      this.props.status,
      this.props.settings
    );

    return (
      <>
        {!this.props.jobColor ? (
          this.props.noLabel ? (
              this.props.linkTo ? (
                      <Link
                          target="_blank"
                          to={this.props.linkTo}
                          ref={(ref) => (this.fooRef = ref)}
                          style={{ backgroundColor: color }}
                          className={`badge noLabel`}
                          data-tip={label}
                          data-place="top"
                      >
                        {this.props.count && this.props.count}
                      </Link>
                  ) :
            <span
              ref={(ref) => (this.fooRef = ref)}
              style={{ backgroundColor: color }}
              className={`badge noLabel`}
              data-tip={label}
              data-place="top"
            >
              {this.props.count && this.props.count}
            </span>
          ) : (
            <span
              ref={(ref) => (this.fooRef = ref)}
              style={{ backgroundColor: color }}
              className={`badge`}
              data-tip={label}
              data-place="top"
            >
              {this.props.count && this.props.count + " "}
              {label}
            </span>
          )
        ) : (
          <div
            style={{ backgroundColor: color }}
            className={`job-status-color`}
          ></div>
        )}
      </>
    );
  }
}

export default connectWithStore(JobBadge, ["settings"]);
