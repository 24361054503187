import React, { Component } from "react";
import i18n from "../../i18n";
import Modal from "react-modal";
import "./confirm.scss";

export default class Confirm extends Component {
  constructor(props) {
    super(props);
    this.buttons = [
      {
        label: i18n.t("default:_CONFIRM"),
        onClick: this.handleConfirm.bind(this),
      },
      {
        label: i18n.t("default:_CANCEL"),
        onClick: this.handleCancel.bind(this),
      },
    ];
  }

  handleConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.handleClose();
  }

  handleClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Modal
        className="ConfirmModal"
        ariaHideApp={false}
        isOpen={this.props.show}
        onRequestClose={() => this.handleClose()}
        contentLabel={this.props.title}
        style={{
          content: {
            width: "500px",
          },
        }}
      >
        <div className="ConfirmModal-body">
          <h4>{this.props.title}</h4>
          <p>{this.props.message}</p>
          {this.props.buttons && this.props.buttons()}
          {!this.props.buttons && (
            <div className="confirmButtons text-right">
              <button
                onClick={() => this.buttons[0].onClick()}
                className="btn btn-danger custom-click"
              >
                {this.buttons[0].label}
              </button>
              <button
                onClick={() => this.buttons[1].onClick()}
                className="btn btn-primary custom-click"
              >
                {this.buttons[1].label}
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
