import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { DialogContent } from "@material-ui/core";
import { connectWithStore } from "../../../services/redux";
import { BlueprintEditor } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 200,
    width: 300,
  },
  title: {
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

function PolygonSidesInput({ open, handleClose, dispatch }) {
  const classes = useStyles();
  const [sides, setSides] = useState(1);

  const onSidesSet = () => {
    dispatch(BlueprintEditor.setOpenPolygonSides(Number(sides)));
    handleClose();
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      onClose={handleClose}
      aria-labelledby="polygon-sides-title"
      open={open}
    >
      <DialogTitle className={classes.title} id="polygon-sides-title">
        Set polygon sides
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          value={sides}
          onChange={(e) => setSides(e.target.value)}
          type="number"
          inputProps={{
            min: 1,
          }}
        />
        <Button color="primary" variant="outlined" onClick={() => onSidesSet()}>
          Set
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default connectWithStore(PolygonSidesInput, []);
