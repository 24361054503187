import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connectWithStore } from "../../../services/redux";
import { CONSTRUCTOR_NAME } from "./constants";
import {
  convertMeterToPixels,
  convertPixelsToUnit,
  getLineWidth,
  updateLineWidth,
} from "./utils";
import MeasureAdjuster from "../../measure_adjuster/measure_adjuster";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 7,
  },
}));

function DimensionsPanel({ element, blueprintEditor, widthAsHeight }) {
  const classes = useStyles();
  const [elWidth, setElWidth] = useState("");
  const [elHeight, setElHeight] = useState("");
  const { layer, selectedElement } = blueprintEditor;

  const shape = blueprintEditor.app.find("#" + element.id)[0];

  const setInitialDimentions = useCallback(() => {
    let width = 360;
    let height = 360;

    if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LAMP) {
      const lampWidth = shape.attrs.width;
      const lampHeight = shape.attrs.height
        ? shape.attrs.height
        : shape.attrs.width;
      const scaleX = shape.attrs.scaleX ? shape.attrs.scaleX : 1;
      const scaleY = shape.attrs.scaleY ? shape.attrs.scaleY : 1;
      width = lampWidth * scaleX;
      height = lampHeight * scaleY;
    }

    if (shape && shape.attrs.name === CONSTRUCTOR_NAME.RECT) {
      width = shape.width();
      height = shape.height();
    }

    if (shape && shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE) {
      width = shape.width();
      height = shape.width();
    }

    if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LINE) {
      width = getLineWidth(shape);
      if (shape.attrs.widthAsHeight && shape.attrs.strokeHeight) {
        height = shape.attrs.strokeHeight;
        //if line element has stroke height, use its width converter for equity
        setElWidth(convertPixelsToUnit(width, "width"));
        setElHeight(convertPixelsToUnit(height, "width"));
        return;
      } else {
        height = "";
      }
    }
    setElWidth(convertPixelsToUnit(width, "width"));
    setElHeight(convertPixelsToUnit(height, "height"));
  }, [shape]);

  useEffect(() => {
    if (selectedElement && !elHeight && !elWidth) {
      setInitialDimentions();
    }
  }, [setInitialDimentions, selectedElement, elHeight, elWidth]);

  //update input on widthAsHeight toggle
  useEffect(() => {
    if (!shape) return;
    if (shape.attrs && shape.attrs.strokeHeight) {
      setInitialDimentions();
    }
  }, [widthAsHeight, shape, setInitialDimentions]);

  const onWidthChange = (val) => {
    setElWidth(val);
    if (!val || Number(val) < 0) {
      return;
    }
    const convertedValue = Number(convertMeterToPixels(Number(val), "width"));
    const shape = blueprintEditor.app.find("#" + element.id)[0];

    if (shape && Number(val) > 0) {
      const parentGroup = shape.parent;
      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        parentGroup.clearCache();
      }
      setElWidth(val);
      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.RECT) {
        shape.width(convertedValue);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE) {
        shape.width(convertedValue);
        setElHeight(val);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LINE) {
        updateLineWidth(shape, convertedValue);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LAMP) {
        const attrs = shape.attrs;
        //adjust width according to the scale
        const scale = attrs.scaleX ? attrs.scaleX : 1;
        shape.width(convertedValue / scale);
      }
      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        parentGroup.cache();
      }

      layer.batchDraw();
    }
  };

  const onHeightChange = (val) => {
    setElHeight(val);
    if (!val || Number(val) < 0) {
      return;
    }
    let convertedValue = Number(convertMeterToPixels(Number(val), "height"));
    const shape = blueprintEditor.app.find("#" + element.id)[0];
    if (shape && val > 0) {
      const parentGroup = shape.parent;
      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        parentGroup.clearCache();
      }
      setElHeight(val);
      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.RECT) {
        shape.height(convertedValue);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.CIRCLE) {
        shape.height(convertedValue);
        setElWidth(val);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LINE) {
        //consider width while converting the value due to widthAsHeight toggle is on
        convertedValue = Number(convertMeterToPixels(Number(val), "width"));
        shape.setAttr("strokeHeight", convertedValue);
      }

      if (shape && shape.attrs.name === CONSTRUCTOR_NAME.LAMP) {
        const attrs = shape.attrs;
        //adjust height according to the scale
        const scale = attrs.scaleY ? attrs.scaleY : 1;
        shape.height(convertedValue / scale);
      }

      if (parentGroup.attrs && parentGroup.attrs.name === "Group") {
        parentGroup.cache();
      }

      layer.batchDraw();
    }
  };

  const decrementWidth = () => {
    const newVal = Number(elWidth) - 0.1;
    onWidthChange(newVal.toFixed(2));
  };

  const incrementWidth = () => {
    const newVal = Number(elWidth) + 0.1;
    onWidthChange(newVal.toFixed(2));
  };

  const decrementHeight = () => {
    const newVal = Number(elHeight) - 0.1;
    onHeightChange(newVal.toFixed(2));
  };

  const incrementHeight = () => {
    const newVal = Number(elHeight) + 0.1;
    onHeightChange(newVal.toFixed(2));
  };

  const shouldShowHeightInput = () => {
    return (
      shape &&
      shape.attrs &&
      (shape.attrs.name !== "Line" ||
        (shape.attrs.name === "Line" && shape.attrs.widthAsHeight))
    );
  };

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid container justifyContent="center" item xs={6}>
        <MeasureAdjuster
          value={elWidth}
          onValueChange={onWidthChange}
          onDecrement={decrementWidth}
          onIncrement={incrementWidth}
          name="widthInput"
          step={0.1}
        />
      </Grid>
      {shouldShowHeightInput() && (
        <Grid container justifyContent="center" item xs={6}>
          <MeasureAdjuster
            value={elHeight}
            onValueChange={onHeightChange}
            onDecrement={decrementHeight}
            onIncrement={incrementHeight}
            name="heightInput"
            step={0.1}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default connectWithStore(DimensionsPanel, ["blueprintEditor"]);
