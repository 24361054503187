import React, { useState, useCallback } from "react";
import i18n from "../../i18n";
import PlacesAutocomplete from "../../components/google_map/location_search";
import ShareButton from "../../components/google_map/share_button";
import GoogleMapReact from "google-map-react";
import Marker from "../../components/marker/marker";
import NavigateButton from "../../components/google_map/navigate_button";

import "./map_with_buttons.scss";
import { connectWithStore } from "../../services/redux";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const LOCATION_MODE = {
  DEFAULT: 0,
  EDIT: 1,
};

function MapWithButtons({
  project,
  onLocationChanged,
  apiLoaded,
  copyLink,
  customChangeHandler,
  customShareLink,
  accessAllowed,
}) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [locationMode, setLocationMode] = useState(LOCATION_MODE.DEFAULT);

  const [coords, setCoords] = useState({
    lat:
      project.location && project.location.lat
        ? project.location.lat
        : 32.109333,
    long:
      project.location && project.location.long
        ? project.location.long
        : 34.855499,
    address:
      project.location && project.location.address
        ? project.location.address
        : null,
  });

  const toggleEditLocationMode = () => {
    if (customChangeHandler) {
      return customChangeHandler();
    }
    setLocationMode(
      locationMode === LOCATION_MODE.DEFAULT
        ? LOCATION_MODE.EDIT
        : LOCATION_MODE.DEFAULT
    );
  };

  const isLocationEditMode = () => {
    return locationMode === LOCATION_MODE.EDIT;
  };

  const getMapLink = () => {
    if (customShareLink) {
      return customShareLink;
    }
    return `${window.location.href.replace("/location", "")}/location`;
  };

  const getWazeLink = () => {
    const { location } = project;
    if (!location) return;
    return `https://www.waze.com/ul?ll=${location.lat},${location.long}&navigate=yes&zoom=11`;
  };

  const getGoogleMapsLink = () => {
    const { location } = project;
    if (!location) return;
    return `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`;
  };

  const _onLocationChanged = async (coords) => {
    //console.log(coords);
    if (onLocationChanged) {
      await onLocationChanged(coords);
      setCoords({
        lat: project && project.location ? project.location.lat : null,
        long: project && project.location ? project.location.long : null,
        address: project && project.location ? project.location.address : null,
      });
    }
    forceUpdate();
  };

  const shareTitle =
    project && project.name
      ? `${project.name} ${i18n.t("default:_LOCATION")}`
      : `${i18n.t("default:_LOCATION")}`;
  const shareText =
    project && project.name
      ? `${project.name} ${i18n.t("default:_LOCATION")}`
      : `${i18n.t("default:_LOCATION")}`;
  return (
    <div
      className={isLocationEditMode() ? "google-map blue-frame" : "google-map"}
    >
      <div className="map-wrapper">
        {locationMode === LOCATION_MODE.EDIT && (
          <PlacesAutocomplete
            className="form-control"
            onChange={(coords) => _onLocationChanged(coords)}
            onMyLocation={({ coords }) =>
              _onLocationChanged({
                lng: coords.longitude,
                lat: coords.latitude,
              })
            }
          />
        )}
        {locationMode === LOCATION_MODE.DEFAULT && (
          <h6>
            <i className="mdi mdi-map-marker-outline large" />
            {project.location && project.location.address
              ? project.location.address
              : i18n.t("default:_ADDRESS")}
          </h6>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          center={{ lat: coords.lat, lng: coords.long }}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) =>
            apiLoaded ? apiLoaded(map, maps) : null
          }
          onClick={({ lat, lng }) =>
            isLocationEditMode()
              ? _onLocationChanged({
                  lat,
                  lng,
                })
              : null
          }
        >
          <Marker lat={coords.lat} lng={coords.long} />
        </GoogleMapReact>
        <div className="modeSelector">
          {accessAllowed && (
            <button
              onClick={() => toggleEditLocationMode()}
              className={isLocationEditMode() ? "btn-full active" : "btn-full"}
            >
              <i className="mdi mdi-pencil" />
              {isLocationEditMode()
                ? i18n.t("default:_SAVE")
                : i18n.t("default:_CHANGE")}
            </button>
          )}
          {copyLink && (
            <ShareButton
              samuraiShare={{
                title: shareTitle,
                text: shareText,
                link: getMapLink(),
              }}
              wazeShare={{
                title: shareTitle,
                text: shareText,
                link: getWazeLink(),
              }}
              mapsShare={{
                title: shareTitle,
                text: shareText,
                link: getGoogleMapsLink(),
              }}
              copy={(link) => copyLink(link)}
            />
          )}
          <NavigateButton
            wazeUrl={getWazeLink()}
            mapsUrl={getGoogleMapsLink()}
          />
        </div>
      </div>
    </div>
  );
}

export default connectWithStore(MapWithButtons, []);
