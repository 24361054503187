import React, { Component } from "react";

export default class Length extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        this.props.answers[this.props.question.ID] &&
        (this.props.answers[this.props.question.ID]["value"] ||
          this.props.answers[this.props.question.ID]["value"] === 0)
          ? this.props.answers[this.props.question.ID]["value"]
          : "",
    };
  }

  componentDidMount() {
    let cmpData = this.props.answers[this.props.question.ID];

    if (this.props.question.settings.isMandatory) {
      if (!cmpData || (cmpData && !cmpData.value && cmpData.value !== 0)) {
        this.props.handleValid(true);
      } else {
        this.props.handleValid(false);
      }
    } else {
      this.props.handleValid(false);
    }
  }

  increment() {
    const { settings } = this.props.question;
    const step = settings.step || 1;
    const max = parseFloat(settings.max) || 9999999999999999999;
    const current = this.state.value ? this.state.value : 0;
    let next = parseFloat(current) + parseFloat(step);
    if (next > max) {
      next = max;
    }
    this.handleChange(next);
  }

  decrement() {
    const { settings } = this.props.question;
    const step = settings.step || 1;
    const min = parseFloat(settings.min) || 0;
    const current = this.state.value ? this.state.value : 0;
    let next = parseFloat(current) - parseFloat(step);
    if (next < min) {
      next = min;
    }
    this.handleChange(next);
  }

  onInputChange(value) {
    const { settings } = this.props.question;
    const min = settings.min || 0;
    const max = settings.max || 9999999999999999999;
    let currentValue = value;

    const intVal = parseInt(value);
    const regExp = /^0[0-9].*$/;
    if (isNaN(intVal)) {
      currentValue = undefined;
    }
    if (regExp.test(currentValue)) {
      currentValue = min;
    }
    if (intVal < min) {
      currentValue = min;
    }

    if (intVal > max) {
      currentValue = max;
    }
    this.handleChange(currentValue);
  }

  handleChange(val) {
    this.setState(
      {
        value: val,
      },
      () => this.props.handleChange(val)
    );
  }

  render() {
    return (
      <div className="question-content length-wrapper">
        <div className="form-row">
          <div className="input-group">
            <div
              className="input-group-prepend pointer"
              onClick={() => this.decrement()}
            >
              <div className="circle-button-length">
                <span>-</span>
              </div>
            </div>
            <input
              value={this.state.value}
              onChange={(event) => this.onInputChange(event.target.value)}
              type="number"
              className="form-control free-text length"
              name="questionField"
              id="questionField"
              max={this.props.question.settings.max || 9999999999999999999}
              min={this.props.question.settings.min || 0}
              step={this.props.question.settings.step || 1}
            />
            <div className="input-group-append pointer">
              <div
                className="circle-button-length"
                onClick={() => this.increment()}
              >
                <span>+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
